{
    "https://locations.massageenvy.com/alaska/anchorage/8840-old-seward-hwy.html": {
        "name": "South Anchorage",
        "address": "8840 Old Seward Hwy Unit A-1, Anchorage, Alaska, 99515",
        "phone": "(907) 336-3689",
        "url": "https://locations.massageenvy.com/alaska/anchorage/8840-old-seward-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 4:30 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            61.14037688297839,
            -149.8651510477066
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.27,
                    "duration": "1 Months",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.98,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.75,
                    "duration": "1 Months",
                    "model_conf": 101.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.14,
                    "duration": "1 Months",
                    "model_conf": 155.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 183.78,
                    "duration": "2 Months",
                    "model_conf": 273.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.9,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.54,
                    "duration": "7 Months",
                    "model_conf": 168.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.75,
                    "duration": "indefinitely",
                    "model_conf": 157.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.47,
                    "duration": "1 Months",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.43,
                    "duration": "1 Months",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.34,
                    "duration": "2 Months",
                    "model_conf": 221.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/maine/south-portland/343-gorham-road.html": {
        "name": "South Portland",
        "address": "343 Gorham Road, South Portland, Maine, 04106",
        "phone": "(207) 772-7770",
        "url": "https://locations.massageenvy.com/maine/south-portland/343-gorham-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 2:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            43.636781,
            -70.330204
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.93,
                    "duration": "2 Months",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.37,
                    "duration": "indefinitely",
                    "model_conf": 139.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.13,
                    "duration": "6 Months",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 155.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.52,
                    "duration": "1 Months",
                    "model_conf": 281.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.6,
                    "duration": "4 Months",
                    "model_conf": 162.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.75,
                    "duration": "8 Months",
                    "model_conf": 165.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.35,
                    "duration": "3 Months",
                    "model_conf": 154.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.15,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.09,
                    "duration": "3 Months",
                    "model_conf": 84.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.71,
                    "duration": "2 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.69,
                    "duration": "2 Months",
                    "model_conf": 220.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-dakota/fargo/2424-13th-avenue-south.html": {
        "name": "Fargo",
        "address": "2424 13th Avenue South, Fargo, North Dakota, 58103",
        "phone": "(701) 239-9000",
        "url": "https://locations.massageenvy.com/north-dakota/fargo/2424-13th-avenue-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            46.860898,
            -96.818742
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.57,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.47,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.09,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.7,
                    "duration": "indefinitely",
                    "model_conf": 101.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.11,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.39,
                    "duration": "indefinitely",
                    "model_conf": 283.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.23,
                    "duration": "indefinitely",
                    "model_conf": 195.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.67,
                    "duration": "1 Months",
                    "model_conf": 165.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.0,
                    "duration": "indefinitely",
                    "model_conf": 164.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 157.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.35,
                    "duration": "indefinitely",
                    "model_conf": 234.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.67,
                    "duration": "1 Months",
                    "model_conf": 85.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.76,
                    "duration": "1 Months",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.68,
                    "duration": "indefinitely",
                    "model_conf": 221.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/vermont/williston/2141-essex-road.html": {
        "name": "Williston",
        "address": "2141 Essex Road, Williston Williston, Vermont, 05495",
        "phone": "(802) 879-0888",
        "url": "https://locations.massageenvy.com/vermont/williston/2141-essex-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:45 AM - 8:00 PM",
            "TUESDAY": "9:45 AM - 8:00 PM",
            "WEDNESDAY": "9:45 AM - 8:00 PM",
            "THURSDAY": "9:45 AM - 8:00 PM",
            "FRIDAY": "9:45 AM - 8:00 PM",
            "SATURDAY": "9:45 AM - 7:00 PM",
            "SUNDAY": "9:45 AM - 6:00 PM"
        },
        "geolocation": [
            44.451195829375024,
            -73.11046930716736
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 70.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.64,
                    "duration": "1 Months",
                    "model_conf": 139.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.22,
                    "duration": "indefinitely",
                    "model_conf": 102.29
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 155.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.65,
                    "duration": "indefinitely",
                    "model_conf": 283.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.24,
                    "duration": "2 Months",
                    "model_conf": 167.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.14,
                    "duration": "indefinitely",
                    "model_conf": 168.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.46,
                    "duration": "1 Months",
                    "model_conf": 155.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.29,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.54,
                    "duration": "1 Months",
                    "model_conf": 85.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.81,
                    "duration": "1 Months",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 212.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/district-of-columbia/washington/4926-wisconsin-avenue-nw.html": {
        "name": "Tenleytown",
        "address": "4926 Wisconsin Avenue NW, Washington, Washington DC, 20016",
        "phone": "(202) 851-3689",
        "url": "https://locations.massageenvy.com/district-of-columbia/washington/4926-wisconsin-avenue-nw.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.95415064038924,
            -77.08273082971579
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.27,
                    "duration": "indefinitely",
                    "model_conf": 69.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.69,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.88,
                    "duration": "indefinitely",
                    "model_conf": 77.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.22,
                    "duration": "indefinitely",
                    "model_conf": 143.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.66,
                    "duration": "indefinitely",
                    "model_conf": 247.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.21,
                    "duration": "1 Months",
                    "model_conf": 148.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.33,
                    "duration": "indefinitely",
                    "model_conf": 131.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.85,
                    "duration": "indefinitely",
                    "model_conf": 134.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.33,
                    "duration": "1 Months",
                    "model_conf": 138.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.15,
                    "duration": "indefinitely",
                    "model_conf": 80.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.33,
                    "duration": "indefinitely",
                    "model_conf": 51.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.72,
                    "duration": "indefinitely",
                    "model_conf": 180.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/auburn/2431-east-glenn-ave..html": {
        "name": "Auburn",
        "address": "2431 East Glenn Ave. Suite 600, Auburn, Alabama, 36830",
        "phone": "(334) 502-2999",
        "url": "https://locations.massageenvy.com/alabama/auburn/2431-east-glenn-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 8:00 PM",
            "TUESDAY": "8:30 AM - 8:00 PM",
            "WEDNESDAY": "8:30 AM - 8:00 PM",
            "THURSDAY": "8:30 AM - 8:00 PM",
            "FRIDAY": "8:30 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.6096198,
            -85.4302694
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.88,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 101.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.52,
                    "duration": "1 Months",
                    "model_conf": 154.1
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.15,
                    "duration": "indefinitely",
                    "model_conf": 283.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 194.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.0,
                    "duration": "3 Months",
                    "model_conf": 163.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 167.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.18,
                    "duration": "1 Months",
                    "model_conf": 154.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.08,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.46,
                    "duration": "indefinitely",
                    "model_conf": 235.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.44,
                    "duration": "1 Months",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.77,
                    "duration": "1 Months",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.78,
                    "duration": "indefinitely",
                    "model_conf": 221.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/daphne/6880-us-hwy-90.html": {
        "name": "Daphne",
        "address": "6880 US Hwy 90 Ste C4, Daphne, Alabama, 36526",
        "phone": "(251) 607-9299",
        "url": "https://locations.massageenvy.com/alabama/daphne/6880-us-hwy-90.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.652120023576344,
            -87.90540397167211
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.24,
                    "duration": "indefinitely",
                    "model_conf": 276.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.65,
                    "duration": "1 Months",
                    "model_conf": 166.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.59,
                    "duration": "indefinitely",
                    "model_conf": 167.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.45,
                    "duration": "indefinitely",
                    "model_conf": 157.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.79,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.31,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.26,
                    "duration": "indefinitely",
                    "model_conf": 221.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/dothan/3430-w-main-street.html": {
        "name": "Palms Terrace",
        "address": "3430 W Main Street Suite 1, Dothan Dothan, Alabama, 36305",
        "phone": "(334) 836-0878",
        "url": "https://locations.massageenvy.com/alabama/dothan/3430-w-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.231000565383138,
            -85.44315099808347
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.58,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.6,
                    "duration": "indefinitely",
                    "model_conf": 158.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.57,
                    "duration": "1 Months",
                    "model_conf": 139.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.91,
                    "duration": "indefinitely",
                    "model_conf": 102.6
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.33,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.47,
                    "duration": "indefinitely",
                    "model_conf": 276.64
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.48,
                    "duration": "indefinitely",
                    "model_conf": 183.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.33,
                    "duration": "2 Months",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.25,
                    "duration": "indefinitely",
                    "model_conf": 167.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.56,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.32,
                    "duration": "1 Months",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.53,
                    "duration": "indefinitely",
                    "model_conf": 237.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.47,
                    "duration": "1 Months",
                    "model_conf": 83.02
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.62,
                    "duration": "2 Months",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.91,
                    "duration": "indefinitely",
                    "model_conf": 221.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/gulf-shores/160-cotton-creek-drive.html": {
        "name": "Gulf Shores",
        "address": "160 Cotton Creek Drive Suite 220, Gulf Shores, Alabama, 36542",
        "phone": "(256) 410-5011",
        "url": "https://locations.massageenvy.com/alabama/gulf-shores/160-cotton-creek-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.2981511,
            -87.6816352
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.2,
                    "duration": "indefinitely",
                    "model_conf": 283.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.7,
                    "duration": "indefinitely",
                    "model_conf": 166.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.14,
                    "duration": "indefinitely",
                    "model_conf": 167.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.33,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.92,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.71,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.33,
                    "duration": "indefinitely",
                    "model_conf": 220.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/hoover/5519-grove-boulevard.html": {
        "name": "Hoover",
        "address": "5519 Grove Boulevard Suite 125, Hoover Hoover, Alabama, 35226",
        "phone": "(205) 988-6400",
        "url": "https://locations.massageenvy.com/alabama/hoover/5519-grove-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.3616423,
            -86.850205
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.0,
                    "duration": "indefinitely",
                    "model_conf": 277.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.32,
                    "duration": "2 Months",
                    "model_conf": 164.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.51,
                    "duration": "indefinitely",
                    "model_conf": 168.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.81,
                    "duration": "2 Months",
                    "model_conf": 157.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.98,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.57,
                    "duration": "1 Months",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.29,
                    "duration": "4 Months",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 202.41,
                    "duration": "indefinitely",
                    "model_conf": 218.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/huntsville/4851-whitesburg-dr.html": {
        "name": "Huntsville - AL",
        "address": "4851 Whitesburg Dr Ste C, Huntsville Huntsville, Alabama, 35802",
        "phone": "(256) 881-8288",
        "url": "https://locations.massageenvy.com/alabama/huntsville/4851-whitesburg-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.69276632748831,
            -86.56593967290837
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.13,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.8,
                    "duration": "indefinitely",
                    "model_conf": 158.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.31,
                    "duration": "2 Months",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.23,
                    "duration": "indefinitely",
                    "model_conf": 98.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.95,
                    "duration": "indefinitely",
                    "model_conf": 154.59
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 277.91
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 193.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 165.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.18,
                    "duration": "indefinitely",
                    "model_conf": 166.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.64,
                    "duration": "indefinitely",
                    "model_conf": 157.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.15,
                    "duration": "2 Months",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.47,
                    "duration": "1 Months",
                    "model_conf": 237.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.9,
                    "duration": "indefinitely",
                    "model_conf": 84.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.91,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.21,
                    "duration": "1 Months",
                    "model_conf": 221.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/madison/5923-wall-triana-hwy.html": {
        "name": "Madison - AL",
        "address": "5923 Wall Triana Hwy, Madison Madison, Alabama, 35758",
        "phone": "(256) 430-4445",
        "url": "https://locations.massageenvy.com/alabama/madison/5923-wall-triana-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.753357725227275,
            -86.74934856271472
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.18,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.26,
                    "duration": "indefinitely",
                    "model_conf": 156.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.59,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.5,
                    "duration": "indefinitely",
                    "model_conf": 102.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.39,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.1,
                    "duration": "indefinitely",
                    "model_conf": 274.54
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.97,
                    "duration": "indefinitely",
                    "model_conf": 187.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.47,
                    "duration": "1 Months",
                    "model_conf": 166.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.65,
                    "duration": "indefinitely",
                    "model_conf": 159.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.0,
                    "duration": "indefinitely",
                    "model_conf": 156.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.37,
                    "duration": "indefinitely",
                    "model_conf": 140.54
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 233.62
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.65,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.35,
                    "duration": "indefinitely",
                    "model_conf": 54.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.34,
                    "duration": "indefinitely",
                    "model_conf": 219.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/montgomery/2478-berryhill-rd.html": {
        "name": "Eastchase",
        "address": "2478 Berryhill Rd Ste 6, Montgomery Montgomery, Alabama, 36117",
        "phone": "(334) 272-8787",
        "url": "https://locations.massageenvy.com/alabama/montgomery/2478-berryhill-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            32.3581098587792,
            -86.1606240225571
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.29,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.59,
                    "duration": "1 Months",
                    "model_conf": 153.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.33,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.68,
                    "duration": "2 Months",
                    "model_conf": 102.43
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.96,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.45,
                    "duration": "4 Months",
                    "model_conf": 280.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.24,
                    "duration": "2 Months",
                    "model_conf": 194.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.34,
                    "duration": "indefinitely",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.4,
                    "duration": "3 Months",
                    "model_conf": 167.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.53,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.93,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.81,
                    "duration": "3 Months",
                    "model_conf": 213.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.63,
                    "duration": "indefinitely",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.34,
                    "duration": "indefinitely",
                    "model_conf": 53.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.84,
                    "duration": "2 Months",
                    "model_conf": 214.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/tuscaloosa/1800-mcfarland-blvd.html": {
        "name": "Tuscaloosa",
        "address": "1800 McFarland Blvd East, Tuscaloosa Tuscaloosa, Alabama, 35404",
        "phone": "(205) 773-2244",
        "url": "https://locations.massageenvy.com/alabama/tuscaloosa/1800-mcfarland-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.194726,
            -87.527219
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/arizona/avondale/9945-w-mcdowell-rd.html": {
        "name": "Avondale",
        "address": "9945 W McDowell Rd Suite 107, Avondale, Arizona, 85392",
        "phone": "(623) 209-3939",
        "url": "https://locations.massageenvy.com/arizona/avondale/9945-w-mcdowell-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.46422968878515,
            -112.27298728321625
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.29,
                    "duration": "indefinitely",
                    "model_conf": 100.91
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 153.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.51,
                    "duration": "indefinitely",
                    "model_conf": 278.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 165.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 166.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 155.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 85.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 55.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.87,
                    "duration": "indefinitely",
                    "model_conf": 218.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/flagstaff/1235-south-plaza-way.html": {
        "name": "Flagstaff",
        "address": "1235 South Plaza Way, Flagstaff, Arizona, 86001",
        "phone": "(928) 526-3689",
        "url": "https://locations.massageenvy.com/arizona/flagstaff/1235-south-plaza-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.189004,
            -111.6639885
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.46,
                    "duration": "1 Months",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 102.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.01,
                    "duration": "2 Months",
                    "model_conf": 153.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.56,
                    "duration": "3 Months",
                    "model_conf": 282.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.3,
                    "duration": "4 Months",
                    "model_conf": 167.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 157.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.55,
                    "duration": "2 Months",
                    "model_conf": 221.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/goodyear/1468-n-litchfield-rd.html": {
        "name": "Goodyear",
        "address": "1468 N Litchfield Rd Ste #M3, Goodyear, Arizona, 85395",
        "phone": "(623) 935-5495",
        "url": "https://locations.massageenvy.com/arizona/goodyear/1468-n-litchfield-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.46381549501922,
            -112.35969834613633
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.28,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 101.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 153.88
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 279.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 165.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 166.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.19,
                    "duration": "indefinitely",
                    "model_conf": 156.04
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.74,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 218.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/oro-valley/7645-n-oracle-rd.html": {
        "name": "Northwest Tucson",
        "address": "7645 N Oracle Rd Suite 140, Oro Valley Oro Valley, Arizona, 85704",
        "phone": "(520) 777-5417",
        "url": "https://locations.massageenvy.com/arizona/oro-valley/7645-n-oracle-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.346205700871565,
            -110.97796434224233
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.09,
                    "duration": "indefinitely",
                    "model_conf": 70.3
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.21,
                    "duration": "indefinitely",
                    "model_conf": 152.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.82,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.51,
                    "duration": "indefinitely",
                    "model_conf": 100.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.89,
                    "duration": "indefinitely",
                    "model_conf": 150.62
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.48,
                    "duration": "indefinitely",
                    "model_conf": 205.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.8,
                    "duration": "indefinitely",
                    "model_conf": 124.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.27,
                    "duration": "5 Months",
                    "model_conf": 152.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.59,
                    "duration": "indefinitely",
                    "model_conf": 156.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.44,
                    "duration": "3 Months",
                    "model_conf": 128.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.0,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.68,
                    "duration": "indefinitely",
                    "model_conf": 200.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 84.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 52.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.98,
                    "duration": "indefinitely",
                    "model_conf": 201.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/prescott/120-e-sheldon-street.html": {
        "name": "Prescott",
        "address": "120 E Sheldon Street, Prescott, Arizona, 86301",
        "phone": "(928) 778-3689",
        "url": "https://locations.massageenvy.com/arizona/prescott/120-e-sheldon-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            34.545624,
            -112.46919
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 71.36
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.49,
                    "duration": "4 Months",
                    "model_conf": 139.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 102.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 155.03
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.56,
                    "duration": "3 Months",
                    "model_conf": 282.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 166.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.61,
                    "duration": "2 Months",
                    "model_conf": 168.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 157.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.18,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 55.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.42,
                    "duration": "1 Months",
                    "model_conf": 221.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/queen-creek/21582-s-ellsworth-loop.html": {
        "name": "Queen Creek",
        "address": "21582 S Ellsworth Loop Ste 110, Queen Creek Queen Creek, Arizona, 85142",
        "phone": "(480) 888-2800",
        "url": "https://locations.massageenvy.com/arizona/queen-creek/21582-s-ellsworth-loop.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.252398875039,
            -111.63824585695488
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 70.88
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 154.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 97.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.29,
                    "duration": "1 Months",
                    "model_conf": 153.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 273.55
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 188.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 162.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 159.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.57,
                    "duration": "indefinitely",
                    "model_conf": 155.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.16,
                    "duration": "2 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 228.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 84.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 55.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.91,
                    "duration": "2 Months",
                    "model_conf": 201.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/surprise/13757-w-bell-road.html": {
        "name": "Surprise",
        "address": "13757 W Bell Road Ste 105, Surprise Surprise, Arizona, 85374",
        "phone": "(623) 544-3300",
        "url": "https://locations.massageenvy.com/arizona/surprise/13757-w-bell-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.6370656076701,
            -112.35579310661205
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 158.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 101.51
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 153.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.76,
                    "duration": "indefinitely",
                    "model_conf": 279.08
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 190.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.95,
                    "duration": "indefinitely",
                    "model_conf": 165.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 167.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.77,
                    "duration": "indefinitely",
                    "model_conf": 156.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.66,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 236.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 85.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 215.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/yuma/2377-s-22nd-drive.html": {
        "name": "Yuma",
        "address": "2377 S 22nd Drive Ste A, Yuma, Arizona, 85364",
        "phone": "(928) 328-8844",
        "url": "https://locations.massageenvy.com/arizona/yuma/2377-s-22nd-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            32.6844207,
            -114.6468931
        ],
        "rating": 0,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.275,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.46,
                    "duration": "1 Months",
                    "model_conf": 141.22
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.32,
                    "duration": "1 Months",
                    "model_conf": 238.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.59,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.98,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.09,
                    "duration": "indefinitely",
                    "model_conf": 221.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/arkansas/conway/1040-south-amity-rd.html": {
        "name": "Conway",
        "address": "1040 South Amity Rd Suite K, Conway, Arkansas, 72032",
        "phone": "(501) 557-3684",
        "url": "https://locations.massageenvy.com/arkansas/conway/1040-south-amity-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.0647036,
            -92.4119683
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 100.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 155.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.72,
                    "duration": "3 Months",
                    "model_conf": 282.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.59,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 166.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.73,
                    "duration": "indefinitely",
                    "model_conf": 157.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.44,
                    "duration": "1 Months",
                    "model_conf": 221.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/arkansas/fayetteville/3557-n.-shiloh-dr.html": {
        "name": "Fayetteville-AR East",
        "address": "3557 N. Shiloh Dr Ste 5, Fayetteville, Arkansas, 72703",
        "phone": "(479) 571-3689",
        "url": "https://locations.massageenvy.com/arkansas/fayetteville/3557-n.-shiloh-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "1:00 PM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.11629253898487,
            -94.15484368801117
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.52,
                    "duration": "indefinitely",
                    "model_conf": 101.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 154.39
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 281.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 165.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 168.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 157.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.11,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.21,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.51,
                    "duration": "indefinitely",
                    "model_conf": 221.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/arkansas/rogers/2603-pleasant-grove-road.html": {
        "name": "Pleasant Grove",
        "address": "2603 Pleasant Grove Road Ste 114, Rogers, Arkansas, 72758",
        "phone": "(479) 633-8340",
        "url": "https://locations.massageenvy.com/arkansas/rogers/2603-pleasant-grove-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.28457693200512,
            -94.15262793624638
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.76,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 93.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.04,
                    "duration": "1 Months",
                    "model_conf": 154.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.78,
                    "duration": "3 Months",
                    "model_conf": 267.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.31,
                    "duration": "indefinitely",
                    "model_conf": 165.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.54,
                    "duration": "indefinitely",
                    "model_conf": 168.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.22,
                    "duration": "indefinitely",
                    "model_conf": 156.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.12,
                    "duration": "indefinitely",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.52,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.93,
                    "duration": "4 Months",
                    "model_conf": 220.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/alameda/2233-s.-shore-center.html": {
        "name": "Alameda Towne Centre",
        "address": "2233 S. Shore Center, Alameda Alameda, California, 94501",
        "phone": "(510) 865-3689",
        "url": "https://locations.massageenvy.com/california/alameda/2233-s.-shore-center.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "Closed",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.757082878576504,
            -122.25068074718666
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.1,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.4,
                    "duration": "1 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.56,
                    "duration": "2 Months",
                    "model_conf": 101.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.05,
                    "duration": "indefinitely",
                    "model_conf": 154.68
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.83,
                    "duration": "2 Months",
                    "model_conf": 275.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.74,
                    "duration": "indefinitely",
                    "model_conf": 164.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.02,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.94,
                    "duration": "indefinitely",
                    "model_conf": 156.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.34,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.87,
                    "duration": "indefinitely",
                    "model_conf": 84.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.12,
                    "duration": "indefinitely",
                    "model_conf": 55.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.43,
                    "duration": "indefinitely",
                    "model_conf": 216.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/aliso-viejo/26852-la-paz-road.html": {
        "name": "Aliso Viejo",
        "address": "26852 La Paz Road #2-A, Aliso Viejo Aliso Viejo, California, 92656",
        "phone": "(949) 472-8177",
        "url": "https://locations.massageenvy.com/california/aliso-viejo/26852-la-paz-road.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.572867,
            -117.706611
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 70.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 142.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.91,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 92.96
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.6,
                    "duration": "indefinitely",
                    "model_conf": 147.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 280.11
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 186.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.59,
                    "duration": "1 Months",
                    "model_conf": 165.37
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 164.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 155.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.37,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 196.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 85.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.3,
                    "duration": "indefinitely",
                    "model_conf": 51.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.96,
                    "duration": "indefinitely",
                    "model_conf": 219.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/anaheim/5769-e-santa-ana-canyon-rd.html": {
        "name": "Anaheim Hills",
        "address": "5769 E Santa Ana Canyon Rd #E, Anaheim Anaheim, California, 92807",
        "phone": "(714) 637-2300",
        "url": "https://locations.massageenvy.com/california/anaheim/5769-e-santa-ana-canyon-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.85352513322857,
            -117.7874319255352
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.39,
                    "duration": "1 Months",
                    "model_conf": 244.38
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.27,
                    "duration": "1 Months",
                    "model_conf": 164.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.38,
                    "duration": "indefinitely",
                    "model_conf": 153.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.7,
                    "duration": "6 Months",
                    "model_conf": 133.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.83,
                    "duration": "indefinitely",
                    "model_conf": 152.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.93,
                    "duration": "indefinitely",
                    "model_conf": 140.59
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.21,
                    "duration": "2 Months",
                    "model_conf": 176.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.9,
                    "duration": "indefinitely",
                    "model_conf": 78.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 49.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.63,
                    "duration": "2 Months",
                    "model_conf": 171.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/apple-valley/19179-bear-valley-rd.html": {
        "name": "Apple Valley, CA",
        "address": "19179 Bear Valley Rd Ste 7, Apple Valley, California, 92308",
        "phone": "(760) 240-3689",
        "url": "https://locations.massageenvy.com/california/apple-valley/19179-bear-valley-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.47043095652305,
            -117.24556070491084
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 71.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.47,
                    "duration": "4 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 102.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.67,
                    "duration": "3 Months",
                    "model_conf": 283.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.59,
                    "duration": "indefinitely",
                    "model_conf": 167.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 168.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 157.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 55.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.49,
                    "duration": "1 Months",
                    "model_conf": 221.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/arroyo-grande/905-rancho-parkway.html": {
        "name": "Arroyo Grande",
        "address": "905 Rancho Parkway Ste D, Arroyo Grande Arroyo Grande, California, 93420",
        "phone": "(805) 481-5600",
        "url": "https://locations.massageenvy.com/california/arroyo-grande/905-rancho-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.12637617382668,
            -120.59307965214958
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.35,
                    "duration": "indefinitely",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.25,
                    "duration": "4 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.14,
                    "duration": "indefinitely",
                    "model_conf": 102.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.9,
                    "duration": "indefinitely",
                    "model_conf": 155.15
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.38,
                    "duration": "indefinitely",
                    "model_conf": 283.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.72,
                    "duration": "indefinitely",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.35,
                    "duration": "indefinitely",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.12,
                    "duration": "indefinitely",
                    "model_conf": 157.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.18,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.74,
                    "duration": "indefinitely",
                    "model_conf": 85.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.71,
                    "duration": "indefinitely",
                    "model_conf": 220.33
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/bakersfield/9000-ming-ave..html": {
        "name": "Bakersfield Marketplace",
        "address": "9000 Ming Ave., Suite D1, Bakersfield, California, 93311",
        "phone": "(661) 664-3689",
        "url": "https://locations.massageenvy.com/california/bakersfield/9000-ming-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.34142620471681,
            -119.10308092832565
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.66,
                    "duration": "indefinitely",
                    "model_conf": 280.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.7,
                    "duration": "indefinitely",
                    "model_conf": 194.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 165.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.6,
                    "duration": "indefinitely",
                    "model_conf": 165.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.03,
                    "duration": "indefinitely",
                    "model_conf": 153.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.87,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.7,
                    "duration": "indefinitely",
                    "model_conf": 237.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.08,
                    "duration": "indefinitely",
                    "model_conf": 86.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.99,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.75,
                    "duration": "indefinitely",
                    "model_conf": 220.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/beverly-hills/246-south-robertson-boulevard.html": {
        "name": "Beverly Hills",
        "address": "246 South Robertson Boulevard, Beverly Hills Beverly Hills, California, 90211",
        "phone": "(310) 854-3689",
        "url": "https://locations.massageenvy.com/california/beverly-hills/246-south-robertson-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.063606,
            -118.383348
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.0,
                    "duration": "indefinitely",
                    "model_conf": 70.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.49,
                    "duration": "1 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.4,
                    "duration": "3 Months",
                    "model_conf": 100.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.93,
                    "duration": "1 Months",
                    "model_conf": 154.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.77,
                    "duration": "indefinitely",
                    "model_conf": 280.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.75,
                    "duration": "indefinitely",
                    "model_conf": 165.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.45,
                    "duration": "2 Months",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.82,
                    "duration": "indefinitely",
                    "model_conf": 154.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.22,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.65,
                    "duration": "indefinitely",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.55,
                    "duration": "indefinitely",
                    "model_conf": 216.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/brea/375-w.-birch-street.html": {
        "name": "Brea Downtown",
        "address": "375 W. Birch Street #22, Brea Brea, California, 92821",
        "phone": "(714) 990-3033",
        "url": "https://locations.massageenvy.com/california/brea/375-w.-birch-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.91946454848395,
            -117.90191413128281
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.59,
                    "duration": "indefinitely",
                    "model_conf": 259.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.43,
                    "duration": "1 Months",
                    "model_conf": 177.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.55,
                    "duration": "indefinitely",
                    "model_conf": 157.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.2,
                    "duration": "4 Months",
                    "model_conf": 148.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.09,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.58,
                    "duration": "indefinitely",
                    "model_conf": 140.34
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.26,
                    "duration": "1 Months",
                    "model_conf": 202.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.91,
                    "duration": "indefinitely",
                    "model_conf": 81.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.03,
                    "duration": "indefinitely",
                    "model_conf": 52.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.28,
                    "duration": "1 Months",
                    "model_conf": 194.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/brentwood/2465-sand-creek-road.html": {
        "name": "The Streets Of Brentwood",
        "address": "2465 Sand Creek Road Suite 112, Brentwood, California, 94513",
        "phone": "(925) 516-1818",
        "url": "https://locations.massageenvy.com/california/brentwood/2465-sand-creek-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 4:30 PM",
            "SUNDAY": "9:00 AM - 4:30 PM"
        },
        "geolocation": [
            37.94499898180906,
            -121.73742890417589
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1..5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.19,
                    "duration": "indefinitely",
                    "model_conf": 71.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.65,
                    "duration": "2 Months",
                    "model_conf": 102.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.79,
                    "duration": "2 Months",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.23,
                    "duration": "indefinitely",
                    "model_conf": 280.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.63,
                    "duration": "indefinitely",
                    "model_conf": 166.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.13,
                    "duration": "5 Months",
                    "model_conf": 162.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.26,
                    "duration": "indefinitely",
                    "model_conf": 152.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.2,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.72,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.92,
                    "duration": "indefinitely",
                    "model_conf": 55.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.94,
                    "duration": "2 Months",
                    "model_conf": 218.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/burlingame/1209-howard-avenue.html": {
        "name": "Burlingame",
        "address": "1209 Howard Avenue, Burlingame, California, 94010",
        "phone": "(650) 392-3689",
        "url": "https://locations.massageenvy.com/california/burlingame/1209-howard-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.5775452,
            -122.3450719
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.92,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.37,
                    "duration": "1 Months",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.45,
                    "duration": "indefinitely",
                    "model_conf": 101.69
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.85,
                    "duration": "indefinitely",
                    "model_conf": 154.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.5,
                    "duration": "indefinitely",
                    "model_conf": 273.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 165.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.67,
                    "duration": "indefinitely",
                    "model_conf": 165.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.86,
                    "duration": "2 Months",
                    "model_conf": 156.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.85,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.64,
                    "duration": "1 Months",
                    "model_conf": 84.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 55.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.71,
                    "duration": "indefinitely",
                    "model_conf": 219.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/camarillo/2360-las-posas-rd.html": {
        "name": "Camarillo",
        "address": "2360 Las Posas Rd Ste G, Camarillo, California, 93010",
        "phone": "(805) 987-1010",
        "url": "https://locations.massageenvy.com/california/camarillo/2360-las-posas-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "11:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.234841,
            -119.037738
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.91,
                    "duration": "indefinitely",
                    "model_conf": 70.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.7,
                    "duration": "1 Months",
                    "model_conf": 100.75
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.44,
                    "duration": "1 Months",
                    "model_conf": 154.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.59,
                    "duration": "indefinitely",
                    "model_conf": 282.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.52,
                    "duration": "1 Months",
                    "model_conf": 166.4
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.98,
                    "duration": "indefinitely",
                    "model_conf": 167.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.39,
                    "duration": "1 Months",
                    "model_conf": 156.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.39,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.46,
                    "duration": "3 Months",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.28,
                    "duration": "indefinitely",
                    "model_conf": 218.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/campbell/1875-s-bascom-avenue.html": {
        "name": "Campbell",
        "address": "1875 S Bascom Avenue Suite 650, Campbell Campbell, California, 95008",
        "phone": "(408) 679-3689",
        "url": "https://locations.massageenvy.com/california/campbell/1875-s-bascom-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.289002024647154,
            -121.9336091294445
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.65,
                    "duration": "indefinitely",
                    "model_conf": 70.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.84,
                    "duration": "2 Months",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.16,
                    "duration": "4 Months",
                    "model_conf": 88.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.29,
                    "duration": "indefinitely",
                    "model_conf": 152.53
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.77,
                    "duration": "indefinitely",
                    "model_conf": 269.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.69,
                    "duration": "indefinitely",
                    "model_conf": 157.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.24,
                    "duration": "3 Months",
                    "model_conf": 141.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.01,
                    "duration": "indefinitely",
                    "model_conf": 149.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.55,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.85,
                    "duration": "indefinitely",
                    "model_conf": 84.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.89,
                    "duration": "indefinitely",
                    "model_conf": 55.05
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.09,
                    "duration": "indefinitely",
                    "model_conf": 215.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/castro-valley/3348-village-drive.html": {
        "name": "Castro Village",
        "address": "3348 Village Drive, Castro Valley, California, 94546",
        "phone": "(510) 690-8585",
        "url": "https://locations.massageenvy.com/california/castro-valley/3348-village-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.69683522763698,
            -122.077669898468
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.04,
                    "duration": "indefinitely",
                    "model_conf": 71.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.26,
                    "duration": "4 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.82,
                    "duration": "indefinitely",
                    "model_conf": 101.15
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.93,
                    "duration": "indefinitely",
                    "model_conf": 154.31
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.39,
                    "duration": "indefinitely",
                    "model_conf": 277.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.24,
                    "duration": "indefinitely",
                    "model_conf": 164.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.22,
                    "duration": "indefinitely",
                    "model_conf": 164.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.45,
                    "duration": "indefinitely",
                    "model_conf": 154.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.12,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.11,
                    "duration": "indefinitely",
                    "model_conf": 84.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.08,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.34,
                    "duration": "indefinitely",
                    "model_conf": 216.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/chico/615-mangrove-ave.html": {
        "name": "Chico",
        "address": "615 Mangrove Ave Ste 110, Chico, California, 95926",
        "phone": "(530) 892-8585",
        "url": "https://locations.massageenvy.com/california/chico/615-mangrove-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 4:30 PM"
        },
        "geolocation": [
            39.734742,
            -121.836009
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.93,
                    "duration": "indefinitely",
                    "model_conf": 280.87
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.22,
                    "duration": "indefinitely",
                    "model_conf": 194.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.72,
                    "duration": "indefinitely",
                    "model_conf": 166.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.75,
                    "duration": "indefinitely",
                    "model_conf": 167.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.0,
                    "duration": "indefinitely",
                    "model_conf": 155.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.05,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.0,
                    "duration": "indefinitely",
                    "model_conf": 238.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.94,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.91,
                    "duration": "indefinitely",
                    "model_conf": 221.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/chino/3825-grand-avenue.html": {
        "name": "Chino",
        "address": "3825 Grand Avenue Ste B, Chino Chino, California, 91710",
        "phone": "(909) 573-0200",
        "url": "https://locations.massageenvy.com/california/chino/3825-grand-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.9984122,
            -117.7210338
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 70.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.74,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 99.72
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 154.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 277.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.03,
                    "duration": "2 Months",
                    "model_conf": 164.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.07,
                    "duration": "indefinitely",
                    "model_conf": 163.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 155.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.78,
                    "duration": "3 Months",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 85.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 215.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/chula-vista/878-eastlake-parkway.html": {
        "name": "Eastlake",
        "address": "878 Eastlake Parkway Ste 912, Chula Vista, California, 91914",
        "phone": "(619) 482-2072",
        "url": "https://locations.massageenvy.com/california/chula-vista/878-eastlake-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            32.651336433167195,
            -116.97046334968549
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.63,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 101.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.69,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.46,
                    "duration": "indefinitely",
                    "model_conf": 278.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 165.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.3,
                    "duration": "indefinitely",
                    "model_conf": 166.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.09,
                    "duration": "indefinitely",
                    "model_conf": 155.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.23,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 85.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 55.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.33,
                    "duration": "indefinitely",
                    "model_conf": 219.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/claremont/2090-n-mills-avenue.html": {
        "name": "Claremont",
        "address": "2090 N Mills Avenue, Claremont Claremont, California, 91711",
        "phone": "(909) 447-7500",
        "url": "https://locations.massageenvy.com/california/claremont/2090-n-mills-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.121282,
            -117.706831
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.28,
                    "duration": "indefinitely",
                    "model_conf": 70.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.46,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.08,
                    "duration": "indefinitely",
                    "model_conf": 99.72
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.13,
                    "duration": "1 Months",
                    "model_conf": 154.16
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.3,
                    "duration": "1 Months",
                    "model_conf": 280.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.37,
                    "duration": "indefinitely",
                    "model_conf": 164.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.78,
                    "duration": "1 Months",
                    "model_conf": 164.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.38,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.42,
                    "duration": "1 Months",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.9,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.63,
                    "duration": "indefinitely",
                    "model_conf": 218.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/clovis/605-west-herndon-avenue.html": {
        "name": "Clovis",
        "address": "605 West Herndon Avenue, Clovis, California, 93612",
        "phone": "(559) 299-7077",
        "url": "https://locations.massageenvy.com/california/clovis/605-west-herndon-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            36.838179,
            -119.725702
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.79,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.9,
                    "duration": "indefinitely",
                    "model_conf": 237.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.04,
                    "duration": "indefinitely",
                    "model_conf": 85.26
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.04,
                    "duration": "indefinitely",
                    "model_conf": 54.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.8,
                    "duration": "indefinitely",
                    "model_conf": 215.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/corona/2225-eagle-glen-parkway.html": {
        "name": "Corona at Eagle Glen",
        "address": "2225 Eagle Glen Parkway Suite 101, Corona Corona, California, 92883",
        "phone": "(951) 371-3689",
        "url": "https://locations.massageenvy.com/california/corona/2225-eagle-glen-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            33.821484,
            -117.522919
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.1,
                    "duration": "indefinitely",
                    "model_conf": 70.98
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.35,
                    "duration": "2 Months",
                    "model_conf": 156.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.79,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.89,
                    "duration": "indefinitely",
                    "model_conf": 100.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.26,
                    "duration": "1 Months",
                    "model_conf": 154.63
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.56,
                    "duration": "indefinitely",
                    "model_conf": 281.62
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.58,
                    "duration": "indefinitely",
                    "model_conf": 194.57
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.58,
                    "duration": "indefinitely",
                    "model_conf": 166.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.52,
                    "duration": "3 Months",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.88,
                    "duration": "indefinitely",
                    "model_conf": 156.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.57,
                    "duration": "indefinitely",
                    "model_conf": 140.49
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 237.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.57,
                    "duration": "1 Months",
                    "model_conf": 85.36
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.7,
                    "duration": "2 Months",
                    "model_conf": 55.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.41,
                    "duration": "1 Months",
                    "model_conf": 215.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/costa-mesa/3030-harbor-blvd.html": {
        "name": "Costa Mesa",
        "address": "3030 Harbor Blvd Suite G2, Costa Mesa Costa Mesa, California, 92626",
        "phone": "(714) 545-5305",
        "url": "https://locations.massageenvy.com/california/costa-mesa/3030-harbor-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.68257337147621,
            -117.91726060999952
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.05,
                    "duration": "indefinitely",
                    "model_conf": 275.5
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.79,
                    "duration": "indefinitely",
                    "model_conf": 187.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.74,
                    "duration": "indefinitely",
                    "model_conf": 161.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.1,
                    "duration": "5 Months",
                    "model_conf": 161.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.76,
                    "duration": "indefinitely",
                    "model_conf": 152.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.58,
                    "duration": "indefinitely",
                    "model_conf": 140.57
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.73,
                    "duration": "indefinitely",
                    "model_conf": 221.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.53,
                    "duration": "1 Months",
                    "model_conf": 84.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.87,
                    "duration": "indefinitely",
                    "model_conf": 54.2
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.05,
                    "duration": "1 Months",
                    "model_conf": 214.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/cotati/640-e-cotati-ave.html": {
        "name": "Cotati",
        "address": "640 E Cotati Ave, Cotati, California, 94931",
        "phone": "(707) 795-7700",
        "url": "https://locations.massageenvy.com/california/cotati/640-e-cotati-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "11:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.329133,
            -122.697545
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.05,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.58,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.22,
                    "duration": "indefinitely",
                    "model_conf": 102.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.33,
                    "duration": "1 Months",
                    "model_conf": 154.13
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.24,
                    "duration": "indefinitely",
                    "model_conf": 282.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.15,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.18,
                    "duration": "indefinitely",
                    "model_conf": 167.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.23,
                    "duration": "indefinitely",
                    "model_conf": 155.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.41,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.21,
                    "duration": "indefinitely",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.94,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.48,
                    "duration": "indefinitely",
                    "model_conf": 216.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/culver-city/10994-jefferson-blvd.html": {
        "name": "Culver City South",
        "address": "10994 Jefferson Blvd, Culver City, California, 90230",
        "phone": "(424) 231-8068",
        "url": "https://locations.massageenvy.com/california/culver-city/10994-jefferson-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 4:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.99775965989135,
            -118.39469153372954
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 70.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 99.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 153.42
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 277.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.19,
                    "duration": "indefinitely",
                    "model_conf": 163.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.92,
                    "duration": "6 Months",
                    "model_conf": 163.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.5,
                    "duration": "indefinitely",
                    "model_conf": 154.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.62,
                    "duration": "1 Months",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 55.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.62,
                    "duration": "2 Months",
                    "model_conf": 215.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/cypress/6915-katella-avenue.html": {
        "name": "Cypress - CA",
        "address": "6915 Katella Avenue, Cypress Cypress, California, 90630",
        "phone": "(714) 947-1888",
        "url": "https://locations.massageenvy.com/california/cypress/6915-katella-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.80404243084454,
            -118.01155786380633
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.84,
                    "duration": "indefinitely",
                    "model_conf": 269.07
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.17,
                    "duration": "2 Months",
                    "model_conf": 182.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.28,
                    "duration": "indefinitely",
                    "model_conf": 162.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.3,
                    "duration": "3 Months",
                    "model_conf": 156.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.76,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.29,
                    "duration": "indefinitely",
                    "model_conf": 140.59
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.27,
                    "duration": "1 Months",
                    "model_conf": 221.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.39,
                    "duration": "1 Months",
                    "model_conf": 83.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.68,
                    "duration": "1 Months",
                    "model_conf": 54.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.14,
                    "duration": "1 Months",
                    "model_conf": 210.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/daly-city/239-lake-merced-blvd.html": {
        "name": "Daly City",
        "address": "239 Lake Merced Blvd, Daly City, California, 94015",
        "phone": "(650) 757-3689",
        "url": "https://locations.massageenvy.com/california/daly-city/239-lake-merced-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            37.6995108,
            -122.4836922
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.36,
                    "duration": "1 Months",
                    "model_conf": 71.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.57,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 101.95
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.59,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.21,
                    "duration": "indefinitely",
                    "model_conf": 270.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.64,
                    "duration": "4 Months",
                    "model_conf": 164.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 163.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.3,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.61,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.19,
                    "duration": "indefinitely",
                    "model_conf": 218.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/davis/110-f-street.html": {
        "name": "Davis",
        "address": "110 F Street Ste C, Davis, California, 95616",
        "phone": "(530) 758-3689",
        "url": "https://locations.massageenvy.com/california/davis/110-f-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.542777,
            -121.739545
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.19,
                    "duration": "2 Months",
                    "model_conf": 283.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.99,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.54,
                    "duration": "2 Months",
                    "model_conf": 168.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.37,
                    "duration": "indefinitely",
                    "model_conf": 157.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.57,
                    "duration": "1 Months",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.15,
                    "duration": "indefinitely",
                    "model_conf": 85.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.89,
                    "duration": "2 Months",
                    "model_conf": 221.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/diamond-bar/1195-s-diamond-bar-boulevard.html": {
        "name": "Diamond Bar",
        "address": "1195 S Diamond Bar Boulevard Ste B, Diamond Bar, California, 91765",
        "phone": "(909) 612-9700",
        "url": "https://locations.massageenvy.com/california/diamond-bar/1195-s-diamond-bar-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            34.001419,
            -117.811362
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.49,
                    "duration": "indefinitely",
                    "model_conf": 274.0
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.3,
                    "duration": "indefinitely",
                    "model_conf": 188.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.08,
                    "duration": "2 Months",
                    "model_conf": 163.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.53,
                    "duration": "indefinitely",
                    "model_conf": 161.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.0,
                    "duration": "2 Months",
                    "model_conf": 155.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.43,
                    "duration": "indefinitely",
                    "model_conf": 226.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 84.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 54.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.66,
                    "duration": "indefinitely",
                    "model_conf": 212.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/downey/12052-lakewood-boulevard.html": {
        "name": "Downey",
        "address": "12052 Lakewood Boulevard, Downey Downey, California, 90242",
        "phone": "(562) 622-3689",
        "url": "https://locations.massageenvy.com/california/downey/12052-lakewood-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.927598,
            -118.126172
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.86,
                    "duration": "indefinitely",
                    "model_conf": 71.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.36,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.99,
                    "duration": "indefinitely",
                    "model_conf": 98.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.43,
                    "duration": "1 Months",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.1,
                    "duration": "indefinitely",
                    "model_conf": 278.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.75,
                    "duration": "indefinitely",
                    "model_conf": 164.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.24,
                    "duration": "indefinitely",
                    "model_conf": 164.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.38,
                    "duration": "1 Months",
                    "model_conf": 156.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.63,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.53,
                    "duration": "1 Months",
                    "model_conf": 85.19
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.74,
                    "duration": "1 Months",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.87,
                    "duration": "indefinitely",
                    "model_conf": 217.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/eastvale/12435-limonite-ave.html": {
        "name": "Eastvale",
        "address": "12435 Limonite Ave Ste 540, Eastvale, California, 91752",
        "phone": "(951) 360-3689",
        "url": "https://locations.massageenvy.com/california/eastvale/12435-limonite-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.976395178379846,
            -117.55320740631078
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.81,
                    "duration": "indefinitely",
                    "model_conf": 70.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.15,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.13,
                    "duration": "indefinitely",
                    "model_conf": 100.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.53,
                    "duration": "indefinitely",
                    "model_conf": 154.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.15,
                    "duration": "1 Months",
                    "model_conf": 280.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.58,
                    "duration": "1 Months",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.03,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.19,
                    "duration": "indefinitely",
                    "model_conf": 156.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.73,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.3,
                    "duration": "indefinitely",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.19,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.17,
                    "duration": "indefinitely",
                    "model_conf": 219.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/el-dorado-hills/4510-post-street.html": {
        "name": "El Dorado Hills",
        "address": "4510 Post Street Ste 340, El Dorado Hills, California, 95762",
        "phone": "(916) 941-6850",
        "url": "https://locations.massageenvy.com/california/el-dorado-hills/4510-post-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.65250327926966,
            -121.0675996641465
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.77,
                    "duration": "indefinitely",
                    "model_conf": 277.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.87,
                    "duration": "5 Months",
                    "model_conf": 165.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.83,
                    "duration": "indefinitely",
                    "model_conf": 166.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.63,
                    "duration": "2 Months",
                    "model_conf": 156.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.52,
                    "duration": "1 Months",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 202.18,
                    "duration": "indefinitely",
                    "model_conf": 214.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/encinitas/278-n-el-camino-real.html": {
        "name": "Encinitas",
        "address": "278 N El Camino Real Suite D, Encinitas, California, 92024",
        "phone": "(760) 436-3300",
        "url": "https://locations.massageenvy.com/california/encinitas/278-n-el-camino-real.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            33.052848979855355,
            -117.26247849449481
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 70.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.17,
                    "duration": "2 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.99,
                    "duration": "5 Months",
                    "model_conf": 101.8
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.31,
                    "duration": "1 Months",
                    "model_conf": 154.16
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.91,
                    "duration": "4 Months",
                    "model_conf": 279.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.93,
                    "duration": "indefinitely",
                    "model_conf": 165.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.5,
                    "duration": "7 Months",
                    "model_conf": 161.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.49,
                    "duration": "indefinitely",
                    "model_conf": 150.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.14,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.6,
                    "duration": "indefinitely",
                    "model_conf": 85.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.44,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.3,
                    "duration": "3 Months",
                    "model_conf": 219.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/escondido/1210-auto-parkway.html": {
        "name": "Escondido",
        "address": "1210 Auto Parkway, Escondido, California, 92029",
        "phone": "(760) 747-3529",
        "url": "https://locations.massageenvy.com/california/escondido/1210-auto-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 4:30 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.110377,
            -117.097179
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 70.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.93,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.37,
                    "duration": "3 Months",
                    "model_conf": 102.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.67,
                    "duration": "indefinitely",
                    "model_conf": 154.24
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.39,
                    "duration": "3 Months",
                    "model_conf": 280.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.53,
                    "duration": "indefinitely",
                    "model_conf": 163.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.88,
                    "duration": "5 Months",
                    "model_conf": 161.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.22,
                    "duration": "indefinitely",
                    "model_conf": 150.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.99,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.61,
                    "duration": "indefinitely",
                    "model_conf": 85.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.52,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.7,
                    "duration": "2 Months",
                    "model_conf": 220.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fair-oaks/5319-sunrise-boulevard.html": {
        "name": "Sunrise",
        "address": "5319 Sunrise Boulevard, Fair Oaks Fair Oaks, California, 95628",
        "phone": "(916) 965-3689",
        "url": "https://locations.massageenvy.com/california/fair-oaks/5319-sunrise-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.662499,
            -121.273854
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.19,
                    "duration": "4 Months",
                    "model_conf": 281.27
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.61,
                    "duration": "3 Months",
                    "model_conf": 193.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.75,
                    "duration": "3 Months",
                    "model_conf": 165.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.72,
                    "duration": "1 Months",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.97,
                    "duration": "indefinitely",
                    "model_conf": 155.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.25,
                    "duration": "1 Months",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 232.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.2,
                    "duration": "indefinitely",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.23,
                    "duration": "indefinitely",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.17,
                    "duration": "1 Months",
                    "model_conf": 207.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fairfield/5041-business-center-drive.html": {
        "name": "Cordelia - Green Valley",
        "address": "5041 Business Center Drive Suite 100, Fairfield, California, 94534",
        "phone": "(707) 864-2200",
        "url": "https://locations.massageenvy.com/california/fairfield/5041-business-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.21754496503632,
            -122.14185610413551
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.29,
                    "duration": "indefinitely",
                    "model_conf": 283.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.38,
                    "duration": "indefinitely",
                    "model_conf": 166.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.71,
                    "duration": "1 Months",
                    "model_conf": 168.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.0,
                    "duration": "indefinitely",
                    "model_conf": 157.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.8,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.86,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.43,
                    "duration": "1 Months",
                    "model_conf": 221.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fontana/16391-sierra-lakes-parkway.html": {
        "name": "North Fontana",
        "address": "16391 Sierra Lakes Parkway Ste 100, Fontana, California, 92336",
        "phone": "(909) 355-5553",
        "url": "https://locations.massageenvy.com/california/fontana/16391-sierra-lakes-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.137694,
            -117.448444
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.75,
                    "duration": "indefinitely",
                    "model_conf": 70.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.07,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.32,
                    "duration": "indefinitely",
                    "model_conf": 100.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.39,
                    "duration": "indefinitely",
                    "model_conf": 154.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.01,
                    "duration": "indefinitely",
                    "model_conf": 281.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.18,
                    "duration": "3 Months",
                    "model_conf": 165.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.56,
                    "duration": "indefinitely",
                    "model_conf": 167.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.61,
                    "duration": "3 Months",
                    "model_conf": 156.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.28,
                    "duration": "2 Months",
                    "model_conf": 140.64
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.35,
                    "duration": "2 Months",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.57,
                    "duration": "3 Months",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.95,
                    "duration": "indefinitely",
                    "model_conf": 220.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fremont/39016-paseo-padre-parkway.html": {
        "name": "Fremont",
        "address": "39016 Paseo Padre Parkway, Fremont, California, 94538",
        "phone": "(510) 456-3689",
        "url": "https://locations.massageenvy.com/california/fremont/39016-paseo-padre-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "12:00 PM - 8:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.554554,
            -121.980857
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.8,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.06,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.14,
                    "duration": "indefinitely",
                    "model_conf": 101.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.82,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.84,
                    "duration": "indefinitely",
                    "model_conf": 277.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.14,
                    "duration": "2 Months",
                    "model_conf": 165.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.49,
                    "duration": "indefinitely",
                    "model_conf": 165.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.57,
                    "duration": "indefinitely",
                    "model_conf": 156.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.66,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.13,
                    "duration": "indefinitely",
                    "model_conf": 85.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.13,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.75,
                    "duration": "indefinitely",
                    "model_conf": 217.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/glendale/333-north-brand-blvd.html": {
        "name": "Glendale",
        "address": "333 North Brand Blvd, Glendale, California, 91203",
        "phone": "(818) 246-3689",
        "url": "https://locations.massageenvy.com/california/glendale/333-north-brand-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "8:30 AM - 6:00 PM"
        },
        "geolocation": [
            34.1511368,
            -118.2552653
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.04,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.82,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.44,
                    "duration": "2 Months",
                    "model_conf": 98.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.63,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.2,
                    "duration": "1 Months",
                    "model_conf": 276.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.39,
                    "duration": "indefinitely",
                    "model_conf": 164.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.3,
                    "duration": "3 Months",
                    "model_conf": 166.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.68,
                    "duration": "indefinitely",
                    "model_conf": 152.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.2,
                    "duration": "indefinitely",
                    "model_conf": 140.24
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.68,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.85,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.72,
                    "duration": "indefinitely",
                    "model_conf": 209.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/glendora/1343-e-gladstone-st.html": {
        "name": "Glendora",
        "address": "1343 E Gladstone St Bldg E, Glendora Glendora, California, 91740",
        "phone": "(909) 267-7515",
        "url": "https://locations.massageenvy.com/california/glendora/1343-e-gladstone-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            34.11682665626124,
            -117.83555226405042
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.71,
                    "duration": "indefinitely",
                    "model_conf": 280.05
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.47,
                    "duration": "1 Months",
                    "model_conf": 192.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.79,
                    "duration": "indefinitely",
                    "model_conf": 164.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.45,
                    "duration": "2 Months",
                    "model_conf": 164.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.9,
                    "duration": "indefinitely",
                    "model_conf": 154.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.79,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.66,
                    "duration": "indefinitely",
                    "model_conf": 234.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.34,
                    "duration": "1 Months",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.95,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.89,
                    "duration": "1 Months",
                    "model_conf": 217.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/granada-hills/11852-balboa-blvd.html": {
        "name": "Granada Hills",
        "address": "11852 Balboa Blvd Ste B103, Granada Hills, California, 91344",
        "phone": "(818) 366-3772",
        "url": "https://locations.massageenvy.com/california/granada-hills/11852-balboa-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            34.285292073669495,
            -118.50097931921482
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.31,
                    "duration": "1 Months",
                    "model_conf": 71.29
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.01,
                    "duration": "indefinitely",
                    "model_conf": 139.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.9,
                    "duration": "indefinitely",
                    "model_conf": 101.91
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.06,
                    "duration": "indefinitely",
                    "model_conf": 154.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.8,
                    "duration": "2 Months",
                    "model_conf": 281.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.32,
                    "duration": "indefinitely",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.1,
                    "duration": "4 Months",
                    "model_conf": 167.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.33,
                    "duration": "indefinitely",
                    "model_conf": 156.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.76,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.13,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.32,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.15,
                    "duration": "1 Months",
                    "model_conf": 220.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/hermosa-beach/1601-pacific-coast-hwy.html": {
        "name": "Hermosa Beach",
        "address": "1601 Pacific Coast Hwy Suite 285, Hermosa Beach, California, 90254",
        "phone": "(310) 318-3689",
        "url": "https://locations.massageenvy.com/california/hermosa-beach/1601-pacific-coast-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.8670086,
            -118.394314
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.95,
                    "duration": "indefinitely",
                    "model_conf": 69.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 139.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.95,
                    "duration": "indefinitely",
                    "model_conf": 89.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.78,
                    "duration": "indefinitely",
                    "model_conf": 148.2
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.41,
                    "duration": "3 Months",
                    "model_conf": 266.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.69,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.69,
                    "duration": "1 Months",
                    "model_conf": 144.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.81,
                    "duration": "indefinitely",
                    "model_conf": 146.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.82,
                    "duration": "2 Months",
                    "model_conf": 140.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.88,
                    "duration": "indefinitely",
                    "model_conf": 85.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.12,
                    "duration": "indefinitely",
                    "model_conf": 53.0
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.59,
                    "duration": "2 Months",
                    "model_conf": 191.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/irvine/6262-irvine-boulevard.html": {
        "name": "Irvine at Woodbury Town Center",
        "address": "6262 Irvine Boulevard, Irvine Irvine, California, 92620",
        "phone": "(949) 570-9590",
        "url": "https://locations.massageenvy.com/california/irvine/6262-irvine-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.6975611684383,
            -117.7432224379249
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.51,
                    "duration": "3 Months",
                    "model_conf": 277.72
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.72,
                    "duration": "3 Months",
                    "model_conf": 189.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.81,
                    "duration": "indefinitely",
                    "model_conf": 164.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.51,
                    "duration": "8 Months",
                    "model_conf": 163.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.41,
                    "duration": "1 Months",
                    "model_conf": 155.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.4,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.04,
                    "duration": "2 Months",
                    "model_conf": 221.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.86,
                    "duration": "indefinitely",
                    "model_conf": 84.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.17,
                    "duration": "indefinitely",
                    "model_conf": 53.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.36,
                    "duration": "3 Months",
                    "model_conf": 213.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/la-ca\u00f1ada-flintridge/659-foothill-blvd.html": {
        "name": "La Ca\u00f1ada Flintridge",
        "address": "659 Foothill Blvd, La Ca\u00f1ada Flintridge, California, 91011",
        "phone": "(818) 952-3689",
        "url": "https://locations.massageenvy.com/california/la-ca\u00f1ada-flintridge/659-foothill-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            34.202582,
            -118.19289
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.61,
                    "duration": "3 Months",
                    "model_conf": 274.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.01,
                    "duration": "2 Months",
                    "model_conf": 191.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.17,
                    "duration": "indefinitely",
                    "model_conf": 163.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.24,
                    "duration": "3 Months",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.14,
                    "duration": "indefinitely",
                    "model_conf": 150.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.4,
                    "duration": "indefinitely",
                    "model_conf": 140.48
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.51,
                    "duration": "3 Months",
                    "model_conf": 235.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.68,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.5,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.3,
                    "duration": "1 Months",
                    "model_conf": 203.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/la-habra/1216-s-idaho-st.html": {
        "name": "La Habra",
        "address": "1216 S Idaho St Ste D, La Habra, California, 90631",
        "phone": "(714) 515-7555",
        "url": "https://locations.massageenvy.com/california/la-habra/1216-s-idaho-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.915552,
            -117.958364
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.25,
                    "duration": "indefinitely",
                    "model_conf": 265.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.22,
                    "duration": "indefinitely",
                    "model_conf": 182.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.55,
                    "duration": "1 Months",
                    "model_conf": 159.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.46,
                    "duration": "indefinitely",
                    "model_conf": 154.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.01,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.53,
                    "duration": "1 Months",
                    "model_conf": 140.37
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.22,
                    "duration": "indefinitely",
                    "model_conf": 213.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.75,
                    "duration": "indefinitely",
                    "model_conf": 82.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.64,
                    "duration": "1 Months",
                    "model_conf": 53.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.34,
                    "duration": "indefinitely",
                    "model_conf": 202.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/la-jolla/7650-girard-avenue.html": {
        "name": "La Jolla",
        "address": "7650 Girard Avenue Ste 200, La Jolla, California, 92037",
        "phone": "(858) 551-0078",
        "url": "https://locations.massageenvy.com/california/la-jolla/7650-girard-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.842998,
            -117.273534
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.42,
                    "duration": "indefinitely",
                    "model_conf": 261.85
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.22,
                    "duration": "indefinitely",
                    "model_conf": 193.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.1,
                    "duration": "indefinitely",
                    "model_conf": 155.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.61,
                    "duration": "indefinitely",
                    "model_conf": 153.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.86,
                    "duration": "indefinitely",
                    "model_conf": 152.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.76,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.61,
                    "duration": "indefinitely",
                    "model_conf": 213.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.28,
                    "duration": "1 Months",
                    "model_conf": 82.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.65,
                    "duration": "1 Months",
                    "model_conf": 55.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.73,
                    "duration": "indefinitely",
                    "model_conf": 203.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/la-mesa/3727-avocado-blvd.html": {
        "name": "Rancho San Diego",
        "address": "3727 Avocado Blvd, La Mesa La Mesa, California, 91941",
        "phone": "(619) 660-6789",
        "url": "https://locations.massageenvy.com/california/la-mesa/3727-avocado-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.747402703665415,
            -116.95921800103406
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.57,
                    "duration": "indefinitely",
                    "model_conf": 272.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.54,
                    "duration": "indefinitely",
                    "model_conf": 192.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.17,
                    "duration": "2 Months",
                    "model_conf": 164.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.2,
                    "duration": "indefinitely",
                    "model_conf": 165.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.34,
                    "duration": "1 Months",
                    "model_conf": 150.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.94,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.67,
                    "duration": "indefinitely",
                    "model_conf": 230.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.1,
                    "duration": "indefinitely",
                    "model_conf": 85.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.02,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.51,
                    "duration": "indefinitely",
                    "model_conf": 215.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/la-quinta/79485-highway-111.html": {
        "name": "La Quinta",
        "address": "79485 Highway 111 Ste 9, La Quinta, California, 92253",
        "phone": "(760) 771-2244",
        "url": "https://locations.massageenvy.com/california/la-quinta/79485-highway-111.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.70623534076375,
            -116.27794968039002
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.24,
                    "duration": "indefinitely",
                    "model_conf": 71.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.95,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.44,
                    "duration": "indefinitely",
                    "model_conf": 101.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.51,
                    "duration": "indefinitely",
                    "model_conf": 154.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.44,
                    "duration": "indefinitely",
                    "model_conf": 281.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.5,
                    "duration": "indefinitely",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.31,
                    "duration": "indefinitely",
                    "model_conf": 157.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.72,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.41,
                    "duration": "indefinitely",
                    "model_conf": 85.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.14,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.59,
                    "duration": "indefinitely",
                    "model_conf": 220.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/laguna-niguel/30065-alicia-parkway.html": {
        "name": "Laguna Niguel Plaza",
        "address": "30065 Alicia Parkway #B, Laguna Niguel, California, 92677",
        "phone": "(949) 495-8100",
        "url": "https://locations.massageenvy.com/california/laguna-niguel/30065-alicia-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.526640056130475,
            -117.71701148958397
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.33,
                    "duration": "indefinitely",
                    "model_conf": 70.67
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 147.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.56,
                    "duration": "indefinitely",
                    "model_conf": 139.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.65,
                    "duration": "indefinitely",
                    "model_conf": 96.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 149.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.53,
                    "duration": "indefinitely",
                    "model_conf": 279.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.14,
                    "duration": "indefinitely",
                    "model_conf": 189.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.83,
                    "duration": "3 Months",
                    "model_conf": 165.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.11,
                    "duration": "indefinitely",
                    "model_conf": 165.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.72,
                    "duration": "2 Months",
                    "model_conf": 155.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.41,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.68,
                    "duration": "indefinitely",
                    "model_conf": 210.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 85.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 52.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.95,
                    "duration": "indefinitely",
                    "model_conf": 218.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/lake-forest/23635-el-toro-rd.html": {
        "name": "Lake Forest",
        "address": "23635 El Toro Rd Ste A, Lake Forest, California, 92630",
        "phone": "(949) 768-3689",
        "url": "https://locations.massageenvy.com/california/lake-forest/23635-el-toro-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.62008160826717,
            -117.70422577857971
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 70.67
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 146.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.98,
                    "duration": "indefinitely",
                    "model_conf": 139.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.55,
                    "duration": "2 Months",
                    "model_conf": 95.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.14,
                    "duration": "indefinitely",
                    "model_conf": 148.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.58,
                    "duration": "3 Months",
                    "model_conf": 278.78
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.76,
                    "duration": "3 Months",
                    "model_conf": 187.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 165.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.19,
                    "duration": "4 Months",
                    "model_conf": 164.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.55,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.12,
                    "duration": "2 Months",
                    "model_conf": 205.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 85.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.46,
                    "duration": "indefinitely",
                    "model_conf": 52.24
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 217.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/lakewood/4949-graywood-ave..html": {
        "name": "Lakewood Center",
        "address": "4949 Graywood Ave., Lakewood, California, 90712",
        "phone": "(562) 630-3689",
        "url": "https://locations.massageenvy.com/california/lakewood/4949-graywood-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "8:00 AM - 5:00 PM"
        },
        "geolocation": [
            33.8485762,
            -118.1384252
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.03,
                    "duration": "indefinitely",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.55,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.1,
                    "duration": "indefinitely",
                    "model_conf": 93.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.96,
                    "duration": "1 Months",
                    "model_conf": 153.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.99,
                    "duration": "indefinitely",
                    "model_conf": 273.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.85,
                    "duration": "indefinitely",
                    "model_conf": 162.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.05,
                    "duration": "indefinitely",
                    "model_conf": 159.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.01,
                    "duration": "1 Months",
                    "model_conf": 155.24
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.18,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.19,
                    "duration": "2 Months",
                    "model_conf": 84.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.57,
                    "duration": "2 Months",
                    "model_conf": 54.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.83,
                    "duration": "indefinitely",
                    "model_conf": 213.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/lancaster/1050-west-avenue-k.html": {
        "name": "Lancaster West",
        "address": "1050 West Avenue K, Lancaster Lancaster, California, 93534",
        "phone": "(661) 874-4080",
        "url": "https://locations.massageenvy.com/california/lancaster/1050-west-avenue-k.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.67341435303849,
            -118.15027545767214
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.81,
                    "duration": "indefinitely",
                    "model_conf": 71.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.24,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.09,
                    "duration": "indefinitely",
                    "model_conf": 102.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.6,
                    "duration": "indefinitely",
                    "model_conf": 154.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.32,
                    "duration": "indefinitely",
                    "model_conf": 282.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.96,
                    "duration": "indefinitely",
                    "model_conf": 165.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.18,
                    "duration": "indefinitely",
                    "model_conf": 167.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 157.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.89,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.89,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.26,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.02,
                    "duration": "indefinitely",
                    "model_conf": 218.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/lemon-grove/7034-broadway.html": {
        "name": "Lemon Grove",
        "address": "7034 Broadway, Lemon Grove, California, 91945",
        "phone": "(619) 460-3689",
        "url": "https://locations.massageenvy.com/california/lemon-grove/7034-broadway.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            32.7432241,
            -117.0463389
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.34,
                    "duration": "indefinitely",
                    "model_conf": 270.27
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.1,
                    "duration": "indefinitely",
                    "model_conf": 193.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.53,
                    "duration": "1 Months",
                    "model_conf": 163.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.93,
                    "duration": "indefinitely",
                    "model_conf": 164.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.71,
                    "duration": "indefinitely",
                    "model_conf": 149.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.22,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.27,
                    "duration": "indefinitely",
                    "model_conf": 231.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.28,
                    "duration": "indefinitely",
                    "model_conf": 84.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.86,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.32,
                    "duration": "indefinitely",
                    "model_conf": 214.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/livermore/2100-portola-avenue.html": {
        "name": "Livermore",
        "address": "2100 Portola Avenue Suite E, Livermore, California, 94551",
        "phone": "(925) 292-5165",
        "url": "https://locations.massageenvy.com/california/livermore/2100-portola-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.691798,
            -121.773494
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.16,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.38,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.64,
                    "duration": "1 Months",
                    "model_conf": 102.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.66,
                    "duration": "indefinitely",
                    "model_conf": 153.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.52,
                    "duration": "1 Months",
                    "model_conf": 281.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.64,
                    "duration": "indefinitely",
                    "model_conf": 165.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.41,
                    "duration": "3 Months",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.34,
                    "duration": "indefinitely",
                    "model_conf": 155.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.44,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.27,
                    "duration": "indefinitely",
                    "model_conf": 85.24
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.18,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.4,
                    "duration": "1 Months",
                    "model_conf": 219.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/los-angeles/315-west-9th-st.html": {
        "name": "Downtown LA",
        "address": "315 West 9th St Suite 101, Los Angeles, California, 90015",
        "phone": "(323) 315-6464",
        "url": "https://locations.massageenvy.com/california/los-angeles/315-west-9th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.0433827,
            -118.2574565
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.6,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.41,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.12,
                    "duration": "4 Months",
                    "model_conf": 99.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.62,
                    "duration": "indefinitely",
                    "model_conf": 154.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.19,
                    "duration": "4 Months",
                    "model_conf": 279.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.83,
                    "duration": "indefinitely",
                    "model_conf": 164.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.34,
                    "duration": "3 Months",
                    "model_conf": 165.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.07,
                    "duration": "indefinitely",
                    "model_conf": 154.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.14,
                    "duration": "1 Months",
                    "model_conf": 140.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.63,
                    "duration": "indefinitely",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.91,
                    "duration": "indefinitely",
                    "model_conf": 55.44
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.93,
                    "duration": "1 Months",
                    "model_conf": 212.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/manhattan-beach/1590-rosecrans-avenue.html": {
        "name": "Manhattan Beach",
        "address": "1590 Rosecrans Avenue, Manhattan Beach Manhattan Beach, California, 90266",
        "phone": "(310) 321-6717",
        "url": "https://locations.massageenvy.com/california/manhattan-beach/1590-rosecrans-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.90161673980944,
            -118.38460738043614
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.53,
                    "duration": "indefinitely",
                    "model_conf": 70.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.3,
                    "duration": "4 Months",
                    "model_conf": 92.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.64,
                    "duration": "indefinitely",
                    "model_conf": 149.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.98,
                    "duration": "2 Months",
                    "model_conf": 268.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.83,
                    "duration": "7 Months",
                    "model_conf": 149.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.26,
                    "duration": "indefinitely",
                    "model_conf": 149.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.66,
                    "duration": "3 Months",
                    "model_conf": 140.38
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.08,
                    "duration": "indefinitely",
                    "model_conf": 53.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 197.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/marin-city/160-donahue-street.html": {
        "name": "Sausalito",
        "address": "160 Donahue Street Suite I, Marin City Marin City, California, 94965",
        "phone": "(415) 339-9003",
        "url": "https://locations.massageenvy.com/california/marin-city/160-donahue-street.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "12:00 PM - 5:00 PM",
            "WEDNESDAY": "Closed",
            "THURSDAY": "12:00 PM - 5:00 PM",
            "FRIDAY": "12:00 PM - 5:00 PM",
            "SATURDAY": "10:00 AM - 1:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            37.87313368885004,
            -122.51135423619365
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.32,
                    "duration": "1 Months",
                    "model_conf": 71.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.23,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.47,
                    "duration": "2 Months",
                    "model_conf": 101.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.33,
                    "duration": "1 Months",
                    "model_conf": 154.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 183.65,
                    "duration": "2 Months",
                    "model_conf": 277.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.89,
                    "duration": "indefinitely",
                    "model_conf": 164.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.02,
                    "duration": "4 Months",
                    "model_conf": 166.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.41,
                    "duration": "indefinitely",
                    "model_conf": 155.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.69,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.23,
                    "duration": "indefinitely",
                    "model_conf": 85.33
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.83,
                    "duration": "indefinitely",
                    "model_conf": 55.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.07,
                    "duration": "indefinitely",
                    "model_conf": 217.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/menifee/30134-haun-road.html": {
        "name": "Menifee",
        "address": "30134 Haun Road Suite 430, Menifee Menifee, California, 92584",
        "phone": "(951) 821-3222",
        "url": "https://locations.massageenvy.com/california/menifee/30134-haun-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.68301814467515,
            -117.17453611408615
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.49,
                    "duration": "1 Months",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.34,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.73,
                    "duration": "1 Months",
                    "model_conf": 101.4
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.06,
                    "duration": "indefinitely",
                    "model_conf": 154.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.99,
                    "duration": "indefinitely",
                    "model_conf": 282.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.33,
                    "duration": "1 Months",
                    "model_conf": 165.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.01,
                    "duration": "indefinitely",
                    "model_conf": 166.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.45,
                    "duration": "1 Months",
                    "model_conf": 155.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.84,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 85.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.05,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.28,
                    "duration": "indefinitely",
                    "model_conf": 220.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/milpitas/581-e-calaveras-blvd.html": {
        "name": "Milpitas",
        "address": "581 E Calaveras Blvd, Milpitas Milpitas, California, 95035",
        "phone": "(408) 586-8888",
        "url": "https://locations.massageenvy.com/california/milpitas/581-e-calaveras-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.43553680308766,
            -121.8975844903174
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.13,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.1,
                    "duration": "2 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 99.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.61,
                    "duration": "indefinitely",
                    "model_conf": 154.3
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.76,
                    "duration": "indefinitely",
                    "model_conf": 277.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 164.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 162.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.57,
                    "duration": "indefinitely",
                    "model_conf": 155.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.56,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 85.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.75,
                    "duration": "indefinitely",
                    "model_conf": 219.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/mission-viejo/27620-marguerite-parkway.html": {
        "name": "Mission Viejo South",
        "address": "27620 Marguerite Parkway Ste A&B, Mission Viejo, California, 92692",
        "phone": "(949) 388-7300",
        "url": "https://locations.massageenvy.com/california/mission-viejo/27620-marguerite-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 5:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.562548294695056,
            -117.65716927349239
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 70.53
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 146.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.57,
                    "duration": "indefinitely",
                    "model_conf": 94.95
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 149.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 281.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 188.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.11,
                    "duration": "2 Months",
                    "model_conf": 165.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.9,
                    "duration": "indefinitely",
                    "model_conf": 164.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.34,
                    "duration": "1 Months",
                    "model_conf": 155.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.97,
                    "duration": "indefinitely",
                    "model_conf": 206.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.25,
                    "duration": "indefinitely",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.13,
                    "duration": "indefinitely",
                    "model_conf": 52.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.43,
                    "duration": "indefinitely",
                    "model_conf": 218.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/monrovia/123-w.-foothill-blvd.html": {
        "name": "Monrovia",
        "address": "123 W. Foothill Blvd, Monrovia, California, 91016",
        "phone": "(626) 930-0444",
        "url": "https://locations.massageenvy.com/california/monrovia/123-w.-foothill-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            34.151554,
            -118.001972
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.55,
                    "duration": "indefinitely",
                    "model_conf": 274.2
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.14,
                    "duration": "indefinitely",
                    "model_conf": 192.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.63,
                    "duration": "indefinitely",
                    "model_conf": 163.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.32,
                    "duration": "indefinitely",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.48,
                    "duration": "indefinitely",
                    "model_conf": 148.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.68,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.94,
                    "duration": "indefinitely",
                    "model_conf": 233.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.97,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.94,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.7,
                    "duration": "indefinitely",
                    "model_conf": 198.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/monterey/595-munras-avenue.html": {
        "name": "Monterey Downtown",
        "address": "595 Munras Avenue Suite 101, Monterey, California, 93940",
        "phone": "(831) 920-0272",
        "url": "https://locations.massageenvy.com/california/monterey/595-munras-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.59660684031706,
            -121.89367656805716
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.5,
                    "duration": "1 Months",
                    "model_conf": 71.37
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.97,
                    "duration": "indefinitely",
                    "model_conf": 158.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.14,
                    "duration": "2 Months",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.01,
                    "duration": "indefinitely",
                    "model_conf": 100.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.9,
                    "duration": "indefinitely",
                    "model_conf": 155.04
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.83,
                    "duration": "indefinitely",
                    "model_conf": 279.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.91,
                    "duration": "indefinitely",
                    "model_conf": 195.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.87,
                    "duration": "2 Months",
                    "model_conf": 166.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.41,
                    "duration": "indefinitely",
                    "model_conf": 168.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.19,
                    "duration": "1 Months",
                    "model_conf": 157.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/moraga/558-center-street.html": {
        "name": "Lamorinda",
        "address": "558 Center Street, Moraga, California, 94556",
        "phone": "(925) 376-3689",
        "url": "https://locations.massageenvy.com/california/moraga/558-center-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.8574659,
            -122.1260724
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.81,
                    "duration": "indefinitely",
                    "model_conf": 279.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.11,
                    "duration": "indefinitely",
                    "model_conf": 164.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.6,
                    "duration": "2 Months",
                    "model_conf": 166.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.65,
                    "duration": "indefinitely",
                    "model_conf": 156.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.29,
                    "duration": "1 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.21,
                    "duration": "indefinitely",
                    "model_conf": 85.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.46,
                    "duration": "1 Months",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/moreno-valley/27120-eucalyptus-ave.html": {
        "name": "Moreno Valley",
        "address": "27120 Eucalyptus Ave Suite A, Moreno Valley Moreno Valley, California, 92555",
        "phone": "(951) 221-9900",
        "url": "https://locations.massageenvy.com/california/moreno-valley/27120-eucalyptus-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.935795350776445,
            -117.18795742203838
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.29,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.81,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 100.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.66,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.44,
                    "duration": "indefinitely",
                    "model_conf": 281.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 166.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.38,
                    "duration": "indefinitely",
                    "model_conf": 168.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.47,
                    "duration": "indefinitely",
                    "model_conf": 157.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.27,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.07,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.56,
                    "duration": "indefinitely",
                    "model_conf": 219.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/morgan-hill/1049-cochrane-rd.html": {
        "name": "Morgan Hill",
        "address": "1049 Cochrane Rd Suite 150, Morgan Hill, California, 95037",
        "phone": "(408) 776-3689",
        "url": "https://locations.massageenvy.com/california/morgan-hill/1049-cochrane-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            37.15642386230759,
            -121.65181183680534
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.74,
                    "duration": "2 Months",
                    "model_conf": 279.19
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.27,
                    "duration": "1 Months",
                    "model_conf": 194.42
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.47,
                    "duration": "indefinitely",
                    "model_conf": 166.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.11,
                    "duration": "4 Months",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.13,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.47,
                    "duration": "3 Months",
                    "model_conf": 237.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.49,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.24,
                    "duration": "indefinitely",
                    "model_conf": 54.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.92,
                    "duration": "1 Months",
                    "model_conf": 218.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/mountain-view/1040-grant-road.html": {
        "name": "Mountain View",
        "address": "1040 Grant Road Ste 110, Mountain View Mountain View, California, 94040",
        "phone": "(650) 961-3689",
        "url": "https://locations.massageenvy.com/california/mountain-view/1040-grant-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.378934,
            -122.0755724
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.58,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.85,
                    "duration": "3 Months",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.2,
                    "duration": "4 Months",
                    "model_conf": 97.99
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.88,
                    "duration": "indefinitely",
                    "model_conf": 154.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.25,
                    "duration": "1 Months",
                    "model_conf": 263.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.13,
                    "duration": "indefinitely",
                    "model_conf": 162.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.82,
                    "duration": "7 Months",
                    "model_conf": 157.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.54,
                    "duration": "indefinitely",
                    "model_conf": 153.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.29,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.59,
                    "duration": "indefinitely",
                    "model_conf": 84.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.47,
                    "duration": "indefinitely",
                    "model_conf": 55.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.8,
                    "duration": "2 Months",
                    "model_conf": 213.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/murrieta/39400-murrieta-hot-springs-rd.html": {
        "name": "Murrieta",
        "address": "39400 Murrieta Hot Springs Rd Suite 114B, Murrieta, California, 92563",
        "phone": "(951) 698-2888",
        "url": "https://locations.massageenvy.com/california/murrieta/39400-murrieta-hot-springs-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.55506514940529,
            -117.15454762395684
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.78,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.71,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 102.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.92,
                    "duration": "indefinitely",
                    "model_conf": 153.49
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.41,
                    "duration": "indefinitely",
                    "model_conf": 282.45
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.17,
                    "duration": "2 Months",
                    "model_conf": 155.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.56,
                    "duration": "indefinitely",
                    "model_conf": 155.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.92,
                    "duration": "1 Months",
                    "model_conf": 155.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.97,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.66,
                    "duration": "indefinitely",
                    "model_conf": 84.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.7,
                    "duration": "1 Months",
                    "model_conf": 55.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.98,
                    "duration": "indefinitely",
                    "model_conf": 212.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/napa/308-soscol-ave.html": {
        "name": "Napa",
        "address": "308 Soscol Ave Suite E, Napa Napa, California, 94559",
        "phone": "(707) 255-8000",
        "url": "https://locations.massageenvy.com/california/napa/308-soscol-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.28617026129896,
            -122.27335895337296
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.27,
                    "duration": "indefinitely",
                    "model_conf": 71.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.78,
                    "duration": "3 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.5,
                    "duration": "2 Months",
                    "model_conf": 102.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.4,
                    "duration": "indefinitely",
                    "model_conf": 154.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.74,
                    "duration": "2 Months",
                    "model_conf": 283.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.34,
                    "duration": "indefinitely",
                    "model_conf": 166.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.52,
                    "duration": "2 Months",
                    "model_conf": 167.67
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.49,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.92,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.12,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.07,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.9,
                    "duration": "indefinitely",
                    "model_conf": 221.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/newport-beach/1052-irvine-ave.html": {
        "name": "Newport Beach At Westcliff",
        "address": "1052 Irvine Ave, Newport Beach Newport Beach, California, 92660",
        "phone": "(949) 200-3330",
        "url": "https://locations.massageenvy.com/california/newport-beach/1052-irvine-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.628407,
            -117.906078
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.89,
                    "duration": "indefinitely",
                    "model_conf": 278.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.32,
                    "duration": "indefinitely",
                    "model_conf": 188.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.5,
                    "duration": "1 Months",
                    "model_conf": 163.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.88,
                    "duration": "indefinitely",
                    "model_conf": 163.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.98,
                    "duration": "1 Months",
                    "model_conf": 153.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.29,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.86,
                    "duration": "indefinitely",
                    "model_conf": 224.59
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.5,
                    "duration": "1 Months",
                    "model_conf": 85.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.66,
                    "duration": "1 Months",
                    "model_conf": 54.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.5,
                    "duration": "indefinitely",
                    "model_conf": 217.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/north-hollywood/5077-lankershim-blvd..html": {
        "name": "North Hollywood",
        "address": "5077 Lankershim Blvd., North Hollywood, California, 91601",
        "phone": "(747) 214-0421",
        "url": "https://locations.massageenvy.com/california/north-hollywood/5077-lankershim-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.162628133096725,
            -118.37411894463457
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.46,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.52,
                    "duration": "1 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.02,
                    "duration": "5 Months",
                    "model_conf": 100.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.86,
                    "duration": "2 Months",
                    "model_conf": 154.04
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.16,
                    "duration": "1 Months",
                    "model_conf": 279.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.18,
                    "duration": "indefinitely",
                    "model_conf": 165.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.02,
                    "duration": "5 Months",
                    "model_conf": 167.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.23,
                    "duration": "indefinitely",
                    "model_conf": 155.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.11,
                    "duration": "indefinitely",
                    "model_conf": 140.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.28,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.09,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.89,
                    "duration": "1 Months",
                    "model_conf": 217.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/northridge/18665-devonshire-street.html": {
        "name": "Northridge",
        "address": "18665 Devonshire Street, Northridge, California, 91324",
        "phone": "(818) 360-3000",
        "url": "https://locations.massageenvy.com/california/northridge/18665-devonshire-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "11:00 AM - 5:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.2585612,
            -118.5392933
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.5,
                    "duration": "1 Months",
                    "model_conf": 281.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.06,
                    "duration": "indefinitely",
                    "model_conf": 166.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.72,
                    "duration": "1 Months",
                    "model_conf": 167.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.69,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.67,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.55,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.2,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.9,
                    "duration": "indefinitely",
                    "model_conf": 220.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/oceanside/2455-vista-way.html": {
        "name": "Carlsbad-Oceanside",
        "address": "2455 Vista Way Suite C, Oceanside, California, 92054",
        "phone": "(760) 439-1155",
        "url": "https://locations.massageenvy.com/california/oceanside/2455-vista-way.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 5:00 PM"
        },
        "geolocation": [
            33.182666,
            -117.33365
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.3,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.85,
                    "duration": "indefinitely",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.0,
                    "duration": "indefinitely",
                    "model_conf": 102.31
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.79,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.74,
                    "duration": "indefinitely",
                    "model_conf": 281.57
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.59,
                    "duration": "indefinitely",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.07,
                    "duration": "indefinitely",
                    "model_conf": 165.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.98,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.28,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 85.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.01,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.34,
                    "duration": "indefinitely",
                    "model_conf": 220.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/ontario/4150-e-4th-street.html": {
        "name": "Ontario",
        "address": "4150 E 4th Street Suite A, Ontario Ontario, California, 91764",
        "phone": "(909) 294-2222",
        "url": "https://locations.massageenvy.com/california/ontario/4150-e-4th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.076933,
            -117.5629914
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.24,
                    "duration": "indefinitely",
                    "model_conf": 70.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.1,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.01,
                    "duration": "indefinitely",
                    "model_conf": 99.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.99,
                    "duration": "indefinitely",
                    "model_conf": 153.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.65,
                    "duration": "indefinitely",
                    "model_conf": 281.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.63,
                    "duration": "1 Months",
                    "model_conf": 164.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.81,
                    "duration": "indefinitely",
                    "model_conf": 165.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.26,
                    "duration": "1 Months",
                    "model_conf": 153.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.88,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.66,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.64,
                    "duration": "1 Months",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.82,
                    "duration": "indefinitely",
                    "model_conf": 219.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/oxnard/655-town-center-dr.html": {
        "name": "Oxnard",
        "address": "655 Town Center Dr Suite 2200, Oxnard, California, 93036",
        "phone": "(805) 981-3689",
        "url": "https://locations.massageenvy.com/california/oxnard/655-town-center-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.24052682927368,
            -119.17862290674589
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.64,
                    "duration": "indefinitely",
                    "model_conf": 70.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.85,
                    "duration": "3 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.7,
                    "duration": "1 Months",
                    "model_conf": 101.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.44,
                    "duration": "indefinitely",
                    "model_conf": 154.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.33,
                    "duration": "indefinitely",
                    "model_conf": 282.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.68,
                    "duration": "indefinitely",
                    "model_conf": 156.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.94,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.5,
                    "duration": "indefinitely",
                    "model_conf": 218.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/palm-desert/72363-highway-111.html": {
        "name": "Palm Desert",
        "address": "72363 Highway 111 Suite A2, Palm Desert, California, 92260",
        "phone": "(760) 779-1954",
        "url": "https://locations.massageenvy.com/california/palm-desert/72363-highway-111.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.726696384459245,
            -116.40242483170424
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.4,
                    "duration": "indefinitely",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.99,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.37,
                    "duration": "indefinitely",
                    "model_conf": 100.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.87,
                    "duration": "indefinitely",
                    "model_conf": 154.81
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.54,
                    "duration": "indefinitely",
                    "model_conf": 282.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.42,
                    "duration": "indefinitely",
                    "model_conf": 166.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.44,
                    "duration": "indefinitely",
                    "model_conf": 165.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.9,
                    "duration": "indefinitely",
                    "model_conf": 156.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.5,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.21,
                    "duration": "indefinitely",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.95,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.58,
                    "duration": "indefinitely",
                    "model_conf": 221.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/palm-springs/5200-e-ramon-road.html": {
        "name": "Palm Springs",
        "address": "5200 E Ramon Road Ste H-11, Palm Springs, California, 92264",
        "phone": "(760) 969-4800",
        "url": "https://locations.massageenvy.com/california/palm-springs/5200-e-ramon-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.81611879055969,
            -116.49119609015635
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.36,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.91,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.56,
                    "duration": "indefinitely",
                    "model_conf": 101.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.02,
                    "duration": "indefinitely",
                    "model_conf": 154.9
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.28,
                    "duration": "indefinitely",
                    "model_conf": 281.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.77,
                    "duration": "indefinitely",
                    "model_conf": 166.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.42,
                    "duration": "indefinitely",
                    "model_conf": 157.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.58,
                    "duration": "indefinitely",
                    "model_conf": 140.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.56,
                    "duration": "indefinitely",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.98,
                    "duration": "indefinitely",
                    "model_conf": 221.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/palmdale/39445-10th-street-w.html": {
        "name": "Palmdale",
        "address": "39445 10th Street W Ste E-5, Palmdale, California, 93551",
        "phone": "(661) 526-0050",
        "url": "https://locations.massageenvy.com/california/palmdale/39445-10th-street-w.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:30 PM",
            "TUESDAY": "9:00 AM - 8:30 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 6:45 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:45 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            34.600141,
            -118.148132
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.26,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 102.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.36,
                    "duration": "indefinitely",
                    "model_conf": 282.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 165.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 167.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 157.61
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 218.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/palo-alto/4335-el-camino-real.html": {
        "name": "Palo Alto",
        "address": "4335 El Camino Real, Palo Alto, California, 94306",
        "phone": "(650) 305-3464",
        "url": "https://locations.massageenvy.com/california/palo-alto/4335-el-camino-real.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.404937,
            -122.118077
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.72,
                    "duration": "indefinitely",
                    "model_conf": 71.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 99.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.71,
                    "duration": "indefinitely",
                    "model_conf": 154.5
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.87,
                    "duration": "indefinitely",
                    "model_conf": 266.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.36,
                    "duration": "indefinitely",
                    "model_conf": 163.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 160.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.23,
                    "duration": "indefinitely",
                    "model_conf": 155.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.87,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 84.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.59,
                    "duration": "indefinitely",
                    "model_conf": 55.34
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.95,
                    "duration": "indefinitely",
                    "model_conf": 214.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/petaluma/247-north-mcdowell-blvd.html": {
        "name": "Petaluma",
        "address": "247 North Mcdowell Blvd, Petaluma, California, 94954",
        "phone": "(707) 763-6274",
        "url": "https://locations.massageenvy.com/california/petaluma/247-north-mcdowell-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            38.249100999999996,
            -122.63004409325407
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.53,
                    "duration": "indefinitely",
                    "model_conf": 71.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.64,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.19,
                    "duration": "indefinitely",
                    "model_conf": 102.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.03,
                    "duration": "1 Months",
                    "model_conf": 154.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.93,
                    "duration": "indefinitely",
                    "model_conf": 281.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.9,
                    "duration": "indefinitely",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.74,
                    "duration": "1 Months",
                    "model_conf": 167.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.59,
                    "duration": "indefinitely",
                    "model_conf": 155.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 183.97,
                    "duration": "2 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.72,
                    "duration": "indefinitely",
                    "model_conf": 85.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.75,
                    "duration": "1 Months",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.37,
                    "duration": "1 Months",
                    "model_conf": 217.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/placentia/122-east-yorba-linda-boulevard.html": {
        "name": "Placentia",
        "address": "122 East Yorba Linda Boulevard, Placentia Placentia, California, 92870",
        "phone": "(714) 528-3689",
        "url": "https://locations.massageenvy.com/california/placentia/122-east-yorba-linda-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.886183,
            -117.865385
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.41,
                    "duration": "indefinitely",
                    "model_conf": 246.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.79,
                    "duration": "indefinitely",
                    "model_conf": 166.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.59,
                    "duration": "indefinitely",
                    "model_conf": 152.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.97,
                    "duration": "5 Months",
                    "model_conf": 136.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.59,
                    "duration": "indefinitely",
                    "model_conf": 153.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.28,
                    "duration": "indefinitely",
                    "model_conf": 140.36
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.65,
                    "duration": "indefinitely",
                    "model_conf": 179.55
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.49,
                    "duration": "1 Months",
                    "model_conf": 79.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.56,
                    "duration": "2 Months",
                    "model_conf": 49.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.96,
                    "duration": "1 Months",
                    "model_conf": 175.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/point-loma/2560-laning-rd-ste.html": {
        "name": "Point Loma",
        "address": "2560 Laning Rd Ste Ste A103, Point Loma, California, 92106",
        "phone": "(619) 222-0300",
        "url": "https://locations.massageenvy.com/california/point-loma/2560-laning-rd-ste.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.72875467329827,
            -117.21781803672457
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.98,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 98.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.7,
                    "duration": "indefinitely",
                    "model_conf": 154.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.61,
                    "duration": "indefinitely",
                    "model_conf": 270.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 161.37
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 162.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.84,
                    "duration": "indefinitely",
                    "model_conf": 151.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.12,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 84.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.34,
                    "duration": "indefinitely",
                    "model_conf": 209.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/rancho-cucamonga/7305-day-creek-blvd.html": {
        "name": "Rancho Cucamonga",
        "address": "7305 Day Creek Blvd Ste 101, Rancho Cucamonga Rancho Cucamonga, California, 91739",
        "phone": "(909) 803-1001",
        "url": "https://locations.massageenvy.com/california/rancho-cucamonga/7305-day-creek-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.121007,
            -117.536432
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.54,
                    "duration": "indefinitely",
                    "model_conf": 70.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.39,
                    "duration": "indefinitely",
                    "model_conf": 99.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.04,
                    "duration": "indefinitely",
                    "model_conf": 153.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.24,
                    "duration": "indefinitely",
                    "model_conf": 281.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.1,
                    "duration": "2 Months",
                    "model_conf": 164.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.29,
                    "duration": "indefinitely",
                    "model_conf": 165.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.5,
                    "duration": "2 Months",
                    "model_conf": 154.27
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.48,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.25,
                    "duration": "2 Months",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.33,
                    "duration": "3 Months",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.7,
                    "duration": "indefinitely",
                    "model_conf": 220.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/redding/1040-e-cypress-ave.html": {
        "name": "Redding",
        "address": "1040 E Cypress Ave Unit D, Redding, California, 96002",
        "phone": "(530) 221-8585",
        "url": "https://locations.massageenvy.com/california/redding/1040-e-cypress-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.571379240534945,
            -122.3500641070434
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.27,
                    "duration": "indefinitely",
                    "model_conf": 282.07
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.87,
                    "duration": "indefinitely",
                    "model_conf": 190.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.4,
                    "duration": "indefinitely",
                    "model_conf": 165.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.97,
                    "duration": "indefinitely",
                    "model_conf": 168.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.57,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.09,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.26,
                    "duration": "indefinitely",
                    "model_conf": 236.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.78,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.91,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.11,
                    "duration": "indefinitely",
                    "model_conf": 214.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/redlands/9990-alabama-st.html": {
        "name": "Redlands",
        "address": "9990 Alabama St Ste C&D, Redlands Redlands, California, 92374",
        "phone": "(909) 792-2277",
        "url": "https://locations.massageenvy.com/california/redlands/9990-alabama-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 3:00 PM"
        },
        "geolocation": [
            34.072261512413135,
            -117.20947091843738
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.42,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.66,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.43,
                    "duration": "indefinitely",
                    "model_conf": 102.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.94,
                    "duration": "indefinitely",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.49,
                    "duration": "indefinitely",
                    "model_conf": 281.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.54,
                    "duration": "1 Months",
                    "model_conf": 166.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.95,
                    "duration": "indefinitely",
                    "model_conf": 168.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.6,
                    "duration": "indefinitely",
                    "model_conf": 157.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.65,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.99,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.85,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.49,
                    "duration": "indefinitely",
                    "model_conf": 221.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/redwood-city/326-walnut-st.html": {
        "name": "Redwood City",
        "address": "326 Walnut St, Redwood City, California, 94063",
        "phone": "(650) 569-3689",
        "url": "https://locations.massageenvy.com/california/redwood-city/326-walnut-st.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.491206,
            -122.224795
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.75,
                    "duration": "indefinitely",
                    "model_conf": 71.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.71,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.05,
                    "duration": "indefinitely",
                    "model_conf": 101.43
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.6,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.29,
                    "duration": "indefinitely",
                    "model_conf": 271.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.78,
                    "duration": "indefinitely",
                    "model_conf": 165.73
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.8,
                    "duration": "indefinitely",
                    "model_conf": 164.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.46,
                    "duration": "indefinitely",
                    "model_conf": 156.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.61,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 84.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.43,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 212.86,
                    "duration": "indefinitely",
                    "model_conf": 220.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/rolling-hills-estates/887-silver-spur-rd.html": {
        "name": "Rolling Hills",
        "address": "887 Silver Spur Rd, Rolling Hills Estates Palos Verdes Peninsula, California, 90274",
        "phone": "(310) 698-0660",
        "url": "https://locations.massageenvy.com/california/rolling-hills-estates/887-silver-spur-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.7694175,
            -118.3671706
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 70.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 149.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 95.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.41,
                    "duration": "indefinitely",
                    "model_conf": 150.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 273.24
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 183.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 159.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 154.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.69,
                    "duration": "indefinitely",
                    "model_conf": 151.2
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.21,
                    "duration": "2 Months",
                    "model_conf": 140.43
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.16,
                    "duration": "indefinitely",
                    "model_conf": 226.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 54.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.49,
                    "duration": "indefinitely",
                    "model_conf": 201.18
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-francisco/785-mission-street.html": {
        "name": "San Francisco-Metreon",
        "address": "785 Mission Street, San Francisco, California, 94103",
        "phone": "(415) 341-9800",
        "url": "https://locations.massageenvy.com/california/san-francisco/785-mission-street.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 7:00 PM",
            "TUESDAY": "11:00 AM - 7:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 7:00 PM",
            "FRIDAY": "11:00 AM - 7:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            37.7846906,
            -122.4036205
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "180",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.97,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.49,
                    "duration": "1 Months",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.46,
                    "duration": "3 Months",
                    "model_conf": 101.02
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.02,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 162.85,
                    "duration": "5 Months",
                    "model_conf": 261.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 159.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.46,
                    "duration": "3 Months",
                    "model_conf": 159.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.63,
                    "duration": "indefinitely",
                    "model_conf": 157.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.27,
                    "duration": "1 Months",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.87,
                    "duration": "indefinitely",
                    "model_conf": 83.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.39,
                    "duration": "indefinitely",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.22,
                    "duration": "indefinitely",
                    "model_conf": 210.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-marcos/145-las-posas-rd.html": {
        "name": "San Marcos",
        "address": "145 Las Posas Rd Ste 180, San Marcos, California, 92078",
        "phone": "(760) 410-0288",
        "url": "https://locations.massageenvy.com/california/san-marcos/145-las-posas-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.14079047989503,
            -117.19094758482859
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 69.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.57,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.7,
                    "duration": "6 Months",
                    "model_conf": 102.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.8,
                    "duration": "indefinitely",
                    "model_conf": 152.48
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.45,
                    "duration": "7 Months",
                    "model_conf": 277.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.75,
                    "duration": "indefinitely",
                    "model_conf": 163.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.03,
                    "duration": "10 Months",
                    "model_conf": 145.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.09,
                    "duration": "indefinitely",
                    "model_conf": 131.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.48,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.41,
                    "duration": "indefinitely",
                    "model_conf": 84.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.69,
                    "duration": "indefinitely",
                    "model_conf": 55.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.86,
                    "duration": "3 Months",
                    "model_conf": 216.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-mateo/1100-park-place.html": {
        "name": "San Mateo Bay Meadows",
        "address": "1100 Park Place Suite 40, San Mateo, California, 94403",
        "phone": "(650) 393-6477",
        "url": "https://locations.massageenvy.com/california/san-mateo/1100-park-place.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.545085592886245,
            -122.29101374745369
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.68,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.34,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.02,
                    "duration": "indefinitely",
                    "model_conf": 101.96
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 232.24,
                    "duration": "indefinitely",
                    "model_conf": 154.63
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.38,
                    "duration": "indefinitely",
                    "model_conf": 272.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.44,
                    "duration": "3 Months",
                    "model_conf": 165.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.57,
                    "duration": "indefinitely",
                    "model_conf": 165.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.51,
                    "duration": "indefinitely",
                    "model_conf": 155.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.39,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.82,
                    "duration": "indefinitely",
                    "model_conf": 83.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 54.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 232.78,
                    "duration": "indefinitely",
                    "model_conf": 219.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-rafael/219-3rd-st.html": {
        "name": "San Rafael",
        "address": "219 3rd St, San Rafael, California, 94901",
        "phone": "(415) 459-3689",
        "url": "https://locations.massageenvy.com/california/san-rafael/219-3rd-st.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            37.9696961,
            -122.515547
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.34,
                    "duration": "1 Months",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.6,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.97,
                    "duration": "indefinitely",
                    "model_conf": 102.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.61,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.25,
                    "duration": "1 Months",
                    "model_conf": 280.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.35,
                    "duration": "indefinitely",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.11,
                    "duration": "4 Months",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.79,
                    "duration": "indefinitely",
                    "model_conf": 156.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.97,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.96,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.97,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.44,
                    "duration": "indefinitely",
                    "model_conf": 218.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/santa-barbara/136-s.-hope-ave.html": {
        "name": "Santa Barbara",
        "address": "136 S. Hope Ave B107, Santa Barbara, California, 93105",
        "phone": "(805) 681-5050",
        "url": "https://locations.massageenvy.com/california/santa-barbara/136-s.-hope-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            34.43701711949535,
            -119.74822722561116
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.32,
                    "duration": "indefinitely",
                    "model_conf": 282.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.87,
                    "duration": "indefinitely",
                    "model_conf": 168.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.39,
                    "duration": "indefinitely",
                    "model_conf": 157.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.13,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.4,
                    "duration": "indefinitely",
                    "model_conf": 84.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.7,
                    "duration": "indefinitely",
                    "model_conf": 221.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/santa-clara/2066-el-camino-real-e-09.html": {
        "name": "Santa Clara Town Centre",
        "address": "2066 El Camino Real E-09, Santa Clara Santa Clara, California, 95050",
        "phone": "(408) 243-1238",
        "url": "https://locations.massageenvy.com/california/santa-clara/2066-el-camino-real-e-09.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            37.35004891538201,
            -121.96242570877075
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.32,
                    "duration": "indefinitely",
                    "model_conf": 70.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.25,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 91.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.17,
                    "duration": "indefinitely",
                    "model_conf": 153.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.37,
                    "duration": "indefinitely",
                    "model_conf": 269.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 159.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.36,
                    "duration": "indefinitely",
                    "model_conf": 147.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.18,
                    "duration": "indefinitely",
                    "model_conf": 151.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.11,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 84.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.36,
                    "duration": "indefinitely",
                    "model_conf": 55.0
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.85,
                    "duration": "indefinitely",
                    "model_conf": 214.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/santa-rosa/5761-mountain-hawk-way.html": {
        "name": "Santa Rosa",
        "address": "5761 Mountain Hawk Way, Santa Rosa Santa Rosa, California, 95409",
        "phone": "(707) 537-0555",
        "url": "https://locations.massageenvy.com/california/santa-rosa/5761-mountain-hawk-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.46322349151561,
            -122.64424245096353
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.8,
                    "duration": "indefinitely",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.37,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.22,
                    "duration": "indefinitely",
                    "model_conf": 102.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.09,
                    "duration": "1 Months",
                    "model_conf": 154.03
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.61,
                    "duration": "indefinitely",
                    "model_conf": 281.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.75,
                    "duration": "indefinitely",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.54,
                    "duration": "2 Months",
                    "model_conf": 167.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.19,
                    "duration": "1 Months",
                    "model_conf": 156.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.29,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.7,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.89,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.68,
                    "duration": "indefinitely",
                    "model_conf": 220.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/santee/9824-mission-gorge-road.html": {
        "name": "Santee",
        "address": "9824 Mission Gorge Road Ste D, Santee, California, 92071",
        "phone": "(619) 448-3689",
        "url": "https://locations.massageenvy.com/california/santee/9824-mission-gorge-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.8405568,
            -116.9818619
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.28,
                    "duration": "indefinitely",
                    "model_conf": 274.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.43,
                    "duration": "indefinitely",
                    "model_conf": 192.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.48,
                    "duration": "indefinitely",
                    "model_conf": 163.74
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.67,
                    "duration": "indefinitely",
                    "model_conf": 164.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.48,
                    "duration": "indefinitely",
                    "model_conf": 151.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.76,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.94,
                    "duration": "indefinitely",
                    "model_conf": 228.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.36,
                    "duration": "indefinitely",
                    "model_conf": 84.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.31,
                    "duration": "indefinitely",
                    "model_conf": 55.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.85,
                    "duration": "indefinitely",
                    "model_conf": 214.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/seal-beach/12340-seal-beach-blvd.html": {
        "name": "Seal Beach",
        "address": "12340 Seal Beach Blvd Ste A, Seal Beach Seal Beach, California, 90740",
        "phone": "(562) 596-1500",
        "url": "https://locations.massageenvy.com/california/seal-beach/12340-seal-beach-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.782511,
            -118.071583
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.85,
                    "duration": "indefinitely",
                    "model_conf": 70.96
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.17,
                    "duration": "2 Months",
                    "model_conf": 155.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.58,
                    "duration": "1 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.51,
                    "duration": "3 Months",
                    "model_conf": 90.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.84,
                    "duration": "2 Months",
                    "model_conf": 153.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.77,
                    "duration": "indefinitely",
                    "model_conf": 271.33
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.69,
                    "duration": "1 Months",
                    "model_conf": 184.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.84,
                    "duration": "indefinitely",
                    "model_conf": 162.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.46,
                    "duration": "4 Months",
                    "model_conf": 157.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.02,
                    "duration": "indefinitely",
                    "model_conf": 156.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.53,
                    "duration": "indefinitely",
                    "model_conf": 140.58
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.26,
                    "duration": "2 Months",
                    "model_conf": 225.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.81,
                    "duration": "indefinitely",
                    "model_conf": 84.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.06,
                    "duration": "indefinitely",
                    "model_conf": 54.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.98,
                    "duration": "2 Months",
                    "model_conf": 213.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sherman-oaks/14652-ventura-blvd.html": {
        "name": "Sherman Oaks",
        "address": "14652 Ventura Blvd Ste 100, Sherman Oaks, California, 91403",
        "phone": "(818) 986-3689",
        "url": "https://locations.massageenvy.com/california/sherman-oaks/14652-ventura-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 6:30 PM",
            "FRIDAY": "9:30 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:30 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.151398,
            -118.452734
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.56,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.51,
                    "duration": "1 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.97,
                    "duration": "5 Months",
                    "model_conf": 100.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.93,
                    "duration": "1 Months",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.23,
                    "duration": "3 Months",
                    "model_conf": 280.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.64,
                    "duration": "indefinitely",
                    "model_conf": 166.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.95,
                    "duration": "5 Months",
                    "model_conf": 167.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.54,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.46,
                    "duration": "1 Months",
                    "model_conf": 140.59
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.96,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.92,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.13,
                    "duration": "3 Months",
                    "model_conf": 219.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/simi-valley/1263-simi-town-center-hwy.html": {
        "name": "Simi Valley",
        "address": "1263 Simi Town Center Hwy Suite H-7, Simi Valley, California, 93065",
        "phone": "(805) 210-3689",
        "url": "https://locations.massageenvy.com/california/simi-valley/1263-simi-town-center-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "11:00 AM - 7:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 7:00 PM",
            "FRIDAY": "11:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            34.284152,
            -118.774852
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.41,
                    "duration": "indefinitely",
                    "model_conf": 70.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.38,
                    "duration": "2 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.77,
                    "duration": "indefinitely",
                    "model_conf": 101.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.29,
                    "duration": "indefinitely",
                    "model_conf": 154.78
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.86,
                    "duration": "indefinitely",
                    "model_conf": 282.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.18,
                    "duration": "2 Months",
                    "model_conf": 166.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.29,
                    "duration": "indefinitely",
                    "model_conf": 167.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.47,
                    "duration": "indefinitely",
                    "model_conf": 156.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.32,
                    "duration": "1 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 85.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.63,
                    "duration": "2 Months",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 212.16,
                    "duration": "indefinitely",
                    "model_conf": 219.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/south-pasadena/1136-fair-oaks-avenue.html": {
        "name": "South Pasadena",
        "address": "1136 Fair Oaks Avenue, South Pasadena, California, 91030",
        "phone": "(626) 403-6000",
        "url": "https://locations.massageenvy.com/california/south-pasadena/1136-fair-oaks-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            34.112522,
            -118.150133
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.83,
                    "duration": "indefinitely",
                    "model_conf": 270.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.38,
                    "duration": "indefinitely",
                    "model_conf": 191.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.58,
                    "duration": "1 Months",
                    "model_conf": 161.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.3,
                    "duration": "indefinitely",
                    "model_conf": 166.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.2,
                    "duration": "1 Months",
                    "model_conf": 144.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.39,
                    "duration": "indefinitely",
                    "model_conf": 140.25
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.18,
                    "duration": "indefinitely",
                    "model_conf": 232.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.32,
                    "duration": "1 Months",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 55.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.02,
                    "duration": "indefinitely",
                    "model_conf": 186.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/stockton/5757-pacific-avenue-store.html": {
        "name": "Stockton-StoneCreek Village",
        "address": "5757 Pacific Avenue Store #A155, Stockton Stockton, California, 95207",
        "phone": "(209) 478-3689",
        "url": "https://locations.massageenvy.com/california/stockton/5757-pacific-avenue-store.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.00193543695437,
            -121.31961810960775
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.37,
                    "duration": "1 Months",
                    "model_conf": 283.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 168.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.08,
                    "duration": "indefinitely",
                    "model_conf": 156.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.81,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.73,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.64,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.66,
                    "duration": "indefinitely",
                    "model_conf": 220.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sunnyvale/413-e-el-camino-real.html": {
        "name": "Sunnyvale",
        "address": "413 E El Camino Real, Sunnyvale, California, 94087",
        "phone": "(408) 737-9888",
        "url": "https://locations.massageenvy.com/california/sunnyvale/413-e-el-camino-real.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            37.364866,
            -122.02819
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.42,
                    "duration": "indefinitely",
                    "model_conf": 70.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.61,
                    "duration": "4 Months",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.4,
                    "duration": "3 Months",
                    "model_conf": 94.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.86,
                    "duration": "indefinitely",
                    "model_conf": 153.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.59,
                    "duration": "indefinitely",
                    "model_conf": 263.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.15,
                    "duration": "indefinitely",
                    "model_conf": 161.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 151.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.54,
                    "duration": "indefinitely",
                    "model_conf": 152.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.37,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 83.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 54.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.56,
                    "duration": "indefinitely",
                    "model_conf": 213.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/temecula/32909-highway-79-south.html": {
        "name": "Temecula",
        "address": "32909 Highway 79 South Ste 105, Temecula, California, 92592",
        "phone": "(951) 302-6002",
        "url": "https://locations.massageenvy.com/california/temecula/32909-highway-79-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            33.48405131153942,
            -117.08150138292433
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.3,
                    "duration": "indefinitely",
                    "model_conf": 71.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.19,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.43,
                    "duration": "indefinitely",
                    "model_conf": 101.8
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.3,
                    "duration": "indefinitely",
                    "model_conf": 154.03
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 167.19,
                    "duration": "indefinitely",
                    "model_conf": 281.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.51,
                    "duration": "1 Months",
                    "model_conf": 157.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.05,
                    "duration": "indefinitely",
                    "model_conf": 160.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.16,
                    "duration": "1 Months",
                    "model_conf": 156.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.85,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.82,
                    "duration": "indefinitely",
                    "model_conf": 84.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.84,
                    "duration": "indefinitely",
                    "model_conf": 55.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.57,
                    "duration": "indefinitely",
                    "model_conf": 214.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/thousand-oaks/1348-a-n-moorpark-road.html": {
        "name": "Thousand Oaks",
        "address": "1348-A N Moorpark Road Suite A, Thousand Oaks Thousand Oaks, California, 91360",
        "phone": "(805) 494-1448",
        "url": "https://locations.massageenvy.com/california/thousand-oaks/1348-a-n-moorpark-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 5:00 PM",
            "THURSDAY": "9:00 AM - 5:00 PM",
            "FRIDAY": "9:00 AM - 7:30 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 5:30 PM"
        },
        "geolocation": [
            34.196394413593836,
            -118.86855676705494
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.42,
                    "duration": "indefinitely",
                    "model_conf": 70.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.59,
                    "duration": "indefinitely",
                    "model_conf": 101.12
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.07,
                    "duration": "indefinitely",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.45,
                    "duration": "indefinitely",
                    "model_conf": 282.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.11,
                    "duration": "2 Months",
                    "model_conf": 166.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.58,
                    "duration": "indefinitely",
                    "model_conf": 167.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.63,
                    "duration": "indefinitely",
                    "model_conf": 157.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.34,
                    "duration": "1 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.37,
                    "duration": "indefinitely",
                    "model_conf": 85.36
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.75,
                    "duration": "1 Months",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.85,
                    "duration": "indefinitely",
                    "model_conf": 219.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/tracy/2463-naglee-rd.html": {
        "name": "Tracy Pavillion",
        "address": "2463 Naglee Rd, Tracy, California, 95304",
        "phone": "(209) 839-1919",
        "url": "https://locations.massageenvy.com/california/tracy/2463-naglee-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.756428,
            -121.466186
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.79,
                    "duration": "indefinitely",
                    "model_conf": 102.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.46,
                    "duration": "indefinitely",
                    "model_conf": 154.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 281.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.27,
                    "duration": "2 Months",
                    "model_conf": 166.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 167.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 157.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.59,
                    "duration": "indefinitely",
                    "model_conf": 220.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/tustin/2833-park-avenue.html": {
        "name": "Tustin",
        "address": "2833 Park Avenue, Tustin Tustin, California, 92782",
        "phone": "(714) 617-8900",
        "url": "https://locations.massageenvy.com/california/tustin/2833-park-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.6968491,
            -117.8254982
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.14,
                    "duration": "indefinitely",
                    "model_conf": 70.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 96.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.56,
                    "duration": "2 Months",
                    "model_conf": 152.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.09,
                    "duration": "indefinitely",
                    "model_conf": 275.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.44,
                    "duration": "indefinitely",
                    "model_conf": 162.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 161.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.98,
                    "duration": "2 Months",
                    "model_conf": 154.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.05,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 84.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 53.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.8,
                    "duration": "indefinitely",
                    "model_conf": 212.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/union-city/30963-courthouse-dr.html": {
        "name": "Union Landing",
        "address": "30963 Courthouse Dr, Union City, California, 94587",
        "phone": "(510) 471-5000",
        "url": "https://locations.massageenvy.com/california/union-city/30963-courthouse-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "Closed",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.603057,
            -122.0680949
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.17,
                    "duration": "2 Months",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.85,
                    "duration": "indefinitely",
                    "model_conf": 101.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.8,
                    "duration": "indefinitely",
                    "model_conf": 154.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.26,
                    "duration": "indefinitely",
                    "model_conf": 275.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.45,
                    "duration": "1 Months",
                    "model_conf": 165.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.35,
                    "duration": "indefinitely",
                    "model_conf": 165.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.91,
                    "duration": "indefinitely",
                    "model_conf": 155.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.44,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.95,
                    "duration": "indefinitely",
                    "model_conf": 84.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.01,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.41,
                    "duration": "indefinitely",
                    "model_conf": 215.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/upland/1900-campus-ave.html": {
        "name": "Upland",
        "address": "1900 Campus Ave Ste C, Upland, California, 91784",
        "phone": "(909) 946-9996",
        "url": "https://locations.massageenvy.com/california/upland/1900-campus-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.131445,
            -117.639178
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 105.925,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": null,
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": null,
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.63,
                    "duration": "indefinitely",
                    "model_conf": 280.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.52,
                    "duration": "indefinitely",
                    "model_conf": 163.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.16,
                    "duration": "indefinitely",
                    "model_conf": 164.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.13,
                    "duration": "indefinitely",
                    "model_conf": 151.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/vacaville/196-nut-tree-parkway.html": {
        "name": "Vacaville",
        "address": "196 Nut Tree Parkway Suite C, Vacaville, California, 95687",
        "phone": "(707) 446-4600",
        "url": "https://locations.massageenvy.com/california/vacaville/196-nut-tree-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            38.362043484523355,
            -121.96714278077117
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.71,
                    "duration": "indefinitely",
                    "model_conf": 283.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.68,
                    "duration": "3 Months",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.34,
                    "duration": "indefinitely",
                    "model_conf": 167.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.04,
                    "duration": "1 Months",
                    "model_conf": 157.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.81,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.5,
                    "duration": "1 Months",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.85,
                    "duration": "indefinitely",
                    "model_conf": 221.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/valencia/23957-newhall-ranch-road.html": {
        "name": "Valencia",
        "address": "23957 Newhall Ranch Road, Valencia, California, 91354",
        "phone": "(661) 259-0878",
        "url": "https://locations.massageenvy.com/california/valencia/23957-newhall-ranch-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:30 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.4300548,
            -118.5549449
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.1,
                    "duration": "indefinitely",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.84,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.33,
                    "duration": "4 Months",
                    "model_conf": 102.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.26,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.16,
                    "duration": "indefinitely",
                    "model_conf": 282.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.61,
                    "duration": "1 Months",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.28,
                    "duration": "4 Months",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.75,
                    "duration": "indefinitely",
                    "model_conf": 157.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.61,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.19,
                    "duration": "indefinitely",
                    "model_conf": 55.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.3,
                    "duration": "indefinitely",
                    "model_conf": 219.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/visalia/4222-s-mooney-blvd.html": {
        "name": "Visalia",
        "address": "4222 S Mooney Blvd Suite G5-6, Visalia, California, 93277",
        "phone": "(559) 702-1166",
        "url": "https://locations.massageenvy.com/california/visalia/4222-s-mooney-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.291659,
            -119.3124207
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.42,
                    "duration": "indefinitely",
                    "model_conf": 283.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.68,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.88,
                    "duration": "indefinitely",
                    "model_conf": 168.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.68,
                    "duration": "indefinitely",
                    "model_conf": 157.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.42,
                    "duration": "1 Months",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.51,
                    "duration": "indefinitely",
                    "model_conf": 220.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/west-covina/2620-e-workman-ave.html": {
        "name": "West Covina-Eastland Center",
        "address": "2620 E Workman Ave Ste 4B, West Covina West Covina, California, 91791",
        "phone": "(626) 966-2220",
        "url": "https://locations.massageenvy.com/california/west-covina/2620-e-workman-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            34.07447557754179,
            -117.88942838171221
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.28,
                    "duration": "indefinitely",
                    "model_conf": 279.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 191.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.48,
                    "duration": "1 Months",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.32,
                    "duration": "indefinitely",
                    "model_conf": 164.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.64,
                    "duration": "indefinitely",
                    "model_conf": 154.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.11,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 232.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 85.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 55.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.81,
                    "duration": "indefinitely",
                    "model_conf": 215.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/whittier/15632-whitwood-lane.html": {
        "name": "Whittier",
        "address": "15632 Whitwood Lane, Whittier, California, 90603",
        "phone": "(562) 698-5000",
        "url": "https://locations.massageenvy.com/california/whittier/15632-whitwood-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.941893,
            -117.995303
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.38,
                    "duration": "indefinitely",
                    "model_conf": 271.6
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.26,
                    "duration": "indefinitely",
                    "model_conf": 187.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.27,
                    "duration": "1 Months",
                    "model_conf": 161.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.53,
                    "duration": "indefinitely",
                    "model_conf": 159.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.64,
                    "duration": "indefinitely",
                    "model_conf": 154.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.0,
                    "duration": "indefinitely",
                    "model_conf": 140.52
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.52,
                    "duration": "indefinitely",
                    "model_conf": 223.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.07,
                    "duration": "indefinitely",
                    "model_conf": 83.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.98,
                    "duration": "indefinitely",
                    "model_conf": 54.34
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.82,
                    "duration": "indefinitely",
                    "model_conf": 210.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/wildomar/23885-clinton-keith-rd.html": {
        "name": "Wildomar At Clinton Keith",
        "address": "23885 Clinton Keith Rd Ste #1, Wildomar, California, 92595",
        "phone": "(951) 677-7781",
        "url": "https://locations.massageenvy.com/california/wildomar/23885-clinton-keith-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.5950016466015,
            -117.24158457546312
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.91,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.07,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.56,
                    "duration": "indefinitely",
                    "model_conf": 101.61
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.14,
                    "duration": "indefinitely",
                    "model_conf": 154.51
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.72,
                    "duration": "indefinitely",
                    "model_conf": 282.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.4,
                    "duration": "1 Months",
                    "model_conf": 163.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.03,
                    "duration": "indefinitely",
                    "model_conf": 164.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.23,
                    "duration": "1 Months",
                    "model_conf": 155.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.65,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.65,
                    "duration": "indefinitely",
                    "model_conf": 85.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.67,
                    "duration": "1 Months",
                    "model_conf": 55.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.12,
                    "duration": "indefinitely",
                    "model_conf": 218.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/yorba-linda/20499-yorba-linda-blvd.html": {
        "name": "Yorba Linda",
        "address": "20499 Yorba Linda Blvd, Yorba Linda, California, 92886",
        "phone": "(714) 701-0200",
        "url": "https://locations.massageenvy.com/california/yorba-linda/20499-yorba-linda-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.8929286,
            -117.7739141
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.03,
                    "duration": "indefinitely",
                    "model_conf": 254.05
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.07,
                    "duration": "indefinitely",
                    "model_conf": 172.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.09,
                    "duration": "indefinitely",
                    "model_conf": 156.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.93,
                    "duration": "indefinitely",
                    "model_conf": 142.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.99,
                    "duration": "indefinitely",
                    "model_conf": 153.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.36,
                    "duration": "indefinitely",
                    "model_conf": 140.63
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.32,
                    "duration": "indefinitely",
                    "model_conf": 193.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.09,
                    "duration": "indefinitely",
                    "model_conf": 80.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.15,
                    "duration": "indefinitely",
                    "model_conf": 51.09
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.14,
                    "duration": "indefinitely",
                    "model_conf": 186.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/arvada/15530-w-64th-ave.html": {
        "name": "Arvada West",
        "address": "15530 W 64th Ave Ste M, Arvada Arvada, Colorado, 80007",
        "phone": "(303) 423-3689",
        "url": "https://locations.massageenvy.com/colorado/arvada/15530-w-64th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.810450054769575,
            -105.17325548475185
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.48,
                    "duration": "indefinitely",
                    "model_conf": 266.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.03,
                    "duration": "indefinitely",
                    "model_conf": 189.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.22,
                    "duration": "7 Months",
                    "model_conf": 165.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.51,
                    "duration": "indefinitely",
                    "model_conf": 164.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.08,
                    "duration": "3 Months",
                    "model_conf": 156.46
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.81,
                    "duration": "2 Months",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.02,
                    "duration": "indefinitely",
                    "model_conf": 225.56
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.3,
                    "duration": "1 Months",
                    "model_conf": 84.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.58,
                    "duration": "2 Months",
                    "model_conf": 55.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.88,
                    "duration": "indefinitely",
                    "model_conf": 216.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/boulder/2490-arapahoe-avenue.html": {
        "name": "Boulder",
        "address": "2490 Arapahoe Avenue, Boulder Boulder, Colorado, 80302",
        "phone": "(303) 447-3689",
        "url": "https://locations.massageenvy.com/colorado/boulder/2490-arapahoe-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            40.01430100000001,
            -105.26209709325406
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.62,
                    "duration": "5 Months",
                    "model_conf": 271.04
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.33,
                    "duration": "4 Months",
                    "model_conf": 181.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.63,
                    "duration": "indefinitely",
                    "model_conf": 166.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.09,
                    "duration": "4 Months",
                    "model_conf": 161.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.96,
                    "duration": "indefinitely",
                    "model_conf": 155.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.97,
                    "duration": "2 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.82,
                    "duration": "5 Months",
                    "model_conf": 231.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.08,
                    "duration": "indefinitely",
                    "model_conf": 83.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.0,
                    "duration": "indefinitely",
                    "model_conf": 54.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.46,
                    "duration": "2 Months",
                    "model_conf": 213.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/castle-rock/5650-allen-way.html": {
        "name": "Castle Rock",
        "address": "5650 Allen Way #116, Castle Rock, Colorado, 80108",
        "phone": "(720) 974-9100",
        "url": "https://locations.massageenvy.com/colorado/castle-rock/5650-allen-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.414940635765156,
            -104.86264834106358
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.99,
                    "duration": "2 Months",
                    "model_conf": 278.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.19,
                    "duration": "2 Months",
                    "model_conf": 191.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.86,
                    "duration": "indefinitely",
                    "model_conf": 164.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.62,
                    "duration": "1 Months",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.24,
                    "duration": "indefinitely",
                    "model_conf": 155.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.44,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.96,
                    "duration": "2 Months",
                    "model_conf": 233.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.96,
                    "duration": "indefinitely",
                    "model_conf": 84.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.06,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.15,
                    "duration": "1 Months",
                    "model_conf": 216.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/fort-collins/1514-e-harmony-road.html": {
        "name": "Fort Collins",
        "address": "1514 E Harmony Road Suite 2B, Fort Collins Fort Collins, Colorado, 80525",
        "phone": "(970) 488-3689",
        "url": "https://locations.massageenvy.com/colorado/fort-collins/1514-e-harmony-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.525205109772386,
            -105.05095615448232
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 154.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.93,
                    "duration": "3 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 93.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 154.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.39,
                    "duration": "indefinitely",
                    "model_conf": 279.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 190.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.59,
                    "duration": "1 Months",
                    "model_conf": 166.07
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.29,
                    "duration": "indefinitely",
                    "model_conf": 168.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 140.45
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 238.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 84.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 54.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 214.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/grand-junction/2490-patterson-rd.html": {
        "name": "Grand Junction",
        "address": "2490 Patterson Rd Suite 7, Grand Junction, Colorado, 81505",
        "phone": "(970) 783-9999",
        "url": "https://locations.massageenvy.com/colorado/grand-junction/2490-patterson-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            39.09249716259493,
            -108.59077485825925
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/colorado/greeley/4206-centerplace-dr..html": {
        "name": "Greeley",
        "address": "4206 Centerplace Dr. Suite 706, Greeley Greeley, Colorado, 80634",
        "phone": "(970) 330-2220",
        "url": "https://locations.massageenvy.com/colorado/greeley/4206-centerplace-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.394165937114394,
            -104.74575117230415
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 279.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.8,
                    "duration": "indefinitely",
                    "model_conf": 193.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.07,
                    "duration": "indefinitely",
                    "model_conf": 164.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.15,
                    "duration": "4 Months",
                    "model_conf": 165.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.28,
                    "duration": "1 Months",
                    "model_conf": 157.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.72,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.66,
                    "duration": "indefinitely",
                    "model_conf": 237.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.53,
                    "duration": "1 Months",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.83,
                    "duration": "indefinitely",
                    "model_conf": 55.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.62,
                    "duration": "2 Months",
                    "model_conf": 220.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/longmont/210-ken-pratt-boulevard.html": {
        "name": "Longmont",
        "address": "210 Ken Pratt Boulevard Suite 180, Longmont Longmont, Colorado, 80501",
        "phone": "(303) 772-1400",
        "url": "https://locations.massageenvy.com/colorado/longmont/210-ken-pratt-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.15380254826699,
            -105.09866730847534
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.25,
                    "duration": "1 Months",
                    "model_conf": 279.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.17,
                    "duration": "indefinitely",
                    "model_conf": 188.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.85,
                    "duration": "2 Months",
                    "model_conf": 165.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.66,
                    "duration": "indefinitely",
                    "model_conf": 163.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.63,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.97,
                    "duration": "2 Months",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.54,
                    "duration": "indefinitely",
                    "model_conf": 230.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.59,
                    "duration": "indefinitely",
                    "model_conf": 85.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.42,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.26,
                    "duration": "indefinitely",
                    "model_conf": 219.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/loveland/1657-rocky-mountain-avenue.html": {
        "name": "Loveland",
        "address": "1657 Rocky Mountain Avenue, Loveland, Colorado, 80538",
        "phone": "(970) 622-8900",
        "url": "https://locations.massageenvy.com/colorado/loveland/1657-rocky-mountain-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.40957,
            -105.00681
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.91,
                    "duration": "2 Months",
                    "model_conf": 280.6
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.36,
                    "duration": "1 Months",
                    "model_conf": 192.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.13,
                    "duration": "indefinitely",
                    "model_conf": 165.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.19,
                    "duration": "4 Months",
                    "model_conf": 164.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.05,
                    "duration": "indefinitely",
                    "model_conf": 154.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.82,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.0,
                    "duration": "2 Months",
                    "model_conf": 237.59
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.87,
                    "duration": "indefinitely",
                    "model_conf": 85.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.02,
                    "duration": "indefinitely",
                    "model_conf": 55.16
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.81,
                    "duration": "2 Months",
                    "model_conf": 219.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/parker/17001-lincoln-ave.html": {
        "name": "Parker",
        "address": "17001 Lincoln Ave #D, Parker Parker, Colorado, 80134",
        "phone": "(720) 842-0900",
        "url": "https://locations.massageenvy.com/colorado/parker/17001-lincoln-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            39.53618873473874,
            -104.79097402827608
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.82,
                    "duration": "indefinitely",
                    "model_conf": 243.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.85,
                    "duration": "indefinitely",
                    "model_conf": 170.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.97,
                    "duration": "indefinitely",
                    "model_conf": 153.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.0,
                    "duration": "5 Months",
                    "model_conf": 145.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.21,
                    "duration": "indefinitely",
                    "model_conf": 144.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.36,
                    "duration": "indefinitely",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.52,
                    "duration": "1 Months",
                    "model_conf": 193.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.45,
                    "duration": "indefinitely",
                    "model_conf": 80.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.0,
                    "duration": "indefinitely",
                    "model_conf": 51.44
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.72,
                    "duration": "2 Months",
                    "model_conf": 197.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/sheridan/3960-e-river-point-parkway.html": {
        "name": "Englewood",
        "address": "3960 E River Point Parkway, Sheridan Sheridan, Colorado, 80110",
        "phone": "(303) 999-3879",
        "url": "https://locations.massageenvy.com/colorado/sheridan/3960-e-river-point-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.646028,
            -105.007724
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.56,
                    "duration": "indefinitely",
                    "model_conf": 249.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.11,
                    "duration": "indefinitely",
                    "model_conf": 173.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.76,
                    "duration": "3 Months",
                    "model_conf": 154.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.19,
                    "duration": "indefinitely",
                    "model_conf": 148.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.88,
                    "duration": "1 Months",
                    "model_conf": 144.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.97,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.86,
                    "duration": "indefinitely",
                    "model_conf": 204.52
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.39,
                    "duration": "1 Months",
                    "model_conf": 81.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.83,
                    "duration": "indefinitely",
                    "model_conf": 52.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.25,
                    "duration": "indefinitely",
                    "model_conf": 196.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/thornton/1181-e-120th-ave.html": {
        "name": "Thornton",
        "address": "1181 E 120th Ave, Thornton, Colorado, 80233",
        "phone": "(303) 920-4200",
        "url": "https://locations.massageenvy.com/colorado/thornton/1181-e-120th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.916459,
            -104.972772
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.74,
                    "duration": "indefinitely",
                    "model_conf": 268.02
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.76,
                    "duration": "indefinitely",
                    "model_conf": 190.42
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.74,
                    "duration": "indefinitely",
                    "model_conf": 165.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.73,
                    "duration": "indefinitely",
                    "model_conf": 165.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.9,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.51,
                    "duration": "1 Months",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.68,
                    "duration": "indefinitely",
                    "model_conf": 228.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 84.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.0,
                    "duration": "indefinitely",
                    "model_conf": 55.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.76,
                    "duration": "indefinitely",
                    "model_conf": 220.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/westminster/10449-town-center-dr.html": {
        "name": "Westminster",
        "address": "10449 Town Center Dr Ste 600, Westminster, Colorado, 80021",
        "phone": "(303) 466-1818",
        "url": "https://locations.massageenvy.com/colorado/westminster/10449-town-center-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.8876988,
            -105.0763974
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.03,
                    "duration": "indefinitely",
                    "model_conf": 244.51
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.07,
                    "duration": "indefinitely",
                    "model_conf": 185.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.02,
                    "duration": "5 Months",
                    "model_conf": 164.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.04,
                    "duration": "indefinitely",
                    "model_conf": 160.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.07,
                    "duration": "3 Months",
                    "model_conf": 155.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 140.52
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.6,
                    "duration": "indefinitely",
                    "model_conf": 212.38
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.0,
                    "duration": "2 Months",
                    "model_conf": 82.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.23,
                    "duration": "3 Months",
                    "model_conf": 54.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.55,
                    "duration": "indefinitely",
                    "model_conf": 213.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/brookfield/49-federal-road.html": {
        "name": "Brookfield",
        "address": "49 Federal Road, Brookfield Brookfield, Connecticut, 06804",
        "phone": "(203) 775-3689",
        "url": "https://locations.massageenvy.com/connecticut/brookfield/49-federal-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "2:00 PM - 8:00 PM",
            "WEDNESDAY": "12:00 PM - 9:00 PM",
            "THURSDAY": "12:00 PM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            41.43265,
            -73.412349
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/connecticut/canton/110-albany-turnpike.html": {
        "name": "Canton",
        "address": "110 Albany Turnpike Ste 205, Canton, Connecticut, 06019",
        "phone": "(860) 693-8000",
        "url": "https://locations.massageenvy.com/connecticut/canton/110-albany-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.82321370287702,
            -72.88391107103199
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.96,
                    "duration": "indefinitely",
                    "model_conf": 281.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.97,
                    "duration": "3 Months",
                    "model_conf": 166.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.98,
                    "duration": "indefinitely",
                    "model_conf": 166.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.94,
                    "duration": "2 Months",
                    "model_conf": 156.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.53,
                    "duration": "1 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.77,
                    "duration": "indefinitely",
                    "model_conf": 220.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/cromwell/51-shunpike-road.html": {
        "name": "Cromwell",
        "address": "51 Shunpike Road, Cromwell Cromwell, Connecticut, 06416",
        "phone": "(860) 635-3689",
        "url": "https://locations.massageenvy.com/connecticut/cromwell/51-shunpike-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.6064193,
            -72.6783538
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.76,
                    "duration": "3 Months",
                    "model_conf": 281.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 165.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 165.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.17,
                    "duration": "indefinitely",
                    "model_conf": 157.24
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 55.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.3,
                    "duration": "1 Months",
                    "model_conf": 221.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/fairfield/2279-black-rock-turnpike.html": {
        "name": "Fairfield",
        "address": "2279 Black Rock Turnpike, Fairfield Fairfield, Connecticut, 06825",
        "phone": "(203) 333-3689",
        "url": "https://locations.massageenvy.com/connecticut/fairfield/2279-black-rock-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.18224744683034,
            -73.25484194407841
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": null,
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.34,
                    "duration": "indefinitely",
                    "model_conf": 262.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.42,
                    "duration": "1 Months",
                    "model_conf": 153.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.51,
                    "duration": "indefinitely",
                    "model_conf": 155.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.19,
                    "duration": "1 Months",
                    "model_conf": 153.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.37,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 83.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.61,
                    "duration": "2 Months",
                    "model_conf": 54.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.69,
                    "duration": "indefinitely",
                    "model_conf": 213.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/farmington/1600-se-road.html": {
        "name": "West Farm",
        "address": "1600 SE Road, Farmington, Connecticut, 06032",
        "phone": "(860) 677-4477",
        "url": "https://locations.massageenvy.com/connecticut/farmington/1600-se-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.71623553757954,
            -72.76715274716582
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 43.55,
                    "duration": "12 Months",
                    "model_conf": 273.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 178.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.88,
                    "duration": "3 Months",
                    "model_conf": 165.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 162.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.57,
                    "duration": "2 Months",
                    "model_conf": 155.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.64,
                    "duration": "3 Months",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 224.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.42,
                    "duration": "indefinitely",
                    "model_conf": 85.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 55.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 220.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/glastonbury/140-glastonbury-boulevard.html": {
        "name": "Glastonbury",
        "address": "140 Glastonbury Boulevard, Glastonbury, Connecticut, 06033",
        "phone": "(860) 633-1111",
        "url": "https://locations.massageenvy.com/connecticut/glastonbury/140-glastonbury-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.720832,
            -72.621482
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.53,
                    "duration": "6 Months",
                    "model_conf": 272.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 166.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 161.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.26,
                    "duration": "indefinitely",
                    "model_conf": 157.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.09,
                    "duration": "5 Months",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.53,
                    "duration": "indefinitely",
                    "model_conf": 85.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.27,
                    "duration": "indefinitely",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.14,
                    "duration": "1 Months",
                    "model_conf": 220.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/hamden/2300-dixwell-avenue.html": {
        "name": "Hamden",
        "address": "2300 Dixwell Avenue, Hamden, Connecticut, 06514",
        "phone": "(203) 281-3689",
        "url": "https://locations.massageenvy.com/connecticut/hamden/2300-dixwell-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "11:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.3714508,
            -72.9197871
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.92,
                    "duration": "indefinitely",
                    "model_conf": 282.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.26,
                    "duration": "5 Months",
                    "model_conf": 166.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.54,
                    "duration": "indefinitely",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.48,
                    "duration": "1 Months",
                    "model_conf": 154.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.15,
                    "duration": "indefinitely",
                    "model_conf": 55.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.68,
                    "duration": "indefinitely",
                    "model_conf": 211.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/milford/1640-boston-post-road.html": {
        "name": "Milford",
        "address": "1640 Boston Post Road, Milford Milford, Connecticut, 06460",
        "phone": "(203) 876-8900",
        "url": "https://locations.massageenvy.com/connecticut/milford/1640-boston-post-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.247568,
            -73.027514
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.96,
                    "duration": "indefinitely",
                    "model_conf": 274.95
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 162.37
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.12,
                    "duration": "indefinitely",
                    "model_conf": 159.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.71,
                    "duration": "indefinitely",
                    "model_conf": 152.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.94,
                    "duration": "2 Months",
                    "model_conf": 139.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.24,
                    "duration": "2 Months",
                    "model_conf": 84.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.96,
                    "duration": "6 Months",
                    "model_conf": 52.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.3,
                    "duration": "indefinitely",
                    "model_conf": 215.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/monroe/51-monroe-turnpike.html": {
        "name": "Trumbull-Monroe",
        "address": "51 Monroe Turnpike, Monroe, Connecticut, 06611",
        "phone": "(203) 268-2222",
        "url": "https://locations.massageenvy.com/connecticut/monroe/51-monroe-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.2909411,
            -73.2352371
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.5,
                    "duration": "indefinitely",
                    "model_conf": 280.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.72,
                    "duration": "3 Months",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.19,
                    "duration": "indefinitely",
                    "model_conf": 165.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.05,
                    "duration": "1 Months",
                    "model_conf": 156.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.02,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.37,
                    "duration": "1 Months",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.53,
                    "duration": "2 Months",
                    "model_conf": 55.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.46,
                    "duration": "indefinitely",
                    "model_conf": 218.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/norwalk/607-main-avenue.html": {
        "name": "Norwalk - Wilton",
        "address": "607 Main Avenue Ste 9, Norwalk Norwalk, Connecticut, 06851",
        "phone": "(203) 846-1000",
        "url": "https://locations.massageenvy.com/connecticut/norwalk/607-main-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.15713965934656,
            -73.42271046996073
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1..5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.25,
                    "duration": "indefinitely",
                    "model_conf": 69.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.48,
                    "duration": "1 Months",
                    "model_conf": 139.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.41,
                    "duration": "indefinitely",
                    "model_conf": 83.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.98,
                    "duration": "indefinitely",
                    "model_conf": 146.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.78,
                    "duration": "indefinitely",
                    "model_conf": 253.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.55,
                    "duration": "7 Months",
                    "model_conf": 144.23
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.7,
                    "duration": "indefinitely",
                    "model_conf": 138.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.76,
                    "duration": "2 Months",
                    "model_conf": 143.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.89,
                    "duration": "2 Months",
                    "model_conf": 139.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.23,
                    "duration": "2 Months",
                    "model_conf": 82.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.53,
                    "duration": "3 Months",
                    "model_conf": 52.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 202.9,
                    "duration": "indefinitely",
                    "model_conf": 185.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/shelton/110-commerce-drive.html": {
        "name": "Shelton",
        "address": "110 Commerce Drive Suite 112, Shelton, Connecticut, 06484",
        "phone": "(203) 763-4899",
        "url": "https://locations.massageenvy.com/connecticut/shelton/110-commerce-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.27740550000001,
            -73.1229329
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.75,
                    "duration": "indefinitely",
                    "model_conf": 278.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.05,
                    "duration": "3 Months",
                    "model_conf": 164.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.49,
                    "duration": "indefinitely",
                    "model_conf": 161.49
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.57,
                    "duration": "2 Months",
                    "model_conf": 153.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 101.55,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.63,
                    "duration": "1 Months",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.98,
                    "duration": "indefinitely",
                    "model_conf": 53.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.8,
                    "duration": "indefinitely",
                    "model_conf": 216.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/south-windsor/90-buckland-road.html": {
        "name": "South Windsor",
        "address": "90 Buckland Road, South Windsor, Connecticut, 06074",
        "phone": "(860) 644-5800",
        "url": "https://locations.massageenvy.com/connecticut/south-windsor/90-buckland-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.811181009922116,
            -72.55402568220904
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.97,
                    "duration": "2 Months",
                    "model_conf": 266.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 165.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 156.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 156.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.41,
                    "duration": "1 Months",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 85.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.04,
                    "duration": "indefinitely",
                    "model_conf": 220.19
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/southbury/775-main-street-south.html": {
        "name": "Southbury-CT",
        "address": "775 Main Street South, Southbury, Connecticut, 06488",
        "phone": "(203) 263-3689",
        "url": "https://locations.massageenvy.com/connecticut/southbury/775-main-street-south.html",
        "email": "",
        "hours": {
            "MONDAY": "12:00 PM - 8:00 PM",
            "TUESDAY": "11:00 AM - 8:00 PM",
            "WEDNESDAY": "11:00 AM - 8:00 PM",
            "THURSDAY": "11:00 AM - 8:00 PM",
            "FRIDAY": "9:30 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            41.4619593,
            -73.2314117
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.53,
                    "duration": "1 Months",
                    "model_conf": 282.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 166.37
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 168.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.3,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 55.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 219.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/stamford/1014-high-ridge-road.html": {
        "name": "North Stamford",
        "address": "1014 High Ridge Road, Stamford, Connecticut, 06905",
        "phone": "(203) 322-7000",
        "url": "https://locations.massageenvy.com/connecticut/stamford/1014-high-ridge-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.1061748,
            -73.5485811
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/connecticut/west-hartford/333-n-main-st.html": {
        "name": "West Hartford",
        "address": "333 N Main St Unit 35, West Hartford, Connecticut, 06117",
        "phone": "(860) 233-8890",
        "url": "https://locations.massageenvy.com/connecticut/west-hartford/333-n-main-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.784403,
            -72.748285
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.25,
                    "duration": "2 Months",
                    "model_conf": 271.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.79,
                    "duration": "6 Months",
                    "model_conf": 166.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.88,
                    "duration": "indefinitely",
                    "model_conf": 160.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.9,
                    "duration": "2 Months",
                    "model_conf": 156.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.62,
                    "duration": "3 Months",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 220.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/connecticut/westport/275-post-road-east.html": {
        "name": "Westport",
        "address": "275 Post Road East, Westport, Connecticut, 06880",
        "phone": "(203) 571-1234",
        "url": "https://locations.massageenvy.com/connecticut/westport/275-post-road-east.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            41.1420851,
            -73.3551587
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.71,
                    "duration": "indefinitely",
                    "model_conf": 70.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.72,
                    "duration": "indefinitely",
                    "model_conf": 139.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.44,
                    "duration": "indefinitely",
                    "model_conf": 87.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.61,
                    "duration": "indefinitely",
                    "model_conf": 149.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.17,
                    "duration": "indefinitely",
                    "model_conf": 258.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.35,
                    "duration": "4 Months",
                    "model_conf": 149.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.04,
                    "duration": "indefinitely",
                    "model_conf": 145.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.46,
                    "duration": "indefinitely",
                    "model_conf": 146.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.24,
                    "duration": "1 Months",
                    "model_conf": 140.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.82,
                    "duration": "indefinitely",
                    "model_conf": 82.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.44,
                    "duration": "2 Months",
                    "model_conf": 53.36
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.74,
                    "duration": "indefinitely",
                    "model_conf": 194.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/delaware/dover/30-n.-dupont-hwy.html": {
        "name": "Capital Station",
        "address": "30 N. DuPont Hwy Suite #1, Dover, Delaware, 19901",
        "phone": "(302) 446-3689",
        "url": "https://locations.massageenvy.com/delaware/dover/30-n.-dupont-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 3:00 PM",
            "TUESDAY": "9:00 AM - 9:30 PM",
            "WEDNESDAY": "9:00 AM - 9:30 PM",
            "THURSDAY": "9:00 AM - 9:30 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.1639989,
            -75.5169889
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.89,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.68,
                    "duration": "indefinitely",
                    "model_conf": 101.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 155.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.21,
                    "duration": "indefinitely",
                    "model_conf": 282.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.29,
                    "duration": "2 Months",
                    "model_conf": 166.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.45,
                    "duration": "indefinitely",
                    "model_conf": 168.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 157.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.93,
                    "duration": "2 Months",
                    "model_conf": 141.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.37,
                    "duration": "2 Months",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.6,
                    "duration": "2 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.34,
                    "duration": "indefinitely",
                    "model_conf": 221.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/delaware/newark/3148-fashion-center-blvd..html": {
        "name": "Christiana",
        "address": "3148 Fashion Center Blvd., Newark, Delaware, 19702",
        "phone": "(302) 266-2762",
        "url": "https://locations.massageenvy.com/delaware/newark/3148-fashion-center-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 4:00 PM",
            "SUNDAY": "9:30 AM - 7:00 PM"
        },
        "geolocation": [
            39.670608,
            -75.650204
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.32,
                    "duration": "indefinitely",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.42,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.47,
                    "duration": "3 Months",
                    "model_conf": 100.85
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.4,
                    "duration": "3 Months",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.23,
                    "duration": "indefinitely",
                    "model_conf": 276.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.65,
                    "duration": "indefinitely",
                    "model_conf": 164.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.99,
                    "duration": "7 Months",
                    "model_conf": 166.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.74,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.82,
                    "duration": "indefinitely",
                    "model_conf": 85.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.87,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.8,
                    "duration": "2 Months",
                    "model_conf": 219.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/delaware/rehoboth-beach/18949-coastal-hwy.html": {
        "name": "Rehoboth Beach",
        "address": "18949 Coastal Hwy Suites 104, Rehoboth Beach, Delaware, 19971",
        "phone": "(302) 703-4100",
        "url": "https://locations.massageenvy.com/delaware/rehoboth-beach/18949-coastal-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7307116,
            -75.1317071
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.42,
                    "duration": "indefinitely",
                    "model_conf": 282.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.68,
                    "duration": "indefinitely",
                    "model_conf": 166.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.88,
                    "duration": "indefinitely",
                    "model_conf": 168.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.68,
                    "duration": "indefinitely",
                    "model_conf": 157.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.42,
                    "duration": "1 Months",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.51,
                    "duration": "indefinitely",
                    "model_conf": 221.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/delaware/wilmington/5615-concord-pike.html": {
        "name": "Concord Pike",
        "address": "5615 Concord Pike, Wilmington Wilmington, Delaware, 19803",
        "phone": "(302) 691-7168",
        "url": "https://locations.massageenvy.com/delaware/wilmington/5615-concord-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "11:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 7:00 PM",
            "SATURDAY": "8:00 AM - 4:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.83372997556154,
            -75.5411221408778
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.23,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.49,
                    "duration": "1 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.69,
                    "duration": "2 Months",
                    "model_conf": 100.0
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.36,
                    "duration": "1 Months",
                    "model_conf": 153.28
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.8,
                    "duration": "indefinitely",
                    "model_conf": 280.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.99,
                    "duration": "indefinitely",
                    "model_conf": 165.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.8,
                    "duration": "1 Months",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 157.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.3,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.87,
                    "duration": "indefinitely",
                    "model_conf": 85.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.81,
                    "duration": "1 Months",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.6,
                    "duration": "indefinitely",
                    "model_conf": 216.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/altamonte-springs/397-e-altamonte-dr.html": {
        "name": "Altamonte Springs",
        "address": "397 E Altamonte Dr Ste 1600, Altamonte Springs Altamonte Springs, Florida, 32701",
        "phone": "(407) 339-3689",
        "url": "https://locations.massageenvy.com/florida/altamonte-springs/397-e-altamonte-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.6646809,
            -81.3794778
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.56,
                    "duration": "1 Months",
                    "model_conf": 68.12
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.05,
                    "duration": "indefinitely",
                    "model_conf": 135.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.84,
                    "duration": "indefinitely",
                    "model_conf": 139.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.63,
                    "duration": "3 Months",
                    "model_conf": 80.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.08,
                    "duration": "indefinitely",
                    "model_conf": 146.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.5,
                    "duration": "4 Months",
                    "model_conf": 246.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.91,
                    "duration": "3 Months",
                    "model_conf": 159.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.09,
                    "duration": "indefinitely",
                    "model_conf": 142.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.19,
                    "duration": "7 Months",
                    "model_conf": 136.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.45,
                    "duration": "indefinitely",
                    "model_conf": 138.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.77,
                    "duration": "indefinitely",
                    "model_conf": 139.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.84,
                    "duration": "4 Months",
                    "model_conf": 178.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.46,
                    "duration": "indefinitely",
                    "model_conf": 80.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.39,
                    "duration": "indefinitely",
                    "model_conf": 51.18
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.13,
                    "duration": "1 Months",
                    "model_conf": 176.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/aventura/20633-biscayne-boulevard.html": {
        "name": "Aventura",
        "address": "20633 Biscayne Boulevard, Aventura Aventura, Florida, 33180",
        "phone": "(305) 935-1771",
        "url": "https://locations.massageenvy.com/florida/aventura/20633-biscayne-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            25.966685168479792,
            -80.14420285820961
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.8,
                    "duration": "indefinitely",
                    "model_conf": 70.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.74,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 100.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 153.94
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.11,
                    "duration": "2 Months",
                    "model_conf": 280.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 164.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.22,
                    "duration": "4 Months",
                    "model_conf": 165.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.45,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 85.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.13,
                    "duration": "indefinitely",
                    "model_conf": 54.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.99,
                    "duration": "2 Months",
                    "model_conf": 217.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/bradenton/4734-cortez-road-west.html": {
        "name": "Bradenton",
        "address": "4734 Cortez Road West, Bradenton Bradenton, Florida, 34210",
        "phone": "(941) 909-0800",
        "url": "https://locations.massageenvy.com/florida/bradenton/4734-cortez-road-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.463200889825515,
            -82.60977006922997
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.6,
                    "duration": "1 Months",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.49,
                    "duration": "1 Months",
                    "model_conf": 158.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.21,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.47,
                    "duration": "4 Months",
                    "model_conf": 101.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.25,
                    "duration": "1 Months",
                    "model_conf": 154.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.72,
                    "duration": "3 Months",
                    "model_conf": 279.6
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.95,
                    "duration": "3 Months",
                    "model_conf": 191.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 165.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.03,
                    "duration": "7 Months",
                    "model_conf": 165.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.14,
                    "duration": "indefinitely",
                    "model_conf": 156.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.97,
                    "duration": "3 Months",
                    "model_conf": 237.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.06,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.06,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.81,
                    "duration": "2 Months",
                    "model_conf": 219.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/brandon/2092-badlands-drive.html": {
        "name": "Brandon",
        "address": "2092 Badlands Drive, Brandon, Florida, 33511",
        "phone": "(813) 413-2211",
        "url": "https://locations.massageenvy.com/florida/brandon/2092-badlands-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.927662,
            -82.315442
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.72,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.65,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.53,
                    "duration": "3 Months",
                    "model_conf": 101.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.6,
                    "duration": "indefinitely",
                    "model_conf": 154.44
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.91,
                    "duration": "3 Months",
                    "model_conf": 279.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.03,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.2,
                    "duration": "6 Months",
                    "model_conf": 167.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.33,
                    "duration": "indefinitely",
                    "model_conf": 155.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.77,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.92,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.1,
                    "duration": "1 Months",
                    "model_conf": 219.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/brooksville/13185-cortez-boulevard.html": {
        "name": "Spring Hill-Brooksville",
        "address": "13185 Cortez Boulevard, Brooksville, Florida, 34613",
        "phone": "(352) 678-3344",
        "url": "https://locations.massageenvy.com/florida/brooksville/13185-cortez-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.5356044,
            -82.5079574
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.28,
                    "duration": "indefinitely",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.59,
                    "duration": "indefinitely",
                    "model_conf": 157.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.91,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.29,
                    "duration": "indefinitely",
                    "model_conf": 102.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.47,
                    "duration": "indefinitely",
                    "model_conf": 154.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.06,
                    "duration": "indefinitely",
                    "model_conf": 283.35
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.46,
                    "duration": "indefinitely",
                    "model_conf": 194.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.91,
                    "duration": "indefinitely",
                    "model_conf": 165.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.29,
                    "duration": "indefinitely",
                    "model_conf": 167.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.08,
                    "duration": "indefinitely",
                    "model_conf": 157.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.82,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.46,
                    "duration": "indefinitely",
                    "model_conf": 235.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.14,
                    "duration": "indefinitely",
                    "model_conf": 85.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.05,
                    "duration": "indefinitely",
                    "model_conf": 55.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.23,
                    "duration": "indefinitely",
                    "model_conf": 221.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/clearwater/2516-n-mcmullen-booth-road-ste-b.html": {
        "name": "Clearwater",
        "address": "2516 N Mcmullen Booth Road Ste B, Clearwater, Florida, 33761",
        "phone": "(727) 799-3689",
        "url": "https://locations.massageenvy.com/florida/clearwater/2516-n-mcmullen-booth-road-ste-b.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            28.013462445602062,
            -82.71131669784813
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 70.44
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 152.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 98.52,
                    "duration": "5 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.16,
                    "duration": "indefinitely",
                    "model_conf": 97.69
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.06,
                    "duration": "indefinitely",
                    "model_conf": 153.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.31,
                    "duration": "indefinitely",
                    "model_conf": 266.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 190.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.84,
                    "duration": "indefinitely",
                    "model_conf": 165.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.39,
                    "duration": "indefinitely",
                    "model_conf": 164.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 150.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.28,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.1,
                    "duration": "indefinitely",
                    "model_conf": 233.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.93,
                    "duration": "indefinitely",
                    "model_conf": 84.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.78,
                    "duration": "1 Months",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.93,
                    "duration": "indefinitely",
                    "model_conf": 216.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/clermont/1500-oakley-seaver-blvd.html": {
        "name": "Clermont",
        "address": "1500 Oakley Seaver Blvd Ste 15, Clermont, Florida, 34711",
        "phone": "(352) 243-3689",
        "url": "https://locations.massageenvy.com/florida/clermont/1500-oakley-seaver-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.545116185886407,
            -81.72816227664556
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.725,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.65,
                    "duration": "1 Months",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.18,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.92,
                    "duration": "indefinitely",
                    "model_conf": 102.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.91,
                    "duration": "indefinitely",
                    "model_conf": 154.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.93,
                    "duration": "3 Months",
                    "model_conf": 282.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.9,
                    "duration": "indefinitely",
                    "model_conf": 166.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.89,
                    "duration": "indefinitely",
                    "model_conf": 167.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.99,
                    "duration": "indefinitely",
                    "model_conf": 154.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.67,
                    "duration": "1 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.1,
                    "duration": "indefinitely",
                    "model_conf": 85.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.9,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.54,
                    "duration": "1 Months",
                    "model_conf": 221.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/coconut-creek/4407-lyons-rd.html": {
        "name": "Coconut Creek",
        "address": "4407 Lyons Rd Suite 102, Coconut Creek, Florida, 33073",
        "phone": "(954) 688-7777",
        "url": "https://locations.massageenvy.com/florida/coconut-creek/4407-lyons-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 7:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.282814429522894,
            -80.18704909325407
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.08,
                    "duration": "indefinitely",
                    "model_conf": 69.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.88,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.56,
                    "duration": "indefinitely",
                    "model_conf": 99.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.24,
                    "duration": "1 Months",
                    "model_conf": 149.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.28,
                    "duration": "indefinitely",
                    "model_conf": 277.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.35,
                    "duration": "2 Months",
                    "model_conf": 159.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 160.9
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.04,
                    "duration": "4 Months",
                    "model_conf": 146.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.15,
                    "duration": "indefinitely",
                    "model_conf": 140.64
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.21,
                    "duration": "2 Months",
                    "model_conf": 84.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.71,
                    "duration": "1 Months",
                    "model_conf": 53.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.25,
                    "duration": "indefinitely",
                    "model_conf": 209.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/cooper-city/5856-flamingo-rd.html": {
        "name": "Cooper City",
        "address": "5856 Flamingo Rd, Cooper City, Florida, 33330",
        "phone": "(954) 432-1112",
        "url": "https://locations.massageenvy.com/florida/cooper-city/5856-flamingo-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.0450897,
            -80.311479
        ],
        "rating": 4.8,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 68.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.27,
                    "duration": "4 Months",
                    "model_conf": 99.31
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.71,
                    "duration": "2 Months",
                    "model_conf": 152.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 279.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.44,
                    "duration": "indefinitely",
                    "model_conf": 163.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.92,
                    "duration": "6 Months",
                    "model_conf": 165.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 153.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.24,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 52.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.6,
                    "duration": "2 Months",
                    "model_conf": 209.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/coral-springs/2878-university-dr.html": {
        "name": "Coral Springs",
        "address": "2878 University Dr, Coral Springs Coral Springs, Florida, 33065",
        "phone": "(954) 757-3689",
        "url": "https://locations.massageenvy.com/florida/coral-springs/2878-university-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:30 AM - 9:00 PM",
            "SUNDAY": "9:30 AM - 8:30 PM"
        },
        "geolocation": [
            26.2663466,
            -80.250733
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.26,
                    "duration": "indefinitely",
                    "model_conf": 70.32
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 154.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.54,
                    "duration": "indefinitely",
                    "model_conf": 99.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.29,
                    "duration": "1 Months",
                    "model_conf": 151.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.94,
                    "duration": "indefinitely",
                    "model_conf": 278.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.12,
                    "duration": "indefinitely",
                    "model_conf": 192.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 161.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.59,
                    "duration": "indefinitely",
                    "model_conf": 163.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.86,
                    "duration": "4 Months",
                    "model_conf": 150.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.75,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.63,
                    "duration": "indefinitely",
                    "model_conf": 232.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.91,
                    "duration": "3 Months",
                    "model_conf": 84.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.11,
                    "duration": "5 Months",
                    "model_conf": 53.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.97,
                    "duration": "indefinitely",
                    "model_conf": 215.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/cutler-bay/18443-south-dixie-hwy.html": {
        "name": "Cutler Bay",
        "address": "18443 South Dixie Hwy, Cutler Bay Cutler Bay, Florida, 33157",
        "phone": "(305) 231-3689",
        "url": "https://locations.massageenvy.com/florida/cutler-bay/18443-south-dixie-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 5:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            25.598111238998733,
            -80.351437017791
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.96,
                    "duration": "indefinitely",
                    "model_conf": 69.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.49,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 100.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 153.27
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.34,
                    "duration": "1 Months",
                    "model_conf": 280.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 165.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 160.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 151.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.62,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.4,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 54.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.36,
                    "duration": "1 Months",
                    "model_conf": 213.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/davie/2264-s.-university.html": {
        "name": "Davie",
        "address": "2264 S. University, Davie, Florida, 33324",
        "phone": "(954) 473-2799",
        "url": "https://locations.massageenvy.com/florida/davie/2264-s.-university.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.094114690994065,
            -80.24944469332695
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.11,
                    "duration": "indefinitely",
                    "model_conf": 69.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 100.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.91,
                    "duration": "2 Months",
                    "model_conf": 152.9
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.22,
                    "duration": "indefinitely",
                    "model_conf": 280.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.29,
                    "duration": "indefinitely",
                    "model_conf": 164.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.69,
                    "duration": "2 Months",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.23,
                    "duration": "1 Months",
                    "model_conf": 154.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.0,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.75,
                    "duration": "indefinitely",
                    "model_conf": 85.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.81,
                    "duration": "1 Months",
                    "model_conf": 53.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 215.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/deland/233-east-international-speedway-blvd.html": {
        "name": "DeLand",
        "address": "233 East International Speedway Blvd, DeLand, Florida, 32724",
        "phone": "(386) 873-3300",
        "url": "https://locations.massageenvy.com/florida/deland/233-east-international-speedway-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.056267968258854,
            -81.30204101081794
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.9,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.36,
                    "duration": "indefinitely",
                    "model_conf": 283.34
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.17,
                    "duration": "indefinitely",
                    "model_conf": 195.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.01,
                    "duration": "3 Months",
                    "model_conf": 166.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.87,
                    "duration": "indefinitely",
                    "model_conf": 168.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.63,
                    "duration": "3 Months",
                    "model_conf": 157.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/deerfield-beach/200-south-federal-hwy.html": {
        "name": "Hillsboro",
        "address": "200 South Federal Hwy, Deerfield Beach Deerfield Beach, Florida, 33441",
        "phone": "(954) 570-8883",
        "url": "https://locations.massageenvy.com/florida/deerfield-beach/200-south-federal-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            26.314841310694415,
            -80.08884474635124
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.75,
                    "duration": "indefinitely",
                    "model_conf": 69.49
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 154.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 99.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 150.68
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 277.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 190.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 161.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 160.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 147.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.24,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 230.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 84.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 53.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 210.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/destin/34904-emerald-coast-pkwy.html": {
        "name": "Destin",
        "address": "34904 Emerald Coast Pkwy Suite 132, Destin, Florida, 32541",
        "phone": "(850) 650-8500",
        "url": "https://locations.massageenvy.com/florida/destin/34904-emerald-coast-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            30.387375902356386,
            -86.42707058305332
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.46,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.49,
                    "duration": "indefinitely",
                    "model_conf": 157.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.14,
                    "duration": "2 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.52,
                    "duration": "indefinitely",
                    "model_conf": 102.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.14,
                    "duration": "indefinitely",
                    "model_conf": 154.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.23,
                    "duration": "indefinitely",
                    "model_conf": 274.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 195.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.06,
                    "duration": "2 Months",
                    "model_conf": 166.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.06,
                    "duration": "indefinitely",
                    "model_conf": 167.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.51,
                    "duration": "indefinitely",
                    "model_conf": 156.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.19,
                    "duration": "indefinitely",
                    "model_conf": 237.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.73,
                    "duration": "indefinitely",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.59,
                    "duration": "indefinitely",
                    "model_conf": 209.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/estero/8001-plaza-del-lago-drive.html": {
        "name": "Coconut Point",
        "address": "8001 Plaza Del Lago Drive, Estero, Florida, 33928",
        "phone": "(239) 947-3689",
        "url": "https://locations.massageenvy.com/florida/estero/8001-plaza-del-lago-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.4071435,
            -81.8085313
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.56,
                    "duration": "1 Months",
                    "model_conf": 70.92
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.47,
                    "duration": "1 Months",
                    "model_conf": 158.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.53,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.55,
                    "duration": "3 Months",
                    "model_conf": 101.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.36,
                    "duration": "1 Months",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.34,
                    "duration": "4 Months",
                    "model_conf": 281.76
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.67,
                    "duration": "4 Months",
                    "model_conf": 192.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.29,
                    "duration": "indefinitely",
                    "model_conf": 164.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.0,
                    "duration": "7 Months",
                    "model_conf": 166.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.46,
                    "duration": "indefinitely",
                    "model_conf": 155.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.35,
                    "duration": "1 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.68,
                    "duration": "4 Months",
                    "model_conf": 236.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.19,
                    "duration": "indefinitely",
                    "model_conf": 85.36
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.16,
                    "duration": "indefinitely",
                    "model_conf": 55.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.45,
                    "duration": "3 Months",
                    "model_conf": 218.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/fleming-island/865-hibernia-rd.html": {
        "name": "Fleming Island",
        "address": "865 Hibernia Rd Ste 103, Fleming Island, Florida, 32003",
        "phone": "(904) 529-7170",
        "url": "https://locations.massageenvy.com/florida/fleming-island/865-hibernia-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.070190091389524,
            -81.70605262698365
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 157.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.24,
                    "duration": "2 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.46,
                    "duration": "3 Months",
                    "model_conf": 101.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 154.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 278.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 194.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 165.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 163.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 156.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.17,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 235.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.16,
                    "duration": "indefinitely",
                    "model_conf": 85.02
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 220.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/fort-lauderdale/1917-cordova-road.html": {
        "name": "Fort Lauderdale",
        "address": "1917 Cordova Road, Fort Lauderdale Fort Lauderdale, Florida, 33316",
        "phone": "(954) 626-6200",
        "url": "https://locations.massageenvy.com/florida/fort-lauderdale/1917-cordova-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            26.097131615454717,
            -80.13097501092489
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.77,
                    "duration": "indefinitely",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.76,
                    "duration": "1 Months",
                    "model_conf": 100.63
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.77,
                    "duration": "indefinitely",
                    "model_conf": 153.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.45,
                    "duration": "indefinitely",
                    "model_conf": 279.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 164.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.88,
                    "duration": "indefinitely",
                    "model_conf": 165.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.12,
                    "duration": "1 Months",
                    "model_conf": 155.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.96,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.72,
                    "duration": "indefinitely",
                    "model_conf": 85.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.74,
                    "duration": "1 Months",
                    "model_conf": 53.96
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.29,
                    "duration": "indefinitely",
                    "model_conf": 219.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/fort-walton-beach/99-eglin-pkwy-ne.html": {
        "name": "Ft. Walton Beach",
        "address": "99 Eglin Pkwy NE Ste 5B, Fort Walton Beach Fort Walton Beach, Florida, 32548",
        "phone": "(850) 301-2000",
        "url": "https://locations.massageenvy.com/florida/fort-walton-beach/99-eglin-pkwy-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            30.419212537886406,
            -86.60878347202647
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.26,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 158.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 102.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.51,
                    "duration": "indefinitely",
                    "model_conf": 278.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 194.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.54,
                    "duration": "1 Months",
                    "model_conf": 166.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.57,
                    "duration": "indefinitely",
                    "model_conf": 166.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.94,
                    "duration": "indefinitely",
                    "model_conf": 157.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.78,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 236.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 85.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.86,
                    "duration": "indefinitely",
                    "model_conf": 216.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/gainesville/3946-sw-archer-road.html": {
        "name": "Gainesville-Butler Plaza",
        "address": "3946 SW Archer Road Ste W105-106, Gainesville Gainesville, Florida, 32608",
        "phone": "(352) 373-3689",
        "url": "https://locations.massageenvy.com/florida/gainesville/3946-sw-archer-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            29.6216456,
            -82.3829699
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.63,
                    "duration": "1 Months",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.31,
                    "duration": "2 Months",
                    "model_conf": 159.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.44,
                    "duration": "3 Months",
                    "model_conf": 102.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.44,
                    "duration": "1 Months",
                    "model_conf": 155.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.31,
                    "duration": "4 Months",
                    "model_conf": 274.85
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.62,
                    "duration": "4 Months",
                    "model_conf": 194.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.57,
                    "duration": "indefinitely",
                    "model_conf": 164.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.21,
                    "duration": "5 Months",
                    "model_conf": 166.9
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.41,
                    "duration": "indefinitely",
                    "model_conf": 157.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.96,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.8,
                    "duration": "3 Months",
                    "model_conf": 236.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.37,
                    "duration": "indefinitely",
                    "model_conf": 85.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.24,
                    "duration": "indefinitely",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.65,
                    "duration": "2 Months",
                    "model_conf": 212.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/gulf-breeze/1165-gulf-breeze-parkway.html": {
        "name": "Gulf Breeze - Pensacola Beach",
        "address": "1165 Gulf Breeze Parkway, Gulf Breeze, Florida, 32561",
        "phone": "(850) 932-2244",
        "url": "https://locations.massageenvy.com/florida/gulf-breeze/1165-gulf-breeze-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.357613768692836,
            -87.15545430779463
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.71,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.54,
                    "duration": "4 Months",
                    "model_conf": 158.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.92,
                    "duration": "3 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.9,
                    "duration": "7 Months",
                    "model_conf": 101.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 154.38
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.97,
                    "duration": "8 Months",
                    "model_conf": 281.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.86,
                    "duration": "6 Months",
                    "model_conf": 194.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.05,
                    "duration": "indefinitely",
                    "model_conf": 164.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.65,
                    "duration": "8 Months",
                    "model_conf": 166.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.67,
                    "duration": "indefinitely",
                    "model_conf": 157.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.59,
                    "duration": "1 Months",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.16,
                    "duration": "9 Months",
                    "model_conf": 237.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.31,
                    "duration": "indefinitely",
                    "model_conf": 85.17
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.28,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.81,
                    "duration": "4 Months",
                    "model_conf": 220.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville-beach/3940-s-third-street.html": {
        "name": "Jacksonville Beach",
        "address": "3940 S Third Street, Jacksonville Beach Jacksonville Beach, Florida, 32250",
        "phone": "(904) 746-4440",
        "url": "https://locations.massageenvy.com/florida/jacksonville-beach/3940-s-third-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.258288,
            -81.392122
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.83,
                    "duration": "indefinitely",
                    "model_conf": 70.39
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 156.03
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.22,
                    "duration": "4 Months",
                    "model_conf": 100.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.55,
                    "duration": "1 Months",
                    "model_conf": 276.89
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 190.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 162.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 166.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.55,
                    "duration": "indefinitely",
                    "model_conf": 151.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.19,
                    "duration": "2 Months",
                    "model_conf": 233.27
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 83.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.35,
                    "duration": "indefinitely",
                    "model_conf": 54.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.49,
                    "duration": "1 Months",
                    "model_conf": 217.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jupiter/3755-military-trail.html": {
        "name": "Jupiter",
        "address": "3755 Military Trail Ste A-1, Jupiter, Florida, 33458",
        "phone": "(561) 743-8878",
        "url": "https://locations.massageenvy.com/florida/jupiter/3755-military-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.8986111,
            -80.1015032
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.39,
                    "duration": "indefinitely",
                    "model_conf": 70.63
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 158.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.7,
                    "duration": "3 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 102.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.81,
                    "duration": "indefinitely",
                    "model_conf": 151.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.91,
                    "duration": "indefinitely",
                    "model_conf": 273.82
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.08,
                    "duration": "indefinitely",
                    "model_conf": 193.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 164.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.1,
                    "duration": "indefinitely",
                    "model_conf": 163.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.34,
                    "duration": "1 Months",
                    "model_conf": 154.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.52,
                    "duration": "indefinitely",
                    "model_conf": 140.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.36,
                    "duration": "indefinitely",
                    "model_conf": 234.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.93,
                    "duration": "indefinitely",
                    "model_conf": 217.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/kissimmee/3245-rolling-oaks-blvd.html": {
        "name": "Kissimmee",
        "address": "3245 Rolling Oaks Blvd, Kissimmee, Florida, 34747",
        "phone": "(321) 765-9227",
        "url": "https://locations.massageenvy.com/florida/kissimmee/3245-rolling-oaks-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.344592,
            -81.61149499999999
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.725,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.92,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.17,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.23,
                    "duration": "indefinitely",
                    "model_conf": 102.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.38,
                    "duration": "indefinitely",
                    "model_conf": 155.04
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.27,
                    "duration": "2 Months",
                    "model_conf": 281.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.04,
                    "duration": "indefinitely",
                    "model_conf": 163.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.28,
                    "duration": "indefinitely",
                    "model_conf": 167.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.36,
                    "duration": "indefinitely",
                    "model_conf": 156.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.54,
                    "duration": "1 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.2,
                    "duration": "indefinitely",
                    "model_conf": 85.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.1,
                    "duration": "indefinitely",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.98,
                    "duration": "indefinitely",
                    "model_conf": 221.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/lake-mary/3879-lake-emma-rd.html": {
        "name": "Heathrow-Lake Mary Centre",
        "address": "3879 Lake Emma Rd, Lake Mary, Florida, 32746",
        "phone": "(407) 585-3131",
        "url": "https://locations.massageenvy.com/florida/lake-mary/3879-lake-emma-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.75488018637941,
            -81.35661109325409
        ],
        "rating": 4.8,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 91.675,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": null,
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.05,
                    "duration": "indefinitely",
                    "model_conf": 69.74
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 153.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.14,
                    "duration": "indefinitely",
                    "model_conf": 139.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.85,
                    "duration": "indefinitely",
                    "model_conf": 153.17
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.63,
                    "duration": "indefinitely",
                    "model_conf": 270.04
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.12,
                    "duration": "indefinitely",
                    "model_conf": 178.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.56,
                    "duration": "1 Months",
                    "model_conf": 158.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.56,
                    "duration": "indefinitely",
                    "model_conf": 151.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/lake-worth/6169-jog-rd.html": {
        "name": "Lake Worth",
        "address": "6169 Jog Rd, Lake Worth, Florida, 33467",
        "phone": "(561) 964-3689",
        "url": "https://locations.massageenvy.com/florida/lake-worth/6169-jog-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.588016255233626,
            -80.14917969703674
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.86,
                    "duration": "indefinitely",
                    "model_conf": 70.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.93,
                    "duration": "indefinitely",
                    "model_conf": 100.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.09,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.8,
                    "duration": "indefinitely",
                    "model_conf": 274.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 165.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.13,
                    "duration": "indefinitely",
                    "model_conf": 164.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.63,
                    "duration": "indefinitely",
                    "model_conf": 156.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.04,
                    "duration": "indefinitely",
                    "model_conf": 205.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/lakeland/3615-s-florida-ave.html": {
        "name": "Lakeland",
        "address": "3615 S Florida Ave #440, Lakeland Lakeland, Florida, 33803",
        "phone": "(863) 646-3689",
        "url": "https://locations.massageenvy.com/florida/lakeland/3615-s-florida-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.9987558,
            -81.9604385
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 71.03
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.87,
                    "duration": "indefinitely",
                    "model_conf": 157.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.18,
                    "duration": "3 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.83,
                    "duration": "1 Months",
                    "model_conf": 100.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.36,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.27,
                    "duration": "2 Months",
                    "model_conf": 280.03
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 189.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.71,
                    "duration": "indefinitely",
                    "model_conf": 165.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.77,
                    "duration": "1 Months",
                    "model_conf": 162.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.79,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.73,
                    "duration": "indefinitely",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.51,
                    "duration": "1 Months",
                    "model_conf": 237.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.08,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.52,
                    "duration": "1 Months",
                    "model_conf": 217.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/largo/10609-ulmerton-rd.html": {
        "name": "Largo",
        "address": "10609 Ulmerton Rd, Largo Largo, Florida, 33771",
        "phone": "(727) 581-6500",
        "url": "https://locations.massageenvy.com/florida/largo/10609-ulmerton-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.89545414018802,
            -82.78562711772014
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.93,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.8,
                    "duration": "3 Months",
                    "model_conf": 157.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.42,
                    "duration": "1 Months",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.79,
                    "duration": "1 Months",
                    "model_conf": 100.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.33,
                    "duration": "1 Months",
                    "model_conf": 154.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.22,
                    "duration": "2 Months",
                    "model_conf": 279.64
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.1,
                    "duration": "2 Months",
                    "model_conf": 192.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.86,
                    "duration": "indefinitely",
                    "model_conf": 166.37
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.33,
                    "duration": "4 Months",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.3,
                    "duration": "indefinitely",
                    "model_conf": 155.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.47,
                    "duration": "1 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.29,
                    "duration": "2 Months",
                    "model_conf": 233.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 85.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.78,
                    "duration": "1 Months",
                    "model_conf": 55.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.02,
                    "duration": "2 Months",
                    "model_conf": 220.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/lutz/17673-n-dale-mabry-hwy.html": {
        "name": "Lutz",
        "address": "17673 N Dale Mabry Hwy, Lutz, Florida, 33548",
        "phone": "(813) 868-5400",
        "url": "https://locations.massageenvy.com/florida/lutz/17673-n-dale-mabry-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.12630983894709,
            -82.50080443456233
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.37,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.08,
                    "duration": "2 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.03,
                    "duration": "indefinitely",
                    "model_conf": 99.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 154.28
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.51,
                    "duration": "indefinitely",
                    "model_conf": 275.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.83,
                    "duration": "indefinitely",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.06,
                    "duration": "indefinitely",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.43,
                    "duration": "indefinitely",
                    "model_conf": 155.04
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.68,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.04,
                    "duration": "indefinitely",
                    "model_conf": 85.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.91,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.11,
                    "duration": "indefinitely",
                    "model_conf": 219.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/melbourne/2261-town-center-ave.html": {
        "name": "Viera",
        "address": "2261 Town Center Ave #115, Melbourne, Florida, 32940",
        "phone": "(321) 433-3689",
        "url": "https://locations.massageenvy.com/florida/melbourne/2261-town-center-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.2442161,
            -80.7260977
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.81,
                    "duration": "indefinitely",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 112.02,
                    "duration": "indefinitely",
                    "model_conf": 151.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.23,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.38,
                    "duration": "indefinitely",
                    "model_conf": 102.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.64,
                    "duration": "indefinitely",
                    "model_conf": 154.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.58,
                    "duration": "indefinitely",
                    "model_conf": 278.13
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 112.84,
                    "duration": "indefinitely",
                    "model_conf": 177.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.74,
                    "duration": "5 Months",
                    "model_conf": 164.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 47.19,
                    "duration": "indefinitely",
                    "model_conf": 166.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.57,
                    "duration": "3 Months",
                    "model_conf": 156.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.53,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 113.42,
                    "duration": "indefinitely",
                    "model_conf": 227.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.7,
                    "duration": "1 Months",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.7,
                    "duration": "2 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.28,
                    "duration": "indefinitely",
                    "model_conf": 221.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami-beach/767-17th-street.html": {
        "name": "South Beach",
        "address": "767 17th Street, Miami Beach, Florida, 33139",
        "phone": "(305) 370-3689",
        "url": "https://locations.massageenvy.com/florida/miami-beach/767-17th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 10:00 PM",
            "TUESDAY": "11:00 AM - 9:00 PM",
            "WEDNESDAY": "11:00 AM - 9:00 PM",
            "THURSDAY": "11:00 AM - 10:00 PM",
            "FRIDAY": "11:00 AM - 10:00 PM",
            "SATURDAY": "11:00 AM - 9:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            25.792363870160962,
            -80.1361334323883
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 70.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 101.23
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 152.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.32,
                    "duration": "indefinitely",
                    "model_conf": 279.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 164.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 159.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 149.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.2,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 84.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 54.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 214.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami-lakes/15422-nw-77th-court.html": {
        "name": "Miami Lakes",
        "address": "15422 NW 77th Court Suites B&C, Miami Lakes, Florida, 33016",
        "phone": "(305) 558-3689",
        "url": "https://locations.massageenvy.com/florida/miami-lakes/15422-nw-77th-court.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            25.9139102,
            -80.3285599
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 70.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 101.31
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.66,
                    "duration": "indefinitely",
                    "model_conf": 152.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 280.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.74,
                    "duration": "indefinitely",
                    "model_conf": 164.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 165.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 153.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.56,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 85.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 54.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 214.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miramar/12304-miramar-parkway.html": {
        "name": "Miramar",
        "address": "12304 Miramar Parkway, Miramar, Florida, 33025",
        "phone": "(954) 251-5678",
        "url": "https://locations.massageenvy.com/florida/miramar/12304-miramar-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            25.9781605,
            -80.3102382
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 68.67
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.12,
                    "duration": "2 Months",
                    "model_conf": 153.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 99.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 151.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 278.56
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 190.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.02,
                    "duration": "indefinitely",
                    "model_conf": 163.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 163.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 152.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.07,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 232.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 52.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 207.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/naples/6345-naples-blvd.html": {
        "name": "Naples",
        "address": "6345 Naples Blvd Ste A3-4, Naples, Florida, 34109",
        "phone": "(239) 325-3689",
        "url": "https://locations.massageenvy.com/florida/naples/6345-naples-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.219812648885867,
            -81.7732384800911
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.27,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 158.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.45,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.96,
                    "duration": "indefinitely",
                    "model_conf": 100.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.11,
                    "duration": "indefinitely",
                    "model_conf": 155.04
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.06,
                    "duration": "indefinitely",
                    "model_conf": 279.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 194.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.64,
                    "duration": "indefinitely",
                    "model_conf": 165.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.86,
                    "duration": "1 Months",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.63,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.2,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.7,
                    "duration": "indefinitely",
                    "model_conf": 236.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.12,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.87,
                    "duration": "indefinitely",
                    "model_conf": 211.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/north-miami/1815-ne-123rd-st..html": {
        "name": "North Miami",
        "address": "1815 NE 123rd St., North Miami North Miami, Florida, 33181",
        "phone": "(305) 893-4772",
        "url": "https://locations.massageenvy.com/florida/north-miami/1815-ne-123rd-st..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            25.890007874462054,
            -80.16207575798035
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 70.34
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 156.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 101.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 153.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.48,
                    "duration": "indefinitely",
                    "model_conf": 279.73
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 192.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.01,
                    "duration": "indefinitely",
                    "model_conf": 164.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 161.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.41,
                    "duration": "indefinitely",
                    "model_conf": 152.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.09,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 234.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 84.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 54.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.75,
                    "duration": "indefinitely",
                    "model_conf": 216.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/north-palm-beach/11680-us-hwy-1.html": {
        "name": "Pga",
        "address": "11680 US HWY 1 Suite 60, North Palm Beach North Palm Beach, Florida, 33408",
        "phone": "(561) 624-3444",
        "url": "https://locations.massageenvy.com/florida/north-palm-beach/11680-us-hwy-1.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            26.84603568732535,
            -80.05891727976223
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.13,
                    "duration": "indefinitely",
                    "model_conf": 70.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.77,
                    "duration": "indefinitely",
                    "model_conf": 102.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.0,
                    "duration": "indefinitely",
                    "model_conf": 146.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.98,
                    "duration": "indefinitely",
                    "model_conf": 267.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 159.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.64,
                    "duration": "indefinitely",
                    "model_conf": 157.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 139.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.54,
                    "duration": "indefinitely",
                    "model_conf": 218.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/ocala/2701-sw-college-rd..html": {
        "name": "Ocala",
        "address": "2701 SW College Rd. Suite 601, Ocala, Florida, 34474",
        "phone": "(352) 873-3689",
        "url": "https://locations.massageenvy.com/florida/ocala/2701-sw-college-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.167644889745123,
            -82.17030465602875
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.725,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.81,
                    "duration": "indefinitely",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.22,
                    "duration": "2 Months",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.67,
                    "duration": "2 Months",
                    "model_conf": 102.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.8,
                    "duration": "indefinitely",
                    "model_conf": 154.9
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.33,
                    "duration": "5 Months",
                    "model_conf": 283.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.93,
                    "duration": "indefinitely",
                    "model_conf": 166.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.32,
                    "duration": "4 Months",
                    "model_conf": 168.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.07,
                    "duration": "indefinitely",
                    "model_conf": 157.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.08,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.23,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.09,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.63,
                    "duration": "3 Months",
                    "model_conf": 221.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orange-city/985-harley-strickland-blvd.html": {
        "name": "Orange City",
        "address": "985 Harley Strickland Blvd Suite 300, Orange City, Florida, 32763",
        "phone": "(386) 878-8045",
        "url": "https://locations.massageenvy.com/florida/orange-city/985-harley-strickland-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.918748235933872,
            -81.28591719692419
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/florida/ormond-beach/332-n-nova-road.html": {
        "name": "Ormond Beach",
        "address": "332 N Nova Road, Ormond Beach, Florida, 32174",
        "phone": "(386) 944-1970",
        "url": "https://locations.massageenvy.com/florida/ormond-beach/332-n-nova-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.285150928284903,
            -81.0839762421614
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.72,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.7,
                    "duration": "1 Months",
                    "model_conf": 159.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.45,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.76,
                    "duration": "2 Months",
                    "model_conf": 102.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.73,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.29,
                    "duration": "2 Months",
                    "model_conf": 277.33
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.32,
                    "duration": "2 Months",
                    "model_conf": 193.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.68,
                    "duration": "1 Months",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.32,
                    "duration": "5 Months",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.6,
                    "duration": "indefinitely",
                    "model_conf": 157.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.43,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.65,
                    "duration": "1 Months",
                    "model_conf": 237.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.96,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.09,
                    "duration": "indefinitely",
                    "model_conf": 55.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.37,
                    "duration": "1 Months",
                    "model_conf": 221.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/oviedo/73-alafaya-woods-blvd.html": {
        "name": "Oviedo",
        "address": "73 Alafaya Woods Blvd, Oviedo, Florida, 32765",
        "phone": "(407) 366-6400",
        "url": "https://locations.massageenvy.com/florida/oviedo/73-alafaya-woods-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.650962,
            -81.205168
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 70.76
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 153.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.42,
                    "duration": "indefinitely",
                    "model_conf": 97.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 153.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.26,
                    "duration": "indefinitely",
                    "model_conf": 275.6
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.32,
                    "duration": "indefinitely",
                    "model_conf": 188.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 161.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.57,
                    "duration": "indefinitely",
                    "model_conf": 160.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 151.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.12,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.69,
                    "duration": "indefinitely",
                    "model_conf": 228.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 84.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 54.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.45,
                    "duration": "indefinitely",
                    "model_conf": 212.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/palm-beach-gardens/3938-northlake-blvd.html": {
        "name": "Palm Beach Gardens",
        "address": "3938 Northlake Blvd, Palm Beach Gardens Palm Beach Gardens, Florida, 33403",
        "phone": "(561) 627-3689",
        "url": "https://locations.massageenvy.com/florida/palm-beach-gardens/3938-northlake-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.8061592,
            -80.096671
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.96,
                    "duration": "indefinitely",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.21,
                    "duration": "indefinitely",
                    "model_conf": 158.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.81,
                    "duration": "indefinitely",
                    "model_conf": 101.99
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.63,
                    "duration": "indefinitely",
                    "model_conf": 144.17
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.32,
                    "duration": "indefinitely",
                    "model_conf": 264.12
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.39,
                    "duration": "indefinitely",
                    "model_conf": 191.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.72,
                    "duration": "indefinitely",
                    "model_conf": 157.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.37,
                    "duration": "indefinitely",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.34,
                    "duration": "1 Months",
                    "model_conf": 154.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.95,
                    "duration": "indefinitely",
                    "model_conf": 139.23
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.57,
                    "duration": "indefinitely",
                    "model_conf": 231.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.83,
                    "duration": "indefinitely",
                    "model_conf": 85.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.89,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.51,
                    "duration": "indefinitely",
                    "model_conf": 218.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/palm-city/2927-sw-high-meadows-ave.html": {
        "name": "Palm City",
        "address": "2927 SW High Meadows Ave, Palm City, Florida, 34990",
        "phone": "(772) 287-1177",
        "url": "https://locations.massageenvy.com/florida/palm-city/2927-sw-high-meadows-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            27.1696292,
            -80.293589
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.27,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.45,
                    "duration": "indefinitely",
                    "model_conf": 157.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.13,
                    "duration": "2 Months",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 99.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.72,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.38,
                    "duration": "indefinitely",
                    "model_conf": 282.28
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.85,
                    "duration": "indefinitely",
                    "model_conf": 187.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.69,
                    "duration": "1 Months",
                    "model_conf": 165.74
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.62,
                    "duration": "indefinitely",
                    "model_conf": 167.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 157.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.41,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.81,
                    "duration": "indefinitely",
                    "model_conf": 238.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.73,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.93,
                    "duration": "indefinitely",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.11,
                    "duration": "indefinitely",
                    "model_conf": 209.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/palm-coast/250-palm-coast-parkway-ne.html": {
        "name": "Palm Coast",
        "address": "250 Palm Coast Parkway NE #210-211, Palm Coast Palm Coast, Florida, 32137",
        "phone": "(386) 463-3689",
        "url": "https://locations.massageenvy.com/florida/palm-coast/250-palm-coast-parkway-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.557381,
            -81.209973
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.8,
                    "duration": "3 Months",
                    "model_conf": 139.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 101.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.22,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.63,
                    "duration": "3 Months",
                    "model_conf": 282.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 166.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 168.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.65,
                    "duration": "indefinitely",
                    "model_conf": 157.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 220.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/palm-harbor/34848-us-highway-19n.html": {
        "name": "Palm Harbor",
        "address": "34848 US Highway 19N, Palm Harbor, Florida, 34684",
        "phone": "(727) 787-3689",
        "url": "https://locations.massageenvy.com/florida/palm-harbor/34848-us-highway-19n.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            28.088780208911523,
            -82.74081804662706
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.45,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 98.97,
                    "duration": "4 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.2,
                    "duration": "6 Months",
                    "model_conf": 100.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.89,
                    "duration": "indefinitely",
                    "model_conf": 154.1
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.21,
                    "duration": "2 Months",
                    "model_conf": 275.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 101.11,
                    "duration": "indefinitely",
                    "model_conf": 166.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.0,
                    "duration": "7 Months",
                    "model_conf": 166.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.88,
                    "duration": "indefinitely",
                    "model_conf": 154.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.92,
                    "duration": "indefinitely",
                    "model_conf": 140.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.47,
                    "duration": "indefinitely",
                    "model_conf": 85.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.24,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.43,
                    "duration": "1 Months",
                    "model_conf": 219.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/palmetto-bay/13605-s-dixie-hwy.html": {
        "name": "The Falls",
        "address": "13605 S Dixie Hwy, Palmetto Bay Palmetto Bay, Florida, 33156",
        "phone": "(786) 430-4100",
        "url": "https://locations.massageenvy.com/florida/palmetto-bay/13605-s-dixie-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            25.642075223063465,
            -80.33381763954685
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 69.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 99.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.29,
                    "duration": "1 Months",
                    "model_conf": 152.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 279.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 163.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.16,
                    "duration": "5 Months",
                    "model_conf": 158.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 148.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.95,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 84.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.31,
                    "duration": "indefinitely",
                    "model_conf": 54.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.8,
                    "duration": "2 Months",
                    "model_conf": 210.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/panama-city-beach/15801-l-c-hilton-jr-dr.html": {
        "name": "Pier Park",
        "address": "15801 L C Hilton Jr Dr Ste 120, Panama City Beach Panama City Beach, Florida, 32413",
        "phone": "(850) 563-0070",
        "url": "https://locations.massageenvy.com/florida/panama-city-beach/15801-l-c-hilton-jr-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            30.218445,
            -85.874608
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.88,
                    "duration": "indefinitely",
                    "model_conf": 159.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.96,
                    "duration": "indefinitely",
                    "model_conf": 100.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.14,
                    "duration": "indefinitely",
                    "model_conf": 154.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.44,
                    "duration": "indefinitely",
                    "model_conf": 282.9
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.08,
                    "duration": "indefinitely",
                    "model_conf": 192.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 166.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.84,
                    "duration": "indefinitely",
                    "model_conf": 167.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.48,
                    "duration": "1 Months",
                    "model_conf": 157.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.19,
                    "duration": "indefinitely",
                    "model_conf": 237.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 85.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 55.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.16,
                    "duration": "indefinitely",
                    "model_conf": 221.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/pembroke-pines/14912-pines-blvd.html": {
        "name": "Pembroke Pines",
        "address": "14912 Pines Blvd, Pembroke Pines, Florida, 33027",
        "phone": "(954) 430-4456",
        "url": "https://locations.massageenvy.com/florida/pembroke-pines/14912-pines-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.0062965,
            -80.3479312
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 69.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 99.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 152.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.39,
                    "duration": "indefinitely",
                    "model_conf": 279.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.07,
                    "duration": "indefinitely",
                    "model_conf": 163.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.49,
                    "duration": "3 Months",
                    "model_conf": 164.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.5,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.57,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 52.96
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 210.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/pensacola/6601-north-davis-highway.html": {
        "name": "Pensacola",
        "address": "6601 North Davis Highway Suite 37A, Pensacola Pensacola, Florida, 32504",
        "phone": "(850) 484-3689",
        "url": "https://locations.massageenvy.com/florida/pensacola/6601-north-davis-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.4927259,
            -87.2265761
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.48,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 159.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.38,
                    "duration": "5 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.43,
                    "duration": "indefinitely",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.86,
                    "duration": "indefinitely",
                    "model_conf": 154.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.9,
                    "duration": "indefinitely",
                    "model_conf": 282.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 195.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 166.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.71,
                    "duration": "indefinitely",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.99,
                    "duration": "indefinitely",
                    "model_conf": 157.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.93,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.53,
                    "duration": "indefinitely",
                    "model_conf": 238.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.63,
                    "duration": "1 Months",
                    "model_conf": 85.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.75,
                    "duration": "1 Months",
                    "model_conf": 55.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.06,
                    "duration": "indefinitely",
                    "model_conf": 221.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/plantation/12101-west-sunrise-blvd.html": {
        "name": "Sawgrass/Plantation",
        "address": "12101 West Sunrise Blvd, Plantation, Florida, 33323",
        "phone": "(954) 370-3689",
        "url": "https://locations.massageenvy.com/florida/plantation/12101-west-sunrise-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.147049,
            -80.3094037
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.42,
                    "duration": "indefinitely",
                    "model_conf": 70.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.08,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.26,
                    "duration": "indefinitely",
                    "model_conf": 100.85
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.87,
                    "duration": "2 Months",
                    "model_conf": 152.51
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.1,
                    "duration": "indefinitely",
                    "model_conf": 280.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 163.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.03,
                    "duration": "indefinitely",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.06,
                    "duration": "2 Months",
                    "model_conf": 154.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.09,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.54,
                    "duration": "1 Months",
                    "model_conf": 85.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.63,
                    "duration": "2 Months",
                    "model_conf": 53.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.35,
                    "duration": "indefinitely",
                    "model_conf": 216.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/pompano-beach/1159-south-federal-highway.html": {
        "name": "Pompano Beach",
        "address": "1159 South Federal Highway, Pompano Beach Pompano Beach, Florida, 33062",
        "phone": "(954) 946-7600",
        "url": "https://locations.massageenvy.com/florida/pompano-beach/1159-south-federal-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.216564199990756,
            -80.10825455188751
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 70.25
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 154.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 101.02
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.75,
                    "duration": "indefinitely",
                    "model_conf": 152.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.04,
                    "duration": "indefinitely",
                    "model_conf": 277.72
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 191.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 163.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 162.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.56,
                    "duration": "2 Months",
                    "model_conf": 152.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.69,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.16,
                    "duration": "indefinitely",
                    "model_conf": 230.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 85.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.77,
                    "duration": "1 Months",
                    "model_conf": 54.17
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.96,
                    "duration": "indefinitely",
                    "model_conf": 215.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/port-charlotte/1808-tamiami-trail.html": {
        "name": "Port Charlotte",
        "address": "1808 Tamiami Trail, Port Charlotte Port Charlotte, Florida, 33948",
        "phone": "(941) 629-3689",
        "url": "https://locations.massageenvy.com/florida/port-charlotte/1808-tamiami-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            27.00756439029524,
            -82.13172023677066
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.54,
                    "duration": "indefinitely",
                    "model_conf": 70.66
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.77,
                    "duration": "indefinitely",
                    "model_conf": 158.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.97,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.45,
                    "duration": "indefinitely",
                    "model_conf": 102.15
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.61,
                    "duration": "indefinitely",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.75,
                    "duration": "indefinitely",
                    "model_conf": 282.76
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.95,
                    "duration": "indefinitely",
                    "model_conf": 191.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.09,
                    "duration": "indefinitely",
                    "model_conf": 153.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.53,
                    "duration": "indefinitely",
                    "model_conf": 160.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.08,
                    "duration": "indefinitely",
                    "model_conf": 157.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.22,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.97,
                    "duration": "indefinitely",
                    "model_conf": 238.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.21,
                    "duration": "indefinitely",
                    "model_conf": 85.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.08,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.7,
                    "duration": "indefinitely",
                    "model_conf": 217.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/port-orange/5535-s-williamson-blvd.html": {
        "name": "Port Orange",
        "address": "5535 S Williamson Blvd Ste 645, Port Orange Port Orange, Florida, 32128",
        "phone": "(386) 523-2828",
        "url": "https://locations.massageenvy.com/florida/port-orange/5535-s-williamson-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.109979,
            -81.032575
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.95,
                    "duration": "indefinitely",
                    "model_conf": 71.42
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.43,
                    "duration": "indefinitely",
                    "model_conf": 159.03
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.07,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.38,
                    "duration": "indefinitely",
                    "model_conf": 102.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.94,
                    "duration": "indefinitely",
                    "model_conf": 153.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.77,
                    "duration": "indefinitely",
                    "model_conf": 280.53
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.4,
                    "duration": "indefinitely",
                    "model_conf": 185.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.93,
                    "duration": "4 Months",
                    "model_conf": 164.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.16,
                    "duration": "indefinitely",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.8,
                    "duration": "2 Months",
                    "model_conf": 156.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.87,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.04,
                    "duration": "indefinitely",
                    "model_conf": 237.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.74,
                    "duration": "1 Months",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.95,
                    "duration": "indefinitely",
                    "model_conf": 55.44
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.62,
                    "duration": "indefinitely",
                    "model_conf": 221.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/port-st.-lucie/1707-nw-st-lucie-west-blvd.html": {
        "name": "Port St. Lucie West",
        "address": "1707 NW St Lucie West Blvd Ste 146, Port St. Lucie, Florida, 34986",
        "phone": "(772) 344-0222",
        "url": "https://locations.massageenvy.com/florida/port-st.-lucie/1707-nw-st-lucie-west-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.3131298,
            -80.4047437
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.76,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 158.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 101.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.68,
                    "duration": "indefinitely",
                    "model_conf": 155.1
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.14,
                    "duration": "2 Months",
                    "model_conf": 281.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 186.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 166.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.15,
                    "duration": "indefinitely",
                    "model_conf": 157.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.89,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.15,
                    "duration": "2 Months",
                    "model_conf": 237.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 218.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/royal-palm-beach/11021-southern-blvd.html": {
        "name": "Royal Palm Beach",
        "address": "11021 Southern Blvd Ste 100, Royal Palm Beach Royal Palm Beach, Florida, 33411",
        "phone": "(561) 422-8889",
        "url": "https://locations.massageenvy.com/florida/royal-palm-beach/11021-southern-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            26.683293643428822,
            -80.22259218216811
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.67,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 100.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.17,
                    "duration": "indefinitely",
                    "model_conf": 153.19
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.21,
                    "duration": "indefinitely",
                    "model_conf": 275.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 165.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 164.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 156.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.78,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.85,
                    "duration": "indefinitely",
                    "model_conf": 208.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/ruskin/3726-sun-city-center-blvd.html": {
        "name": "Sun City",
        "address": "3726 Sun City Center Blvd Suite 102, Ruskin, Florida, 33573",
        "phone": "(813) 419-1047",
        "url": "https://locations.massageenvy.com/florida/ruskin/3726-sun-city-center-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.713180524320986,
            -82.37582195717067
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.75,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.82,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.66,
                    "duration": "1 Months",
                    "model_conf": 159.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.03,
                    "duration": "indefinitely",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.83,
                    "duration": "1 Months",
                    "model_conf": 101.89
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.91,
                    "duration": "indefinitely",
                    "model_conf": 154.88
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.71,
                    "duration": "4 Months",
                    "model_conf": 279.64
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.96,
                    "duration": "3 Months",
                    "model_conf": 189.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.45,
                    "duration": "indefinitely",
                    "model_conf": 166.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.53,
                    "duration": "3 Months",
                    "model_conf": 167.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.8,
                    "duration": "indefinitely",
                    "model_conf": 155.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.72,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.98,
                    "duration": "3 Months",
                    "model_conf": 235.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.04,
                    "duration": "indefinitely",
                    "model_conf": 85.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.01,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.96,
                    "duration": "2 Months",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/sanford/1465-wp-ball-blvd.html": {
        "name": "Lake Mary/Sanford",
        "address": "1465 Wp Ball Blvd, Sanford, Florida, 32771",
        "phone": "(407) 333-9599",
        "url": "https://locations.massageenvy.com/florida/sanford/1465-wp-ball-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.799566,
            -81.335251
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.12,
                    "duration": "indefinitely",
                    "model_conf": 68.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.23,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.64,
                    "duration": "indefinitely",
                    "model_conf": 92.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.78,
                    "duration": "indefinitely",
                    "model_conf": 153.9
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.77,
                    "duration": "indefinitely",
                    "model_conf": 271.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.54,
                    "duration": "6 Months",
                    "model_conf": 155.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.98,
                    "duration": "indefinitely",
                    "model_conf": 163.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.77,
                    "duration": "2 Months",
                    "model_conf": 150.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.54,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.76,
                    "duration": "1 Months",
                    "model_conf": 84.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.06,
                    "duration": "indefinitely",
                    "model_conf": 54.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.91,
                    "duration": "indefinitely",
                    "model_conf": 197.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/st-johns/90-shops-blvd.html": {
        "name": "St Johns Parkway",
        "address": "90 Shops Blvd Unit 30, St Johns, Florida, 32259",
        "phone": "(904) 201-2434",
        "url": "https://locations.massageenvy.com/florida/st-johns/90-shops-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.06153383013609,
            -81.5325848537213
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 281.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 164.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.67,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.95,
                    "duration": "indefinitely",
                    "model_conf": 155.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/stuart/1503-nw-federal-hwy.html": {
        "name": "Stuart",
        "address": "1503 NW Federal Hwy, Stuart, Florida, 34994",
        "phone": "(772) 497-7500",
        "url": "https://locations.massageenvy.com/florida/stuart/1503-nw-federal-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.223743339377325,
            -80.26362016797066
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.3,
                    "duration": "2 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.13,
                    "duration": "indefinitely",
                    "model_conf": 98.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.63,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.98,
                    "duration": "indefinitely",
                    "model_conf": 282.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.84,
                    "duration": "indefinitely",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.5,
                    "duration": "indefinitely",
                    "model_conf": 167.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.69,
                    "duration": "indefinitely",
                    "model_conf": 157.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.14,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.84,
                    "duration": "indefinitely",
                    "model_conf": 85.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.98,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.18,
                    "duration": "indefinitely",
                    "model_conf": 212.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/tallahassee/2887-kerry-forest-parkway.html": {
        "name": "Kerry Forest",
        "address": "2887 Kerry Forest Parkway, Tallahassee Tallahassee, Florida, 32312",
        "phone": "(850) 422-3689",
        "url": "https://locations.massageenvy.com/florida/tallahassee/2887-kerry-forest-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.542510111462754,
            -84.22974073893602
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.99,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.43,
                    "duration": "2 Months",
                    "model_conf": 158.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.02,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.41,
                    "duration": "5 Months",
                    "model_conf": 102.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.95,
                    "duration": "indefinitely",
                    "model_conf": 154.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.92,
                    "duration": "6 Months",
                    "model_conf": 283.03
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.83,
                    "duration": "4 Months",
                    "model_conf": 195.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.05,
                    "duration": "indefinitely",
                    "model_conf": 166.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.03,
                    "duration": "8 Months",
                    "model_conf": 163.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.37,
                    "duration": "indefinitely",
                    "model_conf": 157.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.84,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.41,
                    "duration": "5 Months",
                    "model_conf": 233.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.74,
                    "duration": "indefinitely",
                    "model_conf": 86.02
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.44,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.2,
                    "duration": "4 Months",
                    "model_conf": 220.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/the-villages/1004-bichara-blvd.html": {
        "name": "The Villages",
        "address": "1004 Bichara Blvd, The Villages The Villages, Florida, 32159",
        "phone": "(352) 430-3689",
        "url": "https://locations.massageenvy.com/florida/the-villages/1004-bichara-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.937329723629677,
            -81.94591830349725
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.25,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.24,
                    "duration": "3 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.69,
                    "duration": "2 Months",
                    "model_conf": 100.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.35,
                    "duration": "indefinitely",
                    "model_conf": 154.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.18,
                    "duration": "2 Months",
                    "model_conf": 283.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.55,
                    "duration": "indefinitely",
                    "model_conf": 166.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.33,
                    "duration": "5 Months",
                    "model_conf": 167.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.83,
                    "duration": "indefinitely",
                    "model_conf": 157.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.79,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.24,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.2,
                    "duration": "indefinitely",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.39,
                    "duration": "1 Months",
                    "model_conf": 219.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/titusville/2530-s-washington-ave.html": {
        "name": "Titusville",
        "address": "2530 S Washington Ave Ste 110, Titusville, Florida, 32780",
        "phone": "(321) 222-1144",
        "url": "https://locations.massageenvy.com/florida/titusville/2530-s-washington-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.5856331,
            -80.802764
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.4,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.5,
                    "duration": "indefinitely",
                    "model_conf": 158.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.93,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.32,
                    "duration": "indefinitely",
                    "model_conf": 100.8
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.26,
                    "duration": "indefinitely",
                    "model_conf": 154.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.51,
                    "duration": "indefinitely",
                    "model_conf": 283.26
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.75,
                    "duration": "indefinitely",
                    "model_conf": 195.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.12,
                    "duration": "indefinitely",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.27,
                    "duration": "indefinitely",
                    "model_conf": 164.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.97,
                    "duration": "indefinitely",
                    "model_conf": 155.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.09,
                    "duration": "indefinitely",
                    "model_conf": 140.45
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.71,
                    "duration": "indefinitely",
                    "model_conf": 236.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.09,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.02,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.32,
                    "duration": "indefinitely",
                    "model_conf": 217.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/venice/1695-us-41-bypass-s.html": {
        "name": "Venice",
        "address": "1695 US 41 Bypass S Unit 8, Venice, Florida, 34293",
        "phone": "(941) 584-3331",
        "url": "https://locations.massageenvy.com/florida/venice/1695-us-41-bypass-s.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            27.07375978650186,
            -82.42419380985889
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.7,
                    "duration": "indefinitely",
                    "model_conf": 283.04
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.77,
                    "duration": "indefinitely",
                    "model_conf": 194.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.56,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.61,
                    "duration": "2 Months",
                    "model_conf": 167.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.45,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.73,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.65,
                    "duration": "1 Months",
                    "model_conf": 238.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.29,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.23,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.72,
                    "duration": "indefinitely",
                    "model_conf": 221.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/vero-beach/5820-20th-st.html": {
        "name": "Vero Beach",
        "address": "5820 20th St, Vero Beach, Florida, 32966",
        "phone": "(772) 794-9411",
        "url": "https://locations.massageenvy.com/florida/vero-beach/5820-20th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.6406378,
            -80.4484344
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 102.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.86,
                    "duration": "3 Months",
                    "model_conf": 281.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 162.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 167.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.34,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.3,
                    "duration": "1 Months",
                    "model_conf": 221.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/wellington/2615-state-road-7.html": {
        "name": "Wellington",
        "address": "2615 State Road 7, Wellington, Florida, 33414",
        "phone": "(561) 692-7777",
        "url": "https://locations.massageenvy.com/florida/wellington/2615-state-road-7.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.6421924,
            -80.2061222
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.57,
                    "duration": "indefinitely",
                    "model_conf": 70.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.07,
                    "duration": "indefinitely",
                    "model_conf": 100.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.8,
                    "duration": "indefinitely",
                    "model_conf": 152.24
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.93,
                    "duration": "indefinitely",
                    "model_conf": 272.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 164.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 163.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 156.44
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.84,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.41,
                    "duration": "indefinitely",
                    "model_conf": 199.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/wesley-chapel/1821-bruce-b-downs-blvd.html": {
        "name": "Wesley Chapel",
        "address": "1821 Bruce B Downs Blvd Ste 124, Wesley Chapel Wesley Chapel, Florida, 33544",
        "phone": "(813) 948-5600",
        "url": "https://locations.massageenvy.com/florida/wesley-chapel/1821-bruce-b-downs-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            28.183206527241396,
            -82.35455212372096
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.77,
                    "duration": "indefinitely",
                    "model_conf": 70.87
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.22,
                    "duration": "indefinitely",
                    "model_conf": 158.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.66,
                    "duration": "1 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.16,
                    "duration": "indefinitely",
                    "model_conf": 99.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.49,
                    "duration": "indefinitely",
                    "model_conf": 154.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.74,
                    "duration": "3 Months",
                    "model_conf": 278.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.23,
                    "duration": "2 Months",
                    "model_conf": 190.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 166.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 167.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.56,
                    "duration": "indefinitely",
                    "model_conf": 155.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.88,
                    "duration": "3 Months",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.12,
                    "duration": "2 Months",
                    "model_conf": 235.25
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 85.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.04,
                    "duration": "indefinitely",
                    "model_conf": 55.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.78,
                    "duration": "indefinitely",
                    "model_conf": 219.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/west-melbourne/4311-norfolk-parkway.html": {
        "name": "West Melbourne",
        "address": "4311 Norfolk Parkway Suite 112, West Melbourne West Melbourne, Florida, 32904",
        "phone": "(321) 608-4295",
        "url": "https://locations.massageenvy.com/florida/west-melbourne/4311-norfolk-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.03775807682849,
            -80.6609898022391
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.09,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.77,
                    "duration": "indefinitely",
                    "model_conf": 139.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.15,
                    "duration": "indefinitely",
                    "model_conf": 102.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.25,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.68,
                    "duration": "indefinitely",
                    "model_conf": 280.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.08,
                    "duration": "3 Months",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.28,
                    "duration": "indefinitely",
                    "model_conf": 168.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.24,
                    "duration": "1 Months",
                    "model_conf": 155.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.57,
                    "duration": "1 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.62,
                    "duration": "1 Months",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.81,
                    "duration": "1 Months",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.51,
                    "duration": "indefinitely",
                    "model_conf": 221.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/west-palm-beach/851-village-blvd.html": {
        "name": "West Palm Beach",
        "address": "851 Village Blvd Ste 503, West Palm Beach West Palm Beach, Florida, 33409",
        "phone": "(561) 333-3323",
        "url": "https://locations.massageenvy.com/florida/west-palm-beach/851-village-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:30 AM - 6:00 PM"
        },
        "geolocation": [
            26.714050835166308,
            -80.10214341302338
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.21,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.71,
                    "duration": "3 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.64,
                    "duration": "indefinitely",
                    "model_conf": 102.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.35,
                    "duration": "indefinitely",
                    "model_conf": 152.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.24,
                    "duration": "indefinitely",
                    "model_conf": 277.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 164.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.13,
                    "duration": "indefinitely",
                    "model_conf": 164.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.93,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 55.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.38,
                    "duration": "indefinitely",
                    "model_conf": 218.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/weston/1374-weston-road.html": {
        "name": "Weston",
        "address": "1374 Weston Road, Weston, Florida, 33326",
        "phone": "(954) 660-3689",
        "url": "https://locations.massageenvy.com/florida/weston/1374-weston-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.1034658,
            -80.3635014
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 69.72
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.05,
                    "duration": "2 Months",
                    "model_conf": 156.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 100.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.69,
                    "duration": "2 Months",
                    "model_conf": 152.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.64,
                    "duration": "indefinitely",
                    "model_conf": 281.22
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 189.45
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 163.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.66,
                    "duration": "2 Months",
                    "model_conf": 165.49
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.27,
                    "duration": "1 Months",
                    "model_conf": 155.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.56,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 235.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 85.17
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 53.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 212.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/winter-garden/3025-daniels-road.html": {
        "name": "Winter Garden",
        "address": "3025 Daniels Road Suite 104, Winter Garden Winter Garden, Florida, 34787",
        "phone": "(321) 221-9966",
        "url": "https://locations.massageenvy.com/florida/winter-garden/3025-daniels-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.524747068777,
            -81.58412825251906
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.08,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.2,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.81,
                    "duration": "1 Months",
                    "model_conf": 101.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.27,
                    "duration": "indefinitely",
                    "model_conf": 154.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.78,
                    "duration": "indefinitely",
                    "model_conf": 281.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.14,
                    "duration": "indefinitely",
                    "model_conf": 165.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.79,
                    "duration": "1 Months",
                    "model_conf": 166.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.44,
                    "duration": "indefinitely",
                    "model_conf": 156.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.85,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.19,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.4,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.89,
                    "duration": "indefinitely",
                    "model_conf": 220.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/winter-park/1919-aloma-avenue.html": {
        "name": "Winter Park",
        "address": "1919 Aloma Avenue, Winter Park Winter Park, Florida, 32792",
        "phone": "(407) 875-3689",
        "url": "https://locations.massageenvy.com/florida/winter-park/1919-aloma-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.601335,
            -81.326929
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.01,
                    "duration": "indefinitely",
                    "model_conf": 67.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.23,
                    "duration": "indefinitely",
                    "model_conf": 138.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.79,
                    "duration": "1 Months",
                    "model_conf": 67.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.6,
                    "duration": "indefinitely",
                    "model_conf": 139.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.76,
                    "duration": "indefinitely",
                    "model_conf": 229.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.72,
                    "duration": "1 Months",
                    "model_conf": 128.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.8,
                    "duration": "1 Months",
                    "model_conf": 112.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.26,
                    "duration": "indefinitely",
                    "model_conf": 130.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.02,
                    "duration": "indefinitely",
                    "model_conf": 138.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.14,
                    "duration": "indefinitely",
                    "model_conf": 79.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.16,
                    "duration": "indefinitely",
                    "model_conf": 49.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.21,
                    "duration": "indefinitely",
                    "model_conf": 153.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/acworth/3384-cobb-parkway.html": {
        "name": "Acworth",
        "address": "3384 Cobb Parkway Ste 110/120, Acworth Acworth, Georgia, 30101",
        "phone": "(770) 974-0880",
        "url": "https://locations.massageenvy.com/georgia/acworth/3384-cobb-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.0368356,
            -84.6849469
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 79.525,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "100",
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "87",
                        "servicePrice": "72",
                        "memberAdditional": "52",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "122"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": "90",
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "100",
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": "90",
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": "36",
                        "memberAdditional": "26",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": "36",
                        "memberAdditional": "26",
                        "memberUpgrade": null,
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 115.06,
                    "duration": "indefinitely",
                    "model_conf": 71.12
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.8,
                    "duration": "indefinitely",
                    "model_conf": 158.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.81,
                    "duration": "5 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 39.86,
                    "duration": "1 Months",
                    "model_conf": 99.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.09,
                    "duration": "indefinitely",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 115.89,
                    "duration": "indefinitely",
                    "model_conf": 277.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.34,
                    "duration": "indefinitely",
                    "model_conf": 193.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.17,
                    "duration": "indefinitely",
                    "model_conf": 163.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 40.09,
                    "duration": "indefinitely",
                    "model_conf": 164.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.83,
                    "duration": "indefinitely",
                    "model_conf": 156.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 115.67,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.2,
                    "duration": "indefinitely",
                    "model_conf": 232.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.6,
                    "duration": "1 Months",
                    "model_conf": 84.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 39.85,
                    "duration": "1 Months",
                    "model_conf": 55.17
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.12,
                    "duration": "indefinitely",
                    "model_conf": 216.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/alpharetta/5530-windward-pkwy.html": {
        "name": "Alpharetta",
        "address": "5530 Windward Pkwy Bldg G, Alpharetta Alpharetta, Georgia, 30004",
        "phone": "(678) 336-1280",
        "url": "https://locations.massageenvy.com/georgia/alpharetta/5530-windward-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.0887164,
            -84.2677097
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.02,
                    "duration": "2 Months",
                    "model_conf": 156.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.59,
                    "duration": "4 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.88,
                    "duration": "6 Months",
                    "model_conf": 89.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 152.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.68,
                    "duration": "6 Months",
                    "model_conf": 268.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.35,
                    "duration": "4 Months",
                    "model_conf": 175.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.79,
                    "duration": "indefinitely",
                    "model_conf": 148.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.55,
                    "duration": "8 Months",
                    "model_conf": 157.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.83,
                    "duration": "indefinitely",
                    "model_conf": 152.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.79,
                    "duration": "6 Months",
                    "model_conf": 216.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 84.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 54.36
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.13,
                    "duration": "3 Months",
                    "model_conf": 211.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/athens/1850-epps-bridge-parkway.html": {
        "name": "Athens",
        "address": "1850 Epps Bridge Parkway, Athens Athens, Georgia, 30606",
        "phone": "(706) 543-3000",
        "url": "https://locations.massageenvy.com/georgia/athens/1850-epps-bridge-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "12:00 PM - 6:00 PM"
        },
        "geolocation": [
            33.915420226982484,
            -83.44319591256391
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.29,
                    "duration": "indefinitely",
                    "model_conf": 281.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 195.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.35,
                    "duration": "indefinitely",
                    "model_conf": 166.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.15,
                    "duration": "indefinitely",
                    "model_conf": 168.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.31,
                    "duration": "indefinitely",
                    "model_conf": 157.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.99,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.22,
                    "duration": "indefinitely",
                    "model_conf": 238.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.08,
                    "duration": "indefinitely",
                    "model_conf": 85.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.04,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 208.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/augusta/210-robert-c-daniel-jr-pkwy.html": {
        "name": "Augusta Exchange",
        "address": "210 Robert C Daniel Jr Pkwy Ste E/F, Augusta Augusta, Georgia, 30909",
        "phone": "(706) 738-0288",
        "url": "https://locations.massageenvy.com/georgia/augusta/210-robert-c-daniel-jr-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.491365,
            -82.077454
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.38,
                    "duration": "1 Months",
                    "model_conf": 71.5
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.88,
                    "duration": "3 Months",
                    "model_conf": 158.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.98,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.19,
                    "duration": "4 Months",
                    "model_conf": 102.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.75,
                    "duration": "2 Months",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.59,
                    "duration": "6 Months",
                    "model_conf": 282.55
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.25,
                    "duration": "5 Months",
                    "model_conf": 194.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.82,
                    "duration": "indefinitely",
                    "model_conf": 165.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.37,
                    "duration": "9 Months",
                    "model_conf": 168.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.6,
                    "duration": "indefinitely",
                    "model_conf": 155.15
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.08,
                    "duration": "5 Months",
                    "model_conf": 235.32
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.31,
                    "duration": "indefinitely",
                    "model_conf": 54.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.64,
                    "duration": "4 Months",
                    "model_conf": 218.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/austell/4180-austell-road.html": {
        "name": "Austell",
        "address": "4180 Austell Road, Austell Austell, Georgia, 30106",
        "phone": "(678) 903-0300",
        "url": "https://locations.massageenvy.com/georgia/austell/4180-austell-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.851749,
            -84.606289
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 157.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.16,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 100.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.7,
                    "duration": "indefinitely",
                    "model_conf": 278.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 192.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.59,
                    "duration": "1 Months",
                    "model_conf": 164.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 164.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.16,
                    "duration": "indefinitely",
                    "model_conf": 155.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.96,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 230.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 85.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 55.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.03,
                    "duration": "indefinitely",
                    "model_conf": 216.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/buford/3420-buford-drive.html": {
        "name": "Mall Of Georgia",
        "address": "3420 Buford Drive Suite E-770, Buford, Georgia, 30519",
        "phone": "(678) 541-6868",
        "url": "https://locations.massageenvy.com/georgia/buford/3420-buford-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.0755542,
            -83.986531
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.25,
                    "duration": "2 Months",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.5,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.52,
                    "duration": "3 Months",
                    "model_conf": 98.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.38,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.39,
                    "duration": "7 Months",
                    "model_conf": 278.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.54,
                    "duration": "1 Months",
                    "model_conf": 163.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.19,
                    "duration": "5 Months",
                    "model_conf": 164.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.74,
                    "duration": "indefinitely",
                    "model_conf": 156.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.83,
                    "duration": "3 Months",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.4,
                    "duration": "indefinitely",
                    "model_conf": 85.07
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.58,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.66,
                    "duration": "2 Months",
                    "model_conf": 216.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/canton/1810-cumming-hwy.html": {
        "name": "Canton - GA",
        "address": "1810 Cumming Hwy Suite 620, Canton Canton, Georgia, 30115",
        "phone": "(770) 345-4772",
        "url": "https://locations.massageenvy.com/georgia/canton/1810-cumming-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.23673328815005,
            -84.46143983201603
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.36,
                    "duration": "indefinitely",
                    "model_conf": 71.26
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.53,
                    "duration": "1 Months",
                    "model_conf": 158.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.73,
                    "duration": "4 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.7,
                    "duration": "1 Months",
                    "model_conf": 100.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.04,
                    "duration": "indefinitely",
                    "model_conf": 154.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 280.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 194.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.12,
                    "duration": "indefinitely",
                    "model_conf": 165.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.7,
                    "duration": "1 Months",
                    "model_conf": 165.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.01,
                    "duration": "indefinitely",
                    "model_conf": 156.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.1,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.51,
                    "duration": "1 Months",
                    "model_conf": 233.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.09,
                    "duration": "indefinitely",
                    "model_conf": 85.26
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.62,
                    "duration": "indefinitely",
                    "model_conf": 218.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/columbus/1639-bradley-park-drive.html": {
        "name": "Bradley Park",
        "address": "1639 Bradley Park Drive, Columbus, Georgia, 31904",
        "phone": "(706) 576-3689",
        "url": "https://locations.massageenvy.com/georgia/columbus/1639-bradley-park-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "12:00 PM - 8:00 PM"
        },
        "geolocation": [
            32.53415312578386,
            -84.96759208465573
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.71,
                    "duration": "indefinitely",
                    "model_conf": 70.91
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.53,
                    "duration": "indefinitely",
                    "model_conf": 159.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.81,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.1,
                    "duration": "indefinitely",
                    "model_conf": 102.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.08,
                    "duration": "indefinitely",
                    "model_conf": 154.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.48,
                    "duration": "indefinitely",
                    "model_conf": 277.89
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.33,
                    "duration": "indefinitely",
                    "model_conf": 194.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.28,
                    "duration": "2 Months",
                    "model_conf": 165.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.63,
                    "duration": "indefinitely",
                    "model_conf": 168.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.18,
                    "duration": "1 Months",
                    "model_conf": 156.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.97,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.56,
                    "duration": "indefinitely",
                    "model_conf": 238.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.71,
                    "duration": "1 Months",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.69,
                    "duration": "2 Months",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.15,
                    "duration": "indefinitely",
                    "model_conf": 221.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/dacula/525-dacula-rd.html": {
        "name": "Dacula",
        "address": "525 Dacula Rd Ste 250, Dacula Dacula, Georgia, 30019",
        "phone": "(678) 376-3444",
        "url": "https://locations.massageenvy.com/georgia/dacula/525-dacula-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.00097895531923,
            -83.90698161722867
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 157.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 99.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.42,
                    "duration": "indefinitely",
                    "model_conf": 153.38
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.92,
                    "duration": "indefinitely",
                    "model_conf": 279.16
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 192.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 164.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.3,
                    "duration": "indefinitely",
                    "model_conf": 165.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 156.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 140.66
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 233.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.34,
                    "duration": "indefinitely",
                    "model_conf": 216.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/flowery-branch/7338-spout-springs-rd..html": {
        "name": "Flowery Branch",
        "address": "7338 Spout Springs Rd. Suite C12, Flowery Branch, Georgia, 30542",
        "phone": "(770) 965-8853",
        "url": "https://locations.massageenvy.com/georgia/flowery-branch/7338-spout-springs-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.1111721,
            -83.876546
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 156.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 99.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.09,
                    "duration": "indefinitely",
                    "model_conf": 154.16
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.28,
                    "duration": "4 Months",
                    "model_conf": 278.68
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 191.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 165.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 166.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.09,
                    "duration": "indefinitely",
                    "model_conf": 156.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.75,
                    "duration": "3 Months",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.83,
                    "duration": "3 Months",
                    "model_conf": 233.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 85.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.45,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 218.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/kennesaw/745-chastain-road-nw.html": {
        "name": "Kennesaw",
        "address": "745 Chastain Road NW Suite 1080-1090, Kennesaw Kennesaw, Georgia, 30144",
        "phone": "(678) 213-3689",
        "url": "https://locations.massageenvy.com/georgia/kennesaw/745-chastain-road-nw.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.034498016231176,
            -84.571467670284
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.63,
                    "duration": "indefinitely",
                    "model_conf": 70.59
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.42,
                    "duration": "indefinitely",
                    "model_conf": 157.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 92.91
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 152.48
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.46,
                    "duration": "indefinitely",
                    "model_conf": 273.07
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 189.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.52,
                    "duration": "indefinitely",
                    "model_conf": 157.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 156.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 154.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.38,
                    "duration": "indefinitely",
                    "model_conf": 230.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 83.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 206.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/lawrenceville/1860-duluth-highway.html": {
        "name": "Sugarloaf",
        "address": "1860 Duluth Highway Suite 405, Lawrenceville Lawrenceville, Georgia, 30043",
        "phone": "(678) 218-3689",
        "url": "https://locations.massageenvy.com/georgia/lawrenceville/1860-duluth-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.97493496171899,
            -84.0775488452843
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.36,
                    "duration": "1 Months",
                    "model_conf": 70.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 157.02
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 94.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 152.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.29,
                    "duration": "1 Months",
                    "model_conf": 276.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 189.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.43,
                    "duration": "1 Months",
                    "model_conf": 159.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.25,
                    "duration": "4 Months",
                    "model_conf": 159.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 155.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.2,
                    "duration": "2 Months",
                    "model_conf": 231.32
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.8,
                    "duration": "indefinitely",
                    "model_conf": 84.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 55.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.8,
                    "duration": "2 Months",
                    "model_conf": 210.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/newnan/238-newnan-crossing-bypass.html": {
        "name": "Newnan",
        "address": "238 Newnan Crossing Bypass, Newnan, Georgia, 30265",
        "phone": "(770) 252-3000",
        "url": "https://locations.massageenvy.com/georgia/newnan/238-newnan-crossing-bypass.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.390872,
            -84.764728
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.53,
                    "duration": "1 Months",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 159.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 101.98
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.48,
                    "duration": "1 Months",
                    "model_conf": 154.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.01,
                    "duration": "2 Months",
                    "model_conf": 281.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 193.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 163.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.23,
                    "duration": "2 Months",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 237.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.68,
                    "duration": "indefinitely",
                    "model_conf": 85.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 54.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.13,
                    "duration": "1 Months",
                    "model_conf": 218.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/peachtree-city/1215-peachtree-parkway.html": {
        "name": "Peachtree City",
        "address": "1215 Peachtree Parkway, Peachtree City Peachtree City, Georgia, 30269",
        "phone": "(678) 216-1000",
        "url": "https://locations.massageenvy.com/georgia/peachtree-city/1215-peachtree-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.436217714908324,
            -84.59080735276126
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.75,
                    "duration": "indefinitely",
                    "model_conf": 71.37
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 159.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.68,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 102.0
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.45,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.35,
                    "duration": "4 Months",
                    "model_conf": 281.58
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 193.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 164.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 156.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.79,
                    "duration": "3 Months",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.05,
                    "duration": "2 Months",
                    "model_conf": 237.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.16,
                    "duration": "indefinitely",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 54.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.27,
                    "duration": "1 Months",
                    "model_conf": 219.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/peachtree-corners/5270-peachtree-pkwy.html": {
        "name": "Peachtree Corners",
        "address": "5270 Peachtree Pkwy Suite 109B, Peachtree Corners, Georgia, 30092",
        "phone": "(770) 300-0033",
        "url": "https://locations.massageenvy.com/georgia/peachtree-corners/5270-peachtree-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.97595614466337,
            -84.21645430868011
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.03,
                    "duration": "indefinitely",
                    "model_conf": 70.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.57,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 93.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.19,
                    "duration": "1 Months",
                    "model_conf": 152.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.22,
                    "duration": "indefinitely",
                    "model_conf": 273.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.88,
                    "duration": "indefinitely",
                    "model_conf": 155.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.31,
                    "duration": "4 Months",
                    "model_conf": 157.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.45,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.39,
                    "duration": "indefinitely",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.25,
                    "duration": "indefinitely",
                    "model_conf": 84.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.07,
                    "duration": "indefinitely",
                    "model_conf": 54.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.21,
                    "duration": "1 Months",
                    "model_conf": 209.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/roswell/885-woodstock-rd.html": {
        "name": "Roswell West",
        "address": "885 Woodstock Rd Ste 300, Roswell Roswell, Georgia, 30075",
        "phone": "(770) 928-3689",
        "url": "https://locations.massageenvy.com/georgia/roswell/885-woodstock-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.06154133642768,
            -84.38552281634924
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 70.34
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 154.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.17,
                    "duration": "2 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 89.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.68,
                    "duration": "indefinitely",
                    "model_conf": 152.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 268.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 174.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 148.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 155.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 151.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 214.27
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 83.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 54.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.96,
                    "duration": "indefinitely",
                    "model_conf": 208.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/snellville/1905-scenic-highway.html": {
        "name": "Snellville",
        "address": "1905 Scenic Highway Ste 220, Snellville, Georgia, 30078",
        "phone": "(770) 817-6868",
        "url": "https://locations.massageenvy.com/georgia/snellville/1905-scenic-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.876654,
            -84.014987
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.71,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.93,
                    "duration": "indefinitely",
                    "model_conf": 157.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.65,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 99.02
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 153.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 278.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 193.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.93,
                    "duration": "indefinitely",
                    "model_conf": 163.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.46,
                    "duration": "3 Months",
                    "model_conf": 164.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.25,
                    "duration": "indefinitely",
                    "model_conf": 155.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.5,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 231.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 84.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.31,
                    "duration": "indefinitely",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 216.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/stockbridge/1490-hudson-bridge-rd.html": {
        "name": "Stockbridge",
        "address": "1490 Hudson Bridge Rd, Stockbridge, Georgia, 30281",
        "phone": "(678) 902-0300",
        "url": "https://locations.massageenvy.com/georgia/stockbridge/1490-hudson-bridge-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.506333574203964,
            -84.23799276351929
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.53,
                    "duration": "indefinitely",
                    "model_conf": 158.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.62,
                    "duration": "indefinitely",
                    "model_conf": 102.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 153.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.4,
                    "duration": "indefinitely",
                    "model_conf": 281.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.21,
                    "duration": "indefinitely",
                    "model_conf": 195.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.31,
                    "duration": "2 Months",
                    "model_conf": 166.73
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.84,
                    "duration": "indefinitely",
                    "model_conf": 167.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.15,
                    "duration": "1 Months",
                    "model_conf": 157.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.38,
                    "duration": "indefinitely",
                    "model_conf": 237.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.29,
                    "duration": "2 Months",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.56,
                    "duration": "2 Months",
                    "model_conf": 55.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.67,
                    "duration": "indefinitely",
                    "model_conf": 220.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/woodstock/134-woodstock-square-ave.html": {
        "name": "Woodstock",
        "address": "134 Woodstock Square Ave, Woodstock Woodstock, Georgia, 30189",
        "phone": "(770) 928-0800",
        "url": "https://locations.massageenvy.com/georgia/woodstock/134-woodstock-square-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.0845008,
            -84.5461248
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 79.525,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "100",
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "87",
                        "servicePrice": "72",
                        "memberAdditional": "52",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "122"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": "90",
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "100",
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": "90",
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": "36",
                        "memberAdditional": "26",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": "36",
                        "memberAdditional": "26",
                        "memberUpgrade": null,
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": "60",
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 114.91,
                    "duration": "indefinitely",
                    "model_conf": 70.82
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.07,
                    "duration": "indefinitely",
                    "model_conf": 157.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.45,
                    "duration": "2 Months",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 40.07,
                    "duration": "indefinitely",
                    "model_conf": 92.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.96,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 116.65,
                    "duration": "indefinitely",
                    "model_conf": 272.85
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.88,
                    "duration": "indefinitely",
                    "model_conf": 188.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.72,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 40.36,
                    "duration": "indefinitely",
                    "model_conf": 156.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.75,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 116.61,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 229.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.54,
                    "duration": "2 Months",
                    "model_conf": 83.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 40.03,
                    "duration": "indefinitely",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.17,
                    "duration": "indefinitely",
                    "model_conf": 206.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/hawaii/honolulu/820-w-hind-dr..html": {
        "name": "Aina Haina",
        "address": "820 W Hind Dr. #1228-1229, Honolulu, Hawaii, 96821",
        "phone": "(808) 524-3689",
        "url": "https://locations.massageenvy.com/hawaii/honolulu/820-w-hind-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            21.2791847,
            -157.7533514
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 102.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.69,
                    "duration": "indefinitely",
                    "model_conf": 154.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.65,
                    "duration": "3 Months",
                    "model_conf": 281.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 166.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.08,
                    "duration": "5 Months",
                    "model_conf": 167.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 156.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.89,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.37,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.33,
                    "duration": "1 Months",
                    "model_conf": 216.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/hawaii/kahului/70-ho-okele-street.html": {
        "name": "Maui",
        "address": "70 Ho'okele Street Suite 1250 & 1260, Kahului, Hawaii, 96732",
        "phone": "(808) 524-3689",
        "url": "https://locations.massageenvy.com/hawaii/kahului/70-ho-okele-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            20.874033593072273,
            -156.45749819464982
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.49,
                    "duration": "4 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 102.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 154.81
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.56,
                    "duration": "3 Months",
                    "model_conf": 282.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.61,
                    "duration": "2 Months",
                    "model_conf": 168.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 157.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.42,
                    "duration": "1 Months",
                    "model_conf": 221.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/hawaii/kaneohe/45-480-kaneohe-bay-dr.html": {
        "name": "Kaneohe",
        "address": "45-480 Kaneohe Bay Dr Ste B18, Kaneohe, Hawaii, 96744",
        "phone": "(808) 524-3689",
        "url": "https://locations.massageenvy.com/hawaii/kaneohe/45-480-kaneohe-bay-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            21.401532257736342,
            -157.7989066921349
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.76,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 102.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.74,
                    "duration": "3 Months",
                    "model_conf": 282.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 166.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.22,
                    "duration": "4 Months",
                    "model_conf": 167.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 156.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.96,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.4,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 217.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/hawaii/kapolei/4460-kapolei-parkway.html": {
        "name": "Kapolei",
        "address": "4460 Kapolei Parkway Suite 220, Kapolei, Hawaii, 96707",
        "phone": "(808) 524-3689",
        "url": "https://locations.massageenvy.com/hawaii/kapolei/4460-kapolei-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            21.33044416123717,
            -158.0900739133358
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.92,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.81,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 102.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.02,
                    "duration": "indefinitely",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.62,
                    "duration": "1 Months",
                    "model_conf": 280.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.95,
                    "duration": "indefinitely",
                    "model_conf": 157.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.89,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.13,
                    "duration": "indefinitely",
                    "model_conf": 218.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/hawaii/pearl-city/1000-kamehameha-hwy.html": {
        "name": "Pearl Highlands Center",
        "address": "1000 Kamehameha Hwy #222, Pearl City, Hawaii, 96782",
        "phone": "(808) 524-3689",
        "url": "https://locations.massageenvy.com/hawaii/pearl-city/1000-kamehameha-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            21.396458,
            -157.9768411
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 102.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 154.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.09,
                    "duration": "2 Months",
                    "model_conf": 281.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 166.67
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.16,
                    "duration": "indefinitely",
                    "model_conf": 157.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.87,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 217.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/idaho/ammon/3047-s-25th-east.html": {
        "name": "Ammon",
        "address": "3047 S 25th East, Ammon Ammon, Idaho, 83406",
        "phone": "(208) 522-0877",
        "url": "https://locations.massageenvy.com/idaho/ammon/3047-s-25th-east.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            43.469869,
            -111.981822
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.69,
                    "duration": "indefinitely",
                    "model_conf": 71.37
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.75,
                    "duration": "indefinitely",
                    "model_conf": 157.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.89,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.7,
                    "duration": "indefinitely",
                    "model_conf": 102.54
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.33,
                    "duration": "indefinitely",
                    "model_conf": 154.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.72,
                    "duration": "indefinitely",
                    "model_conf": 281.58
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.56,
                    "duration": "indefinitely",
                    "model_conf": 193.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.49,
                    "duration": "indefinitely",
                    "model_conf": 165.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.51,
                    "duration": "indefinitely",
                    "model_conf": 166.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.07,
                    "duration": "indefinitely",
                    "model_conf": 156.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.54,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.93,
                    "duration": "indefinitely",
                    "model_conf": 237.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.66,
                    "duration": "indefinitely",
                    "model_conf": 85.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.37,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.84,
                    "duration": "indefinitely",
                    "model_conf": 221.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/idaho/boise/7640-w-state-street.html": {
        "name": "Boise North",
        "address": "7640 W State Street, Boise, Idaho, 83714",
        "phone": "(208) 391-5649",
        "url": "https://locations.massageenvy.com/idaho/boise/7640-w-state-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.671466,
            -116.28306
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.8,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.25,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.71,
                    "duration": "indefinitely",
                    "model_conf": 100.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.79,
                    "duration": "indefinitely",
                    "model_conf": 151.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.75,
                    "duration": "indefinitely",
                    "model_conf": 283.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.49,
                    "duration": "4 Months",
                    "model_conf": 156.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.93,
                    "duration": "indefinitely",
                    "model_conf": 164.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 157.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.41,
                    "duration": "4 Months",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.01,
                    "duration": "indefinitely",
                    "model_conf": 216.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/idaho/coeur-d-alene/212-west-ironwood-drive.html": {
        "name": "Coeur D\u2019Alene",
        "address": "212 West Ironwood Drive Suite E, Coeur d'Alene, Idaho, 83814",
        "phone": "(208) 502-0500",
        "url": "https://locations.massageenvy.com/idaho/coeur-d-alene/212-west-ironwood-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.69372864636125,
            -116.78901046514511
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.87,
                    "duration": "3 Months",
                    "model_conf": 282.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.17,
                    "duration": "2 Months",
                    "model_conf": 194.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.64,
                    "duration": "indefinitely",
                    "model_conf": 167.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.13,
                    "duration": "11 Months",
                    "model_conf": 166.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 157.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/idaho/meridian/1180-north-eagle-road.html": {
        "name": "Meridian",
        "address": "1180 North Eagle Road, Meridian Meridian, Idaho, 83642",
        "phone": "(208) 343-3689",
        "url": "https://locations.massageenvy.com/idaho/meridian/1180-north-eagle-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.6152049,
            -116.3527092
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.9,
                    "duration": "5 Months",
                    "model_conf": 283.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.93,
                    "duration": "indefinitely",
                    "model_conf": 156.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.07,
                    "duration": "5 Months",
                    "model_conf": 163.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.67,
                    "duration": "indefinitely",
                    "model_conf": 157.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.6,
                    "duration": "3 Months",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.89,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.11,
                    "duration": "indefinitely",
                    "model_conf": 55.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.48,
                    "duration": "2 Months",
                    "model_conf": 218.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/idaho/nampa/16469-n-marketplace-blvd.html": {
        "name": "Nampa",
        "address": "16469 N Marketplace Blvd, Nampa Nampa, Idaho, 83687",
        "phone": "(208) 468-3689",
        "url": "https://locations.massageenvy.com/idaho/nampa/16469-n-marketplace-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.61180629917262,
            -116.59194859265976
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.39,
                    "duration": "indefinitely",
                    "model_conf": 278.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.84,
                    "duration": "indefinitely",
                    "model_conf": 166.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.38,
                    "duration": "3 Months",
                    "model_conf": 168.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.23,
                    "duration": "1 Months",
                    "model_conf": 157.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.87,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.57,
                    "duration": "1 Months",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.83,
                    "duration": "2 Months",
                    "model_conf": 220.33
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/algonquin/790-b-s.-randall-road.html": {
        "name": "Algonquin",
        "address": "790 B S. Randall Road Suite B, Algonquin Algonquin, Illinois, 60102",
        "phone": "(847) 658-1155",
        "url": "https://locations.massageenvy.com/illinois/algonquin/790-b-s.-randall-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.162246314324726,
            -88.33638668060303
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.11,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 139.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.42,
                    "duration": "indefinitely",
                    "model_conf": 101.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.42,
                    "duration": "9 Months",
                    "model_conf": 151.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.54,
                    "duration": "6 Months",
                    "model_conf": 278.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.85,
                    "duration": "indefinitely",
                    "model_conf": 164.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.84,
                    "duration": "indefinitely",
                    "model_conf": 166.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.75,
                    "duration": "indefinitely",
                    "model_conf": 155.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.08,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.5,
                    "duration": "7 Months",
                    "model_conf": 218.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/bloomingdale/342-w-army-trail.html": {
        "name": "Bloomingdale",
        "address": "342 W Army Trail #120, Bloomingdale, Illinois, 60108",
        "phone": "(630) 295-5600",
        "url": "https://locations.massageenvy.com/illinois/bloomingdale/342-w-army-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.936912707365174,
            -88.10694100420278
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 43.54,
                    "duration": "12 Months",
                    "model_conf": 272.95
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.07,
                    "duration": "2 Months",
                    "model_conf": 188.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 160.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 161.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.65,
                    "duration": "indefinitely",
                    "model_conf": 153.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.07,
                    "duration": "5 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.25,
                    "duration": "5 Months",
                    "model_conf": 225.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 84.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.26,
                    "duration": "4 Months",
                    "model_conf": 54.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 209.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/darien/2445-75th-street-unit-e.html": {
        "name": "Darien-Downers Grove",
        "address": "2445 75th Street Unit E, Darien, Illinois, 60561",
        "phone": "(630) 910-4500",
        "url": "https://locations.massageenvy.com/illinois/darien/2445-75th-street-unit-e.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.750591,
            -88.005051
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.58,
                    "duration": "3 Months",
                    "model_conf": 259.66
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.96,
                    "duration": "indefinitely",
                    "model_conf": 177.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 153.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.45,
                    "duration": "3 Months",
                    "model_conf": 146.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.75,
                    "duration": "indefinitely",
                    "model_conf": 144.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.03,
                    "duration": "2 Months",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 210.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.31,
                    "duration": "indefinitely",
                    "model_conf": 82.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.01,
                    "duration": "indefinitely",
                    "model_conf": 53.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.61,
                    "duration": "2 Months",
                    "model_conf": 197.19
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/elmhurst/191-s-il-route-83.html": {
        "name": "Elmhurst",
        "address": "191 S Il Route 83, Elmhurst Elmhurst, Illinois, 60126",
        "phone": "(630) 758-4050",
        "url": "https://locations.massageenvy.com/illinois/elmhurst/191-s-il-route-83.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.894785,
            -87.96143
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 42.46,
                    "duration": "indefinitely",
                    "model_conf": 244.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 164.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 143.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.98,
                    "duration": "6 Months",
                    "model_conf": 131.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.95,
                    "duration": "indefinitely",
                    "model_conf": 136.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.83,
                    "duration": "indefinitely",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.02,
                    "duration": "5 Months",
                    "model_conf": 189.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.91,
                    "duration": "indefinitely",
                    "model_conf": 80.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 51.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.07,
                    "duration": "1 Months",
                    "model_conf": 179.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/frankfort/11061-w.-lincoln-highway.html": {
        "name": "New Lenox",
        "address": "11061 W. Lincoln Highway, Frankfort, Illinois, 60423",
        "phone": "(815) 277-5151",
        "url": "https://locations.massageenvy.com/illinois/frankfort/11061-w.-lincoln-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.502272027363574,
            -87.88540005683899
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/geneva/116-commons-drive.html": {
        "name": "Geneva Commons",
        "address": "116 Commons Drive, Geneva, Illinois, 60134",
        "phone": "(630) 208-0100",
        "url": "https://locations.massageenvy.com/illinois/geneva/116-commons-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.890752,
            -88.343232
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.84,
                    "duration": "1 Months",
                    "model_conf": 280.34
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 194.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.39,
                    "duration": "4 Months",
                    "model_conf": 152.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.17,
                    "duration": "indefinitely",
                    "model_conf": 163.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.98,
                    "duration": "indefinitely",
                    "model_conf": 218.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.15,
                    "duration": "indefinitely",
                    "model_conf": 85.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.7,
                    "duration": "indefinitely",
                    "model_conf": 54.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.24,
                    "duration": "indefinitely",
                    "model_conf": 192.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/glen-carbon/3000-south-state-route-159.html": {
        "name": "Glen Carbon",
        "address": "3000 South State Route 159, Glen Carbon, Illinois, 62034",
        "phone": "(618) 656-3689",
        "url": "https://locations.massageenvy.com/illinois/glen-carbon/3000-south-state-route-159.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:30 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            38.778501,
            -89.95327
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.36,
                    "duration": "1 Months",
                    "model_conf": 282.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.47,
                    "duration": "indefinitely",
                    "model_conf": 165.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.18,
                    "duration": "indefinitely",
                    "model_conf": 167.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.69,
                    "duration": "indefinitely",
                    "model_conf": 157.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.83,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.66,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.23,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.8,
                    "duration": "indefinitely",
                    "model_conf": 219.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/glen-ellyn/840-roosevelt-road.html": {
        "name": "Glen Ellyn",
        "address": "840 Roosevelt Road, Glen Ellyn Glen Ellyn, Illinois, 60137",
        "phone": "(630) 790-4400",
        "url": "https://locations.massageenvy.com/illinois/glen-ellyn/840-roosevelt-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.85997606904162,
            -88.05083953098699
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.35,
                    "duration": "1 Months",
                    "model_conf": 68.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 139.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 81.23
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.22,
                    "duration": "indefinitely",
                    "model_conf": 144.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.21,
                    "duration": "4 Months",
                    "model_conf": 244.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.9,
                    "duration": "6 Months",
                    "model_conf": 142.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.19,
                    "duration": "indefinitely",
                    "model_conf": 133.27
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.02,
                    "duration": "indefinitely",
                    "model_conf": 138.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 140.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 80.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 51.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 176.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/grayslake/811-e-belvidere-rd.html": {
        "name": "Grayslake",
        "address": "811 E Belvidere Rd, Grayslake Grayslake, Illinois, 60030",
        "phone": "(224) 259-2200",
        "url": "https://locations.massageenvy.com/illinois/grayslake/811-e-belvidere-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.33612331022309,
            -88.02844194698991
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.59,
                    "duration": "3 Months",
                    "model_conf": 280.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.36,
                    "duration": "indefinitely",
                    "model_conf": 165.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.14,
                    "duration": "indefinitely",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.03,
                    "duration": "indefinitely",
                    "model_conf": 155.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.02,
                    "duration": "indefinitely",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.92,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.05,
                    "duration": "1 Months",
                    "model_conf": 219.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/hoffman-estates/2652-n-sutton-road.html": {
        "name": "Hoffman Estates",
        "address": "2652 N Sutton Road, Hoffman Estates Hoffman Estates, Illinois, 60192",
        "phone": "(847) 645-1700",
        "url": "https://locations.massageenvy.com/illinois/hoffman-estates/2652-n-sutton-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:30 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.072225,
            -88.1935885
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.02,
                    "duration": "5 Months",
                    "model_conf": 278.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.54,
                    "duration": "indefinitely",
                    "model_conf": 163.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 163.27
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.0,
                    "duration": "indefinitely",
                    "model_conf": 154.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.15,
                    "duration": "2 Months",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.05,
                    "duration": "indefinitely",
                    "model_conf": 85.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 55.18
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.01,
                    "duration": "2 Months",
                    "model_conf": 214.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/johnsburg/2936-commerce-drive.html": {
        "name": "McHenry-Johnsburg",
        "address": "2936 Commerce Drive, Johnsburg Johnsburg, Illinois, 60051",
        "phone": "(815) 385-3001",
        "url": "https://locations.massageenvy.com/illinois/johnsburg/2936-commerce-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.36942489766426,
            -88.26636064847816
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 282.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.84,
                    "duration": "indefinitely",
                    "model_conf": 166.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 168.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.29,
                    "duration": "indefinitely",
                    "model_conf": 157.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.49,
                    "duration": "1 Months",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 55.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.47,
                    "duration": "1 Months",
                    "model_conf": 220.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/la-grange/1-n-la-grange-rd.html": {
        "name": "La Grange",
        "address": "1 N La Grange Rd Unit 2A, La Grange, Illinois, 60525",
        "phone": "(708) 354-3689",
        "url": "https://locations.massageenvy.com/illinois/la-grange/1-n-la-grange-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            41.816577,
            -87.8694211
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 268.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 150.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 83.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 54.23
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.23,
                    "duration": "1 Months",
                    "model_conf": 205.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/lake-zurich/299-south-rand-road.html": {
        "name": "Lake Zurich",
        "address": "299 South Rand Road, Lake Zurich Lake Zurich, Illinois, 60047",
        "phone": "(847) 438-9100",
        "url": "https://locations.massageenvy.com/illinois/lake-zurich/299-south-rand-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.192246187314744,
            -88.11060662822439
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 41.09,
                    "duration": "indefinitely",
                    "model_conf": 268.43
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.88,
                    "duration": "9 Months",
                    "model_conf": 183.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.36,
                    "duration": "indefinitely",
                    "model_conf": 160.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.13,
                    "duration": "11 Months",
                    "model_conf": 154.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.85,
                    "duration": "indefinitely",
                    "model_conf": 142.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.9,
                    "duration": "2 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.83,
                    "duration": "12 Months",
                    "model_conf": 223.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 83.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.48,
                    "duration": "indefinitely",
                    "model_conf": 53.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.5,
                    "duration": "7 Months",
                    "model_conf": 208.02
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/mount-prospect/1212-e-central-rd.html": {
        "name": "Mount Prospect",
        "address": "1212 E Central Rd Suite A, Mount Prospect, Illinois, 60056",
        "phone": "(847) 577-3677",
        "url": "https://locations.massageenvy.com/illinois/mount-prospect/1212-e-central-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.0665461,
            -87.92003539999999
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 43.72,
                    "duration": "11 Months",
                    "model_conf": 250.6
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 171.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 149.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.59,
                    "duration": "2 Months",
                    "model_conf": 141.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.17,
                    "duration": "indefinitely",
                    "model_conf": 141.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.87,
                    "duration": "indefinitely",
                    "model_conf": 140.54
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 202.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.95,
                    "duration": "indefinitely",
                    "model_conf": 81.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.37,
                    "duration": "indefinitely",
                    "model_conf": 52.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.0,
                    "duration": "indefinitely",
                    "model_conf": 188.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/niles/5661-w-touhy-ave.html": {
        "name": "Niles",
        "address": "5661 W Touhy Ave Unit A, Niles Niles, Illinois, 60714",
        "phone": "(847) 588-0900",
        "url": "https://locations.massageenvy.com/illinois/niles/5661-w-touhy-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.010424,
            -87.769106
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/normal/314-towanda-ave.html": {
        "name": "Bloomington - Normal",
        "address": "314 Towanda Ave #310, Normal, Illinois, 61761",
        "phone": "(309) 862-0900",
        "url": "https://locations.massageenvy.com/illinois/normal/314-towanda-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.50572089680637,
            -88.95763853313629
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/north-aurora/1866-town-center-drive.html": {
        "name": "North Aurora",
        "address": "1866 Town Center Drive, North Aurora, Illinois, 60542",
        "phone": "(630) 907-6100",
        "url": "https://locations.massageenvy.com/illinois/north-aurora/1866-town-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.79579739869769,
            -88.36683726880437
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.36,
                    "duration": "indefinitely",
                    "model_conf": 278.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 193.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 163.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 165.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 155.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.69,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.56,
                    "duration": "indefinitely",
                    "model_conf": 231.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 85.26
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.77,
                    "duration": "indefinitely",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.23,
                    "duration": "1 Months",
                    "model_conf": 214.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/northbrook/840-willow-road.html": {
        "name": "Northbrook-Willow Festival",
        "address": "840 Willow Road Suite R, Northbrook Northbrook, Illinois, 60062",
        "phone": "(847) 564-0500",
        "url": "https://locations.massageenvy.com/illinois/northbrook/840-willow-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.10626840822409,
            -87.80155496057039
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 43.79,
                    "duration": "10 Months",
                    "model_conf": 264.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 182.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.4,
                    "duration": "indefinitely",
                    "model_conf": 159.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.85,
                    "duration": "indefinitely",
                    "model_conf": 148.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.11,
                    "duration": "indefinitely",
                    "model_conf": 147.61
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.8,
                    "duration": "indefinitely",
                    "model_conf": 140.73
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 209.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 84.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.46,
                    "duration": "indefinitely",
                    "model_conf": 54.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.25,
                    "duration": "3 Months",
                    "model_conf": 196.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/o-fallon/1140-central-park-drive.html": {
        "name": "O'Fallon-IL",
        "address": "1140 Central Park Drive, O'Fallon, Illinois, 62269",
        "phone": "(618) 622-3689",
        "url": "https://locations.massageenvy.com/illinois/o-fallon/1140-central-park-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.573716,
            -89.930955
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/oak-lawn/11022-s.-cicero-ave..html": {
        "name": "Oak Lawn",
        "address": "11022 S. Cicero Ave., Oak Lawn, Illinois, 60453",
        "phone": "(708) 423-3689",
        "url": "https://locations.massageenvy.com/illinois/oak-lawn/11022-s.-cicero-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.69174981641398,
            -87.74026371538645
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.41,
                    "duration": "4 Months",
                    "model_conf": 281.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 166.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.19,
                    "duration": "4 Months",
                    "model_conf": 167.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.36,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/orland-park/15159-s-lagrange-rd.html": {
        "name": "Orland Park",
        "address": "15159 S Lagrange Rd Ste 200, Orland Park Orland Park, Illinois, 60462",
        "phone": "(708) 675-1555",
        "url": "https://locations.massageenvy.com/illinois/orland-park/15159-s-lagrange-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.61426,
            -87.852731
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/oswego/300-fifth-street.html": {
        "name": "Oswego",
        "address": "300 Fifth Street Suite 100, Oswego, Illinois, 60543",
        "phone": "(630) 554-5055",
        "url": "https://locations.massageenvy.com/illinois/oswego/300-fifth-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            41.70717344444263,
            -88.30083027361067
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.44,
                    "duration": "4 Months",
                    "model_conf": 276.41
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 191.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 164.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.68,
                    "duration": "1 Months",
                    "model_conf": 164.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 153.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 230.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.1,
                    "duration": "indefinitely",
                    "model_conf": 84.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.65,
                    "duration": "indefinitely",
                    "model_conf": 55.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.83,
                    "duration": "2 Months",
                    "model_conf": 216.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/palatine/728-e-dundee-rd.html": {
        "name": "Palatine",
        "address": "728 E Dundee Rd, Palatine Palatine, Illinois, 60074",
        "phone": "(847) 991-1010",
        "url": "https://locations.massageenvy.com/illinois/palatine/728-e-dundee-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.13958528664264,
            -88.02500490176209
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 41.37,
                    "duration": "indefinitely",
                    "model_conf": 233.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.37,
                    "duration": "7 Months",
                    "model_conf": 155.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.34,
                    "duration": "indefinitely",
                    "model_conf": 139.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.55,
                    "duration": "8 Months",
                    "model_conf": 124.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.34,
                    "duration": "indefinitely",
                    "model_conf": 129.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.81,
                    "duration": "3 Months",
                    "model_conf": 140.5
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.46,
                    "duration": "10 Months",
                    "model_conf": 179.29
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 79.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 50.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.05,
                    "duration": "3 Months",
                    "model_conf": 167.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/park-ridge/110-s.-northwest-highway.html": {
        "name": "Park Ridge",
        "address": "110 S. Northwest Highway Unit 3, Park Ridge, Illinois, 60068",
        "phone": "(847) 268-4641",
        "url": "https://locations.massageenvy.com/illinois/park-ridge/110-s.-northwest-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.0098535,
            -87.827615
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/peoria/7424-n.-orange-prairie-rd.html": {
        "name": "Peoria",
        "address": "7424 N. Orange Prairie Rd Suite A, Peoria Peoria, Illinois, 61615",
        "phone": "(309) 683-3689",
        "url": "https://locations.massageenvy.com/illinois/peoria/7424-n.-orange-prairie-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.7788324,
            -89.6780658
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.9,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.32,
                    "duration": "indefinitely",
                    "model_conf": 282.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.42,
                    "duration": "indefinitely",
                    "model_conf": 193.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.48,
                    "duration": "indefinitely",
                    "model_conf": 166.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.59,
                    "duration": "2 Months",
                    "model_conf": 167.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.71,
                    "duration": "indefinitely",
                    "model_conf": 152.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/plainfield/13400-s-route-59.html": {
        "name": "Plainfield-IL",
        "address": "13400 S Route 59 #118, Plainfield Plainfield, Illinois, 60585",
        "phone": "(815) 609-9999",
        "url": "https://locations.massageenvy.com/illinois/plainfield/13400-s-route-59.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.63807138111239,
            -88.20583991687823
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/river-forest/7341-lake-st.html": {
        "name": "Oak Park - River Forest",
        "address": "7341 Lake St, River Forest, Illinois, 60305",
        "phone": "(708) 771-5300",
        "url": "https://locations.massageenvy.com/illinois/river-forest/7341-lake-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            41.888135,
            -87.8081048
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/illinois/rockford/6241-e.-state-street.html": {
        "name": "Rockford",
        "address": "6241 E. State Street, Rockford Rockford, Illinois, 61108",
        "phone": "(815) 398-3689",
        "url": "https://locations.massageenvy.com/illinois/rockford/6241-e.-state-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            42.26536786898269,
            -88.99480327963829
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 276.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 166.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.42,
                    "duration": "indefinitely",
                    "model_conf": 168.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.92,
                    "duration": "indefinitely",
                    "model_conf": 157.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 138.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.49,
                    "duration": "indefinitely",
                    "model_conf": 85.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 221.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/savoy/1213-savoy-plaza-lane.html": {
        "name": "Savoy Plaza",
        "address": "1213 Savoy Plaza Lane Suite D, Savoy, Illinois, 61874",
        "phone": "(217) 356-6368",
        "url": "https://locations.massageenvy.com/illinois/savoy/1213-savoy-plaza-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.069431,
            -88.251942
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.38,
                    "duration": "indefinitely",
                    "model_conf": 282.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.4,
                    "duration": "indefinitely",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.13,
                    "duration": "indefinitely",
                    "model_conf": 168.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.66,
                    "duration": "indefinitely",
                    "model_conf": 156.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.9,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.36,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.1,
                    "duration": "indefinitely",
                    "model_conf": 217.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/schaumburg/600-e-golf-rd.html": {
        "name": "Schaumburg",
        "address": "600 E Golf Rd, Schaumburg, Illinois, 60173",
        "phone": "(847) 843-3689",
        "url": "https://locations.massageenvy.com/illinois/schaumburg/600-e-golf-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            42.051485,
            -88.0651852
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.61,
                    "duration": "3 Months",
                    "model_conf": 267.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.07,
                    "duration": "2 Months",
                    "model_conf": 157.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.66,
                    "duration": "2 Months",
                    "model_conf": 153.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.03,
                    "duration": "1 Months",
                    "model_conf": 149.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.49,
                    "duration": "4 Months",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.04,
                    "duration": "2 Months",
                    "model_conf": 83.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 53.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.44,
                    "duration": "3 Months",
                    "model_conf": 203.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/shorewood/1033-brookforest-ave.html": {
        "name": "Shorewood",
        "address": "1033 Brookforest Ave, Shorewood, Illinois, 60404",
        "phone": "(815) 676-3800",
        "url": "https://locations.massageenvy.com/illinois/shorewood/1033-brookforest-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.541746,
            -88.200065
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.64,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.66,
                    "duration": "1 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.71,
                    "duration": "2 Months",
                    "model_conf": 99.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.6,
                    "duration": "indefinitely",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.47,
                    "duration": "indefinitely",
                    "model_conf": 278.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.49,
                    "duration": "1 Months",
                    "model_conf": 163.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.96,
                    "duration": "indefinitely",
                    "model_conf": 166.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.02,
                    "duration": "2 Months",
                    "model_conf": 156.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.49,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.6,
                    "duration": "1 Months",
                    "model_conf": 85.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.88,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 219.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/south-elgin/600-randall-road.html": {
        "name": "South Elgin",
        "address": "600 Randall Road, South Elgin, Illinois, 60177",
        "phone": "(847) 888-8500",
        "url": "https://locations.massageenvy.com/illinois/south-elgin/600-randall-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.972768,
            -88.341546
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.06,
                    "duration": "5 Months",
                    "model_conf": 279.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.76,
                    "duration": "3 Months",
                    "model_conf": 141.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.59,
                    "duration": "2 Months",
                    "model_conf": 159.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 150.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.37,
                    "duration": "4 Months",
                    "model_conf": 140.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 85.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.61,
                    "duration": "indefinitely",
                    "model_conf": 54.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.23,
                    "duration": "1 Months",
                    "model_conf": 179.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/springfield/2933-veterans-pkwy.html": {
        "name": "Springfield - IL",
        "address": "2933 Veterans Pkwy, Springfield, Illinois, 62704",
        "phone": "(217) 210-3689",
        "url": "https://locations.massageenvy.com/illinois/springfield/2933-veterans-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.76552133888138,
            -89.70910236992495
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 283.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.67,
                    "duration": "1 Months",
                    "model_conf": 166.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.53,
                    "duration": "indefinitely",
                    "model_conf": 167.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.7,
                    "duration": "indefinitely",
                    "model_conf": 156.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.75,
                    "duration": "indefinitely",
                    "model_conf": 140.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.4,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.4,
                    "duration": "indefinitely",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.25,
                    "duration": "indefinitely",
                    "model_conf": 221.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/tinley-park/7270-w-191st-st.html": {
        "name": "Tinley Park",
        "address": "7270 W 191st St, Tinley Park, Illinois, 60477",
        "phone": "(815) 469-4400",
        "url": "https://locations.massageenvy.com/illinois/tinley-park/7270-w-191st-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.5477798,
            -87.7940955
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.63,
                    "duration": "indefinitely",
                    "model_conf": 281.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.82,
                    "duration": "indefinitely",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.14,
                    "duration": "indefinitely",
                    "model_conf": 168.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.15,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.06,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.8,
                    "duration": "1 Months",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 219.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/vernon-hills/700-n.-milwaukee-ave.html": {
        "name": "Vernon Hills",
        "address": "700 N. Milwaukee Ave Suite 138, Vernon Hills, Illinois, 60061",
        "phone": "(847) 367-1010",
        "url": "https://locations.massageenvy.com/illinois/vernon-hills/700-n.-milwaukee-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.2394077,
            -87.9477986
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 42.29,
                    "duration": "indefinitely",
                    "model_conf": 271.09
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.07,
                    "duration": "5 Months",
                    "model_conf": 185.95
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.72,
                    "duration": "indefinitely",
                    "model_conf": 160.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.55,
                    "duration": "indefinitely",
                    "model_conf": 159.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.47,
                    "duration": "indefinitely",
                    "model_conf": 148.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.28,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.0,
                    "duration": "9 Months",
                    "model_conf": 221.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.09,
                    "duration": "indefinitely",
                    "model_conf": 84.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.67,
                    "duration": "indefinitely",
                    "model_conf": 54.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.44,
                    "duration": "3 Months",
                    "model_conf": 206.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/wheaton/211-rice-lake-square.html": {
        "name": "Wheaton",
        "address": "211 Rice Lake Square, Wheaton, Illinois, 60189",
        "phone": "(630) 510-3689",
        "url": "https://locations.massageenvy.com/illinois/wheaton/211-rice-lake-square.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.831485,
            -88.098958
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Skin Care Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.41,
                    "duration": "1 Months",
                    "model_conf": 69.32
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.49,
                    "duration": "1 Months",
                    "model_conf": 143.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.4,
                    "duration": "3 Months",
                    "model_conf": 87.91
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 148.62
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.29,
                    "duration": "4 Months",
                    "model_conf": 255.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.14,
                    "duration": "5 Months",
                    "model_conf": 174.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.51,
                    "duration": "1 Months",
                    "model_conf": 150.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.2,
                    "duration": "4 Months",
                    "model_conf": 144.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 142.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.68,
                    "duration": "indefinitely",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.37,
                    "duration": "4 Months",
                    "model_conf": 205.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 82.17
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 52.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.57,
                    "duration": "2 Months",
                    "model_conf": 190.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/wheeling/701-n-milwaukee-avenue.html": {
        "name": "Wheeling",
        "address": "701 N Milwaukee Avenue, Wheeling, Illinois, 60090",
        "phone": "(847) 537-9500",
        "url": "https://locations.massageenvy.com/illinois/wheeling/701-n-milwaukee-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.1508718,
            -87.912507
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 68.48
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.29,
                    "duration": "indefinitely",
                    "model_conf": 139.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 83.61
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.09,
                    "duration": "indefinitely",
                    "model_conf": 146.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.7,
                    "duration": "indefinitely",
                    "model_conf": 247.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 168.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 146.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.26,
                    "duration": "indefinitely",
                    "model_conf": 138.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 140.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.47,
                    "duration": "6 Months",
                    "model_conf": 140.4
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 193.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.15,
                    "duration": "2 Months",
                    "model_conf": 81.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 52.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 178.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/wilmette/3232-lake-ave.html": {
        "name": "Wilmette - Temporarily Closed",
        "address": "3232 Lake Ave Unit 165, Wilmette Wilmette, Illinois, 60091",
        "phone": "(847) 267-0101",
        "url": "https://locations.massageenvy.com/illinois/wilmette/3232-lake-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            42.08109863616228,
            -87.75649180219142
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 42.91,
                    "duration": "indefinitely",
                    "model_conf": 264.51
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.69,
                    "duration": "3 Months",
                    "model_conf": 182.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.56,
                    "duration": "indefinitely",
                    "model_conf": 159.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.06,
                    "duration": "5 Months",
                    "model_conf": 144.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 146.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.82,
                    "duration": "3 Months",
                    "model_conf": 207.34
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 84.35
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 54.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.69,
                    "duration": "2 Months",
                    "model_conf": 197.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/avon/10960-e-us-hwy-36.html": {
        "name": "Avon",
        "address": "10960 E US Hwy 36, Avon, Indiana, 46123",
        "phone": "(317) 271-9000",
        "url": "https://locations.massageenvy.com/indiana/avon/10960-e-us-hwy-36.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.764883,
            -86.327649
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.49,
                    "duration": "7 Months",
                    "model_conf": 281.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.64,
                    "duration": "indefinitely",
                    "model_conf": 166.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.61,
                    "duration": "9 Months",
                    "model_conf": 166.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.62,
                    "duration": "indefinitely",
                    "model_conf": 155.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.4,
                    "duration": "1 Months",
                    "model_conf": 140.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.97,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.58,
                    "duration": "5 Months",
                    "model_conf": 219.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/bloomington/2652-e-3rd-street.html": {
        "name": "Bloomington - IN",
        "address": "2652 E 3rd Street, Bloomington, Indiana, 47401",
        "phone": "(812) 323-7727",
        "url": "https://locations.massageenvy.com/indiana/bloomington/2652-e-3rd-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.1629068,
            -86.4991038
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.19,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.85,
                    "duration": "3 Months",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.34,
                    "duration": "indefinitely",
                    "model_conf": 101.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.45,
                    "duration": "indefinitely",
                    "model_conf": 155.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.3,
                    "duration": "indefinitely",
                    "model_conf": 278.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 167.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.6,
                    "duration": "indefinitely",
                    "model_conf": 167.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.05,
                    "duration": "indefinitely",
                    "model_conf": 157.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.09,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.2,
                    "duration": "indefinitely",
                    "model_conf": 85.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.09,
                    "duration": "indefinitely",
                    "model_conf": 55.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.4,
                    "duration": "indefinitely",
                    "model_conf": 221.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/brownsburg/311-w-northfield-drive.html": {
        "name": "Brownsburg",
        "address": "311 W Northfield Drive, Brownsburg, Indiana, 46112",
        "phone": "(317) 852-0700",
        "url": "https://locations.massageenvy.com/indiana/brownsburg/311-w-northfield-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.8567085,
            -86.3955993
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.39,
                    "duration": "1 Months",
                    "model_conf": 70.74
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.66,
                    "duration": "4 Months",
                    "model_conf": 156.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.45,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.86,
                    "duration": "7 Months",
                    "model_conf": 99.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.65,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.46,
                    "duration": "10 Months",
                    "model_conf": 280.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.36,
                    "duration": "8 Months",
                    "model_conf": 191.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.76,
                    "duration": "indefinitely",
                    "model_conf": 166.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.51,
                    "duration": "9 Months",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.64,
                    "duration": "indefinitely",
                    "model_conf": 156.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.37,
                    "duration": "1 Months",
                    "model_conf": 140.35
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.14,
                    "duration": "9 Months",
                    "model_conf": 231.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 85.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.45,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.15,
                    "duration": "6 Months",
                    "model_conf": 219.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/clarksville/1025-veterans-parkway.html": {
        "name": "Clarksville-IN",
        "address": "1025 Veterans Parkway, Clarksville, Indiana, 47129",
        "phone": "(812) 725-1387",
        "url": "https://locations.massageenvy.com/indiana/clarksville/1025-veterans-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.32700535182329,
            -85.76612319051941
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.75,
                    "duration": "indefinitely",
                    "model_conf": 70.99
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 158.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.37,
                    "duration": "3 Months",
                    "model_conf": 102.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.18,
                    "duration": "1 Months",
                    "model_conf": 154.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.6,
                    "duration": "3 Months",
                    "model_conf": 282.11
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 195.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 164.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.74,
                    "duration": "1 Months",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 157.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.37,
                    "duration": "1 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.95,
                    "duration": "3 Months",
                    "model_conf": 237.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 85.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.42,
                    "duration": "3 Months",
                    "model_conf": 54.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.93,
                    "duration": "2 Months",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/evansville/6501-e-lloyd-expressway.html": {
        "name": "Evansville",
        "address": "6501 E Lloyd Expressway Ste 16, Evansville, Indiana, 47715",
        "phone": "(812) 436-3689",
        "url": "https://locations.massageenvy.com/indiana/evansville/6501-e-lloyd-expressway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.97347340950008,
            -87.47296465929271
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.3,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.71,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.63,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.78,
                    "duration": "indefinitely",
                    "model_conf": 102.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.92,
                    "duration": "indefinitely",
                    "model_conf": 153.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.62,
                    "duration": "indefinitely",
                    "model_conf": 282.71
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.37,
                    "duration": "indefinitely",
                    "model_conf": 194.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.22,
                    "duration": "2 Months",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.06,
                    "duration": "indefinitely",
                    "model_conf": 162.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.16,
                    "duration": "1 Months",
                    "model_conf": 157.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.79,
                    "duration": "indefinitely",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.71,
                    "duration": "indefinitely",
                    "model_conf": 235.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.44,
                    "duration": "1 Months",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.64,
                    "duration": "2 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.85,
                    "duration": "indefinitely",
                    "model_conf": 216.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/fishers/11680-commercial-dr-300-400.html": {
        "name": "Fishers",
        "address": "11680 Commercial Dr #300-400, Fishers, Indiana, 46038",
        "phone": "(317) 577-4400",
        "url": "https://locations.massageenvy.com/indiana/fishers/11680-commercial-dr-300-400.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            39.959123,
            -86.01291
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.16,
                    "duration": "indefinitely",
                    "model_conf": 70.0
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 138.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.5,
                    "duration": "indefinitely",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 91.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.69,
                    "duration": "indefinitely",
                    "model_conf": 151.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.21,
                    "duration": "indefinitely",
                    "model_conf": 264.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 182.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 157.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 148.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.51,
                    "duration": "indefinitely",
                    "model_conf": 149.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.46,
                    "duration": "indefinitely",
                    "model_conf": 140.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.36,
                    "duration": "indefinitely",
                    "model_conf": 212.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 83.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.66,
                    "duration": "indefinitely",
                    "model_conf": 53.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.22,
                    "duration": "indefinitely",
                    "model_conf": 202.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/greenwood/1001-n-state-road-135.html": {
        "name": "Greenwood",
        "address": "1001 N State Road 135 Ste B8-B10, Greenwood, Indiana, 46142",
        "phone": "(317) 300-2300",
        "url": "https://locations.massageenvy.com/indiana/greenwood/1001-n-state-road-135.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "12:00 PM - 8:00 PM"
        },
        "geolocation": [
            39.6302714,
            -86.15665539999999
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.96,
                    "duration": "indefinitely",
                    "model_conf": 68.0
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.23,
                    "duration": "indefinitely",
                    "model_conf": 130.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.76,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.67,
                    "duration": "2 Months",
                    "model_conf": 101.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.77,
                    "duration": "indefinitely",
                    "model_conf": 146.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.98,
                    "duration": "indefinitely",
                    "model_conf": 266.26
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 153.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 163.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.91,
                    "duration": "indefinitely",
                    "model_conf": 137.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.31,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.06,
                    "duration": "indefinitely",
                    "model_conf": 140.27
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.8,
                    "duration": "indefinitely",
                    "model_conf": 223.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.95,
                    "duration": "indefinitely",
                    "model_conf": 82.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.1,
                    "duration": "indefinitely",
                    "model_conf": 52.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.7,
                    "duration": "indefinitely",
                    "model_conf": 169.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/highland/10343-indianapolis-blvd.html": {
        "name": "Highland",
        "address": "10343 Indianapolis Blvd Ste 107, Highland, Indiana, 46322",
        "phone": "(219) 922-0900",
        "url": "https://locations.massageenvy.com/indiana/highland/10343-indianapolis-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.525342,
            -87.47051
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.51,
                    "duration": "1 Months",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 102.49
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 153.78
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 274.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 167.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.31,
                    "duration": "1 Months",
                    "model_conf": 157.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.69,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 85.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 220.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/lafayette/3540-state-road-38-east.html": {
        "name": "Lafayette, IN",
        "address": "3540 State Road 38 East Ste 709, Lafayette, Indiana, 47905",
        "phone": "(765) 448-3689",
        "url": "https://locations.massageenvy.com/indiana/lafayette/3540-state-road-38-east.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.3971277,
            -86.8517146
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.59,
                    "duration": "1 Months",
                    "model_conf": 70.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.15,
                    "duration": "2 Months",
                    "model_conf": 158.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.01,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.37,
                    "duration": "4 Months",
                    "model_conf": 98.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.31,
                    "duration": "1 Months",
                    "model_conf": 154.5
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.72,
                    "duration": "6 Months",
                    "model_conf": 283.22
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.76,
                    "duration": "4 Months",
                    "model_conf": 189.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.02,
                    "duration": "indefinitely",
                    "model_conf": 166.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.51,
                    "duration": "9 Months",
                    "model_conf": 166.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.99,
                    "duration": "indefinitely",
                    "model_conf": 157.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.1,
                    "duration": "2 Months",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.94,
                    "duration": "6 Months",
                    "model_conf": 235.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.35,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.69,
                    "duration": "4 Months",
                    "model_conf": 220.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/mishawaka/6501-n-grape-road.html": {
        "name": "University Park Mall",
        "address": "6501 N Grape Road Ste 1125, Mishawaka, Indiana, 46545",
        "phone": "(574) 272-3689",
        "url": "https://locations.massageenvy.com/indiana/mishawaka/6501-n-grape-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.71898740954861,
            -86.18954392086414
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.5,
                    "duration": "1 Months",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.87,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.24,
                    "duration": "5 Months",
                    "model_conf": 101.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.14,
                    "duration": "1 Months",
                    "model_conf": 154.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.91,
                    "duration": "5 Months",
                    "model_conf": 283.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.63,
                    "duration": "indefinitely",
                    "model_conf": 165.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 52.94,
                    "duration": "indefinitely",
                    "model_conf": 167.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.59,
                    "duration": "indefinitely",
                    "model_conf": 157.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.48,
                    "duration": "1 Months",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.91,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.18,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.64,
                    "duration": "4 Months",
                    "model_conf": 221.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/plainfield/2683-e-main-st.html": {
        "name": "Plainfield",
        "address": "2683 E Main St Suite 111, Plainfield, Indiana, 46168",
        "phone": "(317) 839-6000",
        "url": "https://locations.massageenvy.com/indiana/plainfield/2683-e-main-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.716218040663094,
            -86.35375118650825
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.99,
                    "duration": "5 Months",
                    "model_conf": 281.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 166.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.88,
                    "duration": "7 Months",
                    "model_conf": 166.49
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.48,
                    "duration": "indefinitely",
                    "model_conf": 156.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.44,
                    "duration": "1 Months",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.0,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.86,
                    "duration": "4 Months",
                    "model_conf": 219.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/terre-haute/4432-s.-us-hwy-41.html": {
        "name": "Terre Haute",
        "address": "4432 S. US Hwy 41 Suite 94, Terre Haute, Indiana, 47802",
        "phone": "(812) 233-3663",
        "url": "https://locations.massageenvy.com/indiana/terre-haute/4432-s.-us-hwy-41.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.413174,
            -87.41407881349176
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.36,
                    "duration": "indefinitely",
                    "model_conf": 283.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.72,
                    "duration": "indefinitely",
                    "model_conf": 167.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.73,
                    "duration": "indefinitely",
                    "model_conf": 168.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.6,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.51,
                    "duration": "1 Months",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.26,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.45,
                    "duration": "indefinitely",
                    "model_conf": 220.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/valparaiso/300-porters-vale-blvd.html": {
        "name": "Valparaiso",
        "address": "300 Porters Vale Blvd Suite 110, Valparaiso, Indiana, 46383",
        "phone": "(219) 476-7474",
        "url": "https://locations.massageenvy.com/indiana/valparaiso/300-porters-vale-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.47147069671508,
            -87.01586410403252
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.01,
                    "duration": "indefinitely",
                    "model_conf": 283.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.05,
                    "duration": "3 Months",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.05,
                    "duration": "indefinitely",
                    "model_conf": 167.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.2,
                    "duration": "indefinitely",
                    "model_conf": 157.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.97,
                    "duration": "indefinitely",
                    "model_conf": 141.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.33,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.32,
                    "duration": "indefinitely",
                    "model_conf": 55.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.14,
                    "duration": "indefinitely",
                    "model_conf": 216.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/west-lafayette/500-sagamore-parkway-w.html": {
        "name": "West Lafayette",
        "address": "500 Sagamore Parkway W, West Lafayette, Indiana, 47906",
        "phone": "(765) 807-5670",
        "url": "https://locations.massageenvy.com/indiana/west-lafayette/500-sagamore-parkway-w.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.454185,
            -86.912287
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.97,
                    "duration": "indefinitely",
                    "model_conf": 71.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.87,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.49,
                    "duration": "3 Months",
                    "model_conf": 99.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.12,
                    "duration": "1 Months",
                    "model_conf": 154.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.65,
                    "duration": "3 Months",
                    "model_conf": 283.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.8,
                    "duration": "indefinitely",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.11,
                    "duration": "5 Months",
                    "model_conf": 167.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.46,
                    "duration": "indefinitely",
                    "model_conf": 157.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.33,
                    "duration": "1 Months",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.28,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.27,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.41,
                    "duration": "3 Months",
                    "model_conf": 220.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/iowa/ankeny/110-north-ankeny-blvd.html": {
        "name": "Ankeny",
        "address": "110 North Ankeny Blvd Suite 500, Ankeny, Iowa, 50023",
        "phone": "(515) 393-5500",
        "url": "https://locations.massageenvy.com/iowa/ankeny/110-north-ankeny-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.73319737004723,
            -93.60146726333511
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.94,
                    "duration": "indefinitely",
                    "model_conf": 71.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.73,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.67,
                    "duration": "2 Months",
                    "model_conf": 102.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.81,
                    "duration": "indefinitely",
                    "model_conf": 154.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.44,
                    "duration": "1 Months",
                    "model_conf": 283.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.13,
                    "duration": "indefinitely",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 48.94,
                    "duration": "8 Months",
                    "model_conf": 167.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.96,
                    "duration": "indefinitely",
                    "model_conf": 157.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.61,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.25,
                    "duration": "indefinitely",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.3,
                    "duration": "indefinitely",
                    "model_conf": 55.1
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.36,
                    "duration": "1 Months",
                    "model_conf": 220.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/iowa/clive/12655-university-avenue.html": {
        "name": "Clive",
        "address": "12655 University Avenue Suite 180, Clive, Iowa, 50325",
        "phone": "(515) 225-3689",
        "url": "https://locations.massageenvy.com/iowa/clive/12655-university-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.601203,
            -93.79205
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.89,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.43,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.62,
                    "duration": "2 Months",
                    "model_conf": 97.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.94,
                    "duration": "3 Months",
                    "model_conf": 279.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.29,
                    "duration": "indefinitely",
                    "model_conf": 165.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.06,
                    "duration": "7 Months",
                    "model_conf": 161.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.59,
                    "duration": "indefinitely",
                    "model_conf": 157.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.66,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.32,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.28,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.81,
                    "duration": "2 Months",
                    "model_conf": 210.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/iowa/davenport/5302-elmore-avenue.html": {
        "name": "Davenport",
        "address": "5302 Elmore Avenue, Davenport, Iowa, 52807",
        "phone": "(563) 355-5003",
        "url": "https://locations.massageenvy.com/iowa/davenport/5302-elmore-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.57551524748574,
            -90.52930794477464
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.82,
                    "duration": "indefinitely",
                    "model_conf": 70.59
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 159.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.55,
                    "duration": "1 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.94,
                    "duration": "indefinitely",
                    "model_conf": 100.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.16,
                    "duration": "indefinitely",
                    "model_conf": 152.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.26,
                    "duration": "2 Months",
                    "model_conf": 283.35
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 192.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.41,
                    "duration": "2 Months",
                    "model_conf": 166.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.85,
                    "duration": "1 Months",
                    "model_conf": 157.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.76,
                    "duration": "indefinitely",
                    "model_conf": 155.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.64,
                    "duration": "1 Months",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.82,
                    "duration": "indefinitely",
                    "model_conf": 228.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.84,
                    "duration": "indefinitely",
                    "model_conf": 83.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.04,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.68,
                    "duration": "indefinitely",
                    "model_conf": 221.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/iowa/marion/1422-twixt-town-road.html": {
        "name": "Cedar Rapids",
        "address": "1422 Twixt Town Road, Marion Marion, Iowa, 52302",
        "phone": "(319) 377-9900",
        "url": "https://locations.massageenvy.com/iowa/marion/1422-twixt-town-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.0296273,
            -91.6231334
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.81,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.48,
                    "duration": "2 Months",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.83,
                    "duration": "1 Months",
                    "model_conf": 102.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.78,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.63,
                    "duration": "1 Months",
                    "model_conf": 278.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.68,
                    "duration": "1 Months",
                    "model_conf": 167.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.37,
                    "duration": "4 Months",
                    "model_conf": 167.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.98,
                    "duration": "indefinitely",
                    "model_conf": 157.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.59,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.69,
                    "duration": "1 Months",
                    "model_conf": 85.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.04,
                    "duration": "indefinitely",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.4,
                    "duration": "1 Months",
                    "model_conf": 221.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/lawrence/4931-w-6th-street.html": {
        "name": "Lawrence",
        "address": "4931 W 6th Street Ste 110, Lawrence Lawrence, Kansas, 66049",
        "phone": "(785) 841-2000",
        "url": "https://locations.massageenvy.com/kansas/lawrence/4931-w-6th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.9704164954558,
            -95.30920797851161
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.35,
                    "duration": "1 Months",
                    "model_conf": 71.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.86,
                    "duration": "indefinitely",
                    "model_conf": 102.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.14,
                    "duration": "1 Months",
                    "model_conf": 155.2
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.85,
                    "duration": "3 Months",
                    "model_conf": 282.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.19,
                    "duration": "indefinitely",
                    "model_conf": 167.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.26,
                    "duration": "4 Months",
                    "model_conf": 166.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.89,
                    "duration": "indefinitely",
                    "model_conf": 157.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.61,
                    "duration": "1 Months",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.86,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.71,
                    "duration": "2 Months",
                    "model_conf": 220.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/leawood/4312-14-w-119th.html": {
        "name": "Leawood-Camelot Court",
        "address": "4312 14 W 119th, Leawood, Kansas, 66209",
        "phone": "(913) 491-4440",
        "url": "https://locations.massageenvy.com/kansas/leawood/4312-14-w-119th.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.913953,
            -94.63679
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.16,
                    "duration": "indefinitely",
                    "model_conf": 69.78
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.61,
                    "duration": "indefinitely",
                    "model_conf": 142.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.31,
                    "duration": "indefinitely",
                    "model_conf": 83.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.41,
                    "duration": "indefinitely",
                    "model_conf": 154.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.86,
                    "duration": "indefinitely",
                    "model_conf": 277.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.89,
                    "duration": "indefinitely",
                    "model_conf": 188.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.41,
                    "duration": "5 Months",
                    "model_conf": 160.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.85,
                    "duration": "indefinitely",
                    "model_conf": 143.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.06,
                    "duration": "2 Months",
                    "model_conf": 151.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.09,
                    "duration": "5 Months",
                    "model_conf": 140.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.97,
                    "duration": "indefinitely",
                    "model_conf": 220.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.4,
                    "duration": "1 Months",
                    "model_conf": 82.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.68,
                    "duration": "2 Months",
                    "model_conf": 53.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.67,
                    "duration": "indefinitely",
                    "model_conf": 187.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/lenexa/13224-w-87th-st.html": {
        "name": "Lenexa/Shawnee",
        "address": "13224 W 87th St, Lenexa, Kansas, 66215",
        "phone": "(913) 888-3689",
        "url": "https://locations.massageenvy.com/kansas/lenexa/13224-w-87th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.971512,
            -94.73935
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.5,
                    "duration": "1 Months",
                    "model_conf": 69.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.01,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.61,
                    "duration": "2 Months",
                    "model_conf": 85.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.77,
                    "duration": "indefinitely",
                    "model_conf": 153.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.21,
                    "duration": "5 Months",
                    "model_conf": 282.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.77,
                    "duration": "indefinitely",
                    "model_conf": 163.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.02,
                    "duration": "6 Months",
                    "model_conf": 145.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.29,
                    "duration": "indefinitely",
                    "model_conf": 152.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.82,
                    "duration": "3 Months",
                    "model_conf": 140.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.31,
                    "duration": "indefinitely",
                    "model_conf": 83.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.52,
                    "duration": "indefinitely",
                    "model_conf": 54.15
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.18,
                    "duration": "3 Months",
                    "model_conf": 189.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/overland-park/9095-metcalf-ave.html": {
        "name": "North Overland Park",
        "address": "9095 Metcalf Ave, Overland Park Overland Park, Kansas, 66212",
        "phone": "(913) 381-3689",
        "url": "https://locations.massageenvy.com/kansas/overland-park/9095-metcalf-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.964835443489854,
            -94.66675426271473
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.19,
                    "duration": "2 Months",
                    "model_conf": 69.84
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 144.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.53,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.72,
                    "duration": "1 Months",
                    "model_conf": 85.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.68,
                    "duration": "indefinitely",
                    "model_conf": 154.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.86,
                    "duration": "6 Months",
                    "model_conf": 279.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.37,
                    "duration": "5 Months",
                    "model_conf": 189.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.37,
                    "duration": "indefinitely",
                    "model_conf": 161.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.56,
                    "duration": "9 Months",
                    "model_conf": 145.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.23,
                    "duration": "indefinitely",
                    "model_conf": 152.15
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.6,
                    "duration": "4 Months",
                    "model_conf": 140.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.86,
                    "duration": "6 Months",
                    "model_conf": 221.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.28,
                    "duration": "indefinitely",
                    "model_conf": 82.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.34,
                    "duration": "indefinitely",
                    "model_conf": 53.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.16,
                    "duration": "6 Months",
                    "model_conf": 190.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/topeka/2130-sw-wanamaker-road.html": {
        "name": "Topeka",
        "address": "2130 SW Wanamaker Road Suite 120, Topeka, Kansas, 66614",
        "phone": "(785) 228-3689",
        "url": "https://locations.massageenvy.com/kansas/topeka/2130-sw-wanamaker-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.0276851,
            -95.7596896
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.24,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.44,
                    "duration": "indefinitely",
                    "model_conf": 159.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.55,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.27,
                    "duration": "indefinitely",
                    "model_conf": 101.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.0,
                    "duration": "indefinitely",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.29,
                    "duration": "indefinitely",
                    "model_conf": 282.5
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.63,
                    "duration": "indefinitely",
                    "model_conf": 195.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.96,
                    "duration": "indefinitely",
                    "model_conf": 167.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.06,
                    "duration": "indefinitely",
                    "model_conf": 166.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.76,
                    "duration": "indefinitely",
                    "model_conf": 157.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.16,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.66,
                    "duration": "indefinitely",
                    "model_conf": 238.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.1,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.08,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.01,
                    "duration": "indefinitely",
                    "model_conf": 221.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/florence/4960-houston-road.html": {
        "name": "Florence",
        "address": "4960 Houston Road Suite E, Florence, Kentucky, 41042",
        "phone": "(859) 647-1777",
        "url": "https://locations.massageenvy.com/kentucky/florence/4960-houston-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.013636,
            -84.638463
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 70.87
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.23,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.58,
                    "duration": "2 Months",
                    "model_conf": 102.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.62,
                    "duration": "indefinitely",
                    "model_conf": 281.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.84,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.95,
                    "duration": "indefinitely",
                    "model_conf": 165.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.66,
                    "duration": "indefinitely",
                    "model_conf": 156.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.96,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.5,
                    "duration": "1 Months",
                    "model_conf": 85.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.08,
                    "duration": "indefinitely",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 221.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/newport/189-pavilion-parkway.html": {
        "name": "Newport - KY",
        "address": "189 Pavilion Parkway, Newport, Kentucky, 41071",
        "phone": "(859) 261-3689",
        "url": "https://locations.massageenvy.com/kentucky/newport/189-pavilion-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.0865493,
            -84.4764255
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.34,
                    "duration": "indefinitely",
                    "model_conf": 281.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.68,
                    "duration": "indefinitely",
                    "model_conf": 193.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 163.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.35,
                    "duration": "1 Months",
                    "model_conf": 156.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.9,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.62,
                    "duration": "indefinitely",
                    "model_conf": 235.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.01,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.78,
                    "duration": "1 Months",
                    "model_conf": 54.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.63,
                    "duration": "indefinitely",
                    "model_conf": 217.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/bossier-city/2800-beene-blvd..html": {
        "name": "Bossier City",
        "address": "2800 Beene Blvd., Bossier City, Louisiana, 71111",
        "phone": "(318) 741-3722",
        "url": "https://locations.massageenvy.com/louisiana/bossier-city/2800-beene-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.56086647876032,
            -93.70810027116397
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.36,
                    "duration": "indefinitely",
                    "model_conf": 70.74
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.73,
                    "duration": "indefinitely",
                    "model_conf": 159.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.02,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.92,
                    "duration": "indefinitely",
                    "model_conf": 102.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 154.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.78,
                    "duration": "indefinitely",
                    "model_conf": 280.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.23,
                    "duration": "indefinitely",
                    "model_conf": 194.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.9,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.65,
                    "duration": "2 Months",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.73,
                    "duration": "indefinitely",
                    "model_conf": 156.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.39,
                    "duration": "indefinitely",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.66,
                    "duration": "1 Months",
                    "model_conf": 237.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.41,
                    "duration": "indefinitely",
                    "model_conf": 85.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.32,
                    "duration": "indefinitely",
                    "model_conf": 55.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.38,
                    "duration": "1 Months",
                    "model_conf": 220.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/harahan/1208-s.-clearview-pkwy..html": {
        "name": "Elmwood",
        "address": "1208 S. Clearview Pkwy. Suite C, Harahan, Louisiana, 70123",
        "phone": "(504) 737-3689",
        "url": "https://locations.massageenvy.com/louisiana/harahan/1208-s.-clearview-pkwy..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.9599755,
            -90.1846188
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.61,
                    "duration": "1 Months",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 154.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.07,
                    "duration": "3 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.16,
                    "duration": "indefinitely",
                    "model_conf": 79.0
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.76,
                    "duration": "indefinitely",
                    "model_conf": 147.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.04,
                    "duration": "indefinitely",
                    "model_conf": 227.99
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 157.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.0,
                    "duration": "3 Months",
                    "model_conf": 166.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.42,
                    "duration": "indefinitely",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.95,
                    "duration": "indefinitely",
                    "model_conf": 154.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.32,
                    "duration": "1 Months",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 202.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.67,
                    "duration": "1 Months",
                    "model_conf": 80.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.79,
                    "duration": "1 Months",
                    "model_conf": 51.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.44,
                    "duration": "indefinitely",
                    "model_conf": 221.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/mandeville/3471-e-causeway-approach.html": {
        "name": "Mandeville",
        "address": "3471 E Causeway Approach Ste 4, Mandeville, Louisiana, 70448",
        "phone": "(985) 626-6260",
        "url": "https://locations.massageenvy.com/louisiana/mandeville/3471-e-causeway-approach.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            30.375320894565466,
            -90.08984619940567
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.7,
                    "duration": "indefinitely",
                    "model_conf": 71.36
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.09,
                    "duration": "indefinitely",
                    "model_conf": 102.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.44,
                    "duration": "1 Months",
                    "model_conf": 154.78
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.16,
                    "duration": "2 Months",
                    "model_conf": 283.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.42,
                    "duration": "3 Months",
                    "model_conf": 167.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 157.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.86,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.87,
                    "duration": "indefinitely",
                    "model_conf": 85.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.96,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.01,
                    "duration": "2 Months",
                    "model_conf": 215.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/monroe/4429-pecanland-mall-dr.html": {
        "name": "Monroe - West Monroe",
        "address": "4429 Pecanland Mall Dr, Monroe, Louisiana, 71203",
        "phone": "(318) 651-9902",
        "url": "https://locations.massageenvy.com/louisiana/monroe/4429-pecanland-mall-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.499407434546704,
            -92.06726825396731
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.41,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 159.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.36,
                    "duration": "indefinitely",
                    "model_conf": 101.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 155.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.45,
                    "duration": "indefinitely",
                    "model_conf": 283.11
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.56,
                    "duration": "indefinitely",
                    "model_conf": 192.13
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.35,
                    "duration": "indefinitely",
                    "model_conf": 165.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.4,
                    "duration": "indefinitely",
                    "model_conf": 168.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.37,
                    "duration": "indefinitely",
                    "model_conf": 157.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.03,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 232.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.21,
                    "duration": "indefinitely",
                    "model_conf": 221.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/new-orleans/5300-tchoupitoulas-st.html": {
        "name": "Uptown",
        "address": "5300 Tchoupitoulas St, New Orleans, Louisiana, 70115",
        "phone": "(504) 891-8980",
        "url": "https://locations.massageenvy.com/louisiana/new-orleans/5300-tchoupitoulas-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.91593,
            -90.115448
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.88,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.71,
                    "duration": "1 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.2,
                    "duration": "6 Months",
                    "model_conf": 86.16
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.74,
                    "duration": "2 Months",
                    "model_conf": 149.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.27,
                    "duration": "4 Months",
                    "model_conf": 245.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 101.1,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 48.46,
                    "duration": "12 Months",
                    "model_conf": 167.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.25,
                    "duration": "indefinitely",
                    "model_conf": 155.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.6,
                    "duration": "1 Months",
                    "model_conf": 82.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.11,
                    "duration": "indefinitely",
                    "model_conf": 52.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.37,
                    "duration": "3 Months",
                    "model_conf": 221.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/shreveport/7523-youree-drive.html": {
        "name": "Youree Drive",
        "address": "7523 Youree Drive, Shreveport, Louisiana, 71105",
        "phone": "(318) 797-3722",
        "url": "https://locations.massageenvy.com/louisiana/shreveport/7523-youree-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.4381729,
            -93.7116438
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 69.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.19,
                    "duration": "2 Months",
                    "model_conf": 159.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.18,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.47,
                    "duration": "3 Months",
                    "model_conf": 102.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.34,
                    "duration": "1 Months",
                    "model_conf": 154.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.12,
                    "duration": "5 Months",
                    "model_conf": 281.78
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.01,
                    "duration": "3 Months",
                    "model_conf": 194.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.53,
                    "duration": "indefinitely",
                    "model_conf": 165.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.4,
                    "duration": "10 Months",
                    "model_conf": 164.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.2,
                    "duration": "indefinitely",
                    "model_conf": 155.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.27,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.16,
                    "duration": "5 Months",
                    "model_conf": 238.24
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.54,
                    "duration": "indefinitely",
                    "model_conf": 54.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.13,
                    "duration": "4 Months",
                    "model_conf": 220.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/slidell/126-town-center-pkwy.html": {
        "name": "Slidell",
        "address": "126 Town Center Pkwy, Slidell, Louisiana, 70458",
        "phone": "(985) 781-7900",
        "url": "https://locations.massageenvy.com/louisiana/slidell/126-town-center-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.2694777,
            -89.7590571
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.39,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 159.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.57,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.14,
                    "duration": "indefinitely",
                    "model_conf": 98.95
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.92,
                    "duration": "indefinitely",
                    "model_conf": 155.2
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.11,
                    "duration": "indefinitely",
                    "model_conf": 281.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.29,
                    "duration": "indefinitely",
                    "model_conf": 194.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.68,
                    "duration": "indefinitely",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.02,
                    "duration": "indefinitely",
                    "model_conf": 168.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.55,
                    "duration": "indefinitely",
                    "model_conf": 156.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.41,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 237.62
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.2,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.1,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.82,
                    "duration": "indefinitely",
                    "model_conf": 221.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/annapolis/2327-b-forest-dr.html": {
        "name": "Annapolis",
        "address": "2327 B Forest Dr, Annapolis, Maryland, 21401",
        "phone": "(410) 224-4184",
        "url": "https://locations.massageenvy.com/maryland/annapolis/2327-b-forest-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.978874,
            -76.545925
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.5,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.65,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.55,
                    "duration": "2 Months",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.58,
                    "duration": "indefinitely",
                    "model_conf": 221.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/baltimore/727-w.-40th-street.html": {
        "name": "Rotunda",
        "address": "727 W. 40th Street Suite 108, Baltimore Baltimore, Maryland, 21211",
        "phone": "(410) 387-2555",
        "url": "https://locations.massageenvy.com/maryland/baltimore/727-w.-40th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.33487863136247,
            -76.63056991667173
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.02,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.28,
                    "duration": "indefinitely",
                    "model_conf": 84.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 54.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.23,
                    "duration": "indefinitely",
                    "model_conf": 220.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/bel-air/591-baltimore-pike.html": {
        "name": "Bel Air",
        "address": "591 Baltimore Pike, Bel Air Bel Air, Maryland, 21014",
        "phone": "(410) 420-7770",
        "url": "https://locations.massageenvy.com/maryland/bel-air/591-baltimore-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.52625711586126,
            -76.35196723925236
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.06,
                    "duration": "2 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.96,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.99,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.7,
                    "duration": "indefinitely",
                    "model_conf": 218.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/college-park/10260-baltimore-ave.html": {
        "name": "College Park",
        "address": "10260 Baltimore Ave Ste L, College Park College Park, Maryland, 20740",
        "phone": "(301) 313-0555",
        "url": "https://locations.massageenvy.com/maryland/college-park/10260-baltimore-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.02310304402564,
            -76.9253945837213
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.91,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.94,
                    "duration": "indefinitely",
                    "model_conf": 85.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.98,
                    "duration": "indefinitely",
                    "model_conf": 55.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.2,
                    "duration": "1 Months",
                    "model_conf": 217.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/elkridge/6630-marie-curie-drive.html": {
        "name": "Elkridge",
        "address": "6630 Marie Curie Drive, Elkridge Elkridge, Maryland, 21075",
        "phone": "(443) 661-3689",
        "url": "https://locations.massageenvy.com/maryland/elkridge/6630-marie-curie-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            39.187869,
            -76.796469
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.28,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.11,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.01,
                    "duration": "indefinitely",
                    "model_conf": 55.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.44,
                    "duration": "1 Months",
                    "model_conf": 221.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/ellicott-city/10039-baltimore-national-pike.html": {
        "name": "Ellicott City",
        "address": "10039 Baltimore National Pike, Ellicott City Ellicott City, Maryland, 21042",
        "phone": "(410) 313-8600",
        "url": "https://locations.massageenvy.com/maryland/ellicott-city/10039-baltimore-national-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            39.2782276,
            -76.8575561
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.17,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.38,
                    "duration": "1 Months",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.67,
                    "duration": "1 Months",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.29,
                    "duration": "indefinitely",
                    "model_conf": 221.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/frederick/7820-worman-s-mill-rd.html": {
        "name": "Frederick",
        "address": "7820 Worman's Mill Rd Ste G, Frederick, Maryland, 21701",
        "phone": "(240) 397-2333",
        "url": "https://locations.massageenvy.com/maryland/frederick/7820-worman-s-mill-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.44600672814735,
            -77.39655989814537
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": null,
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/gaithersburg/127-crown-park-ave.html": {
        "name": "Gaithersburg",
        "address": "127 Crown Park Ave, Gaithersburg, Maryland, 20878",
        "phone": "(301) 519-3689",
        "url": "https://locations.massageenvy.com/maryland/gaithersburg/127-crown-park-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            39.1145644,
            -77.2061516
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.25,
                    "duration": "1 Months",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.5,
                    "duration": "indefinitely",
                    "model_conf": 85.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.26,
                    "duration": "1 Months",
                    "model_conf": 215.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/gambrills/1404-s-main-chapel-way.html": {
        "name": "Crofton",
        "address": "1404 S Main Chapel Way Ste 110, Gambrills Gambrills, Maryland, 21054",
        "phone": "(410) 451-3689",
        "url": "https://locations.massageenvy.com/maryland/gambrills/1404-s-main-chapel-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.0330474,
            -76.685515
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/germantown/20950-frederick-rd.html": {
        "name": "Germantown",
        "address": "20950 Frederick Rd, Germantown, Maryland, 20876",
        "phone": "(301) 972-5599",
        "url": "https://locations.massageenvy.com/maryland/germantown/20950-frederick-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 7:00 PM",
            "TUESDAY": "11:00 AM - 7:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 7:00 PM",
            "FRIDAY": "Closed",
            "SATURDAY": "10:00 AM - 4:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            39.20300660987521,
            -77.24655635581973
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": null,
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/glen-burnie/7927-ritchie-hwy.html": {
        "name": "Glen Burnie",
        "address": "7927 Ritchie Hwy Ste E, Glen Burnie, Maryland, 21061",
        "phone": "(410) 691-8181",
        "url": "https://locations.massageenvy.com/maryland/glen-burnie/7927-ritchie-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.136914,
            -76.595535
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.52,
                    "duration": "1 Months",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.73,
                    "duration": "indefinitely",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.86,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.12,
                    "duration": "1 Months",
                    "model_conf": 220.02
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/hagerstown/12814-shank-farm-way.html": {
        "name": "Hagerstown",
        "address": "12814 Shank Farm Way Suite D, Hagerstown, Maryland, 21742",
        "phone": "(240) 513-6981",
        "url": "https://locations.massageenvy.com/maryland/hagerstown/12814-shank-farm-way.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "Closed",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.6651834,
            -77.6999445
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/hanover/7645-arundel-mills-blvd..html": {
        "name": "Arundel Mills",
        "address": "7645 Arundel Mills Blvd., Hanover, Maryland, 21076",
        "phone": "(410) 799-2002",
        "url": "https://locations.massageenvy.com/maryland/hanover/7645-arundel-mills-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            39.1519258,
            -76.7230566
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.99,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.72,
                    "duration": "indefinitely",
                    "model_conf": 85.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.49,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.02,
                    "duration": "1 Months",
                    "model_conf": 221.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/lanham/9300-woodmore-centre-drive.html": {
        "name": "Bowie-Woodmore Towne Centre",
        "address": "9300 Woodmore Centre Drive Ste 501, Lanham Lanham, Maryland, 20706",
        "phone": "(301) 364-3400",
        "url": "https://locations.massageenvy.com/maryland/lanham/9300-woodmore-centre-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.9219811,
            -76.8459977
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.55,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.98,
                    "duration": "indefinitely",
                    "model_conf": 85.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.1,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.19,
                    "duration": "indefinitely",
                    "model_conf": 219.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/laurel/14720-baltimore-ave.html": {
        "name": "Laurel",
        "address": "14720 Baltimore Ave, Laurel, Maryland, 20707",
        "phone": "(301) 497-9977",
        "url": "https://locations.massageenvy.com/maryland/laurel/14720-baltimore-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.0937446,
            -76.8561029
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.27,
                    "duration": "1 Months",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.6,
                    "duration": "1 Months",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.02,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.68,
                    "duration": "2 Months",
                    "model_conf": 220.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/nottingham/5247-campbell-blvd.html": {
        "name": "White Marsh",
        "address": "5247 Campbell Blvd, Nottingham Nottingham, Maryland, 21236",
        "phone": "(410) 933-0308",
        "url": "https://locations.massageenvy.com/maryland/nottingham/5247-campbell-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.367928371812994,
            -76.45404339003665
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/ocean-pines/11001-manklin-creek-rd.html": {
        "name": "Ocean City",
        "address": "11001 Manklin Creek Rd Building D - Unit 4, Ocean Pines, Maryland, 21811",
        "phone": "(410) 387-2698",
        "url": "https://locations.massageenvy.com/maryland/ocean-pines/11001-manklin-creek-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 5:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.3751871,
            -75.1637988
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": null,
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/owings-mills/10130-reisterstown-rd.html": {
        "name": "Owings Mills",
        "address": "10130 Reisterstown Rd Suite #20, Owings Mills, Maryland, 21117",
        "phone": "(443) 546-9902",
        "url": "https://locations.massageenvy.com/maryland/owings-mills/10130-reisterstown-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            39.41293734976613,
            -76.7727841484795
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.68,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.81,
                    "duration": "1 Months",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.57,
                    "duration": "indefinitely",
                    "model_conf": 217.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/rockville/12266-rockville-pike.html": {
        "name": "Rockville",
        "address": "12266 Rockville Pike Ste Q, Rockville, Maryland, 20852",
        "phone": "(240) 221-0078",
        "url": "https://locations.massageenvy.com/maryland/rockville/12266-rockville-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 7:00 PM",
            "TUESDAY": "11:00 AM - 8:30 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 8:30 PM",
            "FRIDAY": "11:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            39.0570711,
            -77.1222523
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.89,
                    "duration": "indefinitely",
                    "model_conf": 70.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.64,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.74,
                    "duration": "indefinitely",
                    "model_conf": 85.0
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.3,
                    "duration": "indefinitely",
                    "model_conf": 147.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.28,
                    "duration": "indefinitely",
                    "model_conf": 258.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.56,
                    "duration": "3 Months",
                    "model_conf": 157.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.95,
                    "duration": "indefinitely",
                    "model_conf": 146.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.49,
                    "duration": "2 Months",
                    "model_conf": 141.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.23,
                    "duration": "1 Months",
                    "model_conf": 139.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.29,
                    "duration": "1 Months",
                    "model_conf": 83.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.34,
                    "duration": "3 Months",
                    "model_conf": 53.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.23,
                    "duration": "indefinitely",
                    "model_conf": 194.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/salisbury/2657-n-salisbury-blvd.html": {
        "name": "Salisbury",
        "address": "2657 N Salisbury Blvd Ste 105, Salisbury, Maryland, 21801",
        "phone": "(443) 736-7772",
        "url": "https://locations.massageenvy.com/maryland/salisbury/2657-n-salisbury-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.414496510441616,
            -75.56564207916848
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": "N/",
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.77,
                    "duration": "indefinitely",
                    "model_conf": 281.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.5,
                    "duration": "1 Months",
                    "model_conf": 166.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.49,
                    "duration": "indefinitely",
                    "model_conf": 167.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.06,
                    "duration": "indefinitely",
                    "model_conf": 157.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/severna-park/145-ritchie-hwy.html": {
        "name": "Severna Park",
        "address": "145 Ritchie Hwy, Severna Park, Maryland, 21146",
        "phone": "(410) 544-3800",
        "url": "https://locations.massageenvy.com/maryland/severna-park/145-ritchie-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.100908830184174,
            -76.56496804662703
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.23,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.42,
                    "duration": "1 Months",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.59,
                    "duration": "2 Months",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.96,
                    "duration": "2 Months",
                    "model_conf": 220.33
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/silver-spring/955-wayne-avenue.html": {
        "name": "Downtown Silver Spring",
        "address": "955 Wayne Avenue, Silver Spring, Maryland, 20910",
        "phone": "(301) 264-5154",
        "url": "https://locations.massageenvy.com/maryland/silver-spring/955-wayne-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 8:00 PM",
            "TUESDAY": "11:00 AM - 8:00 PM",
            "WEDNESDAY": "Closed",
            "THURSDAY": "Closed",
            "FRIDAY": "11:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.9947732,
            -77.0261978
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 120.475,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": null,
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "132",
                        "servicePrice": null,
                        "memberAdditional": "97",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "167"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": null,
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": null,
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "43",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "43"
                    },
                    "serviceTime": "30min",
                    "accrual": "43"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "60",
                        "servicePrice": null,
                        "memberAdditional": "49",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "78"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": null,
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.52,
                    "duration": "indefinitely",
                    "model_conf": 70.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.42,
                    "duration": "1 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 84.77,
                    "duration": "1 Months",
                    "model_conf": 86.66
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 241.1,
                    "duration": "indefinitely",
                    "model_conf": 148.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.28,
                    "duration": "1 Months",
                    "model_conf": 263.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.83,
                    "duration": "2 Months",
                    "model_conf": 157.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 85.63,
                    "duration": "indefinitely",
                    "model_conf": 148.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 239.87,
                    "duration": "indefinitely",
                    "model_conf": 142.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/timonium/1810-york-road.html": {
        "name": "Timonium",
        "address": "1810 York Road Ste D-F, Timonium Timonium, Maryland, 21093",
        "phone": "(410) 387-4600",
        "url": "https://locations.massageenvy.com/maryland/timonium/1810-york-road.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "11:00 AM - 7:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 7:00 PM",
            "FRIDAY": "11:00 AM - 7:00 PM",
            "SATURDAY": "11:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 7:00 PM"
        },
        "geolocation": [
            39.430706,
            -76.6222446
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/urbana/3511-john-simmons-st..html": {
        "name": "Urbana",
        "address": "3511 John Simmons St. Suite B103, Urbana Urbana, Maryland, 21704",
        "phone": "(301) 321-3689",
        "url": "https://locations.massageenvy.com/maryland/urbana/3511-john-simmons-st..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.3302661,
            -77.3535133
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.27,
                    "duration": "1 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.34,
                    "duration": "indefinitely",
                    "model_conf": 85.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.85,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.48,
                    "duration": "1 Months",
                    "model_conf": 221.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/maryland/waldorf/3031-festival-way.html": {
        "name": "Waldorf",
        "address": "3031 Festival Way, Waldorf, Maryland, 20601",
        "phone": "(301) 645-6697",
        "url": "https://locations.massageenvy.com/maryland/waldorf/3031-festival-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.6298267,
            -76.9120363
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/maryland/westminster/140-englar-rd.html": {
        "name": "Westminster-MD",
        "address": "140 Englar Rd Suite 21, Westminster, Maryland, 21157",
        "phone": "(410) 848-8400",
        "url": "https://locations.massageenvy.com/maryland/westminster/140-englar-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.5818217,
            -76.9904976
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 141.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.76,
                    "duration": "3 Months",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.23,
                    "duration": "4 Months",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.29,
                    "duration": "1 Months",
                    "model_conf": 221.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/arlington/1398-massachusetts-ave.html": {
        "name": "Arlington Heights",
        "address": "1398 Massachusetts Ave, Arlington, Massachusetts, 02476",
        "phone": "(781) 641-3689",
        "url": "https://locations.massageenvy.com/massachusetts/arlington/1398-massachusetts-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            42.424429,
            -71.18534
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 279.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.33,
                    "duration": "1 Months",
                    "model_conf": 162.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.95,
                    "duration": "indefinitely",
                    "model_conf": 164.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.29,
                    "duration": "1 Months",
                    "model_conf": 156.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.59,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.64,
                    "duration": "indefinitely",
                    "model_conf": 83.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.2,
                    "duration": "indefinitely",
                    "model_conf": 53.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.71,
                    "duration": "indefinitely",
                    "model_conf": 215.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/boston/211-berkeley-street.html": {
        "name": "Back Bay Boston",
        "address": "211 Berkeley Street, Boston, Massachusetts, 02116",
        "phone": "(857) 239-1111",
        "url": "https://locations.massageenvy.com/massachusetts/boston/211-berkeley-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            42.350912,
            -71.0727257
        ],
        "rating": 3.9,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.93,
                    "duration": "indefinitely",
                    "model_conf": 70.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.82,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.31,
                    "duration": "indefinitely",
                    "model_conf": 98.99
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.51,
                    "duration": "indefinitely",
                    "model_conf": 152.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.11,
                    "duration": "indefinitely",
                    "model_conf": 278.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.02,
                    "duration": "2 Months",
                    "model_conf": 158.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.67,
                    "duration": "indefinitely",
                    "model_conf": 163.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.29,
                    "duration": "1 Months",
                    "model_conf": 156.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.31,
                    "duration": "1 Months",
                    "model_conf": 140.58
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.9,
                    "duration": "indefinitely",
                    "model_conf": 81.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.67,
                    "duration": "indefinitely",
                    "model_conf": 50.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.6,
                    "duration": "indefinitely",
                    "model_conf": 213.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/brockton/1309-belmont-street.html": {
        "name": "Easton-Brockton",
        "address": "1309 Belmont Street, Brockton, Massachusetts, 02301",
        "phone": "(508) 580-3689",
        "url": "https://locations.massageenvy.com/massachusetts/brockton/1309-belmont-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.058157,
            -71.069438
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.23,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.12,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.57,
                    "duration": "2 Months",
                    "model_conf": 102.49
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.92,
                    "duration": "1 Months",
                    "model_conf": 154.49
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.32,
                    "duration": "1 Months",
                    "model_conf": 282.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.4,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.85,
                    "duration": "5 Months",
                    "model_conf": 167.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.1,
                    "duration": "indefinitely",
                    "model_conf": 157.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.34,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.0,
                    "duration": "indefinitely",
                    "model_conf": 85.35
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.03,
                    "duration": "indefinitely",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.69,
                    "duration": "2 Months",
                    "model_conf": 221.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/brookline/481-harvard-street.html": {
        "name": "Brookline",
        "address": "481 Harvard Street, Brookline, Massachusetts, 02446",
        "phone": "(617) 277-5600",
        "url": "https://locations.massageenvy.com/massachusetts/brookline/481-harvard-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.347258,
            -71.12848
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.02,
                    "duration": "3 Months",
                    "model_conf": 70.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.7,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.34,
                    "duration": "indefinitely",
                    "model_conf": 99.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.46,
                    "duration": "1 Months",
                    "model_conf": 151.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.1,
                    "duration": "2 Months",
                    "model_conf": 278.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.0,
                    "duration": "indefinitely",
                    "model_conf": 156.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.92,
                    "duration": "indefinitely",
                    "model_conf": 163.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.64,
                    "duration": "3 Months",
                    "model_conf": 156.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.81,
                    "duration": "indefinitely",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.94,
                    "duration": "4 Months",
                    "model_conf": 80.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.4,
                    "duration": "5 Months",
                    "model_conf": 50.04
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.4,
                    "duration": "1 Months",
                    "model_conf": 214.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/burlington/2-wayside-road.html": {
        "name": "Burlington",
        "address": "2 Wayside Road, Burlington Burlington, Massachusetts, 01803",
        "phone": "(781) 270-5555",
        "url": "https://locations.massageenvy.com/massachusetts/burlington/2-wayside-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.48462035511,
            -71.19366637509114
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.25,
                    "duration": "indefinitely",
                    "model_conf": 277.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.63,
                    "duration": "indefinitely",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.91,
                    "duration": "indefinitely",
                    "model_conf": 165.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.66,
                    "duration": "indefinitely",
                    "model_conf": 156.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.02,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.69,
                    "duration": "indefinitely",
                    "model_conf": 84.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.19,
                    "duration": "indefinitely",
                    "model_conf": 53.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.71,
                    "duration": "indefinitely",
                    "model_conf": 216.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/cambridge/750-memorial-drive.html": {
        "name": "Cambridge Memorial Drive",
        "address": "750 Memorial Drive, Cambridge Cambridge, Massachusetts, 02139",
        "phone": "(617) 234-0007",
        "url": "https://locations.massageenvy.com/massachusetts/cambridge/750-memorial-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.358101,
            -71.115018
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.83,
                    "duration": "indefinitely",
                    "model_conf": 278.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.72,
                    "duration": "3 Months",
                    "model_conf": 157.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.7,
                    "duration": "1 Months",
                    "model_conf": 162.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.48,
                    "duration": "indefinitely",
                    "model_conf": 156.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.63,
                    "duration": "3 Months",
                    "model_conf": 140.41
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.35,
                    "duration": "1 Months",
                    "model_conf": 80.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.19,
                    "duration": "4 Months",
                    "model_conf": 49.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 212.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/danvers/35-independence-way.html": {
        "name": "Danvers",
        "address": "35 Independence Way, Danvers Danvers, Massachusetts, 01923",
        "phone": "(978) 777-7002",
        "url": "https://locations.massageenvy.com/massachusetts/danvers/35-independence-way.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.54984445536426,
            -70.93992462440599
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.7,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.6,
                    "duration": "2 Months",
                    "model_conf": 101.29
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.94,
                    "duration": "5 Months",
                    "model_conf": 276.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.57,
                    "duration": "1 Months",
                    "model_conf": 165.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.4,
                    "duration": "4 Months",
                    "model_conf": 165.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 156.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.83,
                    "duration": "3 Months",
                    "model_conf": 140.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.04,
                    "duration": "indefinitely",
                    "model_conf": 85.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.27,
                    "duration": "indefinitely",
                    "model_conf": 54.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.44,
                    "duration": "3 Months",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/dedham/820-providence-highway-south.html": {
        "name": "Dedham",
        "address": "820 Providence Highway South, Dedham Dedham, Massachusetts, 02026",
        "phone": "(781) 329-2777",
        "url": "https://locations.massageenvy.com/massachusetts/dedham/820-providence-highway-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.23645183015793,
            -71.17792382209012
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.24,
                    "duration": "1 Months",
                    "model_conf": 70.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.44,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.75,
                    "duration": "1 Months",
                    "model_conf": 96.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.61,
                    "duration": "indefinitely",
                    "model_conf": 151.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.9,
                    "duration": "2 Months",
                    "model_conf": 279.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.6,
                    "duration": "3 Months",
                    "model_conf": 154.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.72,
                    "duration": "1 Months",
                    "model_conf": 162.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.64,
                    "duration": "indefinitely",
                    "model_conf": 155.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.6,
                    "duration": "3 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.18,
                    "duration": "2 Months",
                    "model_conf": 79.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.58,
                    "duration": "2 Months",
                    "model_conf": 47.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.46,
                    "duration": "indefinitely",
                    "model_conf": 208.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/framingham/85-worcester-rd.html": {
        "name": "Framingham-Natick-Wellesley (TEMPORARILY CLOSED)",
        "address": "85 Worcester Rd Ste B, Framingham Framingham, Massachusetts, 01701",
        "phone": "(508) 879-3689",
        "url": "https://locations.massageenvy.com/massachusetts/framingham/85-worcester-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "Closed",
            "WEDNESDAY": "Closed",
            "THURSDAY": "Closed",
            "FRIDAY": "Closed",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            42.299457,
            -71.397457
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.99,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.71,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.74,
                    "duration": "1 Months",
                    "model_conf": 101.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.59,
                    "duration": "indefinitely",
                    "model_conf": 154.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 162.79,
                    "duration": "5 Months",
                    "model_conf": 280.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.87,
                    "duration": "indefinitely",
                    "model_conf": 165.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.66,
                    "duration": "1 Months",
                    "model_conf": 166.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.95,
                    "duration": "indefinitely",
                    "model_conf": 157.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 163.89,
                    "duration": "2 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.67,
                    "duration": "indefinitely",
                    "model_conf": 85.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.39,
                    "duration": "indefinitely",
                    "model_conf": 54.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.39,
                    "duration": "1 Months",
                    "model_conf": 220.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/franklin/405-franklin-village-dr.html": {
        "name": "Franklin",
        "address": "405 Franklin Village Dr, Franklin, Massachusetts, 02038",
        "phone": "(508) 541-3689",
        "url": "https://locations.massageenvy.com/massachusetts/franklin/405-franklin-village-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "9:00 AM - 4:00 PM"
        },
        "geolocation": [
            42.0894307,
            -71.4248303
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.64,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.34,
                    "duration": "indefinitely",
                    "model_conf": 101.98
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 154.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.64,
                    "duration": "indefinitely",
                    "model_conf": 278.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.8,
                    "duration": "4 Months",
                    "model_conf": 164.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.49,
                    "duration": "indefinitely",
                    "model_conf": 164.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 157.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.6,
                    "duration": "1 Months",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.04,
                    "duration": "indefinitely",
                    "model_conf": 85.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.08,
                    "duration": "indefinitely",
                    "model_conf": 54.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.72,
                    "duration": "indefinitely",
                    "model_conf": 217.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/hyannis/1070-iyannough-road.html": {
        "name": "Hyannis",
        "address": "1070 Iyannough Road Unit 50, Hyannis, Massachusetts, 02601",
        "phone": "(508) 790-3689",
        "url": "https://locations.massageenvy.com/massachusetts/hyannis/1070-iyannough-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.676357,
            -70.304792
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.41,
                    "duration": "indefinitely",
                    "model_conf": 276.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.68,
                    "duration": "indefinitely",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.87,
                    "duration": "indefinitely",
                    "model_conf": 168.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.68,
                    "duration": "indefinitely",
                    "model_conf": 157.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.42,
                    "duration": "1 Months",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 86.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.51,
                    "duration": "indefinitely",
                    "model_conf": 221.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/medford/672-fellsway.html": {
        "name": "Medford",
        "address": "672 Fellsway, Medford Medford, Massachusetts, 02155",
        "phone": "(781) 391-9111",
        "url": "https://locations.massageenvy.com/massachusetts/medford/672-fellsway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.4077054,
            -71.0845669
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.16,
                    "duration": "1 Months",
                    "model_conf": 70.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.79,
                    "duration": "6 Months",
                    "model_conf": 99.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.42,
                    "duration": "1 Months",
                    "model_conf": 153.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.48,
                    "duration": "8 Months",
                    "model_conf": 279.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 161.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.31,
                    "duration": "3 Months",
                    "model_conf": 163.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.98,
                    "duration": "indefinitely",
                    "model_conf": 156.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.16,
                    "duration": "1 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.39,
                    "duration": "indefinitely",
                    "model_conf": 82.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.13,
                    "duration": "indefinitely",
                    "model_conf": 51.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 207.79,
                    "duration": "4 Months",
                    "model_conf": 213.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/methuen/70-pleasant-valley-st..html": {
        "name": "Methuen",
        "address": "70 Pleasant Valley St., Methuen, Massachusetts, 01844",
        "phone": "(978) 685-1077",
        "url": "https://locations.massageenvy.com/massachusetts/methuen/70-pleasant-valley-st..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            42.7424954,
            -71.1666026
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.04,
                    "duration": "indefinitely",
                    "model_conf": 279.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.34,
                    "duration": "4 Months",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.39,
                    "duration": "indefinitely",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.68,
                    "duration": "indefinitely",
                    "model_conf": 156.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.35,
                    "duration": "4 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.21,
                    "duration": "indefinitely",
                    "model_conf": 220.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/north-attleboro/1250-s.-washington-st.html": {
        "name": "North Attleboro",
        "address": "1250 S. Washington St, North Attleboro, Massachusetts, 02760",
        "phone": "(508) 699-3689",
        "url": "https://locations.massageenvy.com/massachusetts/north-attleboro/1250-s.-washington-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.9402077,
            -71.3475414
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.0,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 99.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.84,
                    "duration": "8 Months",
                    "model_conf": 259.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 163.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.28,
                    "duration": "4 Months",
                    "model_conf": 162.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.39,
                    "duration": "indefinitely",
                    "model_conf": 156.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.98,
                    "duration": "2 Months",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.9,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.82,
                    "duration": "indefinitely",
                    "model_conf": 53.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.29,
                    "duration": "3 Months",
                    "model_conf": 201.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/north-billerica/199-boston-rd.html": {
        "name": "Billerica",
        "address": "199 Boston Rd Suite 14, North Billerica, Massachusetts, 01862",
        "phone": "(978) 663-3689",
        "url": "https://locations.massageenvy.com/massachusetts/north-billerica/199-boston-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.5815971,
            -71.28945089999999
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.64,
                    "duration": "11 Months",
                    "model_conf": 279.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.69,
                    "duration": "indefinitely",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.13,
                    "duration": "10 Months",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 202.54,
                    "duration": "indefinitely",
                    "model_conf": 156.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.75,
                    "duration": "3 Months",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.86,
                    "duration": "indefinitely",
                    "model_conf": 85.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.68,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 196.99,
                    "duration": "5 Months",
                    "model_conf": 220.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/north-reading/271-main-street.html": {
        "name": "North Reading",
        "address": "271 Main Street, North Reading North Reading, Massachusetts, 01864",
        "phone": "(978) 207-7222",
        "url": "https://locations.massageenvy.com/massachusetts/north-reading/271-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            42.592978,
            -71.117752
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.57,
                    "duration": "indefinitely",
                    "model_conf": 101.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.55,
                    "duration": "indefinitely",
                    "model_conf": 154.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 278.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.92,
                    "duration": "3 Months",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.27,
                    "duration": "indefinitely",
                    "model_conf": 164.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.47,
                    "duration": "1 Months",
                    "model_conf": 156.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.2,
                    "duration": "4 Months",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.68,
                    "duration": "indefinitely",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 55.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/norwell/10-washington-street.html": {
        "name": "Norwell",
        "address": "10 Washington Street, Norwell, Massachusetts, 02061",
        "phone": "(781) 792-0077",
        "url": "https://locations.massageenvy.com/massachusetts/norwell/10-washington-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.172238,
            -70.883499
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.85,
                    "duration": "2 Months",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.11,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.65,
                    "duration": "1 Months",
                    "model_conf": 98.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.54,
                    "duration": "indefinitely",
                    "model_conf": 154.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.68,
                    "duration": "2 Months",
                    "model_conf": 275.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.45,
                    "duration": "4 Months",
                    "model_conf": 163.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.75,
                    "duration": "1 Months",
                    "model_conf": 143.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.55,
                    "duration": "indefinitely",
                    "model_conf": 156.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.91,
                    "duration": "2 Months",
                    "model_conf": 140.52
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.7,
                    "duration": "indefinitely",
                    "model_conf": 79.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.92,
                    "duration": "indefinitely",
                    "model_conf": 54.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.26,
                    "duration": "1 Months",
                    "model_conf": 220.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/quincy/70-quincy-avenue.html": {
        "name": "Quincy Avenue",
        "address": "70 Quincy Avenue, Quincy Quincy, Massachusetts, 02169",
        "phone": "(617) 479-3689",
        "url": "https://locations.massageenvy.com/massachusetts/quincy/70-quincy-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.244104,
            -70.997356
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.07,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.69,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.83,
                    "duration": "indefinitely",
                    "model_conf": 101.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.21,
                    "duration": "1 Months",
                    "model_conf": 153.77
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.24,
                    "duration": "1 Months",
                    "model_conf": 279.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.53,
                    "duration": "1 Months",
                    "model_conf": 162.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.35,
                    "duration": "3 Months",
                    "model_conf": 165.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.79,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.03,
                    "duration": "2 Months",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.64,
                    "duration": "indefinitely",
                    "model_conf": 83.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.82,
                    "duration": "indefinitely",
                    "model_conf": 54.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.99,
                    "duration": "1 Months",
                    "model_conf": 220.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/saugus/358-broadway.html": {
        "name": "Saugus",
        "address": "358 Broadway, Saugus Saugus, Massachusetts, 01906",
        "phone": "(339) 600-9000",
        "url": "https://locations.massageenvy.com/massachusetts/saugus/358-broadway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.4834902,
            -71.017554
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.29,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.73,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.08,
                    "duration": "indefinitely",
                    "model_conf": 100.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.04,
                    "duration": "1 Months",
                    "model_conf": 154.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.93,
                    "duration": "indefinitely",
                    "model_conf": 278.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.39,
                    "duration": "indefinitely",
                    "model_conf": 165.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.14,
                    "duration": "indefinitely",
                    "model_conf": 165.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.16,
                    "duration": "indefinitely",
                    "model_conf": 156.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.43,
                    "duration": "1 Months",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.39,
                    "duration": "1 Months",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.46,
                    "duration": "2 Months",
                    "model_conf": 54.08
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.83,
                    "duration": "indefinitely",
                    "model_conf": 216.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/shrewsbury/193-boston-turnpike-rd..html": {
        "name": "Lakeway Commons-Shrewsbury",
        "address": "193 Boston Turnpike Rd. Suite 3110, Shrewsbury, Massachusetts, 01545",
        "phone": "(508) 749-5900",
        "url": "https://locations.massageenvy.com/massachusetts/shrewsbury/193-boston-turnpike-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:30 AM - 9:00 PM",
            "TUESDAY": "9:30 AM - 9:00 PM",
            "WEDNESDAY": "9:30 AM - 9:00 PM",
            "THURSDAY": "9:30 AM - 9:00 PM",
            "FRIDAY": "9:30 AM - 9:00 PM",
            "SATURDAY": "9:30 AM - 5:30 PM",
            "SUNDAY": "10:30 AM - 5:30 PM"
        },
        "geolocation": [
            42.2759968,
            -71.7484193
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.18,
                    "duration": "indefinitely",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 102.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.16,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.41,
                    "duration": "indefinitely",
                    "model_conf": 282.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 165.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.84,
                    "duration": "indefinitely",
                    "model_conf": 166.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 157.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.36,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.66,
                    "duration": "2 Months",
                    "model_conf": 55.08
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.15,
                    "duration": "indefinitely",
                    "model_conf": 220.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/west-springfield/935-riverdale-street.html": {
        "name": "West Springfield",
        "address": "935 Riverdale Street #G100, West Springfield West Springfield, Massachusetts, 01089",
        "phone": "(413) 736-3689",
        "url": "https://locations.massageenvy.com/massachusetts/west-springfield/935-riverdale-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            42.129254,
            -72.621865
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.59,
                    "duration": "4 Months",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 101.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.67,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 278.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 165.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.84,
                    "duration": "indefinitely",
                    "model_conf": 168.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 157.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.99,
                    "duration": "indefinitely",
                    "model_conf": 220.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/massachusetts/westborough/18-lyman-street.html": {
        "name": "Westborough",
        "address": "18 Lyman Street, Westborough, Massachusetts, 01581",
        "phone": "(508) 836-3689",
        "url": "https://locations.massageenvy.com/massachusetts/westborough/18-lyman-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.28149400447697,
            -71.60915001692041
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.88,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.9,
                    "duration": "indefinitely",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.19,
                    "duration": "indefinitely",
                    "model_conf": 102.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.58,
                    "duration": "indefinitely",
                    "model_conf": 154.9
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.7,
                    "duration": "indefinitely",
                    "model_conf": 282.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.37,
                    "duration": "1 Months",
                    "model_conf": 165.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.74,
                    "duration": "indefinitely",
                    "model_conf": 164.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.83,
                    "duration": "indefinitely",
                    "model_conf": 157.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.64,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.45,
                    "duration": "indefinitely",
                    "model_conf": 85.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.27,
                    "duration": "indefinitely",
                    "model_conf": 54.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 212.28,
                    "duration": "indefinitely",
                    "model_conf": 221.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/ann-arbor/3143-ann-arbor-saline-road.html": {
        "name": "Ann Arbor",
        "address": "3143 Ann Arbor Saline Road Suite C, Ann Arbor, Michigan, 48103",
        "phone": "(734) 373-5258",
        "url": "https://locations.massageenvy.com/michigan/ann-arbor/3143-ann-arbor-saline-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.24110827748879,
            -83.76830562698366
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/michigan/birmingham/34256-woodward-ave..html": {
        "name": "Birmingham",
        "address": "34256 Woodward Ave., Birmingham, Michigan, 48009",
        "phone": "(248) 593-9903",
        "url": "https://locations.massageenvy.com/michigan/birmingham/34256-woodward-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.5412916,
            -83.2075862
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 96.625,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.96,
                    "duration": "indefinitely",
                    "model_conf": 70.13
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 141.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.59,
                    "duration": "1 Months",
                    "model_conf": 139.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.29,
                    "duration": "indefinitely",
                    "model_conf": 82.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.11,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/canton/42791-ford-road.html": {
        "name": "Canton - MI",
        "address": "42791 Ford Road, Canton, Michigan, 48187",
        "phone": "(734) 928-2133",
        "url": "https://locations.massageenvy.com/michigan/canton/42791-ford-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.32143,
            -83.4628473
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 153.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.5,
                    "duration": "indefinitely",
                    "model_conf": 100.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 154.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/farmington-hills/30985-orchard-lake-road.html": {
        "name": "West Bloomfield",
        "address": "30985 Orchard Lake Road Suite B-103, Farmington Hills, Michigan, 48334",
        "phone": "(248) 851-3689",
        "url": "https://locations.massageenvy.com/michigan/farmington-hills/30985-orchard-lake-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.52458236804305,
            -83.36191433739344
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 70.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 145.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.39,
                    "duration": "1 Months",
                    "model_conf": 139.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.07,
                    "duration": "indefinitely",
                    "model_conf": 91.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 148.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/grandville/4530-ivanrest-ave..html": {
        "name": "Grandville",
        "address": "4530 Ivanrest Ave., Grandville, Michigan, 49418",
        "phone": "(616) 530-9500",
        "url": "https://locations.massageenvy.com/michigan/grandville/4530-ivanrest-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.88244119721369,
            -85.74264523388592
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.775,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": null,
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": null,
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "90min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": null,
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.26,
                    "duration": "3 Months",
                    "model_conf": 154.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/northville/17909-haggerty-road.html": {
        "name": "Northville",
        "address": "17909 Haggerty Road, Northville, Michigan, 48167",
        "phone": "(248) 662-0396",
        "url": "https://locations.massageenvy.com/michigan/northville/17909-haggerty-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.41524194063138,
            -83.4346770932541
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.46,
                    "duration": "1 Months",
                    "model_conf": 70.51
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 153.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.8,
                    "duration": "indefinitely",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.42,
                    "duration": "3 Months",
                    "model_conf": 90.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 151.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/rochester-hills/3050-walton-blvd.html": {
        "name": "Rochester Hills",
        "address": "3050 Walton Blvd, Rochester Hills, Michigan, 48309",
        "phone": "(248) 652-2368",
        "url": "https://locations.massageenvy.com/michigan/rochester-hills/3050-walton-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.68283689241027,
            -83.19556297488613
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.45,
                    "duration": "1 Months",
                    "model_conf": 155.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 99.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 152.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/troy/835-e-big-beaver-rd.html": {
        "name": "Troy",
        "address": "835 E Big Beaver Rd, Troy, Michigan, 48083",
        "phone": "(248) 528-3689",
        "url": "https://locations.massageenvy.com/michigan/troy/835-e-big-beaver-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.564188,
            -83.131143
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 96.625,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.6,
                    "duration": "1 Months",
                    "model_conf": 70.87
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 151.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.07,
                    "duration": "3 Months",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.64,
                    "duration": "2 Months",
                    "model_conf": 92.58
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 148.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/michigan/woodhaven/18768-west-road.html": {
        "name": "Woodhaven",
        "address": "18768 West Road, Woodhaven Woodhaven, Michigan, 48183",
        "phone": "(734) 676-6500",
        "url": "https://locations.massageenvy.com/michigan/woodhaven/18768-west-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.141184012459654,
            -83.22179362177849
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.13,
                    "duration": "5 Months",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 159.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.11,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 102.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 155.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/blaine/10450-baltimore-street-ne.html": {
        "name": "Blaine",
        "address": "10450 Baltimore Street NE, Blaine, Minnesota, 55449",
        "phone": "(763) 780-3689",
        "url": "https://locations.massageenvy.com/minnesota/blaine/10450-baltimore-street-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.160445,
            -93.233556
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.35,
                    "duration": "4 Months",
                    "model_conf": 277.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.87,
                    "duration": "3 Months",
                    "model_conf": 190.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.02,
                    "duration": "2 Months",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.62,
                    "duration": "indefinitely",
                    "model_conf": 166.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.55,
                    "duration": "indefinitely",
                    "model_conf": 155.24
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.68,
                    "duration": "3 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.49,
                    "duration": "1 Months",
                    "model_conf": 233.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.15,
                    "duration": "indefinitely",
                    "model_conf": 84.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.36,
                    "duration": "indefinitely",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.41,
                    "duration": "indefinitely",
                    "model_conf": 218.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/bloomington/7949-southtown-center.html": {
        "name": "Bloomington - Southtown",
        "address": "7949 Southtown Center, Bloomington, Minnesota, 55431",
        "phone": "(952) 463-1000",
        "url": "https://locations.massageenvy.com/minnesota/bloomington/7949-southtown-center.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.8598077,
            -93.3039773
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.86,
                    "duration": "2 Months",
                    "model_conf": 245.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.19,
                    "duration": "2 Months",
                    "model_conf": 169.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.27,
                    "duration": "2 Months",
                    "model_conf": 151.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.19,
                    "duration": "indefinitely",
                    "model_conf": 143.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.23,
                    "duration": "1 Months",
                    "model_conf": 139.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.99,
                    "duration": "indefinitely",
                    "model_conf": 139.85
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.9,
                    "duration": "indefinitely",
                    "model_conf": 201.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.78,
                    "duration": "indefinitely",
                    "model_conf": 85.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.69,
                    "duration": "indefinitely",
                    "model_conf": 53.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.13,
                    "duration": "indefinitely",
                    "model_conf": 186.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/coon-rapids/12580-riverdale-boulevard.html": {
        "name": "Coon Rapids",
        "address": "12580 Riverdale Boulevard Suite 103, Coon Rapids, Minnesota, 55448",
        "phone": "(763) 235-9000",
        "url": "https://locations.massageenvy.com/minnesota/coon-rapids/12580-riverdale-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            45.199168,
            -93.352659
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 276.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.13,
                    "duration": "2 Months",
                    "model_conf": 188.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.65,
                    "duration": "3 Months",
                    "model_conf": 164.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.51,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.93,
                    "duration": "indefinitely",
                    "model_conf": 154.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.69,
                    "duration": "3 Months",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.06,
                    "duration": "indefinitely",
                    "model_conf": 233.24
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.54,
                    "duration": "1 Months",
                    "model_conf": 83.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.87,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.88,
                    "duration": "indefinitely",
                    "model_conf": 219.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/eagan/3360-central-park-village-dr..html": {
        "name": "Eagan",
        "address": "3360 Central Park Village Dr. Suite 100, Eagan, Minnesota, 55121",
        "phone": "(651) 600-3550",
        "url": "https://locations.massageenvy.com/minnesota/eagan/3360-central-park-village-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.8360541,
            -93.1696056
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.98,
                    "duration": "5 Months",
                    "model_conf": 265.19
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.89,
                    "duration": "3 Months",
                    "model_conf": 188.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 159.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 154.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 151.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/eden-prairie/11995-singletree-lane.html": {
        "name": "Eden Prairie",
        "address": "11995 Singletree Lane, Eden Prairie, Minnesota, 55344",
        "phone": "(952) 641-1200",
        "url": "https://locations.massageenvy.com/minnesota/eden-prairie/11995-singletree-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.8560673,
            -93.4286044
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.44,
                    "duration": "indefinitely",
                    "model_conf": 261.35
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.68,
                    "duration": "indefinitely",
                    "model_conf": 180.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 157.23
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.32,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.51,
                    "duration": "indefinitely",
                    "model_conf": 146.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/edina/7101-france-ave-south.html": {
        "name": "Edina",
        "address": "7101 France Ave South, Edina, Minnesota, 55435",
        "phone": "(952) 955-8228",
        "url": "https://locations.massageenvy.com/minnesota/edina/7101-france-ave-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 10:00 PM"
        },
        "geolocation": [
            44.87446,
            -93.328113
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.79,
                    "duration": "indefinitely",
                    "model_conf": 67.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.54,
                    "duration": "2 Months",
                    "model_conf": 78.66
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.42,
                    "duration": "indefinitely",
                    "model_conf": 152.17
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.31,
                    "duration": "4 Months",
                    "model_conf": 241.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.57,
                    "duration": "4 Months",
                    "model_conf": 149.78
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.41,
                    "duration": "indefinitely",
                    "model_conf": 139.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.51,
                    "duration": "1 Months",
                    "model_conf": 136.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.9,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 85.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.95,
                    "duration": "indefinitely",
                    "model_conf": 53.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.96,
                    "duration": "indefinitely",
                    "model_conf": 181.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/golden-valley/7704-olsen-memorial-highway.html": {
        "name": "Golden Valley",
        "address": "7704 Olsen Memorial Highway, Golden Valley, Minnesota, 55427",
        "phone": "(763) 543-1000",
        "url": "https://locations.massageenvy.com/minnesota/golden-valley/7704-olsen-memorial-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.984462,
            -93.3777565
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 68.62
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.86,
                    "duration": "indefinitely",
                    "model_conf": 148.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.32,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 87.23
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.73,
                    "duration": "indefinitely",
                    "model_conf": 153.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.53,
                    "duration": "indefinitely",
                    "model_conf": 251.5
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.39,
                    "duration": "indefinitely",
                    "model_conf": 174.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 156.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.64,
                    "duration": "2 Months",
                    "model_conf": 151.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.7,
                    "duration": "indefinitely",
                    "model_conf": 142.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.43,
                    "duration": "indefinitely",
                    "model_conf": 140.55
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.36,
                    "duration": "indefinitely",
                    "model_conf": 203.62
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.97,
                    "duration": "indefinitely",
                    "model_conf": 84.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.16,
                    "duration": "indefinitely",
                    "model_conf": 53.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.24,
                    "duration": "indefinitely",
                    "model_conf": 196.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/maple-grove/8091-wedgewood-lane-north.html": {
        "name": "Maple Grove",
        "address": "8091 Wedgewood Lane North, Maple Grove, Minnesota, 55369",
        "phone": "(763) 425-1777",
        "url": "https://locations.massageenvy.com/minnesota/maple-grove/8091-wedgewood-lane-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.1014642,
            -93.4504812
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.63,
                    "duration": "3 Months",
                    "model_conf": 274.5
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.07,
                    "duration": "2 Months",
                    "model_conf": 189.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.31,
                    "duration": "indefinitely",
                    "model_conf": 163.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.4,
                    "duration": "3 Months",
                    "model_conf": 165.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.32,
                    "duration": "indefinitely",
                    "model_conf": 154.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.04,
                    "duration": "2 Months",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.75,
                    "duration": "3 Months",
                    "model_conf": 227.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 84.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 54.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.81,
                    "duration": "2 Months",
                    "model_conf": 216.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/plymouth/3225-vicksburg-lane-north.html": {
        "name": "Plymouth",
        "address": "3225 Vicksburg Lane North Suite D, Plymouth, Minnesota, 55447",
        "phone": "(763) 550-1600",
        "url": "https://locations.massageenvy.com/minnesota/plymouth/3225-vicksburg-lane-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 10:00 PM"
        },
        "geolocation": [
            45.018284480118865,
            -93.48348408937454
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.25,
                    "duration": "indefinitely",
                    "model_conf": 70.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.82,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.29,
                    "duration": "4 Months",
                    "model_conf": 96.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.53,
                    "duration": "1 Months",
                    "model_conf": 153.88
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.88,
                    "duration": "3 Months",
                    "model_conf": 271.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.4,
                    "duration": "indefinitely",
                    "model_conf": 161.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.76,
                    "duration": "8 Months",
                    "model_conf": 162.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.59,
                    "duration": "indefinitely",
                    "model_conf": 151.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.61,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.73,
                    "duration": "indefinitely",
                    "model_conf": 84.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.69,
                    "duration": "indefinitely",
                    "model_conf": 54.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.19,
                    "duration": "1 Months",
                    "model_conf": 212.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/rochester/2550-south-broadway.html": {
        "name": "Rochester",
        "address": "2550 South Broadway, Rochester, Minnesota, 55904",
        "phone": "(507) 289-3689",
        "url": "https://locations.massageenvy.com/minnesota/rochester/2550-south-broadway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.983703,
            -92.461451
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 282.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.28,
                    "duration": "2 Months",
                    "model_conf": 194.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.78,
                    "duration": "1 Months",
                    "model_conf": 168.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.84,
                    "duration": "indefinitely",
                    "model_conf": 157.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/rogers/14165-james-road.html": {
        "name": "Rogers",
        "address": "14165 James Road, Rogers, Minnesota, 55374",
        "phone": "(763) 428-3605",
        "url": "https://locations.massageenvy.com/minnesota/rogers/14165-james-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.21164681479161,
            -93.5509056571209
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 282.23
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 193.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 165.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.78,
                    "duration": "1 Months",
                    "model_conf": 168.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 157.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/roseville/2480-fairview-ave.html": {
        "name": "Roseville-MN",
        "address": "2480 Fairview Ave Ste 120, Roseville, Minnesota, 55113",
        "phone": "(651) 636-4020",
        "url": "https://locations.massageenvy.com/minnesota/roseville/2480-fairview-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.01656081878808,
            -93.17418159729571
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.89,
                    "duration": "indefinitely",
                    "model_conf": 253.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.35,
                    "duration": "indefinitely",
                    "model_conf": 176.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 156.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 152.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.28,
                    "duration": "indefinitely",
                    "model_conf": 141.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.65,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.72,
                    "duration": "indefinitely",
                    "model_conf": 210.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.04,
                    "duration": "indefinitely",
                    "model_conf": 84.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.63,
                    "duration": "indefinitely",
                    "model_conf": 53.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.89,
                    "duration": "indefinitely",
                    "model_conf": 198.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/saint-cloud/2714-w-division-street.html": {
        "name": "St Cloud",
        "address": "2714 W Division Street, Saint Cloud, Minnesota, 56301",
        "phone": "(320) 252-4400",
        "url": "https://locations.massageenvy.com/minnesota/saint-cloud/2714-w-division-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.552864,
            -94.190909
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 70.65
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.1,
                    "duration": "2 Months",
                    "model_conf": 159.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 100.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.22,
                    "duration": "4 Months",
                    "model_conf": 282.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.37,
                    "duration": "1 Months",
                    "model_conf": 194.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.94,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 166.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.4,
                    "duration": "indefinitely",
                    "model_conf": 157.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.1,
                    "duration": "2 Months",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.57,
                    "duration": "4 Months",
                    "model_conf": 238.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 84.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.37,
                    "duration": "3 Months",
                    "model_conf": 221.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/saint-paul/2140-ford-parkway.html": {
        "name": "Highland Village",
        "address": "2140 Ford Parkway, Saint Paul, Minnesota, 55116",
        "phone": "(651) 699-4522",
        "url": "https://locations.massageenvy.com/minnesota/saint-paul/2140-ford-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.917139,
            -93.1910352
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.09,
                    "duration": "2 Months",
                    "model_conf": 263.51
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 182.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.09,
                    "duration": "indefinitely",
                    "model_conf": 160.07
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.43,
                    "duration": "3 Months",
                    "model_conf": 156.27
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.19,
                    "duration": "indefinitely",
                    "model_conf": 148.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/savage/14119-highway-13-s.html": {
        "name": "Savage",
        "address": "14119 Highway 13 S, Savage, Minnesota, 55378",
        "phone": "(952) 440-4411",
        "url": "https://locations.massageenvy.com/minnesota/savage/14119-highway-13-s.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.7476715,
            -93.3779417
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 277.88
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 191.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.17,
                    "duration": "indefinitely",
                    "model_conf": 164.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.74,
                    "duration": "1 Months",
                    "model_conf": 165.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.27,
                    "duration": "indefinitely",
                    "model_conf": 155.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/stillwater/5901-norwich-avenue-north.html": {
        "name": "Stillwater",
        "address": "5901 Norwich Avenue North, Stillwater, Minnesota, 55082",
        "phone": "(651) 430-0300",
        "url": "https://locations.massageenvy.com/minnesota/stillwater/5901-norwich-avenue-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.03447215946016,
            -92.82937109470367
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.52,
                    "duration": "1 Months",
                    "model_conf": 71.29
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 158.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.56,
                    "duration": "1 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 98.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.97,
                    "duration": "4 Months",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 281.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 194.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.76,
                    "duration": "7 Months",
                    "model_conf": 161.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.17,
                    "duration": "1 Months",
                    "model_conf": 155.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.04,
                    "duration": "2 Months",
                    "model_conf": 233.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.97,
                    "duration": "3 Months",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 55.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.49,
                    "duration": "3 Months",
                    "model_conf": 220.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/vadnais-heights/925-co-rd-e-east.html": {
        "name": "Vadnais Heights",
        "address": "925 Co Rd E East Suite 160, Vadnais Heights, Minnesota, 55127",
        "phone": "(651) 203-1015",
        "url": "https://locations.massageenvy.com/minnesota/vadnais-heights/925-co-rd-e-east.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            45.05214758780487,
            -93.06319564985837
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.33,
                    "duration": "indefinitely",
                    "model_conf": 70.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.04,
                    "duration": "2 Months",
                    "model_conf": 154.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.98,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 94.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.08,
                    "duration": "1 Months",
                    "model_conf": 153.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.44,
                    "duration": "1 Months",
                    "model_conf": 266.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.73,
                    "duration": "indefinitely",
                    "model_conf": 187.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.51,
                    "duration": "indefinitely",
                    "model_conf": 162.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.77,
                    "duration": "7 Months",
                    "model_conf": 161.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.2,
                    "duration": "indefinitely",
                    "model_conf": 145.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.88,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.93,
                    "duration": "3 Months",
                    "model_conf": 227.25
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.54,
                    "duration": "indefinitely",
                    "model_conf": 54.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.02,
                    "duration": "2 Months",
                    "model_conf": 209.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/woodbury/572-commons-drive.html": {
        "name": "Woodbury",
        "address": "572 Commons Drive Suite 400, Woodbury, Minnesota, 55125",
        "phone": "(651) 203-9988",
        "url": "https://locations.massageenvy.com/minnesota/woodbury/572-commons-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.939976,
            -92.93294
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.36,
                    "duration": "indefinitely",
                    "model_conf": 271.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.94,
                    "duration": "indefinitely",
                    "model_conf": 190.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.82,
                    "duration": "indefinitely",
                    "model_conf": 163.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.6,
                    "duration": "2 Months",
                    "model_conf": 165.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.8,
                    "duration": "2 Months",
                    "model_conf": 147.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.46,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.88,
                    "duration": "indefinitely",
                    "model_conf": 224.56
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.78,
                    "duration": "indefinitely",
                    "model_conf": 82.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 54.17
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.03,
                    "duration": "indefinitely",
                    "model_conf": 214.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/d-iberville/3920-promenade-parkway.html": {
        "name": "D'Iberville",
        "address": "3920 Promenade Parkway Suite H, D'Iberville, Mississippi, 39540",
        "phone": "(228) 396-1900",
        "url": "https://locations.massageenvy.com/mississippi/d-iberville/3920-promenade-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.45746652861258,
            -88.90264003325547
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.25,
                    "duration": "2 Months",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.94,
                    "duration": "3 Months",
                    "model_conf": 158.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.14,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.02,
                    "duration": "6 Months",
                    "model_conf": 99.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.24,
                    "duration": "1 Months",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.55,
                    "duration": "7 Months",
                    "model_conf": 283.28
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.92,
                    "duration": "6 Months",
                    "model_conf": 194.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.42,
                    "duration": "10 Months",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.27,
                    "duration": "indefinitely",
                    "model_conf": 157.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.83,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.08,
                    "duration": "6 Months",
                    "model_conf": 236.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 85.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.39,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.17,
                    "duration": "3 Months",
                    "model_conf": 221.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/flowood/149-market-street.html": {
        "name": "Flowood",
        "address": "149 Market Street, Flowood Flowood, Mississippi, 39232",
        "phone": "(601) 709-3689",
        "url": "https://locations.massageenvy.com/mississippi/flowood/149-market-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.3369312,
            -90.0666945
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.75,
                    "duration": "indefinitely",
                    "model_conf": 70.71
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.23,
                    "duration": "indefinitely",
                    "model_conf": 152.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.1,
                    "duration": "2 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.03,
                    "duration": "indefinitely",
                    "model_conf": 100.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.98,
                    "duration": "indefinitely",
                    "model_conf": 153.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.97,
                    "duration": "indefinitely",
                    "model_conf": 281.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.76,
                    "duration": "indefinitely",
                    "model_conf": 193.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.51,
                    "duration": "1 Months",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.33,
                    "duration": "indefinitely",
                    "model_conf": 166.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.29,
                    "duration": "1 Months",
                    "model_conf": 157.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.18,
                    "duration": "2 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.46,
                    "duration": "indefinitely",
                    "model_conf": 225.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.35,
                    "duration": "2 Months",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.76,
                    "duration": "1 Months",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.72,
                    "duration": "indefinitely",
                    "model_conf": 221.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/gulfport/15256-crossroads-pkwy.html": {
        "name": "Gulfport",
        "address": "15256 Crossroads Pkwy, Gulfport Gulfport, Mississippi, 39503",
        "phone": "(228) 539-3393",
        "url": "https://locations.massageenvy.com/mississippi/gulfport/15256-crossroads-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.4377314,
            -89.0942864
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.26,
                    "duration": "2 Months",
                    "model_conf": 71.29
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.94,
                    "duration": "3 Months",
                    "model_conf": 158.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.14,
                    "duration": "indefinitely",
                    "model_conf": 139.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.02,
                    "duration": "6 Months",
                    "model_conf": 99.75
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.24,
                    "duration": "1 Months",
                    "model_conf": 154.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.55,
                    "duration": "7 Months",
                    "model_conf": 283.27
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.93,
                    "duration": "6 Months",
                    "model_conf": 194.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 165.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.42,
                    "duration": "10 Months",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.27,
                    "duration": "indefinitely",
                    "model_conf": 157.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.84,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.08,
                    "duration": "6 Months",
                    "model_conf": 236.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.39,
                    "duration": "indefinitely",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.17,
                    "duration": "3 Months",
                    "model_conf": 221.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/hattiesburg/6169-us-highway-98.html": {
        "name": "Hattiesburg",
        "address": "6169 US Highway 98 Ste 40, Hattiesburg, Mississippi, 39402",
        "phone": "(601) 261-1116",
        "url": "https://locations.massageenvy.com/mississippi/hattiesburg/6169-us-highway-98.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.32177907691482,
            -89.38800670385979
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.11,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.34,
                    "duration": "2 Months",
                    "model_conf": 159.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.28,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.61,
                    "duration": "2 Months",
                    "model_conf": 100.23
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.53,
                    "duration": "1 Months",
                    "model_conf": 154.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.5,
                    "duration": "4 Months",
                    "model_conf": 283.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.23,
                    "duration": "2 Months",
                    "model_conf": 195.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.2,
                    "duration": "indefinitely",
                    "model_conf": 166.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.23,
                    "duration": "4 Months",
                    "model_conf": 168.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.22,
                    "duration": "indefinitely",
                    "model_conf": 157.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.81,
                    "duration": "indefinitely",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.77,
                    "duration": "3 Months",
                    "model_conf": 236.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.35,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.56,
                    "duration": "3 Months",
                    "model_conf": 219.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/jackson/1220-northside-drive.html": {
        "name": "Maywood",
        "address": "1220 Northside Drive, Jackson Jackson, Mississippi, 39211",
        "phone": "(601) 709-4300",
        "url": "https://locations.massageenvy.com/mississippi/jackson/1220-northside-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.356475908187136,
            -90.14990314766021
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.87,
                    "duration": "indefinitely",
                    "model_conf": 70.54
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.45,
                    "duration": "indefinitely",
                    "model_conf": 149.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.15,
                    "duration": "2 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.13,
                    "duration": "indefinitely",
                    "model_conf": 100.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.22,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.94,
                    "duration": "indefinitely",
                    "model_conf": 281.9
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.77,
                    "duration": "indefinitely",
                    "model_conf": 194.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.52,
                    "duration": "1 Months",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.47,
                    "duration": "indefinitely",
                    "model_conf": 165.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.47,
                    "duration": "1 Months",
                    "model_conf": 157.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.27,
                    "duration": "2 Months",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.51,
                    "duration": "indefinitely",
                    "model_conf": 221.27
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.45,
                    "duration": "1 Months",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.87,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.79,
                    "duration": "indefinitely",
                    "model_conf": 221.18
                }
            }
        }
    },
    "https://locations.massageenvy.com/mississippi/madison/111-colony-crossing-way.html": {
        "name": "Madison",
        "address": "111 Colony Crossing Way Suite 280, Madison Madison, Mississippi, 39110",
        "phone": "(601) 856-8686",
        "url": "https://locations.massageenvy.com/mississippi/madison/111-colony-crossing-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.476090914907495,
            -90.1426428119538
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.11,
                    "duration": "indefinitely",
                    "model_conf": 70.58
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.4,
                    "duration": "indefinitely",
                    "model_conf": 150.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.29,
                    "duration": "2 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 100.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.32,
                    "duration": "indefinitely",
                    "model_conf": 152.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.37,
                    "duration": "indefinitely",
                    "model_conf": 281.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.37,
                    "duration": "indefinitely",
                    "model_conf": 194.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.6,
                    "duration": "indefinitely",
                    "model_conf": 165.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.91,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.79,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 222.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.49,
                    "duration": "3 Months",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.47,
                    "duration": "indefinitely",
                    "model_conf": 221.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/chesterfield/1694-clarkson-road.html": {
        "name": "Chesterfield",
        "address": "1694 Clarkson Road, Chesterfield, Missouri, 63017",
        "phone": "(636) 532-7733",
        "url": "https://locations.massageenvy.com/missouri/chesterfield/1694-clarkson-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.643038,
            -90.565445
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.9,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.76,
                    "duration": "indefinitely",
                    "model_conf": 238.45
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 164.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.87,
                    "duration": "3 Months",
                    "model_conf": 148.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.63,
                    "duration": "indefinitely",
                    "model_conf": 138.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 138.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.74,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 195.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.94,
                    "duration": "indefinitely",
                    "model_conf": 80.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.87,
                    "duration": "indefinitely",
                    "model_conf": 50.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.16,
                    "duration": "indefinitely",
                    "model_conf": 187.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/clayton/8251-maryland-ave.html": {
        "name": "Clayton",
        "address": "8251 Maryland Ave, Clayton, Missouri, 63105",
        "phone": "(314) 333-3368",
        "url": "https://locations.massageenvy.com/missouri/clayton/8251-maryland-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.6528672,
            -90.3450259
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.14,
                    "duration": "2 Months",
                    "model_conf": 258.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.06,
                    "duration": "indefinitely",
                    "model_conf": 154.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.32,
                    "duration": "4 Months",
                    "model_conf": 151.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.88,
                    "duration": "indefinitely",
                    "model_conf": 145.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.62,
                    "duration": "1 Months",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.55,
                    "duration": "indefinitely",
                    "model_conf": 82.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.25,
                    "duration": "indefinitely",
                    "model_conf": 52.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 201.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/columbia/2703-e-broadway.html": {
        "name": "Columbia",
        "address": "2703 E Broadway Suite 131, Columbia, Missouri, 65201",
        "phone": "(573) 446-3689",
        "url": "https://locations.massageenvy.com/missouri/columbia/2703-e-broadway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.947273776859284,
            -92.29691243531343
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 283.27
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 195.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.9,
                    "duration": "3 Months",
                    "model_conf": 167.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 168.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.23,
                    "duration": "3 Months",
                    "model_conf": 157.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/cottleville/6085-mid-rivers-mall-dr.html": {
        "name": "Cottleville",
        "address": "6085 Mid Rivers Mall Dr, Cottleville, Missouri, 63304",
        "phone": "(636) 442-5999",
        "url": "https://locations.massageenvy.com/missouri/cottleville/6085-mid-rivers-mall-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7447594,
            -90.6367811
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.91,
                    "duration": "2 Months",
                    "model_conf": 261.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.58,
                    "duration": "1 Months",
                    "model_conf": 120.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.82,
                    "duration": "1 Months",
                    "model_conf": 137.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.75,
                    "duration": "indefinitely",
                    "model_conf": 135.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.13,
                    "duration": "2 Months",
                    "model_conf": 140.02
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.84,
                    "duration": "indefinitely",
                    "model_conf": 82.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.06,
                    "duration": "indefinitely",
                    "model_conf": 49.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 184.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/creve-coeur/12528-olive-blvd.html": {
        "name": "Creve Coeur",
        "address": "12528 Olive Blvd, Creve Coeur, Missouri, 63141",
        "phone": "(314) 744-7779",
        "url": "https://locations.massageenvy.com/missouri/creve-coeur/12528-olive-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.675267,
            -90.46387577546312
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.79,
                    "duration": "indefinitely",
                    "model_conf": 246.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.0,
                    "duration": "3 Months",
                    "model_conf": 150.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.62,
                    "duration": "indefinitely",
                    "model_conf": 144.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 140.27
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.44,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.18,
                    "duration": "indefinitely",
                    "model_conf": 80.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.28,
                    "duration": "indefinitely",
                    "model_conf": 51.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.26,
                    "duration": "indefinitely",
                    "model_conf": 190.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/des-peres/12123-manchester-rd.html": {
        "name": "Des Peres",
        "address": "12123 Manchester Rd, Des Peres, Missouri, 63131",
        "phone": "(314) 965-7779",
        "url": "https://locations.massageenvy.com/missouri/des-peres/12123-manchester-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 4:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.6036326,
            -90.4376538
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.02,
                    "duration": "2 Months",
                    "model_conf": 236.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.56,
                    "duration": "indefinitely",
                    "model_conf": 140.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.01,
                    "duration": "7 Months",
                    "model_conf": 133.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 131.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.23,
                    "duration": "indefinitely",
                    "model_conf": 79.2
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.17,
                    "duration": "indefinitely",
                    "model_conf": 49.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 176.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/kansas-city/6240-n-chatham-ave.html": {
        "name": "Tom Watson Parkway",
        "address": "6240 N Chatham Ave, Kansas City, Missouri, 64151",
        "phone": "(816) 505-3689",
        "url": "https://locations.massageenvy.com/missouri/kansas-city/6240-n-chatham-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.2089863,
            -94.6451155
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.21,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.89,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.1,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.91,
                    "duration": "indefinitely",
                    "model_conf": 101.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.95,
                    "duration": "indefinitely",
                    "model_conf": 154.1
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.75,
                    "duration": "3 Months",
                    "model_conf": 272.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.58,
                    "duration": "1 Months",
                    "model_conf": 190.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.98,
                    "duration": "indefinitely",
                    "model_conf": 165.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.4,
                    "duration": "4 Months",
                    "model_conf": 167.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.98,
                    "duration": "indefinitely",
                    "model_conf": 155.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.69,
                    "duration": "indefinitely",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.04,
                    "duration": "3 Months",
                    "model_conf": 222.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.6,
                    "duration": "indefinitely",
                    "model_conf": 85.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.43,
                    "duration": "indefinitely",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.04,
                    "duration": "2 Months",
                    "model_conf": 214.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/lee-s-summit/1748-nw-chipman-rd.html": {
        "name": "Lees Summit",
        "address": "1748 NW Chipman Rd, Lee's Summit, Missouri, 64081",
        "phone": "(816) 524-5118",
        "url": "https://locations.massageenvy.com/missouri/lee-s-summit/1748-nw-chipman-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:45 AM - 9:15 PM",
            "TUESDAY": "8:45 AM - 9:15 PM",
            "WEDNESDAY": "8:45 AM - 9:15 PM",
            "THURSDAY": "8:45 AM - 9:15 PM",
            "FRIDAY": "8:45 AM - 9:15 PM",
            "SATURDAY": "8:45 AM - 8:15 PM",
            "SUNDAY": "8:45 AM - 6:15 PM"
        },
        "geolocation": [
            38.9282797,
            -94.4086497
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.54,
                    "duration": "1 Months",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.9,
                    "duration": "indefinitely",
                    "model_conf": 154.9
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.48,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 100.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.1,
                    "duration": "1 Months",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.84,
                    "duration": "indefinitely",
                    "model_conf": 280.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.53,
                    "duration": "1 Months",
                    "model_conf": 194.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 165.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.56,
                    "duration": "2 Months",
                    "model_conf": 167.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.62,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.49,
                    "duration": "1 Months",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.63,
                    "duration": "1 Months",
                    "model_conf": 230.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.63,
                    "duration": "1 Months",
                    "model_conf": 85.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.17,
                    "duration": "1 Months",
                    "model_conf": 220.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/manchester/14523a-manchester-rd.html": {
        "name": "Manchester",
        "address": "14523A Manchester Rd, Manchester, Missouri, 63011",
        "phone": "(636) 227-7711",
        "url": "https://locations.massageenvy.com/missouri/manchester/14523a-manchester-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.59376,
            -90.522135
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.66,
                    "duration": "indefinitely",
                    "model_conf": 227.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.17,
                    "duration": "2 Months",
                    "model_conf": 144.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.55,
                    "duration": "indefinitely",
                    "model_conf": 132.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 133.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.38,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.74,
                    "duration": "indefinitely",
                    "model_conf": 78.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.0,
                    "duration": "indefinitely",
                    "model_conf": 49.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.23,
                    "duration": "indefinitely",
                    "model_conf": 178.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/o-fallon/3062-winghaven-blvd..html": {
        "name": "O'Fallon",
        "address": "3062 Winghaven Blvd., O'Fallon, Missouri, 63368",
        "phone": "(636) 300-9988",
        "url": "https://locations.massageenvy.com/missouri/o-fallon/3062-winghaven-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.75031812812309,
            -90.74781328439713
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 279.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.04,
                    "duration": "3 Months",
                    "model_conf": 161.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.16,
                    "duration": "indefinitely",
                    "model_conf": 163.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.78,
                    "duration": "2 Months",
                    "model_conf": 153.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.04,
                    "duration": "2 Months",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.29,
                    "duration": "2 Months",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.52,
                    "duration": "3 Months",
                    "model_conf": 54.96
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.51,
                    "duration": "indefinitely",
                    "model_conf": 217.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/oakville/5656-telegraph-road.html": {
        "name": "Oakville",
        "address": "5656 Telegraph Road, Oakville, Missouri, 63129",
        "phone": "(314) 375-1100",
        "url": "https://locations.massageenvy.com/missouri/oakville/5656-telegraph-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.466481084877046,
            -90.3031569421455
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.35,
                    "duration": "4 Months",
                    "model_conf": 280.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.79,
                    "duration": "indefinitely",
                    "model_conf": 165.78
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.16,
                    "duration": "5 Months",
                    "model_conf": 162.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 156.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.63,
                    "duration": "indefinitely",
                    "model_conf": 85.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.31,
                    "duration": "indefinitely",
                    "model_conf": 55.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.93,
                    "duration": "2 Months",
                    "model_conf": 220.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/saint-louis/1740-s-brentwood.html": {
        "name": "Brentwood/Maplewood",
        "address": "1740 S Brentwood, Saint Louis Maplewood, Missouri, 63144",
        "phone": "(314) 646-8484",
        "url": "https://locations.massageenvy.com/missouri/saint-louis/1740-s-brentwood.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.62619290894573,
            -90.34624156147305
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.31,
                    "duration": "4 Months",
                    "model_conf": 246.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.66,
                    "duration": "1 Months",
                    "model_conf": 149.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.26,
                    "duration": "indefinitely",
                    "model_conf": 144.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 140.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.32,
                    "duration": "indefinitely",
                    "model_conf": 80.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.74,
                    "duration": "indefinitely",
                    "model_conf": 50.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.56,
                    "duration": "indefinitely",
                    "model_conf": 193.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/springfield/2155-west-republic-road.html": {
        "name": "Springfield",
        "address": "2155 West Republic Road, Springfield Springfield, Missouri, 65810",
        "phone": "(417) 887-3689",
        "url": "https://locations.massageenvy.com/missouri/springfield/2155-west-republic-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.13916,
            -93.323315
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.17,
                    "duration": "indefinitely",
                    "model_conf": 68.61
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.44,
                    "duration": "indefinitely",
                    "model_conf": 158.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.81,
                    "duration": "indefinitely",
                    "model_conf": 139.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.35,
                    "duration": "indefinitely",
                    "model_conf": 102.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.73,
                    "duration": "indefinitely",
                    "model_conf": 154.78
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.7,
                    "duration": "indefinitely",
                    "model_conf": 270.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.73,
                    "duration": "indefinitely",
                    "model_conf": 195.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.49,
                    "duration": "2 Months",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.63,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.86,
                    "duration": "2 Months",
                    "model_conf": 151.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.11,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.11,
                    "duration": "indefinitely",
                    "model_conf": 217.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.68,
                    "duration": "1 Months",
                    "model_conf": 85.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.61,
                    "duration": "3 Months",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.53,
                    "duration": "indefinitely",
                    "model_conf": 208.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/st.-louis/9739-manchester-rd.html": {
        "name": "Webster Rock Hill",
        "address": "9739 Manchester Rd, St. Louis, Missouri, 63119",
        "phone": "(314) 292-5040",
        "url": "https://locations.massageenvy.com/missouri/st.-louis/9739-manchester-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.60806061253392,
            -90.37154227495193
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.84,
                    "duration": "3 Months",
                    "model_conf": 225.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.4,
                    "duration": "indefinitely",
                    "model_conf": 137.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.86,
                    "duration": "indefinitely",
                    "model_conf": 128.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.76,
                    "duration": "indefinitely",
                    "model_conf": 129.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.45,
                    "duration": "indefinitely",
                    "model_conf": 78.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.67,
                    "duration": "indefinitely",
                    "model_conf": 47.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.95,
                    "duration": "indefinitely",
                    "model_conf": 176.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/sunset-hills/3880-lindbergh-blvd.html": {
        "name": "Sunset Hills",
        "address": "3880 Lindbergh Blvd Suite 301, Sunset Hills, Missouri, 63127",
        "phone": "(314) 849-5100",
        "url": "https://locations.massageenvy.com/missouri/sunset-hills/3880-lindbergh-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.547692,
            -90.403937
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.75,
                    "duration": "indefinitely",
                    "model_conf": 262.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.92,
                    "duration": "indefinitely",
                    "model_conf": 153.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.6,
                    "duration": "2 Months",
                    "model_conf": 151.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.16,
                    "duration": "indefinitely",
                    "model_conf": 146.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.54,
                    "duration": "indefinitely",
                    "model_conf": 82.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.27,
                    "duration": "indefinitely",
                    "model_conf": 52.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.16,
                    "duration": "indefinitely",
                    "model_conf": 202.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/missouri/wildwood/2424-taylor-rd.html": {
        "name": "Wildwood",
        "address": "2424 Taylor Rd, Wildwood, Missouri, 63040",
        "phone": "(636) 821-2999",
        "url": "https://locations.massageenvy.com/missouri/wildwood/2424-taylor-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.5814419,
            -90.6256471
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.9,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.25,
                    "duration": "indefinitely",
                    "model_conf": 260.12
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 180.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.55,
                    "duration": "1 Months",
                    "model_conf": 159.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.63,
                    "duration": "indefinitely",
                    "model_conf": 155.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 149.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.5,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 220.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.49,
                    "duration": "1 Months",
                    "model_conf": 82.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.8,
                    "duration": "1 Months",
                    "model_conf": 52.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.92,
                    "duration": "indefinitely",
                    "model_conf": 207.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/montana/billings/1595-grand-ave.html": {
        "name": "Billings",
        "address": "1595 Grand Ave Ste 215, Billings, Montana, 59102",
        "phone": "(406) 256-9000",
        "url": "https://locations.massageenvy.com/montana/billings/1595-grand-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "11:00 AM - 5:00 PM"
        },
        "geolocation": [
            45.78547327981053,
            -108.55596243826221
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.99,
                    "duration": "3 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 99.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.81,
                    "duration": "indefinitely",
                    "model_conf": 155.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.45,
                    "duration": "4 Months",
                    "model_conf": 282.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 167.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 168.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 156.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.67,
                    "duration": "2 Months",
                    "model_conf": 220.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/montana/missoula/935-sw-higgins.html": {
        "name": "Missoula",
        "address": "935 SW Higgins, Missoula, Montana, 59803",
        "phone": "(406) 721-9000",
        "url": "https://locations.massageenvy.com/montana/missoula/935-sw-higgins.html",
        "email": "",
        "hours": {
            "MONDAY": "9:45 AM - 8:00 PM",
            "TUESDAY": "9:45 AM - 5:15 PM",
            "WEDNESDAY": "9:45 AM - 8:00 PM",
            "THURSDAY": "9:45 AM - 8:00 PM",
            "FRIDAY": "9:45 AM - 8:00 PM",
            "SATURDAY": "11:00 AM - 4:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            46.83497882547874,
            -114.01283145767212
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.51,
                    "duration": "1 Months",
                    "model_conf": 71.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.92,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.73,
                    "duration": "indefinitely",
                    "model_conf": 101.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 281.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.43,
                    "duration": "1 Months",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 168.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.99,
                    "duration": "2 Months",
                    "model_conf": 157.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.32,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.21,
                    "duration": "indefinitely",
                    "model_conf": 221.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/bellevue/3604-twin-creek-drive.html": {
        "name": "Twin Creek",
        "address": "3604 Twin Creek Drive #105, Bellevue, Nebraska, 68123",
        "phone": "(402) 291-3000",
        "url": "https://locations.massageenvy.com/nebraska/bellevue/3604-twin-creek-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.1428129,
            -95.9673815
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 71.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 102.43
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 278.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.68,
                    "duration": "3 Months",
                    "model_conf": 165.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.13,
                    "duration": "indefinitely",
                    "model_conf": 166.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.06,
                    "duration": "1 Months",
                    "model_conf": 156.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.83,
                    "duration": "3 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 85.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.61,
                    "duration": "2 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.84,
                    "duration": "indefinitely",
                    "model_conf": 220.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/la-vista/12746-westport-pkwy.html": {
        "name": "La Vista",
        "address": "12746 Westport Pkwy, La Vista, Nebraska, 68138",
        "phone": "(402) 933-3689",
        "url": "https://locations.massageenvy.com/nebraska/la-vista/12746-westport-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.178870026438084,
            -96.11450282618037
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 70.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.21,
                    "duration": "indefinitely",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 150.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.14,
                    "duration": "indefinitely",
                    "model_conf": 280.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 166.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.42,
                    "duration": "indefinitely",
                    "model_conf": 159.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 154.04
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.07,
                    "duration": "2 Months",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 81.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.87,
                    "duration": "indefinitely",
                    "model_conf": 219.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/lincoln/6005-o-street.html": {
        "name": "O Street",
        "address": "6005 O Street Ste F, Lincoln, Nebraska, 68510",
        "phone": "(402) 489-0007",
        "url": "https://locations.massageenvy.com/nebraska/lincoln/6005-o-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.81315,
            -96.639287
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.61,
                    "duration": "indefinitely",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.09,
                    "duration": "indefinitely",
                    "model_conf": 102.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.64,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 283.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.2,
                    "duration": "indefinitely",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.05,
                    "duration": "indefinitely",
                    "model_conf": 168.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 157.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.92,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.53,
                    "duration": "indefinitely",
                    "model_conf": 85.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.37,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 221.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/sparks/155-disc-drive.html": {
        "name": "Reno - Sparks Galleria",
        "address": "155 Disc Drive, Sparks Sparks, Nevada, 89436",
        "phone": "(775) 857-3689",
        "url": "https://locations.massageenvy.com/nevada/sparks/155-disc-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.5752076,
            -119.7408024
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": null,
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.36,
                    "duration": "1 Months",
                    "model_conf": 281.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.82,
                    "duration": "indefinitely",
                    "model_conf": 166.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.64,
                    "duration": "1 Months",
                    "model_conf": 166.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 156.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.85,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.82,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.52,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.33,
                    "duration": "1 Months",
                    "model_conf": 219.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-hampshire/manchester/375-south-willow-street.html": {
        "name": "Manchester-NH",
        "address": "375 South Willow Street, Manchester, New Hampshire, 03103",
        "phone": "(603) 622-2400",
        "url": "https://locations.massageenvy.com/new-hampshire/manchester/375-south-willow-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.966219,
            -71.452737
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.27,
                    "duration": "1 Months",
                    "model_conf": 278.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.86,
                    "duration": "indefinitely",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.52,
                    "duration": "2 Months",
                    "model_conf": 168.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.83,
                    "duration": "indefinitely",
                    "model_conf": 155.61
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.45,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.01,
                    "duration": "indefinitely",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.25,
                    "duration": "1 Months",
                    "model_conf": 220.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-hampshire/nashua/310-daniel-webster-highway.html": {
        "name": "Nashua",
        "address": "310 Daniel Webster Highway, Nashua, New Hampshire, 03060",
        "phone": "(603) 598-4400",
        "url": "https://locations.massageenvy.com/new-hampshire/nashua/310-daniel-webster-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.7022419,
            -71.4378536
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.79,
                    "duration": "3 Months",
                    "model_conf": 281.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.74,
                    "duration": "1 Months",
                    "model_conf": 167.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 156.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.44,
                    "duration": "1 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.52,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.38,
                    "duration": "indefinitely",
                    "model_conf": 55.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.46,
                    "duration": "1 Months",
                    "model_conf": 220.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/bayonne/211-lefante-way.html": {
        "name": "Bayonne",
        "address": "211 Lefante Way, Bayonne Bayonne, New Jersey, 07002",
        "phone": "(201) 243-1777",
        "url": "https://locations.massageenvy.com/new-jersey/bayonne/211-lefante-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.66733466677612,
            -74.10540963157757
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.54,
                    "duration": "1 Months",
                    "model_conf": 70.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.18,
                    "duration": "indefinitely",
                    "model_conf": 96.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.24,
                    "duration": "indefinitely",
                    "model_conf": 152.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 272.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.71,
                    "duration": "indefinitely",
                    "model_conf": 160.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.81,
                    "duration": "indefinitely",
                    "model_conf": 159.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.42,
                    "duration": "indefinitely",
                    "model_conf": 152.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.6,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.98,
                    "duration": "indefinitely",
                    "model_conf": 84.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.44,
                    "duration": "indefinitely",
                    "model_conf": 54.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.09,
                    "duration": "1 Months",
                    "model_conf": 207.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/berkeley-heights/404-springfield-ave.html": {
        "name": "Berkeley Heights",
        "address": "404 Springfield Ave, Berkeley Heights Berkeley Heights, New Jersey, 07922",
        "phone": "(908) 464-0070",
        "url": "https://locations.massageenvy.com/new-jersey/berkeley-heights/404-springfield-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.68521858376943,
            -74.43654119968414
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.31,
                    "duration": "indefinitely",
                    "model_conf": 67.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.42,
                    "duration": "indefinitely",
                    "model_conf": 139.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.05,
                    "duration": "indefinitely",
                    "model_conf": 53.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.02,
                    "duration": "indefinitely",
                    "model_conf": 136.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.84,
                    "duration": "indefinitely",
                    "model_conf": 218.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.09,
                    "duration": "indefinitely",
                    "model_conf": 121.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.79,
                    "duration": "indefinitely",
                    "model_conf": 92.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 206.83,
                    "duration": "5 Months",
                    "model_conf": 127.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.36,
                    "duration": "11 Months",
                    "model_conf": 139.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.08,
                    "duration": "2 Months",
                    "model_conf": 76.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.99,
                    "duration": "5 Months",
                    "model_conf": 48.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.41,
                    "duration": "indefinitely",
                    "model_conf": 126.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/branchburg/3150-route-22-w.html": {
        "name": "Branchburg",
        "address": "3150 Route 22 W Ste 6, Branchburg, New Jersey, 08876",
        "phone": "(908) 704-0404",
        "url": "https://locations.massageenvy.com/new-jersey/branchburg/3150-route-22-w.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.60465903809103,
            -74.69196259975433
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.79,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.8,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.88,
                    "duration": "indefinitely",
                    "model_conf": 100.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.76,
                    "duration": "indefinitely",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.09,
                    "duration": "indefinitely",
                    "model_conf": 278.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.3,
                    "duration": "4 Months",
                    "model_conf": 164.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 72.86,
                    "duration": "indefinitely",
                    "model_conf": 166.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.9,
                    "duration": "2 Months",
                    "model_conf": 156.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.31,
                    "duration": "1 Months",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.88,
                    "duration": "indefinitely",
                    "model_conf": 85.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.76,
                    "duration": "1 Months",
                    "model_conf": 55.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 213.3,
                    "duration": "indefinitely",
                    "model_conf": 217.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/brick/682-route-70.html": {
        "name": "Brick",
        "address": "682 Route 70, Brick Brick, New Jersey, 08723",
        "phone": "(732) 920-3100",
        "url": "https://locations.massageenvy.com/new-jersey/brick/682-route-70.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.0621740865019,
            -74.14355549443655
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.13,
                    "duration": "indefinitely",
                    "model_conf": 275.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.23,
                    "duration": "2 Months",
                    "model_conf": 166.73
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.11,
                    "duration": "indefinitely",
                    "model_conf": 167.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 197.98,
                    "duration": "3 Months",
                    "model_conf": 156.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.1,
                    "duration": "2 Months",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.36,
                    "duration": "4 Months",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.77,
                    "duration": "6 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.6,
                    "duration": "indefinitely",
                    "model_conf": 219.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/cherry-hill/957-haddonfield-road.html": {
        "name": "Cherry Hill",
        "address": "957 Haddonfield Road Ste 3B, Cherry Hill, New Jersey, 08002",
        "phone": "(856) 665-3800",
        "url": "https://locations.massageenvy.com/new-jersey/cherry-hill/957-haddonfield-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            39.9238685598888,
            -75.03434686414622
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.29,
                    "duration": "1 Months",
                    "model_conf": 70.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.81,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.15,
                    "duration": "5 Months",
                    "model_conf": 101.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.45,
                    "duration": "6 Months",
                    "model_conf": 274.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 163.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.84,
                    "duration": "6 Months",
                    "model_conf": 149.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 152.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.29,
                    "duration": "1 Months",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 85.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.45,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.22,
                    "duration": "3 Months",
                    "model_conf": 205.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/chester/145-us-route-206-s.html": {
        "name": "Chester",
        "address": "145 US Route 206 S, Chester, New Jersey, 07930",
        "phone": "(908) 879-4200",
        "url": "https://locations.massageenvy.com/new-jersey/chester/145-us-route-206-s.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.781092,
            -74.696573
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.44,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.91,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.47,
                    "duration": "indefinitely",
                    "model_conf": 101.64
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.68,
                    "duration": "indefinitely",
                    "model_conf": 154.42
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.19,
                    "duration": "indefinitely",
                    "model_conf": 279.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.63,
                    "duration": "indefinitely",
                    "model_conf": 165.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.6,
                    "duration": "indefinitely",
                    "model_conf": 167.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.61,
                    "duration": "indefinitely",
                    "model_conf": 156.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.63,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.68,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.71,
                    "duration": "1 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.35,
                    "duration": "indefinitely",
                    "model_conf": 219.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/clifton/852-route-3-west.html": {
        "name": "Clifton",
        "address": "852 Route 3 West, Clifton Clifton, New Jersey, 07012",
        "phone": "(973) 340-3689",
        "url": "https://locations.massageenvy.com/new-jersey/clifton/852-route-3-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.8541157,
            -74.17680179999999
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.02,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.21,
                    "duration": "2 Months",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.19,
                    "duration": "4 Months",
                    "model_conf": 96.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 153.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.83,
                    "duration": "indefinitely",
                    "model_conf": 272.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.67,
                    "duration": "indefinitely",
                    "model_conf": 163.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.46,
                    "duration": "9 Months",
                    "model_conf": 163.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.11,
                    "duration": "indefinitely",
                    "model_conf": 154.44
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.68,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 85.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.37,
                    "duration": "indefinitely",
                    "model_conf": 54.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.35,
                    "duration": "indefinitely",
                    "model_conf": 211.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/closter/51-vervalen-street.html": {
        "name": "Closter",
        "address": "51 Vervalen Street, Closter, New Jersey, 07624",
        "phone": "(201) 784-3333",
        "url": "https://locations.massageenvy.com/new-jersey/closter/51-vervalen-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "9:30 AM - 6:00 PM"
        },
        "geolocation": [
            40.9705178,
            -73.9558893
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.14,
                    "duration": "indefinitely",
                    "model_conf": 70.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.99,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.63,
                    "duration": "indefinitely",
                    "model_conf": 92.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.98,
                    "duration": "indefinitely",
                    "model_conf": 151.49
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.86,
                    "duration": "indefinitely",
                    "model_conf": 264.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.72,
                    "duration": "indefinitely",
                    "model_conf": 159.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.3,
                    "duration": "indefinitely",
                    "model_conf": 155.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.31,
                    "duration": "indefinitely",
                    "model_conf": 152.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.78,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.92,
                    "duration": "indefinitely",
                    "model_conf": 84.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.14,
                    "duration": "indefinitely",
                    "model_conf": 53.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.46,
                    "duration": "indefinitely",
                    "model_conf": 206.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/east-brunswick/314-rt-18.html": {
        "name": "East Brunswick",
        "address": "314 Rt 18, East Brunswick East Brunswick, New Jersey, 08816",
        "phone": "(732) 238-4444",
        "url": "https://locations.massageenvy.com/new-jersey/east-brunswick/314-rt-18.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.45656620891131,
            -74.39780360486941
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.09,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.83,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.12,
                    "duration": "indefinitely",
                    "model_conf": 98.94
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.79,
                    "duration": "indefinitely",
                    "model_conf": 153.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.87,
                    "duration": "indefinitely",
                    "model_conf": 280.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.36,
                    "duration": "4 Months",
                    "model_conf": 163.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.47,
                    "duration": "indefinitely",
                    "model_conf": 164.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.82,
                    "duration": "2 Months",
                    "model_conf": 154.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.68,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.64,
                    "duration": "indefinitely",
                    "model_conf": 85.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.78,
                    "duration": "1 Months",
                    "model_conf": 54.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.69,
                    "duration": "indefinitely",
                    "model_conf": 214.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/east-rutherford/100-route-17-north.html": {
        "name": "East Rutherford",
        "address": "100 Route 17 North, East Rutherford East Rutherford, New Jersey, 07073",
        "phone": "(201) 728-9460",
        "url": "https://locations.massageenvy.com/new-jersey/east-rutherford/100-route-17-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.8307803,
            -74.0893916
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.17,
                    "duration": "indefinitely",
                    "model_conf": 70.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.22,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.22,
                    "duration": "indefinitely",
                    "model_conf": 95.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.12,
                    "duration": "1 Months",
                    "model_conf": 152.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.64,
                    "duration": "indefinitely",
                    "model_conf": 269.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.31,
                    "duration": "indefinitely",
                    "model_conf": 161.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.74,
                    "duration": "1 Months",
                    "model_conf": 159.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.43,
                    "duration": "indefinitely",
                    "model_conf": 152.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.37,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.93,
                    "duration": "indefinitely",
                    "model_conf": 84.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.65,
                    "duration": "indefinitely",
                    "model_conf": 54.36
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.03,
                    "duration": "indefinitely",
                    "model_conf": 208.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/edgewater/725-river-rd.html": {
        "name": "Edgewater",
        "address": "725 River Rd, Edgewater, New Jersey, 07020",
        "phone": "(201) 941-2424",
        "url": "https://locations.massageenvy.com/new-jersey/edgewater/725-river-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:30 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.81840500737263,
            -73.97740673421772
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 69.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 139.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 89.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.21,
                    "duration": "indefinitely",
                    "model_conf": 148.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.18,
                    "duration": "indefinitely",
                    "model_conf": 256.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 152.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 147.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.84,
                    "duration": "indefinitely",
                    "model_conf": 146.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.4,
                    "duration": "indefinitely",
                    "model_conf": 140.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 82.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 53.18
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.25,
                    "duration": "indefinitely",
                    "model_conf": 192.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/englishtown/126-route-9-north.html": {
        "name": "Marlboro",
        "address": "126 Route 9 North, Englishtown, New Jersey, 07726",
        "phone": "(732) 972-9800",
        "url": "https://locations.massageenvy.com/new-jersey/englishtown/126-route-9-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.350159,
            -74.3046463
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.81,
                    "duration": "2 Months",
                    "model_conf": 70.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.04,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 72.6,
                    "duration": "12 Months",
                    "model_conf": 94.51
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.38,
                    "duration": "2 Months",
                    "model_conf": 152.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.34,
                    "duration": "indefinitely",
                    "model_conf": 278.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.26,
                    "duration": "indefinitely",
                    "model_conf": 159.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.15,
                    "duration": "indefinitely",
                    "model_conf": 157.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.8,
                    "duration": "indefinitely",
                    "model_conf": 148.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.15,
                    "duration": "1 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.38,
                    "duration": "indefinitely",
                    "model_conf": 84.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.0,
                    "duration": "indefinitely",
                    "model_conf": 54.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 215.12,
                    "duration": "8 Months",
                    "model_conf": 207.02
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/fair-lawn/2150-promenade-blvd.html": {
        "name": "Fair Lawn",
        "address": "2150 Promenade Blvd, Fair Lawn, New Jersey, 07410",
        "phone": "(201) 794-2000",
        "url": "https://locations.massageenvy.com/new-jersey/fair-lawn/2150-promenade-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.9438343,
            -74.1293468
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.56,
                    "duration": "indefinitely",
                    "model_conf": 70.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.82,
                    "duration": "3 Months",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.66,
                    "duration": "1 Months",
                    "model_conf": 91.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.94,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.33,
                    "duration": "1 Months",
                    "model_conf": 265.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.52,
                    "duration": "indefinitely",
                    "model_conf": 163.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.64,
                    "duration": "1 Months",
                    "model_conf": 159.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.36,
                    "duration": "indefinitely",
                    "model_conf": 154.15
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.66,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.1,
                    "duration": "indefinitely",
                    "model_conf": 84.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.48,
                    "duration": "indefinitely",
                    "model_conf": 53.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.39,
                    "duration": "indefinitely",
                    "model_conf": 207.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/flemington/100-reaville-ave.html": {
        "name": "Flemington",
        "address": "100 Reaville Ave, Flemington Flemington, New Jersey, 08822",
        "phone": "(908) 782-2223",
        "url": "https://locations.massageenvy.com/new-jersey/flemington/100-reaville-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.500086194215214,
            -74.85100408182143
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.37,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.8,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.81,
                    "duration": "indefinitely",
                    "model_conf": 100.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.17,
                    "duration": "indefinitely",
                    "model_conf": 154.68
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.05,
                    "duration": "indefinitely",
                    "model_conf": 282.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.51,
                    "duration": "3 Months",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.41,
                    "duration": "indefinitely",
                    "model_conf": 165.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.08,
                    "duration": "3 Months",
                    "model_conf": 157.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.69,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.29,
                    "duration": "1 Months",
                    "model_conf": 85.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.61,
                    "duration": "2 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.13,
                    "duration": "indefinitely",
                    "model_conf": 219.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/florham-park/184-columbia-turnpike.html": {
        "name": "Florham Park",
        "address": "184 Columbia Turnpike, Florham Park, New Jersey, 07932",
        "phone": "(973) 822-2300",
        "url": "https://locations.massageenvy.com/new-jersey/florham-park/184-columbia-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.7905528,
            -74.393075
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.26,
                    "duration": "indefinitely",
                    "model_conf": 70.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.29,
                    "duration": "1 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.45,
                    "duration": "indefinitely",
                    "model_conf": 92.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.77,
                    "duration": "indefinitely",
                    "model_conf": 148.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.17,
                    "duration": "indefinitely",
                    "model_conf": 257.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.52,
                    "duration": "1 Months",
                    "model_conf": 149.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.53,
                    "duration": "indefinitely",
                    "model_conf": 154.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.68,
                    "duration": "indefinitely",
                    "model_conf": 149.15
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.2,
                    "duration": "1 Months",
                    "model_conf": 140.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.07,
                    "duration": "indefinitely",
                    "model_conf": 83.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.08,
                    "duration": "indefinitely",
                    "model_conf": 54.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.74,
                    "duration": "indefinitely",
                    "model_conf": 204.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/freehold/57-village-center-drive.html": {
        "name": "Freehold",
        "address": "57 Village Center Drive, Freehold Freehold, New Jersey, 07728",
        "phone": "(732) 845-3300",
        "url": "https://locations.massageenvy.com/new-jersey/freehold/57-village-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.241399,
            -74.306235
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.32,
                    "duration": "1 Months",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.81,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.66,
                    "duration": "6 Months",
                    "model_conf": 100.89
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.9,
                    "duration": "1 Months",
                    "model_conf": 154.07
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.55,
                    "duration": "5 Months",
                    "model_conf": 280.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.03,
                    "duration": "indefinitely",
                    "model_conf": 164.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.27,
                    "duration": "9 Months",
                    "model_conf": 166.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.22,
                    "duration": "indefinitely",
                    "model_conf": 156.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.9,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.66,
                    "duration": "indefinitely",
                    "model_conf": 85.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.54,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.05,
                    "duration": "3 Months",
                    "model_conf": 217.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/garwood/518-north-avenue.html": {
        "name": "Garwood",
        "address": "518 North Avenue, Garwood, New Jersey, 07027",
        "phone": "(908) 233-4442",
        "url": "https://locations.massageenvy.com/new-jersey/garwood/518-north-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 9:00 PM"
        },
        "geolocation": [
            40.65295,
            -74.330145
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.81,
                    "duration": "indefinitely",
                    "model_conf": 69.03
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.41,
                    "duration": "indefinitely",
                    "model_conf": 139.29
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.34,
                    "duration": "indefinitely",
                    "model_conf": 81.75
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.8,
                    "duration": "indefinitely",
                    "model_conf": 145.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.9,
                    "duration": "indefinitely",
                    "model_conf": 248.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.53,
                    "duration": "6 Months",
                    "model_conf": 143.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.68,
                    "duration": "indefinitely",
                    "model_conf": 134.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.41,
                    "duration": "2 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.43,
                    "duration": "1 Months",
                    "model_conf": 140.31
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.4,
                    "duration": "1 Months",
                    "model_conf": 81.32
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.56,
                    "duration": "2 Months",
                    "model_conf": 52.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 225.04,
                    "duration": "indefinitely",
                    "model_conf": 178.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/hillsborough/30-falcon-rd.html": {
        "name": "Hillsborough",
        "address": "30 Falcon Rd, Hillsborough Hillsborough, New Jersey, 08844",
        "phone": "(908) 281-7995",
        "url": "https://locations.massageenvy.com/new-jersey/hillsborough/30-falcon-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.519,
            -74.629652
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.74,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.49,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.58,
                    "duration": "indefinitely",
                    "model_conf": 97.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 223.38,
                    "duration": "indefinitely",
                    "model_conf": 154.33
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.94,
                    "duration": "indefinitely",
                    "model_conf": 274.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.4,
                    "duration": "indefinitely",
                    "model_conf": 161.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 82.29,
                    "duration": "indefinitely",
                    "model_conf": 162.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 214.38,
                    "duration": "10 Months",
                    "model_conf": 154.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.76,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.83,
                    "duration": "5 Months",
                    "model_conf": 84.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.4,
                    "duration": "8 Months",
                    "model_conf": 54.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.48,
                    "duration": "indefinitely",
                    "model_conf": 216.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/hoboken/1170-maxwell-lane.html": {
        "name": "Hoboken",
        "address": "1170 Maxwell Lane, Hoboken, New Jersey, 07030",
        "phone": "(201) 253-1000",
        "url": "https://locations.massageenvy.com/new-jersey/hoboken/1170-maxwell-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.7496149,
            -74.0246598
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.94,
                    "duration": "indefinitely",
                    "model_conf": 67.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.68,
                    "duration": "indefinitely",
                    "model_conf": 139.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.84,
                    "duration": "6 Months",
                    "model_conf": 74.54
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.18,
                    "duration": "indefinitely",
                    "model_conf": 142.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.56,
                    "duration": "indefinitely",
                    "model_conf": 229.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.84,
                    "duration": "3 Months",
                    "model_conf": 135.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.04,
                    "duration": "4 Months",
                    "model_conf": 122.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.14,
                    "duration": "indefinitely",
                    "model_conf": 132.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.0,
                    "duration": "indefinitely",
                    "model_conf": 140.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.37,
                    "duration": "indefinitely",
                    "model_conf": 79.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.91,
                    "duration": "indefinitely",
                    "model_conf": 50.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.93,
                    "duration": "indefinitely",
                    "model_conf": 163.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/holmdel/2101-route-35.html": {
        "name": "Holmdel",
        "address": "2101 Route 35 Unit 50, Holmdel, New Jersey, 07733",
        "phone": "(732) 796-1000",
        "url": "https://locations.massageenvy.com/new-jersey/holmdel/2101-route-35.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.41463549697058,
            -74.14433368404407
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.23,
                    "duration": "indefinitely",
                    "model_conf": 70.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.06,
                    "duration": "4 Months",
                    "model_conf": 93.8
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.35,
                    "duration": "2 Months",
                    "model_conf": 151.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.18,
                    "duration": "4 Months",
                    "model_conf": 275.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.61,
                    "duration": "indefinitely",
                    "model_conf": 160.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.03,
                    "duration": "10 Months",
                    "model_conf": 158.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.97,
                    "duration": "indefinitely",
                    "model_conf": 152.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.68,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.46,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.45,
                    "duration": "indefinitely",
                    "model_conf": 53.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.74,
                    "duration": "3 Months",
                    "model_conf": 214.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/jersey-city/389-washington-street.html": {
        "name": "Jersey City Downtown",
        "address": "389 Washington Street, Jersey City, New Jersey, 07302",
        "phone": "(201) 427-7997",
        "url": "https://locations.massageenvy.com/new-jersey/jersey-city/389-washington-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.7215348,
            -74.0366311
        ],
        "rating": 4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.6,
                    "duration": "indefinitely",
                    "model_conf": 68.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.51,
                    "duration": "indefinitely",
                    "model_conf": 139.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.47,
                    "duration": "indefinitely",
                    "model_conf": 80.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.88,
                    "duration": "indefinitely",
                    "model_conf": 144.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.94,
                    "duration": "indefinitely",
                    "model_conf": 238.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.33,
                    "duration": "indefinitely",
                    "model_conf": 141.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.73,
                    "duration": "1 Months",
                    "model_conf": 131.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.14,
                    "duration": "indefinitely",
                    "model_conf": 136.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.9,
                    "duration": "indefinitely",
                    "model_conf": 140.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.29,
                    "duration": "indefinitely",
                    "model_conf": 80.07
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.51,
                    "duration": "2 Months",
                    "model_conf": 51.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.53,
                    "duration": "indefinitely",
                    "model_conf": 173.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/lawrenceville/3373-brunswick-pike.html": {
        "name": "Princeton",
        "address": "3373 Brunswick Pike #55, Lawrenceville, New Jersey, 08648",
        "phone": "(609) 750-0140",
        "url": "https://locations.massageenvy.com/new-jersey/lawrenceville/3373-brunswick-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.298571,
            -74.681556
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 70.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.58,
                    "duration": "1 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.84,
                    "duration": "indefinitely",
                    "model_conf": 94.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.35,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.67,
                    "duration": "2 Months",
                    "model_conf": 272.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.87,
                    "duration": "indefinitely",
                    "model_conf": 155.74
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.62,
                    "duration": "1 Months",
                    "model_conf": 160.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.66,
                    "duration": "indefinitely",
                    "model_conf": 153.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.0,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.57,
                    "duration": "indefinitely",
                    "model_conf": 84.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.26,
                    "duration": "indefinitely",
                    "model_conf": 54.3
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.71,
                    "duration": "indefinitely",
                    "model_conf": 217.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/marlton/153-route-73-south.html": {
        "name": "Marlton Crossing",
        "address": "153 Route 73 South, Marlton, New Jersey, 08053",
        "phone": "(856) 983-8333",
        "url": "https://locations.massageenvy.com/new-jersey/marlton/153-route-73-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.890697,
            -74.927149
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 70.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.56,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.27,
                    "duration": "indefinitely",
                    "model_conf": 101.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 269.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.62,
                    "duration": "4 Months",
                    "model_conf": 161.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.84,
                    "duration": "indefinitely",
                    "model_conf": 136.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.42,
                    "duration": "1 Months",
                    "model_conf": 150.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.24,
                    "duration": "2 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 84.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 197.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/metuchen/665-middlesex-ave..html": {
        "name": "Metuchen",
        "address": "665 Middlesex Ave. Suite 115, Metuchen Metuchen, New Jersey, 08840",
        "phone": "(732) 395-4791",
        "url": "https://locations.massageenvy.com/new-jersey/metuchen/665-middlesex-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.539872606841975,
            -74.36742665229872
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.15,
                    "duration": "indefinitely",
                    "model_conf": 70.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.87,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.63,
                    "duration": "indefinitely",
                    "model_conf": 98.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.68,
                    "duration": "indefinitely",
                    "model_conf": 153.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.52,
                    "duration": "indefinitely",
                    "model_conf": 275.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.18,
                    "duration": "7 Months",
                    "model_conf": 161.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 162.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 216.85,
                    "duration": "5 Months",
                    "model_conf": 154.27
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.86,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.66,
                    "duration": "3 Months",
                    "model_conf": 84.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.17,
                    "duration": "4 Months",
                    "model_conf": 54.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 225.51,
                    "duration": "indefinitely",
                    "model_conf": 213.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/morris-plains/1711-state-route-10-east.html": {
        "name": "Morris Plains",
        "address": "1711 State Route 10 East Suite 15B, Morris Plains, New Jersey, 07950",
        "phone": "(973) 539-2900",
        "url": "https://locations.massageenvy.com/new-jersey/morris-plains/1711-state-route-10-east.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.8450438,
            -74.4672401
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.38,
                    "duration": "indefinitely",
                    "model_conf": 70.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.78,
                    "duration": "3 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.96,
                    "duration": "5 Months",
                    "model_conf": 93.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.57,
                    "duration": "2 Months",
                    "model_conf": 148.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.13,
                    "duration": "4 Months",
                    "model_conf": 253.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.3,
                    "duration": "indefinitely",
                    "model_conf": 143.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.33,
                    "duration": "8 Months",
                    "model_conf": 161.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.06,
                    "duration": "indefinitely",
                    "model_conf": 152.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.03,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.33,
                    "duration": "indefinitely",
                    "model_conf": 83.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.52,
                    "duration": "indefinitely",
                    "model_conf": 54.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.72,
                    "duration": "4 Months",
                    "model_conf": 212.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/mount-laurel/46a-centerton-road.html": {
        "name": "Mount Laurel",
        "address": "46A Centerton Road Unit I-1, Mount Laurel Mount Laurel, New Jersey, 08054",
        "phone": "(856) 780-1078",
        "url": "https://locations.massageenvy.com/new-jersey/mount-laurel/46a-centerton-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.96935627160077,
            -74.91137016572827
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.3,
                    "duration": "1 Months",
                    "model_conf": 70.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.89,
                    "duration": "6 Months",
                    "model_conf": 101.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.44,
                    "duration": "1 Months",
                    "model_conf": 154.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.9,
                    "duration": "8 Months",
                    "model_conf": 275.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 162.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.64,
                    "duration": "8 Months",
                    "model_conf": 151.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.88,
                    "duration": "indefinitely",
                    "model_conf": 154.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.64,
                    "duration": "indefinitely",
                    "model_conf": 85.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.68,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.81,
                    "duration": "4 Months",
                    "model_conf": 204.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/parsippany/1119-us-route-46-east.html": {
        "name": "Parsippany",
        "address": "1119 US Route 46 East, Parsippany, New Jersey, 07054",
        "phone": "(973) 335-4900",
        "url": "https://locations.massageenvy.com/new-jersey/parsippany/1119-us-route-46-east.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "8:30 AM - 8:00 PM",
            "SUNDAY": "8:30 AM - 6:00 PM"
        },
        "geolocation": [
            40.86176558634407,
            -74.3872545656414
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.4,
                    "duration": "11 Months",
                    "model_conf": 260.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.3,
                    "duration": "indefinitely",
                    "model_conf": 152.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.62,
                    "duration": "indefinitely",
                    "model_conf": 163.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 203.95,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.3,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.47,
                    "duration": "indefinitely",
                    "model_conf": 84.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.52,
                    "duration": "indefinitely",
                    "model_conf": 54.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 195.83,
                    "duration": "8 Months",
                    "model_conf": 213.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/piscataway/1344-centennial-ave.html": {
        "name": "Piscataway",
        "address": "1344 Centennial Ave, Piscataway, New Jersey, 08854",
        "phone": "(732) 844-1010",
        "url": "https://locations.massageenvy.com/new-jersey/piscataway/1344-centennial-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.55112556164435,
            -74.43693548440933
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.9,
                    "duration": "indefinitely",
                    "model_conf": 70.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.97,
                    "duration": "indefinitely",
                    "model_conf": 139.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.02,
                    "duration": "indefinitely",
                    "model_conf": 97.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.05,
                    "duration": "indefinitely",
                    "model_conf": 153.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.09,
                    "duration": "indefinitely",
                    "model_conf": 272.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.51,
                    "duration": "9 Months",
                    "model_conf": 160.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.42,
                    "duration": "indefinitely",
                    "model_conf": 161.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 216.32,
                    "duration": "6 Months",
                    "model_conf": 154.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.29,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.32,
                    "duration": "4 Months",
                    "model_conf": 84.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.91,
                    "duration": "5 Months",
                    "model_conf": 54.23
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 226.09,
                    "duration": "indefinitely",
                    "model_conf": 212.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/ramsey/1300-state-route-17-north.html": {
        "name": "Ramsey",
        "address": "1300 State Route 17 North, Ramsey Ramsey, New Jersey, 07446",
        "phone": "(201) 236-2550",
        "url": "https://locations.massageenvy.com/new-jersey/ramsey/1300-state-route-17-north.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "8:30 AM - 7:00 PM",
            "SUNDAY": "8:30 AM - 7:00 PM"
        },
        "geolocation": [
            41.0725909518915,
            -74.13781031571679
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.1,
                    "duration": "indefinitely",
                    "model_conf": 71.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.14,
                    "duration": "2 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.17,
                    "duration": "indefinitely",
                    "model_conf": 96.63
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.82,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.87,
                    "duration": "indefinitely",
                    "model_conf": 272.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.45,
                    "duration": "indefinitely",
                    "model_conf": 164.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.34,
                    "duration": "indefinitely",
                    "model_conf": 162.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.87,
                    "duration": "indefinitely",
                    "model_conf": 155.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.15,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.34,
                    "duration": "indefinitely",
                    "model_conf": 85.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.04,
                    "duration": "indefinitely",
                    "model_conf": 54.3
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.21,
                    "duration": "indefinitely",
                    "model_conf": 213.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/river-edge/1041-main-street.html": {
        "name": "River Edge",
        "address": "1041 Main Street, River Edge River Edge, New Jersey, 07661",
        "phone": "(201) 525-5550",
        "url": "https://locations.massageenvy.com/new-jersey/river-edge/1041-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.9124583,
            -74.0381181
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.99,
                    "duration": "indefinitely",
                    "model_conf": 70.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.86,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.9,
                    "duration": "indefinitely",
                    "model_conf": 92.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.79,
                    "duration": "indefinitely",
                    "model_conf": 152.38
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.31,
                    "duration": "indefinitely",
                    "model_conf": 266.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.87,
                    "duration": "indefinitely",
                    "model_conf": 162.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.01,
                    "duration": "5 Months",
                    "model_conf": 158.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.48,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.6,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.16,
                    "duration": "indefinitely",
                    "model_conf": 84.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.55,
                    "duration": "indefinitely",
                    "model_conf": 53.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.78,
                    "duration": "indefinitely",
                    "model_conf": 208.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/sea-girt/2100-highway-35.html": {
        "name": "Sea Girt",
        "address": "2100 Highway 35 #7, Sea Girt Sea Girt, New Jersey, 08750",
        "phone": "(732) 449-3689",
        "url": "https://locations.massageenvy.com/new-jersey/sea-girt/2100-highway-35.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.14506399362036,
            -74.05797180489344
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.09,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.3,
                    "duration": "1 Months",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.71,
                    "duration": "1 Months",
                    "model_conf": 101.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.79,
                    "duration": "indefinitely",
                    "model_conf": 154.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.58,
                    "duration": "indefinitely",
                    "model_conf": 279.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.58,
                    "duration": "1 Months",
                    "model_conf": 165.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.99,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.64,
                    "duration": "indefinitely",
                    "model_conf": 156.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.56,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.45,
                    "duration": "1 Months",
                    "model_conf": 85.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.17,
                    "duration": "indefinitely",
                    "model_conf": 218.19
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/short-hills/726-morris-turnpike.html": {
        "name": "Short Hills",
        "address": "726 Morris Turnpike, Short Hills, New Jersey, 07078",
        "phone": "(973) 467-5700",
        "url": "https://locations.massageenvy.com/new-jersey/short-hills/726-morris-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.7200477,
            -74.3297329
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.59,
                    "duration": "indefinitely",
                    "model_conf": 68.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.9,
                    "duration": "indefinitely",
                    "model_conf": 139.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.69,
                    "duration": "indefinitely",
                    "model_conf": 79.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.52,
                    "duration": "indefinitely",
                    "model_conf": 144.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.04,
                    "duration": "indefinitely",
                    "model_conf": 245.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.14,
                    "duration": "4 Months",
                    "model_conf": 141.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.57,
                    "duration": "indefinitely",
                    "model_conf": 130.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.57,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.9,
                    "duration": "2 Months",
                    "model_conf": 140.27
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.86,
                    "duration": "indefinitely",
                    "model_conf": 80.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.06,
                    "duration": "indefinitely",
                    "model_conf": 51.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 223.33,
                    "duration": "indefinitely",
                    "model_conf": 173.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/shrewsbury/210-shrewsbury-plaza.html": {
        "name": "Shrewsbury",
        "address": "210 Shrewsbury Plaza, Shrewsbury, New Jersey, 07702",
        "phone": "(732) 460-1919",
        "url": "https://locations.massageenvy.com/new-jersey/shrewsbury/210-shrewsbury-plaza.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.3151015,
            -74.0658532
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.64,
                    "duration": "indefinitely",
                    "model_conf": 66.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.88,
                    "duration": "indefinitely",
                    "model_conf": 139.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 72.63,
                    "duration": "12 Months",
                    "model_conf": 40.16
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.7,
                    "duration": "4 Months",
                    "model_conf": 132.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.15,
                    "duration": "10 Months",
                    "model_conf": 219.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.62,
                    "duration": "indefinitely",
                    "model_conf": 99.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.62,
                    "duration": "indefinitely",
                    "model_conf": 68.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 223.26,
                    "duration": "indefinitely",
                    "model_conf": 113.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.28,
                    "duration": "indefinitely",
                    "model_conf": 139.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.15,
                    "duration": "indefinitely",
                    "model_conf": 78.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.45,
                    "duration": "indefinitely",
                    "model_conf": 46.2
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 214.71,
                    "duration": "9 Months",
                    "model_conf": 189.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/sicklerville/610-cross-keys-rd.html": {
        "name": "Turnersville",
        "address": "610 Cross Keys Rd Suite 303, Sicklerville, New Jersey, 08081",
        "phone": "(856) 740-3689",
        "url": "https://locations.massageenvy.com/new-jersey/sicklerville/610-cross-keys-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.7340888,
            -75.0057144
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.46,
                    "duration": "1 Months",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 101.9
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 154.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.37,
                    "duration": "1 Months",
                    "model_conf": 282.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 166.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 167.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.13,
                    "duration": "indefinitely",
                    "model_conf": 157.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.42,
                    "duration": "1 Months",
                    "model_conf": 220.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/skillman/1325-us-rt-206.html": {
        "name": "Montgomery",
        "address": "1325 US Rt 206 Ste 18, Skillman, New Jersey, 08558",
        "phone": "(609) 921-7000",
        "url": "https://locations.massageenvy.com/new-jersey/skillman/1325-us-rt-206.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.403867,
            -74.647228
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.16,
                    "duration": "indefinitely",
                    "model_conf": 279.53
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.66,
                    "duration": "indefinitely",
                    "model_conf": 190.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.04,
                    "duration": "9 Months",
                    "model_conf": 162.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.34,
                    "duration": "indefinitely",
                    "model_conf": 162.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 197.19,
                    "duration": "5 Months",
                    "model_conf": 153.0
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.83,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 233.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.54,
                    "duration": "4 Months",
                    "model_conf": 84.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.17,
                    "duration": "5 Months",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 204.46,
                    "duration": "indefinitely",
                    "model_conf": 218.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/toms-river/2-route-37-west.html": {
        "name": "Toms River",
        "address": "2 Route 37 West Ste G-4, Toms River, New Jersey, 08753",
        "phone": "(732) 341-3300",
        "url": "https://locations.massageenvy.com/new-jersey/toms-river/2-route-37-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.9652023,
            -74.202362
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.2,
                    "duration": "indefinitely",
                    "model_conf": 280.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.51,
                    "duration": "4 Months",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.1,
                    "duration": "indefinitely",
                    "model_conf": 168.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.81,
                    "duration": "2 Months",
                    "model_conf": 157.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.85,
                    "duration": "2 Months",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.88,
                    "duration": "3 Months",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.74,
                    "duration": "1 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.7,
                    "duration": "indefinitely",
                    "model_conf": 219.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/verona/285-pompton-avenue.html": {
        "name": "Verona",
        "address": "285 Pompton Avenue, Verona, New Jersey, 07044",
        "phone": "(973) 571-1322",
        "url": "https://locations.massageenvy.com/new-jersey/verona/285-pompton-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.835155,
            -74.225249
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.26,
                    "duration": "indefinitely",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.3,
                    "duration": "1 Months",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.29,
                    "duration": "3 Months",
                    "model_conf": 97.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.97,
                    "duration": "1 Months",
                    "model_conf": 153.07
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.77,
                    "duration": "2 Months",
                    "model_conf": 272.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.62,
                    "duration": "indefinitely",
                    "model_conf": 162.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.78,
                    "duration": "6 Months",
                    "model_conf": 163.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.17,
                    "duration": "indefinitely",
                    "model_conf": 154.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.86,
                    "duration": "indefinitely",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.96,
                    "duration": "indefinitely",
                    "model_conf": 84.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.88,
                    "duration": "indefinitely",
                    "model_conf": 54.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.76,
                    "duration": "2 Months",
                    "model_conf": 212.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/waldwick/26-franklin-turnpike.html": {
        "name": "Waldwick",
        "address": "26 Franklin Turnpike, Waldwick, New Jersey, 07463",
        "phone": "(201) 689-8400",
        "url": "https://locations.massageenvy.com/new-jersey/waldwick/26-franklin-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 7:00 PM",
            "SATURDAY": "9:30 AM - 6:00 PM",
            "SUNDAY": "9:30 AM - 8:00 PM"
        },
        "geolocation": [
            41.0093826,
            -74.1196407
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.74,
                    "duration": "indefinitely",
                    "model_conf": 70.88
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.88,
                    "duration": "2 Months",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.13,
                    "duration": "indefinitely",
                    "model_conf": 89.94
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.67,
                    "duration": "indefinitely",
                    "model_conf": 152.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.8,
                    "duration": "indefinitely",
                    "model_conf": 262.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.41,
                    "duration": "indefinitely",
                    "model_conf": 162.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.66,
                    "duration": "indefinitely",
                    "model_conf": 157.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.17,
                    "duration": "indefinitely",
                    "model_conf": 153.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.6,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.21,
                    "duration": "indefinitely",
                    "model_conf": 84.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.25,
                    "duration": "indefinitely",
                    "model_conf": 52.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.29,
                    "duration": "indefinitely",
                    "model_conf": 205.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/warren/12-mt-bethel-rd.html": {
        "name": "Warren",
        "address": "12 Mt Bethel Rd, Warren, New Jersey, 07059",
        "phone": "(908) 412-6200",
        "url": "https://locations.massageenvy.com/new-jersey/warren/12-mt-bethel-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.616531,
            -74.494418
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.91,
                    "duration": "indefinitely",
                    "model_conf": 70.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.18,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.36,
                    "duration": "indefinitely",
                    "model_conf": 93.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 224.49,
                    "duration": "indefinitely",
                    "model_conf": 151.24
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.97,
                    "duration": "indefinitely",
                    "model_conf": 267.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.32,
                    "duration": "indefinitely",
                    "model_conf": 157.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.98,
                    "duration": "indefinitely",
                    "model_conf": 155.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 215.9,
                    "duration": "7 Months",
                    "model_conf": 151.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.17,
                    "duration": "4 Months",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.33,
                    "duration": "4 Months",
                    "model_conf": 83.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.77,
                    "duration": "6 Months",
                    "model_conf": 53.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.47,
                    "duration": "indefinitely",
                    "model_conf": 202.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/wayne/22-preakness-shopping-center.html": {
        "name": "Wayne",
        "address": "22 Preakness Shopping Center, Wayne, New Jersey, 07470",
        "phone": "(973) 686-3689",
        "url": "https://locations.massageenvy.com/new-jersey/wayne/22-preakness-shopping-center.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.962513985491036,
            -74.24401223659521
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.85,
                    "duration": "indefinitely",
                    "model_conf": 70.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.2,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.56,
                    "duration": "indefinitely",
                    "model_conf": 97.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.93,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.2,
                    "duration": "indefinitely",
                    "model_conf": 273.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.11,
                    "duration": "2 Months",
                    "model_conf": 164.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.6,
                    "duration": "indefinitely",
                    "model_conf": 162.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.43,
                    "duration": "3 Months",
                    "model_conf": 156.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.61,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 85.17
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 54.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.99,
                    "duration": "indefinitely",
                    "model_conf": 214.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-jersey/westwood/286-fairview-avenue.html": {
        "name": "Westwood-NJ",
        "address": "286 Fairview Avenue, Westwood, New Jersey, 07675",
        "phone": "(201) 722-0055",
        "url": "https://locations.massageenvy.com/new-jersey/westwood/286-fairview-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.992778,
            -74.031198
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.56,
                    "duration": "indefinitely",
                    "model_conf": 261.0
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.6,
                    "duration": "indefinitely",
                    "model_conf": 188.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.83,
                    "duration": "3 Months",
                    "model_conf": 160.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.81,
                    "duration": "indefinitely",
                    "model_conf": 156.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.95,
                    "duration": "indefinitely",
                    "model_conf": 152.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.32,
                    "duration": "indefinitely",
                    "model_conf": 218.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.48,
                    "duration": "indefinitely",
                    "model_conf": 84.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 52.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.57,
                    "duration": "indefinitely",
                    "model_conf": 204.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/farmington/4917-e-main-street.html": {
        "name": "Farmington",
        "address": "4917 E Main Street Ste C, Farmington, New Mexico, 87402",
        "phone": "(505) 326-3689",
        "url": "https://locations.massageenvy.com/new-mexico/farmington/4917-e-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.7664172159611,
            -108.14591980489354
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.19,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 98.87,
                    "duration": "4 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.56,
                    "duration": "3 Months",
                    "model_conf": 102.16
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.39,
                    "duration": "indefinitely",
                    "model_conf": 155.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.01,
                    "duration": "2 Months",
                    "model_conf": 281.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.46,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.23,
                    "duration": "6 Months",
                    "model_conf": 167.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.82,
                    "duration": "indefinitely",
                    "model_conf": 155.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.85,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.17,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.15,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.32,
                    "duration": "1 Months",
                    "model_conf": 221.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/las-cruces/2001-e-lohman.html": {
        "name": "Las Cruces",
        "address": "2001 E Lohman Suite 103, Las Cruces, New Mexico, 88011",
        "phone": "(575) 525-3689",
        "url": "https://locations.massageenvy.com/new-mexico/las-cruces/2001-e-lohman.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.3114084017139,
            -106.75702108258139
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.41,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.39,
                    "duration": "indefinitely",
                    "model_conf": 159.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.37,
                    "duration": "indefinitely",
                    "model_conf": 102.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 154.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.48,
                    "duration": "indefinitely",
                    "model_conf": 282.56
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.58,
                    "duration": "indefinitely",
                    "model_conf": 195.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.34,
                    "duration": "indefinitely",
                    "model_conf": 162.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.41,
                    "duration": "indefinitely",
                    "model_conf": 168.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.35,
                    "duration": "indefinitely",
                    "model_conf": 155.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.02,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.56,
                    "duration": "indefinitely",
                    "model_conf": 238.25
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 83.35
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.93,
                    "duration": "indefinitely",
                    "model_conf": 54.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.22,
                    "duration": "indefinitely",
                    "model_conf": 221.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/rio-rancho/4229-crestview-drive.html": {
        "name": "Rio Rancho East",
        "address": "4229 Crestview Drive, Rio Rancho, New Mexico, 87124",
        "phone": "(505) 892-3689",
        "url": "https://locations.massageenvy.com/new-mexico/rio-rancho/4229-crestview-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.2532546,
            -106.650711
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.76,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.85,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.16,
                    "duration": "indefinitely",
                    "model_conf": 97.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.72,
                    "duration": "indefinitely",
                    "model_conf": 272.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.77,
                    "duration": "indefinitely",
                    "model_conf": 166.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.15,
                    "duration": "indefinitely",
                    "model_conf": 163.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.5,
                    "duration": "1 Months",
                    "model_conf": 155.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.84,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 84.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.92,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 213.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/santa-fe/3490-zafarano-drive.html": {
        "name": "Santa Fe",
        "address": "3490 Zafarano Drive Ste A, Santa Fe, New Mexico, 87507",
        "phone": "(505) 471-3689",
        "url": "https://locations.massageenvy.com/new-mexico/santa-fe/3490-zafarano-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.643239,
            -106.013626
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.77,
                    "duration": "indefinitely",
                    "model_conf": 71.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.39,
                    "duration": "2 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.61,
                    "duration": "indefinitely",
                    "model_conf": 102.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.1,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.31,
                    "duration": "indefinitely",
                    "model_conf": 283.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.9,
                    "duration": "indefinitely",
                    "model_conf": 166.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.69,
                    "duration": "indefinitely",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.19,
                    "duration": "indefinitely",
                    "model_conf": 156.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.66,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.03,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.98,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.19,
                    "duration": "indefinitely",
                    "model_conf": 221.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/bayside/38-03-bell-boulevard.html": {
        "name": "Bayside",
        "address": "38-03 Bell Boulevard, Bayside, New York, 11361",
        "phone": "(718) 279-3689",
        "url": "https://locations.massageenvy.com/new-york/bayside/38-03-bell-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.766402,
            -73.772202
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.34,
                    "duration": "indefinitely",
                    "model_conf": 70.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.26,
                    "duration": "indefinitely",
                    "model_conf": 93.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.34,
                    "duration": "indefinitely",
                    "model_conf": 149.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.55,
                    "duration": "indefinitely",
                    "model_conf": 258.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.47,
                    "duration": "1 Months",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.45,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.27,
                    "duration": "indefinitely",
                    "model_conf": 148.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.34,
                    "duration": "1 Months",
                    "model_conf": 140.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.87,
                    "duration": "indefinitely",
                    "model_conf": 83.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.1,
                    "duration": "indefinitely",
                    "model_conf": 53.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.53,
                    "duration": "indefinitely",
                    "model_conf": 199.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/bedford-hills/737-bedford-road.html": {
        "name": "Bedford-Mt Kisco",
        "address": "737 Bedford Road, Bedford Hills, New York, 10507",
        "phone": "(914) 244-3689",
        "url": "https://locations.massageenvy.com/new-york/bedford-hills/737-bedford-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.2250177,
            -73.7148779
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.45,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 139.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 87.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.65,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.6,
                    "duration": "indefinitely",
                    "model_conf": 252.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 163.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 163.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.53,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.92,
                    "duration": "4 Months",
                    "model_conf": 140.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 83.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.32,
                    "duration": "indefinitely",
                    "model_conf": 55.16
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.84,
                    "duration": "indefinitely",
                    "model_conf": 216.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/brooklyn/470-4th-ave.html": {
        "name": "Park Slope - COMING SOON",
        "address": "470 4th Ave, Brooklyn, New York, 11215",
        "phone": "(718) 228-0102",
        "url": "https://locations.massageenvy.com/new-york/brooklyn/470-4th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.66958880000001,
            -73.9896356
        ],
        "rating": 0,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "LIMITED TIME OFFER: Save 20% on Jan Marini Skin Research\u00ae Products",
                "description": "The annual Thanks & Giving Sale is going on now through 11/28/22 at participating locations only, while supplies last. Excludes Starter Kit. Cannot be combined with any other promotional offer."
            },
            {
                "title": "CyMe\u2122\u2026 Try Me\u2026 Love Me!",
                "description": "Buy 2 CyMe\u2122 skin care products, get 1 FREE!\n\nNow through 12/31/22, get 1 FREE CyMe\u2122 product when you buy 2! While supplies last at participating locations only. Free CyMe\u2122 product will be the lowest priced."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.51,
                    "duration": "1 Months",
                    "model_conf": 68.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.27,
                    "duration": "4 Months",
                    "model_conf": 139.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.03,
                    "duration": "9 Months",
                    "model_conf": 86.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.12,
                    "duration": "indefinitely",
                    "model_conf": 147.34
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.36,
                    "duration": "9 Months",
                    "model_conf": 248.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 162.02,
                    "duration": "indefinitely",
                    "model_conf": 148.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.23,
                    "duration": "indefinitely",
                    "model_conf": 139.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.76,
                    "duration": "indefinitely",
                    "model_conf": 141.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.34,
                    "duration": "indefinitely",
                    "model_conf": 140.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.34,
                    "duration": "indefinitely",
                    "model_conf": 81.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.78,
                    "duration": "indefinitely",
                    "model_conf": 52.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.14,
                    "duration": "3 Months",
                    "model_conf": 175.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/clarence/4375-transit-road.html": {
        "name": "Main and Transit-Williamsville",
        "address": "4375 Transit Road, Clarence, New York, 14221",
        "phone": "(716) 633-6300",
        "url": "https://locations.massageenvy.com/new-york/clarence/4375-transit-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.9663977,
            -78.6961883
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.74,
                    "duration": "indefinitely",
                    "model_conf": 71.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.74,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.18,
                    "duration": "indefinitely",
                    "model_conf": 101.31
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.6,
                    "duration": "indefinitely",
                    "model_conf": 154.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.74,
                    "duration": "indefinitely",
                    "model_conf": 283.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.81,
                    "duration": "4 Months",
                    "model_conf": 165.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.86,
                    "duration": "indefinitely",
                    "model_conf": 164.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.32,
                    "duration": "3 Months",
                    "model_conf": 149.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.02,
                    "duration": "3 Months",
                    "model_conf": 85.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.4,
                    "duration": "4 Months",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.02,
                    "duration": "indefinitely",
                    "model_conf": 221.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/commack/5001-jericho-turnpike.html": {
        "name": "Commack",
        "address": "5001 Jericho Turnpike, Commack, New York, 11725",
        "phone": "(631) 499-3689",
        "url": "https://locations.massageenvy.com/new-york/commack/5001-jericho-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.8410191,
            -73.3128753
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 101.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.86,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.16,
                    "duration": "2 Months",
                    "model_conf": 280.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.36,
                    "duration": "4 Months",
                    "model_conf": 164.74
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.65,
                    "duration": "indefinitely",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.46,
                    "duration": "1 Months",
                    "model_conf": 157.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.06,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.91,
                    "duration": "indefinitely",
                    "model_conf": 218.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/depew/4900-transit-road.html": {
        "name": "Cheektowaga-Lancaster",
        "address": "4900 Transit Road Ste 300, Depew Depew, New York, 14043",
        "phone": "(716) 656-2000",
        "url": "https://locations.massageenvy.com/new-york/depew/4900-transit-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.87735278240975,
            -78.69789004325867
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.04,
                    "duration": "2 Months",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.38,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.45,
                    "duration": "11 Months",
                    "model_conf": 101.49
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.7,
                    "duration": "2 Months",
                    "model_conf": 154.39
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.7,
                    "duration": "8 Months",
                    "model_conf": 282.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.81,
                    "duration": "indefinitely",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 52.82,
                    "duration": "indefinitely",
                    "model_conf": 164.81
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.01,
                    "duration": "indefinitely",
                    "model_conf": 151.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.6,
                    "duration": "indefinitely",
                    "model_conf": 85.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.92,
                    "duration": "indefinitely",
                    "model_conf": 55.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.42,
                    "duration": "5 Months",
                    "model_conf": 221.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/farmingdale/224-airport-plaza-boulevard.html": {
        "name": "Farmingdale",
        "address": "224 Airport Plaza Boulevard, Farmingdale, New York, 11735",
        "phone": "(631) 393-2995",
        "url": "https://locations.massageenvy.com/new-york/farmingdale/224-airport-plaza-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.7375193,
            -73.4179079
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.55,
                    "duration": "1 Months",
                    "model_conf": 70.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.11,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.95,
                    "duration": "indefinitely",
                    "model_conf": 97.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.15,
                    "duration": "indefinitely",
                    "model_conf": 152.03
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 264.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.73,
                    "duration": "3 Months",
                    "model_conf": 148.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.7,
                    "duration": "indefinitely",
                    "model_conf": 160.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.29,
                    "duration": "4 Months",
                    "model_conf": 140.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 81.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 54.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.51,
                    "duration": "indefinitely",
                    "model_conf": 206.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/fayetteville/103-towne-drive.html": {
        "name": "Fayetteville-NY",
        "address": "103 Towne Drive, Fayetteville Fayetteville, New York, 13066",
        "phone": "(315) 329-5070",
        "url": "https://locations.massageenvy.com/new-york/fayetteville/103-towne-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.03556950386417,
            -76.01961225276682
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.95,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.15,
                    "duration": "indefinitely",
                    "model_conf": 102.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 154.39
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.52,
                    "duration": "indefinitely",
                    "model_conf": 282.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.5,
                    "duration": "1 Months",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.89,
                    "duration": "indefinitely",
                    "model_conf": 167.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.52,
                    "duration": "indefinitely",
                    "model_conf": 157.0
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.96,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.04,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 221.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/garden-city/680-stewart-avenue.html": {
        "name": "Garden City",
        "address": "680 Stewart Avenue, Garden City, New York, 11530",
        "phone": "(516) 227-3689",
        "url": "https://locations.massageenvy.com/new-york/garden-city/680-stewart-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.731681,
            -73.608626
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.96,
                    "duration": "indefinitely",
                    "model_conf": 68.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 139.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.33,
                    "duration": "indefinitely",
                    "model_conf": 71.51
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.21,
                    "duration": "indefinitely",
                    "model_conf": 140.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.87,
                    "duration": "indefinitely",
                    "model_conf": 232.13
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.13,
                    "duration": "8 Months",
                    "model_conf": 130.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 117.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.7,
                    "duration": "4 Months",
                    "model_conf": 132.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.73,
                    "duration": "indefinitely",
                    "model_conf": 139.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.19,
                    "duration": "2 Months",
                    "model_conf": 79.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 50.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.93,
                    "duration": "indefinitely",
                    "model_conf": 159.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/greece/2833-ridge-road-west.html": {
        "name": "Greece",
        "address": "2833 Ridge Road West Bldg 1, Unit 1C, Greece Greece, New York, 14626",
        "phone": "(585) 613-7700",
        "url": "https://locations.massageenvy.com/new-york/greece/2833-ridge-road-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.20905096653506,
            -77.70755492303459
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.99,
                    "duration": "3 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.71,
                    "duration": "2 Months",
                    "model_conf": 100.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.12,
                    "duration": "indefinitely",
                    "model_conf": 282.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.83,
                    "duration": "indefinitely",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.82,
                    "duration": "1 Months",
                    "model_conf": 167.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 157.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.77,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.8,
                    "duration": "indefinitely",
                    "model_conf": 85.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.69,
                    "duration": "2 Months",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 221.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/hamburg/3670-mckinley-parkway.html": {
        "name": "Hamburg-Orchard Park",
        "address": "3670 Mckinley Parkway Unit 5B, Hamburg Hamburg, New York, 14219",
        "phone": "(716) 822-3689",
        "url": "https://locations.massageenvy.com/new-york/hamburg/3670-mckinley-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.786178,
            -78.8125904
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.42,
                    "duration": "1 Months",
                    "model_conf": 70.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.27,
                    "duration": "2 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.92,
                    "duration": "7 Months",
                    "model_conf": 101.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.42,
                    "duration": "3 Months",
                    "model_conf": 154.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.24,
                    "duration": "2 Months",
                    "model_conf": 282.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.98,
                    "duration": "indefinitely",
                    "model_conf": 166.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.62,
                    "duration": "9 Months",
                    "model_conf": 164.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.27,
                    "duration": "indefinitely",
                    "model_conf": 155.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.85,
                    "duration": "indefinitely",
                    "model_conf": 85.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.85,
                    "duration": "1 Months",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.03,
                    "duration": "4 Months",
                    "model_conf": 221.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/lake-grove/133-alexander-avenue.html": {
        "name": "Lake Grove",
        "address": "133 Alexander Avenue, Lake Grove Lake Grove, New York, 11755",
        "phone": "(631) 361-2929",
        "url": "https://locations.massageenvy.com/new-york/lake-grove/133-alexander-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.8668141,
            -73.1331756
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 71.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.19,
                    "duration": "indefinitely",
                    "model_conf": 102.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.64,
                    "duration": "indefinitely",
                    "model_conf": 154.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.37,
                    "duration": "1 Months",
                    "model_conf": 276.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.53,
                    "duration": "indefinitely",
                    "model_conf": 165.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 167.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.72,
                    "duration": "indefinitely",
                    "model_conf": 155.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.4,
                    "duration": "1 Months",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 216.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/liverpool/3873-state-route-31.html": {
        "name": "Clay/Baldwinsville",
        "address": "3873 State Route 31 Suite 135, Liverpool Liverpool, New York, 13090",
        "phone": "(315) 652-6100",
        "url": "https://locations.massageenvy.com/new-york/liverpool/3873-state-route-31.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.186241,
            -76.245079
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.37,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.82,
                    "duration": "1 Months",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 154.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.06,
                    "duration": "2 Months",
                    "model_conf": 280.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.53,
                    "duration": "indefinitely",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.0,
                    "duration": "7 Months",
                    "model_conf": 167.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.69,
                    "duration": "indefinitely",
                    "model_conf": 157.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.48,
                    "duration": "1 Months",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.09,
                    "duration": "indefinitely",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.07,
                    "duration": "indefinitely",
                    "model_conf": 55.38
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.65,
                    "duration": "2 Months",
                    "model_conf": 220.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/merrick/1660-merrick-road.html": {
        "name": "Merrick",
        "address": "1660 Merrick Road, Merrick, New York, 11566",
        "phone": "(516) 623-3689",
        "url": "https://locations.massageenvy.com/new-york/merrick/1660-merrick-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.652479,
            -73.5593581
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.75,
                    "duration": "indefinitely",
                    "model_conf": 70.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.97,
                    "duration": "indefinitely",
                    "model_conf": 95.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.49,
                    "duration": "indefinitely",
                    "model_conf": 151.44
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.96,
                    "duration": "indefinitely",
                    "model_conf": 258.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.53,
                    "duration": "7 Months",
                    "model_conf": 155.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.87,
                    "duration": "indefinitely",
                    "model_conf": 156.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.79,
                    "duration": "4 Months",
                    "model_conf": 149.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.87,
                    "duration": "3 Months",
                    "model_conf": 83.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.3,
                    "duration": "4 Months",
                    "model_conf": 54.34
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.9,
                    "duration": "indefinitely",
                    "model_conf": 202.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/new-hyde-park/1476-union-turnpike.html": {
        "name": "Lake Success",
        "address": "1476 Union Turnpike Space Q, New Hyde Park New Hyde Park, New York, 11040",
        "phone": "(516) 673-4278",
        "url": "https://locations.massageenvy.com/new-york/new-hyde-park/1476-union-turnpike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.75326844233974,
            -73.69328331070989
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.0,
                    "duration": "indefinitely",
                    "model_conf": 68.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 139.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.84,
                    "duration": "indefinitely",
                    "model_conf": 75.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.14,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.34,
                    "duration": "indefinitely",
                    "model_conf": 234.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.01,
                    "duration": "5 Months",
                    "model_conf": 130.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.71,
                    "duration": "indefinitely",
                    "model_conf": 124.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.36,
                    "duration": "3 Months",
                    "model_conf": 135.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 139.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 79.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.1,
                    "duration": "5 Months",
                    "model_conf": 51.04
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.67,
                    "duration": "indefinitely",
                    "model_conf": 164.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/oceanside/3153-long-beach-road.html": {
        "name": "Oceanside",
        "address": "3153 Long Beach Road, Oceanside Oceanside, New York, 11572",
        "phone": "(516) 255-1919",
        "url": "https://locations.massageenvy.com/new-york/oceanside/3153-long-beach-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.63467795429048,
            -73.63969165545888
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.91,
                    "duration": "indefinitely",
                    "model_conf": 70.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.54,
                    "duration": "1 Months",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.71,
                    "duration": "indefinitely",
                    "model_conf": 96.85
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.53,
                    "duration": "indefinitely",
                    "model_conf": 151.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.67,
                    "duration": "indefinitely",
                    "model_conf": 262.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.56,
                    "duration": "7 Months",
                    "model_conf": 158.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.68,
                    "duration": "indefinitely",
                    "model_conf": 158.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.2,
                    "duration": "3 Months",
                    "model_conf": 151.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.92,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 84.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.66,
                    "duration": "2 Months",
                    "model_conf": 54.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.72,
                    "duration": "indefinitely",
                    "model_conf": 205.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/plainview/355-south-oyster-bay-road.html": {
        "name": "Plainview",
        "address": "355 South Oyster Bay Road, Plainview Plainview, New York, 11803",
        "phone": "(516) 513-1334",
        "url": "https://locations.massageenvy.com/new-york/plainview/355-south-oyster-bay-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.78619828290831,
            -73.50218221600801
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 70.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 139.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.32,
                    "duration": "indefinitely",
                    "model_conf": 92.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.71,
                    "duration": "indefinitely",
                    "model_conf": 149.34
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.71,
                    "duration": "indefinitely",
                    "model_conf": 252.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.98,
                    "duration": "6 Months",
                    "model_conf": 142.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.33,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.27,
                    "duration": "1 Months",
                    "model_conf": 147.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.07,
                    "duration": "indefinitely",
                    "model_conf": 140.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 80.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.59,
                    "duration": "2 Months",
                    "model_conf": 53.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.58,
                    "duration": "indefinitely",
                    "model_conf": 193.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/port-washington/47-main-street.html": {
        "name": "Port Washington",
        "address": "47 Main Street, Port Washington, New York, 11050",
        "phone": "(516) 767-6368",
        "url": "https://locations.massageenvy.com/new-york/port-washington/47-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.83098611788383,
            -73.68671234110451
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 70.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.11,
                    "duration": "indefinitely",
                    "model_conf": 91.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.63,
                    "duration": "indefinitely",
                    "model_conf": 150.19
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.61,
                    "duration": "indefinitely",
                    "model_conf": 261.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.06,
                    "duration": "2 Months",
                    "model_conf": 154.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.5,
                    "duration": "indefinitely",
                    "model_conf": 152.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.32,
                    "duration": "1 Months",
                    "model_conf": 148.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 140.52
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 83.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 54.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.52,
                    "duration": "indefinitely",
                    "model_conf": 201.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/rochester/3400-monroe-avenue.html": {
        "name": "Pittsford/Brighton",
        "address": "3400 Monroe Avenue, Rochester Rochester, New York, 14618",
        "phone": "(585) 641-3689",
        "url": "https://locations.massageenvy.com/new-york/rochester/3400-monroe-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.102444,
            -77.534566
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.39,
                    "duration": "2 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.89,
                    "duration": "indefinitely",
                    "model_conf": 98.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.56,
                    "duration": "indefinitely",
                    "model_conf": 153.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.74,
                    "duration": "indefinitely",
                    "model_conf": 268.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.22,
                    "duration": "2 Months",
                    "model_conf": 163.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.71,
                    "duration": "indefinitely",
                    "model_conf": 167.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.6,
                    "duration": "2 Months",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.97,
                    "duration": "3 Months",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.62,
                    "duration": "2 Months",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.91,
                    "duration": "indefinitely",
                    "model_conf": 221.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/rocky-point/346-route-25a.html": {
        "name": "Rocky Point",
        "address": "346 Route 25A Suite #106, Rocky Point, New York, 11778",
        "phone": "(631) 821-3999",
        "url": "https://locations.massageenvy.com/new-york/rocky-point/346-route-25a.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.9435493,
            -72.9415812
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.4,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 102.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 281.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 166.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.3,
                    "duration": "indefinitely",
                    "model_conf": 166.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.94,
                    "duration": "indefinitely",
                    "model_conf": 156.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.89,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 85.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.04,
                    "duration": "indefinitely",
                    "model_conf": 219.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/rye-brook/158-south-ridge-street.html": {
        "name": "Rye Brook",
        "address": "158 South Ridge Street, Rye Brook, New York, 10573",
        "phone": "(914) 417-6940",
        "url": "https://locations.massageenvy.com/new-york/rye-brook/158-south-ridge-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.00220875997942,
            -73.68195801973343
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.72,
                    "duration": "indefinitely",
                    "model_conf": 69.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 139.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.86,
                    "duration": "indefinitely",
                    "model_conf": 73.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.22,
                    "duration": "indefinitely",
                    "model_conf": 143.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.6,
                    "duration": "indefinitely",
                    "model_conf": 230.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.23,
                    "duration": "8 Months",
                    "model_conf": 131.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.04,
                    "duration": "indefinitely",
                    "model_conf": 125.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.76,
                    "duration": "2 Months",
                    "model_conf": 133.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.99,
                    "duration": "4 Months",
                    "model_conf": 139.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 79.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.44,
                    "duration": "3 Months",
                    "model_conf": 49.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 223.73,
                    "duration": "indefinitely",
                    "model_conf": 163.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/scarsdale/777-white-plains-road.html": {
        "name": "Scarsdale",
        "address": "777 White Plains Road, Scarsdale, New York, 10583",
        "phone": "(914) 902-9200",
        "url": "https://locations.massageenvy.com/new-york/scarsdale/777-white-plains-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            40.9747033,
            -73.8036676
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.99,
                    "duration": "indefinitely",
                    "model_conf": 70.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.77,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 82.09,
                    "duration": "indefinitely",
                    "model_conf": 86.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.76,
                    "duration": "indefinitely",
                    "model_conf": 148.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.66,
                    "duration": "indefinitely",
                    "model_conf": 255.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.91,
                    "duration": "5 Months",
                    "model_conf": 147.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 82.89,
                    "duration": "indefinitely",
                    "model_conf": 141.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.18,
                    "duration": "3 Months",
                    "model_conf": 144.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.24,
                    "duration": "1 Months",
                    "model_conf": 140.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.34,
                    "duration": "1 Months",
                    "model_conf": 82.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.15,
                    "duration": "4 Months",
                    "model_conf": 52.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 234.09,
                    "duration": "indefinitely",
                    "model_conf": 189.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/schenectady/410-balltown-rd.html": {
        "name": "Niskayuna",
        "address": "410 Balltown Rd, Schenectady Schenectady, New York, 12304",
        "phone": "(518) 370-3689",
        "url": "https://locations.massageenvy.com/new-york/schenectady/410-balltown-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.7731973,
            -73.8927654
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.4,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.86,
                    "duration": "4 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.92,
                    "duration": "indefinitely",
                    "model_conf": 101.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.62,
                    "duration": "indefinitely",
                    "model_conf": 279.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.39,
                    "duration": "indefinitely",
                    "model_conf": 166.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.79,
                    "duration": "1 Months",
                    "model_conf": 166.81
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.15,
                    "duration": "indefinitely",
                    "model_conf": 156.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.22,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.34,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.02,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.05,
                    "duration": "indefinitely",
                    "model_conf": 221.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/slingerlands/10-vista-boulevard.html": {
        "name": "Slingerlands",
        "address": "10 Vista Boulevard, Slingerlands Slingerlands, New York, 12159",
        "phone": "(518) 478-0900",
        "url": "https://locations.massageenvy.com/new-york/slingerlands/10-vista-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.640071,
            -73.8608692
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.14,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.44,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.37,
                    "duration": "11 Months",
                    "model_conf": 101.56
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.64,
                    "duration": "2 Months",
                    "model_conf": 154.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.19,
                    "duration": "7 Months",
                    "model_conf": 280.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 112.26,
                    "duration": "indefinitely",
                    "model_conf": 166.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 52.21,
                    "duration": "indefinitely",
                    "model_conf": 167.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.63,
                    "duration": "indefinitely",
                    "model_conf": 156.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.97,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.13,
                    "duration": "indefinitely",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.65,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.32,
                    "duration": "5 Months",
                    "model_conf": 219.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/staten-island/280-marsh-avenue.html": {
        "name": "Staten Island",
        "address": "280 Marsh Avenue, Staten Island, New York, 10314",
        "phone": "(718) 477-2800",
        "url": "https://locations.massageenvy.com/new-york/staten-island/280-marsh-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.5782854,
            -74.1639818
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "0",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": null,
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "35",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.16,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.09,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.24,
                    "duration": "indefinitely",
                    "model_conf": 99.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.53,
                    "duration": "indefinitely",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.25,
                    "duration": "1 Months",
                    "model_conf": 279.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 163.23
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.38,
                    "duration": "indefinitely",
                    "model_conf": 164.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.98,
                    "duration": "indefinitely",
                    "model_conf": 155.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.76,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.48,
                    "duration": "indefinitely",
                    "model_conf": 85.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.14,
                    "duration": "indefinitely",
                    "model_conf": 216.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/tonawanda/1154-niagara-falls-boulevard.html": {
        "name": "Amherst",
        "address": "1154 Niagara Falls Boulevard, Tonawanda Tonawanda, New York, 14150",
        "phone": "(716) 566-1000",
        "url": "https://locations.massageenvy.com/new-york/tonawanda/1154-niagara-falls-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.98556617358086,
            -78.82320921868086
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.67,
                    "duration": "1 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.07,
                    "duration": "6 Months",
                    "model_conf": 101.66
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.55,
                    "duration": "2 Months",
                    "model_conf": 154.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.15,
                    "duration": "2 Months",
                    "model_conf": 282.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.34,
                    "duration": "indefinitely",
                    "model_conf": 166.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.69,
                    "duration": "9 Months",
                    "model_conf": 165.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.89,
                    "duration": "indefinitely",
                    "model_conf": 153.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.94,
                    "duration": "indefinitely",
                    "model_conf": 85.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.65,
                    "duration": "2 Months",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.64,
                    "duration": "2 Months",
                    "model_conf": 221.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/victor/7553-commons-blvd.html": {
        "name": "Victor/Fairport",
        "address": "7553 Commons Blvd, Victor Victor, New York, 14564",
        "phone": "(585) 223-3689",
        "url": "https://locations.massageenvy.com/new-york/victor/7553-commons-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.033576,
            -77.4496045
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.76,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.75,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.07,
                    "duration": "6 Months",
                    "model_conf": 99.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.01,
                    "duration": "2 Months",
                    "model_conf": 154.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.48,
                    "duration": "1 Months",
                    "model_conf": 276.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.06,
                    "duration": "3 Months",
                    "model_conf": 166.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.12,
                    "duration": "6 Months",
                    "model_conf": 167.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 157.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.63,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.17,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.74,
                    "duration": "1 Months",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 220.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/webster/900-holt-road.html": {
        "name": "Webster/Penfield",
        "address": "900 Holt Road Bldg 1, Webster Webster, New York, 14580",
        "phone": "(585) 872-6772",
        "url": "https://locations.massageenvy.com/new-york/webster/900-holt-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.21305519487264,
            -77.44411075255539
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.02,
                    "duration": "3 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.5,
                    "duration": "3 Months",
                    "model_conf": 101.16
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 154.88
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.84,
                    "duration": "indefinitely",
                    "model_conf": 281.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.23,
                    "duration": "2 Months",
                    "model_conf": 166.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.0,
                    "duration": "indefinitely",
                    "model_conf": 163.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 157.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.6,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.86,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.57,
                    "duration": "3 Months",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 220.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/apex/1473-beaver-creek-commons-dr.html": {
        "name": "Apex Beaver Creek",
        "address": "1473 Beaver Creek Commons Dr, Apex, North Carolina, 27502",
        "phone": "(919) 303-3689",
        "url": "https://locations.massageenvy.com/north-carolina/apex/1473-beaver-creek-commons-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.744872,
            -78.884059
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.4,
                    "duration": "indefinitely",
                    "model_conf": 271.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.03,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.44,
                    "duration": "indefinitely",
                    "model_conf": 165.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.71,
                    "duration": "1 Months",
                    "model_conf": 150.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.89,
                    "duration": "indefinitely",
                    "model_conf": 144.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.01,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.62,
                    "duration": "indefinitely",
                    "model_conf": 215.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.29,
                    "duration": "1 Months",
                    "model_conf": 85.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.53,
                    "duration": "2 Months",
                    "model_conf": 53.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.61,
                    "duration": "indefinitely",
                    "model_conf": 203.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/burlington/1469-university-drive.html": {
        "name": "Burlington - NC",
        "address": "1469 University Drive, Burlington Burlington, North Carolina, 27215",
        "phone": "(336) 278-9000",
        "url": "https://locations.massageenvy.com/north-carolina/burlington/1469-university-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.06578753135388,
            -79.52103801324336
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 159.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.72,
                    "duration": "1 Months",
                    "model_conf": 102.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 154.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.44,
                    "duration": "4 Months",
                    "model_conf": 259.44
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.2,
                    "duration": "2 Months",
                    "model_conf": 194.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 166.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.39,
                    "duration": "3 Months",
                    "model_conf": 163.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.08,
                    "duration": "indefinitely",
                    "model_conf": 157.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.45,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.78,
                    "duration": "3 Months",
                    "model_conf": 236.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.72,
                    "duration": "2 Months",
                    "model_conf": 215.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/cary/1385-kildaire-farm-road.html": {
        "name": "Cary Kildaire",
        "address": "1385 Kildaire Farm Road, Cary Cary, North Carolina, 27511",
        "phone": "(919) 467-5400",
        "url": "https://locations.massageenvy.com/north-carolina/cary/1385-kildaire-farm-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.75925182344205,
            -78.7815059067459
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.22,
                    "duration": "1 Months",
                    "model_conf": 259.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.08,
                    "duration": "2 Months",
                    "model_conf": 176.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.08,
                    "duration": "indefinitely",
                    "model_conf": 164.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.69,
                    "duration": "6 Months",
                    "model_conf": 130.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.23,
                    "duration": "1 Months",
                    "model_conf": 133.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.93,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.06,
                    "duration": "2 Months",
                    "model_conf": 182.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.38,
                    "duration": "1 Months",
                    "model_conf": 84.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.72,
                    "duration": "1 Months",
                    "model_conf": 50.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.26,
                    "duration": "3 Months",
                    "model_conf": 179.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/chapel-hill/1800-e-franklin-st.html": {
        "name": "Chapel Hill",
        "address": "1800 E Franklin St Ste 8, Chapel Hill Chapel Hill, North Carolina, 27514",
        "phone": "(919) 442-0500",
        "url": "https://locations.massageenvy.com/north-carolina/chapel-hill/1800-e-franklin-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.9351785,
            -79.0253357
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.16,
                    "duration": "indefinitely",
                    "model_conf": 278.52
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.1,
                    "duration": "indefinitely",
                    "model_conf": 187.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.74,
                    "duration": "indefinitely",
                    "model_conf": 161.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.39,
                    "duration": "3 Months",
                    "model_conf": 157.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.15,
                    "duration": "indefinitely",
                    "model_conf": 155.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.3,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 229.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.87,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.14,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.36,
                    "duration": "1 Months",
                    "model_conf": 189.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/concord/6004-bayfield-parkway.html": {
        "name": "Concord",
        "address": "6004 Bayfield Parkway, Concord Concord, North Carolina, 28027",
        "phone": "(704) 721-0170",
        "url": "https://locations.massageenvy.com/north-carolina/concord/6004-bayfield-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.420080256806685,
            -80.67670541104508
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.54,
                    "duration": "indefinitely",
                    "model_conf": 279.94
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.33,
                    "duration": "indefinitely",
                    "model_conf": 191.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.94,
                    "duration": "indefinitely",
                    "model_conf": 163.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.92,
                    "duration": "indefinitely",
                    "model_conf": 166.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.53,
                    "duration": "indefinitely",
                    "model_conf": 155.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.43,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 234.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.09,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.08,
                    "duration": "indefinitely",
                    "model_conf": 54.94
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.01,
                    "duration": "indefinitely",
                    "model_conf": 218.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/denver/7218-hwy-73.html": {
        "name": "Denver NC",
        "address": "7218 Hwy 73 Suite 200, Denver, North Carolina, 28037",
        "phone": "(704) 809-1000",
        "url": "https://locations.massageenvy.com/north-carolina/denver/7218-hwy-73.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.44892312681113,
            -81.00285911665962
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 157.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.24,
                    "duration": "2 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.96,
                    "duration": "indefinitely",
                    "model_conf": 101.93
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.46,
                    "duration": "indefinitely",
                    "model_conf": 154.62
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.83,
                    "duration": "3 Months",
                    "model_conf": 280.04
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.65,
                    "duration": "1 Months",
                    "model_conf": 193.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.02,
                    "duration": "indefinitely",
                    "model_conf": 165.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.02,
                    "duration": "indefinitely",
                    "model_conf": 167.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 156.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.73,
                    "duration": "3 Months",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.07,
                    "duration": "2 Months",
                    "model_conf": 234.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.15,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.11,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.27,
                    "duration": "1 Months",
                    "model_conf": 218.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/gastonia/2290-e-franklin-blvd.html": {
        "name": "Gastonia",
        "address": "2290 E Franklin Blvd, Gastonia, North Carolina, 28054",
        "phone": "(704) 867-2290",
        "url": "https://locations.massageenvy.com/north-carolina/gastonia/2290-e-franklin-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.26199698476771,
            -81.13843194209039
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 158.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 100.56
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.2,
                    "duration": "4 Months",
                    "model_conf": 281.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 192.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 165.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.43,
                    "duration": "3 Months",
                    "model_conf": 168.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.33,
                    "duration": "indefinitely",
                    "model_conf": 157.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.74,
                    "duration": "3 Months",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.58,
                    "duration": "4 Months",
                    "model_conf": 236.59
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.52,
                    "duration": "3 Months",
                    "model_conf": 219.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/greensboro/2619-lawndale-drive.html": {
        "name": "Greensboro",
        "address": "2619 Lawndale Drive, Greensboro Greensboro, North Carolina, 27408",
        "phone": "(336) 286-1308",
        "url": "https://locations.massageenvy.com/north-carolina/greensboro/2619-lawndale-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            36.102198762817835,
            -79.8208824677273
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 70.7
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 158.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.55,
                    "duration": "indefinitely",
                    "model_conf": 100.54
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 154.5
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.99,
                    "duration": "indefinitely",
                    "model_conf": 275.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 193.93
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.72,
                    "duration": "3 Months",
                    "model_conf": 166.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.65,
                    "duration": "indefinitely",
                    "model_conf": 167.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.7,
                    "duration": "2 Months",
                    "model_conf": 157.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.85,
                    "duration": "3 Months",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.9,
                    "duration": "indefinitely",
                    "model_conf": 238.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.91,
                    "duration": "3 Months",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.44,
                    "duration": "3 Months",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.59,
                    "duration": "indefinitely",
                    "model_conf": 217.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/greenville/205-se-greenville-blvd.html": {
        "name": "Greenville",
        "address": "205 SE Greenville Blvd, Greenville, North Carolina, 27858",
        "phone": "(252) 756-3182",
        "url": "https://locations.massageenvy.com/north-carolina/greenville/205-se-greenville-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.580018606828915,
            -77.37909346818924
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.01,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 153.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 101.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 154.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 282.78
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 194.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 167.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 157.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 236.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 217.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/hickory/2262-hwy-70-se.html": {
        "name": "Hickory",
        "address": "2262 Hwy 70 SE, Hickory, North Carolina, 28602",
        "phone": "(828) 322-7001",
        "url": "https://locations.massageenvy.com/north-carolina/hickory/2262-hwy-70-se.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.7026384,
            -81.2963007
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.9,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.73,
                    "duration": "4 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.78,
                    "duration": "1 Months",
                    "model_conf": 102.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.27,
                    "duration": "indefinitely",
                    "model_conf": 155.07
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.63,
                    "duration": "3 Months",
                    "model_conf": 283.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.5,
                    "duration": "indefinitely",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.71,
                    "duration": "1 Months",
                    "model_conf": 168.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.73,
                    "duration": "indefinitely",
                    "model_conf": 157.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.0,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 86.02
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.97,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.46,
                    "duration": "1 Months",
                    "model_conf": 217.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/high-point/1589-skeet-club-rd.html": {
        "name": "High Point",
        "address": "1589 Skeet Club Rd Ste #137, High Point, North Carolina, 27265",
        "phone": "(336) 882-3689",
        "url": "https://locations.massageenvy.com/north-carolina/high-point/1589-skeet-club-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            36.032956371088275,
            -79.96890194463049
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 70.83
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 158.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.61,
                    "duration": "indefinitely",
                    "model_conf": 100.43
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.0,
                    "duration": "indefinitely",
                    "model_conf": 154.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.17,
                    "duration": "indefinitely",
                    "model_conf": 275.05
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.08,
                    "duration": "indefinitely",
                    "model_conf": 193.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.69,
                    "duration": "3 Months",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.99,
                    "duration": "indefinitely",
                    "model_conf": 167.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.86,
                    "duration": "2 Months",
                    "model_conf": 157.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.88,
                    "duration": "3 Months",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.17,
                    "duration": "indefinitely",
                    "model_conf": 238.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.03,
                    "duration": "2 Months",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.46,
                    "duration": "3 Months",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.76,
                    "duration": "indefinitely",
                    "model_conf": 218.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/holly-springs/177-grand-hill-place.html": {
        "name": "Holly Springs",
        "address": "177 Grand Hill Place, Holly Springs, North Carolina, 27540",
        "phone": "(919) 577-1500",
        "url": "https://locations.massageenvy.com/north-carolina/holly-springs/177-grand-hill-place.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.662044,
            -78.848145
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.48,
                    "duration": "indefinitely",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.5,
                    "duration": "indefinitely",
                    "model_conf": 154.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.65,
                    "duration": "1 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 56.43,
                    "duration": "indefinitely",
                    "model_conf": 97.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.32,
                    "duration": "indefinitely",
                    "model_conf": 277.45
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.26,
                    "duration": "indefinitely",
                    "model_conf": 189.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 107.92,
                    "duration": "7 Months",
                    "model_conf": 165.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 57.17,
                    "duration": "indefinitely",
                    "model_conf": 154.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.29,
                    "duration": "5 Months",
                    "model_conf": 147.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.51,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.83,
                    "duration": "indefinitely",
                    "model_conf": 226.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.7,
                    "duration": "4 Months",
                    "model_conf": 85.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.18,
                    "duration": "5 Months",
                    "model_conf": 55.04
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.01,
                    "duration": "indefinitely",
                    "model_conf": 212.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/huntersville/16933-kaufinger-street.html": {
        "name": "Huntersville",
        "address": "16933 Kaufinger Street Ste 110 & 120, Huntersville, North Carolina, 28078",
        "phone": "(704) 896-8881",
        "url": "https://locations.massageenvy.com/north-carolina/huntersville/16933-kaufinger-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.443099,
            -80.857443
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.31,
                    "duration": "3 Months",
                    "model_conf": 281.35
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.06,
                    "duration": "2 Months",
                    "model_conf": 192.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.42,
                    "duration": "indefinitely",
                    "model_conf": 162.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.16,
                    "duration": "4 Months",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.55,
                    "duration": "indefinitely",
                    "model_conf": 155.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.46,
                    "duration": "1 Months",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.79,
                    "duration": "3 Months",
                    "model_conf": 234.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.49,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.37,
                    "duration": "indefinitely",
                    "model_conf": 55.05
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.38,
                    "duration": "2 Months",
                    "model_conf": 219.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/indian-trail/6431-old-monroe-rd.html": {
        "name": "Indian Trail",
        "address": "6431 Old Monroe Rd Suites E & F, Indian Trail Indian Trail, North Carolina, 28079",
        "phone": "(704) 980-0240",
        "url": "https://locations.massageenvy.com/north-carolina/indian-trail/6431-old-monroe-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.050447,
            -80.646633
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.44,
                    "duration": "indefinitely",
                    "model_conf": 70.87
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.75,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.52,
                    "duration": "indefinitely",
                    "model_conf": 100.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.63,
                    "duration": "indefinitely",
                    "model_conf": 153.67
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.11,
                    "duration": "indefinitely",
                    "model_conf": 277.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 163.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.48,
                    "duration": "indefinitely",
                    "model_conf": 164.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.09,
                    "duration": "indefinitely",
                    "model_conf": 155.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.96,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.15,
                    "duration": "indefinitely",
                    "model_conf": 84.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.92,
                    "duration": "indefinitely",
                    "model_conf": 55.29
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.83,
                    "duration": "indefinitely",
                    "model_conf": 218.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/jacksonville/3494-western-blvd..html": {
        "name": "Jacksonville",
        "address": "3494 Western Blvd. Ste 120, Jacksonville, North Carolina, 28546",
        "phone": "(252) 509-0799",
        "url": "https://locations.massageenvy.com/north-carolina/jacksonville/3494-western-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            34.7974962,
            -77.4121717
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.46,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 159.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 101.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 154.94
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.51,
                    "duration": "indefinitely",
                    "model_conf": 281.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 195.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.38,
                    "duration": "indefinitely",
                    "model_conf": 167.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 230.25
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 85.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 221.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/matthews/2233-matthews-township-parkway.html": {
        "name": "Matthews-Mint Hill",
        "address": "2233 Matthews Township Parkway Suite H-1, Matthews, North Carolina, 28105",
        "phone": "(704) 846-3689",
        "url": "https://locations.massageenvy.com/north-carolina/matthews/2233-matthews-township-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.128156,
            -80.702289
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.55,
                    "duration": "indefinitely",
                    "model_conf": 69.17
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 146.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.95,
                    "duration": "indefinitely",
                    "model_conf": 91.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.49,
                    "duration": "indefinitely",
                    "model_conf": 149.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.31,
                    "duration": "indefinitely",
                    "model_conf": 257.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.75,
                    "duration": "indefinitely",
                    "model_conf": 179.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 152.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.15,
                    "duration": "indefinitely",
                    "model_conf": 150.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 150.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.65,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.82,
                    "duration": "indefinitely",
                    "model_conf": 209.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.68,
                    "duration": "indefinitely",
                    "model_conf": 82.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 54.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.72,
                    "duration": "indefinitely",
                    "model_conf": 218.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/mooresville/591-river-highway.html": {
        "name": "Mooresville",
        "address": "591 River Highway, Mooresville Mooresville, North Carolina, 28117",
        "phone": "(704) 664-3090",
        "url": "https://locations.massageenvy.com/north-carolina/mooresville/591-river-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.59379411484977,
            -80.87183070478198
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.83,
                    "duration": "3 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 101.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 282.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 168.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 157.27
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 220.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/morrisville/1001-market-center-dr.html": {
        "name": "Morrisville Park West Village",
        "address": "1001 Market Center Dr Ste 30, Morrisville Morrisville, North Carolina, 27560",
        "phone": "(919) 469-3689",
        "url": "https://locations.massageenvy.com/north-carolina/morrisville/1001-market-center-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.80737946532276,
            -78.81487763871962
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.07,
                    "duration": "indefinitely",
                    "model_conf": 255.87
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.88,
                    "duration": "indefinitely",
                    "model_conf": 174.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.6,
                    "duration": "1 Months",
                    "model_conf": 164.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.61,
                    "duration": "2 Months",
                    "model_conf": 124.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.2,
                    "duration": "1 Months",
                    "model_conf": 128.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.53,
                    "duration": "1 Months",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.84,
                    "duration": "indefinitely",
                    "model_conf": 173.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.47,
                    "duration": "1 Months",
                    "model_conf": 84.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.65,
                    "duration": "1 Months",
                    "model_conf": 50.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.38,
                    "duration": "1 Months",
                    "model_conf": 175.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/southern-pines/10564-us-hwy-15-501.html": {
        "name": "Pinehurst-Southern Pines",
        "address": "10564 US Hwy 15-501, Southern Pines, North Carolina, 28387",
        "phone": "(910) 693-1001",
        "url": "https://locations.massageenvy.com/north-carolina/southern-pines/10564-us-hwy-15-501.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.170496,
            -79.425418
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.88,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.35,
                    "duration": "indefinitely",
                    "model_conf": 159.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.75,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.07,
                    "duration": "indefinitely",
                    "model_conf": 101.64
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.97,
                    "duration": "indefinitely",
                    "model_conf": 154.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.08,
                    "duration": "indefinitely",
                    "model_conf": 277.86
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.88,
                    "duration": "indefinitely",
                    "model_conf": 195.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.35,
                    "duration": "indefinitely",
                    "model_conf": 165.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.89,
                    "duration": "indefinitely",
                    "model_conf": 157.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.53,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.1,
                    "duration": "indefinitely",
                    "model_conf": 232.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 86.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.85,
                    "duration": "indefinitely",
                    "model_conf": 203.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/wake-forest/12610-capital-blvd.html": {
        "name": "Wake Forest",
        "address": "12610 Capital Blvd Ste. 104, Wake Forest Wake Forest, North Carolina, 27587",
        "phone": "(919) 569-3689",
        "url": "https://locations.massageenvy.com/north-carolina/wake-forest/12610-capital-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.98846757553592,
            -78.52962970733643
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.48,
                    "duration": "indefinitely",
                    "model_conf": 278.56
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.91,
                    "duration": "indefinitely",
                    "model_conf": 191.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.35,
                    "duration": "1 Months",
                    "model_conf": 162.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.6,
                    "duration": "indefinitely",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.39,
                    "duration": "1 Months",
                    "model_conf": 152.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.44,
                    "duration": "1 Months",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.31,
                    "duration": "indefinitely",
                    "model_conf": 232.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.38,
                    "duration": "indefinitely",
                    "model_conf": 85.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.3,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.6,
                    "duration": "indefinitely",
                    "model_conf": 217.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/waxhaw/8139-kensington-drive.html": {
        "name": "Weddington/Waxhaw",
        "address": "8139 Kensington Drive, Waxhaw Waxhaw, North Carolina, 28173",
        "phone": "(704) 243-1111",
        "url": "https://locations.massageenvy.com/north-carolina/waxhaw/8139-kensington-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.95457956614932,
            -80.75976666151041
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.65,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.57,
                    "duration": "1 Months",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.92,
                    "duration": "indefinitely",
                    "model_conf": 100.49
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.13,
                    "duration": "indefinitely",
                    "model_conf": 153.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.71,
                    "duration": "indefinitely",
                    "model_conf": 278.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.69,
                    "duration": "indefinitely",
                    "model_conf": 164.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.67,
                    "duration": "2 Months",
                    "model_conf": 165.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.49,
                    "duration": "indefinitely",
                    "model_conf": 156.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.67,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.1,
                    "duration": "indefinitely",
                    "model_conf": 85.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.27,
                    "duration": "indefinitely",
                    "model_conf": 55.38
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.12,
                    "duration": "indefinitely",
                    "model_conf": 217.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/avon/35825-detroit-road.html": {
        "name": "Avon-OH",
        "address": "35825 Detroit Road Ste 105, Avon, Ohio, 44011",
        "phone": "(440) 937-0877",
        "url": "https://locations.massageenvy.com/ohio/avon/35825-detroit-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.458141,
            -82.016648
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 157.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 101.04
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 152.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 281.24
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 187.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.84,
                    "duration": "indefinitely",
                    "model_conf": 163.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.26,
                    "duration": "4 Months",
                    "model_conf": 166.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.22,
                    "duration": "indefinitely",
                    "model_conf": 156.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 234.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 84.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 217.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/beachwood/2101-richmond-road.html": {
        "name": "Beachwood",
        "address": "2101 Richmond Road, Beachwood, Ohio, 44122",
        "phone": "(216) 593-0140",
        "url": "https://locations.massageenvy.com/ohio/beachwood/2101-richmond-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.5006644,
            -81.4961195
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.4,
                    "duration": "indefinitely",
                    "model_conf": 281.3
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.21,
                    "duration": "indefinitely",
                    "model_conf": 189.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.66,
                    "duration": "3 Months",
                    "model_conf": 162.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.32,
                    "duration": "indefinitely",
                    "model_conf": 167.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 197.66,
                    "duration": "4 Months",
                    "model_conf": 157.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.17,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.56,
                    "duration": "indefinitely",
                    "model_conf": 224.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.92,
                    "duration": "2 Months",
                    "model_conf": 84.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.39,
                    "duration": "3 Months",
                    "model_conf": 55.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.85,
                    "duration": "indefinitely",
                    "model_conf": 219.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/canton/4470-everhard-rd-nw.html": {
        "name": "Belden Village",
        "address": "4470 Everhard Rd NW, Canton, Ohio, 44718",
        "phone": "(330) 526-0444",
        "url": "https://locations.massageenvy.com/ohio/canton/4470-everhard-rd-nw.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            40.858415520249146,
            -81.43173500317936
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 69.48
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.88,
                    "duration": "indefinitely",
                    "model_conf": 134.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.99,
                    "duration": "3 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 95.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.88,
                    "duration": "indefinitely",
                    "model_conf": 151.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.47,
                    "duration": "indefinitely",
                    "model_conf": 277.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 155.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.68,
                    "duration": "3 Months",
                    "model_conf": 164.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.71,
                    "duration": "indefinitely",
                    "model_conf": 141.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.87,
                    "duration": "2 Months",
                    "model_conf": 149.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.55,
                    "duration": "1 Months",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.77,
                    "duration": "indefinitely",
                    "model_conf": 235.25
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.08,
                    "duration": "2 Months",
                    "model_conf": 83.19
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.27,
                    "duration": "4 Months",
                    "model_conf": 49.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.62,
                    "duration": "indefinitely",
                    "model_conf": 206.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/columbus/767-bethel-road.html": {
        "name": "Bethel",
        "address": "767 Bethel Road, Columbus, Ohio, 43214",
        "phone": "(614) 326-3689",
        "url": "https://locations.massageenvy.com/ohio/columbus/767-bethel-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.0625729,
            -83.0408356
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.99,
                    "duration": "indefinitely",
                    "model_conf": 259.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.71,
                    "duration": "indefinitely",
                    "model_conf": 172.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 149.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.72,
                    "duration": "2 Months",
                    "model_conf": 145.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.49,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.55,
                    "duration": "indefinitely",
                    "model_conf": 198.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 82.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 52.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.51,
                    "duration": "indefinitely",
                    "model_conf": 195.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/dublin/357-west-bridge-street.html": {
        "name": "Dublin",
        "address": "357 West Bridge Street, Dublin, Ohio, 43017",
        "phone": "(614) 792-3689",
        "url": "https://locations.massageenvy.com/ohio/dublin/357-west-bridge-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.09798,
            -83.123885
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 70.44
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.05,
                    "duration": "indefinitely",
                    "model_conf": 150.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.44,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.64,
                    "duration": "indefinitely",
                    "model_conf": 92.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.47,
                    "duration": "indefinitely",
                    "model_conf": 151.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 270.08
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 174.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 158.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 156.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.67,
                    "duration": "indefinitely",
                    "model_conf": 150.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.6,
                    "duration": "3 Months",
                    "model_conf": 140.6
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 196.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 84.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 53.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.42,
                    "duration": "indefinitely",
                    "model_conf": 202.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/fairlawn/3265-w-market-street.html": {
        "name": "Fairlawn",
        "address": "3265 W Market Street, Fairlawn, Ohio, 44333",
        "phone": "(330) 867-3689",
        "url": "https://locations.massageenvy.com/ohio/fairlawn/3265-w-market-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.133196,
            -81.617321
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.06,
                    "duration": "indefinitely",
                    "model_conf": 281.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.58,
                    "duration": "indefinitely",
                    "model_conf": 194.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.96,
                    "duration": "2 Months",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.87,
                    "duration": "indefinitely",
                    "model_conf": 168.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.05,
                    "duration": "indefinitely",
                    "model_conf": 157.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.3,
                    "duration": "1 Months",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.96,
                    "duration": "indefinitely",
                    "model_conf": 237.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.46,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.93,
                    "duration": "indefinitely",
                    "model_conf": 219.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/gahanna/1319-stoneridge-dr.html": {
        "name": "Gahanna",
        "address": "1319 Stoneridge Dr, Gahanna, Ohio, 43230",
        "phone": "(614) 476-3689",
        "url": "https://locations.massageenvy.com/ohio/gahanna/1319-stoneridge-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.053096,
            -82.867355
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.42,
                    "duration": "indefinitely",
                    "model_conf": 274.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.97,
                    "duration": "indefinitely",
                    "model_conf": 188.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 162.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.34,
                    "duration": "indefinitely",
                    "model_conf": 156.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.31,
                    "duration": "1 Months",
                    "model_conf": 149.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.25,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.78,
                    "duration": "indefinitely",
                    "model_conf": 227.52
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 84.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 54.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.2,
                    "duration": "indefinitely",
                    "model_conf": 213.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/grandview-heights/775-yard-street.html": {
        "name": "Grandview Yard",
        "address": "775 Yard Street, Grandview Heights, Ohio, 43212",
        "phone": "(614) 484-6860",
        "url": "https://locations.massageenvy.com/ohio/grandview-heights/775-yard-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.97463,
            -83.026431
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 67.39
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 117.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.85,
                    "duration": "indefinitely",
                    "model_conf": 139.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.32,
                    "duration": "indefinitely",
                    "model_conf": 66.66
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.73,
                    "duration": "indefinitely",
                    "model_conf": 139.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 221.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 144.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.28,
                    "duration": "2 Months",
                    "model_conf": 128.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.6,
                    "duration": "indefinitely",
                    "model_conf": 109.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.75,
                    "duration": "indefinitely",
                    "model_conf": 127.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 159.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.79,
                    "duration": "indefinitely",
                    "model_conf": 78.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.23,
                    "duration": "indefinitely",
                    "model_conf": 48.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.01,
                    "duration": "indefinitely",
                    "model_conf": 149.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/grove-city/5907-hoover-road.html": {
        "name": "Grove City",
        "address": "5907 Hoover Road, Grove City Grove City, Ohio, 43123",
        "phone": "(614) 871-3689",
        "url": "https://locations.massageenvy.com/ohio/grove-city/5907-hoover-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.842322,
            -83.073938
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.5,
                    "duration": "1 Months",
                    "model_conf": 69.55
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 151.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.41,
                    "duration": "3 Months",
                    "model_conf": 96.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.49,
                    "duration": "1 Months",
                    "model_conf": 153.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.27,
                    "duration": "4 Months",
                    "model_conf": 275.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.94,
                    "duration": "6 Months",
                    "model_conf": 190.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 164.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.64,
                    "duration": "8 Months",
                    "model_conf": 161.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.4,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.45,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.76,
                    "duration": "3 Months",
                    "model_conf": 230.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 84.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 54.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.79,
                    "duration": "indefinitely",
                    "model_conf": 217.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/hamilton/3417-princeton-road.html": {
        "name": "Bridgewater Falls",
        "address": "3417 Princeton Road Ste 127, Hamilton Hamilton, Ohio, 45011",
        "phone": "(513) 868-3689",
        "url": "https://locations.massageenvy.com/ohio/hamilton/3417-princeton-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.3886294,
            -84.5016411
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Skin Care Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 156.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 101.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 281.15
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 189.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 166.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 163.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.15,
                    "duration": "indefinitely",
                    "model_conf": 156.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 236.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 84.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.19,
                    "duration": "indefinitely",
                    "model_conf": 54.29
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 219.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/hilliard/3965-trueman-blvd.html": {
        "name": "Hilliard",
        "address": "3965 Trueman Blvd, Hilliard, Ohio, 43026",
        "phone": "(614) 771-6860",
        "url": "https://locations.massageenvy.com/ohio/hilliard/3965-trueman-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.034373,
            -83.118802
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 70.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.83,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.67,
                    "duration": "indefinitely",
                    "model_conf": 90.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.23,
                    "duration": "indefinitely",
                    "model_conf": 150.26
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.09,
                    "duration": "indefinitely",
                    "model_conf": 265.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.51,
                    "duration": "1 Months",
                    "model_conf": 156.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.32,
                    "duration": "indefinitely",
                    "model_conf": 151.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.94,
                    "duration": "indefinitely",
                    "model_conf": 148.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.72,
                    "duration": "indefinitely",
                    "model_conf": 83.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.15,
                    "duration": "indefinitely",
                    "model_conf": 53.3
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.88,
                    "duration": "indefinitely",
                    "model_conf": 200.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/lewis-center/6497-pullman-dr.html": {
        "name": "Lewis Center",
        "address": "6497 Pullman Dr, Lewis Center, Ohio, 43035",
        "phone": "(740) 657-1200",
        "url": "https://locations.massageenvy.com/ohio/lewis-center/6497-pullman-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.20149994707752,
            -83.02632629871368
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.33,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.64,
                    "duration": "1 Months",
                    "model_conf": 155.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.56,
                    "duration": "2 Months",
                    "model_conf": 95.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 153.24
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.31,
                    "duration": "1 Months",
                    "model_conf": 279.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 185.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 163.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 161.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.07,
                    "duration": "indefinitely",
                    "model_conf": 152.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.84,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 216.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 85.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 54.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 213.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/macedonia/650-e-aurora-road.html": {
        "name": "Macedonia",
        "address": "650 E Aurora Road Unit B, Macedonia Macedonia, Ohio, 44056",
        "phone": "(330) 748-0151",
        "url": "https://locations.massageenvy.com/ohio/macedonia/650-e-aurora-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.313571,
            -81.515457
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.13,
                    "duration": "indefinitely",
                    "model_conf": 281.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.42,
                    "duration": "indefinitely",
                    "model_conf": 192.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.61,
                    "duration": "indefinitely",
                    "model_conf": 165.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.75,
                    "duration": "1 Months",
                    "model_conf": 168.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.46,
                    "duration": "indefinitely",
                    "model_conf": 157.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.28,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.13,
                    "duration": "indefinitely",
                    "model_conf": 232.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.29,
                    "duration": "indefinitely",
                    "model_conf": 85.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.17,
                    "duration": "indefinitely",
                    "model_conf": 55.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.39,
                    "duration": "1 Months",
                    "model_conf": 219.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/mason/5525-deerfield-boulevard.html": {
        "name": "Mason",
        "address": "5525 Deerfield Boulevard, Mason Mason, Ohio, 45040",
        "phone": "(513) 770-0190",
        "url": "https://locations.massageenvy.com/ohio/mason/5525-deerfield-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.306868,
            -84.317787
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.76,
                    "duration": "3 Months",
                    "model_conf": 158.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.69,
                    "duration": "6 Months",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 98.89
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.76,
                    "duration": "2 Months",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.49,
                    "duration": "indefinitely",
                    "model_conf": 275.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 190.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.87,
                    "duration": "indefinitely",
                    "model_conf": 165.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.81,
                    "duration": "7 Months",
                    "model_conf": 146.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.87,
                    "duration": "indefinitely",
                    "model_conf": 154.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.24,
                    "duration": "2 Months",
                    "model_conf": 235.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 52.29
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.09,
                    "duration": "1 Months",
                    "model_conf": 217.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/maumee/2100-oak-street.html": {
        "name": "Maumee",
        "address": "2100 Oak Street Ste 400, Maumee Maumee, Ohio, 43537",
        "phone": "(419) 458-7570",
        "url": "https://locations.massageenvy.com/ohio/maumee/2100-oak-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.547067807748746,
            -83.70659599999999
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.62,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 158.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.43,
                    "duration": "4 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 102.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.29,
                    "duration": "indefinitely",
                    "model_conf": 277.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.17,
                    "duration": "indefinitely",
                    "model_conf": 191.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.41,
                    "duration": "indefinitely",
                    "model_conf": 165.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.35,
                    "duration": "1 Months",
                    "model_conf": 155.12
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.66,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.23,
                    "duration": "indefinitely",
                    "model_conf": 235.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 85.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.19,
                    "duration": "indefinitely",
                    "model_conf": 220.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/mentor/9666-mentor-avenue.html": {
        "name": "Mentor",
        "address": "9666 Mentor Avenue, Mentor Mentor, Ohio, 44060",
        "phone": "(440) 352-6666",
        "url": "https://locations.massageenvy.com/ohio/mentor/9666-mentor-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.683904,
            -81.293497
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 158.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.45,
                    "duration": "3 Months",
                    "model_conf": 101.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.81,
                    "duration": "indefinitely",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.99,
                    "duration": "5 Months",
                    "model_conf": 281.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.97,
                    "duration": "3 Months",
                    "model_conf": 194.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.57,
                    "duration": "indefinitely",
                    "model_conf": 166.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.1,
                    "duration": "5 Months",
                    "model_conf": 168.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.72,
                    "duration": "indefinitely",
                    "model_conf": 157.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.36,
                    "duration": "4 Months",
                    "model_conf": 237.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.48,
                    "duration": "3 Months",
                    "model_conf": 220.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/miamisburg/9536-springboro-pike.html": {
        "name": "The Exchange",
        "address": "9536 Springboro Pike, Miamisburg, Ohio, 45342",
        "phone": "(937) 424-5888",
        "url": "https://locations.massageenvy.com/ohio/miamisburg/9536-springboro-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.6120525,
            -84.2269671
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 70.89
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 158.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 101.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 154.71
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.36,
                    "duration": "1 Months",
                    "model_conf": 273.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 192.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 166.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.34,
                    "duration": "3 Months",
                    "model_conf": 162.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.61,
                    "duration": "indefinitely",
                    "model_conf": 157.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.19,
                    "duration": "2 Months",
                    "model_conf": 231.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.2,
                    "duration": "indefinitely",
                    "model_conf": 55.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.3,
                    "duration": "1 Months",
                    "model_conf": 202.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/pickerington/1686-hill-road-north.html": {
        "name": "Pickerington",
        "address": "1686 Hill Road North, Pickerington Pickerington, Ohio, 43147",
        "phone": "(614) 863-3689",
        "url": "https://locations.massageenvy.com/ohio/pickerington/1686-hill-road-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.9248023,
            -82.7841586
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.35,
                    "duration": "indefinitely",
                    "model_conf": 277.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 193.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.12,
                    "duration": "2 Months",
                    "model_conf": 164.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 163.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.87,
                    "duration": "2 Months",
                    "model_conf": 154.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.97,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.65,
                    "duration": "indefinitely",
                    "model_conf": 235.41
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 85.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.97,
                    "duration": "indefinitely",
                    "model_conf": 219.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/powell/4028-powell-road.html": {
        "name": "Powell",
        "address": "4028 Powell Road, Powell Powell, Ohio, 43065",
        "phone": "(614) 734-3689",
        "url": "https://locations.massageenvy.com/ohio/powell/4028-powell-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.160879,
            -83.09261
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.26,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.25,
                    "duration": "indefinitely",
                    "model_conf": 139.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.8,
                    "duration": "indefinitely",
                    "model_conf": 97.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 153.15
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.11,
                    "duration": "2 Months",
                    "model_conf": 276.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 182.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 161.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.5,
                    "duration": "indefinitely",
                    "model_conf": 162.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.59,
                    "duration": "indefinitely",
                    "model_conf": 153.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.18,
                    "duration": "2 Months",
                    "model_conf": 140.56
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 207.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 84.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 54.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 210.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/strongsville/6-southpark-center.html": {
        "name": "Strongsville",
        "address": "6 Southpark Center, Strongsville Strongsville, Ohio, 44136",
        "phone": "(440) 878-0500",
        "url": "https://locations.massageenvy.com/ohio/strongsville/6-southpark-center.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.309591,
            -81.820736
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 156.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 100.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.06,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.75,
                    "duration": "3 Months",
                    "model_conf": 278.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 188.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.61,
                    "duration": "4 Months",
                    "model_conf": 162.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.87,
                    "duration": "indefinitely",
                    "model_conf": 162.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 156.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.14,
                    "duration": "4 Months",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 236.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.93,
                    "duration": "indefinitely",
                    "model_conf": 85.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.38,
                    "duration": "1 Months",
                    "model_conf": 213.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/toledo/3305-west-central-ave.html": {
        "name": "Toledo",
        "address": "3305 West Central Ave Unit A2, Toledo Toledo, Ohio, 43606",
        "phone": "(419) 535-3689",
        "url": "https://locations.massageenvy.com/ohio/toledo/3305-west-central-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.67674462440184,
            -83.6232404593942
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.65,
                    "duration": "indefinitely",
                    "model_conf": 71.43
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 158.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.74,
                    "duration": "3 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.76,
                    "duration": "indefinitely",
                    "model_conf": 101.9
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.53,
                    "duration": "indefinitely",
                    "model_conf": 281.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.88,
                    "duration": "indefinitely",
                    "model_conf": 190.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.99,
                    "duration": "3 Months",
                    "model_conf": 164.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.08,
                    "duration": "indefinitely",
                    "model_conf": 161.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.04,
                    "duration": "4 Months",
                    "model_conf": 150.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.6,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.29,
                    "duration": "indefinitely",
                    "model_conf": 232.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.03,
                    "duration": "2 Months",
                    "model_conf": 84.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.71,
                    "duration": "indefinitely",
                    "model_conf": 219.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/west-chester-township/7639-voice-of-america-center-drive.html": {
        "name": "West Chester",
        "address": "7639 Voice Of America Center Drive, West Chester Township West Chester Township, Ohio, 45069",
        "phone": "(513) 779-7444",
        "url": "https://locations.massageenvy.com/ohio/west-chester-township/7639-voice-of-america-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.35623669337092,
            -84.36369242944772
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.53,
                    "duration": "1 Months",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 158.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 98.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.77,
                    "duration": "2 Months",
                    "model_conf": 155.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.78,
                    "duration": "indefinitely",
                    "model_conf": 276.16
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.49,
                    "duration": "indefinitely",
                    "model_conf": 191.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 165.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 147.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.68,
                    "duration": "2 Months",
                    "model_conf": 154.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.61,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 235.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.56,
                    "duration": "1 Months",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 52.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.28,
                    "duration": "indefinitely",
                    "model_conf": 217.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/westerville/668-n-state-street.html": {
        "name": "Westerville",
        "address": "668 N State Street, Westerville, Ohio, 43082",
        "phone": "(614) 818-3689",
        "url": "https://locations.massageenvy.com/ohio/westerville/668-n-state-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.143716151081115,
            -82.92331393005941
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.66,
                    "duration": "indefinitely",
                    "model_conf": 277.03
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.17,
                    "duration": "indefinitely",
                    "model_conf": 187.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 163.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 160.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 154.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.79,
                    "duration": "indefinitely",
                    "model_conf": 223.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 85.2
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 54.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.51,
                    "duration": "indefinitely",
                    "model_conf": 213.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/westlake/30307-detroit-road.html": {
        "name": "Westlake -OH",
        "address": "30307 Detroit Road, Westlake, Ohio, 44145",
        "phone": "(440) 808-8000",
        "url": "https://locations.massageenvy.com/ohio/westlake/30307-detroit-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.462212,
            -81.95591
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 281.41
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 186.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 163.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 166.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 156.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/oklahoma/owasso/9455-n-owasso-expressway.html": {
        "name": "Owasso",
        "address": "9455 N Owasso Expressway Ste G, Owasso, Oklahoma, 74055",
        "phone": "(918) 274-3689",
        "url": "https://locations.massageenvy.com/oklahoma/owasso/9455-n-owasso-expressway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.289957,
            -95.835814
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.98,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.92,
                    "duration": "3 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 102.31
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.26,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.57,
                    "duration": "1 Months",
                    "model_conf": 280.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 165.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.15,
                    "duration": "indefinitely",
                    "model_conf": 167.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.19,
                    "duration": "indefinitely",
                    "model_conf": 157.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 85.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.02,
                    "duration": "indefinitely",
                    "model_conf": 221.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/beaverton/2680-sw-cedar-hills-blvd.html": {
        "name": "Cedar Hills",
        "address": "2680 SW Cedar Hills Blvd, Beaverton, Oregon, 97005",
        "phone": "(503) 726-3689",
        "url": "https://locations.massageenvy.com/oregon/beaverton/2680-sw-cedar-hills-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.500333,
            -122.804957
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.52,
                    "duration": "indefinitely",
                    "model_conf": 263.33
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.28,
                    "duration": "indefinitely",
                    "model_conf": 175.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.65,
                    "duration": "8 Months",
                    "model_conf": 156.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 77.75,
                    "duration": "indefinitely",
                    "model_conf": 148.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 216.95,
                    "duration": "5 Months",
                    "model_conf": 147.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.4,
                    "duration": "indefinitely",
                    "model_conf": 140.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.23,
                    "duration": "indefinitely",
                    "model_conf": 227.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.22,
                    "duration": "2 Months",
                    "model_conf": 81.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.4,
                    "duration": "3 Months",
                    "model_conf": 53.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.41,
                    "duration": "indefinitely",
                    "model_conf": 213.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/clackamas/9895-se-sunnyside-road.html": {
        "name": "Clackamas",
        "address": "9895 SE Sunnyside Road Suite K, Clackamas, Oregon, 97015",
        "phone": "(503) 653-0400",
        "url": "https://locations.massageenvy.com/oregon/clackamas/9895-se-sunnyside-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "11:00 AM - 5:00 PM"
        },
        "geolocation": [
            45.43438083365935,
            -122.56202280521393
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.32,
                    "duration": "indefinitely",
                    "model_conf": 275.13
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 187.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 163.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.01,
                    "duration": "indefinitely",
                    "model_conf": 161.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.25,
                    "duration": "1 Months",
                    "model_conf": 153.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.21,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.87,
                    "duration": "indefinitely",
                    "model_conf": 233.58
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.5,
                    "duration": "1 Months",
                    "model_conf": 84.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.75,
                    "duration": "1 Months",
                    "model_conf": 55.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.18,
                    "duration": "indefinitely",
                    "model_conf": 218.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/keizer/6395-keizer-station-blvd.html": {
        "name": "Keizer",
        "address": "6395 Keizer Station Blvd #103, Keizer Keizer, Oregon, 97303",
        "phone": "(503) 589-1597",
        "url": "https://locations.massageenvy.com/oregon/keizer/6395-keizer-station-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 2:00 PM",
            "SUNDAY": "10:00 AM - 4:30 PM"
        },
        "geolocation": [
            45.011545,
            -122.996457
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.87,
                    "duration": "9 Months",
                    "model_conf": 280.53
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.25,
                    "duration": "7 Months",
                    "model_conf": 194.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.65,
                    "duration": "indefinitely",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.08,
                    "duration": "10 Months",
                    "model_conf": 163.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.12,
                    "duration": "indefinitely",
                    "model_conf": 157.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.84,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.55,
                    "duration": "9 Months",
                    "model_conf": 238.31
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.28,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.83,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.33,
                    "duration": "4 Months",
                    "model_conf": 220.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/sherwood/16078-sw-tualatin-sherwood-road.html": {
        "name": "Sherwood",
        "address": "16078 SW Tualatin-Sherwood Road, Sherwood, Oregon, 97140",
        "phone": "(503) 625-0100",
        "url": "https://locations.massageenvy.com/oregon/sherwood/16078-sw-tualatin-sherwood-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.368374,
            -122.844307
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.52,
                    "duration": "indefinitely",
                    "model_conf": 266.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.42,
                    "duration": "indefinitely",
                    "model_conf": 189.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.49,
                    "duration": "indefinitely",
                    "model_conf": 163.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.42,
                    "duration": "indefinitely",
                    "model_conf": 159.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.98,
                    "duration": "indefinitely",
                    "model_conf": 154.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.62,
                    "duration": "indefinitely",
                    "model_conf": 140.64
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.83,
                    "duration": "indefinitely",
                    "model_conf": 232.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.5,
                    "duration": "1 Months",
                    "model_conf": 84.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.57,
                    "duration": "2 Months",
                    "model_conf": 54.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.41,
                    "duration": "indefinitely",
                    "model_conf": 214.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/allentown/1898-catasauqua-rd..html": {
        "name": "Airport Road Allentown",
        "address": "1898 Catasauqua Rd. Unit 2, Allentown, Pennsylvania, 18109",
        "phone": "(610) 231-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/allentown/1898-catasauqua-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.640452,
            -75.429074
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.31,
                    "duration": "indefinitely",
                    "model_conf": 71.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.69,
                    "duration": "indefinitely",
                    "model_conf": 139.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.98,
                    "duration": "indefinitely",
                    "model_conf": 102.04
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.1,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.94,
                    "duration": "indefinitely",
                    "model_conf": 281.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.26,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.47,
                    "duration": "4 Months",
                    "model_conf": 162.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.19,
                    "duration": "indefinitely",
                    "model_conf": 156.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.24,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.32,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.29,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.82,
                    "duration": "indefinitely",
                    "model_conf": 220.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/ardmore/44-greenfield-ave.html": {
        "name": "Ardmore",
        "address": "44 Greenfield Ave, Ardmore, Pennsylvania, 19003",
        "phone": "(484) 413-2944",
        "url": "https://locations.massageenvy.com/pennsylvania/ardmore/44-greenfield-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.009113,
            -75.296916
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.94,
                    "duration": "indefinitely",
                    "model_conf": 280.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.03,
                    "duration": "indefinitely",
                    "model_conf": 164.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.98,
                    "duration": "indefinitely",
                    "model_conf": 164.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 156.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.72,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.06,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.54,
                    "duration": "3 Months",
                    "model_conf": 54.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 215.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/bethel-park/5223-library-road.html": {
        "name": "South Park-PA",
        "address": "5223 Library Road, Bethel Park Bethel Park, Pennsylvania, 15102",
        "phone": "(412) 854-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/bethel-park/5223-library-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.333133,
            -80.022897
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.67,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.92,
                    "duration": "indefinitely",
                    "model_conf": 100.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.79,
                    "duration": "indefinitely",
                    "model_conf": 150.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.97,
                    "duration": "indefinitely",
                    "model_conf": 279.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.93,
                    "duration": "indefinitely",
                    "model_conf": 166.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.37,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.88,
                    "duration": "indefinitely",
                    "model_conf": 152.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.31,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 83.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.5,
                    "duration": "indefinitely",
                    "model_conf": 217.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/collegeville/161-market-street.html": {
        "name": "Collegeville",
        "address": "161 Market Street Suite 3, Collegeville Collegeville, Pennsylvania, 19426",
        "phone": "(610) 409-2525",
        "url": "https://locations.massageenvy.com/pennsylvania/collegeville/161-market-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "8:30 AM - 6:00 PM"
        },
        "geolocation": [
            40.165904156726036,
            -75.47966447029876
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.25,
                    "duration": "2 Months",
                    "model_conf": 71.1
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.36,
                    "duration": "4 Months",
                    "model_conf": 154.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.75,
                    "duration": "7 Months",
                    "model_conf": 99.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.63,
                    "duration": "2 Months",
                    "model_conf": 153.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.34,
                    "duration": "9 Months",
                    "model_conf": 280.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.18,
                    "duration": "8 Months",
                    "model_conf": 194.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.31,
                    "duration": "indefinitely",
                    "model_conf": 161.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.64,
                    "duration": "indefinitely",
                    "model_conf": 158.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.36,
                    "duration": "indefinitely",
                    "model_conf": 156.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.42,
                    "duration": "1 Months",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.03,
                    "duration": "8 Months",
                    "model_conf": 233.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.08,
                    "duration": "indefinitely",
                    "model_conf": 55.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.91,
                    "duration": "6 Months",
                    "model_conf": 209.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/cranberry-township/1694-route-228.html": {
        "name": "Cranberry",
        "address": "1694 Route 228 Suite 108, Cranberry Township, Pennsylvania, 16066",
        "phone": "(724) 772-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/cranberry-township/1694-route-228.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.6849157,
            -80.0814853
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.52,
                    "duration": "1 Months",
                    "model_conf": 70.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 156.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.85,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.94,
                    "duration": "indefinitely",
                    "model_conf": 86.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.87,
                    "duration": "indefinitely",
                    "model_conf": 148.44
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.14,
                    "duration": "4 Months",
                    "model_conf": 230.14
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 194.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.3,
                    "duration": "indefinitely",
                    "model_conf": 139.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.86,
                    "duration": "indefinitely",
                    "model_conf": 140.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 148.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.05,
                    "duration": "2 Months",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.88,
                    "duration": "3 Months",
                    "model_conf": 214.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.09,
                    "duration": "indefinitely",
                    "model_conf": 84.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.5,
                    "duration": "indefinitely",
                    "model_conf": 48.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.68,
                    "duration": "2 Months",
                    "model_conf": 187.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/erie/2070-interchange-rd..html": {
        "name": "Erie",
        "address": "2070 Interchange Rd. Unit 220, Erie Erie, Pennsylvania, 16565",
        "phone": "(814) 866-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/erie/2070-interchange-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.065333957240675,
            -80.1006343960762
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 156.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.0,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.33,
                    "duration": "indefinitely",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.4,
                    "duration": "indefinitely",
                    "model_conf": 283.25
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 194.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.24,
                    "duration": "indefinitely",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.23,
                    "duration": "indefinitely",
                    "model_conf": 168.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.11,
                    "duration": "indefinitely",
                    "model_conf": 157.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.07,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.65,
                    "duration": "indefinitely",
                    "model_conf": 229.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.18,
                    "duration": "indefinitely",
                    "model_conf": 85.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.07,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.21,
                    "duration": "indefinitely",
                    "model_conf": 221.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/exton/282-main-st.html": {
        "name": "Exton",
        "address": "282 Main St Ste K1, Exton, Pennsylvania, 19341",
        "phone": "(610) 280-7600",
        "url": "https://locations.massageenvy.com/pennsylvania/exton/282-main-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.023131337486056,
            -75.62857397375981
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 70.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.37,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.35,
                    "duration": "indefinitely",
                    "model_conf": 102.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 152.43
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 281.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.13,
                    "duration": "3 Months",
                    "model_conf": 156.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.34,
                    "duration": "indefinitely",
                    "model_conf": 145.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.15,
                    "duration": "indefinitely",
                    "model_conf": 157.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 140.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.12,
                    "duration": "indefinitely",
                    "model_conf": 85.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.26,
                    "duration": "indefinitely",
                    "model_conf": 54.94
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 184.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/gibsonia/5375-william-flynn-hwy.html": {
        "name": "Richland Mall - Gibsonia",
        "address": "5375 William Flynn Hwy, Gibsonia, Pennsylvania, 15044",
        "phone": "(724) 443-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/gibsonia/5375-william-flynn-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.6195401,
            -79.9472534
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.42,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 100.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 273.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 163.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 163.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 155.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.15,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.19,
                    "duration": "indefinitely",
                    "model_conf": 54.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 216.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/greensburg/1420-greengate-center-cir.html": {
        "name": "Greensburg",
        "address": "1420 Greengate Center Cir, Greensburg, Pennsylvania, 15601",
        "phone": "(724) 832-8383",
        "url": "https://locations.massageenvy.com/pennsylvania/greensburg/1420-greengate-center-cir.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.3071165,
            -79.5788481
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 101.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.66,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.87,
                    "duration": "3 Months",
                    "model_conf": 282.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 165.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.36,
                    "duration": "3 Months",
                    "model_conf": 163.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.95,
                    "duration": "indefinitely",
                    "model_conf": 157.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.48,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.15,
                    "duration": "1 Months",
                    "model_conf": 219.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/jenkintown/1647-the-fairway.html": {
        "name": "Jenkintown",
        "address": "1647 The Fairway, Jenkintown, Pennsylvania, 19046",
        "phone": "(215) 887-0100",
        "url": "https://locations.massageenvy.com/pennsylvania/jenkintown/1647-the-fairway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.1073139,
            -75.1184645
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Skin Care Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Skin Care Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.13,
                    "duration": "indefinitely",
                    "model_conf": 71.07
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.35,
                    "duration": "indefinitely",
                    "model_conf": 158.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 100.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.43,
                    "duration": "indefinitely",
                    "model_conf": 154.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.76,
                    "duration": "indefinitely",
                    "model_conf": 279.13
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 193.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.85,
                    "duration": "3 Months",
                    "model_conf": 164.74
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.12,
                    "duration": "indefinitely",
                    "model_conf": 162.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 156.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.34,
                    "duration": "1 Months",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 235.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 85.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.38
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.54,
                    "duration": "indefinitely",
                    "model_conf": 214.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/levittown/1327-e-lincoln-hwy.html": {
        "name": "Levittown - Langhorne",
        "address": "1327 E Lincoln Hwy, Levittown, Pennsylvania, 19056",
        "phone": "(267) 460-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/levittown/1327-e-lincoln-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "1:00 PM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.1677531,
            -74.8942147
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 101.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.23,
                    "duration": "1 Months",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 280.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 165.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 166.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.81,
                    "duration": "indefinitely",
                    "model_conf": 157.24
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.89,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 55.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 218.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/mcmurray/4120-washington-road.html": {
        "name": "McMurray",
        "address": "4120 Washington Road #130, McMurray McMurray, Pennsylvania, 15317",
        "phone": "(724) 942-0600",
        "url": "https://locations.massageenvy.com/pennsylvania/mcmurray/4120-washington-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.26486745379546,
            -80.13081565352206
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.98,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.45,
                    "duration": "indefinitely",
                    "model_conf": 101.63
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 153.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 281.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 165.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.89,
                    "duration": "indefinitely",
                    "model_conf": 167.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.89,
                    "duration": "indefinitely",
                    "model_conf": 156.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.32,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 219.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/monroeville/4124-william-penn-hwy.html": {
        "name": "Monroeville",
        "address": "4124 William Penn Hwy, Monroeville, Pennsylvania, 15146",
        "phone": "(412) 856-2001",
        "url": "https://locations.massageenvy.com/pennsylvania/monroeville/4124-william-penn-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.4373103,
            -79.7654785
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.93,
                    "duration": "indefinitely",
                    "model_conf": 101.15
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 153.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.9,
                    "duration": "indefinitely",
                    "model_conf": 280.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.31,
                    "duration": "2 Months",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.91,
                    "duration": "indefinitely",
                    "model_conf": 167.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.36,
                    "duration": "1 Months",
                    "model_conf": 155.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 84.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.75,
                    "duration": "1 Months",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.17,
                    "duration": "indefinitely",
                    "model_conf": 219.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/north-wales/1460-bethlehem-pike.html": {
        "name": "North Wales",
        "address": "1460 Bethlehem Pike Suite 300, North Wales North Wales, Pennsylvania, 19454",
        "phone": "(267) 708-1090",
        "url": "https://locations.massageenvy.com/pennsylvania/north-wales/1460-bethlehem-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.2078664,
            -75.2233061
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 157.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 99.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 154.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 279.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 193.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 163.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.12,
                    "duration": "indefinitely",
                    "model_conf": 160.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 155.71
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 236.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 214.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/reading/718-woodland-road.html": {
        "name": "Wyomissing",
        "address": "718 Woodland Road, Reading, Pennsylvania, 19610",
        "phone": "(484) 926-2730",
        "url": "https://locations.massageenvy.com/pennsylvania/reading/718-woodland-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            40.3509848,
            -75.9658755
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.67,
                    "duration": "1 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.87,
                    "duration": "indefinitely",
                    "model_conf": 102.6
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.7,
                    "duration": "indefinitely",
                    "model_conf": 280.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.44,
                    "duration": "1 Months",
                    "model_conf": 166.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.35,
                    "duration": "4 Months",
                    "model_conf": 167.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 156.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.47,
                    "duration": "1 Months",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.83,
                    "duration": "1 Months",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 221.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/southampton/498-2nd-street-pike.html": {
        "name": "Southampton",
        "address": "498 2nd Street Pike, Southampton Southampton, Pennsylvania, 18966",
        "phone": "(215) 355-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/southampton/498-2nd-street-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 4:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            40.16994292869809,
            -75.04618809898602
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.23,
                    "duration": "indefinitely",
                    "model_conf": 71.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.69,
                    "duration": "indefinitely",
                    "model_conf": 100.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.75,
                    "duration": "indefinitely",
                    "model_conf": 154.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.2,
                    "duration": "indefinitely",
                    "model_conf": 279.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.51,
                    "duration": "4 Months",
                    "model_conf": 165.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.58,
                    "duration": "indefinitely",
                    "model_conf": 163.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 156.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 85.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 55.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.35,
                    "duration": "indefinitely",
                    "model_conf": 217.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/springfield/1001-baltimore-pike.html": {
        "name": "Springfield - PA",
        "address": "1001 Baltimore Pike Ste 3, Springfield, Pennsylvania, 19064",
        "phone": "(610) 544-2020",
        "url": "https://locations.massageenvy.com/pennsylvania/springfield/1001-baltimore-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            39.9132895,
            -75.3415191
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 71.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.52,
                    "duration": "1 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.36,
                    "duration": "indefinitely",
                    "model_conf": 101.91
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 154.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.67,
                    "duration": "indefinitely",
                    "model_conf": 281.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.87,
                    "duration": "indefinitely",
                    "model_conf": 165.4
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.84,
                    "duration": "1 Months",
                    "model_conf": 165.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.47,
                    "duration": "indefinitely",
                    "model_conf": 156.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.9,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.41,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.91,
                    "duration": "indefinitely",
                    "model_conf": 54.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.49,
                    "duration": "indefinitely",
                    "model_conf": 218.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/warrington/1603-main-street.html": {
        "name": "Warrington",
        "address": "1603 Main Street Bldg 11, Warrington Warrington, Pennsylvania, 18976",
        "phone": "(215) 343-3688",
        "url": "https://locations.massageenvy.com/pennsylvania/warrington/1603-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.232982,
            -75.135073
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.66,
                    "duration": "indefinitely",
                    "model_conf": 158.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.87,
                    "duration": "indefinitely",
                    "model_conf": 98.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.89,
                    "duration": "indefinitely",
                    "model_conf": 154.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.01,
                    "duration": "indefinitely",
                    "model_conf": 280.11
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.45,
                    "duration": "indefinitely",
                    "model_conf": 194.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.37,
                    "duration": "4 Months",
                    "model_conf": 163.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.9,
                    "duration": "indefinitely",
                    "model_conf": 161.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.13,
                    "duration": "1 Months",
                    "model_conf": 155.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.75,
                    "duration": "indefinitely",
                    "model_conf": 235.7
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.63,
                    "duration": "indefinitely",
                    "model_conf": 214.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/wayne/283-e-swedesford-road.html": {
        "name": "Wayne-PA",
        "address": "283 E Swedesford Road, Wayne, Pennsylvania, 19087",
        "phone": "(484) 584-4997",
        "url": "https://locations.massageenvy.com/pennsylvania/wayne/283-e-swedesford-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.0739549,
            -75.4303671
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.18,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.66,
                    "duration": "1 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.2,
                    "duration": "indefinitely",
                    "model_conf": 100.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 153.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.23,
                    "duration": "indefinitely",
                    "model_conf": 279.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.56,
                    "duration": "1 Months",
                    "model_conf": 162.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.16,
                    "duration": "indefinitely",
                    "model_conf": 159.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.21,
                    "duration": "1 Months",
                    "model_conf": 156.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.52,
                    "duration": "1 Months",
                    "model_conf": 84.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.66,
                    "duration": "2 Months",
                    "model_conf": 55.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 210.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/wexford/1500-village-run-rd.html": {
        "name": "Wexford",
        "address": "1500 Village Run Rd Suite 312, Wexford Wexford, Pennsylvania, 15090",
        "phone": "(724) 934-3689",
        "url": "https://locations.massageenvy.com/pennsylvania/wexford/1500-village-run-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.6335820042376,
            -80.0580345263428
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 70.87
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 93.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 151.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.98,
                    "duration": "2 Months",
                    "model_conf": 255.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 152.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.29,
                    "duration": "4 Months",
                    "model_conf": 153.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.75,
                    "duration": "indefinitely",
                    "model_conf": 152.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.27,
                    "duration": "1 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 84.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.12,
                    "duration": "indefinitely",
                    "model_conf": 52.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.46,
                    "duration": "1 Months",
                    "model_conf": 202.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/rhode-island/cranston/1000-chapel-view-blvd.html": {
        "name": "Cranston",
        "address": "1000 Chapel View Blvd Ste 146, Cranston Cranston, Rhode Island, 02920",
        "phone": "(401) 275-4900",
        "url": "https://locations.massageenvy.com/rhode-island/cranston/1000-chapel-view-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.75697508082779,
            -71.46062828071058
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 100.54
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.74,
                    "duration": "indefinitely",
                    "model_conf": 154.48
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.74,
                    "duration": "3 Months",
                    "model_conf": 251.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 164.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 162.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.9,
                    "duration": "indefinitely",
                    "model_conf": 157.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.15,
                    "duration": "2 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 85.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.35,
                    "duration": "indefinitely",
                    "model_conf": 53.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.64,
                    "duration": "indefinitely",
                    "model_conf": 199.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/rhode-island/east-greenwich/1000-division-st.html": {
        "name": "East Greenwich",
        "address": "1000 Division St, East Greenwich East Greenwich, Rhode Island, 02818",
        "phone": "(401) 336-2900",
        "url": "https://locations.massageenvy.com/rhode-island/east-greenwich/1000-division-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:30 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.66187208627056,
            -71.49496032307081
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.18,
                    "duration": "2 Months",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 139.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 101.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.21,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.25,
                    "duration": "7 Months",
                    "model_conf": 273.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 165.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 161.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.98,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.52,
                    "duration": "3 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.57,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 55.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.19,
                    "duration": "1 Months",
                    "model_conf": 214.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/rhode-island/east-providence/5-catamore-blvd.html": {
        "name": "East Providence",
        "address": "5 Catamore Blvd, East Providence East Providence, Rhode Island, 02914",
        "phone": "(401) 438-1181",
        "url": "https://locations.massageenvy.com/rhode-island/east-providence/5-catamore-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 4:00 PM",
            "SATURDAY": "9:00 AM - 3:30 PM",
            "SUNDAY": "9:00 AM - 3:30 PM"
        },
        "geolocation": [
            41.80626373058328,
            -71.34186229669467
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.76,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.61,
                    "duration": "2 Months",
                    "model_conf": 101.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.07,
                    "duration": "indefinitely",
                    "model_conf": 154.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.45,
                    "duration": "6 Months",
                    "model_conf": 269.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 166.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 166.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.22,
                    "duration": "indefinitely",
                    "model_conf": 157.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.84,
                    "duration": "3 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.15,
                    "duration": "indefinitely",
                    "model_conf": 85.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.5,
                    "duration": "indefinitely",
                    "model_conf": 217.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/rhode-island/lincoln/618-george-washington-hwy.html": {
        "name": "Lincoln - RI",
        "address": "618 George Washington Hwy Suite C, Lincoln, Rhode Island, 02865",
        "phone": "(401) 334-3689",
        "url": "https://locations.massageenvy.com/rhode-island/lincoln/618-george-washington-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            41.93583812874658,
            -71.48057512670289
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.06,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.37,
                    "duration": "3 Months",
                    "model_conf": 101.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.81,
                    "duration": "2 Months",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.75,
                    "duration": "3 Months",
                    "model_conf": 276.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.06,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.5,
                    "duration": "9 Months",
                    "model_conf": 167.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 157.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 55.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.38,
                    "duration": "3 Months",
                    "model_conf": 218.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/rhode-island/providence/229-waterman-street.html": {
        "name": "Providence",
        "address": "229 Waterman Street, Providence, Rhode Island, 02906",
        "phone": "(401) 455-3689",
        "url": "https://locations.massageenvy.com/rhode-island/providence/229-waterman-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.8284176,
            -71.3880674
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.92,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.15,
                    "duration": "indefinitely",
                    "model_conf": 101.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.89,
                    "duration": "indefinitely",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.53,
                    "duration": "3 Months",
                    "model_conf": 265.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.82,
                    "duration": "3 Months",
                    "model_conf": 166.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.92,
                    "duration": "indefinitely",
                    "model_conf": 166.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.41,
                    "duration": "indefinitely",
                    "model_conf": 157.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.49,
                    "duration": "4 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.56,
                    "duration": "indefinitely",
                    "model_conf": 85.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.41,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.75,
                    "duration": "indefinitely",
                    "model_conf": 216.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/beaufort/330-robert-smalls-pkwy..html": {
        "name": "Beaufort",
        "address": "330 Robert Smalls Pkwy. Ste. 17, Beaufort, South Carolina, 29906",
        "phone": "(843) 470-1254",
        "url": "https://locations.massageenvy.com/south-carolina/beaufort/330-robert-smalls-pkwy..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.42623479201723,
            -80.73195199999998
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.0,
                    "duration": "indefinitely",
                    "model_conf": 282.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.5,
                    "duration": "indefinitely",
                    "model_conf": 195.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.43,
                    "duration": "indefinitely",
                    "model_conf": 168.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.18,
                    "duration": "indefinitely",
                    "model_conf": 157.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.42,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.46,
                    "duration": "indefinitely",
                    "model_conf": 237.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.95,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.43,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.19,
                    "duration": "indefinitely",
                    "model_conf": 220.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/bluffton/1019-fording-island-rd.html": {
        "name": "Bluffton",
        "address": "1019 Fording Island Rd Ste 102G, Bluffton Bluffton, South Carolina, 29910",
        "phone": "(843) 837-3689",
        "url": "https://locations.massageenvy.com/south-carolina/bluffton/1019-fording-island-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.257101,
            -80.849025
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.33,
                    "duration": "indefinitely",
                    "model_conf": 279.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.4,
                    "duration": "indefinitely",
                    "model_conf": 195.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.08,
                    "duration": "indefinitely",
                    "model_conf": 165.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.37,
                    "duration": "indefinitely",
                    "model_conf": 168.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.93,
                    "duration": "indefinitely",
                    "model_conf": 157.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.41,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.52,
                    "duration": "indefinitely",
                    "model_conf": 236.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.49,
                    "duration": "indefinitely",
                    "model_conf": 219.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/charleston/975-savannah-hwy.html": {
        "name": "West Ashley",
        "address": "975 Savannah Hwy, Charleston Charleston, South Carolina, 29407",
        "phone": "(843) 766-5660",
        "url": "https://locations.massageenvy.com/south-carolina/charleston/975-savannah-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.7816867,
            -79.9901366
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.32,
                    "duration": "indefinitely",
                    "model_conf": 70.24
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.79,
                    "duration": "indefinitely",
                    "model_conf": 158.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.71,
                    "duration": "indefinitely",
                    "model_conf": 99.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.11,
                    "duration": "indefinitely",
                    "model_conf": 154.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.41,
                    "duration": "indefinitely",
                    "model_conf": 282.92
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 178.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.59,
                    "duration": "1 Months",
                    "model_conf": 159.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.86,
                    "duration": "indefinitely",
                    "model_conf": 166.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.68,
                    "duration": "indefinitely",
                    "model_conf": 156.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.63,
                    "duration": "3 Months",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.49,
                    "duration": "indefinitely",
                    "model_conf": 237.38
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.43,
                    "duration": "3 Months",
                    "model_conf": 55.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.54,
                    "duration": "indefinitely",
                    "model_conf": 208.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/easley/229-rolling-hills-circle.html": {
        "name": "Easley",
        "address": "229 Rolling Hills Circle, Easley, South Carolina, 29640",
        "phone": "(864) 850-3689",
        "url": "https://locations.massageenvy.com/south-carolina/easley/229-rolling-hills-circle.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.8289833,
            -82.5445632
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.88,
                    "duration": "indefinitely",
                    "model_conf": 281.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.4,
                    "duration": "1 Months",
                    "model_conf": 194.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.47,
                    "duration": "1 Months",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.72,
                    "duration": "1 Months",
                    "model_conf": 167.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.34,
                    "duration": "1 Months",
                    "model_conf": 157.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.11,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.43,
                    "duration": "1 Months",
                    "model_conf": 226.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 85.19
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.48,
                    "duration": "2 Months",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.46,
                    "duration": "1 Months",
                    "model_conf": 217.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/lexington/205-columbia-ave..html": {
        "name": "Lexington - SC",
        "address": "205 Columbia Ave. Suite E, Lexington Lexington, South Carolina, 29072",
        "phone": "(803) 951-3689",
        "url": "https://locations.massageenvy.com/south-carolina/lexington/205-columbia-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.98827131414741,
            -81.24512493610382
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.8,
                    "duration": "indefinitely",
                    "model_conf": 71.29
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 101.4
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 154.53
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.66,
                    "duration": "indefinitely",
                    "model_conf": 282.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 189.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.64,
                    "duration": "1 Months",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 167.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.62,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.42,
                    "duration": "indefinitely",
                    "model_conf": 228.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.9,
                    "duration": "indefinitely",
                    "model_conf": 219.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/north-myrtle-beach/1216-hwy-17-n.html": {
        "name": "Coastal North",
        "address": "1216 Hwy 17 N Suite C.3, North Myrtle Beach, South Carolina, 29582",
        "phone": "(843) 420-1910",
        "url": "https://locations.massageenvy.com/south-carolina/north-myrtle-beach/1216-hwy-17-n.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.83887309075649,
            -78.66568450244233
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.25,
                    "duration": "1 Months",
                    "model_conf": 277.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.98,
                    "duration": "indefinitely",
                    "model_conf": 192.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.66,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.88,
                    "duration": "indefinitely",
                    "model_conf": 166.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.02,
                    "duration": "indefinitely",
                    "model_conf": 157.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.75,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.67,
                    "duration": "indefinitely",
                    "model_conf": 236.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.62,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.39,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.58,
                    "duration": "indefinitely",
                    "model_conf": 220.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/rock-hill/636-crown-pointe-lane.html": {
        "name": "Rock Hill",
        "address": "636 Crown Pointe Lane, Rock Hill, South Carolina, 29730",
        "phone": "(803) 412-3313",
        "url": "https://locations.massageenvy.com/south-carolina/rock-hill/636-crown-pointe-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            34.940495886021075,
            -80.98080128908822
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 70.26
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.65,
                    "duration": "indefinitely",
                    "model_conf": 157.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 100.05
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.73,
                    "duration": "indefinitely",
                    "model_conf": 154.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.01,
                    "duration": "indefinitely",
                    "model_conf": 280.8
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 191.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 165.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.64,
                    "duration": "indefinitely",
                    "model_conf": 165.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.95,
                    "duration": "indefinitely",
                    "model_conf": 156.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 233.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 85.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 55.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.64,
                    "duration": "indefinitely",
                    "model_conf": 216.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/simpsonville/355-harrison-bridge-rd.html": {
        "name": "Simpsonville",
        "address": "355 Harrison Bridge Rd, Simpsonville, South Carolina, 29680",
        "phone": "(864) 688-3689",
        "url": "https://locations.massageenvy.com/south-carolina/simpsonville/355-harrison-bridge-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "12:00 PM - 7:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.70323866804794,
            -82.25689349570087
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 158.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 101.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.68,
                    "duration": "2 Months",
                    "model_conf": 154.39
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.84,
                    "duration": "3 Months",
                    "model_conf": 280.52
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.71,
                    "duration": "3 Months",
                    "model_conf": 192.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 166.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.16,
                    "duration": "5 Months",
                    "model_conf": 168.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 157.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.3,
                    "duration": "1 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.91,
                    "duration": "3 Months",
                    "model_conf": 233.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.73,
                    "duration": "2 Months",
                    "model_conf": 218.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/spartanburg/117-east-blackstock-road.html": {
        "name": "Spartanburg",
        "address": "117 East Blackstock Road, Spartanburg Spartanburg, South Carolina, 29301",
        "phone": "(864) 576-2555",
        "url": "https://locations.massageenvy.com/south-carolina/spartanburg/117-east-blackstock-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.934385,
            -81.989176
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.43,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 158.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.76,
                    "duration": "indefinitely",
                    "model_conf": 102.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 154.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.25,
                    "duration": "indefinitely",
                    "model_conf": 283.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.24,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 166.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.18,
                    "duration": "indefinitely",
                    "model_conf": 167.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.63,
                    "duration": "indefinitely",
                    "model_conf": 157.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.69,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.51,
                    "duration": "indefinitely",
                    "model_conf": 233.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.76,
                    "duration": "1 Months",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.07,
                    "duration": "indefinitely",
                    "model_conf": 221.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-dakota/rapid-city/1147-eglin-st.html": {
        "name": "Rapid City",
        "address": "1147 Eglin St Ste 200, Rapid City, South Dakota, 57701",
        "phone": "(605) 415-4480",
        "url": "https://locations.massageenvy.com/south-dakota/rapid-city/1147-eglin-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.101297,
            -103.191142
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.94,
                    "duration": "indefinitely",
                    "model_conf": 71.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.64,
                    "duration": "4 Months",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.74,
                    "duration": "1 Months",
                    "model_conf": 101.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.25,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.66,
                    "duration": "3 Months",
                    "model_conf": 282.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.57,
                    "duration": "indefinitely",
                    "model_conf": 166.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.66,
                    "duration": "2 Months",
                    "model_conf": 167.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.73,
                    "duration": "indefinitely",
                    "model_conf": 157.15
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.16,
                    "duration": "indefinitely",
                    "model_conf": 141.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.97,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.46,
                    "duration": "1 Months",
                    "model_conf": 221.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-dakota/sioux-falls/4821-s-louise-avenue.html": {
        "name": "Sioux Falls",
        "address": "4821 S Louise Avenue, Sioux Falls Sioux Falls, South Dakota, 57106",
        "phone": "(605) 275-6555",
        "url": "https://locations.massageenvy.com/south-dakota/sioux-falls/4821-s-louise-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.50126672405608,
            -96.77292695465707
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.49,
                    "duration": "1 Months",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 139.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 102.11
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 281.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 167.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.32,
                    "duration": "indefinitely",
                    "model_conf": 167.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.38,
                    "duration": "1 Months",
                    "model_conf": 157.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.71,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 55.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 221.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/alcoa/258-hamilton-crossing-drive.html": {
        "name": "Hamilton Crossing",
        "address": "258 Hamilton Crossing Drive Suite B, Alcoa, Tennessee, 37701",
        "phone": "(865) 983-9828",
        "url": "https://locations.massageenvy.com/tennessee/alcoa/258-hamilton-crossing-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.7660316,
            -83.986209
        ],
        "rating": 4.8,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.92,
                    "duration": "indefinitely",
                    "model_conf": 70.92
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.46,
                    "duration": "1 Months",
                    "model_conf": 157.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.46,
                    "duration": "1 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.64,
                    "duration": "2 Months",
                    "model_conf": 101.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.8,
                    "duration": "indefinitely",
                    "model_conf": 154.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.0,
                    "duration": "indefinitely",
                    "model_conf": 276.88
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.76,
                    "duration": "indefinitely",
                    "model_conf": 189.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.34,
                    "duration": "2 Months",
                    "model_conf": 165.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.02,
                    "duration": "indefinitely",
                    "model_conf": 164.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.46,
                    "duration": "1 Months",
                    "model_conf": 156.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.89,
                    "duration": "indefinitely",
                    "model_conf": 140.24
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 227.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.54,
                    "duration": "1 Months",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.83,
                    "duration": "1 Months",
                    "model_conf": 55.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 218.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/bellevue/8024-highway-100.html": {
        "name": "Bellevue \u2013 Nashville",
        "address": "8024 Highway 100 Ste 2B, Bellevue Bellevue, Tennessee, 37221",
        "phone": "(615) 662-3689",
        "url": "https://locations.massageenvy.com/tennessee/bellevue/8024-highway-100.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.047128,
            -86.95194
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.45,
                    "duration": "1 Months",
                    "model_conf": 70.93
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.58,
                    "duration": "indefinitely",
                    "model_conf": 154.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.07,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.96,
                    "duration": "indefinitely",
                    "model_conf": 99.81
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.8,
                    "duration": "indefinitely",
                    "model_conf": 153.13
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 275.41
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.62,
                    "duration": "1 Months",
                    "model_conf": 189.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.94,
                    "duration": "3 Months",
                    "model_conf": 160.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.42,
                    "duration": "indefinitely",
                    "model_conf": 160.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.2,
                    "duration": "1 Months",
                    "model_conf": 147.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.2,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.53,
                    "duration": "indefinitely",
                    "model_conf": 237.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.87,
                    "duration": "indefinitely",
                    "model_conf": 85.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.3,
                    "duration": "indefinitely",
                    "model_conf": 217.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/collierville/4610-merchants-park-circle.html": {
        "name": "Collierville",
        "address": "4610 Merchants Park Circle Suite 511, Collierville Collierville, Tennessee, 38017",
        "phone": "(901) 854-8255",
        "url": "https://locations.massageenvy.com/tennessee/collierville/4610-merchants-park-circle.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.0230619,
            -89.7201426
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.54,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.3,
                    "duration": "2 Months",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.09,
                    "duration": "indefinitely",
                    "model_conf": 98.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.93,
                    "duration": "indefinitely",
                    "model_conf": 150.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 272.42
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 156.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.17,
                    "duration": "indefinitely",
                    "model_conf": 155.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.28,
                    "duration": "indefinitely",
                    "model_conf": 150.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.25,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.28,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.9,
                    "duration": "indefinitely",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.78,
                    "duration": "indefinitely",
                    "model_conf": 220.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/cordova/2362-n-germantown-parkway.html": {
        "name": "Cordova",
        "address": "2362 N Germantown Parkway Suite 105, Cordova Cordova, Tennessee, 38016",
        "phone": "(901) 372-3689",
        "url": "https://locations.massageenvy.com/tennessee/cordova/2362-n-germantown-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.190014543532904,
            -89.7913717516576
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.82,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.57,
                    "duration": "4 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.63,
                    "duration": "indefinitely",
                    "model_conf": 101.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.32,
                    "duration": "indefinitely",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.87,
                    "duration": "indefinitely",
                    "model_conf": 281.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.0,
                    "duration": "3 Months",
                    "model_conf": 164.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.53,
                    "duration": "indefinitely",
                    "model_conf": 165.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.92,
                    "duration": "indefinitely",
                    "model_conf": 155.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.49,
                    "duration": "1 Months",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.04,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.77,
                    "duration": "1 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.87,
                    "duration": "indefinitely",
                    "model_conf": 221.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/farragut/11669-parkside-drive.html": {
        "name": "Turkey Creek",
        "address": "11669 Parkside Drive, Farragut, Tennessee, 37934",
        "phone": "(865) 218-3689",
        "url": "https://locations.massageenvy.com/tennessee/farragut/11669-parkside-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.89534,
            -84.171841
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.82,
                    "duration": "indefinitely",
                    "model_conf": 71.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.51,
                    "duration": "1 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.68,
                    "duration": "2 Months",
                    "model_conf": 101.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.13,
                    "duration": "1 Months",
                    "model_conf": 154.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.98,
                    "duration": "indefinitely",
                    "model_conf": 278.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 165.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.62,
                    "duration": "2 Months",
                    "model_conf": 164.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.51,
                    "duration": "1 Months",
                    "model_conf": 155.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.84,
                    "duration": "indefinitely",
                    "model_conf": 140.29
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.73,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.04,
                    "duration": "indefinitely",
                    "model_conf": 55.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.62,
                    "duration": "indefinitely",
                    "model_conf": 218.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/germantown/7820-poplar-avenue.html": {
        "name": "Germantown-TN",
        "address": "7820 Poplar Avenue Ste 4-6, Germantown Germantown, Tennessee, 38138",
        "phone": "(901) 312-5382",
        "url": "https://locations.massageenvy.com/tennessee/germantown/7820-poplar-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.090371,
            -89.802141
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.43,
                    "duration": "5 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.28,
                    "duration": "4 Months",
                    "model_conf": 99.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.43,
                    "duration": "1 Months",
                    "model_conf": 152.3
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.33,
                    "duration": "1 Months",
                    "model_conf": 279.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.89,
                    "duration": "indefinitely",
                    "model_conf": 160.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.0,
                    "duration": "indefinitely",
                    "model_conf": 161.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.28,
                    "duration": "indefinitely",
                    "model_conf": 154.44
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.89,
                    "duration": "indefinitely",
                    "model_conf": 85.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.14,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.73,
                    "duration": "indefinitely",
                    "model_conf": 220.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/hendersonville/1050-glenbrook-way.html": {
        "name": "Hendersonville",
        "address": "1050 Glenbrook Way # 200, Hendersonville Hendersonville, Tennessee, 37075",
        "phone": "(615) 822-7636",
        "url": "https://locations.massageenvy.com/tennessee/hendersonville/1050-glenbrook-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.33210836311692,
            -86.6254624970386
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/tennessee/hixson/5591-highway-153.html": {
        "name": "Hixson",
        "address": "5591 Highway 153 Suite 132, Hixson Hixson, Tennessee, 37343",
        "phone": "(423) 877-3000",
        "url": "https://locations.massageenvy.com/tennessee/hixson/5591-highway-153.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.14756346141667,
            -85.25150780959297
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.6,
                    "duration": "1 Months",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.67,
                    "duration": "1 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.47,
                    "duration": "4 Months",
                    "model_conf": 100.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.66,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.82,
                    "duration": "indefinitely",
                    "model_conf": 282.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.9,
                    "duration": "indefinitely",
                    "model_conf": 166.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.8,
                    "duration": "1 Months",
                    "model_conf": 167.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.67,
                    "duration": "indefinitely",
                    "model_conf": 157.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.99,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.94,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.83,
                    "duration": "1 Months",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.49,
                    "duration": "1 Months",
                    "model_conf": 218.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/memphis/1680-union-ave.html": {
        "name": "Midtown Memphis",
        "address": "1680 Union Ave Suite 111, Memphis, Tennessee, 38104",
        "phone": "(901) 276-1011",
        "url": "https://locations.massageenvy.com/tennessee/memphis/1680-union-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.1362032,
            -90.0058653
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.37,
                    "duration": "indefinitely",
                    "model_conf": 71.36
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.13,
                    "duration": "2 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.85,
                    "duration": "indefinitely",
                    "model_conf": 102.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.3,
                    "duration": "indefinitely",
                    "model_conf": 281.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.1,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.24,
                    "duration": "indefinitely",
                    "model_conf": 167.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 157.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.9,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.88,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.3,
                    "duration": "indefinitely",
                    "model_conf": 221.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/mt.-juliet/401-s-mt-juliet-road.html": {
        "name": "Mt. Juliet",
        "address": "401 S Mt Juliet Road #240, Mt. Juliet, Tennessee, 37122",
        "phone": "(615) 773-0772",
        "url": "https://locations.massageenvy.com/tennessee/mt.-juliet/401-s-mt-juliet-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.16624618573623,
            -86.50761365890503
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/tennessee/murfreesboro/2615-medical-center-parkway.html": {
        "name": "Murfreesboro",
        "address": "2615 Medical Center Parkway Ste 1760, Murfreesboro Murfreesboro, Tennessee, 37129",
        "phone": "(615) 907-4888",
        "url": "https://locations.massageenvy.com/tennessee/murfreesboro/2615-medical-center-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.8576652,
            -86.4432471
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/tennessee/powell/627-east-emory-rd.html": {
        "name": "Emory Road",
        "address": "627 East Emory Rd, Powell, Tennessee, 37849",
        "phone": "(865) 947-3689",
        "url": "https://locations.massageenvy.com/tennessee/powell/627-east-emory-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.053031,
            -83.990489
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.75,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.86,
                    "duration": "indefinitely",
                    "model_conf": 101.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.9,
                    "duration": "indefinitely",
                    "model_conf": 155.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.85,
                    "duration": "3 Months",
                    "model_conf": 273.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.32,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.43,
                    "duration": "3 Months",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.3,
                    "duration": "indefinitely",
                    "model_conf": 156.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.02,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.07,
                    "duration": "indefinitely",
                    "model_conf": 84.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.33,
                    "duration": "1 Months",
                    "model_conf": 218.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/alamo-heights/6484-n-new-braunfels.html": {
        "name": "Sunset Ridge",
        "address": "6484 N New Braunfels, Alamo Heights, Texas, 78209",
        "phone": "(210) 826-3689",
        "url": "https://locations.massageenvy.com/texas/alamo-heights/6484-n-new-braunfels.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.4898522,
            -98.4586221
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.23,
                    "duration": "2 Months",
                    "model_conf": 239.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.39,
                    "duration": "1 Months",
                    "model_conf": 160.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 152.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 155.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.22,
                    "duration": "indefinitely",
                    "model_conf": 152.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/allen/816-w-mcdermott-dr.html": {
        "name": "Allen",
        "address": "816 W Mcdermott Dr Suite 340, Allen Allen, Texas, 75013",
        "phone": "(972) 747-7511",
        "url": "https://locations.massageenvy.com/texas/allen/816-w-mcdermott-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.103646,
            -96.681544
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/baytown/8824-n-sh-146.html": {
        "name": "Baytown",
        "address": "8824 N SH 146 STE. 120, Baytown, Texas, 77523",
        "phone": "(281) 317-2818",
        "url": "https://locations.massageenvy.com/texas/baytown/8824-n-sh-146.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.819391737538737,
            -94.89871484922291
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.54,
                    "duration": "indefinitely",
                    "model_conf": 283.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.6,
                    "duration": "1 Months",
                    "model_conf": 165.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.48,
                    "duration": "indefinitely",
                    "model_conf": 168.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.1,
                    "duration": "indefinitely",
                    "model_conf": 157.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.57,
                    "duration": "1 Months",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 86.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.07,
                    "duration": "indefinitely",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.12,
                    "duration": "indefinitely",
                    "model_conf": 221.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/beaumont/3970-dowlen-rd..html": {
        "name": "Beaumont",
        "address": "3970 Dowlen Rd., Beaumont Beaumont, Texas, 77706",
        "phone": "(409) 892-3689",
        "url": "https://locations.massageenvy.com/texas/beaumont/3970-dowlen-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 5:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            30.1165507,
            -94.1670582
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.55,
                    "duration": "indefinitely",
                    "model_conf": 279.7
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.85,
                    "duration": "indefinitely",
                    "model_conf": 178.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.59,
                    "duration": "1 Months",
                    "model_conf": 166.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.25,
                    "duration": "indefinitely",
                    "model_conf": 167.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.22,
                    "duration": "1 Months",
                    "model_conf": 154.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.86,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.18,
                    "duration": "indefinitely",
                    "model_conf": 238.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.81,
                    "duration": "indefinitely",
                    "model_conf": 86.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.82,
                    "duration": "indefinitely",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.96,
                    "duration": "indefinitely",
                    "model_conf": 221.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/bee-cave/12921-shops-parkway.html": {
        "name": "Lakeway",
        "address": "12921 Shops Parkway Suite 100, Bee Cave, Texas, 78738",
        "phone": "(512) 263-1515",
        "url": "https://locations.massageenvy.com/texas/bee-cave/12921-shops-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 4:00 PM",
            "TUESDAY": "10:00 AM - 5:30 PM",
            "WEDNESDAY": "10:00 AM - 3:00 PM",
            "THURSDAY": "1:00 PM - 8:00 PM",
            "FRIDAY": "1:00 PM - 8:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            30.3067053,
            -97.9451922
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.01,
                    "duration": "2 Months",
                    "model_conf": 279.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.04,
                    "duration": "2 Months",
                    "model_conf": 191.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.16,
                    "duration": "4 Months",
                    "model_conf": 161.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.87,
                    "duration": "indefinitely",
                    "model_conf": 154.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.11,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.18,
                    "duration": "2 Months",
                    "model_conf": 235.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.18,
                    "duration": "indefinitely",
                    "model_conf": 84.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.19,
                    "duration": "indefinitely",
                    "model_conf": 55.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.69,
                    "duration": "indefinitely",
                    "model_conf": 213.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/carrollton/1012-w.-hebron-parkway.html": {
        "name": "Carrollton",
        "address": "1012 W. Hebron Parkway Suite 300, Carrollton, Texas, 75010",
        "phone": "(972) 939-6868",
        "url": "https://locations.massageenvy.com/texas/carrollton/1012-w.-hebron-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.021913,
            -96.9164682
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.17,
                    "duration": "indefinitely",
                    "model_conf": 268.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.39,
                    "duration": "indefinitely",
                    "model_conf": 180.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.27,
                    "duration": "4 Months",
                    "model_conf": 160.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.66,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.61,
                    "duration": "2 Months",
                    "model_conf": 150.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.62,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.02,
                    "duration": "indefinitely",
                    "model_conf": 220.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.1,
                    "duration": "2 Months",
                    "model_conf": 83.32
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.36,
                    "duration": "3 Months",
                    "model_conf": 53.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.77,
                    "duration": "indefinitely",
                    "model_conf": 209.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/cedar-hill/305-w-fm-1382.html": {
        "name": "Cedar Hill",
        "address": "305 W FM 1382 Ste 110, Cedar Hill Cedar Hill, Texas, 75104",
        "phone": "(469) 272-3689",
        "url": "https://locations.massageenvy.com/texas/cedar-hill/305-w-fm-1382.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 8:00 PM",
            "TUESDAY": "8:30 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.603414942930925,
            -96.94327123497027
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.48,
                    "duration": "3 Months",
                    "model_conf": 282.54
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.5,
                    "duration": "4 Months",
                    "model_conf": 193.57
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.06,
                    "duration": "indefinitely",
                    "model_conf": 165.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.12,
                    "duration": "4 Months",
                    "model_conf": 163.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.14,
                    "duration": "indefinitely",
                    "model_conf": 157.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.98,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.07,
                    "duration": "5 Months",
                    "model_conf": 234.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.25,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.16,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.81,
                    "duration": "2 Months",
                    "model_conf": 221.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/cedar-park/1335-east-whitestone-boulevard.html": {
        "name": "Cedar Park",
        "address": "1335 East Whitestone Boulevard E300, Cedar Park Cedar Park, Texas, 78613",
        "phone": "(512) 337-0000",
        "url": "https://locations.massageenvy.com/texas/cedar-park/1335-east-whitestone-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.528228,
            -97.814906
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.77,
                    "duration": "indefinitely",
                    "model_conf": 277.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.28,
                    "duration": "indefinitely",
                    "model_conf": 186.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.67,
                    "duration": "3 Months",
                    "model_conf": 165.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.88,
                    "duration": "indefinitely",
                    "model_conf": 161.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.99,
                    "duration": "1 Months",
                    "model_conf": 150.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.36,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.25,
                    "duration": "indefinitely",
                    "model_conf": 231.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.01,
                    "duration": "indefinitely",
                    "model_conf": 83.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.97,
                    "duration": "indefinitely",
                    "model_conf": 54.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.91,
                    "duration": "indefinitely",
                    "model_conf": 208.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/clear-lake-shores/243-fm-2094.html": {
        "name": "Kemah",
        "address": "243 FM 2094 Ste Q, Clear Lake Shores, Texas, 77565",
        "phone": "(281) 535-3689",
        "url": "https://locations.massageenvy.com/texas/clear-lake-shores/243-fm-2094.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.539173,
            -95.021304
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 273.24
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 186.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.21,
                    "duration": "indefinitely",
                    "model_conf": 160.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.02,
                    "duration": "5 Months",
                    "model_conf": 165.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 152.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/college-station/4444-s.-highway-6.html": {
        "name": "College Station",
        "address": "4444 S. Highway 6, College Station, Texas, 77845",
        "phone": "(979) 690-5344",
        "url": "https://locations.massageenvy.com/texas/college-station/4444-s.-highway-6.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "11:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.56064180746334,
            -96.26241017790983
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.25,
                    "duration": "2 Months",
                    "model_conf": 281.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.81,
                    "duration": "indefinitely",
                    "model_conf": 165.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.81,
                    "duration": "1 Months",
                    "model_conf": 168.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 157.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.19,
                    "duration": "indefinitely",
                    "model_conf": 85.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.04,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 220.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/colleyville/5615-colleyville-blvd.html": {
        "name": "Colleyville",
        "address": "5615 Colleyville Blvd Ste 470, Colleyville, Texas, 76034",
        "phone": "(817) 427-3689",
        "url": "https://locations.massageenvy.com/texas/colleyville/5615-colleyville-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.890905,
            -97.147545
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/conroe/1140-n-fm-3083-rd.-west.html": {
        "name": "Teas Crossing",
        "address": "1140 N FM 3083 Rd. West Suite 900, Conroe, Texas, 77304",
        "phone": "(936) 539-3689",
        "url": "https://locations.massageenvy.com/texas/conroe/1140-n-fm-3083-rd.-west.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.35227791057341,
            -95.48589099999998
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.27,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.63,
                    "duration": "1 Months",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.57,
                    "duration": "2 Months",
                    "model_conf": 55.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/coppell/120-s-denton-tap-rd.html": {
        "name": "Coppell",
        "address": "120 S Denton Tap Rd Ste 303, Coppell Coppell, Texas, 75019",
        "phone": "(972) 956-5999",
        "url": "https://locations.massageenvy.com/texas/coppell/120-s-denton-tap-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.9679565,
            -96.9921875
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.24,
                    "duration": "indefinitely",
                    "model_conf": 70.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.91,
                    "duration": "indefinitely",
                    "model_conf": 98.75
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.47,
                    "duration": "indefinitely",
                    "model_conf": 274.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 163.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.89,
                    "duration": "indefinitely",
                    "model_conf": 150.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.53,
                    "duration": "indefinitely",
                    "model_conf": 140.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 83.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.57,
                    "duration": "indefinitely",
                    "model_conf": 216.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/corinth/4451-fm-2181.html": {
        "name": "Corinth",
        "address": "4451 FM 2181 Ste 130, Corinth, Texas, 76210",
        "phone": "(940) 497-5777",
        "url": "https://locations.massageenvy.com/texas/corinth/4451-fm-2181.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.131421146714885,
            -97.04234410943764
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/corpus-christi/5425-south-padre-island-dr.html": {
        "name": "Corpus Christi Spa",
        "address": "5425 South Padre Island Dr #165, Corpus Christi Corpus Christi, Texas, 78411",
        "phone": "(361) 992-3689",
        "url": "https://locations.massageenvy.com/texas/corpus-christi/5425-south-padre-island-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.70849534168891,
            -97.3740095643771
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 281.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 195.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.68,
                    "duration": "7 Months",
                    "model_conf": 168.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.31,
                    "duration": "1 Months",
                    "model_conf": 156.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/cypress/25626-northwest-freeway.html": {
        "name": "Cypress",
        "address": "25626 Northwest Freeway Suite 900, Cypress Cypress, Texas, 77429",
        "phone": "(832) 220-1900",
        "url": "https://locations.massageenvy.com/texas/cypress/25626-northwest-freeway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            29.9670603,
            -95.6892079
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 275.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.69,
                    "duration": "indefinitely",
                    "model_conf": 160.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.39,
                    "duration": "4 Months",
                    "model_conf": 166.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 155.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.2,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.05,
                    "duration": "indefinitely",
                    "model_conf": 54.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 211.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/denton/2515-west-university-dr..html": {
        "name": "Denton",
        "address": "2515 West University Dr. Suite 1023, Denton, Texas, 76201",
        "phone": "(817) 803-6655",
        "url": "https://locations.massageenvy.com/texas/denton/2515-west-university-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:30 PM",
            "WEDNESDAY": "9:00 AM - 6:30 PM",
            "THURSDAY": "9:00 AM - 7:30 PM",
            "FRIDAY": "9:00 AM - 6:30 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.2292795,
            -97.1661391
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.3,
                    "duration": "indefinitely",
                    "model_conf": 278.74
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.4,
                    "duration": "indefinitely",
                    "model_conf": 194.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.68,
                    "duration": "indefinitely",
                    "model_conf": 165.78
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.6,
                    "duration": "indefinitely",
                    "model_conf": 164.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.04,
                    "duration": "indefinitely",
                    "model_conf": 155.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.08,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.63,
                    "duration": "indefinitely",
                    "model_conf": 237.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.25,
                    "duration": "indefinitely",
                    "model_conf": 85.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.77,
                    "duration": "indefinitely",
                    "model_conf": 219.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/euless/2901-rio-grande-blvd..html": {
        "name": "Glade Parks",
        "address": "2901 Rio Grande Blvd. Suite 100, Euless Euless, Texas, 76039",
        "phone": "(817) 545-3689",
        "url": "https://locations.massageenvy.com/texas/euless/2901-rio-grande-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 8:00 PM",
            "TUESDAY": "8:30 AM - 7:00 PM",
            "WEDNESDAY": "8:30 AM - 7:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 7:00 PM",
            "SATURDAY": "8:30 AM - 7:00 PM",
            "SUNDAY": "9:30 AM - 6:00 PM"
        },
        "geolocation": [
            32.877980724977455,
            -97.100534172881
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.77,
                    "duration": "indefinitely",
                    "model_conf": 277.82
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.84,
                    "duration": "indefinitely",
                    "model_conf": 184.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.64,
                    "duration": "indefinitely",
                    "model_conf": 163.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.74,
                    "duration": "indefinitely",
                    "model_conf": 164.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.74,
                    "duration": "indefinitely",
                    "model_conf": 154.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.2,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.15,
                    "duration": "indefinitely",
                    "model_conf": 230.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.27,
                    "duration": "indefinitely",
                    "model_conf": 84.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 55.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.45,
                    "duration": "indefinitely",
                    "model_conf": 214.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/flower-mound/2500-cross-timbers-rd.html": {
        "name": "Flower Mound",
        "address": "2500 Cross Timbers Rd #130, Flower Mound, Texas, 75028",
        "phone": "(972) 899-3610",
        "url": "https://locations.massageenvy.com/texas/flower-mound/2500-cross-timbers-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.03594924445847,
            -97.06727285191351
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.7,
                    "duration": "indefinitely",
                    "model_conf": 274.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.47,
                    "duration": "1 Months",
                    "model_conf": 163.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.21,
                    "duration": "4 Months",
                    "model_conf": 164.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.09,
                    "duration": "1 Months",
                    "model_conf": 148.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/garland/4430-lavon-drive.html": {
        "name": "Firewheel Market",
        "address": "4430 Lavon Drive Ste 310, Garland Garland, Texas, 75040",
        "phone": "(972) 675-3689",
        "url": "https://locations.massageenvy.com/texas/garland/4430-lavon-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.957173,
            -96.615662
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.15,
                    "duration": "indefinitely",
                    "model_conf": 267.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.51,
                    "duration": "indefinitely",
                    "model_conf": 178.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.24,
                    "duration": "2 Months",
                    "model_conf": 161.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.38,
                    "duration": "indefinitely",
                    "model_conf": 160.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.16,
                    "duration": "1 Months",
                    "model_conf": 153.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.08,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.82,
                    "duration": "indefinitely",
                    "model_conf": 219.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.42,
                    "duration": "1 Months",
                    "model_conf": 83.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.57,
                    "duration": "2 Months",
                    "model_conf": 53.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.81,
                    "duration": "indefinitely",
                    "model_conf": 197.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/grand-prairie/5232-south-state-highway-360.html": {
        "name": "South 360",
        "address": "5232 South State Highway 360 Suite 610, Grand Prairie, Texas, 75052",
        "phone": "(817) 303-3689",
        "url": "https://locations.massageenvy.com/texas/grand-prairie/5232-south-state-highway-360.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.64599594969833,
            -97.0595891951075
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/harker-heights/300-e.-central-texas-expressway.html": {
        "name": "Harker Heights",
        "address": "300 E. Central Texas Expressway Suite 200, Harker Heights, Texas, 76548",
        "phone": "(254) 781-4550",
        "url": "https://locations.massageenvy.com/texas/harker-heights/300-e.-central-texas-expressway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            31.07126051420503,
            -97.66131937057798
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/hudson-oaks/100-s.-oakridge-drive.html": {
        "name": "Hudson Oaks",
        "address": "100 S. Oakridge Drive #140, Hudson Oaks, Texas, 76087",
        "phone": "(817) 550-1665",
        "url": "https://locations.massageenvy.com/texas/hudson-oaks/100-s.-oakridge-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:30 PM",
            "TUESDAY": "8:00 AM - 7:30 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.7558363,
            -97.7122471
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/humble/19250-w-lake-houston-parkway.html": {
        "name": "Atascocita",
        "address": "19250 W Lake Houston Parkway Suite A, Humble Humble, Texas, 77346",
        "phone": "(281) 852-0808",
        "url": "https://locations.massageenvy.com/texas/humble/19250-w-lake-houston-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.001404,
            -95.162502
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.52,
                    "duration": "1 Months",
                    "model_conf": 280.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 164.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.14,
                    "duration": "5 Months",
                    "model_conf": 157.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 153.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/irving/975-w-john-carpenter-fwy.html": {
        "name": "Las Colinas",
        "address": "975 W John Carpenter Fwy Ste 112, Irving Irving, Texas, 75039",
        "phone": "(972) 556-0777",
        "url": "https://locations.massageenvy.com/texas/irving/975-w-john-carpenter-fwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.89119123703816,
            -96.95907261183879
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.06,
                    "duration": "indefinitely",
                    "model_conf": 275.89
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 187.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.12,
                    "duration": "2 Months",
                    "model_conf": 163.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.99,
                    "duration": "indefinitely",
                    "model_conf": 164.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.75,
                    "duration": "2 Months",
                    "model_conf": 153.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.9,
                    "duration": "indefinitely",
                    "model_conf": 140.63
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.75,
                    "duration": "indefinitely",
                    "model_conf": 227.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.38,
                    "duration": "1 Months",
                    "model_conf": 84.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 54.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.99,
                    "duration": "indefinitely",
                    "model_conf": 216.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/kingwood/724-b-kingwood-drive.html": {
        "name": "Kingwood",
        "address": "724-B Kingwood Drive, Kingwood, Texas, 77339",
        "phone": "(281) 312-6377",
        "url": "https://locations.massageenvy.com/texas/kingwood/724-b-kingwood-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.049604,
            -95.245297
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 282.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.96,
                    "duration": "indefinitely",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.88,
                    "duration": "6 Months",
                    "model_conf": 164.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 156.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/kyle/5401-s-fm-1626.html": {
        "name": "Kyle Marketplace",
        "address": "5401 S FM 1626 Suite 140, Kyle, Texas, 78640",
        "phone": "(512) 273-3689",
        "url": "https://locations.massageenvy.com/texas/kyle/5401-s-fm-1626.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.0167249,
            -97.8628166
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.38,
                    "duration": "indefinitely",
                    "model_conf": 282.25
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.22,
                    "duration": "indefinitely",
                    "model_conf": 193.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.01,
                    "duration": "indefinitely",
                    "model_conf": 166.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.37,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.07,
                    "duration": "indefinitely",
                    "model_conf": 157.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.28,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.31,
                    "duration": "indefinitely",
                    "model_conf": 236.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.13,
                    "duration": "indefinitely",
                    "model_conf": 85.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.02,
                    "duration": "indefinitely",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 220.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/league-city/2925-gulf-freeway-south.html": {
        "name": "Bay Colony",
        "address": "2925 Gulf Freeway South Suite E, League City League City, Texas, 77573",
        "phone": "(281) 337-1052",
        "url": "https://locations.massageenvy.com/texas/league-city/2925-gulf-freeway-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            29.467209,
            -95.092838
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.46,
                    "duration": "1 Months",
                    "model_conf": 267.95
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 182.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 156.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 164.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.87,
                    "duration": "indefinitely",
                    "model_conf": 149.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/longview/3088-n-eastman-rd.html": {
        "name": "Longview",
        "address": "3088 N Eastman Rd Suite 100, Longview, Texas, 75605",
        "phone": "(903) 663-2600",
        "url": "https://locations.massageenvy.com/texas/longview/3088-n-eastman-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.549336,
            -94.7272221
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/lubbock/4414-82nd-st.html": {
        "name": "Lubbock",
        "address": "4414 82nd St Ste 109, Lubbock, Texas, 79424",
        "phone": "(806) 687-3689",
        "url": "https://locations.massageenvy.com/texas/lubbock/4414-82nd-st.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            33.52069230426396,
            -101.90613136883745
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.79,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.03,
                    "duration": "indefinitely",
                    "model_conf": 55.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/magnolia/6531-fm-1488.html": {
        "name": "Magnolia-Westwood Village",
        "address": "6531 FM 1488 Ste 309, Magnolia, Texas, 77354",
        "phone": "(281) 766-1350",
        "url": "https://locations.massageenvy.com/texas/magnolia/6531-fm-1488.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.22144922624243,
            -95.56477963106833
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.86,
                    "duration": "indefinitely",
                    "model_conf": 281.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.56,
                    "duration": "4 Months",
                    "model_conf": 165.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 164.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.61,
                    "duration": "2 Months",
                    "model_conf": 157.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/mansfield/1497-hwy-287-north.html": {
        "name": "Mansfield",
        "address": "1497 Hwy 287 North, Mansfield Mansfield, Texas, 76063",
        "phone": "(817) 453-3689",
        "url": "https://locations.massageenvy.com/texas/mansfield/1497-hwy-287-north.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.585888,
            -97.139098
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/mcallen/5800-north-10th-street.html": {
        "name": "Las Fuentes",
        "address": "5800 North 10th Street Suite 160, McAllen, Texas, 78504",
        "phone": "(956) 686-0853",
        "url": "https://locations.massageenvy.com/texas/mcallen/5800-north-10th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.25667240326608,
            -98.21967880305078
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.96,
                    "duration": "indefinitely",
                    "model_conf": 278.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.55,
                    "duration": "1 Months",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.8,
                    "duration": "indefinitely",
                    "model_conf": 168.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 157.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.35,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.45,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.16,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.18,
                    "duration": "indefinitely",
                    "model_conf": 216.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/mesquite/1765-n-town-east-blvd.html": {
        "name": "Mesquite Crossing",
        "address": "1765 N Town East Blvd Ste 155, Mesquite Mesquite, Texas, 75150",
        "phone": "(972) 681-0101",
        "url": "https://locations.massageenvy.com/texas/mesquite/1765-n-town-east-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.8113746,
            -96.6255979
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/midland/3208-n-loop-250-w.html": {
        "name": "Midland",
        "address": "3208 N Loop 250 W Ste 900, Midland Midland, Texas, 79707",
        "phone": "(432) 550-3689",
        "url": "https://locations.massageenvy.com/texas/midland/3208-n-loop-250-w.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            32.011233,
            -102.15674
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.25,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.0,
                    "duration": "indefinitely",
                    "model_conf": 86.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.69,
                    "duration": "2 Months",
                    "model_conf": 55.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/missouri-city/5201-hwy-6-s..html": {
        "name": "Missouri City",
        "address": "5201 Hwy 6 S., Missouri City, Texas, 77459",
        "phone": "(281) 208-4445",
        "url": "https://locations.massageenvy.com/texas/missouri-city/5201-hwy-6-s..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.57593912111228,
            -95.57511895895004
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.21,
                    "duration": "2 Months",
                    "model_conf": 278.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 164.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 166.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.12,
                    "duration": "indefinitely",
                    "model_conf": 154.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/montgomery/20212-eva-street.html": {
        "name": "Lone Star Parkway",
        "address": "20212 Eva Street Suite 250, Montgomery, Texas, 77356",
        "phone": "(936) 207-2007",
        "url": "https://locations.massageenvy.com/texas/montgomery/20212-eva-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            30.3899888,
            -95.6797377
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.77,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 86.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.24,
                    "duration": "indefinitely",
                    "model_conf": 55.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/murphy/223-e-farm-to-market-rd-544.html": {
        "name": "Murphy Marketplace",
        "address": "223 E Farm to Market Rd 544 Suite 801, Murphy Murphy, Texas, 75094",
        "phone": "(972) 354-8888",
        "url": "https://locations.massageenvy.com/texas/murphy/223-e-farm-to-market-rd-544.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.01321299999999,
            -96.60596331779098
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.02,
                    "duration": "4 Months",
                    "model_conf": 272.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.59,
                    "duration": "indefinitely",
                    "model_conf": 162.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.94,
                    "duration": "5 Months",
                    "model_conf": 161.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.57,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.47,
                    "duration": "1 Months",
                    "model_conf": 140.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.11,
                    "duration": "indefinitely",
                    "model_conf": 84.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.99,
                    "duration": "indefinitely",
                    "model_conf": 54.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.28,
                    "duration": "3 Months",
                    "model_conf": 198.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/new-braunfels/1667-state-highway-46-west.html": {
        "name": "New Braunfels",
        "address": "1667 State Highway 46 West Ste 415, New Braunfels, Texas, 78130",
        "phone": "(830) 625-3689",
        "url": "https://locations.massageenvy.com/texas/new-braunfels/1667-state-highway-46-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "11:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.715002675725856,
            -98.16039686791525
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.6,
                    "duration": "1 Months",
                    "model_conf": 281.47
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 194.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 167.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.03,
                    "duration": "indefinitely",
                    "model_conf": 157.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/pasadena/5774-fairmont-parkway.html": {
        "name": "Pasadena TX",
        "address": "5774 Fairmont Parkway, Pasadena Pasadena, Texas, 77505",
        "phone": "(281) 998-3689",
        "url": "https://locations.massageenvy.com/texas/pasadena/5774-fairmont-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.6478697,
            -95.1527101
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.27,
                    "duration": "indefinitely",
                    "model_conf": 261.02
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.05,
                    "duration": "indefinitely",
                    "model_conf": 176.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.2,
                    "duration": "8 Months",
                    "model_conf": 152.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.88,
                    "duration": "indefinitely",
                    "model_conf": 160.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.84,
                    "duration": "6 Months",
                    "model_conf": 133.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/prosper/750-richland-blvd..html": {
        "name": "Prosper",
        "address": "750 Richland Blvd. Suite 80, Prosper Prosper, Texas, 75078",
        "phone": "(469) 904-6988",
        "url": "https://locations.massageenvy.com/texas/prosper/750-richland-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.22060008890961,
            -96.79757970578066
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.43,
                    "duration": "indefinitely",
                    "model_conf": 70.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 139.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 97.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.91,
                    "duration": "indefinitely",
                    "model_conf": 273.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 162.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 162.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.98,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 84.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 54.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.26,
                    "duration": "indefinitely",
                    "model_conf": 212.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/richardson/1415-e.-renner-rd..html": {
        "name": "Cityline",
        "address": "1415 E. Renner Rd. Suite 210, Richardson Richardson, Texas, 75082",
        "phone": "(972) 982-2895",
        "url": "https://locations.massageenvy.com/texas/richardson/1415-e.-renner-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.99870438176661,
            -96.69813387036197
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/richmond/10237-west-grand-parkway-south.html": {
        "name": "Aliana",
        "address": "10237 West Grand Parkway South Suite 107, Richmond, Texas, 77407",
        "phone": "(346) 762-1700",
        "url": "https://locations.massageenvy.com/texas/richmond/10237-west-grand-parkway-south.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.6585887,
            -95.7105332
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 70.32
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.48,
                    "duration": "1 Months",
                    "model_conf": 152.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.75,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 95.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.19,
                    "duration": "1 Months",
                    "model_conf": 153.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 263.28
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 180.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 157.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.68,
                    "duration": "1 Months",
                    "model_conf": 154.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.01,
                    "duration": "2 Months",
                    "model_conf": 146.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.34,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 233.84
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 83.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 54.96
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.48,
                    "duration": "1 Months",
                    "model_conf": 203.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/rockwall/2951-ridge-rd.html": {
        "name": "Rockwall",
        "address": "2951 Ridge Rd Suite 101, Rockwall Rockwall, Texas, 75032",
        "phone": "(972) 722-9595",
        "url": "https://locations.massageenvy.com/texas/rockwall/2951-ridge-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.888533,
            -96.4722662
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.38,
                    "duration": "indefinitely",
                    "model_conf": 278.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.49,
                    "duration": "1 Months",
                    "model_conf": 165.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 167.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.49,
                    "duration": "1 Months",
                    "model_conf": 157.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/rowlett/4301-lakeview-parkway.html": {
        "name": "Rowlett Crossing",
        "address": "4301 Lakeview Parkway Ste 600, Rowlett Rowlett, Texas, 75089",
        "phone": "(972) 463-0101",
        "url": "https://locations.massageenvy.com/texas/rowlett/4301-lakeview-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            32.908385,
            -96.5617
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.13,
                    "duration": "indefinitely",
                    "model_conf": 260.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.96,
                    "duration": "indefinitely",
                    "model_conf": 184.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.49,
                    "duration": "1 Months",
                    "model_conf": 164.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.44,
                    "duration": "indefinitely",
                    "model_conf": 164.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.89,
                    "duration": "indefinitely",
                    "model_conf": 155.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.29,
                    "duration": "indefinitely",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.37,
                    "duration": "indefinitely",
                    "model_conf": 222.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 84.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 53.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.48,
                    "duration": "indefinitely",
                    "model_conf": 210.02
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/schertz/17460-ih-35.html": {
        "name": "Schertz",
        "address": "17460 IH 35 Ste 460, Schertz Schertz, Texas, 78154",
        "phone": "(210) 447-3689",
        "url": "https://locations.massageenvy.com/texas/schertz/17460-ih-35.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 5:00 PM",
            "TUESDAY": "10:00 AM - 5:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            29.59705484671052,
            -98.27746496537236
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.9,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.43,
                    "duration": "1 Months",
                    "model_conf": 280.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 194.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.8,
                    "duration": "indefinitely",
                    "model_conf": 165.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.16,
                    "duration": "indefinitely",
                    "model_conf": 167.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 155.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.52,
                    "duration": "1 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 231.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.35,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.13,
                    "duration": "indefinitely",
                    "model_conf": 55.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 220.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/sugar-land/19760-southwest-freeway.html": {
        "name": "Greatwood",
        "address": "19760 Southwest Freeway, Sugar land, Texas, 77479",
        "phone": "(281) 633-9500",
        "url": "https://locations.massageenvy.com/texas/sugar-land/19760-southwest-freeway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.56523,
            -95.68247
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.41,
                    "duration": "4 Months",
                    "model_conf": 278.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 163.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.75,
                    "duration": "1 Months",
                    "model_conf": 165.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 154.33
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/temple/3166-s-31st-street.html": {
        "name": "Temple - Belton",
        "address": "3166 S 31st Street, Temple Temple, Texas, 76502",
        "phone": "(254) 935-2055",
        "url": "https://locations.massageenvy.com/texas/temple/3166-s-31st-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.0725071398345,
            -97.37163573503494
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.93,
                    "duration": "indefinitely",
                    "model_conf": 282.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.51,
                    "duration": "indefinitely",
                    "model_conf": 195.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.71,
                    "duration": "indefinitely",
                    "model_conf": 167.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.99,
                    "duration": "indefinitely",
                    "model_conf": 168.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.65,
                    "duration": "indefinitely",
                    "model_conf": 157.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.12,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.56,
                    "duration": "indefinitely",
                    "model_conf": 238.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.54,
                    "duration": "1 Months",
                    "model_conf": 85.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.73,
                    "duration": "1 Months",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.43,
                    "duration": "indefinitely",
                    "model_conf": 221.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/tyler/8950-s.-broadway-ave..html": {
        "name": "Tyler",
        "address": "8950 S. Broadway Ave. Suite 104, Tyler Tyler, Texas, 75703",
        "phone": "(903) 509-3689",
        "url": "https://locations.massageenvy.com/texas/tyler/8950-s.-broadway-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.251615754408874,
            -95.30251101826934
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.49,
                    "duration": "1 Months",
                    "model_conf": 283.24
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.84,
                    "duration": "indefinitely",
                    "model_conf": 195.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.09,
                    "duration": "indefinitely",
                    "model_conf": 167.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.07,
                    "duration": "indefinitely",
                    "model_conf": 168.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.58,
                    "duration": "indefinitely",
                    "model_conf": 157.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.69,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.76,
                    "duration": "indefinitely",
                    "model_conf": 237.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.25,
                    "duration": "indefinitely",
                    "model_conf": 85.97
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.18,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.92,
                    "duration": "indefinitely",
                    "model_conf": 213.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/waco/2812-w-loop-340.html": {
        "name": "Waco",
        "address": "2812 W Loop 340, Waco, Texas, 76711",
        "phone": "(254) 662-3689",
        "url": "https://locations.massageenvy.com/texas/waco/2812-w-loop-340.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:30 PM",
            "TUESDAY": "9:00 AM - 8:30 PM",
            "WEDNESDAY": "9:00 AM - 8:30 PM",
            "THURSDAY": "9:00 AM - 8:30 PM",
            "FRIDAY": "9:00 AM - 8:30 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            31.4967194,
            -97.152098
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.49,
                    "duration": "1 Months",
                    "model_conf": 281.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.42,
                    "duration": "1 Months",
                    "model_conf": 182.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.91,
                    "duration": "indefinitely",
                    "model_conf": 157.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.3,
                    "duration": "3 Months",
                    "model_conf": 167.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.13,
                    "duration": "indefinitely",
                    "model_conf": 157.46
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.01,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.8,
                    "duration": "3 Months",
                    "model_conf": 236.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.71,
                    "duration": "indefinitely",
                    "model_conf": 85.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.75,
                    "duration": "1 Months",
                    "model_conf": 55.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.28,
                    "duration": "1 Months",
                    "model_conf": 221.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/waxahachie/1620-n-u.s.-highway-77.html": {
        "name": "Waxahachie",
        "address": "1620 N U.S. Highway 77 Suite 300, Waxahachie, Texas, 75165",
        "phone": "(469) 296-3689",
        "url": "https://locations.massageenvy.com/texas/waxahachie/1620-n-u.s.-highway-77.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "12:00 PM - 7:00 PM",
            "SUNDAY": "10:00 AM - 3:00 PM"
        },
        "geolocation": [
            32.4294687,
            -96.8380107
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.13,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.84,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.68,
                    "duration": "2 Months",
                    "model_conf": 102.56
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.07,
                    "duration": "indefinitely",
                    "model_conf": 155.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.46,
                    "duration": "1 Months",
                    "model_conf": 276.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.1,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.26,
                    "duration": "5 Months",
                    "model_conf": 168.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.24,
                    "duration": "indefinitely",
                    "model_conf": 157.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.18,
                    "duration": "indefinitely",
                    "model_conf": 141.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.03,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.2,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.44,
                    "duration": "1 Months",
                    "model_conf": 221.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/webster/943-w-bay-area-blvd.html": {
        "name": "Clear Lake",
        "address": "943 W Bay Area Blvd, Webster, Texas, 77598",
        "phone": "(281) 332-0808",
        "url": "https://locations.massageenvy.com/texas/webster/943-w-bay-area-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.541558259871746,
            -95.13687014579773
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 229.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.43,
                    "duration": "indefinitely",
                    "model_conf": 116.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.1,
                    "duration": "5 Months",
                    "model_conf": 151.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 124.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/wichita-falls/2905-garnett-ave.html": {
        "name": "Wichita Falls",
        "address": "2905 Garnett Ave, Wichita Falls, Texas, 76308",
        "phone": "(940) 767-3689",
        "url": "https://locations.massageenvy.com/texas/wichita-falls/2905-garnett-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            33.8846009,
            -98.525974
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/utah/american-fork/356-north-750-west.html": {
        "name": "American Fork",
        "address": "356 North 750 West Suite D-5, American Fork American Fork, Utah, 84003",
        "phone": "(801) 763-1351",
        "url": "https://locations.massageenvy.com/utah/american-fork/356-north-750-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            40.3842449,
            -111.818728
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.94,
                    "duration": "3 Months",
                    "model_conf": 155.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.81,
                    "duration": "4 Months",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.24,
                    "duration": "5 Months",
                    "model_conf": 100.7
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.33,
                    "duration": "1 Months",
                    "model_conf": 154.68
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.76,
                    "duration": "indefinitely",
                    "model_conf": 281.56
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 182.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.49,
                    "duration": "indefinitely",
                    "model_conf": 165.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.38,
                    "duration": "4 Months",
                    "model_conf": 162.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 157.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 141.14
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.43,
                    "duration": "1 Months",
                    "model_conf": 233.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.9,
                    "duration": "indefinitely",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.04,
                    "duration": "indefinitely",
                    "model_conf": 55.82
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.3,
                    "duration": "1 Months",
                    "model_conf": 215.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/bountiful/24-south-500-west.html": {
        "name": "Bountiful",
        "address": "24 South 500 West Ste B, Bountiful, Utah, 84010",
        "phone": "(801) 295-0290",
        "url": "https://locations.massageenvy.com/utah/bountiful/24-south-500-west.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.889152,
            -111.892578
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.79,
                    "duration": "indefinitely",
                    "model_conf": 280.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.01,
                    "duration": "indefinitely",
                    "model_conf": 191.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.82,
                    "duration": "indefinitely",
                    "model_conf": 166.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 164.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.41,
                    "duration": "indefinitely",
                    "model_conf": 157.04
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.43,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.55,
                    "duration": "1 Months",
                    "model_conf": 233.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.37,
                    "duration": "indefinitely",
                    "model_conf": 85.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.25,
                    "duration": "indefinitely",
                    "model_conf": 55.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.68,
                    "duration": "indefinitely",
                    "model_conf": 219.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/draper/219-e-12300-south.html": {
        "name": "Draper",
        "address": "219 E 12300 South Ste 16, Draper Draper, Utah, 84020",
        "phone": "(801) 495-4280",
        "url": "https://locations.massageenvy.com/utah/draper/219-e-12300-south.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.527643,
            -111.884687
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.91,
                    "duration": "5 Months",
                    "model_conf": 268.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.12,
                    "duration": "5 Months",
                    "model_conf": 188.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.31,
                    "duration": "indefinitely",
                    "model_conf": 161.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.43,
                    "duration": "8 Months",
                    "model_conf": 145.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 202.18,
                    "duration": "indefinitely",
                    "model_conf": 149.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.6,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.33,
                    "duration": "7 Months",
                    "model_conf": 230.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 83.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.24,
                    "duration": "indefinitely",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.16,
                    "duration": "3 Months",
                    "model_conf": 220.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/farmington/134-n-station-parkway.html": {
        "name": "Station Park",
        "address": "134 N Station Parkway, Farmington, Utah, 84025",
        "phone": "(385) 988-6406",
        "url": "https://locations.massageenvy.com/utah/farmington/134-n-station-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.983084,
            -111.903744
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 156.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.04,
                    "duration": "indefinitely",
                    "model_conf": 139.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.08,
                    "duration": "indefinitely",
                    "model_conf": 102.12
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 154.62
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 282.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 190.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.54,
                    "duration": "indefinitely",
                    "model_conf": 157.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.07,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 234.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 55.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 218.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/layton/519-ring-road.html": {
        "name": "Layton",
        "address": "519 Ring Road, Layton Layton, Utah, 84041",
        "phone": "(801) 336-3330",
        "url": "https://locations.massageenvy.com/utah/layton/519-ring-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 4:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "11:00 AM - 4:30 PM"
        },
        "geolocation": [
            41.0761426426668,
            -111.97558198834327
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.75,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.34,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.11,
                    "duration": "indefinitely",
                    "model_conf": 156.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.8,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.18,
                    "duration": "indefinitely",
                    "model_conf": 101.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.32,
                    "duration": "indefinitely",
                    "model_conf": 154.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.37,
                    "duration": "indefinitely",
                    "model_conf": 281.07
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.96,
                    "duration": "indefinitely",
                    "model_conf": 190.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.32,
                    "duration": "indefinitely",
                    "model_conf": 166.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.01,
                    "duration": "indefinitely",
                    "model_conf": 166.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.47,
                    "duration": "indefinitely",
                    "model_conf": 157.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.22,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.24,
                    "duration": "indefinitely",
                    "model_conf": 233.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.49,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.02,
                    "duration": "indefinitely",
                    "model_conf": 55.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.22,
                    "duration": "indefinitely",
                    "model_conf": 219.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/murray/6352-south-state-street.html": {
        "name": "Murray",
        "address": "6352 South State Street, Murray, Utah, 84107",
        "phone": "(801) 281-3689",
        "url": "https://locations.massageenvy.com/utah/murray/6352-south-state-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "9:00 AM - 4:00 PM"
        },
        "geolocation": [
            40.6336563,
            -111.890438
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.62,
                    "duration": "indefinitely",
                    "model_conf": 259.87
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.53,
                    "duration": "indefinitely",
                    "model_conf": 183.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 158.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 141.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.19,
                    "duration": "indefinitely",
                    "model_conf": 143.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/orem/1068-s-750-east.html": {
        "name": "Orem",
        "address": "1068 S 750 East, Orem, Utah, 84097",
        "phone": "(801) 616-4444",
        "url": "https://locations.massageenvy.com/utah/orem/1068-s-750-east.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.2772264,
            -111.6776153
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.91,
                    "duration": "2 Months",
                    "model_conf": 282.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.55,
                    "duration": "1 Months",
                    "model_conf": 190.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.4,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.47,
                    "duration": "2 Months",
                    "model_conf": 166.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.54,
                    "duration": "indefinitely",
                    "model_conf": 157.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.92,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.12,
                    "duration": "2 Months",
                    "model_conf": 237.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.84,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.96,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.35,
                    "duration": "1 Months",
                    "model_conf": 215.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/park-city/1476-newpark-blvd.html": {
        "name": "Park City",
        "address": "1476 Newpark Blvd Ste 70, Park City Park City, Utah, 84098",
        "phone": "(435) 658-3322",
        "url": "https://locations.massageenvy.com/utah/park-city/1476-newpark-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 4:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "9:30 AM - 6:30 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "11:00 AM - 5:30 PM"
        },
        "geolocation": [
            40.72200187449634,
            -111.5391137705002
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.9,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "82",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.62,
                    "duration": "indefinitely",
                    "model_conf": 140.59
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.37,
                    "duration": "indefinitely",
                    "model_conf": 86.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.81,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.06,
                    "duration": "indefinitely",
                    "model_conf": 221.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/riverdale/4097-riverdale-rd.html": {
        "name": "Riverdale",
        "address": "4097 Riverdale Rd, Riverdale, Utah, 84405",
        "phone": "(801) 399-5400",
        "url": "https://locations.massageenvy.com/utah/riverdale/4097-riverdale-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.1892748,
            -111.9834313
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 101.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.68,
                    "duration": "indefinitely",
                    "model_conf": 71.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.65,
                    "duration": "2 Months",
                    "model_conf": 102.23
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.86,
                    "duration": "indefinitely",
                    "model_conf": 154.87
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.02,
                    "duration": "indefinitely",
                    "model_conf": 282.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.7,
                    "duration": "indefinitely",
                    "model_conf": 166.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.59,
                    "duration": "2 Months",
                    "model_conf": 167.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.06,
                    "duration": "indefinitely",
                    "model_conf": 157.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.15,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.66,
                    "duration": "indefinitely",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.64,
                    "duration": "indefinitely",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.14,
                    "duration": "indefinitely",
                    "model_conf": 219.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/sandy/1838-e-9400-s.html": {
        "name": "Sandy",
        "address": "1838 E 9400 S, Sandy Sandy, Utah, 84093",
        "phone": "(801) 545-9111",
        "url": "https://locations.massageenvy.com/utah/sandy/1838-e-9400-s.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.5798804,
            -111.8385637
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.94,
                    "duration": "2 Months",
                    "model_conf": 262.26
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.02,
                    "duration": "2 Months",
                    "model_conf": 184.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.63,
                    "duration": "indefinitely",
                    "model_conf": 159.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.4,
                    "duration": "8 Months",
                    "model_conf": 141.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 145.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.94,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.51,
                    "duration": "4 Months",
                    "model_conf": 233.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 83.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.0,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.23,
                    "duration": "3 Months",
                    "model_conf": 219.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/south-jordan/11553-south-parkway-plaza-drive.html": {
        "name": "South Jordan",
        "address": "11553 South Parkway Plaza Drive, South Jordan South Jordan, Utah, 84095",
        "phone": "(801) 253-5330",
        "url": "https://locations.massageenvy.com/utah/south-jordan/11553-south-parkway-plaza-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "8:30 AM - 8:00 PM",
            "SUNDAY": "8:30 AM - 6:00 PM"
        },
        "geolocation": [
            40.54139297071043,
            -111.97930980978691
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 270.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.51,
                    "duration": "1 Months",
                    "model_conf": 187.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.29,
                    "duration": "indefinitely",
                    "model_conf": 162.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.31,
                    "duration": "3 Months",
                    "model_conf": 149.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.44,
                    "duration": "indefinitely",
                    "model_conf": 151.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.17,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.9,
                    "duration": "3 Months",
                    "model_conf": 231.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.29,
                    "duration": "indefinitely",
                    "model_conf": 84.07
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.01,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.37,
                    "duration": "1 Months",
                    "model_conf": 219.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/west-jordan/6884-south-redwood.html": {
        "name": "West Jordan",
        "address": "6884 South Redwood, West Jordan West Jordan, Utah, 84084",
        "phone": "(801) 495-4490",
        "url": "https://locations.massageenvy.com/utah/west-jordan/6884-south-redwood.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.6262127,
            -111.9405583
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.31,
                    "duration": "indefinitely",
                    "model_conf": 69.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 101.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.23,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.81,
                    "duration": "indefinitely",
                    "model_conf": 267.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.21,
                    "duration": "indefinitely",
                    "model_conf": 161.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 149.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.67,
                    "duration": "indefinitely",
                    "model_conf": 147.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.55,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 84.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 219.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/burke/5747-burke-centre-parkway-b.html": {
        "name": "Burke",
        "address": "5747 Burke Centre Parkway B, Burke Burke, Virginia, 22015",
        "phone": "(703) 239-0224",
        "url": "https://locations.massageenvy.com/virginia/burke/5747-burke-centre-parkway-b.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.799688,
            -77.32019
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.34,
                    "duration": "indefinitely",
                    "model_conf": 257.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.84,
                    "duration": "indefinitely",
                    "model_conf": 155.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.6,
                    "duration": "2 Months",
                    "model_conf": 149.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.89,
                    "duration": "2 Months",
                    "model_conf": 140.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.59,
                    "duration": "indefinitely",
                    "model_conf": 140.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.71,
                    "duration": "indefinitely",
                    "model_conf": 82.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.91,
                    "duration": "indefinitely",
                    "model_conf": 53.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.55,
                    "duration": "indefinitely",
                    "model_conf": 198.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/charlottesville/420-twentyninth-place-court.html": {
        "name": "Charlottesville",
        "address": "420 Twentyninth Place Court, Charlottesville Charlottesville, Virginia, 22901",
        "phone": "(434) 977-3689",
        "url": "https://locations.massageenvy.com/virginia/charlottesville/420-twentyninth-place-court.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.0774241,
            -78.4804081
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.07,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.63,
                    "duration": "indefinitely",
                    "model_conf": 153.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.45,
                    "duration": "indefinitely",
                    "model_conf": 101.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 153.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 268.9
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 186.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 167.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.55,
                    "duration": "indefinitely",
                    "model_conf": 167.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 152.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.57,
                    "duration": "1 Months",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 231.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 84.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 55.0
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.27,
                    "duration": "indefinitely",
                    "model_conf": 204.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/chesapeake/235-east-hanbury-rd.html": {
        "name": "Chesapeake",
        "address": "235 East Hanbury Rd Ste 4, Chesapeake Chesapeake, Virginia, 23322",
        "phone": "(757) 546-7373",
        "url": "https://locations.massageenvy.com/virginia/chesapeake/235-east-hanbury-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.68645087231612,
            -76.22382269963919
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 154.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 101.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.4,
                    "duration": "indefinitely",
                    "model_conf": 154.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.85,
                    "duration": "indefinitely",
                    "model_conf": 281.45
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.07,
                    "duration": "indefinitely",
                    "model_conf": 195.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.74,
                    "duration": "3 Months",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.26,
                    "duration": "indefinitely",
                    "model_conf": 165.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.16,
                    "duration": "1 Months",
                    "model_conf": 157.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.46,
                    "duration": "indefinitely",
                    "model_conf": 234.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.43,
                    "duration": "1 Months",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 55.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.97,
                    "duration": "indefinitely",
                    "model_conf": 219.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/dulles/24570-dulles-landing-dr..html": {
        "name": "Dulles South",
        "address": "24570 Dulles Landing Dr. Suite 160, Dulles, Virginia, 20166",
        "phone": "(703) 327-4432",
        "url": "https://locations.massageenvy.com/virginia/dulles/24570-dulles-landing-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.9376617,
            -77.5203288
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 69.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 90.88
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.33,
                    "duration": "indefinitely",
                    "model_conf": 150.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.22,
                    "duration": "2 Months",
                    "model_conf": 270.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 157.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 146.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.77,
                    "duration": "indefinitely",
                    "model_conf": 150.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.26,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 84.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 54.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.12,
                    "duration": "indefinitely",
                    "model_conf": 200.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/dumfries/4100-fortuna-center-plaza.html": {
        "name": "Dumfries",
        "address": "4100 Fortuna Center Plaza, Dumfries Dumfries, Virginia, 22025",
        "phone": "(703) 670-3689",
        "url": "https://locations.massageenvy.com/virginia/dumfries/4100-fortuna-center-plaza.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.596107,
            -77.331951
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.59,
                    "duration": "indefinitely",
                    "model_conf": 70.16
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 151.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.22,
                    "duration": "2 Months",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.5,
                    "duration": "indefinitely",
                    "model_conf": 94.4
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 153.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.29,
                    "duration": "indefinitely",
                    "model_conf": 263.12
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.46,
                    "duration": "indefinitely",
                    "model_conf": 191.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 160.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.51,
                    "duration": "indefinitely",
                    "model_conf": 160.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.14,
                    "duration": "1 Months",
                    "model_conf": 148.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.54,
                    "duration": "indefinitely",
                    "model_conf": 140.66
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.72,
                    "duration": "indefinitely",
                    "model_conf": 228.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 84.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 55.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.59,
                    "duration": "indefinitely",
                    "model_conf": 210.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/fairfax/12999-fair-lakes-shopping-center.html": {
        "name": "Fairfax/Fair Lakes",
        "address": "12999 Fair Lakes Shopping Center, Fairfax, Virginia, 22033",
        "phone": "(703) 322-9797",
        "url": "https://locations.massageenvy.com/virginia/fairfax/12999-fair-lakes-shopping-center.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.85765613972184,
            -77.38975457335371
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 69.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.9,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 85.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 148.67
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.11,
                    "duration": "2 Months",
                    "model_conf": 255.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.92,
                    "duration": "indefinitely",
                    "model_conf": 151.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.13,
                    "duration": "5 Months",
                    "model_conf": 141.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.93,
                    "duration": "indefinitely",
                    "model_conf": 140.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.53,
                    "duration": "indefinitely",
                    "model_conf": 140.31
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 82.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.52,
                    "duration": "indefinitely",
                    "model_conf": 52.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.03,
                    "duration": "1 Months",
                    "model_conf": 191.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/falls-church/7395-h-lee-highway.html": {
        "name": "Falls Church",
        "address": "7395 H Lee Highway Suite H, Falls Church Falls Church, Virginia, 22042",
        "phone": "(703) 226-8999",
        "url": "https://locations.massageenvy.com/virginia/falls-church/7395-h-lee-highway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.875191,
            -77.198055
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.03,
                    "duration": "indefinitely",
                    "model_conf": 233.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.46,
                    "duration": "1 Months",
                    "model_conf": 138.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.04,
                    "duration": "indefinitely",
                    "model_conf": 122.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.64,
                    "duration": "2 Months",
                    "model_conf": 133.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.6,
                    "duration": "indefinitely",
                    "model_conf": 137.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.78,
                    "duration": "indefinitely",
                    "model_conf": 79.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.3,
                    "duration": "indefinitely",
                    "model_conf": 50.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.41,
                    "duration": "1 Months",
                    "model_conf": 166.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/fredericksburg/1927-carl-d-silver-parkway.html": {
        "name": "Fredericksburg",
        "address": "1927 Carl D Silver Parkway, Fredericksburg, Virginia, 22401",
        "phone": "(540) 548-0747",
        "url": "https://locations.massageenvy.com/virginia/fredericksburg/1927-carl-d-silver-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.309725,
            -77.511112
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.64,
                    "duration": "1 Months",
                    "model_conf": 153.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 102.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.46,
                    "duration": "1 Months",
                    "model_conf": 154.13
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 282.08
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.8,
                    "duration": "indefinitely",
                    "model_conf": 182.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.72,
                    "duration": "1 Months",
                    "model_conf": 167.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 156.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 221.7
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 83.09
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 221.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/gainesville/5123-wellington-rd.html": {
        "name": "Gainesville",
        "address": "5123 Wellington Rd, Gainesville Gainesville, Virginia, 20155",
        "phone": "(703) 753-6024",
        "url": "https://locations.massageenvy.com/virginia/gainesville/5123-wellington-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7927139,
            -77.6046301
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.51,
                    "duration": "1 Months",
                    "model_conf": 70.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.21,
                    "duration": "indefinitely",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.64,
                    "duration": "2 Months",
                    "model_conf": 94.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.33,
                    "duration": "1 Months",
                    "model_conf": 151.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.01,
                    "duration": "2 Months",
                    "model_conf": 247.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 160.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.2,
                    "duration": "4 Months",
                    "model_conf": 154.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 132.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 83.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 54.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.15,
                    "duration": "1 Months",
                    "model_conf": 209.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/hampton/1220-merchant-ln.html": {
        "name": "Hampton",
        "address": "1220 Merchant Ln Ste C-110, Hampton, Virginia, 23666",
        "phone": "(757) 825-8300",
        "url": "https://locations.massageenvy.com/virginia/hampton/1220-merchant-ln.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:45 AM - 6:00 PM"
        },
        "geolocation": [
            37.045237745863105,
            -76.39181372883598
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.31,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.71,
                    "duration": "4 Months",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.02,
                    "duration": "indefinitely",
                    "model_conf": 102.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.05,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.96,
                    "duration": "indefinitely",
                    "model_conf": 281.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.8,
                    "duration": "indefinitely",
                    "model_conf": 166.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.88,
                    "duration": "indefinitely",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.93,
                    "duration": "indefinitely",
                    "model_conf": 157.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.82,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.61,
                    "duration": "1 Months",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.78,
                    "duration": "1 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.44,
                    "duration": "indefinitely",
                    "model_conf": 220.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/herndon/2557-john-milton-drive.html": {
        "name": "Herndon-Reston - Temporarily Closed",
        "address": "2557 John Milton Drive, Herndon, Virginia, 20171",
        "phone": "(703) 391-0123",
        "url": "https://locations.massageenvy.com/virginia/herndon/2557-john-milton-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.9263427,
            -77.3753898
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.96,
                    "duration": "indefinitely",
                    "model_conf": 68.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.93,
                    "duration": "indefinitely",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 82.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 146.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 247.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 146.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.75,
                    "duration": "1 Months",
                    "model_conf": 135.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 139.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 140.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 81.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 52.23
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 182.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/lake-ridge/2219-old-bridge-road.html": {
        "name": "Lake Ridge",
        "address": "2219 Old Bridge Road, Lake Ridge Lake Ridge, Virginia, 22192",
        "phone": "(703) 497-5567",
        "url": "https://locations.massageenvy.com/virginia/lake-ridge/2219-old-bridge-road.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 7:00 PM",
            "TUESDAY": "11:00 AM - 7:00 PM",
            "WEDNESDAY": "11:00 AM - 7:00 PM",
            "THURSDAY": "11:00 AM - 7:00 PM",
            "FRIDAY": "11:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.676758569127706,
            -77.28132311775005
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.55,
                    "duration": "indefinitely",
                    "model_conf": 70.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.06,
                    "duration": "2 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.58,
                    "duration": "indefinitely",
                    "model_conf": 93.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.49,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.6,
                    "duration": "indefinitely",
                    "model_conf": 255.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.48,
                    "duration": "indefinitely",
                    "model_conf": 156.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.83,
                    "duration": "indefinitely",
                    "model_conf": 158.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.03,
                    "duration": "indefinitely",
                    "model_conf": 149.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.49,
                    "duration": "indefinitely",
                    "model_conf": 140.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.57,
                    "duration": "1 Months",
                    "model_conf": 84.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.15,
                    "duration": "indefinitely",
                    "model_conf": 54.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.18,
                    "duration": "indefinitely",
                    "model_conf": 205.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/leesburg/1612-village-market-blvd.html": {
        "name": "Leesburg",
        "address": "1612 Village Market Blvd Ste 100, Leesburg, Virginia, 20176",
        "phone": "(571) 209-3689",
        "url": "https://locations.massageenvy.com/virginia/leesburg/1612-village-market-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.08784278015852,
            -77.52302926965058
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 70.73
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 155.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.61,
                    "duration": "2 Months",
                    "model_conf": 98.6
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.81,
                    "duration": "indefinitely",
                    "model_conf": 153.51
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.6,
                    "duration": "3 Months",
                    "model_conf": 279.41
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.67,
                    "duration": "3 Months",
                    "model_conf": 190.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 164.73
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 162.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.31,
                    "duration": "indefinitely",
                    "model_conf": 154.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.66,
                    "duration": "4 Months",
                    "model_conf": 232.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 85.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 215.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/lynchburg/3901-old-forest-rd.html": {
        "name": "Lynchburg",
        "address": "3901 Old Forest Rd Bldg G, Lynchburg Lynchburg, Virginia, 24501",
        "phone": "(434) 385-8600",
        "url": "https://locations.massageenvy.com/virginia/lynchburg/3901-old-forest-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.39802487558059,
            -79.22276446738914
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.725,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.93,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.78,
                    "duration": "4 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.8,
                    "duration": "1 Months",
                    "model_conf": 101.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.21,
                    "duration": "indefinitely",
                    "model_conf": 154.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.77,
                    "duration": "3 Months",
                    "model_conf": 280.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.58,
                    "duration": "indefinitely",
                    "model_conf": 166.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.71,
                    "duration": "2 Months",
                    "model_conf": 168.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.67,
                    "duration": "indefinitely",
                    "model_conf": 157.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.14,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.06,
                    "duration": "indefinitely",
                    "model_conf": 85.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.97,
                    "duration": "indefinitely",
                    "model_conf": 55.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.5,
                    "duration": "1 Months",
                    "model_conf": 221.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/manassas/10073-market-circle.html": {
        "name": "Manassas",
        "address": "10073 Market Circle, Manassas Manassas, Virginia, 20110",
        "phone": "(703) 396-8111",
        "url": "https://locations.massageenvy.com/virginia/manassas/10073-market-circle.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7398551,
            -77.4536798
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 70.59
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 97.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.9,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 270.21
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 184.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 162.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 160.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.68,
                    "duration": "indefinitely",
                    "model_conf": 151.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 227.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 84.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 55.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.55,
                    "duration": "indefinitely",
                    "model_conf": 213.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/mechanicsville/7236-bell-creek-rd.html": {
        "name": "Mechanicsville",
        "address": "7236 Bell Creek Rd Ste B, Mechanicsville, Virginia, 23111",
        "phone": "(804) 746-1100",
        "url": "https://locations.massageenvy.com/virginia/mechanicsville/7236-bell-creek-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.601109580237356,
            -77.34828135166359
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.51,
                    "duration": "1 Months",
                    "model_conf": 71.14
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 156.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 101.51
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.0,
                    "duration": "2 Months",
                    "model_conf": 154.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 272.54
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 194.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 163.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 166.38
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.1,
                    "duration": "1 Months",
                    "model_conf": 156.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.97,
                    "duration": "2 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 233.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 85.56
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 54.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 218.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/midlothian/5201-commonwealth-centre-parkway.html": {
        "name": "Commonwealth Centre",
        "address": "5201 Commonwealth Centre Parkway, Midlothian Midlothian, Virginia, 23112",
        "phone": "(804) 744-8440",
        "url": "https://locations.massageenvy.com/virginia/midlothian/5201-commonwealth-centre-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.417134,
            -77.628376
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 70.03
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.54,
                    "duration": "1 Months",
                    "model_conf": 155.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.76,
                    "duration": "1 Months",
                    "model_conf": 101.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.7,
                    "duration": "3 Months",
                    "model_conf": 279.09
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.17,
                    "duration": "2 Months",
                    "model_conf": 191.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.73,
                    "duration": "indefinitely",
                    "model_conf": 162.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.36,
                    "duration": "3 Months",
                    "model_conf": 158.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.83,
                    "duration": "indefinitely",
                    "model_conf": 143.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.04,
                    "duration": "2 Months",
                    "model_conf": 228.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 54.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.73,
                    "duration": "2 Months",
                    "model_conf": 216.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/newport-news/12080-jefferson-ave..html": {
        "name": "Newport News",
        "address": "12080 Jefferson Ave. Ste. 210, Newport News, Virginia, 23606",
        "phone": "(757) 881-9188",
        "url": "https://locations.massageenvy.com/virginia/newport-news/12080-jefferson-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:45 AM - 6:00 PM"
        },
        "geolocation": [
            37.103424,
            -76.4896061
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.5,
                    "duration": "indefinitely",
                    "model_conf": 71.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.38,
                    "duration": "5 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.19,
                    "duration": "indefinitely",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.88,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.56,
                    "duration": "indefinitely",
                    "model_conf": 282.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 166.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.93,
                    "duration": "indefinitely",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.57,
                    "duration": "indefinitely",
                    "model_conf": 157.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.82,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.92,
                    "duration": "indefinitely",
                    "model_conf": 221.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/reston/1412-north-point-village-center.html": {
        "name": "Reston - North Point",
        "address": "1412 North Point Village Center, Reston, Virginia, 20194",
        "phone": "(703) 689-2600",
        "url": "https://locations.massageenvy.com/virginia/reston/1412-north-point-village-center.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.979948,
            -77.348907
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/virginia/richmond/1601-willow-lawn-dr.html": {
        "name": "Richmond Willow Lawn",
        "address": "1601 Willow Lawn Dr Ste 304E, Richmond, Virginia, 23230",
        "phone": "(804) 440-0004",
        "url": "https://locations.massageenvy.com/virginia/richmond/1601-willow-lawn-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.58287453889076,
            -77.49674355432357
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 70.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.65,
                    "duration": "1 Months",
                    "model_conf": 147.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 94.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 151.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.61,
                    "duration": "indefinitely",
                    "model_conf": 263.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 183.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 157.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 154.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 148.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.8,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 214.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.51,
                    "duration": "1 Months",
                    "model_conf": 83.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 53.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 204.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/roanoke/2037-colonial-ave.-sw.html": {
        "name": "Roanoke",
        "address": "2037 Colonial Ave. SW, Roanoke, Virginia, 24015",
        "phone": "(540) 343-9600",
        "url": "https://locations.massageenvy.com/virginia/roanoke/2037-colonial-ave.-sw.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.25600248158006,
            -79.95594263076782
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.725,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.31,
                    "duration": "indefinitely",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.79,
                    "duration": "4 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.67,
                    "duration": "indefinitely",
                    "model_conf": 102.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.54,
                    "duration": "indefinitely",
                    "model_conf": 155.13
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.52,
                    "duration": "indefinitely",
                    "model_conf": 282.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.45,
                    "duration": "1 Months",
                    "model_conf": 167.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 56.29,
                    "duration": "indefinitely",
                    "model_conf": 167.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.22,
                    "duration": "1 Months",
                    "model_conf": 157.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.17,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.47,
                    "duration": "1 Months",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.51,
                    "duration": "3 Months",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.56,
                    "duration": "indefinitely",
                    "model_conf": 215.18
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/springfield/8428-old-keene-mill-rd.html": {
        "name": "Springfield West",
        "address": "8428 Old Keene Mill Rd, Springfield Springfield, Virginia, 22152",
        "phone": "(703) 789-9099",
        "url": "https://locations.massageenvy.com/virginia/springfield/8428-old-keene-mill-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7796567,
            -77.2364802
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 70.07
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.86,
                    "duration": "6 Months",
                    "model_conf": 140.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.9,
                    "duration": "6 Months",
                    "model_conf": 85.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.29,
                    "duration": "3 Months",
                    "model_conf": 150.26
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 249.55
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.23,
                    "duration": "2 Months",
                    "model_conf": 182.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.55,
                    "duration": "indefinitely",
                    "model_conf": 153.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.07,
                    "duration": "11 Months",
                    "model_conf": 147.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.34,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.91,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.9,
                    "duration": "3 Months",
                    "model_conf": 213.34
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 82.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 53.16
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.22,
                    "duration": "3 Months",
                    "model_conf": 195.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/stafford/325-garrisonville-rd.html": {
        "name": "Stafford",
        "address": "325 Garrisonville Rd Ste 101, Stafford Stafford, Virginia, 22554",
        "phone": "(540) 658-9999",
        "url": "https://locations.massageenvy.com/virginia/stafford/325-garrisonville-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.4748870838062,
            -77.42166326141177
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 157.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 97.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.21,
                    "duration": "1 Months",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 281.34
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 194.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 165.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 167.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 152.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.71,
                    "duration": "indefinitely",
                    "model_conf": 141.16
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 235.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.49,
                    "duration": "1 Months",
                    "model_conf": 84.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 54.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.34,
                    "duration": "1 Months",
                    "model_conf": 219.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/sterling/22000-dulles-retail-plaza.html": {
        "name": "Sterling",
        "address": "22000 Dulles Retail Plaza Suite 162, Sterling Sterling, Virginia, 20166",
        "phone": "(703) 230-3689",
        "url": "https://locations.massageenvy.com/virginia/sterling/22000-dulles-retail-plaza.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.005618904074545,
            -77.43774895337299
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 69.42
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.25,
                    "duration": "indefinitely",
                    "model_conf": 144.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.78,
                    "duration": "3 Months",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 89.94
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.04,
                    "duration": "indefinitely",
                    "model_conf": 150.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.0,
                    "duration": "2 Months",
                    "model_conf": 262.89
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.72,
                    "duration": "3 Months",
                    "model_conf": 170.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.75,
                    "duration": "indefinitely",
                    "model_conf": 155.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.74,
                    "duration": "1 Months",
                    "model_conf": 147.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.69,
                    "duration": "indefinitely",
                    "model_conf": 145.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 140.56
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.47,
                    "duration": "4 Months",
                    "model_conf": 218.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.53,
                    "duration": "indefinitely",
                    "model_conf": 83.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 53.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 194.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/suffolk/7386-harbour-towne-parkway.html": {
        "name": "Harbour View",
        "address": "7386 Harbour Towne Parkway Ste 25, Suffolk Suffolk, Virginia, 23435",
        "phone": "(757) 638-9888",
        "url": "https://locations.massageenvy.com/virginia/suffolk/7386-harbour-towne-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.875273662913685,
            -76.43572691704964
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.43,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 155.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.79,
                    "duration": "indefinitely",
                    "model_conf": 101.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.85,
                    "duration": "indefinitely",
                    "model_conf": 282.27
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.58,
                    "duration": "indefinitely",
                    "model_conf": 195.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 165.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.23,
                    "duration": "indefinitely",
                    "model_conf": 157.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.3,
                    "duration": "1 Months",
                    "model_conf": 156.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.28,
                    "duration": "1 Months",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.5,
                    "duration": "indefinitely",
                    "model_conf": 228.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.59,
                    "duration": "2 Months",
                    "model_conf": 55.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.55,
                    "duration": "indefinitely",
                    "model_conf": 221.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/vienna/126-branch-road.html": {
        "name": "Vienna",
        "address": "126 Branch Road Suite A, Vienna, Virginia, 22180",
        "phone": "(703) 319-3689",
        "url": "https://locations.massageenvy.com/virginia/vienna/126-branch-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.90654309625826,
            -77.25738399996601
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.63,
                    "duration": "indefinitely",
                    "model_conf": 236.08
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.96,
                    "duration": "indefinitely",
                    "model_conf": 136.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.26,
                    "duration": "4 Months",
                    "model_conf": 120.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.56,
                    "duration": "indefinitely",
                    "model_conf": 133.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.59,
                    "duration": "indefinitely",
                    "model_conf": 138.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.77,
                    "duration": "indefinitely",
                    "model_conf": 79.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.58,
                    "duration": "indefinitely",
                    "model_conf": 50.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.78,
                    "duration": "indefinitely",
                    "model_conf": 166.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/williamsburg/5107-main-street.html": {
        "name": "Williamsburg",
        "address": "5107 Main Street, Williamsburg, Virginia, 23188",
        "phone": "(757) 565-2800",
        "url": "https://locations.massageenvy.com/virginia/williamsburg/5107-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:45 AM - 6:00 PM"
        },
        "geolocation": [
            37.2778274,
            -76.739763
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.57,
                    "duration": "1 Months",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.74,
                    "duration": "indefinitely",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.07,
                    "duration": "indefinitely",
                    "model_conf": 101.54
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.67,
                    "duration": "indefinitely",
                    "model_conf": 154.63
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.17,
                    "duration": "2 Months",
                    "model_conf": 282.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.24,
                    "duration": "indefinitely",
                    "model_conf": 166.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.34,
                    "duration": "4 Months",
                    "model_conf": 167.97
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.93,
                    "duration": "indefinitely",
                    "model_conf": 157.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.44,
                    "duration": "indefinitely",
                    "model_conf": 141.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 86.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.09,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.28,
                    "duration": "1 Months",
                    "model_conf": 221.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/winchester/2638-s-pleasant-valley-rd.html": {
        "name": "Winchester",
        "address": "2638 S Pleasant Valley Rd, Winchester Winchester, Virginia, 22601",
        "phone": "(540) 401-0011",
        "url": "https://locations.massageenvy.com/virginia/winchester/2638-s-pleasant-valley-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.1517508,
            -78.1762235
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.34,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 158.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.88,
                    "duration": "indefinitely",
                    "model_conf": 102.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 154.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.12,
                    "duration": "2 Months",
                    "model_conf": 281.2
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 190.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.43,
                    "duration": "indefinitely",
                    "model_conf": 166.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.27,
                    "duration": "4 Months",
                    "model_conf": 167.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.17,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.85,
                    "duration": "3 Months",
                    "model_conf": 235.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 86.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 54.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.0,
                    "duration": "2 Months",
                    "model_conf": 221.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/woodbridge/15201-potomac-town-place.html": {
        "name": "Woodbridge - VA",
        "address": "15201 Potomac Town Place Suite 130, Woodbridge, Virginia, 22191",
        "phone": "(703) 672-3689",
        "url": "https://locations.massageenvy.com/virginia/woodbridge/15201-potomac-town-place.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.627335,
            -77.2869993
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.43,
                    "duration": "indefinitely",
                    "model_conf": 69.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 139.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.55,
                    "duration": "2 Months",
                    "model_conf": 90.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 152.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.82,
                    "duration": "indefinitely",
                    "model_conf": 251.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.62,
                    "duration": "indefinitely",
                    "model_conf": 156.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.82,
                    "duration": "7 Months",
                    "model_conf": 156.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 144.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.93,
                    "duration": "indefinitely",
                    "model_conf": 140.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 84.36
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 55.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.17,
                    "duration": "indefinitely",
                    "model_conf": 204.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/bellingham/330-36th-street.html": {
        "name": "Bellingham (TEMPORARILY CLOSED)",
        "address": "330 36th Street, Bellingham, Washington, 98225",
        "phone": "(360) 756-1100",
        "url": "https://locations.massageenvy.com/washington/bellingham/330-36th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "Closed",
            "WEDNESDAY": "Closed",
            "THURSDAY": "Closed",
            "FRIDAY": "Closed",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            48.73109,
            -122.473033
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.09,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.31,
                    "duration": "4 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.45,
                    "duration": "3 Months",
                    "model_conf": 102.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.44,
                    "duration": "indefinitely",
                    "model_conf": 155.1
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 163.53,
                    "duration": "3 Months",
                    "model_conf": 278.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 166.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.29,
                    "duration": "3 Months",
                    "model_conf": 165.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.99,
                    "duration": "indefinitely",
                    "model_conf": 157.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.07,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.13,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.07,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.26,
                    "duration": "1 Months",
                    "model_conf": 221.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/covington/27115-185th-ave-se.html": {
        "name": "Covington",
        "address": "27115 185th Ave SE Suite 107, Covington Covington, Washington, 98042",
        "phone": "(253) 236-1111",
        "url": "https://locations.massageenvy.com/washington/covington/27115-185th-ave-se.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.35828377521024,
            -122.09655313988117
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.04,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.86,
                    "duration": "indefinitely",
                    "model_conf": 158.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.03,
                    "duration": "4 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.29,
                    "duration": "indefinitely",
                    "model_conf": 102.12
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.31,
                    "duration": "indefinitely",
                    "model_conf": 155.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.89,
                    "duration": "indefinitely",
                    "model_conf": 280.75
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.59,
                    "duration": "indefinitely",
                    "model_conf": 190.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.73,
                    "duration": "3 Months",
                    "model_conf": 166.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.22,
                    "duration": "indefinitely",
                    "model_conf": 167.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.07,
                    "duration": "1 Months",
                    "model_conf": 157.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/everett/15-sw-everett-mall-way.html": {
        "name": "Everett Mall",
        "address": "15 SW Everett Mall Way Ste K, Everett, Washington, 98204",
        "phone": "(425) 353-5000",
        "url": "https://locations.massageenvy.com/washington/everett/15-sw-everett-mall-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.90759098968998,
            -122.23436723528698
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.8,
                    "duration": "indefinitely",
                    "model_conf": 70.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.78,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.93,
                    "duration": "indefinitely",
                    "model_conf": 102.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.3,
                    "duration": "1 Months",
                    "model_conf": 153.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.25,
                    "duration": "1 Months",
                    "model_conf": 275.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.13,
                    "duration": "indefinitely",
                    "model_conf": 166.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.54,
                    "duration": "2 Months",
                    "model_conf": 165.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.98,
                    "duration": "indefinitely",
                    "model_conf": 155.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.56,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.93,
                    "duration": "indefinitely",
                    "model_conf": 85.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.95,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.17,
                    "duration": "1 Months",
                    "model_conf": 220.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/federal-way/1409-s-348th-st.html": {
        "name": "Federal Way",
        "address": "1409 S 348th St Ste D-102, Federal Way, Washington, 98003",
        "phone": "(253) 517-0888",
        "url": "https://locations.massageenvy.com/washington/federal-way/1409-s-348th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.28789718245488,
            -122.31571227929486
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.07,
                    "duration": "indefinitely",
                    "model_conf": 71.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.68,
                    "duration": "indefinitely",
                    "model_conf": 139.54
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.13,
                    "duration": "indefinitely",
                    "model_conf": 101.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.05,
                    "duration": "indefinitely",
                    "model_conf": 154.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.82,
                    "duration": "indefinitely",
                    "model_conf": 282.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.21,
                    "duration": "1 Months",
                    "model_conf": 165.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.61,
                    "duration": "indefinitely",
                    "model_conf": 164.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.32,
                    "duration": "1 Months",
                    "model_conf": 157.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.97,
                    "duration": "indefinitely",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.54,
                    "duration": "1 Months",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.7,
                    "duration": "1 Months",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.83,
                    "duration": "indefinitely",
                    "model_conf": 220.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/gig-harbor/4641-pt-fosdick-dr-nw.html": {
        "name": "Gig Harbor",
        "address": "4641 Pt Fosdick Dr Nw Ste 300, Gig Harbor, Washington, 98335",
        "phone": "(253) 853-4555",
        "url": "https://locations.massageenvy.com/washington/gig-harbor/4641-pt-fosdick-dr-nw.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 5:00 PM",
            "WEDNESDAY": "9:00 AM - 5:00 PM",
            "THURSDAY": "9:00 AM - 5:00 PM",
            "FRIDAY": "9:00 AM - 5:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.300373,
            -122.575633
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.19,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.83,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.98,
                    "duration": "indefinitely",
                    "model_conf": 102.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.27,
                    "duration": "indefinitely",
                    "model_conf": 154.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.05,
                    "duration": "indefinitely",
                    "model_conf": 280.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.83,
                    "duration": "indefinitely",
                    "model_conf": 164.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.23,
                    "duration": "indefinitely",
                    "model_conf": 165.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.01,
                    "duration": "indefinitely",
                    "model_conf": 157.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.86,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.21,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.15,
                    "duration": "indefinitely",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.18,
                    "duration": "indefinitely",
                    "model_conf": 220.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/issaquah/735-nw-gilman-boulevard.html": {
        "name": "Issaquah",
        "address": "735 NW Gilman Boulevard Suite E, Issaquah, Washington, 98027",
        "phone": "(425) 391-4455",
        "url": "https://locations.massageenvy.com/washington/issaquah/735-nw-gilman-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 5:00 PM"
        },
        "geolocation": [
            47.5416693,
            -122.0495217
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.91,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.61,
                    "duration": "indefinitely",
                    "model_conf": 156.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.62,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.9,
                    "duration": "indefinitely",
                    "model_conf": 101.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.89,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.23,
                    "duration": "indefinitely",
                    "model_conf": 275.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.57,
                    "duration": "indefinitely",
                    "model_conf": 192.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.22,
                    "duration": "4 Months",
                    "model_conf": 161.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.2,
                    "duration": "indefinitely",
                    "model_conf": 164.81
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.68,
                    "duration": "2 Months",
                    "model_conf": 155.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/kennewick/924-n.-columbia-center-blvd..html": {
        "name": "Kennewick",
        "address": "924 N. Columbia Center Blvd., Kennewick, Washington, 99336",
        "phone": "(509) 736-5444",
        "url": "https://locations.massageenvy.com/washington/kennewick/924-n.-columbia-center-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            46.219882751786095,
            -119.22366127976221
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.6,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.03,
                    "duration": "indefinitely",
                    "model_conf": 71.26
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.26,
                    "duration": "2 Months",
                    "model_conf": 157.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.54,
                    "duration": "indefinitely",
                    "model_conf": 139.36
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.55,
                    "duration": "2 Months",
                    "model_conf": 102.4
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.46,
                    "duration": "indefinitely",
                    "model_conf": 154.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 163.32,
                    "duration": "3 Months",
                    "model_conf": 282.45
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.33,
                    "duration": "1 Months",
                    "model_conf": 188.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.08,
                    "duration": "indefinitely",
                    "model_conf": 163.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.21,
                    "duration": "4 Months",
                    "model_conf": 168.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.01,
                    "duration": "indefinitely",
                    "model_conf": 157.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.56,
                    "duration": "1 Months",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.73,
                    "duration": "3 Months",
                    "model_conf": 238.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.59,
                    "duration": "indefinitely",
                    "model_conf": 85.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.37,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.5,
                    "duration": "2 Months",
                    "model_conf": 221.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/lynnwood/2701-184th-st-sw.html": {
        "name": "Lynnwood",
        "address": "2701 184th St Sw Ste 122-C, Lynnwood Lynnwood, Washington, 98037",
        "phone": "(425) 409-5300",
        "url": "https://locations.massageenvy.com/washington/lynnwood/2701-184th-st-sw.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            47.832638631069685,
            -122.26911052847731
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.78,
                    "duration": "indefinitely",
                    "model_conf": 70.74
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.44,
                    "duration": "1 Months",
                    "model_conf": 153.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.39,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.92,
                    "duration": "indefinitely",
                    "model_conf": 101.94
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.92,
                    "duration": "1 Months",
                    "model_conf": 154.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.15,
                    "duration": "indefinitely",
                    "model_conf": 270.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.84,
                    "duration": "indefinitely",
                    "model_conf": 193.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.79,
                    "duration": "indefinitely",
                    "model_conf": 165.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.33,
                    "duration": "3 Months",
                    "model_conf": 163.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.82,
                    "duration": "1 Months",
                    "model_conf": 155.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/mill-creek/15111-main-street.html": {
        "name": "Mill Creek",
        "address": "15111 Main Street Suite A-102, Mill Creek Mill Creek, Washington, 98012",
        "phone": "(425) 341-2200",
        "url": "https://locations.massageenvy.com/washington/mill-creek/15111-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.86123581159751,
            -122.22022686867626
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.58,
                    "duration": "indefinitely",
                    "model_conf": 70.48
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.08,
                    "duration": "2 Months",
                    "model_conf": 153.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.98,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.61,
                    "duration": "1 Months",
                    "model_conf": 101.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.2,
                    "duration": "3 Months",
                    "model_conf": 153.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 183.48,
                    "duration": "3 Months",
                    "model_conf": 269.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.27,
                    "duration": "1 Months",
                    "model_conf": 192.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.54,
                    "duration": "indefinitely",
                    "model_conf": 166.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.8,
                    "duration": "5 Months",
                    "model_conf": 163.67
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.73,
                    "duration": "indefinitely",
                    "model_conf": 154.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/olympia/2505-4th-avenue-west.html": {
        "name": "Olympia",
        "address": "2505 4th Avenue West Suite 101, Olympia, Washington, 98502",
        "phone": "(360) 705-3689",
        "url": "https://locations.massageenvy.com/washington/olympia/2505-4th-avenue-west.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 3:30 PM"
        },
        "geolocation": [
            47.043629,
            -122.930742
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.27,
                    "duration": "1 Months",
                    "model_conf": 280.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.6,
                    "duration": "1 Months",
                    "model_conf": 167.07
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.75,
                    "duration": "1 Months",
                    "model_conf": 168.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.62,
                    "duration": "indefinitely",
                    "model_conf": 157.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.77,
                    "duration": "indefinitely",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 85.36
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.43,
                    "duration": "3 Months",
                    "model_conf": 221.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/puyallup/4423-s.-meridian.html": {
        "name": "Puyallup - South Hill",
        "address": "4423 S. Meridian Suite A-711, Puyallup, Washington, 98373",
        "phone": "(253) 841-0808",
        "url": "https://locations.massageenvy.com/washington/puyallup/4423-s.-meridian.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 7:00 PM",
            "WEDNESDAY": "8:00 AM - 7:00 PM",
            "THURSDAY": "8:00 AM - 7:00 PM",
            "FRIDAY": "8:00 AM - 7:00 PM",
            "SATURDAY": "8:00 AM - 5:00 PM",
            "SUNDAY": "8:00 AM - 5:00 PM"
        },
        "geolocation": [
            47.149633110409646,
            -122.2916846527786
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.55,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.88,
                    "duration": "indefinitely",
                    "model_conf": 157.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.04,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.45,
                    "duration": "indefinitely",
                    "model_conf": 101.66
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.81,
                    "duration": "indefinitely",
                    "model_conf": 155.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.47,
                    "duration": "indefinitely",
                    "model_conf": 281.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.03,
                    "duration": "indefinitely",
                    "model_conf": 191.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.96,
                    "duration": "indefinitely",
                    "model_conf": 165.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.03,
                    "duration": "indefinitely",
                    "model_conf": 164.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.79,
                    "duration": "indefinitely",
                    "model_conf": 157.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/redmond/17214-redmond-way.html": {
        "name": "Redmond",
        "address": "17214 Redmond Way, Redmond, Washington, 98052",
        "phone": "(425) 558-9399",
        "url": "https://locations.massageenvy.com/washington/redmond/17214-redmond-way.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.6726594,
            -122.1099878
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 71.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.55,
                    "duration": "2 Months",
                    "model_conf": 101.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.0,
                    "duration": "4 Months",
                    "model_conf": 148.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.76,
                    "duration": "indefinitely",
                    "model_conf": 249.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 140.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.87,
                    "duration": "6 Months",
                    "model_conf": 152.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.28,
                    "duration": "1 Months",
                    "model_conf": 148.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.97,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 53.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.2,
                    "duration": "1 Months",
                    "model_conf": 206.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/seattle/4-west-crockett-street.html": {
        "name": "Queen Anne",
        "address": "4 West Crockett Street, Seattle, Washington, 98119",
        "phone": "(206) 283-1199",
        "url": "https://locations.massageenvy.com/washington/seattle/4-west-crockett-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 6:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.6372938,
            -122.3573096
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.16,
                    "duration": "1 Months",
                    "model_conf": 70.81
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.64,
                    "duration": "indefinitely",
                    "model_conf": 150.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.55,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.26,
                    "duration": "indefinitely",
                    "model_conf": 101.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.58,
                    "duration": "indefinitely",
                    "model_conf": 153.3
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.09,
                    "duration": "indefinitely",
                    "model_conf": 267.13
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.2,
                    "duration": "indefinitely",
                    "model_conf": 191.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.65,
                    "duration": "3 Months",
                    "model_conf": 161.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.31,
                    "duration": "indefinitely",
                    "model_conf": 159.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.08,
                    "duration": "1 Months",
                    "model_conf": 153.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/shoreline/20124-ballinger-way-ne.html": {
        "name": "Shoreline",
        "address": "20124 Ballinger Way NE Suite A-02, Shoreline, Washington, 98155",
        "phone": "(206) 366-1111",
        "url": "https://locations.massageenvy.com/washington/shoreline/20124-ballinger-way-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 5:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.776015,
            -122.310239
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.77,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.21,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.33,
                    "duration": "indefinitely",
                    "model_conf": 101.82
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.01,
                    "duration": "indefinitely",
                    "model_conf": 153.98
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.71,
                    "duration": "indefinitely",
                    "model_conf": 275.93
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.8,
                    "duration": "2 Months",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.3,
                    "duration": "indefinitely",
                    "model_conf": 163.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.0,
                    "duration": "1 Months",
                    "model_conf": 156.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.86,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.94,
                    "duration": "indefinitely",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.06,
                    "duration": "indefinitely",
                    "model_conf": 55.04
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.67,
                    "duration": "indefinitely",
                    "model_conf": 219.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/silverdale/3036-nw-bucklin-hill-road.html": {
        "name": "Silverdale",
        "address": "3036 NW Bucklin Hill Road, Silverdale Silverdale, Washington, 98383",
        "phone": "(360) 698-0311",
        "url": "https://locations.massageenvy.com/washington/silverdale/3036-nw-bucklin-hill-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            47.650836,
            -122.689707
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.28,
                    "duration": "indefinitely",
                    "model_conf": 71.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.79,
                    "duration": "2 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.75,
                    "duration": "1 Months",
                    "model_conf": 102.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.28,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.95,
                    "duration": "indefinitely",
                    "model_conf": 282.86
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.96,
                    "duration": "indefinitely",
                    "model_conf": 166.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.77,
                    "duration": "1 Months",
                    "model_conf": 164.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.01,
                    "duration": "indefinitely",
                    "model_conf": 157.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.71,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.87,
                    "duration": "indefinitely",
                    "model_conf": 85.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.93,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.18,
                    "duration": "indefinitely",
                    "model_conf": 214.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/tacoma/4502-s-steele-street.html": {
        "name": "Tacoma",
        "address": "4502 S Steele Street, Tacoma Tacoma, Washington, 98409",
        "phone": "(253) 471-2588",
        "url": "https://locations.massageenvy.com/washington/tacoma/4502-s-steele-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.21609941849348,
            -122.46883320534965
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 117.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": "102",
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": "160",
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": "135",
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": "51",
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": "90",
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.14,
                    "duration": "1 Months",
                    "model_conf": 71.36
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.92,
                    "duration": "indefinitely",
                    "model_conf": 158.07
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 162.26,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.72,
                    "duration": "1 Months",
                    "model_conf": 102.04
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.18,
                    "duration": "3 Months",
                    "model_conf": 155.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.09,
                    "duration": "indefinitely",
                    "model_conf": 282.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.62,
                    "duration": "indefinitely",
                    "model_conf": 194.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.93,
                    "duration": "2 Months",
                    "model_conf": 165.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.61,
                    "duration": "1 Months",
                    "model_conf": 165.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.1,
                    "duration": "3 Months",
                    "model_conf": 157.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/woodinville/14213-ne-woodinville-duvall-rd.html": {
        "name": "Woodinville",
        "address": "14213 NE Woodinville-Duvall Rd, Woodinville Woodinville, Washington, 98072",
        "phone": "(425) 368-0800",
        "url": "https://locations.massageenvy.com/washington/woodinville/14213-ne-woodinville-duvall-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 3:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            47.75421243036747,
            -122.14891612921843
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 122.125,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "170",
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "132",
                        "servicePrice": "107",
                        "memberAdditional": "97",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "167"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "95",
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": "143",
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": null,
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "95",
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "43",
                        "memberAdditional": "43",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "43"
                    },
                    "serviceTime": "30min",
                    "accrual": "43"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "60",
                        "servicePrice": "54",
                        "memberAdditional": "49",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "78"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": "143",
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "95",
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.94,
                    "duration": "indefinitely",
                    "model_conf": 71.29
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.39,
                    "duration": "3 Months",
                    "model_conf": 156.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.71,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 84.42,
                    "duration": "2 Months",
                    "model_conf": 102.04
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 238.08,
                    "duration": "3 Months",
                    "model_conf": 153.45
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 195.33,
                    "duration": "indefinitely",
                    "model_conf": 273.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.01,
                    "duration": "indefinitely",
                    "model_conf": 162.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 83.87,
                    "duration": "5 Months",
                    "model_conf": 164.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 239.58,
                    "duration": "indefinitely",
                    "model_conf": 155.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/west-virginia/barboursville/950-lauren-christian-drive.html": {
        "name": "Barboursville",
        "address": "950 Lauren Christian Drive, Barboursville Barboursville, West Virginia, 25504",
        "phone": "(304) 410-0900",
        "url": "https://locations.massageenvy.com/west-virginia/barboursville/950-lauren-christian-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.421096360244114,
            -82.28734391934267
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.0,
                    "duration": "indefinitely",
                    "model_conf": 71.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.94,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.94,
                    "duration": "indefinitely",
                    "model_conf": 102.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.52,
                    "duration": "1 Months",
                    "model_conf": 155.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.52,
                    "duration": "1 Months",
                    "model_conf": 282.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.03,
                    "duration": "indefinitely",
                    "model_conf": 166.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.48,
                    "duration": "4 Months",
                    "model_conf": 168.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.51,
                    "duration": "indefinitely",
                    "model_conf": 157.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.27,
                    "duration": "2 Months",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.49,
                    "duration": "2 Months",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.74,
                    "duration": "2 Months",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.32,
                    "duration": "1 Months",
                    "model_conf": 219.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/west-virginia/charleston/5707-maccorkle-ave-se.html": {
        "name": "Charleston",
        "address": "5707 Maccorkle Ave Se Ste 370, Charleston Charleston, West Virginia, 25304",
        "phone": "(304) 925-3689",
        "url": "https://locations.massageenvy.com/west-virginia/charleston/5707-maccorkle-ave-se.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:30 PM",
            "TUESDAY": "9:00 AM - 8:30 PM",
            "WEDNESDAY": "9:00 AM - 8:30 PM",
            "THURSDAY": "9:00 AM - 8:30 PM",
            "FRIDAY": "9:00 AM - 8:30 PM",
            "SATURDAY": "9:00 AM - 6:30 PM",
            "SUNDAY": "9:00 AM - 6:30 PM"
        },
        "geolocation": [
            38.3113897,
            -81.5690093
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 79.975,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "87",
                        "servicePrice": null,
                        "memberAdditional": "52",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "122"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": null,
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "160"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": null,
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "113",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "165"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": null,
                        "memberAdditional": "26",
                        "memberUpgrade": "20",
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "38",
                        "servicePrice": null,
                        "memberAdditional": "26",
                        "memberUpgrade": null,
                        "nonMemberPrice": "55"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "75",
                        "servicePrice": null,
                        "memberAdditional": "40",
                        "memberUpgrade": null,
                        "nonMemberPrice": "110"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 115.3,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 79.15,
                    "duration": "4 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 39.87,
                    "duration": "1 Months",
                    "model_conf": 102.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.39,
                    "duration": "indefinitely",
                    "model_conf": 154.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 114.84,
                    "duration": "indefinitely",
                    "model_conf": 280.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.19,
                    "duration": "indefinitely",
                    "model_conf": 166.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 39.72,
                    "duration": "2 Months",
                    "model_conf": 168.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 160.33,
                    "duration": "indefinitely",
                    "model_conf": 157.51
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 115.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 114.84,
                    "duration": "indefinitely",
                    "model_conf": 141.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.03,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 40.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 40.04,
                    "duration": "indefinitely",
                    "model_conf": 55.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.91,
                    "duration": "indefinitely",
                    "model_conf": 213.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/west-virginia/morgantown/343-patteson-drive.html": {
        "name": "Morgantown",
        "address": "343 Patteson Drive, Morgantown, West Virginia, 26505",
        "phone": "(304) 291-3200",
        "url": "https://locations.massageenvy.com/west-virginia/morgantown/343-patteson-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.65122,
            -79.970444
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.29,
                    "duration": "2 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.47,
                    "duration": "indefinitely",
                    "model_conf": 102.56
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 155.15
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.26,
                    "duration": "indefinitely",
                    "model_conf": 283.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 166.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 161.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 157.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 85.08
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.14,
                    "duration": "indefinitely",
                    "model_conf": 221.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/altoona/3085-meadowlark-lane.html": {
        "name": "River Prairie",
        "address": "3085 Meadowlark Lane Suite 70, Altoona, Wisconsin, 54720",
        "phone": "(715) 598-8125",
        "url": "https://locations.massageenvy.com/wisconsin/altoona/3085-meadowlark-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 4:00 PM",
            "SUNDAY": "8:00 AM - 4:00 PM"
        },
        "geolocation": [
            44.8198616,
            -91.4601924
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.55,
                    "duration": "indefinitely",
                    "model_conf": 282.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 166.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.8,
                    "duration": "indefinitely",
                    "model_conf": 165.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.54,
                    "duration": "indefinitely",
                    "model_conf": 157.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/appleton/3201-e-calumet-street.html": {
        "name": "Calumet",
        "address": "3201 E Calumet Street, Appleton Appleton, Wisconsin, 54915",
        "phone": "(920) 731-5300",
        "url": "https://locations.massageenvy.com/wisconsin/appleton/3201-e-calumet-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.243468310451014,
            -88.36102205794032
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 98.95,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.78,
                    "duration": "5 Months",
                    "model_conf": 281.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.53,
                    "duration": "4 Months",
                    "model_conf": 186.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.23,
                    "duration": "indefinitely",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.41,
                    "duration": "9 Months",
                    "model_conf": 168.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.06,
                    "duration": "indefinitely",
                    "model_conf": 155.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.99,
                    "duration": "6 Months",
                    "model_conf": 237.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.44,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 55.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.79,
                    "duration": "4 Months",
                    "model_conf": 221.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/delafield/2920-golf-road.html": {
        "name": "Delafield",
        "address": "2920 Golf Road, Delafield, Wisconsin, 53018",
        "phone": "(262) 646-6369",
        "url": "https://locations.massageenvy.com/wisconsin/delafield/2920-golf-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.053628,
            -88.371288
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.94,
                    "duration": "2 Months",
                    "model_conf": 281.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 194.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 165.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 166.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.93,
                    "duration": "indefinitely",
                    "model_conf": 156.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/green-bay/1241-lombardi-access-road.html": {
        "name": "Lombardi",
        "address": "1241 Lombardi Access Road Ste G, Green Bay, Wisconsin, 54304",
        "phone": "(920) 405-9200",
        "url": "https://locations.massageenvy.com/wisconsin/green-bay/1241-lombardi-access-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.5056604352905,
            -88.07351331368149
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 98.95,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 283.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.16,
                    "duration": "indefinitely",
                    "model_conf": 195.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 167.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 168.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 157.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 141.22
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 238.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.04,
                    "duration": "indefinitely",
                    "model_conf": 55.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 220.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/greenfield/7985-west-layton-avenue.html": {
        "name": "Greenfield",
        "address": "7985 West Layton Avenue, Greenfield Greenfield, Wisconsin, 53220",
        "phone": "(414) 325-3689",
        "url": "https://locations.massageenvy.com/wisconsin/greenfield/7985-west-layton-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            42.9586323,
            -88.0122595
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.44,
                    "duration": "indefinitely",
                    "model_conf": 139.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.11,
                    "duration": "indefinitely",
                    "model_conf": 100.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 154.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.53,
                    "duration": "indefinitely",
                    "model_conf": 279.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.12,
                    "duration": "5 Months",
                    "model_conf": 166.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.6,
                    "duration": "indefinitely",
                    "model_conf": 165.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.33,
                    "duration": "3 Months",
                    "model_conf": 156.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.96,
                    "duration": "2 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 85.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.06,
                    "duration": "indefinitely",
                    "model_conf": 218.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/madison/4624-east-washington-avenue.html": {
        "name": "East Washington",
        "address": "4624 East Washington Avenue, Madison, Wisconsin, 53704",
        "phone": "(608) 663-9300",
        "url": "https://locations.massageenvy.com/wisconsin/madison/4624-east-washington-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.130446,
            -89.305864
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 98.95,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.86,
                    "duration": "indefinitely",
                    "model_conf": 282.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.88,
                    "duration": "indefinitely",
                    "model_conf": 194.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 165.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 168.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.4,
                    "duration": "3 Months",
                    "model_conf": 157.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.5,
                    "duration": "1 Months",
                    "model_conf": 141.18
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.11,
                    "duration": "indefinitely",
                    "model_conf": 238.16
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 85.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.78,
                    "duration": "1 Months",
                    "model_conf": 55.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.52,
                    "duration": "indefinitely",
                    "model_conf": 221.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/mequon/10960-n-port-washington-rd.html": {
        "name": "Mequon",
        "address": "10960 N Port Washington Rd, Mequon, Wisconsin, 53092",
        "phone": "(262) 241-3689",
        "url": "https://locations.massageenvy.com/wisconsin/mequon/10960-n-port-washington-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.21762,
            -87.922263
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.81,
                    "duration": "indefinitely",
                    "model_conf": 71.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.5,
                    "duration": "indefinitely",
                    "model_conf": 102.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.01,
                    "duration": "indefinitely",
                    "model_conf": 280.95
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.06,
                    "duration": "5 Months",
                    "model_conf": 165.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.25,
                    "duration": "indefinitely",
                    "model_conf": 167.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.3,
                    "duration": "3 Months",
                    "model_conf": 157.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 141.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.19,
                    "duration": "2 Months",
                    "model_conf": 85.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.5,
                    "duration": "2 Months",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.92,
                    "duration": "indefinitely",
                    "model_conf": 220.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/middleton/8301-murphy-dr.html": {
        "name": "Middleton",
        "address": "8301 Murphy Dr Ste 200, Middleton, Wisconsin, 53562",
        "phone": "(608) 833-9500",
        "url": "https://locations.massageenvy.com/wisconsin/middleton/8301-murphy-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.09752,
            -89.524365
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 98.95,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.0,
                    "duration": "indefinitely",
                    "model_conf": 274.62
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.17,
                    "duration": "indefinitely",
                    "model_conf": 194.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.73,
                    "duration": "indefinitely",
                    "model_conf": 167.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.38,
                    "duration": "1 Months",
                    "model_conf": 157.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.23,
                    "duration": "2 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.19,
                    "duration": "indefinitely",
                    "model_conf": 236.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 82.52
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 55.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 221.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/pleasant-prairie/9160-a-76th-street.html": {
        "name": "Pleasant Prairie",
        "address": "9160 A 76Th Street, Pleasant Prairie Pleasant Prairie, Wisconsin, 53158",
        "phone": "(262) 289-3689",
        "url": "https://locations.massageenvy.com/wisconsin/pleasant-prairie/9160-a-76th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            42.5659141,
            -87.9188461
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.9,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.75,
                    "duration": "6 Months",
                    "model_conf": 282.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.48,
                    "duration": "4 Months",
                    "model_conf": 193.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.68,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.97,
                    "duration": "7 Months",
                    "model_conf": 168.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.37,
                    "duration": "indefinitely",
                    "model_conf": 157.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.89,
                    "duration": "6 Months",
                    "model_conf": 237.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.96,
                    "duration": "indefinitely",
                    "model_conf": 85.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.43,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 220.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/birmingham/250-doug-baker-blvd.html": {
        "name": "Greystone",
        "address": "250 Doug Baker Blvd Ste 500, Birmingham, Alabama, 35242",
        "phone": "(205) 408-1882",
        "url": "https://locations.massageenvy.com/alabama/birmingham/250-doug-baker-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.4106609290185,
            -86.6670136324956
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.17,
                    "duration": "indefinitely",
                    "model_conf": 282.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.0,
                    "duration": "indefinitely",
                    "model_conf": 165.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.84,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.19,
                    "duration": "indefinitely",
                    "model_conf": 155.46
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.94,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.7,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.96,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.62,
                    "duration": "indefinitely",
                    "model_conf": 219.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/birmingham/310-rele-street.html": {
        "name": "Mountain Brook",
        "address": "310 Rele Street, Birmingham, Alabama, 35223",
        "phone": "(205) 834-8140",
        "url": "https://locations.massageenvy.com/alabama/birmingham/310-rele-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.4866042,
            -86.7736893
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.16,
                    "duration": "2 Months",
                    "model_conf": 277.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 162.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.9,
                    "duration": "indefinitely",
                    "model_conf": 168.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.4,
                    "duration": "indefinitely",
                    "model_conf": 155.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.24,
                    "duration": "indefinitely",
                    "model_conf": 84.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.31,
                    "duration": "indefinitely",
                    "model_conf": 54.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.37,
                    "duration": "indefinitely",
                    "model_conf": 209.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/mobile/3966-airport-boulevard.html": {
        "name": "Mobile",
        "address": "3966 Airport Boulevard, Mobile Mobile, Alabama, 36608",
        "phone": "(251) 316-3110",
        "url": "https://locations.massageenvy.com/alabama/mobile/3966-airport-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.6770253,
            -88.1504483
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.17,
                    "duration": "2 Months",
                    "model_conf": 280.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.86,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.06,
                    "duration": "6 Months",
                    "model_conf": 168.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.8,
                    "duration": "indefinitely",
                    "model_conf": 157.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.57,
                    "duration": "1 Months",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.37,
                    "duration": "2 Months",
                    "model_conf": 84.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.58,
                    "duration": "2 Months",
                    "model_conf": 55.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.72,
                    "duration": "indefinitely",
                    "model_conf": 214.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/alabama/mobile/7765-airport-blvd..html": {
        "name": "West Mobile",
        "address": "7765 Airport Blvd. Ste. 150, Mobile Mobile, Alabama, 36608",
        "phone": "(251) 607-7007",
        "url": "https://locations.massageenvy.com/alabama/mobile/7765-airport-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.681717396220318,
            -88.22388374587206
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.425,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.46,
                    "duration": "1 Months",
                    "model_conf": 278.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.92,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.09,
                    "duration": "5 Months",
                    "model_conf": 168.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.65,
                    "duration": "indefinitely",
                    "model_conf": 157.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.88,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.44,
                    "duration": "1 Months",
                    "model_conf": 84.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.67,
                    "duration": "2 Months",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.83,
                    "duration": "indefinitely",
                    "model_conf": 213.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/chandler/2510-west-chandler-blvd.html": {
        "name": "Chandler",
        "address": "2510 West Chandler Blvd Suite 7, Chandler, Arizona, 85224",
        "phone": "(480) 812-3689",
        "url": "https://locations.massageenvy.com/arizona/chandler/2510-west-chandler-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.30653114031349,
            -111.88556428632847
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.47,
                    "duration": "1 Months",
                    "model_conf": 68.43
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.72,
                    "duration": "3 Months",
                    "model_conf": 138.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.66,
                    "duration": "1 Months",
                    "model_conf": 139.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.41,
                    "duration": "3 Months",
                    "model_conf": 83.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.28,
                    "duration": "1 Months",
                    "model_conf": 146.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.63,
                    "duration": "3 Months",
                    "model_conf": 241.38
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.12,
                    "duration": "2 Months",
                    "model_conf": 163.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 146.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.36,
                    "duration": "3 Months",
                    "model_conf": 137.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.88,
                    "duration": "indefinitely",
                    "model_conf": 139.88
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 140.55
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.69,
                    "duration": "3 Months",
                    "model_conf": 194.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 80.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 51.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 181.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/chandler/2880-e-germann-rd.html": {
        "name": "Chandler Crossroads",
        "address": "2880 E Germann Rd Ste 7, Chandler Chandler, Arizona, 85286",
        "phone": "(480) 857-8888",
        "url": "https://locations.massageenvy.com/arizona/chandler/2880-e-germann-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.2813197,
            -111.7909417
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 67.97
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.84,
                    "duration": "6 Months",
                    "model_conf": 134.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 139.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.97,
                    "duration": "6 Months",
                    "model_conf": 82.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.42,
                    "duration": "3 Months",
                    "model_conf": 144.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.45,
                    "duration": "1 Months",
                    "model_conf": 241.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 163.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.16,
                    "duration": "indefinitely",
                    "model_conf": 145.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.53,
                    "duration": "8 Months",
                    "model_conf": 134.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 139.27
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 139.88
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.85,
                    "duration": "3 Months",
                    "model_conf": 183.57
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 80.39
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 51.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.4,
                    "duration": "3 Months",
                    "model_conf": 166.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/chandler/4985-s-alma-school-rd.html": {
        "name": "South Chandler",
        "address": "4985 S Alma School Rd #1, Chandler Chandler, Arizona, 85248",
        "phone": "(480) 802-1919",
        "url": "https://locations.massageenvy.com/arizona/chandler/4985-s-alma-school-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.2346264,
            -111.8566481
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.69,
                    "duration": "indefinitely",
                    "model_conf": 69.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.58,
                    "duration": "4 Months",
                    "model_conf": 148.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 139.51
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 92.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.66,
                    "duration": "2 Months",
                    "model_conf": 150.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 258.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 178.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 155.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.55,
                    "duration": "2 Months",
                    "model_conf": 151.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.93,
                    "duration": "2 Months",
                    "model_conf": 148.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 140.46
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 212.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.46,
                    "duration": "1 Months",
                    "model_conf": 82.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.5,
                    "duration": "2 Months",
                    "model_conf": 53.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.51,
                    "duration": "indefinitely",
                    "model_conf": 196.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/gilbert/1458-n-higley-road.html": {
        "name": "Gilbert Highland",
        "address": "1458 N Higley Road Ste 103, Gilbert, Arizona, 85234",
        "phone": "(480) 832-3689",
        "url": "https://locations.massageenvy.com/arizona/gilbert/1458-n-higley-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.376742,
            -111.72285
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 69.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 139.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.45,
                    "duration": "3 Months",
                    "model_conf": 94.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 150.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 264.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 158.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.41,
                    "duration": "3 Months",
                    "model_conf": 153.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 149.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.12,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 83.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 53.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.87,
                    "duration": "2 Months",
                    "model_conf": 201.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/gilbert/2270-williams-field-rd.html": {
        "name": "Gilbert - San Tan",
        "address": "2270 Williams Field Rd Ste. 110, Gilbert Gilbert, Arizona, 85295",
        "phone": "(480) 855-9910",
        "url": "https://locations.massageenvy.com/arizona/gilbert/2270-williams-field-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.311340423315464,
            -111.74161443735875
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.8,
                    "duration": "indefinitely",
                    "model_conf": 68.63
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.83,
                    "duration": "6 Months",
                    "model_conf": 140.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 139.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.75,
                    "duration": "7 Months",
                    "model_conf": 86.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.21,
                    "duration": "3 Months",
                    "model_conf": 146.41
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.56,
                    "duration": "3 Months",
                    "model_conf": 249.13
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.67,
                    "duration": "3 Months",
                    "model_conf": 169.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.58,
                    "duration": "indefinitely",
                    "model_conf": 150.18
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.15,
                    "duration": "11 Months",
                    "model_conf": 141.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 143.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 140.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.91,
                    "duration": "6 Months",
                    "model_conf": 193.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 81.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.12,
                    "duration": "indefinitely",
                    "model_conf": 51.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.17,
                    "duration": "5 Months",
                    "model_conf": 175.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/gilbert/865-north-gilbert-rd.html": {
        "name": "Gilbert",
        "address": "865 North Gilbert Rd Ste 102-103, Gilbert, Arizona, 85234",
        "phone": "(480) 889-0770",
        "url": "https://locations.massageenvy.com/arizona/gilbert/865-north-gilbert-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.36613,
            -111.789217
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 68.12
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.62,
                    "duration": "4 Months",
                    "model_conf": 136.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.1,
                    "duration": "2 Months",
                    "model_conf": 139.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.22,
                    "duration": "4 Months",
                    "model_conf": 82.02
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.28,
                    "duration": "1 Months",
                    "model_conf": 145.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.55,
                    "duration": "3 Months",
                    "model_conf": 236.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.99,
                    "duration": "3 Months",
                    "model_conf": 162.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.38,
                    "duration": "indefinitely",
                    "model_conf": 144.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.25,
                    "duration": "4 Months",
                    "model_conf": 134.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.28,
                    "duration": "indefinitely",
                    "model_conf": 138.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.13,
                    "duration": "2 Months",
                    "model_conf": 140.47
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.5,
                    "duration": "4 Months",
                    "model_conf": 188.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 80.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 50.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.54,
                    "duration": "2 Months",
                    "model_conf": 173.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/glendale/5143-w.-olive-ave..html": {
        "name": "Glendale - AZ",
        "address": "5143 W. Olive Ave. STE 100, Glendale Glendale, Arizona, 85302",
        "phone": "(623) 931-3689",
        "url": "https://locations.massageenvy.com/arizona/glendale/5143-w.-olive-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.567115796217074,
            -112.17026267773696
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 70.84
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 98.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.98,
                    "duration": "2 Months",
                    "model_conf": 153.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 272.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 162.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.3,
                    "duration": "4 Months",
                    "model_conf": 161.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.38,
                    "duration": "1 Months",
                    "model_conf": 152.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.45,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 84.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 54.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.47,
                    "duration": "1 Months",
                    "model_conf": 212.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/glendale/8110-west-bell-road.html": {
        "name": "Arrowhead",
        "address": "8110 West Bell Road, Glendale Glendale, Arizona, 85308",
        "phone": "(623) 889-1646",
        "url": "https://locations.massageenvy.com/arizona/glendale/8110-west-bell-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.6402468,
            -112.231414
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.45,
                    "duration": "indefinitely",
                    "model_conf": 70.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.53,
                    "duration": "indefinitely",
                    "model_conf": 157.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.97,
                    "duration": "indefinitely",
                    "model_conf": 100.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.28,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.53,
                    "duration": "indefinitely",
                    "model_conf": 274.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.39,
                    "duration": "indefinitely",
                    "model_conf": 185.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.86,
                    "duration": "3 Months",
                    "model_conf": 163.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.57,
                    "duration": "indefinitely",
                    "model_conf": 164.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.34,
                    "duration": "3 Months",
                    "model_conf": 155.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.5,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.8,
                    "duration": "indefinitely",
                    "model_conf": 234.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 84.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.38,
                    "duration": "indefinitely",
                    "model_conf": 216.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/mesa/1657-south-dobson-rd.html": {
        "name": "Dobson",
        "address": "1657 South Dobson Rd Ste B101, Mesa Mesa, Arizona, 85202",
        "phone": "(480) 839-3689",
        "url": "https://locations.massageenvy.com/arizona/mesa/1657-south-dobson-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.383314,
            -111.874489
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.34,
                    "duration": "1 Months",
                    "model_conf": 67.9
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.91,
                    "duration": "indefinitely",
                    "model_conf": 139.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 80.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 145.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.45,
                    "duration": "4 Months",
                    "model_conf": 233.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 142.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 131.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 135.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.35,
                    "duration": "1 Months",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 79.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 50.34
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.05,
                    "duration": "1 Months",
                    "model_conf": 168.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/mesa/1946-south-signal-butte-rd.html": {
        "name": "Superstition Gateway",
        "address": "1946 South Signal Butte Rd Ste 103, Mesa Mesa, Arizona, 85209",
        "phone": "(480) 986-3689",
        "url": "https://locations.massageenvy.com/arizona/mesa/1946-south-signal-butte-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.381890119801064,
            -111.60299511903173
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.36,
                    "duration": "indefinitely",
                    "model_conf": 71.18
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.78,
                    "duration": "1 Months",
                    "model_conf": 101.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 154.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.15,
                    "duration": "indefinitely",
                    "model_conf": 280.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.87,
                    "duration": "indefinitely",
                    "model_conf": 165.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 163.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 156.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.4,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 85.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 55.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.38,
                    "duration": "1 Months",
                    "model_conf": 217.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/mesa/6626-east-mckellips-rd.html": {
        "name": "Mesa",
        "address": "6626 East Mckellips Rd Ste #105, Mesa, Arizona, 85215",
        "phone": "(480) 889-0761",
        "url": "https://locations.massageenvy.com/arizona/mesa/6626-east-mckellips-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.453458,
            -111.687884
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 100.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.22,
                    "duration": "indefinitely",
                    "model_conf": 154.07
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.54,
                    "duration": "1 Months",
                    "model_conf": 277.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.69,
                    "duration": "indefinitely",
                    "model_conf": 164.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 160.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.92,
                    "duration": "indefinitely",
                    "model_conf": 155.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.49,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.44,
                    "duration": "indefinitely",
                    "model_conf": 55.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 217.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/peoria/10006-w-happy-valley-parkway.html": {
        "name": "Lake Pleasant",
        "address": "10006 W Happy Valley Parkway #1240, Peoria Peoria, Arizona, 85383",
        "phone": "(623) 537-3689",
        "url": "https://locations.massageenvy.com/arizona/peoria/10006-w-happy-valley-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            33.7129942,
            -112.2746845
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.53,
                    "duration": "indefinitely",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 157.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.43,
                    "duration": "indefinitely",
                    "model_conf": 101.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 153.16
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.56,
                    "duration": "indefinitely",
                    "model_conf": 277.76
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.02,
                    "duration": "indefinitely",
                    "model_conf": 188.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 164.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.87,
                    "duration": "indefinitely",
                    "model_conf": 165.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 156.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.16,
                    "duration": "indefinitely",
                    "model_conf": 235.64
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 85.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.54,
                    "duration": "indefinitely",
                    "model_conf": 216.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/peoria/9744-w-northern-ave.html": {
        "name": "Peoria - Park West",
        "address": "9744 W Northern Ave STE 1340, Peoria Peoria, Arizona, 85345",
        "phone": "(623) 872-3689",
        "url": "https://locations.massageenvy.com/arizona/peoria/9744-w-northern-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.55310274122692,
            -112.26895223632539
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.67,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.49,
                    "duration": "3 Months",
                    "model_conf": 100.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.83,
                    "duration": "2 Months",
                    "model_conf": 154.06
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 277.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 164.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.85,
                    "duration": "6 Months",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.73,
                    "duration": "indefinitely",
                    "model_conf": 155.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.49,
                    "duration": "indefinitely",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 85.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 55.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.98,
                    "duration": "2 Months",
                    "model_conf": 218.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/13637-north-tatum-blvd.html": {
        "name": "Paradise Valley",
        "address": "13637 North Tatum Blvd Suite 16, Phoenix Phoenix, Arizona, 85032",
        "phone": "(602) 992-4400",
        "url": "https://locations.massageenvy.com/arizona/phoenix/13637-north-tatum-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.61089340632017,
            -111.97622840168596
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.89,
                    "duration": "indefinitely",
                    "model_conf": 65.93
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.54,
                    "duration": "indefinitely",
                    "model_conf": 113.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 138.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.59,
                    "duration": "indefinitely",
                    "model_conf": 62.17
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.65,
                    "duration": "indefinitely",
                    "model_conf": 135.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.7,
                    "duration": "indefinitely",
                    "model_conf": 211.75
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.36,
                    "duration": "indefinitely",
                    "model_conf": 134.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.78,
                    "duration": "3 Months",
                    "model_conf": 124.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.3,
                    "duration": "indefinitely",
                    "model_conf": 99.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.28,
                    "duration": "3 Months",
                    "model_conf": 123.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.55,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.96,
                    "duration": "indefinitely",
                    "model_conf": 150.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 76.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.01,
                    "duration": "5 Months",
                    "model_conf": 48.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.07,
                    "duration": "indefinitely",
                    "model_conf": 138.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/1660-e-camelback-rd.html": {
        "name": "Camelback",
        "address": "1660 E Camelback Rd Ste #195, Phoenix, Arizona, 85016",
        "phone": "(602) 222-3689",
        "url": "https://locations.massageenvy.com/arizona/phoenix/1660-e-camelback-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.510362,
            -112.046094
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.57,
                    "duration": "1 Months",
                    "model_conf": 65.79
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.28,
                    "duration": "2 Months",
                    "model_conf": 118.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.33,
                    "duration": "indefinitely",
                    "model_conf": 139.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 62.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.92,
                    "duration": "2 Months",
                    "model_conf": 141.72
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.3,
                    "duration": "1 Months",
                    "model_conf": 197.47
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 128.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 125.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.8,
                    "duration": "7 Months",
                    "model_conf": 103.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.07,
                    "duration": "indefinitely",
                    "model_conf": 117.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.51,
                    "duration": "indefinitely",
                    "model_conf": 140.6
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.2,
                    "duration": "2 Months",
                    "model_conf": 138.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 74.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 46.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.6,
                    "duration": "2 Months",
                    "model_conf": 135.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/21001-n-tatum-boulevard.html": {
        "name": "Desert Ridge",
        "address": "21001 N Tatum Boulevard Suite Z-1, Phoenix, Arizona, 85050",
        "phone": "(480) 585-1855",
        "url": "https://locations.massageenvy.com/arizona/phoenix/21001-n-tatum-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.674043,
            -111.971644
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 68.12
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 137.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 139.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 83.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.12,
                    "duration": "indefinitely",
                    "model_conf": 146.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.31,
                    "duration": "1 Months",
                    "model_conf": 243.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.48,
                    "duration": "1 Months",
                    "model_conf": 163.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 146.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.75,
                    "duration": "1 Months",
                    "model_conf": 137.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 139.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.98,
                    "duration": "indefinitely",
                    "model_conf": 140.41
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.84,
                    "duration": "indefinitely",
                    "model_conf": 195.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 81.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 51.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 181.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/2330-w-happy-valley-rd.html": {
        "name": "North Phoenix",
        "address": "2330 W Happy Valley Rd Ste 1021, Phoenix Phoenix, Arizona, 85085",
        "phone": "(623) 780-3689",
        "url": "https://locations.massageenvy.com/arizona/phoenix/2330-w-happy-valley-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.714198444934105,
            -112.1094573461877
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.85,
                    "duration": "indefinitely",
                    "model_conf": 70.64
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 156.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.69,
                    "duration": "3 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 99.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.79,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.65,
                    "duration": "indefinitely",
                    "model_conf": 274.35
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.14,
                    "duration": "indefinitely",
                    "model_conf": 186.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 162.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.09,
                    "duration": "indefinitely",
                    "model_conf": 163.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.75,
                    "duration": "2 Months",
                    "model_conf": 154.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.69,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.55,
                    "duration": "indefinitely",
                    "model_conf": 232.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 84.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.48,
                    "duration": "3 Months",
                    "model_conf": 55.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.24,
                    "duration": "indefinitely",
                    "model_conf": 214.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/275-e-bell-rd.html": {
        "name": "Moon Valley",
        "address": "275 E Bell Rd Suite 102, Phoenix Phoenix, Arizona, 85022",
        "phone": "(602) 866-3689",
        "url": "https://locations.massageenvy.com/arizona/phoenix/275-e-bell-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.63902406694267,
            -112.07042732038684
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.87,
                    "duration": "indefinitely",
                    "model_conf": 69.93
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 150.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.07,
                    "duration": "2 Months",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.87,
                    "duration": "indefinitely",
                    "model_conf": 93.85
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 150.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.94,
                    "duration": "indefinitely",
                    "model_conf": 263.54
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.49,
                    "duration": "indefinitely",
                    "model_conf": 176.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 157.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.41,
                    "duration": "indefinitely",
                    "model_conf": 155.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 148.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.24,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.86,
                    "duration": "indefinitely",
                    "model_conf": 219.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 83.33
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.37,
                    "duration": "3 Months",
                    "model_conf": 53.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.4,
                    "duration": "indefinitely",
                    "model_conf": 202.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/3162-e.-indian-school-rd.html": {
        "name": "Arcadia",
        "address": "3162 E. Indian School Rd, Phoenix Phoenix, Arizona, 85016",
        "phone": "(602) 468-3689",
        "url": "https://locations.massageenvy.com/arizona/phoenix/3162-e.-indian-school-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.49597787136822,
            -112.01437737483906
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.44,
                    "duration": "1 Months",
                    "model_conf": 65.6
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.15,
                    "duration": "5 Months",
                    "model_conf": 117.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 139.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.91,
                    "duration": "6 Months",
                    "model_conf": 60.93
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.43,
                    "duration": "3 Months",
                    "model_conf": 141.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.99,
                    "duration": "5 Months",
                    "model_conf": 192.89
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.35,
                    "duration": "4 Months",
                    "model_conf": 123.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.33,
                    "duration": "indefinitely",
                    "model_conf": 124.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.09,
                    "duration": "11 Months",
                    "model_conf": 99.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 115.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.36,
                    "duration": "indefinitely",
                    "model_conf": 140.54
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.91,
                    "duration": "6 Months",
                    "model_conf": 133.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 74.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 46.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.7,
                    "duration": "4 Months",
                    "model_conf": 129.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/4722-e-ray-rd.html": {
        "name": "Phoenix - Ahwatukee",
        "address": "4722 E Ray Rd Ste 22 & 24, Phoenix, Arizona, 85044",
        "phone": "(480) 759-3689",
        "url": "https://locations.massageenvy.com/arizona/phoenix/4722-e-ray-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.3215380054021,
            -111.98182198229858
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 69.4
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 147.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.99,
                    "duration": "indefinitely",
                    "model_conf": 92.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.07,
                    "duration": "indefinitely",
                    "model_conf": 149.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.51,
                    "duration": "6 Months",
                    "model_conf": 256.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.55,
                    "duration": "4 Months",
                    "model_conf": 173.95
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 155.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 150.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.46,
                    "duration": "indefinitely",
                    "model_conf": 147.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.14,
                    "duration": "7 Months",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.79,
                    "duration": "3 Months",
                    "model_conf": 210.62
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.93,
                    "duration": "indefinitely",
                    "model_conf": 82.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.46,
                    "duration": "indefinitely",
                    "model_conf": 52.73
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.45,
                    "duration": "1 Months",
                    "model_conf": 195.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/phoenix/7620-s.-59th-avenue.html": {
        "name": "Laveen",
        "address": "7620 S. 59th Avenue Suite #102, Phoenix, Arizona, 85339",
        "phone": "(602) 218-5333",
        "url": "https://locations.massageenvy.com/arizona/phoenix/7620-s.-59th-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.37678690415605,
            -112.18563385337293
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 71.1
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 157.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.47,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.65,
                    "duration": "indefinitely",
                    "model_conf": 100.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.7,
                    "duration": "indefinitely",
                    "model_conf": 278.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.48,
                    "duration": "indefinitely",
                    "model_conf": 191.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 165.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 165.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.92,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.48,
                    "duration": "indefinitely",
                    "model_conf": 234.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 85.35
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.78,
                    "duration": "indefinitely",
                    "model_conf": 216.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/2765-north-scottsdale-road.html": {
        "name": "Scottsdale Thomas",
        "address": "2765 North Scottsdale Road #101, Scottsdale Scottsdale, Arizona, 85257",
        "phone": "(480) 990-3689",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/2765-north-scottsdale-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.4793671,
            -111.9247231
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.42,
                    "duration": "1 Months",
                    "model_conf": 69.21
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 145.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 139.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 90.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 150.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.91,
                    "duration": "2 Months",
                    "model_conf": 253.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.28,
                    "duration": "2 Months",
                    "model_conf": 172.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.39,
                    "duration": "1 Months",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.74,
                    "duration": "1 Months",
                    "model_conf": 149.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.68,
                    "duration": "indefinitely",
                    "model_conf": 145.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 206.44
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 81.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.29,
                    "duration": "indefinitely",
                    "model_conf": 52.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 192.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/32607-north-scottsdale-rd.html": {
        "name": "Carefree",
        "address": "32607 North Scottsdale Rd Ste #101, Scottsdale, Arizona, 85262",
        "phone": "(480) 437-1463",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/32607-north-scottsdale-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.780758,
            -111.9241865
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 155.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 99.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.31,
                    "duration": "indefinitely",
                    "model_conf": 154.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 276.52
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 191.46
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 164.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 162.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.17,
                    "duration": "indefinitely",
                    "model_conf": 155.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 227.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 85.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 55.38
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 215.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/7000-e-mayo-blvd.html": {
        "name": "Scottsdale 101",
        "address": "7000 E Mayo Blvd Ste 1052, Phoenix Phoenix, Arizona, 85054",
        "phone": "(480) 502-8787",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/7000-e-mayo-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.6578645359731,
            -111.92999843187715
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 66.72
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 124.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 139.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.73,
                    "duration": "indefinitely",
                    "model_conf": 72.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.75,
                    "duration": "indefinitely",
                    "model_conf": 141.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.37,
                    "duration": "indefinitely",
                    "model_conf": 222.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 145.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 134.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.26,
                    "duration": "indefinitely",
                    "model_conf": 116.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.02,
                    "duration": "2 Months",
                    "model_conf": 130.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 140.17
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.04,
                    "duration": "indefinitely",
                    "model_conf": 168.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.29,
                    "duration": "2 Months",
                    "model_conf": 78.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 49.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.69,
                    "duration": "indefinitely",
                    "model_conf": 157.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/7001-n-scottsdale-road.html": {
        "name": "Scottsdale Seville",
        "address": "7001 N Scottsdale Road Ste 158, Scottsdale Scottsdale, Arizona, 85253",
        "phone": "(480) 443-3689",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/7001-n-scottsdale-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.539365,
            -111.924146
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.64,
                    "duration": "indefinitely",
                    "model_conf": 68.85
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.46,
                    "duration": "4 Months",
                    "model_conf": 142.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.62,
                    "duration": "indefinitely",
                    "model_conf": 139.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.44,
                    "duration": "3 Months",
                    "model_conf": 87.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.48,
                    "duration": "3 Months",
                    "model_conf": 148.32
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.93,
                    "duration": "2 Months",
                    "model_conf": 248.38
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.86,
                    "duration": "3 Months",
                    "model_conf": 167.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 150.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.61,
                    "duration": "8 Months",
                    "model_conf": 144.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 142.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.3,
                    "duration": "indefinitely",
                    "model_conf": 140.38
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.0,
                    "duration": "2 Months",
                    "model_conf": 202.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 81.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 52.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.07,
                    "duration": "3 Months",
                    "model_conf": 187.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/8698-e.-raintree-dr..html": {
        "name": "Raintree",
        "address": "8698 E. Raintree Dr. Suite 105, Scottsdale, Arizona, 85260",
        "phone": "(480) 481-2445",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/8698-e.-raintree-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.621040912002385,
            -111.89310729503632
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.94,
                    "duration": "2 Months",
                    "model_conf": 66.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 139.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.49,
                    "duration": "3 Months",
                    "model_conf": 72.95
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 142.48
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.46,
                    "duration": "4 Months",
                    "model_conf": 221.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.64,
                    "duration": "4 Months",
                    "model_conf": 135.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.45,
                    "duration": "3 Months",
                    "model_conf": 118.67
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 128.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.27,
                    "duration": "1 Months",
                    "model_conf": 140.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 78.26
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 48.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.63,
                    "duration": "2 Months",
                    "model_conf": 158.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/scottsdale/8752-east-shea-blvd.html": {
        "name": "Shea",
        "address": "8752 East Shea Blvd Ste #C-3B, Scottsdale, Arizona, 85260",
        "phone": "(480) 367-6762",
        "url": "https://locations.massageenvy.com/arizona/scottsdale/8752-east-shea-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.5834838,
            -111.8927933
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.22,
                    "duration": "2 Months",
                    "model_conf": 68.0
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.25,
                    "duration": "5 Months",
                    "model_conf": 136.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 139.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.81,
                    "duration": "7 Months",
                    "model_conf": 81.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.58,
                    "duration": "2 Months",
                    "model_conf": 145.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.68,
                    "duration": "8 Months",
                    "model_conf": 237.55
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.57,
                    "duration": "7 Months",
                    "model_conf": 156.38
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.74,
                    "duration": "indefinitely",
                    "model_conf": 144.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.61,
                    "duration": "indefinitely",
                    "model_conf": 134.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.45,
                    "duration": "indefinitely",
                    "model_conf": 137.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 140.39
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.9,
                    "duration": "9 Months",
                    "model_conf": 190.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 80.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 51.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 186.75,
                    "duration": "6 Months",
                    "model_conf": 177.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tempe/1900-e-rio-salado-parkway.html": {
        "name": "Tempe Marketplace",
        "address": "1900 E Rio Salado Parkway Ste 150, Tempe Tempe, Arizona, 85281",
        "phone": "(480) 446-3689",
        "url": "https://locations.massageenvy.com/arizona/tempe/1900-e-rio-salado-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.4303887,
            -111.9035828
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.05,
                    "duration": "2 Months",
                    "model_conf": 68.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.2,
                    "duration": "indefinitely",
                    "model_conf": 139.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 86.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 148.39
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.77,
                    "duration": "5 Months",
                    "model_conf": 245.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 150.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.13,
                    "duration": "5 Months",
                    "model_conf": 142.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.0,
                    "duration": "indefinitely",
                    "model_conf": 141.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.88,
                    "duration": "3 Months",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.65,
                    "duration": "1 Months",
                    "model_conf": 81.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 51.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.18,
                    "duration": "3 Months",
                    "model_conf": 176.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tempe/315-west-elliot-road.html": {
        "name": "Tempe",
        "address": "315 West Elliot Road Suite 103, Tempe, Arizona, 85284",
        "phone": "(480) 783-8700",
        "url": "https://locations.massageenvy.com/arizona/tempe/315-west-elliot-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.348671,
            -111.944103
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.29,
                    "duration": "1 Months",
                    "model_conf": 68.42
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.1,
                    "duration": "2 Months",
                    "model_conf": 141.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.88,
                    "duration": "indefinitely",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.01,
                    "duration": "5 Months",
                    "model_conf": 86.24
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 147.36
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 244.1
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.32,
                    "duration": "8 Months",
                    "model_conf": 164.4
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.14,
                    "duration": "indefinitely",
                    "model_conf": 149.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.47,
                    "duration": "9 Months",
                    "model_conf": 140.9
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.27,
                    "duration": "indefinitely",
                    "model_conf": 140.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.04,
                    "duration": "2 Months",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.15,
                    "duration": "11 Months",
                    "model_conf": 193.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.59,
                    "duration": "indefinitely",
                    "model_conf": 80.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.81,
                    "duration": "indefinitely",
                    "model_conf": 51.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.21,
                    "duration": "5 Months",
                    "model_conf": 179.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tucson/3854-w-river-road.html": {
        "name": "Tucson - River Road West",
        "address": "3854 W River Road, Tucson, Arizona, 85741",
        "phone": "(520) 777-9193",
        "url": "https://locations.massageenvy.com/arizona/tucson/3854-w-river-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.3221439,
            -111.045375
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 71.14
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 155.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 101.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.57,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 260.21
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 176.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 163.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.67,
                    "duration": "indefinitely",
                    "model_conf": 164.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 148.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.4,
                    "duration": "indefinitely",
                    "model_conf": 227.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 85.51
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 54.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.7,
                    "duration": "indefinitely",
                    "model_conf": 215.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tucson/4749-e-sunrise-dr.html": {
        "name": "Tucson Foothills",
        "address": "4749 E Sunrise Dr Suite #103, Tucson, Arizona, 85718",
        "phone": "(520) 777-9183",
        "url": "https://locations.massageenvy.com/arizona/tucson/4749-e-sunrise-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.309742386837634,
            -110.89135303443808
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.12,
                    "duration": "indefinitely",
                    "model_conf": 71.07
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.66,
                    "duration": "indefinitely",
                    "model_conf": 156.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.75,
                    "duration": "indefinitely",
                    "model_conf": 102.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.96,
                    "duration": "indefinitely",
                    "model_conf": 152.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.7,
                    "duration": "indefinitely",
                    "model_conf": 257.14
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.67,
                    "duration": "indefinitely",
                    "model_conf": 173.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.77,
                    "duration": "6 Months",
                    "model_conf": 162.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.26,
                    "duration": "indefinitely",
                    "model_conf": 163.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.89,
                    "duration": "4 Months",
                    "model_conf": 147.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.78,
                    "duration": "indefinitely",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.51,
                    "duration": "indefinitely",
                    "model_conf": 221.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 85.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 54.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.66,
                    "duration": "indefinitely",
                    "model_conf": 214.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tucson/6303-e.-broadway-blvd.html": {
        "name": "Tucson - Wilmot",
        "address": "6303 E. Broadway Blvd #121, Tucson Tucson, Arizona, 85710",
        "phone": "(520) 347-6817",
        "url": "https://locations.massageenvy.com/arizona/tucson/6303-e.-broadway-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.22166474605088,
            -110.85745618402672
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.37,
                    "duration": "indefinitely",
                    "model_conf": 69.12
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.54,
                    "duration": "indefinitely",
                    "model_conf": 155.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.86,
                    "duration": "indefinitely",
                    "model_conf": 98.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.78,
                    "duration": "indefinitely",
                    "model_conf": 151.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.57,
                    "duration": "indefinitely",
                    "model_conf": 251.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.85,
                    "duration": "indefinitely",
                    "model_conf": 155.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.42,
                    "duration": "4 Months",
                    "model_conf": 153.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.97,
                    "duration": "indefinitely",
                    "model_conf": 157.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 141.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.22,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.41,
                    "duration": "indefinitely",
                    "model_conf": 226.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 83.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 54.2
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.17,
                    "duration": "indefinitely",
                    "model_conf": 209.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/arizona/tucson/9630-e-22nd-st.html": {
        "name": "Tucson - Old Spanish Trail",
        "address": "9630 E 22nd St Ste 100, Tucson Tucson, Arizona, 85748",
        "phone": "(520) 777-9184",
        "url": "https://locations.massageenvy.com/arizona/tucson/9630-e-22nd-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.205966,
            -110.78691
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.32,
                    "duration": "indefinitely",
                    "model_conf": 69.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.81,
                    "duration": "indefinitely",
                    "model_conf": 157.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.01,
                    "duration": "indefinitely",
                    "model_conf": 100.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.98,
                    "duration": "indefinitely",
                    "model_conf": 152.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.37,
                    "duration": "indefinitely",
                    "model_conf": 266.33
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.22,
                    "duration": "indefinitely",
                    "model_conf": 173.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.7,
                    "duration": "6 Months",
                    "model_conf": 158.55
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.51,
                    "duration": "indefinitely",
                    "model_conf": 161.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.72,
                    "duration": "4 Months",
                    "model_conf": 148.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.74,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.95,
                    "duration": "indefinitely",
                    "model_conf": 231.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 84.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.6,
                    "duration": "2 Months",
                    "model_conf": 54.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 195.16,
                    "duration": "indefinitely",
                    "model_conf": 215.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/arkansas/little-rock/17200-chenal-parkway.html": {
        "name": "Chenal",
        "address": "17200 Chenal Parkway Ste 270, Little Rock, Arkansas, 72223",
        "phone": "(501) 821-5353",
        "url": "https://locations.massageenvy.com/arkansas/little-rock/17200-chenal-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.762036247878676,
            -92.45370286689948
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 71.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 102.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 154.5
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.13,
                    "duration": "indefinitely",
                    "model_conf": 277.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.79,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.5,
                    "duration": "indefinitely",
                    "model_conf": 166.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.88,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.23,
                    "duration": "indefinitely",
                    "model_conf": 85.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 220.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/arkansas/little-rock/209-n-university-ave.html": {
        "name": "Midtowne Little Rock",
        "address": "209 N University Ave Ste 200, Little Rock, Arkansas, 72205",
        "phone": "(501) 492-7721",
        "url": "https://locations.massageenvy.com/arkansas/little-rock/209-n-university-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.753129999475014,
            -92.34078302461057
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.28,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 102.29
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.56,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.56,
                    "duration": "indefinitely",
                    "model_conf": 278.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.79,
                    "duration": "indefinitely",
                    "model_conf": 166.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 167.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.07,
                    "duration": "indefinitely",
                    "model_conf": 156.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 85.32
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 55.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.88,
                    "duration": "indefinitely",
                    "model_conf": 220.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/concord/1975-diamond-blvd..html": {
        "name": "The Willows",
        "address": "1975 Diamond Blvd. Suite D-010, Concord Concord, California, 94520",
        "phone": "(925) 689-3689",
        "url": "https://locations.massageenvy.com/california/concord/1975-diamond-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            37.96821035218539,
            -122.05613007664556
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.13,
                    "duration": "1 Months",
                    "model_conf": 71.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.16,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.65,
                    "duration": "1 Months",
                    "model_conf": 101.59
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.95,
                    "duration": "indefinitely",
                    "model_conf": 154.18
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.54,
                    "duration": "1 Months",
                    "model_conf": 274.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.37,
                    "duration": "1 Months",
                    "model_conf": 162.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.02,
                    "duration": "indefinitely",
                    "model_conf": 163.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.84,
                    "duration": "indefinitely",
                    "model_conf": 156.33
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.61,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.91,
                    "duration": "indefinitely",
                    "model_conf": 85.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.9,
                    "duration": "indefinitely",
                    "model_conf": 55.05
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.55,
                    "duration": "indefinitely",
                    "model_conf": 209.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/concord/5442-ygnacio-valley-rd.html": {
        "name": "Clayton Valley",
        "address": "5442 Ygnacio Valley Rd Ste 140, Concord, California, 94521",
        "phone": "(925) 672-3689",
        "url": "https://locations.massageenvy.com/california/concord/5442-ygnacio-valley-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            37.950876994768436,
            -121.95783592106025
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.26,
                    "duration": "1 Months",
                    "model_conf": 71.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.33,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.69,
                    "duration": "1 Months",
                    "model_conf": 101.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.66,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.9,
                    "duration": "indefinitely",
                    "model_conf": 277.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.85,
                    "duration": "indefinitely",
                    "model_conf": 163.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.88,
                    "duration": "indefinitely",
                    "model_conf": 164.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.19,
                    "duration": "indefinitely",
                    "model_conf": 156.68
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.58,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.01,
                    "duration": "indefinitely",
                    "model_conf": 85.24
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.84,
                    "duration": "indefinitely",
                    "model_conf": 55.28
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.05,
                    "duration": "1 Months",
                    "model_conf": 213.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/elk-grove/8465-elk-grove-florin-road.html": {
        "name": "Elk Grove Florin Rd",
        "address": "8465 Elk Grove Florin Road Suite #105, Elk Grove, California, 95624",
        "phone": "(916) 714-0222",
        "url": "https://locations.massageenvy.com/california/elk-grove/8465-elk-grove-florin-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.4499212,
            -121.3700833
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.93,
                    "duration": "indefinitely",
                    "model_conf": 280.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.66,
                    "duration": "indefinitely",
                    "model_conf": 163.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.9,
                    "duration": "indefinitely",
                    "model_conf": 165.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.91,
                    "duration": "indefinitely",
                    "model_conf": 156.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.55,
                    "duration": "1 Months",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.87,
                    "duration": "indefinitely",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.11,
                    "duration": "indefinitely",
                    "model_conf": 216.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/elk-grove/9139-west-stockton-blvd.html": {
        "name": "Laguna Gateway",
        "address": "9139 West Stockton Blvd Suite 100, Elk Grove, California, 95758",
        "phone": "(916) 683-3689",
        "url": "https://locations.massageenvy.com/california/elk-grove/9139-west-stockton-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.4255672,
            -121.3995793
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.81,
                    "duration": "indefinitely",
                    "model_conf": 280.95
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.45,
                    "duration": "1 Months",
                    "model_conf": 162.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.77,
                    "duration": "indefinitely",
                    "model_conf": 165.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.67,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.6,
                    "duration": "1 Months",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.86,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.86,
                    "duration": "indefinitely",
                    "model_conf": 55.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.93,
                    "duration": "indefinitely",
                    "model_conf": 216.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/folsom/25055-blue-ravine-road.html": {
        "name": "Folsom",
        "address": "25055 Blue Ravine Road, Folsom Folsom, California, 95630",
        "phone": "(916) 985-6100",
        "url": "https://locations.massageenvy.com/california/folsom/25055-blue-ravine-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:15 AM - 6:00 PM"
        },
        "geolocation": [
            38.69014844454403,
            -121.12228035581973
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.72,
                    "duration": "3 Months",
                    "model_conf": 274.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.48,
                    "duration": "1 Months",
                    "model_conf": 193.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 164.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 166.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.25,
                    "duration": "indefinitely",
                    "model_conf": 156.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.14,
                    "duration": "2 Months",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.8,
                    "duration": "indefinitely",
                    "model_conf": 228.28
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.56,
                    "duration": "indefinitely",
                    "model_conf": 85.33
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.41,
                    "duration": "indefinitely",
                    "model_conf": 55.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.72,
                    "duration": "indefinitely",
                    "model_conf": 211.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/folsom/2750-east-bidwell-street.html": {
        "name": "Folsom-Broadstone Marketplace",
        "address": "2750 East Bidwell Street Ste. 200, Folsom Folsom, California, 95630",
        "phone": "(916) 357-5000",
        "url": "https://locations.massageenvy.com/california/folsom/2750-east-bidwell-street.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.655296716244415,
            -121.12314247238731
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.88,
                    "duration": "indefinitely",
                    "model_conf": 275.0
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.24,
                    "duration": "indefinitely",
                    "model_conf": 193.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.6,
                    "duration": "3 Months",
                    "model_conf": 164.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.72,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.55,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.26,
                    "duration": "1 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.78,
                    "duration": "indefinitely",
                    "model_conf": 227.26
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.2,
                    "duration": "indefinitely",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 55.24
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.53,
                    "duration": "indefinitely",
                    "model_conf": 210.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fresno/1170-e-champlain-dr.html": {
        "name": "Fresno - Piazza del Fiore",
        "address": "1170 E Champlain Dr Ste 103, Fresno Fresno, California, 93720",
        "phone": "(559) 434-0900",
        "url": "https://locations.massageenvy.com/california/fresno/1170-e-champlain-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.87171626568651,
            -119.7627542952556
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.37,
                    "duration": "1 Months",
                    "model_conf": 282.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 195.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.48,
                    "duration": "2 Months",
                    "model_conf": 167.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.73,
                    "duration": "indefinitely",
                    "model_conf": 156.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.87,
                    "duration": "indefinitely",
                    "model_conf": 140.96
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.91,
                    "duration": "indefinitely",
                    "model_conf": 238.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.22,
                    "duration": "indefinitely",
                    "model_conf": 84.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.23,
                    "duration": "indefinitely",
                    "model_conf": 54.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.0,
                    "duration": "1 Months",
                    "model_conf": 213.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/fresno/642-w-shaw-ave.html": {
        "name": "Fresno-Fig Garden",
        "address": "642 W Shaw Ave, Fresno, California, 93704",
        "phone": "(559) 264-3689",
        "url": "https://locations.massageenvy.com/california/fresno/642-w-shaw-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.808953,
            -119.805448
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.7,
                    "duration": "indefinitely",
                    "model_conf": 282.67
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.23,
                    "duration": "indefinitely",
                    "model_conf": 194.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.65,
                    "duration": "indefinitely",
                    "model_conf": 167.02
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.84,
                    "duration": "indefinitely",
                    "model_conf": 167.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.53,
                    "duration": "indefinitely",
                    "model_conf": 157.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.42,
                    "duration": "1 Months",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.3,
                    "duration": "indefinitely",
                    "model_conf": 238.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.06,
                    "duration": "indefinitely",
                    "model_conf": 85.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.12,
                    "duration": "indefinitely",
                    "model_conf": 54.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.37,
                    "duration": "1 Months",
                    "model_conf": 215.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/huntington-beach/19431-main-st.html": {
        "name": "Huntington Beach West",
        "address": "19431 Main St Ste 101, Huntington Beach Huntington Beach, California, 92648",
        "phone": "(714) 374-5588",
        "url": "https://locations.massageenvy.com/california/huntington-beach/19431-main-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.680211,
            -118.00206
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.84,
                    "duration": "indefinitely",
                    "model_conf": 277.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.22,
                    "duration": "indefinitely",
                    "model_conf": 189.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.39,
                    "duration": "indefinitely",
                    "model_conf": 163.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.64,
                    "duration": "1 Months",
                    "model_conf": 161.29
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.55,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.14,
                    "duration": "indefinitely",
                    "model_conf": 140.73
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.01,
                    "duration": "indefinitely",
                    "model_conf": 227.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.13,
                    "duration": "2 Months",
                    "model_conf": 84.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.6,
                    "duration": "2 Months",
                    "model_conf": 54.81
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.27,
                    "duration": "1 Months",
                    "model_conf": 216.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/huntington-beach/7644-edinger-avenue.html": {
        "name": "Huntington Beach",
        "address": "7644 Edinger Avenue, Huntington Beach Huntington Beach, California, 92647",
        "phone": "(714) 374-3689",
        "url": "https://locations.massageenvy.com/california/huntington-beach/7644-edinger-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.728934762619126,
            -117.99535743231628
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.88,
                    "duration": "indefinitely",
                    "model_conf": 70.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 93.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 153.14
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.06,
                    "duration": "indefinitely",
                    "model_conf": 274.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.91,
                    "duration": "3 Months",
                    "model_conf": 162.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.68,
                    "duration": "indefinitely",
                    "model_conf": 159.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.37,
                    "duration": "3 Months",
                    "model_conf": 155.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.94,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 84.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.45,
                    "duration": "3 Months",
                    "model_conf": 54.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.19,
                    "duration": "indefinitely",
                    "model_conf": 212.81
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/long-beach/4458-atlantic-ave..html": {
        "name": "Bixby Knolls",
        "address": "4458 Atlantic Ave., Long Beach, California, 90807",
        "phone": "(562) 247-7884",
        "url": "https://locations.massageenvy.com/california/long-beach/4458-atlantic-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.83862645357714,
            -118.18434789776796
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.66,
                    "duration": "indefinitely",
                    "model_conf": 273.72
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.75,
                    "duration": "indefinitely",
                    "model_conf": 184.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.62,
                    "duration": "indefinitely",
                    "model_conf": 161.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.17,
                    "duration": "indefinitely",
                    "model_conf": 159.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.52,
                    "duration": "2 Months",
                    "model_conf": 154.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.52,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.11,
                    "duration": "indefinitely",
                    "model_conf": 228.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.28,
                    "duration": "1 Months",
                    "model_conf": 85.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.66,
                    "duration": "1 Months",
                    "model_conf": 54.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.85,
                    "duration": "indefinitely",
                    "model_conf": 211.69
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/long-beach/7609-carson-blvd.html": {
        "name": "Long Beach Town Center",
        "address": "7609 Carson Blvd, Long Beach, California, 90808",
        "phone": "(562) 354-9300",
        "url": "https://locations.massageenvy.com/california/long-beach/7609-carson-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.8283953,
            -118.084763
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.7,
                    "duration": "indefinitely",
                    "model_conf": 70.82
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.08,
                    "duration": "2 Months",
                    "model_conf": 154.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.04,
                    "duration": "3 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.64,
                    "duration": "2 Months",
                    "model_conf": 90.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.19,
                    "duration": "3 Months",
                    "model_conf": 153.3
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.84,
                    "duration": "indefinitely",
                    "model_conf": 270.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 184.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.97,
                    "duration": "indefinitely",
                    "model_conf": 161.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.74,
                    "duration": "2 Months",
                    "model_conf": 157.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.97,
                    "duration": "2 Months",
                    "model_conf": 155.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.95,
                    "duration": "indefinitely",
                    "model_conf": 140.5
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.15,
                    "duration": "indefinitely",
                    "model_conf": 224.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.13,
                    "duration": "3 Months",
                    "model_conf": 84.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.51,
                    "duration": "3 Months",
                    "model_conf": 54.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.21,
                    "duration": "1 Months",
                    "model_conf": 212.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/orange/745-s-main-street.html": {
        "name": "Orange Town and Country",
        "address": "745 S Main Street Ste 130, Orange, California, 92868",
        "phone": "(714) 921-3900",
        "url": "https://locations.massageenvy.com/california/orange/745-s-main-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.7763,
            -117.866652
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.47,
                    "duration": "1 Months",
                    "model_conf": 263.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.21,
                    "duration": "2 Months",
                    "model_conf": 181.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.36,
                    "duration": "indefinitely",
                    "model_conf": 159.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.63,
                    "duration": "7 Months",
                    "model_conf": 153.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.67,
                    "duration": "indefinitely",
                    "model_conf": 154.61
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.62,
                    "duration": "indefinitely",
                    "model_conf": 140.63
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.76,
                    "duration": "3 Months",
                    "model_conf": 212.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.32,
                    "duration": "indefinitely",
                    "model_conf": 82.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.34,
                    "duration": "indefinitely",
                    "model_conf": 53.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.11,
                    "duration": "1 Months",
                    "model_conf": 200.96
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/orange/7536-e-chapman-ave.html": {
        "name": "Orange Hills",
        "address": "7536 E Chapman Ave, Orange Orange, California, 92869",
        "phone": "(714) 281-1800",
        "url": "https://locations.massageenvy.com/california/orange/7536-e-chapman-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            33.79510190044854,
            -117.77291374966808
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.42,
                    "duration": "indefinitely",
                    "model_conf": 263.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.19,
                    "duration": "indefinitely",
                    "model_conf": 180.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.22,
                    "duration": "indefinitely",
                    "model_conf": 159.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.5,
                    "duration": "2 Months",
                    "model_conf": 152.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.51,
                    "duration": "indefinitely",
                    "model_conf": 154.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.05,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.89,
                    "duration": "indefinitely",
                    "model_conf": 212.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.63,
                    "duration": "1 Months",
                    "model_conf": 82.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.83,
                    "duration": "indefinitely",
                    "model_conf": 53.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.65,
                    "duration": "indefinitely",
                    "model_conf": 199.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/pasadena/345-south-lake-avenue.html": {
        "name": "Pasadena-South Lake",
        "address": "345 South Lake Avenue Ste 205, Pasadena, California, 91101",
        "phone": "(626) 240-1060",
        "url": "https://locations.massageenvy.com/california/pasadena/345-south-lake-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            34.140067314014026,
            -118.13286736072769
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 70.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.92,
                    "duration": "3 Months",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.25,
                    "duration": "4 Months",
                    "model_conf": 89.57
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.29,
                    "duration": "indefinitely",
                    "model_conf": 153.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 266.19
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 160.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.09,
                    "duration": "5 Months",
                    "model_conf": 165.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.9,
                    "duration": "indefinitely",
                    "model_conf": 140.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.93,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.24,
                    "duration": "1 Months",
                    "model_conf": 172.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/pasadena/3707-e-foothill-blvd.html": {
        "name": "Pasadena Hastings Ranch",
        "address": "3707 E Foothill Blvd, Pasadena Pasadena, California, 91107",
        "phone": "(626) 351-9100",
        "url": "https://locations.massageenvy.com/california/pasadena/3707-e-foothill-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            34.1509912,
            -118.0730426
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.77,
                    "duration": "indefinitely",
                    "model_conf": 257.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.75,
                    "duration": "indefinitely",
                    "model_conf": 188.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.98,
                    "duration": "indefinitely",
                    "model_conf": 156.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.27,
                    "duration": "indefinitely",
                    "model_conf": 164.61
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.21,
                    "duration": "1 Months",
                    "model_conf": 128.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.5,
                    "duration": "indefinitely",
                    "model_conf": 140.16
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.24,
                    "duration": "indefinitely",
                    "model_conf": 228.32
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.96,
                    "duration": "2 Months",
                    "model_conf": 85.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.37,
                    "duration": "3 Months",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.1,
                    "duration": "indefinitely",
                    "model_conf": 142.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/riverside/19029-van-buren.html": {
        "name": "Riverside Orangecrest",
        "address": "19029 Van Buren Ste 118, Riverside Riverside, California, 92508",
        "phone": "(951) 789-0908",
        "url": "https://locations.massageenvy.com/california/riverside/19029-van-buren.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.8863363,
            -117.3305432
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.9,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.85,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.7,
                    "duration": "1 Months",
                    "model_conf": 102.05
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.09,
                    "duration": "indefinitely",
                    "model_conf": 154.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.1,
                    "duration": "2 Months",
                    "model_conf": 281.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.81,
                    "duration": "indefinitely",
                    "model_conf": 166.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.61,
                    "duration": "2 Months",
                    "model_conf": 167.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.98,
                    "duration": "indefinitely",
                    "model_conf": 157.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.96,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.48,
                    "duration": "indefinitely",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.28,
                    "duration": "1 Months",
                    "model_conf": 220.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/riverside/3639-riverside-plaza-dr..html": {
        "name": "Riverside Central",
        "address": "3639 Riverside Plaza Dr. Suite 506, Riverside Riverside, California, 92506",
        "phone": "(951) 683-3500",
        "url": "https://locations.massageenvy.com/california/riverside/3639-riverside-plaza-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            33.95538347373679,
            -117.39122308602828
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.97,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.29,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 101.63
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.19,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.22,
                    "duration": "1 Months",
                    "model_conf": 282.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.76,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.71,
                    "duration": "1 Months",
                    "model_conf": 167.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.97,
                    "duration": "indefinitely",
                    "model_conf": 157.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.82,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.59,
                    "duration": "indefinitely",
                    "model_conf": 85.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.2,
                    "duration": "indefinitely",
                    "model_conf": 55.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.39,
                    "duration": "1 Months",
                    "model_conf": 220.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/roseville/10441-fairway-dr.html": {
        "name": "Roseville",
        "address": "10441 Fairway Dr Ste 120, Roseville, California, 95678",
        "phone": "(916) 784-3800",
        "url": "https://locations.massageenvy.com/california/roseville/10441-fairway-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.789428,
            -121.288169
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.17,
                    "duration": "2 Months",
                    "model_conf": 141.02
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.72,
                    "duration": "indefinitely",
                    "model_conf": 235.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.61,
                    "duration": "1 Months",
                    "model_conf": 85.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.79,
                    "duration": "1 Months",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.7,
                    "duration": "indefinitely",
                    "model_conf": 217.29
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/roseville/1485-eureka-road.html": {
        "name": "East Roseville",
        "address": "1485 Eureka Road Suite G120, Roseville, California, 95661",
        "phone": "(916) 783-1787",
        "url": "https://locations.massageenvy.com/california/roseville/1485-eureka-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 3:30 PM",
            "TUESDAY": "8:30 AM - 8:00 PM",
            "WEDNESDAY": "8:30 AM - 8:00 PM",
            "THURSDAY": "8:30 AM - 8:00 PM",
            "FRIDAY": "8:30 AM - 8:00 PM",
            "SATURDAY": "9:30 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            38.75285155545251,
            -121.25158018433467
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.0,
                    "duration": "2 Months",
                    "model_conf": 281.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.85,
                    "duration": "3 Months",
                    "model_conf": 165.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.08,
                    "duration": "indefinitely",
                    "model_conf": 166.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.62,
                    "duration": "indefinitely",
                    "model_conf": 156.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.53,
                    "duration": "3 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.41,
                    "duration": "1 Months",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.71,
                    "duration": "1 Months",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.45,
                    "duration": "1 Months",
                    "model_conf": 214.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sacramento/1901-s-st.html": {
        "name": "Sacramento - Midtown",
        "address": "1901 S St Suite 101, Sacramento, California, 95811",
        "phone": "(916) 447-3689",
        "url": "https://locations.massageenvy.com/california/sacramento/1901-s-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            38.56737267543316,
            -121.48519903421402
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.46,
                    "duration": "3 Months",
                    "model_conf": 275.49
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.64,
                    "duration": "3 Months",
                    "model_conf": 193.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.98,
                    "duration": "indefinitely",
                    "model_conf": 165.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.88,
                    "duration": "6 Months",
                    "model_conf": 167.52
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.03,
                    "duration": "indefinitely",
                    "model_conf": 156.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.58,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.38,
                    "duration": "4 Months",
                    "model_conf": 227.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.34,
                    "duration": "indefinitely",
                    "model_conf": 85.32
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.24,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.84,
                    "duration": "2 Months",
                    "model_conf": 213.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sacramento/2051-arena-blvd.html": {
        "name": "Sacramento - Natomas",
        "address": "2051 Arena Blvd Suite 100, Sacramento, California, 95834",
        "phone": "(916) 568-6252",
        "url": "https://locations.massageenvy.com/california/sacramento/2051-arena-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 8:00 PM",
            "TUESDAY": "8:30 AM - 8:00 PM",
            "WEDNESDAY": "8:30 AM - 8:00 PM",
            "THURSDAY": "8:30 AM - 8:00 PM",
            "FRIDAY": "8:30 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.647344,
            -121.509689
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": null,
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.04,
                    "duration": "7 Months",
                    "model_conf": 280.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.57,
                    "duration": "indefinitely",
                    "model_conf": 165.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.68,
                    "duration": "7 Months",
                    "model_conf": 167.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.44,
                    "duration": "indefinitely",
                    "model_conf": 157.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.04,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.75,
                    "duration": "indefinitely",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.55,
                    "duration": "indefinitely",
                    "model_conf": 55.2
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.34,
                    "duration": "3 Months",
                    "model_conf": 217.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sacramento/2511-fair-oaks-blvd.html": {
        "name": "Loehmann's Plaza",
        "address": "2511 Fair Oaks Blvd Suite A, Sacramento, California, 95825",
        "phone": "(916) 481-3689",
        "url": "https://locations.massageenvy.com/california/sacramento/2511-fair-oaks-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.575410539762636,
            -121.40379540296692
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.74,
                    "duration": "indefinitely",
                    "model_conf": 276.34
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.09,
                    "duration": "indefinitely",
                    "model_conf": 193.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.14,
                    "duration": "indefinitely",
                    "model_conf": 164.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.63,
                    "duration": "2 Months",
                    "model_conf": 167.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.91,
                    "duration": "indefinitely",
                    "model_conf": 155.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.01,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.99,
                    "duration": "indefinitely",
                    "model_conf": 227.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.14,
                    "duration": "indefinitely",
                    "model_conf": 85.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.07,
                    "duration": "indefinitely",
                    "model_conf": 55.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.68,
                    "duration": "indefinitely",
                    "model_conf": 208.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sacramento/4383-arden-way.html": {
        "name": "Sacramento-Arden",
        "address": "4383 Arden Way, Sacramento, California, 95864",
        "phone": "(916) 971-3689",
        "url": "https://locations.massageenvy.com/california/sacramento/4383-arden-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            38.596125,
            -121.36231
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.71,
                    "duration": "indefinitely",
                    "model_conf": 279.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 194.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.76,
                    "duration": "indefinitely",
                    "model_conf": 165.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.04,
                    "duration": "indefinitely",
                    "model_conf": 167.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.95,
                    "duration": "indefinitely",
                    "model_conf": 155.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.83,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 231.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.08,
                    "duration": "indefinitely",
                    "model_conf": 55.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.12,
                    "duration": "indefinitely",
                    "model_conf": 208.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/sacramento/990-florin-road.html": {
        "name": "Sacramento Pocket",
        "address": "990 Florin Road, Sacramento, California, 95831",
        "phone": "(916) 391-3689",
        "url": "https://locations.massageenvy.com/california/sacramento/990-florin-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.4937113,
            -121.5219211
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.58,
                    "duration": "indefinitely",
                    "model_conf": 279.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.14,
                    "duration": "indefinitely",
                    "model_conf": 193.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 165.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.23,
                    "duration": "indefinitely",
                    "model_conf": 167.47
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.43,
                    "duration": "1 Months",
                    "model_conf": 157.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.05,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.58,
                    "duration": "indefinitely",
                    "model_conf": 232.29
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.79,
                    "duration": "indefinitely",
                    "model_conf": 85.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.78,
                    "duration": "1 Months",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.64,
                    "duration": "indefinitely",
                    "model_conf": 218.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-diego/4911-clairemont-drive.html": {
        "name": "Clairemont",
        "address": "4911 Clairemont Drive, San Diego, California, 92117",
        "phone": "(858) 222-8890",
        "url": "https://locations.massageenvy.com/california/san-diego/4911-clairemont-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            32.8320748,
            -117.2042049
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.08,
                    "duration": "indefinitely",
                    "model_conf": 248.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.61,
                    "duration": "indefinitely",
                    "model_conf": 194.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.05,
                    "duration": "indefinitely",
                    "model_conf": 147.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.97,
                    "duration": "indefinitely",
                    "model_conf": 146.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.24,
                    "duration": "1 Months",
                    "model_conf": 149.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.66,
                    "duration": "indefinitely",
                    "model_conf": 140.63
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.31,
                    "duration": "indefinitely",
                    "model_conf": 205.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.5,
                    "duration": "1 Months",
                    "model_conf": 80.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.33,
                    "duration": "3 Months",
                    "model_conf": 54.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.3,
                    "duration": "indefinitely",
                    "model_conf": 191.52
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-diego/655-saturn-blvd.html": {
        "name": "Imperial Beach",
        "address": "655 Saturn Blvd Ste C, San Diego, California, 92154",
        "phone": "(619) 299-0299",
        "url": "https://locations.massageenvy.com/california/san-diego/655-saturn-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.58474437996394,
            -117.09106372944774
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.3,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.2,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.45,
                    "duration": "indefinitely",
                    "model_conf": 158.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.62,
                    "duration": "1 Months",
                    "model_conf": 139.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.53,
                    "duration": "indefinitely",
                    "model_conf": 100.62
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.07,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.82,
                    "duration": "indefinitely",
                    "model_conf": 279.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 194.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 165.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.42,
                    "duration": "indefinitely",
                    "model_conf": 167.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.45,
                    "duration": "indefinitely",
                    "model_conf": 155.61
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.81,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.19,
                    "duration": "indefinitely",
                    "model_conf": 236.66
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.58,
                    "duration": "indefinitely",
                    "model_conf": 85.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.09,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.43,
                    "duration": "indefinitely",
                    "model_conf": 220.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-diego/7610-hazard-center-drive.html": {
        "name": "Mission Valley",
        "address": "7610 Hazard Center Drive Suite 611, San Diego, California, 92108",
        "phone": "(619) 255-3589",
        "url": "https://locations.massageenvy.com/california/san-diego/7610-hazard-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            32.770864255451976,
            -117.15826203802874
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.25,
                    "duration": "indefinitely",
                    "model_conf": 71.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.29,
                    "duration": "2 Months",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 95.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.38,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.46,
                    "duration": "indefinitely",
                    "model_conf": 262.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 158.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 159.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.76,
                    "duration": "indefinitely",
                    "model_conf": 149.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.45,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 83.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.91,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.24,
                    "duration": "indefinitely",
                    "model_conf": 204.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-diego/9450-a-mira-mesa-blvd.html": {
        "name": "Mira Mesa",
        "address": "9450-A Mira Mesa Blvd Suite A, San Diego San Diego, California, 92126",
        "phone": "(858) 271-6888",
        "url": "https://locations.massageenvy.com/california/san-diego/9450-a-mira-mesa-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.91906412022723,
            -117.12188329288335
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.29,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.34,
                    "duration": "1 Months",
                    "model_conf": 139.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.45,
                    "duration": "2 Months",
                    "model_conf": 94.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.99,
                    "duration": "indefinitely",
                    "model_conf": 154.59
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.14,
                    "duration": "4 Months",
                    "model_conf": 267.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.09,
                    "duration": "indefinitely",
                    "model_conf": 157.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.02,
                    "duration": "5 Months",
                    "model_conf": 158.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.06,
                    "duration": "indefinitely",
                    "model_conf": 152.85
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.55,
                    "duration": "indefinitely",
                    "model_conf": 140.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.01,
                    "duration": "indefinitely",
                    "model_conf": 83.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.56,
                    "duration": "indefinitely",
                    "model_conf": 55.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.08,
                    "duration": "1 Months",
                    "model_conf": 208.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-diego/9925-carmel-mountain-road.html": {
        "name": "Rancho Penasquitos",
        "address": "9925 Carmel Mountain Road, San Diego, California, 92129",
        "phone": "(858) 200-8888",
        "url": "https://locations.massageenvy.com/california/san-diego/9925-carmel-mountain-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.9597306,
            -117.1130934
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.42,
                    "duration": "indefinitely",
                    "model_conf": 71.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.5,
                    "duration": "1 Months",
                    "model_conf": 139.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.92,
                    "duration": "indefinitely",
                    "model_conf": 98.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.58,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.99,
                    "duration": "2 Months",
                    "model_conf": 274.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.24,
                    "duration": "indefinitely",
                    "model_conf": 162.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.05,
                    "duration": "indefinitely",
                    "model_conf": 162.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.44,
                    "duration": "indefinitely",
                    "model_conf": 152.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.36,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.88,
                    "duration": "indefinitely",
                    "model_conf": 84.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.53,
                    "duration": "indefinitely",
                    "model_conf": 55.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.14,
                    "duration": "indefinitely",
                    "model_conf": 214.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-jose/111-curtner-ave.html": {
        "name": "San Jose The Plant",
        "address": "111 Curtner Ave, San Jose San Jose, California, 95125",
        "phone": "(408) 755-1200",
        "url": "https://locations.massageenvy.com/california/san-jose/111-curtner-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.30253008183679,
            -121.86572497916791
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.83,
                    "duration": "indefinitely",
                    "model_conf": 70.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.89,
                    "duration": "3 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 91.6
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.06,
                    "duration": "indefinitely",
                    "model_conf": 152.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.57,
                    "duration": "indefinitely",
                    "model_conf": 268.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 159.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 146.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.91,
                    "duration": "indefinitely",
                    "model_conf": 151.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.42,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.22,
                    "duration": "2 Months",
                    "model_conf": 84.26
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 55.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.92,
                    "duration": "indefinitely",
                    "model_conf": 215.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-jose/1316-el-paseo-de-saratoga.html": {
        "name": "San Jose El Paseo",
        "address": "1316 El Paseo De Saratoga, San Jose, California, 95130",
        "phone": "(408) 379-9988",
        "url": "https://locations.massageenvy.com/california/san-jose/1316-el-paseo-de-saratoga.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            37.2907602,
            -121.9917897
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.3,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "93",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.03,
                    "duration": "indefinitely",
                    "model_conf": 70.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.59,
                    "duration": "4 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 93.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.41,
                    "duration": "indefinitely",
                    "model_conf": 153.32
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.43,
                    "duration": "1 Months",
                    "model_conf": 274.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.12,
                    "duration": "indefinitely",
                    "model_conf": 160.7
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 150.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.72,
                    "duration": "indefinitely",
                    "model_conf": 151.91
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.24,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.69,
                    "duration": "indefinitely",
                    "model_conf": 84.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.31,
                    "duration": "indefinitely",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.62,
                    "duration": "indefinitely",
                    "model_conf": 217.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/california/san-jose/954-blossom-hill-rd.html": {
        "name": "San Jose Blossom Hill",
        "address": "954 Blossom Hill Rd, San Jose, California, 95123",
        "phone": "(408) 227-3689",
        "url": "https://locations.massageenvy.com/california/san-jose/954-blossom-hill-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.249583,
            -121.866012
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.44,
                    "duration": "indefinitely",
                    "model_conf": 70.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.2,
                    "duration": "2 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.62,
                    "duration": "1 Months",
                    "model_conf": 92.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.24,
                    "duration": "indefinitely",
                    "model_conf": 153.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.23,
                    "duration": "indefinitely",
                    "model_conf": 265.64
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.71,
                    "duration": "indefinitely",
                    "model_conf": 158.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.08,
                    "duration": "indefinitely",
                    "model_conf": 147.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.7,
                    "duration": "indefinitely",
                    "model_conf": 151.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.14,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.75,
                    "duration": "indefinitely",
                    "model_conf": 84.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.82,
                    "duration": "indefinitely",
                    "model_conf": 55.08
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.64,
                    "duration": "indefinitely",
                    "model_conf": 213.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/aurora/10651-e-garden-drive.html": {
        "name": "Gardens On Havana",
        "address": "10651 E Garden Drive Ste 105, Aurora, Colorado, 80012",
        "phone": "(303) 750-5300",
        "url": "https://locations.massageenvy.com/colorado/aurora/10651-e-garden-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.693787,
            -104.863524
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.57,
                    "duration": "indefinitely",
                    "model_conf": 258.05
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.13,
                    "duration": "indefinitely",
                    "model_conf": 176.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.83,
                    "duration": "indefinitely",
                    "model_conf": 155.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.09,
                    "duration": "indefinitely",
                    "model_conf": 150.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.93,
                    "duration": "indefinitely",
                    "model_conf": 146.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.46,
                    "duration": "1 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.95,
                    "duration": "indefinitely",
                    "model_conf": 209.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.48,
                    "duration": "1 Months",
                    "model_conf": 82.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.61,
                    "duration": "2 Months",
                    "model_conf": 53.05
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.7,
                    "duration": "indefinitely",
                    "model_conf": 200.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/aurora/6295-s-main-st.html": {
        "name": "Southlands",
        "address": "6295 S Main St Ste 103, Aurora, Colorado, 80016",
        "phone": "(303) 699-0868",
        "url": "https://locations.massageenvy.com/colorado/aurora/6295-s-main-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            39.602444,
            -104.709633
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.9,
                    "duration": "4 Months",
                    "model_conf": 270.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.57,
                    "duration": "3 Months",
                    "model_conf": 185.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.61,
                    "duration": "indefinitely",
                    "model_conf": 160.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.22,
                    "duration": "9 Months",
                    "model_conf": 159.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.6,
                    "duration": "indefinitely",
                    "model_conf": 153.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.31,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.6,
                    "duration": "6 Months",
                    "model_conf": 223.41
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.78,
                    "duration": "indefinitely",
                    "model_conf": 84.15
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.4,
                    "duration": "indefinitely",
                    "model_conf": 54.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.84,
                    "duration": "3 Months",
                    "model_conf": 213.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/aurora/6735-cornerstar-way.html": {
        "name": "Aurora Southwest",
        "address": "6735 Cornerstar Way Ste B, Aurora, Colorado, 80016",
        "phone": "(720) 974-9600",
        "url": "https://locations.massageenvy.com/colorado/aurora/6735-cornerstar-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.59402,
            -104.807029
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.29,
                    "duration": "indefinitely",
                    "model_conf": 240.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.55,
                    "duration": "indefinitely",
                    "model_conf": 165.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.88,
                    "duration": "2 Months",
                    "model_conf": 148.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.97,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.11,
                    "duration": "1 Months",
                    "model_conf": 139.87
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.65,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.23,
                    "duration": "indefinitely",
                    "model_conf": 193.24
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.94,
                    "duration": "indefinitely",
                    "model_conf": 80.14
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.44,
                    "duration": "indefinitely",
                    "model_conf": 51.38
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.2,
                    "duration": "indefinitely",
                    "model_conf": 187.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/broomfield/1170-us-hwy-287.html": {
        "name": "Broomfield",
        "address": "1170 US Hwy 287 Suite 300, Broomfield Broomfield, Colorado, 80020",
        "phone": "(303) 404-3689",
        "url": "https://locations.massageenvy.com/colorado/broomfield/1170-us-hwy-287.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.933491,
            -105.089972
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.51,
                    "duration": "1 Months",
                    "model_conf": 258.45
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.42,
                    "duration": "1 Months",
                    "model_conf": 188.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.76,
                    "duration": "indefinitely",
                    "model_conf": 165.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.07,
                    "duration": "4 Months",
                    "model_conf": 164.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.01,
                    "duration": "indefinitely",
                    "model_conf": 157.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.85,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.8,
                    "duration": "3 Months",
                    "model_conf": 218.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.48,
                    "duration": "1 Months",
                    "model_conf": 83.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.77,
                    "duration": "1 Months",
                    "model_conf": 54.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.53,
                    "duration": "2 Months",
                    "model_conf": 220.67
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/broomfield/16818-sheridan-parkway.html": {
        "name": "Vista Highlands",
        "address": "16818 Sheridan Parkway Suite 100, Broomfield, Colorado, 80023",
        "phone": "(303) 325-5606",
        "url": "https://locations.massageenvy.com/colorado/broomfield/16818-sheridan-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:30 PM",
            "TUESDAY": "8:00 AM - 8:30 PM",
            "WEDNESDAY": "8:00 AM - 8:30 PM",
            "THURSDAY": "9:00 AM - 6:30 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.000789848737604,
            -105.00603251126225
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.75,
                    "duration": "3 Months",
                    "model_conf": 275.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.37,
                    "duration": "indefinitely",
                    "model_conf": 166.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.06,
                    "duration": "5 Months",
                    "model_conf": 166.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.14,
                    "duration": "indefinitely",
                    "model_conf": 157.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/colorado-springs/3254-cinema-point.html": {
        "name": "First & Main",
        "address": "3254 Cinema Point, Colorado Springs, Colorado, 80922",
        "phone": "(719) 574-9900",
        "url": "https://locations.massageenvy.com/colorado/colorado-springs/3254-cinema-point.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            38.8801902,
            -104.7172501
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.01,
                    "duration": "indefinitely",
                    "model_conf": 277.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.14,
                    "duration": "indefinitely",
                    "model_conf": 176.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.49,
                    "duration": "3 Months",
                    "model_conf": 163.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.74,
                    "duration": "indefinitely",
                    "model_conf": 167.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.25,
                    "duration": "1 Months",
                    "model_conf": 143.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.41,
                    "duration": "1 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.56,
                    "duration": "indefinitely",
                    "model_conf": 233.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.9,
                    "duration": "indefinitely",
                    "model_conf": 85.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.94,
                    "duration": "indefinitely",
                    "model_conf": 52.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.02,
                    "duration": "indefinitely",
                    "model_conf": 207.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/colorado-springs/742-village-center-drive.html": {
        "name": "Rockrimmon",
        "address": "742 Village Center Drive, Colorado Springs, Colorado, 80919",
        "phone": "(719) 473-9900",
        "url": "https://locations.massageenvy.com/colorado/colorado-springs/742-village-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            38.922495944107524,
            -104.8441814568288
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.44,
                    "duration": "3 Months",
                    "model_conf": 278.4
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.23,
                    "duration": "1 Months",
                    "model_conf": 185.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.96,
                    "duration": "indefinitely",
                    "model_conf": 162.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.74,
                    "duration": "1 Months",
                    "model_conf": 166.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.28,
                    "duration": "indefinitely",
                    "model_conf": 150.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.51,
                    "duration": "1 Months",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.87,
                    "duration": "2 Months",
                    "model_conf": 235.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.18,
                    "duration": "indefinitely",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.25,
                    "duration": "indefinitely",
                    "model_conf": 54.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.34,
                    "duration": "1 Months",
                    "model_conf": 214.36
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/colorado-springs/9235-north-union-boulevard.html": {
        "name": "Briargate",
        "address": "9235 North Union Boulevard #120, Colorado Springs Colorado Springs, Colorado, 80920",
        "phone": "(719) 623-1750",
        "url": "https://locations.massageenvy.com/colorado/colorado-springs/9235-north-union-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            38.96511,
            -104.755212
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.45,
                    "duration": "indefinitely",
                    "model_conf": 279.25
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.06,
                    "duration": "indefinitely",
                    "model_conf": 169.13
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.72,
                    "duration": "8 Months",
                    "model_conf": 161.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 78.66,
                    "duration": "indefinitely",
                    "model_conf": 167.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.83,
                    "duration": "3 Months",
                    "model_conf": 137.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.82,
                    "duration": "2 Months",
                    "model_conf": 140.28
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.13,
                    "duration": "indefinitely",
                    "model_conf": 233.56
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.35,
                    "duration": "1 Months",
                    "model_conf": 85.87
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.48,
                    "duration": "2 Months",
                    "model_conf": 51.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 224.3,
                    "duration": "indefinitely",
                    "model_conf": 201.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/denver/201-steele-street.html": {
        "name": "Cherry Creek",
        "address": "201 Steele Street Unit 1-A, Denver Denver, Colorado, 80206",
        "phone": "(303) 962-8900",
        "url": "https://locations.massageenvy.com/colorado/denver/201-steele-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            39.719708,
            -104.950126
        ],
        "rating": 4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.16,
                    "duration": "4 Months",
                    "model_conf": 246.73
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.33,
                    "duration": "4 Months",
                    "model_conf": 175.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.47,
                    "duration": "indefinitely",
                    "model_conf": 155.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.91,
                    "duration": "5 Months",
                    "model_conf": 148.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.76,
                    "duration": "indefinitely",
                    "model_conf": 148.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.61,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.16,
                    "duration": "4 Months",
                    "model_conf": 202.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.02,
                    "duration": "indefinitely",
                    "model_conf": 81.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.05,
                    "duration": "indefinitely",
                    "model_conf": 53.24
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.36,
                    "duration": "2 Months",
                    "model_conf": 203.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/denver/5465-w-20th-ave.html": {
        "name": "Edgewater Public Market",
        "address": "5465 W 20th Ave, Denver, Colorado, 80214",
        "phone": "(720) 833-3700",
        "url": "https://locations.massageenvy.com/colorado/denver/5465-w-20th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.74779695798478,
            -105.05611075842215
        ],
        "rating": 4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.16,
                    "duration": "4 Months",
                    "model_conf": 249.68
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.59,
                    "duration": "3 Months",
                    "model_conf": 184.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.06,
                    "duration": "indefinitely",
                    "model_conf": 161.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.02,
                    "duration": "5 Months",
                    "model_conf": 157.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.41,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.36,
                    "duration": "1 Months",
                    "model_conf": 140.73
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.64,
                    "duration": "3 Months",
                    "model_conf": 209.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.16,
                    "duration": "indefinitely",
                    "model_conf": 82.35
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.11,
                    "duration": "indefinitely",
                    "model_conf": 54.54
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.31,
                    "duration": "2 Months",
                    "model_conf": 213.85
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/denver/7300-e-hampden-ave.html": {
        "name": "Tiffany Plaza",
        "address": "7300 E Hampden Ave Ste A-1, Denver, Colorado, 80231",
        "phone": "(303) 220-0600",
        "url": "https://locations.massageenvy.com/colorado/denver/7300-e-hampden-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            39.652717,
            -104.903369
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.5,
                    "duration": "indefinitely",
                    "model_conf": 237.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.12,
                    "duration": "indefinitely",
                    "model_conf": 160.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.2,
                    "duration": "2 Months",
                    "model_conf": 145.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 76.04,
                    "duration": "indefinitely",
                    "model_conf": 136.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.21,
                    "duration": "indefinitely",
                    "model_conf": 135.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.9,
                    "duration": "2 Months",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.31,
                    "duration": "indefinitely",
                    "model_conf": 186.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.66,
                    "duration": "indefinitely",
                    "model_conf": 79.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.67,
                    "duration": "1 Months",
                    "model_conf": 50.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.59,
                    "duration": "indefinitely",
                    "model_conf": 179.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/greenwood-village/5900-south-university-boulevard.html": {
        "name": "Cherry Hills Marketplace",
        "address": "5900 South University Boulevard C-6, Greenwood Village Greenwood Village, Colorado, 80121",
        "phone": "(303) 741-3689",
        "url": "https://locations.massageenvy.com/colorado/greenwood-village/5900-south-university-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.60941236898635,
            -104.95915219064489
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.26,
                    "duration": "indefinitely",
                    "model_conf": 235.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.0,
                    "duration": "indefinitely",
                    "model_conf": 158.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.85,
                    "duration": "2 Months",
                    "model_conf": 144.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.82,
                    "duration": "indefinitely",
                    "model_conf": 133.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.75,
                    "duration": "indefinitely",
                    "model_conf": 134.71
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.27,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.58,
                    "duration": "indefinitely",
                    "model_conf": 184.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.74,
                    "duration": "indefinitely",
                    "model_conf": 78.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.86,
                    "duration": "indefinitely",
                    "model_conf": 50.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.58,
                    "duration": "indefinitely",
                    "model_conf": 177.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/greenwood-village/6570-south-yosemite-street.html": {
        "name": "Denver Tech Center",
        "address": "6570 South Yosemite Street #B-2, Greenwood Village Greenwood Village, Colorado, 80111",
        "phone": "(303) 220-3689",
        "url": "https://locations.massageenvy.com/colorado/greenwood-village/6570-south-yosemite-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.59709082491586,
            -104.88813330585049
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.69,
                    "duration": "indefinitely",
                    "model_conf": 209.72
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.75,
                    "duration": "indefinitely",
                    "model_conf": 140.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.63,
                    "duration": "3 Months",
                    "model_conf": 134.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.75,
                    "duration": "indefinitely",
                    "model_conf": 115.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.6,
                    "duration": "indefinitely",
                    "model_conf": 123.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.84,
                    "duration": "2 Months",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.22,
                    "duration": "indefinitely",
                    "model_conf": 156.58
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 75.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.7,
                    "duration": "1 Months",
                    "model_conf": 47.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.2,
                    "duration": "indefinitely",
                    "model_conf": 154.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/highlands-ranch/1265-sgt-jon-stiles-drive.html": {
        "name": "Highlands Ranch Town Center",
        "address": "1265 Sgt Jon Stiles Drive Suite I, Highlands Ranch, Colorado, 80129",
        "phone": "(720) 382-7045",
        "url": "https://locations.massageenvy.com/colorado/highlands-ranch/1265-sgt-jon-stiles-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.549855,
            -105.005904
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.85,
                    "duration": "4 Months",
                    "model_conf": 258.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.46,
                    "duration": "3 Months",
                    "model_conf": 175.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.5,
                    "duration": "indefinitely",
                    "model_conf": 155.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.04,
                    "duration": "4 Months",
                    "model_conf": 149.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.04,
                    "duration": "indefinitely",
                    "model_conf": 145.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.7,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.35,
                    "duration": "4 Months",
                    "model_conf": 209.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.46,
                    "duration": "indefinitely",
                    "model_conf": 81.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.55,
                    "duration": "indefinitely",
                    "model_conf": 52.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.53,
                    "duration": "2 Months",
                    "model_conf": 199.01
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/highlands-ranch/9471-s-university-blvd.html": {
        "name": "Highlands Ranch",
        "address": "9471 S University Blvd, Highlands Ranch, Colorado, 80126",
        "phone": "(303) 791-6637",
        "url": "https://locations.massageenvy.com/colorado/highlands-ranch/9471-s-university-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            39.544024,
            -104.946761
        ],
        "rating": 4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.63,
                    "duration": "3 Months",
                    "model_conf": 241.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.04,
                    "duration": "2 Months",
                    "model_conf": 162.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.21,
                    "duration": "indefinitely",
                    "model_conf": 147.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.92,
                    "duration": "5 Months",
                    "model_conf": 136.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.11,
                    "duration": "indefinitely",
                    "model_conf": 137.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.24,
                    "duration": "1 Months",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.87,
                    "duration": "2 Months",
                    "model_conf": 189.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.0,
                    "duration": "indefinitely",
                    "model_conf": 79.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.03,
                    "duration": "indefinitely",
                    "model_conf": 51.09
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.12,
                    "duration": "3 Months",
                    "model_conf": 184.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/lakewood/5392-s-wadsworth.html": {
        "name": "Southwest Plaza",
        "address": "5392 S Wadsworth Suite 102, Lakewood Lakewood, Colorado, 80123",
        "phone": "(303) 243-3031",
        "url": "https://locations.massageenvy.com/colorado/lakewood/5392-s-wadsworth.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 6:00 PM",
            "TUESDAY": "8:00 AM - 6:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 6:00 PM",
            "FRIDAY": "8:00 AM - 6:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 4:00 PM"
        },
        "geolocation": [
            39.618656,
            -105.090494
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.75,
                    "duration": "3 Months",
                    "model_conf": 267.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.3,
                    "duration": "1 Months",
                    "model_conf": 185.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 161.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.48,
                    "duration": "2 Months",
                    "model_conf": 159.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.33,
                    "duration": "indefinitely",
                    "model_conf": 151.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.07,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.44,
                    "duration": "1 Months",
                    "model_conf": 223.54
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.56,
                    "duration": "indefinitely",
                    "model_conf": 83.05
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.37,
                    "duration": "indefinitely",
                    "model_conf": 54.3
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.35,
                    "duration": "1 Months",
                    "model_conf": 210.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/colorado/lakewood/7033-w-alaska-drive.html": {
        "name": "Lakewood",
        "address": "7033 W Alaska Drive, Lakewood, Colorado, 80226",
        "phone": "(303) 922-3689",
        "url": "https://locations.massageenvy.com/colorado/lakewood/7033-w-alaska-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 10:00 PM"
        },
        "geolocation": [
            39.70939660369808,
            -105.07431507110596
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.89,
                    "duration": "2 Months",
                    "model_conf": 254.02
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.13,
                    "duration": "2 Months",
                    "model_conf": 183.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.59,
                    "duration": "1 Months",
                    "model_conf": 161.1
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.49,
                    "duration": "2 Months",
                    "model_conf": 157.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.96,
                    "duration": "indefinitely",
                    "model_conf": 152.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.78,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.54,
                    "duration": "1 Months",
                    "model_conf": 213.2
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.12,
                    "duration": "indefinitely",
                    "model_conf": 82.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.21,
                    "duration": "indefinitely",
                    "model_conf": 54.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.06,
                    "duration": "1 Months",
                    "model_conf": 212.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/boca-raton/20423-s-state-road-7.html": {
        "name": "Boca Raton Route 441",
        "address": "20423 S State Road 7 Ste F-8, Boca Raton, Florida, 33498",
        "phone": "(561) 477-3689",
        "url": "https://locations.massageenvy.com/florida/boca-raton/20423-s-state-road-7.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.3742972,
            -80.2045704
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.13,
                    "duration": "indefinitely",
                    "model_conf": 69.98
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 155.88
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.0,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.31,
                    "duration": "indefinitely",
                    "model_conf": 100.51
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.73,
                    "duration": "indefinitely",
                    "model_conf": 151.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.71,
                    "duration": "indefinitely",
                    "model_conf": 279.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 189.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 162.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 161.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 150.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 232.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 84.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 54.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.93,
                    "duration": "indefinitely",
                    "model_conf": 213.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/boca-raton/4125-n-federal-hwy.html": {
        "name": "Spanish River",
        "address": "4125 N Federal Hwy, Boca Raton Boca Raton, Florida, 33431",
        "phone": "(561) 750-6606",
        "url": "https://locations.massageenvy.com/florida/boca-raton/4125-n-federal-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.386418,
            -80.078018
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.42,
                    "duration": "1 Months",
                    "model_conf": 70.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.42,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 99.72
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 151.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.97,
                    "duration": "2 Months",
                    "model_conf": 278.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 162.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.15,
                    "duration": "5 Months",
                    "model_conf": 161.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 150.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.28,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 84.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 53.94
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.18,
                    "duration": "1 Months",
                    "model_conf": 213.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/boca-raton/7050-west-palmetto-park-rd.html": {
        "name": "West Boca Raton",
        "address": "7050 West Palmetto Park Rd Bay 17, Boca Raton, Florida, 33433",
        "phone": "(561) 368-2111",
        "url": "https://locations.massageenvy.com/florida/boca-raton/7050-west-palmetto-park-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            26.348108,
            -80.157714
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 69.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.37,
                    "duration": "indefinitely",
                    "model_conf": 99.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 150.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.58,
                    "duration": "indefinitely",
                    "model_conf": 278.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.45,
                    "duration": "1 Months",
                    "model_conf": 160.82
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 159.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 147.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.49,
                    "duration": "1 Months",
                    "model_conf": 84.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 53.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.36,
                    "duration": "indefinitely",
                    "model_conf": 210.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/boynton-beach/1000-n-congress.html": {
        "name": "Boynton Beach",
        "address": "1000 N Congress Suite 140, Boynton Beach, Florida, 33426",
        "phone": "(561) 374-7979",
        "url": "https://locations.massageenvy.com/florida/boynton-beach/1000-n-congress.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.535959,
            -80.0886302
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 101.12
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.33,
                    "duration": "indefinitely",
                    "model_conf": 278.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.02,
                    "duration": "2 Months",
                    "model_conf": 165.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 165.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 156.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 85.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 213.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/boynton-beach/8794-boynton-beach-blvd.html": {
        "name": "West Boynton Beach",
        "address": "8794 Boynton Beach Blvd Suite 105, Boynton Beach, Florida, 33472",
        "phone": "(561) 733-9355",
        "url": "https://locations.massageenvy.com/florida/boynton-beach/8794-boynton-beach-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.526421,
            -80.1867059
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 70.62
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 157.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 100.78
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.8,
                    "duration": "indefinitely",
                    "model_conf": 153.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 276.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.51,
                    "duration": "indefinitely",
                    "model_conf": 188.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.2,
                    "duration": "2 Months",
                    "model_conf": 165.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 165.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 156.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.84,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.62,
                    "duration": "indefinitely",
                    "model_conf": 234.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.97,
                    "duration": "indefinitely",
                    "model_conf": 209.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/cape-coral/1751-ne-pine-island-road.html": {
        "name": "Cape Coral North",
        "address": "1751 NE Pine Island Road Suite 155, Cape Coral, Florida, 33909",
        "phone": "(239) 402-3742",
        "url": "https://locations.massageenvy.com/florida/cape-coral/1751-ne-pine-island-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 3:00 PM"
        },
        "geolocation": [
            26.671521038541812,
            -81.93701528896881
        ],
        "rating": 0,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "Celebrate the Anniversary Event Now",
                "description": "Celebrate the Anniversary Event Now! Enjoy a 20% Discount on all Award-Winning Jan Marini Skin Research Products. Excludes promotional bundles. Cannot be combined with any other promotional offers. Selection varies by location."
            },
            {
                "title": "",
                "description": "ENJOY A SPECIAL $65 INTRO RATE on a 60-minute facial, massage, or stretch session. Terms apply. *Valid only for first-time guests at the Massage Envy North Cape Coral and Cape Coral franchised locations. Session time includes 10 minutes for consultation and dressing. See franchised location for additional details about the special offers. Each location is independently owned and operated.\nMM# 44118, 29773"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.87,
                    "duration": "indefinitely",
                    "model_conf": 70.51
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.53,
                    "duration": "1 Months",
                    "model_conf": 157.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.33,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.77,
                    "duration": "1 Months",
                    "model_conf": 100.77
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.07,
                    "duration": "2 Months",
                    "model_conf": 154.77
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.97,
                    "duration": "indefinitely",
                    "model_conf": 277.36
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.64,
                    "duration": "1 Months",
                    "model_conf": 187.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.33,
                    "duration": "indefinitely",
                    "model_conf": 160.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.65,
                    "duration": "3 Months",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.92,
                    "duration": "indefinitely",
                    "model_conf": 149.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.84,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.93,
                    "duration": "indefinitely",
                    "model_conf": 237.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.13,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.11,
                    "duration": "indefinitely",
                    "model_conf": 54.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.41,
                    "duration": "1 Months",
                    "model_conf": 215.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/cape-coral/2221-santa-barbara-blvd.html": {
        "name": "Cape Coral",
        "address": "2221 Santa Barbara Blvd, Cape Coral, Florida, 33991",
        "phone": "(239) 549-3689",
        "url": "https://locations.massageenvy.com/florida/cape-coral/2221-santa-barbara-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.609984,
            -81.973544
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.86,
                    "duration": "indefinitely",
                    "model_conf": 70.27
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.24,
                    "duration": "indefinitely",
                    "model_conf": 156.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.49,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.2,
                    "duration": "indefinitely",
                    "model_conf": 100.18
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.46,
                    "duration": "1 Months",
                    "model_conf": 154.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.53,
                    "duration": "indefinitely",
                    "model_conf": 275.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.0,
                    "duration": "indefinitely",
                    "model_conf": 184.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.7,
                    "duration": "1 Months",
                    "model_conf": 158.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.1,
                    "duration": "indefinitely",
                    "model_conf": 164.92
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.61,
                    "duration": "indefinitely",
                    "model_conf": 146.4
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.82,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.57,
                    "duration": "indefinitely",
                    "model_conf": 236.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.05,
                    "duration": "indefinitely",
                    "model_conf": 85.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.98,
                    "duration": "indefinitely",
                    "model_conf": 53.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.03,
                    "duration": "indefinitely",
                    "model_conf": 213.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/coral-gables/256-miracle-mile.html": {
        "name": "Coral Gables",
        "address": "256 Miracle Mile, Coral Gables, Florida, 33134",
        "phone": "(305) 648-7149",
        "url": "https://locations.massageenvy.com/florida/coral-gables/256-miracle-mile.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            25.749172209888883,
            -80.25985032320023
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.61,
                    "duration": "indefinitely",
                    "model_conf": 68.77
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 156.19
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.35,
                    "duration": "indefinitely",
                    "model_conf": 98.99
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.86,
                    "duration": "indefinitely",
                    "model_conf": 150.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.7,
                    "duration": "indefinitely",
                    "model_conf": 279.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 187.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 162.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 157.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 145.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.13,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.59,
                    "duration": "indefinitely",
                    "model_conf": 230.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.42,
                    "duration": "indefinitely",
                    "model_conf": 84.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 53.98
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.19,
                    "duration": "indefinitely",
                    "model_conf": 206.47
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/coral-gables/6927-red-road.html": {
        "name": "South Miami",
        "address": "6927 Red Road, Coral Gables, Florida, 33143",
        "phone": "(305) 662-2622",
        "url": "https://locations.massageenvy.com/florida/coral-gables/6927-red-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 10:00 PM"
        },
        "geolocation": [
            25.7048466,
            -80.2852805
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.01,
                    "duration": "indefinitely",
                    "model_conf": 69.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.97,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.27,
                    "duration": "indefinitely",
                    "model_conf": 99.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.58,
                    "duration": "indefinitely",
                    "model_conf": 151.77
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.71,
                    "duration": "indefinitely",
                    "model_conf": 281.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 163.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 160.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.52,
                    "duration": "indefinitely",
                    "model_conf": 148.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.19,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 84.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.94,
                    "duration": "indefinitely",
                    "model_conf": 54.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.98,
                    "duration": "indefinitely",
                    "model_conf": 208.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/fort-myers/15880-summerlin-rd.html": {
        "name": "South Fort Myers",
        "address": "15880 Summerlin Rd, Fort Myers, Florida, 33908",
        "phone": "(239) 333-3689",
        "url": "https://locations.massageenvy.com/florida/fort-myers/15880-summerlin-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 10:00 PM",
            "TUESDAY": "8:30 AM - 10:00 PM",
            "WEDNESDAY": "8:30 AM - 10:00 PM",
            "THURSDAY": "8:30 AM - 10:00 PM",
            "FRIDAY": "8:30 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            26.51420557821585,
            -81.90124232188282
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.14,
                    "duration": "indefinitely",
                    "model_conf": 70.83
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.98,
                    "duration": "indefinitely",
                    "model_conf": 156.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.14,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.79,
                    "duration": "indefinitely",
                    "model_conf": 101.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.05,
                    "duration": "indefinitely",
                    "model_conf": 154.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.12,
                    "duration": "indefinitely",
                    "model_conf": 279.77
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 111.02,
                    "duration": "indefinitely",
                    "model_conf": 189.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.84,
                    "duration": "5 Months",
                    "model_conf": 162.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.13,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.72,
                    "duration": "2 Months",
                    "model_conf": 152.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.76,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 112.15,
                    "duration": "indefinitely",
                    "model_conf": 236.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.66,
                    "duration": "1 Months",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.73,
                    "duration": "2 Months",
                    "model_conf": 54.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.58,
                    "duration": "indefinitely",
                    "model_conf": 217.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/fort-myers/9374-6-mile-cypress-pkwy.html": {
        "name": "Ft Myers",
        "address": "9374 6 Mile Cypress Pkwy Unit 160, Fort Myers, Florida, 33966",
        "phone": "(239) 308-9079",
        "url": "https://locations.massageenvy.com/florida/fort-myers/9374-6-mile-cypress-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            26.6067288,
            -81.8118872
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.85,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.82,
                    "duration": "indefinitely",
                    "model_conf": 70.45
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.42,
                    "duration": "indefinitely",
                    "model_conf": 157.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 91.26,
                    "duration": "indefinitely",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.26,
                    "duration": "indefinitely",
                    "model_conf": 101.1
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.56,
                    "duration": "1 Months",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.55,
                    "duration": "indefinitely",
                    "model_conf": 278.58
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.16,
                    "duration": "indefinitely",
                    "model_conf": 187.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.58,
                    "duration": "1 Months",
                    "model_conf": 161.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.25,
                    "duration": "indefinitely",
                    "model_conf": 165.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.5,
                    "duration": "1 Months",
                    "model_conf": 151.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.66,
                    "duration": "1 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.69,
                    "duration": "indefinitely",
                    "model_conf": 236.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.9,
                    "duration": "indefinitely",
                    "model_conf": 84.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.92,
                    "duration": "indefinitely",
                    "model_conf": 54.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.18,
                    "duration": "indefinitely",
                    "model_conf": 217.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/hollywood/1640-sheridan-st..html": {
        "name": "East Hollywood",
        "address": "1640 Sheridan St., Hollywood Hollywood, Florida, 33020",
        "phone": "(954) 306-9177",
        "url": "https://locations.massageenvy.com/florida/hollywood/1640-sheridan-st..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            26.032507640493357,
            -80.13964093418849
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.74,
                    "duration": "indefinitely",
                    "model_conf": 70.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.36,
                    "duration": "indefinitely",
                    "model_conf": 100.21
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.19,
                    "duration": "indefinitely",
                    "model_conf": 153.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.69,
                    "duration": "indefinitely",
                    "model_conf": 280.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.92,
                    "duration": "3 Months",
                    "model_conf": 164.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.43,
                    "duration": "indefinitely",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.76,
                    "duration": "2 Months",
                    "model_conf": 155.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.11,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 85.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.69,
                    "duration": "2 Months",
                    "model_conf": 54.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.75,
                    "duration": "indefinitely",
                    "model_conf": 217.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/hollywood/5081-sheridan-street.html": {
        "name": "Hollywood - FL",
        "address": "5081 Sheridan Street, Hollywood, Florida, 33021",
        "phone": "(954) 966-1771",
        "url": "https://locations.massageenvy.com/florida/hollywood/5081-sheridan-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            26.0345645,
            -80.1955896
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 69.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.2,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.49,
                    "duration": "indefinitely",
                    "model_conf": 100.16
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 153.32
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.36,
                    "duration": "indefinitely",
                    "model_conf": 280.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 164.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.46,
                    "duration": "indefinitely",
                    "model_conf": 166.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.77,
                    "duration": "2 Months",
                    "model_conf": 154.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.89,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 85.02
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 53.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.76,
                    "duration": "indefinitely",
                    "model_conf": 216.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville/13475-atlantic-blvd.html": {
        "name": "Harbour Village",
        "address": "13475 Atlantic Blvd, Jacksonville, Florida, 32225",
        "phone": "(904) 394-2500",
        "url": "https://locations.massageenvy.com/florida/jacksonville/13475-atlantic-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.322113,
            -81.452972
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.73,
                    "duration": "indefinitely",
                    "model_conf": 69.89
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 154.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.08,
                    "duration": "2 Months",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 99.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 152.44
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.58,
                    "duration": "1 Months",
                    "model_conf": 279.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.32,
                    "duration": "2 Months",
                    "model_conf": 187.36
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 160.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.22,
                    "duration": "4 Months",
                    "model_conf": 167.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.74,
                    "duration": "indefinitely",
                    "model_conf": 150.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 230.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.25,
                    "duration": "indefinitely",
                    "model_conf": 84.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.48,
                    "duration": "indefinitely",
                    "model_conf": 54.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.13,
                    "duration": "1 Months",
                    "model_conf": 213.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville/13820-old-st-augustine-rd.html": {
        "name": "Bartram Park",
        "address": "13820 Old St Augustine Rd Suite #157, Jacksonville, Florida, 32258",
        "phone": "(904) 262-5585",
        "url": "https://locations.massageenvy.com/florida/jacksonville/13820-old-st-augustine-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.140516000000012,
            -81.54898982209016
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.87,
                    "duration": "indefinitely",
                    "model_conf": 70.41
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 152.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 98.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.68,
                    "duration": "indefinitely",
                    "model_conf": 151.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.01,
                    "duration": "indefinitely",
                    "model_conf": 279.53
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.91,
                    "duration": "indefinitely",
                    "model_conf": 186.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 157.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.48,
                    "duration": "indefinitely",
                    "model_conf": 166.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 150.2
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.48,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 226.47
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 84.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.18,
                    "duration": "indefinitely",
                    "model_conf": 54.24
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.46,
                    "duration": "indefinitely",
                    "model_conf": 211.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville/4375-southside-boulevard.html": {
        "name": "Southside",
        "address": "4375 Southside Boulevard Ste 10, Jacksonville Jacksonville, Florida, 32216",
        "phone": "(904) 483-2233",
        "url": "https://locations.massageenvy.com/florida/jacksonville/4375-southside-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.267521440514734,
            -81.5575626361358
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 68.2
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.31,
                    "duration": "2 Months",
                    "model_conf": 155.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 139.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.35,
                    "duration": "3 Months",
                    "model_conf": 93.64
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 147.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.17,
                    "duration": "indefinitely",
                    "model_conf": 279.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 165.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.9,
                    "duration": "indefinitely",
                    "model_conf": 138.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.71,
                    "duration": "1 Months",
                    "model_conf": 168.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.4,
                    "duration": "indefinitely",
                    "model_conf": 133.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.39,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 216.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 81.8
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.02,
                    "duration": "indefinitely",
                    "model_conf": 50.07
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 193.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville/4509-st-johns-ave.html": {
        "name": "Ortega",
        "address": "4509 St Johns Ave Suite 1, Jacksonville, Florida, 32210",
        "phone": "(904) 224-5405",
        "url": "https://locations.massageenvy.com/florida/jacksonville/4509-st-johns-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.2889166,
            -81.7213204
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.04,
                    "duration": "indefinitely",
                    "model_conf": 70.7
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 155.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 95.85
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 151.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.71,
                    "duration": "indefinitely",
                    "model_conf": 275.03
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.45,
                    "duration": "1 Months",
                    "model_conf": 188.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 158.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 167.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.33,
                    "duration": "indefinitely",
                    "model_conf": 152.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 140.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 222.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 84.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.13,
                    "duration": "indefinitely",
                    "model_conf": 54.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 207.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/jacksonville/9651-crosshill-blvd..html": {
        "name": "Oakleaf",
        "address": "9651 Crosshill Blvd. #110, Jacksonville, Florida, 32222",
        "phone": "(904) 771-3689",
        "url": "https://locations.massageenvy.com/florida/jacksonville/9651-crosshill-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.197885181418368,
            -81.8283623558197
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.65,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.05,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.42,
                    "duration": "5 Months",
                    "model_conf": 101.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.49,
                    "duration": "1 Months",
                    "model_conf": 154.42
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.74,
                    "duration": "indefinitely",
                    "model_conf": 280.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.65,
                    "duration": "indefinitely",
                    "model_conf": 164.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.5,
                    "duration": "4 Months",
                    "model_conf": 167.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.5,
                    "duration": "indefinitely",
                    "model_conf": 157.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 126.55,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.03,
                    "duration": "indefinitely",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.28,
                    "duration": "indefinitely",
                    "model_conf": 55.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.79,
                    "duration": "indefinitely",
                    "model_conf": 220.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/13660-sw-88th-street.html": {
        "name": "Kendall",
        "address": "13660 SW 88th Street, Miami, Florida, 33186",
        "phone": "(305) 383-1984",
        "url": "https://locations.massageenvy.com/florida/miami/13660-sw-88th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            25.68383634362762,
            -80.41544880738002
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 69.49
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 100.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.49,
                    "duration": "1 Months",
                    "model_conf": 152.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.99,
                    "duration": "2 Months",
                    "model_conf": 279.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.96,
                    "duration": "indefinitely",
                    "model_conf": 164.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.01,
                    "duration": "5 Months",
                    "model_conf": 159.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.59,
                    "duration": "indefinitely",
                    "model_conf": 149.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.83,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.55,
                    "duration": "indefinitely",
                    "model_conf": 84.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 54.49
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.78,
                    "duration": "2 Months",
                    "model_conf": 212.12
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/13721-sw-152nd-street.html": {
        "name": "Country Walk",
        "address": "13721 SW 152nd Street, Miami Miami, Florida, 33177",
        "phone": "(305) 252-4626",
        "url": "https://locations.massageenvy.com/florida/miami/13721-sw-152nd-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            25.6274405,
            -80.4151321
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 69.68
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.02,
                    "duration": "2 Months",
                    "model_conf": 157.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.57,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 100.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.32,
                    "duration": "1 Months",
                    "model_conf": 153.3
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.2,
                    "duration": "2 Months",
                    "model_conf": 279.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 191.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.58,
                    "duration": "indefinitely",
                    "model_conf": 164.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.23,
                    "duration": "4 Months",
                    "model_conf": 158.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.92,
                    "duration": "indefinitely",
                    "model_conf": 150.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.2,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.51,
                    "duration": "1 Months",
                    "model_conf": 235.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 84.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.07,
                    "duration": "indefinitely",
                    "model_conf": 54.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.08,
                    "duration": "1 Months",
                    "model_conf": 212.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/14200-sw-8th-st.html": {
        "name": "Tamiami",
        "address": "14200 SW 8th St Suite 108, Miami Miami, Florida, 33184",
        "phone": "(305) 222-1984",
        "url": "https://locations.massageenvy.com/florida/miami/14200-sw-8th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            25.760440095916582,
            -80.42583140974426
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 70.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.03,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 101.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 153.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 281.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 165.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 163.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.15,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.1,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.48,
                    "duration": "indefinitely",
                    "model_conf": 85.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.17,
                    "duration": "indefinitely",
                    "model_conf": 55.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 215.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/3401-n-miami-ave.html": {
        "name": "Midtown Miami",
        "address": "3401 N Miami Ave Suite 107, Miami, Florida, 33127",
        "phone": "(305) 438-1101",
        "url": "https://locations.massageenvy.com/florida/miami/3401-n-miami-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            25.80964796303453,
            -80.19497707486153
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 69.1
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 156.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.66,
                    "duration": "2 Months",
                    "model_conf": 100.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.44,
                    "duration": "1 Months",
                    "model_conf": 150.77
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.42,
                    "duration": "1 Months",
                    "model_conf": 277.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.36,
                    "duration": "1 Months",
                    "model_conf": 189.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.97,
                    "duration": "indefinitely",
                    "model_conf": 162.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.2,
                    "duration": "4 Months",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.4,
                    "duration": "indefinitely",
                    "model_conf": 143.62
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.77,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 231.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 83.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 54.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.07,
                    "duration": "1 Months",
                    "model_conf": 208.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/900-s-miami-avenue.html": {
        "name": "Brickell",
        "address": "900 S Miami Avenue, Miami, Florida, 33130",
        "phone": "(305) 995-6080",
        "url": "https://locations.massageenvy.com/florida/miami/900-s-miami-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 10:00 PM"
        },
        "geolocation": [
            25.764972,
            -80.193799
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 69.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.69,
                    "duration": "7 Months",
                    "model_conf": 99.83
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 150.94
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.33,
                    "duration": "7 Months",
                    "model_conf": 278.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.66,
                    "duration": "indefinitely",
                    "model_conf": 162.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.06,
                    "duration": "11 Months",
                    "model_conf": 155.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.99,
                    "duration": "indefinitely",
                    "model_conf": 145.25
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.05,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.31,
                    "duration": "indefinitely",
                    "model_conf": 84.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.77,
                    "duration": "indefinitely",
                    "model_conf": 54.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.68,
                    "duration": "4 Months",
                    "model_conf": 208.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/9017-sw-107th-ave.html": {
        "name": "East Kendall",
        "address": "9017 SW 107th Ave Suite 250, Miami, Florida, 33176",
        "phone": "(305) 595-3689",
        "url": "https://locations.massageenvy.com/florida/miami/9017-sw-107th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            25.684499958539142,
            -80.3652258073779
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 69.02
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.67,
                    "duration": "1 Months",
                    "model_conf": 99.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.96,
                    "duration": "indefinitely",
                    "model_conf": 152.31
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.69,
                    "duration": "3 Months",
                    "model_conf": 279.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 163.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.25,
                    "duration": "4 Months",
                    "model_conf": 158.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.73,
                    "duration": "indefinitely",
                    "model_conf": 147.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.57,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.74,
                    "duration": "indefinitely",
                    "model_conf": 84.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.39,
                    "duration": "indefinitely",
                    "model_conf": 54.35
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.97,
                    "duration": "2 Months",
                    "model_conf": 208.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/miami/9751-nw-41st-st..html": {
        "name": "Doral",
        "address": "9751 NW 41st St., Miami Miami, Florida, 33178",
        "phone": "(305) 800-3689",
        "url": "https://locations.massageenvy.com/florida/miami/9751-nw-41st-st..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            25.813056987152923,
            -80.35542031069781
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 70.09
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 156.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 100.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 152.32
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.86,
                    "duration": "3 Months",
                    "model_conf": 280.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.03,
                    "duration": "2 Months",
                    "model_conf": 187.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.89,
                    "duration": "indefinitely",
                    "model_conf": 164.34
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.42,
                    "duration": "3 Months",
                    "model_conf": 162.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.9,
                    "duration": "indefinitely",
                    "model_conf": 151.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.8,
                    "duration": "indefinitely",
                    "model_conf": 140.86
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.05,
                    "duration": "2 Months",
                    "model_conf": 231.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.6,
                    "duration": "indefinitely",
                    "model_conf": 84.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.26,
                    "duration": "indefinitely",
                    "model_conf": 54.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 213.15
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orlando/13919-narcoossee-rd.html": {
        "name": "Lake Nona",
        "address": "13919 Narcoossee Rd Suite 100, Orlando, Florida, 32832",
        "phone": "(407) 992-7868",
        "url": "https://locations.massageenvy.com/florida/orlando/13919-narcoossee-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            28.3628803,
            -81.24375169999999
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "CBD Enhancement Is Here!",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "Membership helps restore you, inside and out",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/florida/orlando/4101-hunter-s-park-lane.html": {
        "name": "Hunter's Creek",
        "address": "4101 Hunter's Park Lane Ste 404, Orlando, Florida, 32837",
        "phone": "(407) 438-3689",
        "url": "https://locations.massageenvy.com/florida/orlando/4101-hunter-s-park-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            28.367819,
            -81.426935
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.98,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.44,
                    "duration": "1 Months",
                    "model_conf": 157.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.81,
                    "duration": "1 Months",
                    "model_conf": 101.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.47,
                    "duration": "1 Months",
                    "model_conf": 154.51
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.52,
                    "duration": "1 Months",
                    "model_conf": 281.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.84,
                    "duration": "indefinitely",
                    "model_conf": 193.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.75,
                    "duration": "indefinitely",
                    "model_conf": 165.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.5,
                    "duration": "3 Months",
                    "model_conf": 164.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.38,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.29,
                    "duration": "2 Months",
                    "model_conf": 233.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 85.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 54.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.07,
                    "duration": "2 Months",
                    "model_conf": 220.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orlando/45-w-crystal-lake-st.html": {
        "name": "Downtown/Sodo",
        "address": "45 W Crystal Lake St Ste 193, Orlando, Florida, 32806",
        "phone": "(407) 841-3689",
        "url": "https://locations.massageenvy.com/florida/orlando/45-w-crystal-lake-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.515467,
            -81.378958
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.5,
                    "duration": "indefinitely",
                    "model_conf": 70.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.23,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.75,
                    "duration": "indefinitely",
                    "model_conf": 95.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.65,
                    "duration": "indefinitely",
                    "model_conf": 152.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 137.45,
                    "duration": "indefinitely",
                    "model_conf": 273.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.03,
                    "duration": "3 Months",
                    "model_conf": 160.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.22,
                    "duration": "indefinitely",
                    "model_conf": 159.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.97,
                    "duration": "2 Months",
                    "model_conf": 152.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.02,
                    "duration": "indefinitely",
                    "model_conf": 140.51
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.44,
                    "duration": "2 Months",
                    "model_conf": 84.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.0,
                    "duration": "indefinitely",
                    "model_conf": 52.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.21,
                    "duration": "indefinitely",
                    "model_conf": 211.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orlando/6889-eagle-watch-drive.html": {
        "name": "Lee Vista",
        "address": "6889 Eagle Watch Drive Suite 225, Orlando, Florida, 32822",
        "phone": "(407) 990-1192",
        "url": "https://locations.massageenvy.com/florida/orlando/6889-eagle-watch-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.4634053,
            -81.3070097
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.97,
                    "duration": "indefinitely",
                    "model_conf": 70.89
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.57,
                    "duration": "indefinitely",
                    "model_conf": 156.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.51,
                    "duration": "indefinitely",
                    "model_conf": 99.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 154.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.98,
                    "duration": "indefinitely",
                    "model_conf": 279.02
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.62,
                    "duration": "indefinitely",
                    "model_conf": 191.89
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.21,
                    "duration": "2 Months",
                    "model_conf": 164.59
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.46,
                    "duration": "indefinitely",
                    "model_conf": 164.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 155.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.26,
                    "duration": "1 Months",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.93,
                    "duration": "indefinitely",
                    "model_conf": 233.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 85.33
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 54.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.6,
                    "duration": "indefinitely",
                    "model_conf": 218.05
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orlando/8081-turkey-lake-rd.html": {
        "name": "Dr. Phillips",
        "address": "8081 Turkey Lake Rd Ste 100, Orlando, Florida, 32819",
        "phone": "(407) 354-3689",
        "url": "https://locations.massageenvy.com/florida/orlando/8081-turkey-lake-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.447812136039207,
            -81.47548346437709
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.99,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.77,
                    "duration": "1 Months",
                    "model_conf": 101.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 154.38
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.49,
                    "duration": "1 Months",
                    "model_conf": 281.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 165.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.76,
                    "duration": "1 Months",
                    "model_conf": 166.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.18,
                    "duration": "indefinitely",
                    "model_conf": 154.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.1,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.49,
                    "duration": "indefinitely",
                    "model_conf": 85.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 54.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.76,
                    "duration": "indefinitely",
                    "model_conf": 220.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/orlando/833-n-alafaya-trail.html": {
        "name": "Waterford Lakes",
        "address": "833 N Alafaya Trail, Orlando Orlando, Florida, 32828",
        "phone": "(407) 903-3689",
        "url": "https://locations.massageenvy.com/florida/orlando/833-n-alafaya-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.55683662360216,
            -81.20418804904705
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 70.83
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 154.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.34,
                    "duration": "indefinitely",
                    "model_conf": 98.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.35,
                    "duration": "indefinitely",
                    "model_conf": 153.68
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.49,
                    "duration": "indefinitely",
                    "model_conf": 277.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 189.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 162.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.87,
                    "duration": "indefinitely",
                    "model_conf": 162.82
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.31,
                    "duration": "1 Months",
                    "model_conf": 152.97
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.43,
                    "duration": "indefinitely",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.07,
                    "duration": "indefinitely",
                    "model_conf": 231.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 84.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 54.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.25,
                    "duration": "indefinitely",
                    "model_conf": 214.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/sarasota/1279-s-tamiami-trail.html": {
        "name": "Sarasota-Midtown",
        "address": "1279 S Tamiami Trail, Sarasota, Florida, 34239",
        "phone": "(941) 308-7600",
        "url": "https://locations.massageenvy.com/florida/sarasota/1279-s-tamiami-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.322475,
            -82.528601
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.03,
                    "duration": "2 Months",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.9,
                    "duration": "3 Months",
                    "model_conf": 158.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.23,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.07,
                    "duration": "7 Months",
                    "model_conf": 101.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.4,
                    "duration": "3 Months",
                    "model_conf": 149.07
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.47,
                    "duration": "7 Months",
                    "model_conf": 262.86
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 117.7,
                    "duration": "7 Months",
                    "model_conf": 185.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.75,
                    "duration": "indefinitely",
                    "model_conf": 165.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 48.53,
                    "duration": "11 Months",
                    "model_conf": 153.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.04,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.26,
                    "duration": "5 Months",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.13,
                    "duration": "6 Months",
                    "model_conf": 233.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.7,
                    "duration": "1 Months",
                    "model_conf": 84.19
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.79,
                    "duration": "1 Months",
                    "model_conf": 55.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.7,
                    "duration": "4 Months",
                    "model_conf": 221.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/sarasota/2855-university-parkway.html": {
        "name": "Sarasota North",
        "address": "2855 University Parkway, Sarasota Sarasota, Florida, 34243",
        "phone": "(941) 556-6400",
        "url": "https://locations.massageenvy.com/florida/sarasota/2855-university-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.390651534921187,
            -82.51229222500058
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.65,
                    "duration": "1 Months",
                    "model_conf": 71.27
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.51,
                    "duration": "1 Months",
                    "model_conf": 157.96
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 101.59,
                    "duration": "indefinitely",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.47,
                    "duration": "4 Months",
                    "model_conf": 101.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.15,
                    "duration": "1 Months",
                    "model_conf": 149.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.55,
                    "duration": "4 Months",
                    "model_conf": 266.23
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.53,
                    "duration": "4 Months",
                    "model_conf": 187.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.43,
                    "duration": "indefinitely",
                    "model_conf": 165.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 48.91,
                    "duration": "8 Months",
                    "model_conf": 155.99
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.42,
                    "duration": "indefinitely",
                    "model_conf": 157.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.01,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.99,
                    "duration": "3 Months",
                    "model_conf": 233.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.49,
                    "duration": "indefinitely",
                    "model_conf": 84.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.37,
                    "duration": "indefinitely",
                    "model_conf": 55.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.53,
                    "duration": "3 Months",
                    "model_conf": 220.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/sarasota/4055-clark-road.html": {
        "name": "Sarasota South",
        "address": "4055 Clark Road, Sarasota Sarasota, Florida, 34233",
        "phone": "(941) 927-6200",
        "url": "https://locations.massageenvy.com/florida/sarasota/4055-clark-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            27.270227387797846,
            -82.48832501203651
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.29,
                    "duration": "2 Months",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.0,
                    "duration": "3 Months",
                    "model_conf": 158.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 101.28,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.11,
                    "duration": "6 Months",
                    "model_conf": 101.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.53,
                    "duration": "3 Months",
                    "model_conf": 151.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.8,
                    "duration": "3 Months",
                    "model_conf": 270.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.47,
                    "duration": "4 Months",
                    "model_conf": 188.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.1,
                    "duration": "indefinitely",
                    "model_conf": 165.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 48.85,
                    "duration": "9 Months",
                    "model_conf": 158.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.72,
                    "duration": "indefinitely",
                    "model_conf": 157.17
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.48,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.89,
                    "duration": "3 Months",
                    "model_conf": 234.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.07,
                    "duration": "indefinitely",
                    "model_conf": 84.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.14,
                    "duration": "indefinitely",
                    "model_conf": 55.41
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.37,
                    "duration": "3 Months",
                    "model_conf": 220.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/st.-petersburg/1422-66th-street-n.html": {
        "name": "St. Petersburg 66th Street",
        "address": "1422 66th Street N, St. Petersburg, Florida, 33710",
        "phone": "(727) 381-3689",
        "url": "https://locations.massageenvy.com/florida/st.-petersburg/1422-66th-street-n.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.785439000000007,
            -82.7293658687172
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.77,
                    "duration": "indefinitely",
                    "model_conf": 71.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.3,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.32,
                    "duration": "5 Months",
                    "model_conf": 101.29
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.07,
                    "duration": "2 Months",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.33,
                    "duration": "1 Months",
                    "model_conf": 279.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.55,
                    "duration": "indefinitely",
                    "model_conf": 166.67
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.11,
                    "duration": "6 Months",
                    "model_conf": 167.8
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.29,
                    "duration": "indefinitely",
                    "model_conf": 156.38
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.64,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.97,
                    "duration": "indefinitely",
                    "model_conf": 85.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.18,
                    "duration": "indefinitely",
                    "model_conf": 55.61
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.02,
                    "duration": "2 Months",
                    "model_conf": 220.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/st.-petersburg/5020-4th-street-n.html": {
        "name": "St. Petersburg 4th Street",
        "address": "5020 4th Street N, St. Petersburg St. Petersburg, Florida, 33703",
        "phone": "(727) 490-3000",
        "url": "https://locations.massageenvy.com/florida/st.-petersburg/5020-4th-street-n.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.81821007223339,
            -82.6391557228336
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.61,
                    "duration": "1 Months",
                    "model_conf": 71.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.46,
                    "duration": "2 Months",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.24,
                    "duration": "5 Months",
                    "model_conf": 101.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.63,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.66,
                    "duration": "3 Months",
                    "model_conf": 279.62
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.24,
                    "duration": "indefinitely",
                    "model_conf": 166.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.1,
                    "duration": "7 Months",
                    "model_conf": 167.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.52,
                    "duration": "indefinitely",
                    "model_conf": 155.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.03,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.05,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.05,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.01,
                    "duration": "2 Months",
                    "model_conf": 220.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/tampa/10905-n-dale-mabry-hwy.html": {
        "name": "Carrollwood",
        "address": "10905 N Dale Mabry Hwy, Tampa, Florida, 33618",
        "phone": "(813) 600-1631",
        "url": "https://locations.massageenvy.com/florida/tampa/10905-n-dale-mabry-hwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.048041984077283,
            -82.50384126271473
        ],
        "rating": 3.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.22,
                    "duration": "indefinitely",
                    "model_conf": 262.85
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 182.04
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.53,
                    "duration": "indefinitely",
                    "model_conf": 165.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.45,
                    "duration": "indefinitely",
                    "model_conf": 164.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.85,
                    "duration": "indefinitely",
                    "model_conf": 149.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.54,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 232.48
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.5,
                    "duration": "indefinitely",
                    "model_conf": 84.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.15,
                    "duration": "indefinitely",
                    "model_conf": 55.2
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.66,
                    "duration": "indefinitely",
                    "model_conf": 215.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/tampa/1120-east-kennedy-blvd..html": {
        "name": "Downtown Tampa",
        "address": "1120 East Kennedy Blvd. Suite 152, Tampa, Florida, 33602",
        "phone": "(813) 605-3689",
        "url": "https://locations.massageenvy.com/florida/tampa/1120-east-kennedy-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.95118652500286,
            -82.44788512587547
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.79,
                    "duration": "indefinitely",
                    "model_conf": 70.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.07,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.75,
                    "duration": "1 Months",
                    "model_conf": 98.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.92,
                    "duration": "indefinitely",
                    "model_conf": 154.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.41,
                    "duration": "1 Months",
                    "model_conf": 272.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.45,
                    "duration": "indefinitely",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.51,
                    "duration": "3 Months",
                    "model_conf": 166.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.37,
                    "duration": "indefinitely",
                    "model_conf": 153.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.25,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.29,
                    "duration": "indefinitely",
                    "model_conf": 85.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.16,
                    "duration": "indefinitely",
                    "model_conf": 55.1
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.48,
                    "duration": "1 Months",
                    "model_conf": 218.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/tampa/3830-w-neptune-st.html": {
        "name": "South Tampa",
        "address": "3830 W Neptune St Ste C-2, Tampa, Florida, 33629",
        "phone": "(813) 251-3300",
        "url": "https://locations.massageenvy.com/florida/tampa/3830-w-neptune-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            27.929579,
            -82.507246
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.83,
                    "duration": "indefinitely",
                    "model_conf": 70.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.92,
                    "duration": "indefinitely",
                    "model_conf": 98.22
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.02,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.09,
                    "duration": "indefinitely",
                    "model_conf": 270.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.97,
                    "duration": "indefinitely",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.91,
                    "duration": "indefinitely",
                    "model_conf": 166.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 152.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.31,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.2,
                    "duration": "indefinitely",
                    "model_conf": 85.12
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.04,
                    "duration": "indefinitely",
                    "model_conf": 55.17
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.04,
                    "duration": "indefinitely",
                    "model_conf": 217.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/florida/tampa/8618-citrus-park-drive.html": {
        "name": "Citrus Park",
        "address": "8618 Citrus Park Drive, Tampa Tampa, Florida, 33625",
        "phone": "(813) 341-1900",
        "url": "https://locations.massageenvy.com/florida/tampa/8618-citrus-park-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            28.064853974220224,
            -82.5808251468677
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.53,
                    "duration": "indefinitely",
                    "model_conf": 70.32
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 88.79,
                    "duration": "5 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.94,
                    "duration": "indefinitely",
                    "model_conf": 96.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.62,
                    "duration": "indefinitely",
                    "model_conf": 152.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.22,
                    "duration": "indefinitely",
                    "model_conf": 260.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.4,
                    "duration": "indefinitely",
                    "model_conf": 165.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.32,
                    "duration": "indefinitely",
                    "model_conf": 164.48
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.66,
                    "duration": "indefinitely",
                    "model_conf": 147.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.29,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.0,
                    "duration": "indefinitely",
                    "model_conf": 84.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.2,
                    "duration": "indefinitely",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.75,
                    "duration": "indefinitely",
                    "model_conf": 214.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/1801-howell-mill-road.html": {
        "name": "West Midtown",
        "address": "1801 Howell Mill Road, Atlanta, Georgia, 30318",
        "phone": "(404) 352-3330",
        "url": "https://locations.massageenvy.com/georgia/atlanta/1801-howell-mill-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.804146,
            -84.4125514
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.88,
                    "duration": "indefinitely",
                    "model_conf": 68.62
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.39,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.83,
                    "duration": "indefinitely",
                    "model_conf": 139.26
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.52,
                    "duration": "indefinitely",
                    "model_conf": 76.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.33,
                    "duration": "indefinitely",
                    "model_conf": 147.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.93,
                    "duration": "indefinitely",
                    "model_conf": 241.25
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.49,
                    "duration": "indefinitely",
                    "model_conf": 146.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.61,
                    "duration": "1 Months",
                    "model_conf": 133.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.5,
                    "duration": "indefinitely",
                    "model_conf": 135.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.91,
                    "duration": "indefinitely",
                    "model_conf": 138.43
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.6,
                    "duration": "1 Months",
                    "model_conf": 140.45
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.31,
                    "duration": "indefinitely",
                    "model_conf": 177.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.67,
                    "duration": "indefinitely",
                    "model_conf": 80.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.63,
                    "duration": "indefinitely",
                    "model_conf": 50.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.95,
                    "duration": "indefinitely",
                    "model_conf": 186.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/2625-piedmont-rd-ne.html": {
        "name": "Buckhead Crossing",
        "address": "2625 Piedmont Rd Ne Ste 51, Atlanta Atlanta, Georgia, 30324",
        "phone": "(404) 841-3599",
        "url": "https://locations.massageenvy.com/georgia/atlanta/2625-piedmont-rd-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "10:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "10:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.82716256001045,
            -84.36536178881818
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.23,
                    "duration": "indefinitely",
                    "model_conf": 67.41
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.92,
                    "duration": "indefinitely",
                    "model_conf": 131.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 139.29
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 73.55
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.87,
                    "duration": "indefinitely",
                    "model_conf": 144.42
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 230.82
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.21,
                    "duration": "indefinitely",
                    "model_conf": 141.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 133.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.52,
                    "duration": "indefinitely",
                    "model_conf": 123.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.0,
                    "duration": "indefinitely",
                    "model_conf": 132.64
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.79,
                    "duration": "indefinitely",
                    "model_conf": 140.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 164.8
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 78.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.29,
                    "duration": "indefinitely",
                    "model_conf": 49.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.67,
                    "duration": "indefinitely",
                    "model_conf": 169.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/2999-n-druid-hills-rd.html": {
        "name": "Toco Hills",
        "address": "2999 N Druid Hills Rd Ste 130, Atlanta Atlanta, Georgia, 30329",
        "phone": "(678) 399-2311",
        "url": "https://locations.massageenvy.com/georgia/atlanta/2999-n-druid-hills-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.8131789,
            -84.31041
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.88,
                    "duration": "indefinitely",
                    "model_conf": 68.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.99,
                    "duration": "indefinitely",
                    "model_conf": 138.11
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 139.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.81,
                    "duration": "indefinitely",
                    "model_conf": 82.2
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 147.28
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.35,
                    "duration": "indefinitely",
                    "model_conf": 245.54
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.14,
                    "duration": "indefinitely",
                    "model_conf": 158.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.31,
                    "duration": "2 Months",
                    "model_conf": 141.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.02,
                    "duration": "indefinitely",
                    "model_conf": 134.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.56,
                    "duration": "indefinitely",
                    "model_conf": 138.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.76,
                    "duration": "indefinitely",
                    "model_conf": 140.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.59,
                    "duration": "indefinitely",
                    "model_conf": 183.34
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 80.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.24,
                    "duration": "indefinitely",
                    "model_conf": 51.16
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.48,
                    "duration": "indefinitely",
                    "model_conf": 181.92
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/3495-buckhead-loop.html": {
        "name": "Buckhead",
        "address": "3495 Buckhead Loop Suite 225, Atlanta Atlanta, Georgia, 30305",
        "phone": "(404) 869-3689",
        "url": "https://locations.massageenvy.com/georgia/atlanta/3495-buckhead-loop.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.84982179286443,
            -84.37014296116104
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.47,
                    "duration": "indefinitely",
                    "model_conf": 67.36
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.8,
                    "duration": "indefinitely",
                    "model_conf": 128.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 139.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 74.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.16,
                    "duration": "indefinitely",
                    "model_conf": 143.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.4,
                    "duration": "indefinitely",
                    "model_conf": 229.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 147.5
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 135.5
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 122.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 131.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.18,
                    "duration": "indefinitely",
                    "model_conf": 139.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 168.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 78.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.21,
                    "duration": "indefinitely",
                    "model_conf": 49.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.39,
                    "duration": "indefinitely",
                    "model_conf": 165.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/3755-carmia-drive.html": {
        "name": "Camp Creek",
        "address": "3755 Carmia Drive Ste 410, Atlanta Atlanta, Georgia, 30331",
        "phone": "(404) 477-3699",
        "url": "https://locations.massageenvy.com/georgia/atlanta/3755-carmia-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.65685736746124,
            -84.509746737559
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.79,
                    "duration": "indefinitely",
                    "model_conf": 71.35
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.26,
                    "duration": "indefinitely",
                    "model_conf": 158.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.08,
                    "duration": "indefinitely",
                    "model_conf": 101.69
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.84,
                    "duration": "indefinitely",
                    "model_conf": 153.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.51,
                    "duration": "indefinitely",
                    "model_conf": 281.08
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.1,
                    "duration": "indefinitely",
                    "model_conf": 193.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.24,
                    "duration": "2 Months",
                    "model_conf": 165.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.67,
                    "duration": "indefinitely",
                    "model_conf": 166.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 157.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.32,
                    "duration": "indefinitely",
                    "model_conf": 235.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.59,
                    "duration": "1 Months",
                    "model_conf": 85.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.76,
                    "duration": "1 Months",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.09,
                    "duration": "indefinitely",
                    "model_conf": 219.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/4530-olde-perimeter-way.html": {
        "name": "Dunwoody-Sandy Springs",
        "address": "4530 Olde Perimeter Way STE 110, Atlanta Atlanta, Georgia, 30338",
        "phone": "(404) 477-3689",
        "url": "https://locations.massageenvy.com/georgia/atlanta/4530-olde-perimeter-way.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.92940873620658,
            -84.34372569645953
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.54,
                    "duration": "1 Months",
                    "model_conf": 68.29
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 133.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 139.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 77.06
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 145.17
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.39,
                    "duration": "indefinitely",
                    "model_conf": 241.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 157.54
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 134.33
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 129.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 136.83
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 139.81
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 181.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 79.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 51.1
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 176.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/atlanta/650-ponce-de-leon-ave.html": {
        "name": "Midtown",
        "address": "650 Ponce De Leon Ave Suite 600A, Atlanta Atlanta, Georgia, 30308",
        "phone": "(404) 876-3689",
        "url": "https://locations.massageenvy.com/georgia/atlanta/650-ponce-de-leon-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.77437004123949,
            -84.36574534470009
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.87,
                    "duration": "indefinitely",
                    "model_conf": 68.8
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 143.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.72,
                    "duration": "indefinitely",
                    "model_conf": 139.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.47,
                    "duration": "3 Months",
                    "model_conf": 80.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.9,
                    "duration": "indefinitely",
                    "model_conf": 149.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.08,
                    "duration": "5 Months",
                    "model_conf": 248.44
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.79,
                    "duration": "3 Months",
                    "model_conf": 151.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.94,
                    "duration": "indefinitely",
                    "model_conf": 137.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.22,
                    "duration": "5 Months",
                    "model_conf": 139.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.63,
                    "duration": "indefinitely",
                    "model_conf": 141.46
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.59,
                    "duration": "1 Months",
                    "model_conf": 140.49
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.26,
                    "duration": "5 Months",
                    "model_conf": 184.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.63,
                    "duration": "indefinitely",
                    "model_conf": 81.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 51.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.91,
                    "duration": "4 Months",
                    "model_conf": 192.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/cumming/2065-market-place-blvd.html": {
        "name": "Cumming Town Center",
        "address": "2065 Market Place Blvd, Cumming, Georgia, 30041",
        "phone": "(678) 208-2888",
        "url": "https://locations.massageenvy.com/georgia/cumming/2065-market-place-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            34.192123544396395,
            -84.12898856311273
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.96,
                    "duration": "indefinitely",
                    "model_conf": 71.13
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.42,
                    "duration": "1 Months",
                    "model_conf": 157.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.1,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.68,
                    "duration": "2 Months",
                    "model_conf": 97.65
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.14,
                    "duration": "1 Months",
                    "model_conf": 154.63
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.0,
                    "duration": "indefinitely",
                    "model_conf": 277.52
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.95,
                    "duration": "indefinitely",
                    "model_conf": 191.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.88,
                    "duration": "indefinitely",
                    "model_conf": 162.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.28,
                    "duration": "4 Months",
                    "model_conf": 164.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.39,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.62,
                    "duration": "1 Months",
                    "model_conf": 233.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.07,
                    "duration": "indefinitely",
                    "model_conf": 84.47
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.04,
                    "duration": "indefinitely",
                    "model_conf": 55.29
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.13,
                    "duration": "1 Months",
                    "model_conf": 213.31
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/cumming/2585-peachtree-parkway.html": {
        "name": "Johns Creek",
        "address": "2585 Peachtree Parkway Ste 200, Cumming, Georgia, 30041",
        "phone": "(678) 208-0680",
        "url": "https://locations.massageenvy.com/georgia/cumming/2585-peachtree-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.097149,
            -84.1765366
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 70.7
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.78,
                    "duration": "indefinitely",
                    "model_conf": 156.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.13,
                    "duration": "2 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 87.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.34,
                    "duration": "1 Months",
                    "model_conf": 153.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.73,
                    "duration": "indefinitely",
                    "model_conf": 268.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.04,
                    "duration": "indefinitely",
                    "model_conf": 179.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.15,
                    "duration": "indefinitely",
                    "model_conf": 149.45
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 155.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.26,
                    "duration": "1 Months",
                    "model_conf": 152.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.57,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.94,
                    "duration": "indefinitely",
                    "model_conf": 222.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.53,
                    "duration": "1 Months",
                    "model_conf": 83.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.63,
                    "duration": "2 Months",
                    "model_conf": 54.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.26,
                    "duration": "indefinitely",
                    "model_conf": 206.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/marietta/1205-johnson-ferry-road.html": {
        "name": "East Cobb",
        "address": "1205 Johnson Ferry Road Ste 126, Marietta, Georgia, 30068",
        "phone": "(678) 213-2368",
        "url": "https://locations.massageenvy.com/georgia/marietta/1205-johnson-ferry-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.9767611073905,
            -84.41857959962843
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.99,
                    "duration": "indefinitely",
                    "model_conf": 69.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 150.13
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.57,
                    "duration": "1 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 88.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 151.37
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.67,
                    "duration": "indefinitely",
                    "model_conf": 262.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 175.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 149.23
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.52,
                    "duration": "2 Months",
                    "model_conf": 148.49
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 149.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 140.57
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.63,
                    "duration": "1 Months",
                    "model_conf": 210.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 82.1
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 53.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.47,
                    "duration": "1 Months",
                    "model_conf": 197.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/marietta/2960-shallowford-rd.html": {
        "name": "Sandy Plains",
        "address": "2960 Shallowford Rd Suite 201, Marietta Marietta, Georgia, 30066",
        "phone": "(470) 298-3689",
        "url": "https://locations.massageenvy.com/georgia/marietta/2960-shallowford-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.03525385490544,
            -84.46703802760646
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 70.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.93,
                    "duration": "3 Months",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.95,
                    "duration": "indefinitely",
                    "model_conf": 89.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.3,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 270.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 153.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.89,
                    "duration": "indefinitely",
                    "model_conf": 153.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.96,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.32,
                    "duration": "indefinitely",
                    "model_conf": 83.13
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.96,
                    "duration": "indefinitely",
                    "model_conf": 54.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.31,
                    "duration": "indefinitely",
                    "model_conf": 202.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/georgia/marietta/3000-windy-hill-rd.html": {
        "name": "Windy Hill @ Powers Ferry",
        "address": "3000 Windy Hill Rd Suite #192, Marietta, Georgia, 30067",
        "phone": "(770) 955-3339",
        "url": "https://locations.massageenvy.com/georgia/marietta/3000-windy-hill-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "12:00 PM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.90436222735167,
            -84.46673154830933
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.01,
                    "duration": "indefinitely",
                    "model_conf": 69.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.33,
                    "duration": "4 Months",
                    "model_conf": 93.08
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.52,
                    "duration": "1 Months",
                    "model_conf": 151.56
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.18,
                    "duration": "5 Months",
                    "model_conf": 263.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.68,
                    "duration": "indefinitely",
                    "model_conf": 156.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.58,
                    "duration": "9 Months",
                    "model_conf": 153.17
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.71,
                    "duration": "indefinitely",
                    "model_conf": 149.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.15,
                    "duration": "indefinitely",
                    "model_conf": 140.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.59,
                    "duration": "indefinitely",
                    "model_conf": 83.07
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.55,
                    "duration": "indefinitely",
                    "model_conf": 53.95
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.45,
                    "duration": "3 Months",
                    "model_conf": 202.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/1136-s.-delano-ct-west.html": {
        "name": "Chicago South Loop",
        "address": "1136 S. Delano Ct West Suite E201, Chicago, Illinois, 60605",
        "phone": "(312) 386-1000",
        "url": "https://locations.massageenvy.com/illinois/chicago/1136-s.-delano-ct-west.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            41.867972,
            -87.6329587
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.22,
                    "duration": "1 Months",
                    "model_conf": 67.21
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.84,
                    "duration": "5 Months",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.51,
                    "duration": "2 Months",
                    "model_conf": 77.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.54,
                    "duration": "indefinitely",
                    "model_conf": 145.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.44,
                    "duration": "7 Months",
                    "model_conf": 229.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.73,
                    "duration": "3 Months",
                    "model_conf": 140.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.14,
                    "duration": "indefinitely",
                    "model_conf": 124.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.76,
                    "duration": "indefinitely",
                    "model_conf": 131.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.45,
                    "duration": "5 Months",
                    "model_conf": 140.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.44,
                    "duration": "indefinitely",
                    "model_conf": 78.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.36,
                    "duration": "indefinitely",
                    "model_conf": 49.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.17,
                    "duration": "1 Months",
                    "model_conf": 164.48
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/1222-n.-wells-street.html": {
        "name": "Chicago Old Town-Gold Coast",
        "address": "1222 N. Wells Street, Chicago, Illinois, 60610",
        "phone": "(312) 642-3689",
        "url": "https://locations.massageenvy.com/illinois/chicago/1222-n.-wells-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:30 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "11:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:30 PM",
            "FRIDAY": "9:00 AM - 8:30 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.904528859182555,
            -87.63475567102432
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.76,
                    "duration": "2 Months",
                    "model_conf": 65.17
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.68,
                    "duration": "3 Months",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.31,
                    "duration": "3 Months",
                    "model_conf": 60.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.04,
                    "duration": "indefinitely",
                    "model_conf": 139.22
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.06,
                    "duration": "11 Months",
                    "model_conf": 196.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.78,
                    "duration": "3 Months",
                    "model_conf": 124.46
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.32,
                    "duration": "3 Months",
                    "model_conf": 96.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.64,
                    "duration": "indefinitely",
                    "model_conf": 117.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.57,
                    "duration": "7 Months",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.59,
                    "duration": "indefinitely",
                    "model_conf": 74.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.02,
                    "duration": "indefinitely",
                    "model_conf": 46.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.03,
                    "duration": "3 Months",
                    "model_conf": 127.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/1520-north-damen-ave..html": {
        "name": "Chicago Bucktown - Wicker Park",
        "address": "1520 North Damen Ave. Suite B, Chicago, Illinois, 60622",
        "phone": "(773) 292-1300",
        "url": "https://locations.massageenvy.com/illinois/chicago/1520-north-damen-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.908854,
            -87.677628
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.28,
                    "duration": "1 Months",
                    "model_conf": 65.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.7,
                    "duration": "5 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.01,
                    "duration": "5 Months",
                    "model_conf": 63.8
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.25,
                    "duration": "1 Months",
                    "model_conf": 140.34
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 171.79,
                    "duration": "7 Months",
                    "model_conf": 203.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.36,
                    "duration": "1 Months",
                    "model_conf": 127.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.47,
                    "duration": "2 Months",
                    "model_conf": 104.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.66,
                    "duration": "indefinitely",
                    "model_conf": 120.19
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.78,
                    "duration": "2 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.82,
                    "duration": "indefinitely",
                    "model_conf": 75.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.11,
                    "duration": "indefinitely",
                    "model_conf": 47.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.56,
                    "duration": "2 Months",
                    "model_conf": 137.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/1845-north-clybourn-ave.html": {
        "name": "Chicago Lincoln Park Clybourn",
        "address": "1845 North Clybourn Ave Ste H, Chicago, Illinois, 60614",
        "phone": "(773) 904-1100",
        "url": "https://locations.massageenvy.com/illinois/chicago/1845-north-clybourn-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.914566,
            -87.65421
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.03,
                    "duration": "2 Months",
                    "model_conf": 64.96
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.3,
                    "duration": "4 Months",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.94,
                    "duration": "5 Months",
                    "model_conf": 56.11
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.54,
                    "duration": "indefinitely",
                    "model_conf": 138.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.34,
                    "duration": "10 Months",
                    "model_conf": 189.8
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.65,
                    "duration": "indefinitely",
                    "model_conf": 121.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.76,
                    "duration": "6 Months",
                    "model_conf": 91.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.92,
                    "duration": "indefinitely",
                    "model_conf": 113.71
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.84,
                    "duration": "4 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.6,
                    "duration": "indefinitely",
                    "model_conf": 74.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.17,
                    "duration": "indefinitely",
                    "model_conf": 45.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.71,
                    "duration": "4 Months",
                    "model_conf": 120.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/345-east-ohio-street.html": {
        "name": "Chicago Streeterville-River North",
        "address": "345 East Ohio Street, Chicago, Illinois, 60611",
        "phone": "(312) 222-0808",
        "url": "https://locations.massageenvy.com/illinois/chicago/345-east-ohio-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            41.8923873,
            -87.6190414
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.19,
                    "duration": "1 Months",
                    "model_conf": 66.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.49,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.36,
                    "duration": "indefinitely",
                    "model_conf": 69.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.81,
                    "duration": "indefinitely",
                    "model_conf": 142.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.73,
                    "duration": "9 Months",
                    "model_conf": 213.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.03,
                    "duration": "2 Months",
                    "model_conf": 132.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.29,
                    "duration": "indefinitely",
                    "model_conf": 110.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.96,
                    "duration": "indefinitely",
                    "model_conf": 124.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.61,
                    "duration": "9 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.03,
                    "duration": "indefinitely",
                    "model_conf": 76.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.51,
                    "duration": "indefinitely",
                    "model_conf": 48.15
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.57,
                    "duration": "2 Months",
                    "model_conf": 146.88
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/3718-n-southport-ave.html": {
        "name": "Chicago Lakeview-Wrigleyville",
        "address": "3718 N Southport Ave, Chicago, Illinois, 60613",
        "phone": "(773) 747-3232",
        "url": "https://locations.massageenvy.com/illinois/chicago/3718-n-southport-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.949512,
            -87.664433
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.94,
                    "duration": "indefinitely",
                    "model_conf": 67.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.71,
                    "duration": "3 Months",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.64,
                    "duration": "7 Months",
                    "model_conf": 72.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.4,
                    "duration": "2 Months",
                    "model_conf": 143.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.94,
                    "duration": "4 Months",
                    "model_conf": 221.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.02,
                    "duration": "indefinitely",
                    "model_conf": 135.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.2,
                    "duration": "9 Months",
                    "model_conf": 119.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 222.21,
                    "duration": "indefinitely",
                    "model_conf": 126.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.34,
                    "duration": "indefinitely",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.57,
                    "duration": "indefinitely",
                    "model_conf": 77.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.61,
                    "duration": "indefinitely",
                    "model_conf": 48.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 217.33,
                    "duration": "4 Months",
                    "model_conf": 156.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/668-w-diversey-pkwy.html": {
        "name": "Chicago Lincoln Park Diversey",
        "address": "668 W Diversey Pkwy, Chicago, Illinois, 60614",
        "phone": "(773) 747-3000",
        "url": "https://locations.massageenvy.com/illinois/chicago/668-w-diversey-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.93297780819829,
            -87.64626174659944
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.65,
                    "duration": "indefinitely",
                    "model_conf": 65.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.98,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.96,
                    "duration": "indefinitely",
                    "model_conf": 64.73
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.54,
                    "duration": "indefinitely",
                    "model_conf": 140.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.66,
                    "duration": "5 Months",
                    "model_conf": 205.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.55,
                    "duration": "1 Months",
                    "model_conf": 128.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.8,
                    "duration": "1 Months",
                    "model_conf": 106.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.82,
                    "duration": "indefinitely",
                    "model_conf": 120.3
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.29,
                    "duration": "1 Months",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.37,
                    "duration": "indefinitely",
                    "model_conf": 75.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.45,
                    "duration": "indefinitely",
                    "model_conf": 47.42
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.34,
                    "duration": "1 Months",
                    "model_conf": 140.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/chicago/808-w-monroe-street.html": {
        "name": "Chicago West Loop",
        "address": "808 W Monroe Street, Chicago, Illinois, 60607",
        "phone": "(312) 564-5260",
        "url": "https://locations.massageenvy.com/illinois/chicago/808-w-monroe-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            41.880712,
            -87.647746
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.99,
                    "duration": "indefinitely",
                    "model_conf": 66.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.55,
                    "duration": "3 Months",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.69,
                    "duration": "1 Months",
                    "model_conf": 68.13
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.72,
                    "duration": "indefinitely",
                    "model_conf": 141.91
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.67,
                    "duration": "5 Months",
                    "model_conf": 211.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.78,
                    "duration": "indefinitely",
                    "model_conf": 131.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.84,
                    "duration": "indefinitely",
                    "model_conf": 108.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.83,
                    "duration": "indefinitely",
                    "model_conf": 123.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.05,
                    "duration": "4 Months",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.21,
                    "duration": "indefinitely",
                    "model_conf": 76.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.12,
                    "duration": "indefinitely",
                    "model_conf": 47.94
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.16,
                    "duration": "1 Months",
                    "model_conf": 144.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/naperville/1212-s-naper-blvd.html": {
        "name": "Naperville East",
        "address": "1212 S Naper Blvd Ste 106, Naperville, Illinois, 60540",
        "phone": "(630) 718-3700",
        "url": "https://locations.massageenvy.com/illinois/naperville/1212-s-naper-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            41.75063743424177,
            -88.11297532988328
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.26,
                    "duration": "6 Months",
                    "model_conf": 267.06
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.41,
                    "duration": "1 Months",
                    "model_conf": 182.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.06,
                    "duration": "indefinitely",
                    "model_conf": 159.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.62,
                    "duration": "indefinitely",
                    "model_conf": 151.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 145.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.3,
                    "duration": "indefinitely",
                    "model_conf": 140.62
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.14,
                    "duration": "2 Months",
                    "model_conf": 218.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.1,
                    "duration": "indefinitely",
                    "model_conf": 83.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.49,
                    "duration": "indefinitely",
                    "model_conf": 54.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 187.33,
                    "duration": "5 Months",
                    "model_conf": 206.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/naperville/2720-showplace-drive.html": {
        "name": "Naperville South",
        "address": "2720 Showplace Drive Suite 100, Naperville, Illinois, 60564",
        "phone": "(630) 717-3689",
        "url": "https://locations.massageenvy.com/illinois/naperville/2720-showplace-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            41.71479785306768,
            -88.20718870319979
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.77,
                    "duration": "1 Months",
                    "model_conf": 272.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.16,
                    "duration": "2 Months",
                    "model_conf": 162.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.42,
                    "duration": "3 Months",
                    "model_conf": 158.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 147.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.93,
                    "duration": "2 Months",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 223.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.77,
                    "duration": "indefinitely",
                    "model_conf": 83.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.61,
                    "duration": "indefinitely",
                    "model_conf": 55.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.09,
                    "duration": "3 Months",
                    "model_conf": 212.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/illinois/naperville/2760-aurora-avenue.html": {
        "name": "Naperville North",
        "address": "2760 Aurora Avenue Suite 102, Naperville Naperville, Illinois, 60540",
        "phone": "(630) 355-3689",
        "url": "https://locations.massageenvy.com/illinois/naperville/2760-aurora-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.76188174959255,
            -88.19908616553727
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 46.01,
                    "duration": "indefinitely",
                    "model_conf": 269.52
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 184.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.39,
                    "duration": "4 Months",
                    "model_conf": 161.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.48,
                    "duration": "indefinitely",
                    "model_conf": 156.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.16,
                    "duration": "1 Months",
                    "model_conf": 147.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.45,
                    "duration": "1 Months",
                    "model_conf": 140.44
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.08,
                    "duration": "indefinitely",
                    "model_conf": 221.21
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.76,
                    "duration": "indefinitely",
                    "model_conf": 83.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.66,
                    "duration": "indefinitely",
                    "model_conf": 54.96
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 210.49
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/carmel/1950-grayhound-pass.html": {
        "name": "North Carmel Westfield",
        "address": "1950 Grayhound Pass #10, Carmel, Indiana, 46033",
        "phone": "(317) 816-0600",
        "url": "https://locations.massageenvy.com/indiana/carmel/1950-grayhound-pass.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.00505823275605,
            -86.1243166347252
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.37,
                    "duration": "indefinitely",
                    "model_conf": 69.47
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.24,
                    "duration": "indefinitely",
                    "model_conf": 138.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.2,
                    "duration": "indefinitely",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.06,
                    "duration": "indefinitely",
                    "model_conf": 87.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.97,
                    "duration": "indefinitely",
                    "model_conf": 146.97
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.51,
                    "duration": "indefinitely",
                    "model_conf": 255.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 173.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.19,
                    "duration": "indefinitely",
                    "model_conf": 149.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.41,
                    "duration": "indefinitely",
                    "model_conf": 141.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.49,
                    "duration": "indefinitely",
                    "model_conf": 143.75
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.7,
                    "duration": "indefinitely",
                    "model_conf": 140.07
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.44,
                    "duration": "indefinitely",
                    "model_conf": 204.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.0,
                    "duration": "indefinitely",
                    "model_conf": 82.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.77,
                    "duration": "indefinitely",
                    "model_conf": 52.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.52,
                    "duration": "indefinitely",
                    "model_conf": 190.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/carmel/4000-w-106th-st.html": {
        "name": "West Carmel Zionsville",
        "address": "4000 W 106th St Suite 155, Carmel, Indiana, 46032",
        "phone": "(317) 873-3909",
        "url": "https://locations.massageenvy.com/indiana/carmel/4000-w-106th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 6:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.941779520243834,
            -86.23457290674594
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.2,
                    "duration": "indefinitely",
                    "model_conf": 69.33
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.14,
                    "duration": "2 Months",
                    "model_conf": 140.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 139.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.35,
                    "duration": "4 Months",
                    "model_conf": 85.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 147.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.83,
                    "duration": "indefinitely",
                    "model_conf": 256.93
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.53,
                    "duration": "1 Months",
                    "model_conf": 174.0
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.01,
                    "duration": "indefinitely",
                    "model_conf": 150.23
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.51,
                    "duration": "3 Months",
                    "model_conf": 143.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.82,
                    "duration": "indefinitely",
                    "model_conf": 144.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.32,
                    "duration": "indefinitely",
                    "model_conf": 140.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.75,
                    "duration": "4 Months",
                    "model_conf": 204.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.94,
                    "duration": "indefinitely",
                    "model_conf": 82.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 52.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.39,
                    "duration": "1 Months",
                    "model_conf": 192.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/fort-wayne/4302-coldwater-road.html": {
        "name": "Glenbrook",
        "address": "4302 Coldwater Road, Fort Wayne, Indiana, 46805",
        "phone": "(260) 423-2300",
        "url": "https://locations.massageenvy.com/indiana/fort-wayne/4302-coldwater-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.1170184,
            -85.134244
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.08,
                    "duration": "indefinitely",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.36,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.01,
                    "duration": "indefinitely",
                    "model_conf": 101.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 154.96
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.48,
                    "duration": "indefinitely",
                    "model_conf": 281.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.51,
                    "duration": "1 Months",
                    "model_conf": 165.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.59,
                    "duration": "indefinitely",
                    "model_conf": 165.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 157.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.5,
                    "duration": "1 Months",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.12,
                    "duration": "indefinitely",
                    "model_conf": 85.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.86,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 221.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/fort-wayne/6409-w-jefferson-blvd.html": {
        "name": "West Jefferson",
        "address": "6409 W Jefferson Blvd, Fort Wayne, Indiana, 46804",
        "phone": "(260) 489-3689",
        "url": "https://locations.massageenvy.com/indiana/fort-wayne/6409-w-jefferson-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.0547294,
            -85.2244303
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.21,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.78,
                    "duration": "1 Months",
                    "model_conf": 101.32
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.61,
                    "duration": "indefinitely",
                    "model_conf": 281.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.9,
                    "duration": "indefinitely",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.14,
                    "duration": "indefinitely",
                    "model_conf": 165.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.42,
                    "duration": "1 Months",
                    "model_conf": 157.79
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.9,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.96,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.8,
                    "duration": "1 Months",
                    "model_conf": 55.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.17,
                    "duration": "indefinitely",
                    "model_conf": 221.68
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/indianapolis/6280-n-college-ave.html": {
        "name": "Broad Ripple",
        "address": "6280 N College Ave Ste 150, Indianapolis, Indiana, 46220",
        "phone": "(317) 253-5000",
        "url": "https://locations.massageenvy.com/indiana/indianapolis/6280-n-college-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 5:00 PM",
            "TUESDAY": "10:00 AM - 5:00 PM",
            "WEDNESDAY": "10:00 AM - 5:00 PM",
            "THURSDAY": "10:00 AM - 5:00 PM",
            "FRIDAY": "10:00 AM - 5:00 PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            39.86911300899692,
            -86.14636328108978
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 69.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 137.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 139.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 85.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.89,
                    "duration": "indefinitely",
                    "model_conf": 146.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.82,
                    "duration": "3 Months",
                    "model_conf": 249.97
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.06,
                    "duration": "2 Months",
                    "model_conf": 169.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 147.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.01,
                    "duration": "indefinitely",
                    "model_conf": 138.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.92,
                    "duration": "indefinitely",
                    "model_conf": 141.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.27,
                    "duration": "1 Months",
                    "model_conf": 140.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.11,
                    "duration": "2 Months",
                    "model_conf": 201.27
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 81.33
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.58,
                    "duration": "indefinitely",
                    "model_conf": 52.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 186.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/indianapolis/6815-s-emerson-avenue.html": {
        "name": "Southport",
        "address": "6815 S Emerson Avenue, Indianapolis, Indiana, 46237",
        "phone": "(317) 782-1400",
        "url": "https://locations.massageenvy.com/indiana/indianapolis/6815-s-emerson-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.6683114,
            -86.0818425
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.92,
                    "duration": "indefinitely",
                    "model_conf": 69.74
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.47,
                    "duration": "indefinitely",
                    "model_conf": 148.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.6,
                    "duration": "1 Months",
                    "model_conf": 139.67
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.21,
                    "duration": "indefinitely",
                    "model_conf": 98.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.56,
                    "duration": "indefinitely",
                    "model_conf": 151.38
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.75,
                    "duration": "indefinitely",
                    "model_conf": 275.82
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.66,
                    "duration": "indefinitely",
                    "model_conf": 179.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.01,
                    "duration": "3 Months",
                    "model_conf": 165.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.96,
                    "duration": "indefinitely",
                    "model_conf": 153.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.81,
                    "duration": "2 Months",
                    "model_conf": 148.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.31,
                    "duration": "indefinitely",
                    "model_conf": 140.46
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 122.94,
                    "duration": "indefinitely",
                    "model_conf": 230.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.56,
                    "duration": "1 Months",
                    "model_conf": 84.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.2,
                    "duration": "5 Months",
                    "model_conf": 54.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.76,
                    "duration": "indefinitely",
                    "model_conf": 201.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/indianapolis/8555-n-river-road.html": {
        "name": "Keystone At The Crossing",
        "address": "8555 N River Road Ste 140, Indianapolis, Indiana, 46240",
        "phone": "(317) 846-8300",
        "url": "https://locations.massageenvy.com/indiana/indianapolis/8555-n-river-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.911646,
            -86.105418
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.2,
                    "duration": "indefinitely",
                    "model_conf": 238.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 116.69,
                    "duration": "10 Months",
                    "model_conf": 137.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.18,
                    "duration": "indefinitely",
                    "model_conf": 125.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 177.87,
                    "duration": "4 Months",
                    "model_conf": 135.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.32,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.48,
                    "duration": "1 Months",
                    "model_conf": 79.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 50.94
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.95,
                    "duration": "indefinitely",
                    "model_conf": 169.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/noblesville/14139-town-center-blvd.html": {
        "name": "Hamilton Town Center",
        "address": "14139 Town Center Blvd Ste 750, Noblesville, Indiana, 46060",
        "phone": "(317) 776-2550",
        "url": "https://locations.massageenvy.com/indiana/noblesville/14139-town-center-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.99448510405905,
            -85.9325534404928
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.51,
                    "duration": "indefinitely",
                    "model_conf": 277.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 164.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.38,
                    "duration": "indefinitely",
                    "model_conf": 160.4
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.25,
                    "duration": "indefinitely",
                    "model_conf": 155.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/indiana/noblesville/17247-mercantile-boulevard.html": {
        "name": "Noblesville",
        "address": "17247 Mercantile Boulevard, Noblesville, Indiana, 46060",
        "phone": "(317) 770-4910",
        "url": "https://locations.massageenvy.com/indiana/noblesville/17247-mercantile-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.0390108,
            -85.9941609
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.93,
                    "duration": "indefinitely",
                    "model_conf": 70.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.52,
                    "duration": "indefinitely",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 95.0
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.47,
                    "duration": "1 Months",
                    "model_conf": 153.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.11,
                    "duration": "indefinitely",
                    "model_conf": 274.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 162.25
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.09,
                    "duration": "indefinitely",
                    "model_conf": 154.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 154.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.75,
                    "duration": "indefinitely",
                    "model_conf": 140.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.97,
                    "duration": "indefinitely",
                    "model_conf": 84.77
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 54.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 212.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/olathe/14913-west-119th-street.html": {
        "name": "Olathe Pointe",
        "address": "14913 West 119th Street, Olathe Olathe, Kansas, 66062",
        "phone": "(913) 747-3689",
        "url": "https://locations.massageenvy.com/kansas/olathe/14913-west-119th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.911274239902035,
            -94.75834799690432
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.78,
                    "duration": "indefinitely",
                    "model_conf": 68.52
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.84,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.2,
                    "duration": "indefinitely",
                    "model_conf": 65.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.54,
                    "duration": "indefinitely",
                    "model_conf": 154.28
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.72,
                    "duration": "3 Months",
                    "model_conf": 278.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.68,
                    "duration": "1 Months",
                    "model_conf": 159.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.16,
                    "duration": "indefinitely",
                    "model_conf": 120.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.54,
                    "duration": "indefinitely",
                    "model_conf": 145.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.0,
                    "duration": "2 Months",
                    "model_conf": 139.73
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.46,
                    "duration": "indefinitely",
                    "model_conf": 79.37
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 51.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 154.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/olathe/15032-s-black-bob-road.html": {
        "name": "South Olathe",
        "address": "15032 S Black Bob Road, Olathe Olathe, Kansas, 66062",
        "phone": "(913) 390-6400",
        "url": "https://locations.massageenvy.com/kansas/olathe/15032-s-black-bob-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.856431791239295,
            -94.7619832542211
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.59,
                    "duration": "1 Months",
                    "model_conf": 69.93
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.28,
                    "duration": "indefinitely",
                    "model_conf": 145.92
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.71,
                    "duration": "1 Months",
                    "model_conf": 86.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.07,
                    "duration": "indefinitely",
                    "model_conf": 154.35
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.49,
                    "duration": "7 Months",
                    "model_conf": 281.03
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.41,
                    "duration": "5 Months",
                    "model_conf": 189.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 163.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.86,
                    "duration": "indefinitely",
                    "model_conf": 146.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.02,
                    "duration": "indefinitely",
                    "model_conf": 151.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.34,
                    "duration": "1 Months",
                    "model_conf": 140.34
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.75,
                    "duration": "4 Months",
                    "model_conf": 223.29
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.59,
                    "duration": "indefinitely",
                    "model_conf": 82.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.56,
                    "duration": "indefinitely",
                    "model_conf": 54.05
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.64,
                    "duration": "indefinitely",
                    "model_conf": 191.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/wichita/10096-e-13th-st.html": {
        "name": "Wichita East",
        "address": "10096 E 13th St Ste 126, Wichita Wichita, Kansas, 67206",
        "phone": "(316) 630-9400",
        "url": "https://locations.massageenvy.com/kansas/wichita/10096-e-13th-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.709467832742085,
            -97.22121538133706
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.8,
                    "duration": "indefinitely",
                    "model_conf": 71.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.71,
                    "duration": "4 Months",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.15,
                    "duration": "indefinitely",
                    "model_conf": 102.11
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.71,
                    "duration": "indefinitely",
                    "model_conf": 154.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.92,
                    "duration": "indefinitely",
                    "model_conf": 283.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 164.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 166.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.54,
                    "duration": "indefinitely",
                    "model_conf": 156.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.57,
                    "duration": "1 Months",
                    "model_conf": 140.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.76,
                    "duration": "indefinitely",
                    "model_conf": 85.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.77,
                    "duration": "indefinitely",
                    "model_conf": 221.1
                }
            }
        }
    },
    "https://locations.massageenvy.com/kansas/wichita/2441-n-maize-rd.html": {
        "name": "Wichita West",
        "address": "2441 N Maize Rd Ste 1601, Wichita Wichita, Kansas, 67205",
        "phone": "(316) 722-9100",
        "url": "https://locations.massageenvy.com/kansas/wichita/2441-n-maize-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            37.7289088,
            -97.4635884
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + 12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.1,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.26,
                    "duration": "2 Months",
                    "model_conf": 139.8
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.3,
                    "duration": "indefinitely",
                    "model_conf": 102.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.16,
                    "duration": "indefinitely",
                    "model_conf": 154.15
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.57,
                    "duration": "1 Months",
                    "model_conf": 283.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.2,
                    "duration": "indefinitely",
                    "model_conf": 165.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.06,
                    "duration": "indefinitely",
                    "model_conf": 166.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.98,
                    "duration": "indefinitely",
                    "model_conf": 156.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.49,
                    "duration": "1 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.18,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 221.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/lexington/116-marketplace-drive.html": {
        "name": "Nicholasville Rd.",
        "address": "116 Marketplace Drive Ste A, Lexington, Kentucky, 40503",
        "phone": "(859) 899-2300",
        "url": "https://locations.massageenvy.com/kentucky/lexington/116-marketplace-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            37.977266,
            -84.531199
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.87,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.37,
                    "duration": "4 Months",
                    "model_conf": 139.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.98,
                    "duration": "indefinitely",
                    "model_conf": 85.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 153.92
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.01,
                    "duration": "indefinitely",
                    "model_conf": 279.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 158.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.34,
                    "duration": "indefinitely",
                    "model_conf": 167.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.26,
                    "duration": "3 Months",
                    "model_conf": 144.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.22,
                    "duration": "2 Months",
                    "model_conf": 85.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.38,
                    "duration": "3 Months",
                    "model_conf": 55.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.65,
                    "duration": "indefinitely",
                    "model_conf": 168.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/lexington/1908-bryant-rd.html": {
        "name": "Hamburg",
        "address": "1908 Bryant Rd Suite 140, Lexington, Kentucky, 40509",
        "phone": "(859) 258-2200",
        "url": "https://locations.massageenvy.com/kentucky/lexington/1908-bryant-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            38.01823455517029,
            -84.41435186512433
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.29,
                    "duration": "indefinitely",
                    "model_conf": 71.41
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.76,
                    "duration": "indefinitely",
                    "model_conf": 97.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.4,
                    "duration": "indefinitely",
                    "model_conf": 154.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.17,
                    "duration": "indefinitely",
                    "model_conf": 281.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.57,
                    "duration": "4 Months",
                    "model_conf": 164.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.56,
                    "duration": "indefinitely",
                    "model_conf": 164.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.71,
                    "duration": "2 Months",
                    "model_conf": 154.72
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.69,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 85.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.2,
                    "duration": "indefinitely",
                    "model_conf": 210.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/louisville/12911-shelbyville-road.html": {
        "name": "Middletown",
        "address": "12911 Shelbyville Road Ste 109, Louisville, Kentucky, 40243",
        "phone": "(502) 253-2980",
        "url": "https://locations.massageenvy.com/kentucky/louisville/12911-shelbyville-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.24408019441119,
            -85.51534643760967
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.16,
                    "duration": "indefinitely",
                    "model_conf": 71.16
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.18,
                    "duration": "5 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.98,
                    "duration": "indefinitely",
                    "model_conf": 102.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.93,
                    "duration": "indefinitely",
                    "model_conf": 154.83
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.65,
                    "duration": "indefinitely",
                    "model_conf": 278.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 157.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.7,
                    "duration": "indefinitely",
                    "model_conf": 160.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.46,
                    "duration": "indefinitely",
                    "model_conf": 157.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.13,
                    "duration": "2 Months",
                    "model_conf": 141.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 54.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.58,
                    "duration": "indefinitely",
                    "model_conf": 211.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/louisville/3563-springhurst-blvd.html": {
        "name": "Springhurst",
        "address": "3563 Springhurst Blvd Ste 29, Louisville, Kentucky, 40241",
        "phone": "(502) 425-0000",
        "url": "https://locations.massageenvy.com/kentucky/louisville/3563-springhurst-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.298398048127225,
            -85.55890713246525
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.57,
                    "duration": "1 Months",
                    "model_conf": 71.1
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.1,
                    "duration": "indefinitely",
                    "model_conf": 157.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.95,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.56,
                    "duration": "2 Months",
                    "model_conf": 101.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.23,
                    "duration": "indefinitely",
                    "model_conf": 154.78
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.4,
                    "duration": "1 Months",
                    "model_conf": 277.78
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.89,
                    "duration": "indefinitely",
                    "model_conf": 193.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 155.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.68,
                    "duration": "1 Months",
                    "model_conf": 158.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 157.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.92,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 234.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.82,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.29,
                    "duration": "indefinitely",
                    "model_conf": 54.15
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.8,
                    "duration": "indefinitely",
                    "model_conf": 208.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/kentucky/louisville/4600-shelbyville-road.html": {
        "name": "St. Matthews",
        "address": "4600 Shelbyville Road #208, Louisville, Kentucky, 40207",
        "phone": "(502) 895-2007",
        "url": "https://locations.massageenvy.com/kentucky/louisville/4600-shelbyville-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.24944282742671,
            -85.62908316931151
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 71.02
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 157.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.63,
                    "duration": "7 Months",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 101.69
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.52,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.1,
                    "duration": "2 Months",
                    "model_conf": 276.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.85,
                    "duration": "3 Months",
                    "model_conf": 192.99
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 151.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.1,
                    "duration": "indefinitely",
                    "model_conf": 154.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.17,
                    "duration": "indefinitely",
                    "model_conf": 157.2
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.27,
                    "duration": "1 Months",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 233.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.4,
                    "duration": "indefinitely",
                    "model_conf": 53.72
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 204.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/baton-rouge/14650-village-market-st.html": {
        "name": "Long Farm",
        "address": "14650 Village Market St Suite 101, Baton Rouge, Louisiana, 70817",
        "phone": "(225) 754-4960",
        "url": "https://locations.massageenvy.com/louisiana/baton-rouge/14650-village-market-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            30.3621655,
            -91.0127296
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.29,
                    "duration": "indefinitely",
                    "model_conf": 69.72
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.25,
                    "duration": "indefinitely",
                    "model_conf": 151.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.5,
                    "duration": "indefinitely",
                    "model_conf": 139.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.81,
                    "duration": "indefinitely",
                    "model_conf": 94.3
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.71,
                    "duration": "indefinitely",
                    "model_conf": 151.67
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.27,
                    "duration": "indefinitely",
                    "model_conf": 268.04
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.89,
                    "duration": "indefinitely",
                    "model_conf": 181.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.1,
                    "duration": "3 Months",
                    "model_conf": 158.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 51.42,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.3,
                    "duration": "1 Months",
                    "model_conf": 151.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.61,
                    "duration": "1 Months",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.38,
                    "duration": "indefinitely",
                    "model_conf": 222.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.8,
                    "duration": "indefinitely",
                    "model_conf": 83.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.61,
                    "duration": "3 Months",
                    "model_conf": 54.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.79,
                    "duration": "indefinitely",
                    "model_conf": 201.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/baton-rouge/2561-citiplace-ct.html": {
        "name": "Citiplace",
        "address": "2561 Citiplace Ct, Baton Rouge, Louisiana, 70808",
        "phone": "(225) 922-7000",
        "url": "https://locations.massageenvy.com/louisiana/baton-rouge/2561-citiplace-ct.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.423649199020915,
            -91.12890422344213
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.82,
                    "duration": "indefinitely",
                    "model_conf": 66.16
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.58,
                    "duration": "1 Months",
                    "model_conf": 107.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.63,
                    "duration": "1 Months",
                    "model_conf": 139.34
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.89,
                    "duration": "indefinitely",
                    "model_conf": 56.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.79,
                    "duration": "indefinitely",
                    "model_conf": 135.11
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.7,
                    "duration": "indefinitely",
                    "model_conf": 208.62
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.85,
                    "duration": "indefinitely",
                    "model_conf": 133.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.34,
                    "duration": "indefinitely",
                    "model_conf": 118.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.96,
                    "duration": "indefinitely",
                    "model_conf": 92.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 121.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.08,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 141.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.02,
                    "duration": "indefinitely",
                    "model_conf": 76.9
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.07,
                    "duration": "indefinitely",
                    "model_conf": 48.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.28,
                    "duration": "indefinitely",
                    "model_conf": 129.19
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/baton-rouge/7940-bluebonnet-blvd..html": {
        "name": "Bluebonnet",
        "address": "7940 Bluebonnet Blvd., Baton Rouge, Louisiana, 70810",
        "phone": "(225) 761-3689",
        "url": "https://locations.massageenvy.com/louisiana/baton-rouge/7940-bluebonnet-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.3783200767052,
            -91.09886214137077
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 90.175,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.76,
                    "duration": "indefinitely",
                    "model_conf": 68.26
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 134.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.44,
                    "duration": "indefinitely",
                    "model_conf": 139.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.27,
                    "duration": "indefinitely",
                    "model_conf": 82.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.29,
                    "duration": "indefinitely",
                    "model_conf": 146.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.28,
                    "duration": "indefinitely",
                    "model_conf": 247.37
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.4,
                    "duration": "1 Months",
                    "model_conf": 165.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.65,
                    "duration": "1 Months",
                    "model_conf": 145.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.94,
                    "duration": "indefinitely",
                    "model_conf": 135.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 140.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.46,
                    "duration": "1 Months",
                    "model_conf": 140.58
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.53,
                    "duration": "indefinitely",
                    "model_conf": 195.5
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.94,
                    "duration": "indefinitely",
                    "model_conf": 81.23
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.18,
                    "duration": "indefinitely",
                    "model_conf": 51.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.36,
                    "duration": "indefinitely",
                    "model_conf": 179.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/lafayette/130-north-college-road.html": {
        "name": "Lafayette",
        "address": "130 North College Road Ste B, Lafayette, Louisiana, 70506",
        "phone": "(337) 232-7040",
        "url": "https://locations.massageenvy.com/louisiana/lafayette/130-north-college-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.208587,
            -92.039465
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.05,
                    "duration": "indefinitely",
                    "model_conf": 71.36
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.85,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 98.94
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.07,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.19,
                    "duration": "indefinitely",
                    "model_conf": 275.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.2,
                    "duration": "indefinitely",
                    "model_conf": 166.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 155.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.14,
                    "duration": "indefinitely",
                    "model_conf": 157.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.94,
                    "duration": "indefinitely",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.15,
                    "duration": "indefinitely",
                    "model_conf": 86.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.31,
                    "duration": "indefinitely",
                    "model_conf": 219.58
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/lafayette/4243-ambassador-caffery-parkway.html": {
        "name": "Ambassador Crossing",
        "address": "4243 Ambassador Caffery Parkway Suite 106, Lafayette, Louisiana, 70508",
        "phone": "(337) 989-6900",
        "url": "https://locations.massageenvy.com/louisiana/lafayette/4243-ambassador-caffery-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.1620159,
            -92.0531952
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.03,
                    "duration": "indefinitely",
                    "model_conf": 71.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.04,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.88,
                    "duration": "indefinitely",
                    "model_conf": 98.14
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.84,
                    "duration": "indefinitely",
                    "model_conf": 154.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.71,
                    "duration": "indefinitely",
                    "model_conf": 275.51
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.35,
                    "duration": "indefinitely",
                    "model_conf": 166.29
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.95,
                    "duration": "indefinitely",
                    "model_conf": 152.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.67,
                    "duration": "indefinitely",
                    "model_conf": 157.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.44,
                    "duration": "indefinitely",
                    "model_conf": 85.99
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.41,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.42,
                    "duration": "1 Months",
                    "model_conf": 219.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/metairie/3131-veterans-memorial-blvd.html": {
        "name": "Veterans",
        "address": "3131 Veterans Memorial Blvd, Metairie, Louisiana, 70002",
        "phone": "(504) 833-1002",
        "url": "https://locations.massageenvy.com/louisiana/metairie/3131-veterans-memorial-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.004101259357313,
            -90.15389339384842
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.62,
                    "duration": "1 Months",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.34,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.56,
                    "duration": "1 Months",
                    "model_conf": 139.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.49,
                    "duration": "indefinitely",
                    "model_conf": 71.92
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.81,
                    "duration": "indefinitely",
                    "model_conf": 145.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.15,
                    "duration": "indefinitely",
                    "model_conf": 212.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.95,
                    "duration": "indefinitely",
                    "model_conf": 145.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.28,
                    "duration": "2 Months",
                    "model_conf": 167.01
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.75,
                    "duration": "indefinitely",
                    "model_conf": 166.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.67,
                    "duration": "2 Months",
                    "model_conf": 153.92
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.17,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.68,
                    "duration": "indefinitely",
                    "model_conf": 192.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.39,
                    "duration": "1 Months",
                    "model_conf": 79.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.62,
                    "duration": "2 Months",
                    "model_conf": 50.33
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.59,
                    "duration": "indefinitely",
                    "model_conf": 221.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/louisiana/metairie/701-metairie-road.html": {
        "name": "Old Metairie",
        "address": "701 Metairie Road, Metairie, Louisiana, 70005",
        "phone": "(504) 832-0007",
        "url": "https://locations.massageenvy.com/louisiana/metairie/701-metairie-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.9889529,
            -90.1313142
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.4,
                    "duration": "1 Months",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.19,
                    "duration": "indefinitely",
                    "model_conf": 152.06
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.23,
                    "duration": "indefinitely",
                    "model_conf": 139.42
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.13,
                    "duration": "indefinitely",
                    "model_conf": 62.52
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.87,
                    "duration": "indefinitely",
                    "model_conf": 143.75
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.51,
                    "duration": "1 Months",
                    "model_conf": 195.16
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.49,
                    "duration": "1 Months",
                    "model_conf": 133.16
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.31,
                    "duration": "2 Months",
                    "model_conf": 166.96
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.67,
                    "duration": "2 Months",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.0,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.64,
                    "duration": "3 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.55,
                    "duration": "1 Months",
                    "model_conf": 180.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.35,
                    "duration": "2 Months",
                    "model_conf": 77.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.83,
                    "duration": "1 Months",
                    "model_conf": 49.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.42,
                    "duration": "1 Months",
                    "model_conf": 221.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/minneapolis/321-s.-washington-ave..html": {
        "name": "Minneapolis Downtown",
        "address": "321 S. Washington Ave., Minneapolis, Minnesota, 55415",
        "phone": "(612) 332-3689",
        "url": "https://locations.massageenvy.com/minnesota/minneapolis/321-s.-washington-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.9794638,
            -93.2635761
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.24,
                    "duration": "2 Months",
                    "model_conf": 255.81
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.93,
                    "duration": "indefinitely",
                    "model_conf": 175.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.17,
                    "duration": "indefinitely",
                    "model_conf": 157.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.55,
                    "duration": "indefinitely",
                    "model_conf": 151.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.05,
                    "duration": "indefinitely",
                    "model_conf": 143.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/minneapolis/3254-west-lake-street.html": {
        "name": "Lake Street",
        "address": "3254 West Lake Street, Minneapolis, Minnesota, 55416",
        "phone": "(612) 455-9000",
        "url": "https://locations.massageenvy.com/minnesota/minneapolis/3254-west-lake-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            44.9486507,
            -93.3218842
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.37,
                    "duration": "indefinitely",
                    "model_conf": 247.2
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.99,
                    "duration": "indefinitely",
                    "model_conf": 170.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.02,
                    "duration": "indefinitely",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.92,
                    "duration": "indefinitely",
                    "model_conf": 146.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.42,
                    "duration": "1 Months",
                    "model_conf": 140.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.65,
                    "duration": "indefinitely",
                    "model_conf": 140.55
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 203.9
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.69,
                    "duration": "indefinitely",
                    "model_conf": 84.71
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.41,
                    "duration": "3 Months",
                    "model_conf": 53.87
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.3,
                    "duration": "1 Months",
                    "model_conf": 190.89
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/minnetonka/13185-ridgedale-drive.html": {
        "name": "Minnetonka",
        "address": "13185 Ridgedale Drive, Minnetonka, Minnesota, 55305",
        "phone": "(952) 955-8484",
        "url": "https://locations.massageenvy.com/minnesota/minnetonka/13185-ridgedale-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            44.968416,
            -93.446582
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.36,
                    "duration": "indefinitely",
                    "model_conf": 261.46
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.06,
                    "duration": "indefinitely",
                    "model_conf": 181.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.7,
                    "duration": "indefinitely",
                    "model_conf": 158.38
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.48,
                    "duration": "3 Months",
                    "model_conf": 156.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 147.18
                }
            }
        }
    },
    "https://locations.massageenvy.com/minnesota/minnetonka/4754-country-road-101.html": {
        "name": "7-Hi",
        "address": "4754 Country Road 101 Suite E4, Minnetonka, Minnesota, 55345",
        "phone": "(952) 380-1100",
        "url": "https://locations.massageenvy.com/minnesota/minnetonka/4754-country-road-101.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 9:00 PM",
            "TUESDAY": "10:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 9:00 PM",
            "THURSDAY": "10:00 AM - 9:00 PM",
            "FRIDAY": "10:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            44.9181064552606,
            -93.5039404898701
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.36,
                    "duration": "indefinitely",
                    "model_conf": 269.28
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.56,
                    "duration": "indefinitely",
                    "model_conf": 186.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.64,
                    "duration": "4 Months",
                    "model_conf": 159.88
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.97,
                    "duration": "indefinitely",
                    "model_conf": 158.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.38,
                    "duration": "3 Months",
                    "model_conf": 150.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/omaha/1303-south-72nd-street.html": {
        "name": "Aksarben Center",
        "address": "1303 South 72nd Street Suite 208, Omaha, Nebraska, 68124",
        "phone": "(402) 551-3689",
        "url": "https://locations.massageenvy.com/nebraska/omaha/1303-south-72nd-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.2463377,
            -96.0228528
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.77,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.97,
                    "duration": "indefinitely",
                    "model_conf": 102.49
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.77,
                    "duration": "indefinitely",
                    "model_conf": 152.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.33,
                    "duration": "indefinitely",
                    "model_conf": 281.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.89,
                    "duration": "3 Months",
                    "model_conf": 166.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.45,
                    "duration": "indefinitely",
                    "model_conf": 162.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.04,
                    "duration": "indefinitely",
                    "model_conf": 154.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.76,
                    "duration": "8 Months",
                    "model_conf": 140.67
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 83.64
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 55.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 220.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/omaha/16902-wright-plaza.html": {
        "name": "Legacy",
        "address": "16902 Wright Plaza Ste #175, Omaha, Nebraska, 68130",
        "phone": "(402) 898-3689",
        "url": "https://locations.massageenvy.com/nebraska/omaha/16902-wright-plaza.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 4:30 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            41.23294448310666,
            -96.17769182935164
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 70.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.94,
                    "duration": "indefinitely",
                    "model_conf": 102.45
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.04,
                    "duration": "indefinitely",
                    "model_conf": 149.8
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.08,
                    "duration": "indefinitely",
                    "model_conf": 281.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.7,
                    "duration": "3 Months",
                    "model_conf": 166.53
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.45,
                    "duration": "indefinitely",
                    "model_conf": 156.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 152.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.17,
                    "duration": "4 Months",
                    "model_conf": 140.32
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.37,
                    "duration": "1 Months",
                    "model_conf": 80.55
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.72,
                    "duration": "1 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.79,
                    "duration": "indefinitely",
                    "model_conf": 218.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/nebraska/omaha/3525-north-147th-street.html": {
        "name": "Maple",
        "address": "3525 North 147th Street Suite 206, Omaha, Nebraska, 68116",
        "phone": "(402) 991-0711",
        "url": "https://locations.massageenvy.com/nebraska/omaha/3525-north-147th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            41.290454,
            -96.140141
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.03,
                    "duration": "2 Months",
                    "model_conf": 70.6
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.69,
                    "duration": "indefinitely",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 102.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.11,
                    "duration": "indefinitely",
                    "model_conf": 150.46
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.59,
                    "duration": "indefinitely",
                    "model_conf": 279.3
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 127.95,
                    "duration": "6 Months",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.1,
                    "duration": "indefinitely",
                    "model_conf": 158.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.1,
                    "duration": "1 Months",
                    "model_conf": 152.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.36,
                    "duration": "7 Months",
                    "model_conf": 140.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.04,
                    "duration": "2 Months",
                    "model_conf": 81.25
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.41,
                    "duration": "3 Months",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 218.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/henderson/1000-north-green-valley-parkway.html": {
        "name": "Green Valley",
        "address": "1000 North Green Valley Parkway Suite 700, Henderson Henderson, Nevada, 89074",
        "phone": "(702) 563-3689",
        "url": "https://locations.massageenvy.com/nevada/henderson/1000-north-green-valley-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.02915567225653,
            -115.08404237508205
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.9,
                    "duration": "2 Months",
                    "model_conf": 265.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.32,
                    "duration": "indefinitely",
                    "model_conf": 164.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 157.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.69,
                    "duration": "indefinitely",
                    "model_conf": 155.31
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 83.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.14,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.28,
                    "duration": "1 Months",
                    "model_conf": 221.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/henderson/10845-s-eastern-ave.html": {
        "name": "Henderson - Anthem",
        "address": "10845 S Eastern Ave Ste 100, Henderson, Nevada, 89052",
        "phone": "(702) 309-3689",
        "url": "https://locations.massageenvy.com/nevada/henderson/10845-s-eastern-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 10:00 PM"
        },
        "geolocation": [
            35.994478,
            -115.101686
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.6,
                    "duration": "3 Months",
                    "model_conf": 263.5
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.03,
                    "duration": "2 Months",
                    "model_conf": 180.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.62,
                    "duration": "indefinitely",
                    "model_conf": 164.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.09,
                    "duration": "5 Months",
                    "model_conf": 157.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.76,
                    "duration": "indefinitely",
                    "model_conf": 154.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.66,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.55,
                    "duration": "4 Months",
                    "model_conf": 224.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.13,
                    "duration": "indefinitely",
                    "model_conf": 83.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.16,
                    "duration": "indefinitely",
                    "model_conf": 55.21
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.39,
                    "duration": "3 Months",
                    "model_conf": 221.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/henderson/3520-st.-rose-pkwy.html": {
        "name": "St. Rose Parkway",
        "address": "3520 St. Rose Pkwy Suite 103, Henderson, Nevada, 89052",
        "phone": "(702) 966-0454",
        "url": "https://locations.massageenvy.com/nevada/henderson/3520-st.-rose-pkwy.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            35.98778748701305,
            -115.1422160302588
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.55,
                    "duration": "1 Months",
                    "model_conf": 265.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 164.62
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.76,
                    "duration": "1 Months",
                    "model_conf": 158.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.58,
                    "duration": "indefinitely",
                    "model_conf": 155.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 83.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 55.3
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.4,
                    "duration": "1 Months",
                    "model_conf": 221.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/henderson/358-w-lake-mead-parkway.html": {
        "name": "Lake Mead Crossing",
        "address": "358 W Lake Mead Parkway Ste 100, Henderson, Nevada, 89015",
        "phone": "(702) 588-6934",
        "url": "https://locations.massageenvy.com/nevada/henderson/358-w-lake-mead-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            36.03690765921124,
            -114.99509154260977
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.37,
                    "duration": "1 Months",
                    "model_conf": 273.07
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 165.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.49,
                    "duration": "3 Months",
                    "model_conf": 163.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 156.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.4,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 84.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.52,
                    "duration": "indefinitely",
                    "model_conf": 220.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/henderson/711-marks-street.html": {
        "name": "Henderson - Galleria",
        "address": "711 Marks Street Ste A, Henderson Henderson, Nevada, 89014",
        "phone": "(702) 454-3689",
        "url": "https://locations.massageenvy.com/nevada/henderson/711-marks-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.0676368,
            -115.0367897
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.1,
                    "duration": "2 Months",
                    "model_conf": 270.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.27,
                    "duration": "4 Months",
                    "model_conf": 161.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.37,
                    "duration": "indefinitely",
                    "model_conf": 140.92
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 84.41
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 55.56
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.23,
                    "duration": "1 Months",
                    "model_conf": 220.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/4280-blue-diamond-building.html": {
        "name": "Blue Diamond Crossing",
        "address": "4280 Blue Diamond Building Suite 101, Las Vegas Las Vegas, Nevada, 89139",
        "phone": "(702) 851-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/4280-blue-diamond-building.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.03928217525526,
            -115.19858255982399
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.32,
                    "duration": "indefinitely",
                    "model_conf": 254.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.23,
                    "duration": "indefinitely",
                    "model_conf": 184.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.78,
                    "duration": "3 Months",
                    "model_conf": 162.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.58,
                    "duration": "indefinitely",
                    "model_conf": 148.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.46,
                    "duration": "2 Months",
                    "model_conf": 153.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.77,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.43,
                    "duration": "indefinitely",
                    "model_conf": 205.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.85,
                    "duration": "3 Months",
                    "model_conf": 81.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.21,
                    "duration": "4 Months",
                    "model_conf": 54.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.66,
                    "duration": "indefinitely",
                    "model_conf": 220.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/5643-centennial-center-blvd.html": {
        "name": "Centennial Gateway",
        "address": "5643 Centennial Center Blvd Ste 135, Las Vegas, Nevada, 89149",
        "phone": "(702) 228-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/5643-centennial-center-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            36.263499,
            -115.258164
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.29,
                    "duration": "2 Months",
                    "model_conf": 276.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.09,
                    "duration": "indefinitely",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.83,
                    "duration": "7 Months",
                    "model_conf": 164.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.54,
                    "duration": "indefinitely",
                    "model_conf": 156.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.24,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.77,
                    "duration": "indefinitely",
                    "model_conf": 85.21
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.98,
                    "duration": "indefinitely",
                    "model_conf": 55.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.6,
                    "duration": "3 Months",
                    "model_conf": 220.23
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/6475-n-decatur-boulevard.html": {
        "name": "Shadow Mountain",
        "address": "6475 N Decatur Boulevard #160, Las Vegas, Nevada, 89131",
        "phone": "(702) 836-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/6475-n-decatur-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.27687,
            -115.207935
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.23,
                    "duration": "2 Months",
                    "model_conf": 278.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 166.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.14,
                    "duration": "5 Months",
                    "model_conf": 165.14
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.16,
                    "duration": "indefinitely",
                    "model_conf": 157.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.44,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 85.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 55.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.17,
                    "duration": "1 Months",
                    "model_conf": 220.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/6870-s-rainbow-blvd.html": {
        "name": "South Rainbow",
        "address": "6870 S Rainbow Blvd Ste 122, Las Vegas, Nevada, 89118",
        "phone": "(702) 436-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/6870-s-rainbow-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 9:00 PM"
        },
        "geolocation": [
            36.064558,
            -115.241508
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.8,
                    "duration": "indefinitely",
                    "model_conf": 247.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 161.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.51,
                    "duration": "indefinitely",
                    "model_conf": 139.72
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.16,
                    "duration": "1 Months",
                    "model_conf": 151.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 80.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.32,
                    "duration": "4 Months",
                    "model_conf": 54.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.06,
                    "duration": "indefinitely",
                    "model_conf": 221.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/7140-n-durango-drive.html": {
        "name": "Centennial Hills",
        "address": "7140 N Durango Drive #140, Las Vegas Las Vegas, Nevada, 89149",
        "phone": "(702) 839-9997",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/7140-n-durango-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            36.29151749496688,
            -115.28572082519531
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 278.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.36,
                    "duration": "4 Months",
                    "model_conf": 165.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.1,
                    "duration": "indefinitely",
                    "model_conf": 157.13
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.22,
                    "duration": "indefinitely",
                    "model_conf": 140.88
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.9,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.91,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.32,
                    "duration": "1 Months",
                    "model_conf": 219.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/7175-west-lake-mead-blvd.html": {
        "name": "Rock Spring",
        "address": "7175 West Lake Mead Blvd Suite 174, Las Vegas Las Vegas, Nevada, 89128",
        "phone": "(702) 567-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/7175-west-lake-mead-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.195589906372746,
            -115.24883044058208
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.19,
                    "duration": "2 Months",
                    "model_conf": 268.94
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 164.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.94,
                    "duration": "6 Months",
                    "model_conf": 156.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 155.23
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.49,
                    "duration": "indefinitely",
                    "model_conf": 140.99
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 83.79
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 55.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.95,
                    "duration": "2 Months",
                    "model_conf": 219.57
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/8950-w-charleston-boulevard.html": {
        "name": "Boca Park",
        "address": "8950 W Charleston Boulevard Suite 7, Las Vegas Las Vegas, Nevada, 89117",
        "phone": "(702) 212-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/8950-w-charleston-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "8:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.159519178046125,
            -115.29040798544884
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.38,
                    "duration": "1 Months",
                    "model_conf": 259.52
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.57,
                    "duration": "indefinitely",
                    "model_conf": 162.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.05,
                    "duration": "5 Months",
                    "model_conf": 148.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 153.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.01,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 82.3
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.87,
                    "duration": "indefinitely",
                    "model_conf": 54.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.03,
                    "duration": "1 Months",
                    "model_conf": 219.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/las-vegas/9484-west-flamingo-road.html": {
        "name": "West Flamingo",
        "address": "9484 West Flamingo Road Suite 150, Las Vegas Las Vegas, Nevada, 89147",
        "phone": "(702) 368-3689",
        "url": "https://locations.massageenvy.com/nevada/las-vegas/9484-west-flamingo-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.115781966188926,
            -115.29856128232342
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.4,
                    "duration": "indefinitely",
                    "model_conf": 244.25
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.96,
                    "duration": "indefinitely",
                    "model_conf": 178.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.73,
                    "duration": "indefinitely",
                    "model_conf": 160.61
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.48,
                    "duration": "indefinitely",
                    "model_conf": 137.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.41,
                    "duration": "1 Months",
                    "model_conf": 151.03
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.6,
                    "duration": "1 Months",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.68,
                    "duration": "indefinitely",
                    "model_conf": 189.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.18,
                    "duration": "2 Months",
                    "model_conf": 80.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.42,
                    "duration": "3 Months",
                    "model_conf": 54.19
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.53,
                    "duration": "indefinitely",
                    "model_conf": 220.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/reno/5110-maeanne-avenue.html": {
        "name": "NW Reno - Ridgeview Plaza",
        "address": "5110 Maeanne Avenue, Reno Reno, Nevada, 89523",
        "phone": "(775) 825-3689",
        "url": "https://locations.massageenvy.com/nevada/reno/5110-maeanne-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            39.52978127803537,
            -119.86585198842027
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": null,
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.71,
                    "duration": "indefinitely",
                    "model_conf": 279.15
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.97,
                    "duration": "indefinitely",
                    "model_conf": 166.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.51,
                    "duration": "2 Months",
                    "model_conf": 164.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.72,
                    "duration": "indefinitely",
                    "model_conf": 156.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.05,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.73,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.47,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.23,
                    "duration": "1 Months",
                    "model_conf": 217.55
                }
            }
        }
    },
    "https://locations.massageenvy.com/nevada/reno/6795-s-virginia-street.html": {
        "name": "Reno - S. Virginia St.",
        "address": "6795 S Virginia Street Suite A, Reno, Nevada, 89511",
        "phone": "(775) 853-8399",
        "url": "https://locations.massageenvy.com/nevada/reno/6795-s-virginia-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 8:00 PM",
            "TUESDAY": "8:30 AM - 8:00 PM",
            "WEDNESDAY": "8:30 AM - 8:00 PM",
            "THURSDAY": "8:30 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.464556234313534,
            -119.78386117324074
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": null,
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.2,
                    "duration": "4 Months",
                    "model_conf": 272.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.78,
                    "duration": "indefinitely",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.45,
                    "duration": "8 Months",
                    "model_conf": 157.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.4,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.1,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.96,
                    "duration": "indefinitely",
                    "model_conf": 85.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.67,
                    "duration": "indefinitely",
                    "model_conf": 55.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 197.94,
                    "duration": "4 Months",
                    "model_conf": 212.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/albuquerque/3601-old-airport-road-northwest.html": {
        "name": "Cottonwood",
        "address": "3601 Old Airport Road Northwest F6, Albuquerque Albuquerque, New Mexico, 87114",
        "phone": "(505) 922-0202",
        "url": "https://locations.massageenvy.com/new-mexico/albuquerque/3601-old-airport-road-northwest.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.20375120390622,
            -106.65392066119239
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.24,
                    "duration": "indefinitely",
                    "model_conf": 71.25
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.38,
                    "duration": "2 Months",
                    "model_conf": 139.5
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.31,
                    "duration": "indefinitely",
                    "model_conf": 91.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 152.08
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.53,
                    "duration": "indefinitely",
                    "model_conf": 260.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.78,
                    "duration": "indefinitely",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.51,
                    "duration": "indefinitely",
                    "model_conf": 156.62
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.67,
                    "duration": "indefinitely",
                    "model_conf": 151.2
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.92,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.92,
                    "duration": "indefinitely",
                    "model_conf": 83.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.9,
                    "duration": "indefinitely",
                    "model_conf": 55.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.52,
                    "duration": "indefinitely",
                    "model_conf": 202.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/albuquerque/5901-wyoming-blvd-ne.html": {
        "name": "North Towne",
        "address": "5901 Wyoming Blvd NE Suite P3, Albuquerque, New Mexico, 87109",
        "phone": "(505) 242-3689",
        "url": "https://locations.massageenvy.com/new-mexico/albuquerque/5901-wyoming-blvd-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.146834395328625,
            -106.55634820461273
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.84,
                    "duration": "indefinitely",
                    "model_conf": 71.33
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.61,
                    "duration": "3 Months",
                    "model_conf": 94.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.78,
                    "duration": "2 Months",
                    "model_conf": 151.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.61,
                    "duration": "1 Months",
                    "model_conf": 261.21
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.42,
                    "duration": "indefinitely",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.44,
                    "duration": "4 Months",
                    "model_conf": 157.6
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 152.96
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.94,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.47,
                    "duration": "2 Months",
                    "model_conf": 84.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.83,
                    "duration": "1 Months",
                    "model_conf": 54.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.93,
                    "duration": "2 Months",
                    "model_conf": 206.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/albuquerque/6231-riverside-plaza-ln-nw.html": {
        "name": "Riverside Plaza",
        "address": "6231 Riverside Plaza Ln NW Suite B, Albuquerque Albuquerque, New Mexico, 87120",
        "phone": "(505) 890-7979",
        "url": "https://locations.massageenvy.com/new-mexico/albuquerque/6231-riverside-plaza-ln-nw.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.15489048242519,
            -106.68134165889546
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.83,
                    "duration": "indefinitely",
                    "model_conf": 70.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.09,
                    "duration": "indefinitely",
                    "model_conf": 139.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.17,
                    "duration": "indefinitely",
                    "model_conf": 75.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.9,
                    "duration": "indefinitely",
                    "model_conf": 147.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.08,
                    "duration": "indefinitely",
                    "model_conf": 235.7
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.58,
                    "duration": "1 Months",
                    "model_conf": 166.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.4,
                    "duration": "indefinitely",
                    "model_conf": 138.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.39,
                    "duration": "1 Months",
                    "model_conf": 141.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.06,
                    "duration": "indefinitely",
                    "model_conf": 140.68
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.81,
                    "duration": "indefinitely",
                    "model_conf": 80.78
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.89,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.38,
                    "duration": "indefinitely",
                    "model_conf": 169.63
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-mexico/albuquerque/8850-holly-ave-ne.html": {
        "name": "Ventura Place",
        "address": "8850 Holly Ave NE Suite H, Albuquerque Albuquerque, New Mexico, 87122",
        "phone": "(505) 797-3689",
        "url": "https://locations.massageenvy.com/new-mexico/albuquerque/8850-holly-ave-ne.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.1751951,
            -106.5449846
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.93,
                    "duration": "indefinitely",
                    "model_conf": 71.37
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.78,
                    "duration": "indefinitely",
                    "model_conf": 139.56
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.67,
                    "duration": "2 Months",
                    "model_conf": 97.03
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.97,
                    "duration": "2 Months",
                    "model_conf": 152.36
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.31,
                    "duration": "1 Months",
                    "model_conf": 264.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.83,
                    "duration": "indefinitely",
                    "model_conf": 165.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.26,
                    "duration": "5 Months",
                    "model_conf": 159.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.12,
                    "duration": "indefinitely",
                    "model_conf": 154.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.05,
                    "duration": "indefinitely",
                    "model_conf": 84.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.91,
                    "duration": "indefinitely",
                    "model_conf": 54.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.8,
                    "duration": "2 Months",
                    "model_conf": 210.0
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/new-york/312-east-23rd-street.html": {
        "name": "Gramercy Nyc",
        "address": "312 East 23rd Street, New York, New York, 10010",
        "phone": "(212) 995-1999",
        "url": "https://locations.massageenvy.com/new-york/new-york/312-east-23rd-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            40.737403,
            -73.9802453
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.1,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "95",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "95",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.74,
                    "duration": "indefinitely",
                    "model_conf": 66.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.15,
                    "duration": "indefinitely",
                    "model_conf": 138.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.49,
                    "duration": "2 Months",
                    "model_conf": 58.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.71,
                    "duration": "indefinitely",
                    "model_conf": 136.09
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 182.37,
                    "duration": "5 Months",
                    "model_conf": 204.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.49,
                    "duration": "indefinitely",
                    "model_conf": 118.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.19,
                    "duration": "4 Months",
                    "model_conf": 94.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.78,
                    "duration": "indefinitely",
                    "model_conf": 120.35
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.42,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.69,
                    "duration": "indefinitely",
                    "model_conf": 76.29
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.6,
                    "duration": "indefinitely",
                    "model_conf": 47.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.7,
                    "duration": "2 Months",
                    "model_conf": 131.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/new-york/new-york/525-west-42nd-street.html": {
        "name": "Midtown West",
        "address": "525 West 42nd Street, New York, New York, 10036",
        "phone": "(212) 473-3689",
        "url": "https://locations.massageenvy.com/new-york/new-york/525-west-42nd-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.76035088929084,
            -73.9966432478505
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.1,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "95",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "95",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.77,
                    "duration": "indefinitely",
                    "model_conf": 67.35
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.32,
                    "duration": "indefinitely",
                    "model_conf": 138.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.09,
                    "duration": "indefinitely",
                    "model_conf": 67.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.42,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.83,
                    "duration": "indefinitely",
                    "model_conf": 217.37
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.03,
                    "duration": "indefinitely",
                    "model_conf": 127.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.45,
                    "duration": "indefinitely",
                    "model_conf": 109.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.7,
                    "duration": "indefinitely",
                    "model_conf": 126.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.61,
                    "duration": "indefinitely",
                    "model_conf": 139.96
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.32,
                    "duration": "indefinitely",
                    "model_conf": 77.86
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.14,
                    "duration": "indefinitely",
                    "model_conf": 49.15
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 229.94,
                    "duration": "indefinitely",
                    "model_conf": 147.62
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/asheville/1863-hendersonville-road.html": {
        "name": "Asheville South",
        "address": "1863 Hendersonville Road Suite 100, Asheville Asheville, North Carolina, 28803",
        "phone": "(828) 210-1100",
        "url": "https://locations.massageenvy.com/north-carolina/asheville/1863-hendersonville-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.50071503319388,
            -82.52400147385214
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 69.94
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.24,
                    "duration": "2 Months",
                    "model_conf": 156.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.23,
                    "duration": "2 Months",
                    "model_conf": 139.72
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.91,
                    "duration": "indefinitely",
                    "model_conf": 96.96
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.98,
                    "duration": "2 Months",
                    "model_conf": 154.02
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.53,
                    "duration": "1 Months",
                    "model_conf": 279.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 191.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.34,
                    "duration": "indefinitely",
                    "model_conf": 166.21
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.21,
                    "duration": "5 Months",
                    "model_conf": 168.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 156.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.01,
                    "duration": "2 Months",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.19,
                    "duration": "2 Months",
                    "model_conf": 223.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.37,
                    "duration": "2 Months",
                    "model_conf": 85.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.68,
                    "duration": "2 Months",
                    "model_conf": 54.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.92,
                    "duration": "2 Months",
                    "model_conf": 221.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/asheville/354-merrimon-ave..html": {
        "name": "Asheville North",
        "address": "354 Merrimon Ave. Suite 10, Asheville, North Carolina, 28801",
        "phone": "(828) 333-4400",
        "url": "https://locations.massageenvy.com/north-carolina/asheville/354-merrimon-ave..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.611079,
            -82.555362
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.79,
                    "duration": "indefinitely",
                    "model_conf": 70.95
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.5,
                    "duration": "1 Months",
                    "model_conf": 158.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.87,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.07,
                    "duration": "indefinitely",
                    "model_conf": 101.12
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 154.82
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 281.32
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 193.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.97,
                    "duration": "indefinitely",
                    "model_conf": 166.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.39,
                    "duration": "4 Months",
                    "model_conf": 168.27
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 157.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.87,
                    "duration": "3 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 232.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.53,
                    "duration": "1 Months",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.73,
                    "duration": "1 Months",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.09,
                    "duration": "1 Months",
                    "model_conf": 221.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/12840-walker-branch-dr.html": {
        "name": "RiverGate",
        "address": "12840 Walker Branch Dr Ste 300, Charlotte Charlotte, North Carolina, 28273",
        "phone": "(704) 588-8885",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/12840-walker-branch-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.10040466158682,
            -80.98510250488829
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.43,
                    "duration": "3 Months",
                    "model_conf": 263.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.44,
                    "duration": "1 Months",
                    "model_conf": 184.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.61,
                    "duration": "indefinitely",
                    "model_conf": 154.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.08,
                    "duration": "indefinitely",
                    "model_conf": 153.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.28,
                    "duration": "indefinitely",
                    "model_conf": 151.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.96,
                    "duration": "indefinitely",
                    "model_conf": 141.0
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.67,
                    "duration": "indefinitely",
                    "model_conf": 212.32
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.23,
                    "duration": "indefinitely",
                    "model_conf": 82.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.79,
                    "duration": "indefinitely",
                    "model_conf": 55.01
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.21,
                    "duration": "indefinitely",
                    "model_conf": 210.95
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/14835-ballantyne-village-way.html": {
        "name": "Ballantyne",
        "address": "14835 Ballantyne Village Way Ste 160, Charlotte Charlotte, North Carolina, 28277",
        "phone": "(704) 369-5220",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/14835-ballantyne-village-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.053625502878944,
            -80.85087864125995
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.81,
                    "duration": "indefinitely",
                    "model_conf": 68.37
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.65,
                    "duration": "1 Months",
                    "model_conf": 142.88
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 87.61
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.94,
                    "duration": "indefinitely",
                    "model_conf": 149.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.75,
                    "duration": "indefinitely",
                    "model_conf": 250.09
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.99,
                    "duration": "indefinitely",
                    "model_conf": 175.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.14,
                    "duration": "indefinitely",
                    "model_conf": 150.36
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.0,
                    "duration": "indefinitely",
                    "model_conf": 146.23
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.22,
                    "duration": "indefinitely",
                    "model_conf": 148.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.6,
                    "duration": "indefinitely",
                    "model_conf": 140.75
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 201.18
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 81.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 54.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 217.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/1730-east-woodlawn-road.html": {
        "name": "South Park",
        "address": "1730 East Woodlawn Road, Charlotte Charlotte, North Carolina, 28209",
        "phone": "(704) 525-4800",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/1730-east-woodlawn-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.171881,
            -80.847058
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.86,
                    "duration": "indefinitely",
                    "model_conf": 66.83
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.21,
                    "duration": "2 Months",
                    "model_conf": 128.93
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.69,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.32,
                    "duration": "4 Months",
                    "model_conf": 76.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.03,
                    "duration": "indefinitely",
                    "model_conf": 145.25
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.77,
                    "duration": "5 Months",
                    "model_conf": 228.76
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.42,
                    "duration": "4 Months",
                    "model_conf": 163.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.32,
                    "duration": "indefinitely",
                    "model_conf": 134.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.52,
                    "duration": "8 Months",
                    "model_conf": 127.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.54,
                    "duration": "indefinitely",
                    "model_conf": 143.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.83,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.11,
                    "duration": "5 Months",
                    "model_conf": 173.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.9,
                    "duration": "indefinitely",
                    "model_conf": 78.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.66,
                    "duration": "indefinitely",
                    "model_conf": 53.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.46,
                    "duration": "3 Months",
                    "model_conf": 219.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/2008-e-arbor-drive.html": {
        "name": "University",
        "address": "2008 E Arbor Drive Ste 310, Charlotte, North Carolina, 28262",
        "phone": "(704) 717-3010",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/2008-e-arbor-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.337121,
            -80.755688
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.76,
                    "duration": "indefinitely",
                    "model_conf": 71.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.17,
                    "duration": "5 Months",
                    "model_conf": 139.75
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.56,
                    "duration": "2 Months",
                    "model_conf": 101.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.01,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.39,
                    "duration": "4 Months",
                    "model_conf": 279.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.98,
                    "duration": "indefinitely",
                    "model_conf": 160.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.4,
                    "duration": "3 Months",
                    "model_conf": 165.81
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.12,
                    "duration": "indefinitely",
                    "model_conf": 154.8
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.64,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.26,
                    "duration": "indefinitely",
                    "model_conf": 85.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.95,
                    "duration": "indefinitely",
                    "model_conf": 54.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.89,
                    "duration": "2 Months",
                    "model_conf": 219.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/2907-providence-road.html": {
        "name": "Myers Park",
        "address": "2907 Providence Road, Charlotte Charlotte, North Carolina, 28211",
        "phone": "(704) 970-4444",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/2907-providence-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.171429,
            -80.807463
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 66.89
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 130.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.7,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.68,
                    "duration": "1 Months",
                    "model_conf": 76.87
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.08,
                    "duration": "indefinitely",
                    "model_conf": 145.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.33,
                    "duration": "4 Months",
                    "model_conf": 228.57
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.0,
                    "duration": "2 Months",
                    "model_conf": 164.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.85,
                    "duration": "indefinitely",
                    "model_conf": 137.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.04,
                    "duration": "5 Months",
                    "model_conf": 128.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.18,
                    "duration": "indefinitely",
                    "model_conf": 143.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.61,
                    "duration": "4 Months",
                    "model_conf": 174.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.69,
                    "duration": "indefinitely",
                    "model_conf": 78.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.59,
                    "duration": "indefinitely",
                    "model_conf": 53.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.83,
                    "duration": "2 Months",
                    "model_conf": 218.77
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/8020-providence-road.html": {
        "name": "Arboretum-CLT",
        "address": "8020 Providence Road Suite 100, Charlotte, North Carolina, 28277",
        "phone": "(704) 749-5000",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/8020-providence-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.098232,
            -80.780821
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.27,
                    "duration": "indefinitely",
                    "model_conf": 67.03
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.21,
                    "duration": "indefinitely",
                    "model_conf": 134.85
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.41,
                    "duration": "indefinitely",
                    "model_conf": 80.84
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.04,
                    "duration": "indefinitely",
                    "model_conf": 147.03
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.19,
                    "duration": "indefinitely",
                    "model_conf": 236.15
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.97,
                    "duration": "indefinitely",
                    "model_conf": 167.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.04,
                    "duration": "indefinitely",
                    "model_conf": 142.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.99,
                    "duration": "indefinitely",
                    "model_conf": 135.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.29,
                    "duration": "1 Months",
                    "model_conf": 145.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.57,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.17,
                    "duration": "indefinitely",
                    "model_conf": 182.52
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 79.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.76,
                    "duration": "1 Months",
                    "model_conf": 53.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.97,
                    "duration": "indefinitely",
                    "model_conf": 219.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/9733-northlake-centre-parkway.html": {
        "name": "Northlake",
        "address": "9733 Northlake Centre Parkway, Charlotte Charlotte, North Carolina, 28216",
        "phone": "(704) 596-7800",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/9733-northlake-centre-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.3485104,
            -80.8602049
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.57,
                    "duration": "3 Months",
                    "model_conf": 278.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.43,
                    "duration": "1 Months",
                    "model_conf": 189.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.08,
                    "duration": "indefinitely",
                    "model_conf": 162.47
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.05,
                    "duration": "4 Months",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.01,
                    "duration": "indefinitely",
                    "model_conf": 154.84
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.53,
                    "duration": "1 Months",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.8,
                    "duration": "3 Months",
                    "model_conf": 233.55
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.56,
                    "duration": "indefinitely",
                    "model_conf": 85.5
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.5,
                    "duration": "indefinitely",
                    "model_conf": 54.46
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.35,
                    "duration": "2 Months",
                    "model_conf": 219.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/charlotte/9905-sandy-rock-place.html": {
        "name": "Rea Farms",
        "address": "9905 Sandy Rock Place Suite B and D, Charlotte, North Carolina, 28277",
        "phone": "(704) 541-2600",
        "url": "https://locations.massageenvy.com/north-carolina/charlotte/9905-sandy-rock-place.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.051701,
            -80.773772
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.16,
                    "duration": "indefinitely",
                    "model_conf": 68.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 144.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.04,
                    "duration": "indefinitely",
                    "model_conf": 89.34
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.66,
                    "duration": "indefinitely",
                    "model_conf": 149.52
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.55,
                    "duration": "indefinitely",
                    "model_conf": 253.7
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.53,
                    "duration": "indefinitely",
                    "model_conf": 175.42
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 150.14
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.24,
                    "duration": "indefinitely",
                    "model_conf": 148.58
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 149.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.11,
                    "duration": "indefinitely",
                    "model_conf": 140.72
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.65,
                    "duration": "indefinitely",
                    "model_conf": 202.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 81.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 53.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 192.32,
                    "duration": "indefinitely",
                    "model_conf": 218.66
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/durham/737-ninth-street.html": {
        "name": "Durham Ninth Street",
        "address": "737 Ninth Street Ste 240, Durham Durham, North Carolina, 27705",
        "phone": "(919) 667-0808",
        "url": "https://locations.massageenvy.com/north-carolina/durham/737-ninth-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.009844,
            -78.922917
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 176.0,
                    "duration": "indefinitely",
                    "model_conf": 280.87
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.0,
                    "duration": "indefinitely",
                    "model_conf": 190.69
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.04,
                    "duration": "indefinitely",
                    "model_conf": 164.78
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.04,
                    "duration": "indefinitely",
                    "model_conf": 161.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.24,
                    "duration": "1 Months",
                    "model_conf": 156.69
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.34,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.02,
                    "duration": "indefinitely",
                    "model_conf": 231.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.9,
                    "duration": "indefinitely",
                    "model_conf": 55.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.09,
                    "duration": "indefinitely",
                    "model_conf": 206.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/durham/8202-renaissance-parkway.html": {
        "name": "Southpoint",
        "address": "8202 Renaissance Parkway Unit 111, Durham, North Carolina, 27713",
        "phone": "(919) 493-3689",
        "url": "https://locations.massageenvy.com/north-carolina/durham/8202-renaissance-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.903253,
            -78.953908
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.52,
                    "duration": "1 Months",
                    "model_conf": 277.62
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.77,
                    "duration": "indefinitely",
                    "model_conf": 188.33
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.1,
                    "duration": "indefinitely",
                    "model_conf": 163.22
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.94,
                    "duration": "5 Months",
                    "model_conf": 158.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.29,
                    "duration": "1 Months",
                    "model_conf": 153.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.02,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.32,
                    "duration": "1 Months",
                    "model_conf": 228.74
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.73,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.8,
                    "duration": "1 Months",
                    "model_conf": 55.27
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.34,
                    "duration": "3 Months",
                    "model_conf": 196.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/fayetteville/2043-skibo-road.html": {
        "name": "Fayetteville",
        "address": "2043 Skibo Road Suite 101, Fayetteville Fayetteville, North Carolina, 28314",
        "phone": "(910) 864-1500",
        "url": "https://locations.massageenvy.com/north-carolina/fayetteville/2043-skibo-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.05526941308775,
            -78.971978390213
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.06,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.08,
                    "duration": "indefinitely",
                    "model_conf": 158.95
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.69,
                    "duration": "3 Months",
                    "model_conf": 139.82
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.67,
                    "duration": "indefinitely",
                    "model_conf": 101.11
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.91,
                    "duration": "indefinitely",
                    "model_conf": 154.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.53,
                    "duration": "indefinitely",
                    "model_conf": 275.61
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.47,
                    "duration": "indefinitely",
                    "model_conf": 194.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.27,
                    "duration": "5 Months",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.01,
                    "duration": "indefinitely",
                    "model_conf": 166.93
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.42,
                    "duration": "3 Months",
                    "model_conf": 156.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 139.34
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.84,
                    "duration": "indefinitely",
                    "model_conf": 224.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.09,
                    "duration": "2 Months",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.34,
                    "duration": "3 Months",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.18,
                    "duration": "indefinitely",
                    "model_conf": 221.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/fayetteville/4251-ramsey-st.html": {
        "name": "Fayetteville-Shoppes at Ramsey Street",
        "address": "4251 Ramsey St Suite 102, Fayetteville, North Carolina, 28311",
        "phone": "(910) 557-9500",
        "url": "https://locations.massageenvy.com/north-carolina/fayetteville/4251-ramsey-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.1189324,
            -78.880769
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.76,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.7,
                    "duration": "indefinitely",
                    "model_conf": 158.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.97,
                    "duration": "3 Months",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.4,
                    "duration": "indefinitely",
                    "model_conf": 101.42
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.42,
                    "duration": "indefinitely",
                    "model_conf": 154.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.97,
                    "duration": "indefinitely",
                    "model_conf": 276.38
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 132.97,
                    "duration": "indefinitely",
                    "model_conf": 194.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.11,
                    "duration": "5 Months",
                    "model_conf": 166.99
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.64,
                    "duration": "indefinitely",
                    "model_conf": 166.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.28,
                    "duration": "3 Months",
                    "model_conf": 156.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.82,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.4,
                    "duration": "indefinitely",
                    "model_conf": 227.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.98,
                    "duration": "3 Months",
                    "model_conf": 85.81
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.33,
                    "duration": "4 Months",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 193.48,
                    "duration": "indefinitely",
                    "model_conf": 221.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/1028-oberlin-road.html": {
        "name": "Oberlin",
        "address": "1028 Oberlin Road Ste 222, Raleigh Raleigh, North Carolina, 27605",
        "phone": "(919) 838-5060",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/1028-oberlin-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.7976351590402,
            -78.6596875718605
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.22,
                    "duration": "1 Months",
                    "model_conf": 265.23
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.18,
                    "duration": "2 Months",
                    "model_conf": 183.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.59,
                    "duration": "1 Months",
                    "model_conf": 164.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.84,
                    "duration": "6 Months",
                    "model_conf": 143.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.89,
                    "duration": "1 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.46,
                    "duration": "1 Months",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.29,
                    "duration": "1 Months",
                    "model_conf": 202.22
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.25,
                    "duration": "1 Months",
                    "model_conf": 84.54
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.72,
                    "duration": "1 Months",
                    "model_conf": 52.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.34,
                    "duration": "2 Months",
                    "model_conf": 197.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/141-park-at-north-hills-st.html": {
        "name": "North Hills",
        "address": "141 Park At North Hills St Ste 104, Raleigh, North Carolina, 27609",
        "phone": "(919) 398-6499",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/141-park-at-north-hills-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.836941,
            -78.637973
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.46,
                    "duration": "1 Months",
                    "model_conf": 256.47
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.5,
                    "duration": "1 Months",
                    "model_conf": 181.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.4,
                    "duration": "1 Months",
                    "model_conf": 162.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.48,
                    "duration": "2 Months",
                    "model_conf": 135.25
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.23,
                    "duration": "1 Months",
                    "model_conf": 139.09
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.73,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.93,
                    "duration": "indefinitely",
                    "model_conf": 197.55
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.05,
                    "duration": "indefinitely",
                    "model_conf": 84.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.67,
                    "duration": "1 Months",
                    "model_conf": 52.6
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.3,
                    "duration": "1 Months",
                    "model_conf": 193.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/3201-edwards-mill-road.html": {
        "name": "Crabtree Valley",
        "address": "3201 Edwards Mill Road Ste 143-145, Raleigh, North Carolina, 27612",
        "phone": "(919) 787-1114",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/3201-edwards-mill-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.82608,
            -78.706451
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "6. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.88,
                    "duration": "3 Months",
                    "model_conf": 67.72
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.36,
                    "duration": "1 Months",
                    "model_conf": 141.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.8,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.55,
                    "duration": "3 Months",
                    "model_conf": 84.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.05,
                    "duration": "1 Months",
                    "model_conf": 149.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.88,
                    "duration": "3 Months",
                    "model_conf": 262.83
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.28,
                    "duration": "2 Months",
                    "model_conf": 178.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.16,
                    "duration": "2 Months",
                    "model_conf": 165.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.35,
                    "duration": "4 Months",
                    "model_conf": 137.16
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.22,
                    "duration": "1 Months",
                    "model_conf": 134.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.38,
                    "duration": "indefinitely",
                    "model_conf": 140.8
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.95,
                    "duration": "3 Months",
                    "model_conf": 187.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.55,
                    "duration": "1 Months",
                    "model_conf": 84.22
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.2,
                    "duration": "indefinitely",
                    "model_conf": 51.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.42,
                    "duration": "3 Months",
                    "model_conf": 187.11
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/8201-brier-creek-parkway.html": {
        "name": "Brier Creek",
        "address": "8201 Brier Creek Parkway Ste 105, Raleigh, North Carolina, 27617",
        "phone": "(919) 806-3689",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/8201-brier-creek-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.908812,
            -78.78432
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.69,
                    "duration": "indefinitely",
                    "model_conf": 275.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.91,
                    "duration": "indefinitely",
                    "model_conf": 189.2
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.04,
                    "duration": "indefinitely",
                    "model_conf": 165.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.57,
                    "duration": "2 Months",
                    "model_conf": 156.54
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.68,
                    "duration": "indefinitely",
                    "model_conf": 149.16
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.66,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.76,
                    "duration": "indefinitely",
                    "model_conf": 220.0
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.93,
                    "duration": "indefinitely",
                    "model_conf": 85.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 54.14
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.14,
                    "duration": "1 Months",
                    "model_conf": 207.98
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/8357-creedmoor-road.html": {
        "name": "Creedmoor & Strickland",
        "address": "8357 Creedmoor Road, Raleigh, North Carolina, 27613",
        "phone": "(919) 847-8220",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/8357-creedmoor-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.9012704,
            -78.6825247
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.27,
                    "duration": "1 Months",
                    "model_conf": 267.82
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.6,
                    "duration": "1 Months",
                    "model_conf": 186.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.11,
                    "duration": "indefinitely",
                    "model_conf": 164.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.34,
                    "duration": "3 Months",
                    "model_conf": 151.19
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.08,
                    "duration": "indefinitely",
                    "model_conf": 146.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.7,
                    "duration": "indefinitely",
                    "model_conf": 140.84
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.84,
                    "duration": "indefinitely",
                    "model_conf": 214.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.01,
                    "duration": "indefinitely",
                    "model_conf": 85.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.17,
                    "duration": "indefinitely",
                    "model_conf": 53.64
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 219.2,
                    "duration": "1 Months",
                    "model_conf": 204.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/raleigh/9660-falls-of-neuse-rd.html": {
        "name": "Falls Of The Neuse",
        "address": "9660 Falls of Neuse Rd Suite 156, Raleigh, North Carolina, 27615",
        "phone": "(919) 847-3633",
        "url": "https://locations.massageenvy.com/north-carolina/raleigh/9660-falls-of-neuse-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.905921,
            -78.599977
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.72,
                    "duration": "indefinitely",
                    "model_conf": 264.43
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.14,
                    "duration": "indefinitely",
                    "model_conf": 187.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.94,
                    "duration": "indefinitely",
                    "model_conf": 162.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.47,
                    "duration": "2 Months",
                    "model_conf": 149.84
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.59,
                    "duration": "indefinitely",
                    "model_conf": 148.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.66,
                    "duration": "indefinitely",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 218.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.82,
                    "duration": "indefinitely",
                    "model_conf": 85.01
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.01,
                    "duration": "indefinitely",
                    "model_conf": 54.18
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.91,
                    "duration": "2 Months",
                    "model_conf": 207.06
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/wilmington/5541-carolina-beach-rd.html": {
        "name": "Monkey Junction",
        "address": "5541 Carolina Beach Rd Ste 130, Wilmington Wilmington, North Carolina, 28412",
        "phone": "(910) 794-5252",
        "url": "https://locations.massageenvy.com/north-carolina/wilmington/5541-carolina-beach-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            34.13688762382391,
            -77.89259453178317
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.71,
                    "duration": "indefinitely",
                    "model_conf": 281.78
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.47,
                    "duration": "indefinitely",
                    "model_conf": 193.96
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.44,
                    "duration": "1 Months",
                    "model_conf": 165.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.73,
                    "duration": "indefinitely",
                    "model_conf": 168.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.8,
                    "duration": "indefinitely",
                    "model_conf": 157.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.24,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.79,
                    "duration": "indefinitely",
                    "model_conf": 232.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 55.59
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.03,
                    "duration": "indefinitely",
                    "model_conf": 215.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/wilmington/964-inspiration-drive.html": {
        "name": "Mayfaire Town Center",
        "address": "964 Inspiration Drive, Wilmington, North Carolina, 28405",
        "phone": "(910) 256-1211",
        "url": "https://locations.massageenvy.com/north-carolina/wilmington/964-inspiration-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 7:00 PM"
        },
        "geolocation": [
            34.24072,
            -77.83053
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.65,
                    "duration": "indefinitely",
                    "model_conf": 281.3
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.1,
                    "duration": "indefinitely",
                    "model_conf": 194.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.06,
                    "duration": "2 Months",
                    "model_conf": 165.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.27,
                    "duration": "indefinitely",
                    "model_conf": 168.2
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.31,
                    "duration": "1 Months",
                    "model_conf": 157.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.32,
                    "duration": "indefinitely",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.64,
                    "duration": "indefinitely",
                    "model_conf": 230.17
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.66,
                    "duration": "indefinitely",
                    "model_conf": 85.38
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.74,
                    "duration": "1 Months",
                    "model_conf": 55.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.78,
                    "duration": "indefinitely",
                    "model_conf": 212.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/winston-salem/1271-creekshire-way.html": {
        "name": "Winston-Salem Hanes Mall Blvd",
        "address": "1271 Creekshire Way, Winston-Salem, North Carolina, 27103",
        "phone": "(336) 774-9994",
        "url": "https://locations.massageenvy.com/north-carolina/winston-salem/1271-creekshire-way.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.0663059,
            -80.3253444
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.11,
                    "duration": "indefinitely",
                    "model_conf": 280.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.86,
                    "duration": "indefinitely",
                    "model_conf": 194.25
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 166.75
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.12,
                    "duration": "indefinitely",
                    "model_conf": 167.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.17,
                    "duration": "indefinitely",
                    "model_conf": 156.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.0,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.84,
                    "duration": "indefinitely",
                    "model_conf": 238.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.89,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.6,
                    "duration": "indefinitely",
                    "model_conf": 55.77
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.98,
                    "duration": "indefinitely",
                    "model_conf": 219.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/north-carolina/winston-salem/3350-robinhood-rd.html": {
        "name": "Winston-Salem Robinhood",
        "address": "3350 Robinhood Rd, Winston-Salem Winston-Salem, North Carolina, 27106",
        "phone": "(336) 774-8777",
        "url": "https://locations.massageenvy.com/north-carolina/winston-salem/3350-robinhood-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.1143454,
            -80.313975
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.23,
                    "duration": "indefinitely",
                    "model_conf": 280.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.92,
                    "duration": "indefinitely",
                    "model_conf": 194.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.4,
                    "duration": "indefinitely",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 167.77
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.14,
                    "duration": "indefinitely",
                    "model_conf": 156.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.1,
                    "duration": "indefinitely",
                    "model_conf": 140.69
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.89,
                    "duration": "indefinitely",
                    "model_conf": 238.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.87,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.59,
                    "duration": "indefinitely",
                    "model_conf": 55.79
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.99,
                    "duration": "indefinitely",
                    "model_conf": 219.33
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/beavercreek/15-greene-blvd.html": {
        "name": "The Greene",
        "address": "15 Greene Blvd Ste C, Beavercreek, Ohio, 45440",
        "phone": "(937) 458-0056",
        "url": "https://locations.massageenvy.com/ohio/beavercreek/15-greene-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.695704,
            -84.10266
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 70.79
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 159.1
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.59,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 101.72
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.06,
                    "duration": "indefinitely",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.31,
                    "duration": "1 Months",
                    "model_conf": 281.63
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.49,
                    "duration": "1 Months",
                    "model_conf": 193.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 166.09
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.51,
                    "duration": "2 Months",
                    "model_conf": 167.66
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 157.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.14,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.27,
                    "duration": "2 Months",
                    "model_conf": 231.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.28,
                    "duration": "indefinitely",
                    "model_conf": 55.62
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.25,
                    "duration": "1 Months",
                    "model_conf": 200.61
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/beavercreek/2750-n.-fairfield-rd..html": {
        "name": "Beavercreek",
        "address": "2750 N. Fairfield Rd. Suite B, Beavercreek, Ohio, 45431",
        "phone": "(937) 431-5000",
        "url": "https://locations.massageenvy.com/ohio/beavercreek/2750-n.-fairfield-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.7692054,
            -84.0503103
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.89,
                    "duration": "indefinitely",
                    "model_conf": 70.73
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 158.91
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 101.79
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.95,
                    "duration": "indefinitely",
                    "model_conf": 154.59
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.56,
                    "duration": "1 Months",
                    "model_conf": 274.17
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.42,
                    "duration": "1 Months",
                    "model_conf": 192.22
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 166.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.58,
                    "duration": "2 Months",
                    "model_conf": 164.13
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 157.81
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.57,
                    "duration": "indefinitely",
                    "model_conf": 140.54
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 232.31
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.69,
                    "duration": "indefinitely",
                    "model_conf": 202.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/cincinnati/3650-stone-creek-blvd..html": {
        "name": "Colerain",
        "address": "3650 Stone Creek Blvd. #100, Cincinnati, Ohio, 45251",
        "phone": "(513) 245-1111",
        "url": "https://locations.massageenvy.com/ohio/cincinnati/3650-stone-creek-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.2533649,
            -84.6018587
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.37,
                    "duration": "indefinitely",
                    "model_conf": 71.09
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.38,
                    "duration": "1 Months",
                    "model_conf": 157.88
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.22,
                    "duration": "5 Months",
                    "model_conf": 139.7
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.15,
                    "duration": "indefinitely",
                    "model_conf": 102.09
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.95,
                    "duration": "2 Months",
                    "model_conf": 154.61
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.0,
                    "duration": "indefinitely",
                    "model_conf": 282.28
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.43,
                    "duration": "indefinitely",
                    "model_conf": 193.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.79,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.67,
                    "duration": "indefinitely",
                    "model_conf": 165.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.96,
                    "duration": "2 Months",
                    "model_conf": 156.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.43,
                    "duration": "1 Months",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.1,
                    "duration": "indefinitely",
                    "model_conf": 237.45
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.89,
                    "duration": "3 Months",
                    "model_conf": 84.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.37,
                    "duration": "3 Months",
                    "model_conf": 55.25
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.53,
                    "duration": "indefinitely",
                    "model_conf": 220.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/cincinnati/3774-paxton-avenue.html": {
        "name": "Hyde Park",
        "address": "3774 Paxton Avenue, Cincinnati Cincinnati, Ohio, 45209",
        "phone": "(513) 533-3689",
        "url": "https://locations.massageenvy.com/ohio/cincinnati/3774-paxton-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.1456545,
            -84.429016
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.31,
                    "duration": "1 Months",
                    "model_conf": 71.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.3,
                    "duration": "2 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.36,
                    "duration": "3 Months",
                    "model_conf": 99.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.24,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.57,
                    "duration": "1 Months",
                    "model_conf": 278.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.25,
                    "duration": "2 Months",
                    "model_conf": 165.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 158.78
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.31,
                    "duration": "1 Months",
                    "model_conf": 155.48
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.68,
                    "duration": "3 Months",
                    "model_conf": 85.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 54.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 217.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/cincinnati/4450-eastgate-blvd.html": {
        "name": "Eastgate At Jungle Jim's",
        "address": "4450 Eastgate Blvd Ste 245, Cincinnati Cincinnati, Ohio, 45245",
        "phone": "(513) 753-3689",
        "url": "https://locations.massageenvy.com/ohio/cincinnati/4450-eastgate-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            39.09374352235443,
            -84.27327556828294
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.02,
                    "duration": "indefinitely",
                    "model_conf": 71.01
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.11,
                    "duration": "indefinitely",
                    "model_conf": 156.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.72,
                    "duration": "indefinitely",
                    "model_conf": 139.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 101.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.2,
                    "duration": "indefinitely",
                    "model_conf": 154.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.07,
                    "duration": "2 Months",
                    "model_conf": 281.91
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.11,
                    "duration": "2 Months",
                    "model_conf": 194.43
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.75,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 165.63
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.15,
                    "duration": "indefinitely",
                    "model_conf": 156.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.99,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.37,
                    "duration": "1 Months",
                    "model_conf": 232.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.86,
                    "duration": "indefinitely",
                    "model_conf": 85.75
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.53,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 220.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/ohio/cincinnati/7825-kenwood-road.html": {
        "name": "Kenwood",
        "address": "7825 Kenwood Road, Cincinnati Cincinnati, Ohio, 45236",
        "phone": "(513) 793-3689",
        "url": "https://locations.massageenvy.com/ohio/cincinnati/7825-kenwood-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            39.20295132914649,
            -84.3809255191635
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.34,
                    "duration": "1 Months",
                    "model_conf": 278.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.86,
                    "duration": "indefinitely",
                    "model_conf": 166.12
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.29,
                    "duration": "10 Months",
                    "model_conf": 153.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 155.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/oklahoma/oklahoma-city/10700-s-penn.html": {
        "name": "Chatenay Square",
        "address": "10700 S Penn Suite 23, Oklahoma City Oklahoma City, Oklahoma, 73170",
        "phone": "(405) 237-3345",
        "url": "https://locations.massageenvy.com/oklahoma/oklahoma-city/10700-s-penn.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            35.362217544371426,
            -97.54567959920684
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.99,
                    "duration": "indefinitely",
                    "model_conf": 71.46
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 139.83
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.7,
                    "duration": "indefinitely",
                    "model_conf": 102.43
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.16,
                    "duration": "indefinitely",
                    "model_conf": 154.86
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.0,
                    "duration": "indefinitely",
                    "model_conf": 280.1
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.69,
                    "duration": "4 Months",
                    "model_conf": 165.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 61.15,
                    "duration": "indefinitely",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.98,
                    "duration": "2 Months",
                    "model_conf": 153.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.98,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.45,
                    "duration": "1 Months",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.63,
                    "duration": "2 Months",
                    "model_conf": 55.04
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.86,
                    "duration": "indefinitely",
                    "model_conf": 220.64
                }
            }
        }
    },
    "https://locations.massageenvy.com/oklahoma/oklahoma-city/13801-north-pennsylvania-ave.html": {
        "name": "Quail Springs",
        "address": "13801 North Pennsylvania Ave Suite C, Oklahoma City, Oklahoma, 73134",
        "phone": "(405) 418-4393",
        "url": "https://locations.massageenvy.com/oklahoma/oklahoma-city/13801-north-pennsylvania-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 5:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 5:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            35.6112756,
            -97.5503668
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.96,
                    "duration": "indefinitely",
                    "model_conf": 70.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.75,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.56,
                    "duration": "indefinitely",
                    "model_conf": 102.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.61,
                    "duration": "indefinitely",
                    "model_conf": 149.81
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.46,
                    "duration": "4 Months",
                    "model_conf": 258.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.54,
                    "duration": "1 Months",
                    "model_conf": 163.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.9,
                    "duration": "indefinitely",
                    "model_conf": 168.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.9,
                    "duration": "indefinitely",
                    "model_conf": 148.06
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.35,
                    "duration": "4 Months",
                    "model_conf": 139.3
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.35,
                    "duration": "indefinitely",
                    "model_conf": 85.6
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.02,
                    "duration": "indefinitely",
                    "model_conf": 55.36
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.49,
                    "duration": "indefinitely",
                    "model_conf": 202.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/oklahoma/tulsa/1339-east-41st-street.html": {
        "name": "Brookside",
        "address": "1339 East 41st Street, Tulsa Tulsa, Oklahoma, 74105",
        "phone": "(918) 747-2700",
        "url": "https://locations.massageenvy.com/oklahoma/tulsa/1339-east-41st-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.1047973,
            -95.9738769
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.16,
                    "duration": "indefinitely",
                    "model_conf": 69.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.22,
                    "duration": "2 Months",
                    "model_conf": 139.45
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.83,
                    "duration": "1 Months",
                    "model_conf": 98.39
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.09,
                    "duration": "indefinitely",
                    "model_conf": 153.64
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.85,
                    "duration": "indefinitely",
                    "model_conf": 264.75
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.55,
                    "duration": "indefinitely",
                    "model_conf": 164.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.03,
                    "duration": "indefinitely",
                    "model_conf": 167.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.55,
                    "duration": "indefinitely",
                    "model_conf": 157.54
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.07,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.04,
                    "duration": "indefinitely",
                    "model_conf": 82.96
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.1,
                    "duration": "indefinitely",
                    "model_conf": 53.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.93,
                    "duration": "indefinitely",
                    "model_conf": 221.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/oklahoma/tulsa/8115-south-olympia-avenue-west.html": {
        "name": "Tulsa Hills",
        "address": "8115 South Olympia Avenue West, Tulsa, Oklahoma, 74132",
        "phone": "(918) 794-3588",
        "url": "https://locations.massageenvy.com/oklahoma/tulsa/8115-south-olympia-avenue-west.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "10:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 2:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.045511,
            -96.0036954
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.97,
                    "duration": "indefinitely",
                    "model_conf": 69.29
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.27,
                    "duration": "2 Months",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.87,
                    "duration": "indefinitely",
                    "model_conf": 96.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.27,
                    "duration": "indefinitely",
                    "model_conf": 153.2
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 258.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.73,
                    "duration": "indefinitely",
                    "model_conf": 163.69
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.39,
                    "duration": "indefinitely",
                    "model_conf": 167.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.11,
                    "duration": "indefinitely",
                    "model_conf": 157.71
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.87,
                    "duration": "indefinitely",
                    "model_conf": 140.65
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 81.68
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 52.97
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 220.26
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/portland/1517-ne-broadway.html": {
        "name": "Lloyd Center - TEMPORARILY CLOSED",
        "address": "1517 NE Broadway, Portland, Oregon, 97232",
        "phone": "(503) 972-0900",
        "url": "https://locations.massageenvy.com/oregon/portland/1517-ne-broadway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            45.535256,
            -122.650038
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.17,
                    "duration": "1 Months",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.25,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 73.63,
                    "duration": "7 Months",
                    "model_conf": 88.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 216.95,
                    "duration": "5 Months",
                    "model_conf": 152.57
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 172.54,
                    "duration": "5 Months",
                    "model_conf": 271.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.04,
                    "duration": "indefinitely",
                    "model_conf": 153.85
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 72.14,
                    "duration": "indefinitely",
                    "model_conf": 144.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 221.52,
                    "duration": "indefinitely",
                    "model_conf": 145.02
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.73,
                    "duration": "indefinitely",
                    "model_conf": 140.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.15,
                    "duration": "indefinitely",
                    "model_conf": 80.4
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.45,
                    "duration": "indefinitely",
                    "model_conf": 53.99
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 215.95,
                    "duration": "7 Months",
                    "model_conf": 214.35
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/portland/6645-sw-macadam-avenue.html": {
        "name": "Johns Landing - TEMPORARILY CLOSED",
        "address": "6645 SW Macadam Avenue Suite D, Portland, Oregon, 97239",
        "phone": "(503) 246-2446",
        "url": "https://locations.massageenvy.com/oregon/portland/6645-sw-macadam-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 5:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "12:00 PM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            45.476089,
            -122.672101
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.18,
                    "duration": "1 Months",
                    "model_conf": 70.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 151.07,
                    "duration": "indefinitely",
                    "model_conf": 139.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.78,
                    "duration": "1 Months",
                    "model_conf": 90.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.47,
                    "duration": "2 Months",
                    "model_conf": 151.85
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.6,
                    "duration": "3 Months",
                    "model_conf": 271.35
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.95,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.05,
                    "duration": "4 Months",
                    "model_conf": 144.87
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.3,
                    "duration": "indefinitely",
                    "model_conf": 143.93
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.89,
                    "duration": "2 Months",
                    "model_conf": 140.39
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.78,
                    "duration": "indefinitely",
                    "model_conf": 80.28
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.23,
                    "duration": "indefinitely",
                    "model_conf": 54.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.35,
                    "duration": "2 Months",
                    "model_conf": 216.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/oregon/portland/9732-se-washington-st.html": {
        "name": "Mall 205",
        "address": "9732 SE Washington St Suite B, Portland, Oregon, 97216",
        "phone": "(503) 935-5550",
        "url": "https://locations.massageenvy.com/oregon/portland/9732-se-washington-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            45.516831528706355,
            -122.56353216768574
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.325,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Scare": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": "150",
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": "97",
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": "128",
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": "85",
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": "49",
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 173.31,
                    "duration": "3 Months",
                    "model_conf": 273.98
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.38,
                    "duration": "4 Months",
                    "model_conf": 184.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.52,
                    "duration": "indefinitely",
                    "model_conf": 161.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.36,
                    "duration": "3 Months",
                    "model_conf": 156.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.65,
                    "duration": "indefinitely",
                    "model_conf": 151.56
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.7,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 148.67,
                    "duration": "3 Months",
                    "model_conf": 232.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.79,
                    "duration": "indefinitely",
                    "model_conf": 83.17
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.55,
                    "duration": "indefinitely",
                    "model_conf": 54.7
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 218.92,
                    "duration": "1 Months",
                    "model_conf": 215.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/pittsburgh/1910-settlers-ridge-center-drive.html": {
        "name": "Settlers Ridge",
        "address": "1910 Settlers Ridge Center Drive Ste 1910, Pittsburgh, Pennsylvania, 15205",
        "phone": "(412) 446-2000",
        "url": "https://locations.massageenvy.com/pennsylvania/pittsburgh/1910-settlers-ridge-center-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.4387476,
            -80.1465581
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.15,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.24,
                    "duration": "indefinitely",
                    "model_conf": 139.76
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.45,
                    "duration": "indefinitely",
                    "model_conf": 100.72
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.12,
                    "duration": "indefinitely",
                    "model_conf": 153.66
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.66,
                    "duration": "indefinitely",
                    "model_conf": 280.32
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 165.93
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.59,
                    "duration": "indefinitely",
                    "model_conf": 166.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.97,
                    "duration": "indefinitely",
                    "model_conf": 155.36
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 84.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.89,
                    "duration": "indefinitely",
                    "model_conf": 55.53
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 218.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/pittsburgh/5836-forbes-avenue.html": {
        "name": "Squirrel Hill",
        "address": "5836 Forbes Avenue, Pittsburgh, Pennsylvania, 15217",
        "phone": "(412) 420-5188",
        "url": "https://locations.massageenvy.com/pennsylvania/pittsburgh/5836-forbes-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.437826,
            -79.921443
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.375,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "$12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": "1",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": null
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": null
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": null
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.66,
                    "duration": "indefinitely",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.53,
                    "duration": "indefinitely",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 56.19,
                    "duration": "indefinitely",
                    "model_conf": 100.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 150.93
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.3,
                    "duration": "indefinitely",
                    "model_conf": 278.6
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.14,
                    "duration": "3 Months",
                    "model_conf": 165.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 56.09,
                    "duration": "indefinitely",
                    "model_conf": 165.68
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 152.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.22,
                    "duration": "2 Months",
                    "model_conf": 140.98
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.66,
                    "duration": "1 Months",
                    "model_conf": 82.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.22,
                    "duration": "indefinitely",
                    "model_conf": 55.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.67,
                    "duration": "indefinitely",
                    "model_conf": 217.43
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/pittsburgh/595-clairton-blvd.html": {
        "name": "Pleasant Hills - PA",
        "address": "595 Clairton Blvd, Pittsburgh, Pennsylvania, 15236",
        "phone": "(412) 655-9700",
        "url": "https://locations.massageenvy.com/pennsylvania/pittsburgh/595-clairton-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:30 AM - 9:00 PM",
            "TUESDAY": "8:30 AM - 9:00 PM",
            "WEDNESDAY": "8:30 AM - 9:00 PM",
            "THURSDAY": "8:30 AM - 9:00 PM",
            "FRIDAY": "8:30 AM - 9:00 PM",
            "SATURDAY": "8:30 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.3325271,
            -79.9443027
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.0,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.36,
                    "duration": "indefinitely",
                    "model_conf": 101.04
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.71,
                    "duration": "indefinitely",
                    "model_conf": 152.54
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.7,
                    "duration": "indefinitely",
                    "model_conf": 279.78
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.0,
                    "duration": "2 Months",
                    "model_conf": 166.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.69,
                    "duration": "indefinitely",
                    "model_conf": 166.86
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.52,
                    "duration": "indefinitely",
                    "model_conf": 154.45
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.17,
                    "duration": "2 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 83.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.73,
                    "duration": "1 Months",
                    "model_conf": 55.67
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.1,
                    "duration": "indefinitely",
                    "model_conf": 218.9
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/pittsburgh/680-washington-road.html": {
        "name": "Uptown Mt Lebanon",
        "address": "680 Washington Road, Pittsburgh, Pennsylvania, 15228",
        "phone": "(412) 531-1100",
        "url": "https://locations.massageenvy.com/pennsylvania/pittsburgh/680-washington-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.3804227,
            -80.044773
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.6,
                    "duration": "indefinitely",
                    "model_conf": 71.31
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.02,
                    "duration": "indefinitely",
                    "model_conf": 100.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.38,
                    "duration": "indefinitely",
                    "model_conf": 149.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.73,
                    "duration": "indefinitely",
                    "model_conf": 279.17
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.28,
                    "duration": "indefinitely",
                    "model_conf": 165.5
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.16,
                    "duration": "1 Months",
                    "model_conf": 151.73
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.72,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 82.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.65,
                    "duration": "2 Months",
                    "model_conf": 55.51
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.8,
                    "duration": "indefinitely",
                    "model_conf": 217.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/pennsylvania/pittsburgh/791-providence-blvd.html": {
        "name": "McCandless",
        "address": "791 Providence Blvd, Pittsburgh, Pennsylvania, 15237",
        "phone": "(412) 366-6000",
        "url": "https://locations.massageenvy.com/pennsylvania/pittsburgh/791-providence-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "11:00 AM - 9:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 7:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 5:00 PM",
            "SUNDAY": "10:00 AM - 5:00 PM"
        },
        "geolocation": [
            40.570139,
            -80.020557
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.68,
                    "duration": "indefinitely",
                    "model_conf": 71.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.96,
                    "duration": "indefinitely",
                    "model_conf": 99.19
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.89,
                    "duration": "indefinitely",
                    "model_conf": 152.55
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.45,
                    "duration": "1 Months",
                    "model_conf": 273.11
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.13,
                    "duration": "indefinitely",
                    "model_conf": 162.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.12,
                    "duration": "indefinitely",
                    "model_conf": 162.46
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.94,
                    "duration": "indefinitely",
                    "model_conf": 154.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.54,
                    "duration": "1 Months",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 84.94
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.16,
                    "duration": "indefinitely",
                    "model_conf": 54.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 214.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/columbia/144-harbison-boulevard.html": {
        "name": "Harbison",
        "address": "144 Harbison Boulevard Suite A, Columbia, South Carolina, 29212",
        "phone": "(803) 227-4444",
        "url": "https://locations.massageenvy.com/south-carolina/columbia/144-harbison-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.067839,
            -81.162773
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 71.19
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 155.94
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.63,
                    "duration": "2 Months",
                    "model_conf": 101.38
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.14,
                    "duration": "indefinitely",
                    "model_conf": 154.49
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.11,
                    "duration": "indefinitely",
                    "model_conf": 282.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 190.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.74,
                    "duration": "indefinitely",
                    "model_conf": 166.19
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.81,
                    "duration": "1 Months",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.71,
                    "duration": "indefinitely",
                    "model_conf": 157.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.44,
                    "duration": "indefinitely",
                    "model_conf": 140.91
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 230.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 85.11
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.13,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 218.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/columbia/4710-d-forest-drive.html": {
        "name": "Forest Acres",
        "address": "4710-D Forest Drive Suite D, Columbia Columbia, South Carolina, 29206",
        "phone": "(803) 790-9000",
        "url": "https://locations.massageenvy.com/south-carolina/columbia/4710-d-forest-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.0188331,
            -80.9692535
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.63,
                    "duration": "indefinitely",
                    "model_conf": 70.84
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.4,
                    "duration": "1 Months",
                    "model_conf": 149.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.25,
                    "duration": "4 Months",
                    "model_conf": 101.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.82,
                    "duration": "indefinitely",
                    "model_conf": 154.2
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.67,
                    "duration": "3 Months",
                    "model_conf": 282.8
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.75,
                    "duration": "3 Months",
                    "model_conf": 192.9
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.46,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.18,
                    "duration": "4 Months",
                    "model_conf": 162.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.52,
                    "duration": "indefinitely",
                    "model_conf": 155.65
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.65,
                    "duration": "indefinitely",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.36,
                    "duration": "4 Months",
                    "model_conf": 234.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 85.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.03,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.11,
                    "duration": "1 Months",
                    "model_conf": 213.09
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/columbia/630-3-promenade-place.html": {
        "name": "Sandhill",
        "address": "630-3 Promenade Place Suite 3, Columbia Columbia, South Carolina, 29229",
        "phone": "(803) 788-8811",
        "url": "https://locations.massageenvy.com/south-carolina/columbia/630-3-promenade-place.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            34.130391193037646,
            -80.87793987485139
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.61,
                    "duration": "1 Months",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 155.98
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 139.77
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.11,
                    "duration": "indefinitely",
                    "model_conf": 102.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.87,
                    "duration": "indefinitely",
                    "model_conf": 154.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.42,
                    "duration": "indefinitely",
                    "model_conf": 282.76
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 192.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.74,
                    "duration": "1 Months",
                    "model_conf": 162.49
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.39,
                    "duration": "1 Months",
                    "model_conf": 157.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.54,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 235.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.2,
                    "duration": "indefinitely",
                    "model_conf": 55.8
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.54,
                    "duration": "indefinitely",
                    "model_conf": 215.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/greenville/101-verdae-boulevard.html": {
        "name": "Verdae Village",
        "address": "101 Verdae Boulevard Ste 900, Greenville Greenville, South Carolina, 29607",
        "phone": "(864) 675-1155",
        "url": "https://locations.massageenvy.com/south-carolina/greenville/101-verdae-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "1:00 PM - 8:00 PM"
        },
        "geolocation": [
            34.821701778257356,
            -82.33817240575638
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.9,
                    "duration": "indefinitely",
                    "model_conf": 68.27
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 139.57
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.75,
                    "duration": "1 Months",
                    "model_conf": 78.99
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.57,
                    "duration": "indefinitely",
                    "model_conf": 152.01
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.34,
                    "duration": "1 Months",
                    "model_conf": 264.74
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 153.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.67,
                    "duration": "1 Months",
                    "model_conf": 164.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.14,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.88,
                    "duration": "indefinitely",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 77.31
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.14,
                    "duration": "indefinitely",
                    "model_conf": 49.58
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.41,
                    "duration": "1 Months",
                    "model_conf": 172.56
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/greenville/120-n-laurens-st.html": {
        "name": "Downtown Greenville",
        "address": "120 N Laurens St Suite 100, Greenville, South Carolina, 29601",
        "phone": "(864) 520-1111",
        "url": "https://locations.massageenvy.com/south-carolina/greenville/120-n-laurens-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "1:00 PM - 8:00 PM"
        },
        "geolocation": [
            34.8522436,
            -82.3991483
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.64,
                    "duration": "indefinitely",
                    "model_conf": 69.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.88,
                    "duration": "indefinitely",
                    "model_conf": 139.62
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.78,
                    "duration": "1 Months",
                    "model_conf": 88.01
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.55,
                    "duration": "indefinitely",
                    "model_conf": 152.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.89,
                    "duration": "indefinitely",
                    "model_conf": 271.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.73,
                    "duration": "3 Months",
                    "model_conf": 158.58
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.93,
                    "duration": "indefinitely",
                    "model_conf": 165.24
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.51,
                    "duration": "indefinitely",
                    "model_conf": 154.67
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.98,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.8,
                    "duration": "indefinitely",
                    "model_conf": 80.45
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.78,
                    "duration": "1 Months",
                    "model_conf": 51.89
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.74,
                    "duration": "indefinitely",
                    "model_conf": 187.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/greenville/3714-pelham-road.html": {
        "name": "Pelham Hills",
        "address": "3714 Pelham Road, Greenville, South Carolina, 29615",
        "phone": "(864) 288-1150",
        "url": "https://locations.massageenvy.com/south-carolina/greenville/3714-pelham-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "1:00 PM - 8:00 PM"
        },
        "geolocation": [
            34.858618,
            -82.266904
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.43,
                    "duration": "1 Months",
                    "model_conf": 70.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.87,
                    "duration": "indefinitely",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.55,
                    "duration": "2 Months",
                    "model_conf": 93.6
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.73,
                    "duration": "2 Months",
                    "model_conf": 153.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.06,
                    "duration": "indefinitely",
                    "model_conf": 273.88
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.0,
                    "duration": "indefinitely",
                    "model_conf": 160.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.15,
                    "duration": "4 Months",
                    "model_conf": 166.11
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.82,
                    "duration": "indefinitely",
                    "model_conf": 155.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.04,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.57,
                    "duration": "1 Months",
                    "model_conf": 82.44
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.58,
                    "duration": "2 Months",
                    "model_conf": 53.39
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.94,
                    "duration": "1 Months",
                    "model_conf": 198.5
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/mount-pleasant/1948-long-grove-drive.html": {
        "name": "Mount Pleasant",
        "address": "1948 Long Grove Drive Suite 4, Mount Pleasant, South Carolina, 29464",
        "phone": "(843) 849-7377",
        "url": "https://locations.massageenvy.com/south-carolina/mount-pleasant/1948-long-grove-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.82272932502014,
            -79.81829093170424
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.05,
                    "duration": "indefinitely",
                    "model_conf": 70.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.41,
                    "duration": "indefinitely",
                    "model_conf": 139.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.81,
                    "duration": "1 Months",
                    "model_conf": 101.15
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.14,
                    "duration": "indefinitely",
                    "model_conf": 154.58
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.25,
                    "duration": "indefinitely",
                    "model_conf": 282.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 162.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.81,
                    "duration": "1 Months",
                    "model_conf": 167.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.54,
                    "duration": "indefinitely",
                    "model_conf": 157.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.74,
                    "duration": "indefinitely",
                    "model_conf": 140.77
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.94,
                    "duration": "indefinitely",
                    "model_conf": 85.72
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 55.37
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.16,
                    "duration": "indefinitely",
                    "model_conf": 212.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/mount-pleasant/903-houston-northcutt-rd..html": {
        "name": "Mt Pleasant - Houston Northcutt",
        "address": "903 Houston Northcutt Rd., Mount Pleasant Mount Pleasant, South Carolina, 29464",
        "phone": "(843) 216-3689",
        "url": "https://locations.massageenvy.com/south-carolina/mount-pleasant/903-houston-northcutt-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.80276883598348,
            -79.88793790340418
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.47,
                    "duration": "indefinitely",
                    "model_conf": 70.05
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.69,
                    "duration": "indefinitely",
                    "model_conf": 139.69
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.68,
                    "duration": "indefinitely",
                    "model_conf": 100.15
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.95,
                    "duration": "indefinitely",
                    "model_conf": 153.32
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.45,
                    "duration": "indefinitely",
                    "model_conf": 283.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.26,
                    "duration": "2 Months",
                    "model_conf": 154.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.46,
                    "duration": "indefinitely",
                    "model_conf": 165.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.55,
                    "duration": "indefinitely",
                    "model_conf": 157.46
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.47,
                    "duration": "1 Months",
                    "model_conf": 140.35
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.83,
                    "duration": "indefinitely",
                    "model_conf": 85.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.86,
                    "duration": "indefinitely",
                    "model_conf": 54.65
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.3,
                    "duration": "indefinitely",
                    "model_conf": 200.07
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/myrtle-beach/181-brookton-circle.html": {
        "name": "Myrtle Beach - Surfside",
        "address": "181 Brookton Circle Unit 5, Myrtle Beach, South Carolina, 29588",
        "phone": "(843) 293-3689",
        "url": "https://locations.massageenvy.com/south-carolina/myrtle-beach/181-brookton-circle.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.652189,
            -78.983395
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.72,
                    "duration": "3 Months",
                    "model_conf": 276.18
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.51,
                    "duration": "1 Months",
                    "model_conf": 194.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.5,
                    "duration": "indefinitely",
                    "model_conf": 165.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.87,
                    "duration": "indefinitely",
                    "model_conf": 165.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.17,
                    "duration": "indefinitely",
                    "model_conf": 157.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.6,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.26,
                    "duration": "2 Months",
                    "model_conf": 235.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.67,
                    "duration": "indefinitely",
                    "model_conf": 85.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.42,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.59,
                    "duration": "indefinitely",
                    "model_conf": 220.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/myrtle-beach/980-cipriana-drive.html": {
        "name": "Myrtle Beach Grande Dunes",
        "address": "980 Cipriana Drive Unit A-2, Myrtle Beach, South Carolina, 29572",
        "phone": "(843) 213-4050",
        "url": "https://locations.massageenvy.com/south-carolina/myrtle-beach/980-cipriana-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.7618303,
            -78.8142105
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.32,
                    "duration": "indefinitely",
                    "model_conf": 269.47
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.06,
                    "duration": "indefinitely",
                    "model_conf": 193.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.93,
                    "duration": "indefinitely",
                    "model_conf": 165.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 166.79
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.98,
                    "duration": "indefinitely",
                    "model_conf": 157.58
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.27,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.6,
                    "duration": "indefinitely",
                    "model_conf": 235.81
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.61,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.32,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.39,
                    "duration": "indefinitely",
                    "model_conf": 221.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/summerville/404b-azalea-square-blvd.html": {
        "name": "Summerville",
        "address": "404B Azalea Square Blvd Suite B, Summerville Summerville, South Carolina, 29483",
        "phone": "(843) 871-6300",
        "url": "https://locations.massageenvy.com/south-carolina/summerville/404b-azalea-square-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.03974717071889,
            -80.16085879078997
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.95,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 159.14
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.98,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.62,
                    "duration": "indefinitely",
                    "model_conf": 91.74
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 154.7
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.81,
                    "duration": "indefinitely",
                    "model_conf": 283.16
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.52,
                    "duration": "indefinitely",
                    "model_conf": 194.76
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.15,
                    "duration": "2 Months",
                    "model_conf": 165.2
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 167.32
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.33,
                    "duration": "1 Months",
                    "model_conf": 151.18
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.32,
                    "duration": "1 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 229.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.35,
                    "duration": "1 Months",
                    "model_conf": 85.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.6,
                    "duration": "indefinitely",
                    "model_conf": 221.7
                }
            }
        }
    },
    "https://locations.massageenvy.com/south-carolina/summerville/9500-dorchester-rd.html": {
        "name": "Wescott",
        "address": "9500 Dorchester Rd Ste 314, Summerville, South Carolina, 29485",
        "phone": "(843) 832-2112",
        "url": "https://locations.massageenvy.com/south-carolina/summerville/9500-dorchester-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.935726002661774,
            -80.13994418084627
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.06,
                    "duration": "indefinitely",
                    "model_conf": 71.3
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.69,
                    "duration": "indefinitely",
                    "model_conf": 99.71
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 154.79
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.17,
                    "duration": "indefinitely",
                    "model_conf": 282.61
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.89,
                    "duration": "indefinitely",
                    "model_conf": 166.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.66,
                    "duration": "indefinitely",
                    "model_conf": 168.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.53,
                    "duration": "1 Months",
                    "model_conf": 155.28
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.16,
                    "duration": "2 Months",
                    "model_conf": 141.1
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.52,
                    "duration": "1 Months",
                    "model_conf": 85.69
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.8,
                    "duration": "1 Months",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.54,
                    "duration": "indefinitely",
                    "model_conf": 221.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/brentwood/1731-a-mallory-lane.html": {
        "name": "Cool Springs",
        "address": "1731-A Mallory Lane, Brentwood Brentwood, Tennessee, 37027",
        "phone": "(615) 309-7575",
        "url": "https://locations.massageenvy.com/tennessee/brentwood/1731-a-mallory-lane.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 8:00 PM"
        },
        "geolocation": [
            35.9609718,
            -86.8172149
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.75,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.35,
                    "duration": "2 Months",
                    "model_conf": 71.04
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.21,
                    "duration": "2 Months",
                    "model_conf": 152.86
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.17,
                    "duration": "2 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.45,
                    "duration": "3 Months",
                    "model_conf": 99.68
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.91,
                    "duration": "2 Months",
                    "model_conf": 152.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.48,
                    "duration": "4 Months",
                    "model_conf": 279.58
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.86,
                    "duration": "4 Months",
                    "model_conf": 191.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.04,
                    "duration": "3 Months",
                    "model_conf": 159.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.35,
                    "duration": "4 Months",
                    "model_conf": 163.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.37,
                    "duration": "1 Months",
                    "model_conf": 150.22
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.97,
                    "duration": "3 Months",
                    "model_conf": 141.06
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 108.88,
                    "duration": "3 Months",
                    "model_conf": 238.05
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.34,
                    "duration": "2 Months",
                    "model_conf": 85.67
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.74,
                    "duration": "1 Months",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.87,
                    "duration": "2 Months",
                    "model_conf": 217.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/brentwood/330-franklin-rd.html": {
        "name": "Brentwood North",
        "address": "330 Franklin Rd Ste 120A, Brentwood Brentwood, Tennessee, 37027",
        "phone": "(615) 690-3689",
        "url": "https://locations.massageenvy.com/tennessee/brentwood/330-franklin-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.025721037166235,
            -86.7918356262029
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": null,
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": null,
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/tennessee/chattanooga/2100-hamilton-place-blvd.html": {
        "name": "Hamilton Place",
        "address": "2100 Hamilton Place Blvd Ste 208, Chattanooga, Tennessee, 37421",
        "phone": "(423) 855-8686",
        "url": "https://locations.massageenvy.com/tennessee/chattanooga/2100-hamilton-place-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            35.033896,
            -85.159492
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.97,
                    "duration": "indefinitely",
                    "model_conf": 71.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.77,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.65,
                    "duration": "3 Months",
                    "model_conf": 101.25
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.83,
                    "duration": "indefinitely",
                    "model_conf": 154.76
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.78,
                    "duration": "indefinitely",
                    "model_conf": 282.26
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.02,
                    "duration": "indefinitely",
                    "model_conf": 166.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.83,
                    "duration": "1 Months",
                    "model_conf": 167.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.01,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.17,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.23,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.09,
                    "duration": "indefinitely",
                    "model_conf": 55.88
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.56,
                    "duration": "1 Months",
                    "model_conf": 219.27
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/chattanooga/345-frazier.html": {
        "name": "Frazier",
        "address": "345 Frazier Suite 108, Chattanooga, Tennessee, 37405",
        "phone": "(423) 757-2900",
        "url": "https://locations.massageenvy.com/tennessee/chattanooga/345-frazier.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.062191,
            -85.3048629
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 +12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.49,
                    "duration": "1 Months",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.08,
                    "duration": "indefinitely",
                    "model_conf": 139.81
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.35,
                    "duration": "5 Months",
                    "model_conf": 100.37
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.26,
                    "duration": "1 Months",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.29,
                    "duration": "2 Months",
                    "model_conf": 281.83
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.48,
                    "duration": "2 Months",
                    "model_conf": 167.07
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.78,
                    "duration": "1 Months",
                    "model_conf": 167.74
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.62,
                    "duration": "indefinitely",
                    "model_conf": 157.49
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.79,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 90.06,
                    "duration": "indefinitely",
                    "model_conf": 85.73
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.95,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 168.96,
                    "duration": "2 Months",
                    "model_conf": 218.8
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/clarksville/1767-madison-street.html": {
        "name": "Madison Commons",
        "address": "1767 Madison Street Suite B102, Clarksville, Tennessee, 37043",
        "phone": "(931) 221-0616",
        "url": "https://locations.massageenvy.com/tennessee/clarksville/1767-madison-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:30 AM - 7:00 PM",
            "TUESDAY": "9:30 AM - 7:00 PM",
            "WEDNESDAY": "9:30 AM - 7:00 PM",
            "THURSDAY": "9:30 AM - 7:00 PM",
            "FRIDAY": "9:30 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.515144,
            -87.314716
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 84.775,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "45",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": null,
                        "memberAdditional": "57",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": null,
                        "memberAdditional": "29",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "68",
                        "memberUpgrade": "23",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": null,
                        "memberAdditional": "45",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.96,
                    "duration": "indefinitely",
                    "model_conf": 71.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.59,
                    "duration": "1 Months",
                    "model_conf": 139.61
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.79,
                    "duration": "1 Months",
                    "model_conf": 101.46
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.95,
                    "duration": "indefinitely",
                    "model_conf": 154.6
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.26,
                    "duration": "indefinitely",
                    "model_conf": 282.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.64,
                    "duration": "1 Months",
                    "model_conf": 166.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 45.01,
                    "duration": "indefinitely",
                    "model_conf": 168.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.61,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.76,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 90.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 89.63,
                    "duration": "1 Months",
                    "model_conf": 85.63
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.98,
                    "duration": "indefinitely",
                    "model_conf": 55.75
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.29,
                    "duration": "indefinitely",
                    "model_conf": 219.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/clarksville/1780-tiny-town-rd..html": {
        "name": "Tiny Town",
        "address": "1780 Tiny Town Rd. Suite G, Clarksville Clarksville, Tennessee, 37042",
        "phone": "(931) 552-0383",
        "url": "https://locations.massageenvy.com/tennessee/clarksville/1780-tiny-town-rd..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.6245926,
            -87.3199423
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 71.43
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.66,
                    "duration": "1 Months",
                    "model_conf": 139.65
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.85,
                    "duration": "indefinitely",
                    "model_conf": 101.35
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.1,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.97,
                    "duration": "indefinitely",
                    "model_conf": 282.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.37,
                    "duration": "2 Months",
                    "model_conf": 166.6
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.23,
                    "duration": "indefinitely",
                    "model_conf": 168.27
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.21,
                    "duration": "1 Months",
                    "model_conf": 157.57
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.66,
                    "duration": "indefinitely",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.86,
                    "duration": "indefinitely",
                    "model_conf": 85.57
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.71,
                    "duration": "1 Months",
                    "model_conf": 55.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.54,
                    "duration": "indefinitely",
                    "model_conf": 219.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/knoxville/5508-kingston-pike.html": {
        "name": "Cherokee Plaza",
        "address": "5508 Kingston Pike, Knoxville, Tennessee, 37919",
        "phone": "(865) 330-2322",
        "url": "https://locations.massageenvy.com/tennessee/knoxville/5508-kingston-pike.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.934605,
            -83.993094
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.65,
                    "duration": "1 Months",
                    "model_conf": 66.68
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.38,
                    "duration": "2 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.67,
                    "duration": "2 Months",
                    "model_conf": 86.07
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.5,
                    "duration": "1 Months",
                    "model_conf": 154.99
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.15,
                    "duration": "indefinitely",
                    "model_conf": 276.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.13,
                    "duration": "2 Months",
                    "model_conf": 166.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 153.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.07,
                    "duration": "2 Months",
                    "model_conf": 137.39
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.02,
                    "duration": "indefinitely",
                    "model_conf": 139.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.73,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.84,
                    "duration": "1 Months",
                    "model_conf": 53.08
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.61,
                    "duration": "indefinitely",
                    "model_conf": 175.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/knoxville/9450-s-northshore-dr.html": {
        "name": "Northshore Knoxville",
        "address": "9450 S Northshore Dr Ste 112, Knoxville, Tennessee, 37922",
        "phone": "(865) 531-9966",
        "url": "https://locations.massageenvy.com/tennessee/knoxville/9450-s-northshore-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            35.86151582202013,
            -84.06754352638931
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.69,
                    "duration": "indefinitely",
                    "model_conf": 70.26
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.87,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.89,
                    "duration": "indefinitely",
                    "model_conf": 139.73
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.76,
                    "duration": "1 Months",
                    "model_conf": 99.05
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 155.05
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.11,
                    "duration": "2 Months",
                    "model_conf": 274.26
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.67,
                    "duration": "1 Months",
                    "model_conf": 185.41
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.64,
                    "duration": "1 Months",
                    "model_conf": 166.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.62,
                    "duration": "2 Months",
                    "model_conf": 163.75
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.83,
                    "duration": "indefinitely",
                    "model_conf": 153.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.05,
                    "duration": "2 Months",
                    "model_conf": 140.03
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.11,
                    "duration": "2 Months",
                    "model_conf": 228.93
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 85.84
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.3,
                    "duration": "indefinitely",
                    "model_conf": 55.26
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.18,
                    "duration": "1 Months",
                    "model_conf": 211.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/nashville/2002-richard-jones-rd.html": {
        "name": "Green Hills",
        "address": "2002 Richard Jones Rd Ste A-101, Nashville, Tennessee, 37215",
        "phone": "(615) 964-6500",
        "url": "https://locations.massageenvy.com/tennessee/nashville/2002-richard-jones-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 9:00 PM",
            "SUNDAY": "8:00 AM - 9:00 PM"
        },
        "geolocation": [
            36.104900768485145,
            -86.81124779054491
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.22,
                    "duration": "4 Months",
                    "model_conf": 238.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 149.49
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.69,
                    "duration": "1 Months",
                    "model_conf": 121.57
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.44,
                    "duration": "indefinitely",
                    "model_conf": 118.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/nashville/2304-elliston-place.html": {
        "name": "West End",
        "address": "2304 Elliston Place, Nashville Nashville, Tennessee, 37203",
        "phone": "(615) 316-7700",
        "url": "https://locations.massageenvy.com/tennessee/nashville/2304-elliston-place.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "10:00 AM - 10:00 PM"
        },
        "geolocation": [
            36.149917413743594,
            -86.80601032527284
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.75,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.39,
                    "duration": "1 Months",
                    "model_conf": 69.52
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.69,
                    "duration": "1 Months",
                    "model_conf": 150.89
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.58,
                    "duration": "1 Months",
                    "model_conf": 139.09
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.76,
                    "duration": "8 Months",
                    "model_conf": 97.97
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.58,
                    "duration": "indefinitely",
                    "model_conf": 148.23
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 121.67,
                    "duration": "10 Months",
                    "model_conf": 246.11
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 107.54,
                    "duration": "8 Months",
                    "model_conf": 168.47
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.33,
                    "duration": "2 Months",
                    "model_conf": 152.11
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.39,
                    "duration": "4 Months",
                    "model_conf": 131.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 170.83,
                    "duration": "indefinitely",
                    "model_conf": 126.53
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 123.87,
                    "duration": "3 Months",
                    "model_conf": 140.9
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 107.82,
                    "duration": "7 Months",
                    "model_conf": 237.33
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.77,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.47,
                    "duration": "indefinitely",
                    "model_conf": 55.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.21,
                    "duration": "1 Months",
                    "model_conf": 210.54
                }
            }
        }
    },
    "https://locations.massageenvy.com/tennessee/nashville/73-white-bridge-rd.html": {
        "name": "Belle Meade",
        "address": "73 White Bridge Rd Ste 121, Nashville, Tennessee, 37205",
        "phone": "(615) 354-5500",
        "url": "https://locations.massageenvy.com/tennessee/nashville/73-white-bridge-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            36.129517651958636,
            -86.85646326636673
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.075,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.58,
                    "duration": "1 Months",
                    "model_conf": 69.63
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.17,
                    "duration": "2 Months",
                    "model_conf": 152.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.94,
                    "duration": "indefinitely",
                    "model_conf": 139.2
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 58.89,
                    "duration": "7 Months",
                    "model_conf": 98.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.68,
                    "duration": "2 Months",
                    "model_conf": 149.21
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 133.13,
                    "duration": "5 Months",
                    "model_conf": 248.26
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.88,
                    "duration": "3 Months",
                    "model_conf": 171.39
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.98,
                    "duration": "indefinitely",
                    "model_conf": 154.52
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.15,
                    "duration": "5 Months",
                    "model_conf": 134.7
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.66,
                    "duration": "indefinitely",
                    "model_conf": 129.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.12,
                    "duration": "indefinitely",
                    "model_conf": 140.89
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 118.55,
                    "duration": "4 Months",
                    "model_conf": 237.19
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.4,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.49,
                    "duration": "indefinitely",
                    "model_conf": 55.32
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.7,
                    "duration": "2 Months",
                    "model_conf": 211.94
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/arlington/1805-north-collins-st..html": {
        "name": "North Arlington",
        "address": "1805 North Collins St., Arlington, Texas, 76011",
        "phone": "(817) 402-3232",
        "url": "https://locations.massageenvy.com/texas/arlington/1805-north-collins-st..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.763026,
            -97.096661
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 89.575,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "100",
                        "memberUpgrade": "50",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.28,
                    "duration": "indefinitely",
                    "model_conf": 71.15
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 100.16,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.92,
                    "duration": "indefinitely",
                    "model_conf": 101.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.15,
                    "duration": "indefinitely",
                    "model_conf": 154.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.01,
                    "duration": "2 Months",
                    "model_conf": 282.06
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.56,
                    "duration": "1 Months",
                    "model_conf": 164.68
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 49.74,
                    "duration": "2 Months",
                    "model_conf": 165.33
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.83,
                    "duration": "indefinitely",
                    "model_conf": 157.11
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.23,
                    "duration": "2 Months",
                    "model_conf": 141.08
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 100.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 99.99,
                    "duration": "indefinitely",
                    "model_conf": 85.61
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 50.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 50.04,
                    "duration": "indefinitely",
                    "model_conf": 55.5
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.68,
                    "duration": "indefinitely",
                    "model_conf": 218.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/arlington/3900-arlington-highlands-blvd.html": {
        "name": "Arlington Highlands",
        "address": "3900 Arlington Highlands Blvd Ste 125, Arlington Arlington, Texas, 76018",
        "phone": "(817) 465-3456",
        "url": "https://locations.massageenvy.com/texas/arlington/3900-arlington-highlands-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.6811473337722,
            -97.11006822090155
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.89,
                    "duration": "5 Months",
                    "model_conf": 282.02
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 165.86
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.36,
                    "duration": "3 Months",
                    "model_conf": 166.67
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.28,
                    "duration": "indefinitely",
                    "model_conf": 156.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/10515-n-mopac-expressway.html": {
        "name": "Arbor Walk",
        "address": "10515 N Mopac Expressway Suite 210, Austin Austin, Texas, 78759",
        "phone": "(512) 834-3689",
        "url": "https://locations.massageenvy.com/texas/austin/10515-n-mopac-expressway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 7:30 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.390620144194315,
            -97.73198782076264
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.2,
                    "duration": "2 Months",
                    "model_conf": 273.48
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.84,
                    "duration": "indefinitely",
                    "model_conf": 177.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.33,
                    "duration": "1 Months",
                    "model_conf": 165.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.3,
                    "duration": "indefinitely",
                    "model_conf": 150.09
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.07,
                    "duration": "indefinitely",
                    "model_conf": 144.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.13,
                    "duration": "2 Months",
                    "model_conf": 140.61
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.19,
                    "duration": "indefinitely",
                    "model_conf": 225.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.36,
                    "duration": "indefinitely",
                    "model_conf": 79.49
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.13,
                    "duration": "indefinitely",
                    "model_conf": 53.85
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.01,
                    "duration": "indefinitely",
                    "model_conf": 185.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/14005-north-us-hwy-183.html": {
        "name": "Lakeline",
        "address": "14005 North US Hwy 183 Suite 200, Austin, Texas, 78717",
        "phone": "(512) 335-3689",
        "url": "https://locations.massageenvy.com/texas/austin/14005-north-us-hwy-183.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            30.47614325728647,
            -97.79757589101791
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": null,
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/austin/3201-bee-cave-road.html": {
        "name": "Westlake",
        "address": "3201 Bee Cave Road Suite 156, Austin Austin, Texas, 78746",
        "phone": "(512) 306-0777",
        "url": "https://locations.massageenvy.com/texas/austin/3201-bee-cave-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            30.273372631818916,
            -97.80038172742962
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.34,
                    "duration": "indefinitely",
                    "model_conf": 273.41
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.96,
                    "duration": "indefinitely",
                    "model_conf": 188.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.99,
                    "duration": "3 Months",
                    "model_conf": 165.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.32,
                    "duration": "indefinitely",
                    "model_conf": 161.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.78,
                    "duration": "indefinitely",
                    "model_conf": 152.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/507-pressler-street.html": {
        "name": "Central Austin",
        "address": "507 Pressler Street Suite 800, Austin, Texas, 78703",
        "phone": "(512) 346-3689",
        "url": "https://locations.massageenvy.com/texas/austin/507-pressler-street.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            30.272625,
            -97.760078
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.4,
                    "duration": "1 Months",
                    "model_conf": 140.97
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 84.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.21,
                    "duration": "indefinitely",
                    "model_conf": 55.2
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/5770-north-mopac-expressway.html": {
        "name": "Mopac@2222",
        "address": "5770 North Mopac Expressway Ste 300, Austin, Texas, 78731",
        "phone": "(512) 735-2000",
        "url": "https://locations.massageenvy.com/texas/austin/5770-north-mopac-expressway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 4:00 PM",
            "TUESDAY": "Closed",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.33918617879105,
            -97.75555416441803
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.88,
                    "duration": "indefinitely",
                    "model_conf": 267.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.39,
                    "duration": "indefinitely",
                    "model_conf": 177.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.61,
                    "duration": "1 Months",
                    "model_conf": 164.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.82,
                    "duration": "indefinitely",
                    "model_conf": 153.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.48,
                    "duration": "indefinitely",
                    "model_conf": 145.89
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.82,
                    "duration": "2 Months",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.6,
                    "duration": "indefinitely",
                    "model_conf": 222.14
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.41,
                    "duration": "indefinitely",
                    "model_conf": 80.27
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.05,
                    "duration": "indefinitely",
                    "model_conf": 54.11
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.1,
                    "duration": "indefinitely",
                    "model_conf": 181.84
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/9500-south-ih-35-ste-l500.html": {
        "name": "Southpark Meadows",
        "address": "9500 South IH 35 Ste L500 Suite L500, Austin, Texas, 78748",
        "phone": "(512) 381-9500",
        "url": "https://locations.massageenvy.com/texas/austin/9500-south-ih-35-ste-l500.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.160727473946825,
            -97.7914309806889
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.8,
                    "duration": "indefinitely",
                    "model_conf": 276.09
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.72,
                    "duration": "indefinitely",
                    "model_conf": 192.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.02,
                    "duration": "indefinitely",
                    "model_conf": 165.89
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 161.44
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.61,
                    "duration": "indefinitely",
                    "model_conf": 154.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.73,
                    "duration": "indefinitely",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.16,
                    "duration": "indefinitely",
                    "model_conf": 235.36
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.74,
                    "duration": "indefinitely",
                    "model_conf": 85.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.46,
                    "duration": "indefinitely",
                    "model_conf": 55.43
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.47,
                    "duration": "indefinitely",
                    "model_conf": 216.24
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/austin/9600-escarpment-blvd.html": {
        "name": "Circle C",
        "address": "9600 Escarpment Blvd Suite 860, Austin, Texas, 78749",
        "phone": "(512) 288-3689",
        "url": "https://locations.massageenvy.com/texas/austin/9600-escarpment-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            30.203379,
            -97.879124
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": null,
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/dallas/11930-preston-rd.html": {
        "name": "Dallas - Preston & Forest",
        "address": "11930 Preston Rd #150, Dallas Dallas, Texas, 75230",
        "phone": "(972) 361-0270",
        "url": "https://locations.massageenvy.com/texas/dallas/11930-preston-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.911370195876536,
            -96.80259549933623
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/dallas/3699-mckinney-avenue.html": {
        "name": "Dallas Uptown at West Village",
        "address": "3699 Mckinney Avenue Suite 104, Dallas, Texas, 75204",
        "phone": "(214) 522-2727",
        "url": "https://locations.massageenvy.com/texas/dallas/3699-mckinney-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            32.807937,
            -96.798504
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.33,
                    "duration": "indefinitely",
                    "model_conf": 266.44
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 155.15
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.62,
                    "duration": "indefinitely",
                    "model_conf": 155.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.83,
                    "duration": "indefinitely",
                    "model_conf": 153.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/dallas/5225-belt-line-road.html": {
        "name": "Dallas Prestonwood",
        "address": "5225 Belt Line Road Ste 250, Dallas, Texas, 75254",
        "phone": "(972) 388-5500",
        "url": "https://locations.massageenvy.com/texas/dallas/5225-belt-line-road.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.9548855694422,
            -96.81767523289182
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/dallas/5500-greenville-ave.html": {
        "name": "Dallas Old Town - Greenville",
        "address": "5500 Greenville Ave Suite 201, Dallas, Texas, 75206",
        "phone": "(214) 575-3689",
        "url": "https://locations.massageenvy.com/texas/dallas/5500-greenville-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.8519947,
            -96.7691834
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.66,
                    "duration": "indefinitely",
                    "model_conf": 234.86
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.95,
                    "duration": "indefinitely",
                    "model_conf": 145.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.54,
                    "duration": "4 Months",
                    "model_conf": 130.83
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.08,
                    "duration": "indefinitely",
                    "model_conf": 130.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.54,
                    "duration": "2 Months",
                    "model_conf": 147.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.19,
                    "duration": "indefinitely",
                    "model_conf": 139.67
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.57,
                    "duration": "indefinitely",
                    "model_conf": 165.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.44,
                    "duration": "1 Months",
                    "model_conf": 79.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.59,
                    "duration": "2 Months",
                    "model_conf": 49.63
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.65,
                    "duration": "indefinitely",
                    "model_conf": 164.59
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/dallas/7529-campbell.html": {
        "name": "Dallas - Campbell @ Coit",
        "address": "7529 Campbell Suite 301, Dallas Dallas, Texas, 75248",
        "phone": "(972) 818-3689",
        "url": "https://locations.massageenvy.com/texas/dallas/7529-campbell.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "10:00 AM - 7:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.979797618476944,
            -96.76976714876002
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.23,
                    "duration": "indefinitely",
                    "model_conf": 239.95
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.98,
                    "duration": "indefinitely",
                    "model_conf": 161.72
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.35,
                    "duration": "1 Months",
                    "model_conf": 143.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.97,
                    "duration": "indefinitely",
                    "model_conf": 133.45
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.24,
                    "duration": "1 Months",
                    "model_conf": 136.29
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.38,
                    "duration": "indefinitely",
                    "model_conf": 140.36
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.46,
                    "duration": "indefinitely",
                    "model_conf": 183.41
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.87,
                    "duration": "indefinitely",
                    "model_conf": 80.04
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 50.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.28,
                    "duration": "indefinitely",
                    "model_conf": 177.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/dallas/9440-garland-road.html": {
        "name": "Dallas Casa Linda",
        "address": "9440 Garland Road Suite 174, Dallas, Texas, 75218",
        "phone": "(972) 993-3333",
        "url": "https://locations.massageenvy.com/texas/dallas/9440-garland-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            32.83205714444782,
            -96.70178514232788
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/el-paso/1886-joe-battle-blvd..html": {
        "name": "El Paso East",
        "address": "1886 Joe Battle Blvd., El Paso El Paso, Texas, 79936",
        "phone": "(915) 855-3689",
        "url": "https://locations.massageenvy.com/texas/el-paso/1886-joe-battle-blvd..html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.759623980323102,
            -106.26505024017769
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.35,
                    "duration": "indefinitely",
                    "model_conf": 281.69
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.95,
                    "duration": "indefinitely",
                    "model_conf": 195.13
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 165.16
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.54,
                    "duration": "indefinitely",
                    "model_conf": 167.76
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.46,
                    "duration": "indefinitely",
                    "model_conf": 155.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.41,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 237.46
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 85.59
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.9,
                    "duration": "indefinitely",
                    "model_conf": 55.92
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.11,
                    "duration": "indefinitely",
                    "model_conf": 220.02
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/el-paso/7500-north-mesa.html": {
        "name": "El Paso West",
        "address": "7500 North Mesa Suite 207, El Paso El Paso, Texas, 79912",
        "phone": "(915) 585-3689",
        "url": "https://locations.massageenvy.com/texas/el-paso/7500-north-mesa.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "10:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "10:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.838875,
            -106.563613
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.95,
                    "duration": "indefinitely",
                    "model_conf": 280.15
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.47,
                    "duration": "indefinitely",
                    "model_conf": 194.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.39,
                    "duration": "indefinitely",
                    "model_conf": 164.87
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.56,
                    "duration": "indefinitely",
                    "model_conf": 167.98
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.01,
                    "duration": "indefinitely",
                    "model_conf": 155.05
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.26,
                    "duration": "indefinitely",
                    "model_conf": 141.19
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 237.34
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.92,
                    "duration": "indefinitely",
                    "model_conf": 85.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.56,
                    "duration": "indefinitely",
                    "model_conf": 55.9
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.83,
                    "duration": "indefinitely",
                    "model_conf": 219.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/el-paso/8889-gateway-blvd-west.html": {
        "name": "El Paso At The Fountains",
        "address": "8889 Gateway Blvd West Ste 500, El Paso, Texas, 79925",
        "phone": "(915) 590-3689",
        "url": "https://locations.massageenvy.com/texas/el-paso/8889-gateway-blvd-west.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            31.771412933191453,
            -106.37224944578355
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 93.625,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "120",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": "82",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": "41",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": "105",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": "70",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.29,
                    "duration": "indefinitely",
                    "model_conf": 282.34
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 195.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 165.08
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.63,
                    "duration": "indefinitely",
                    "model_conf": 167.69
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.63,
                    "duration": "indefinitely",
                    "model_conf": 155.14
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.39,
                    "duration": "indefinitely",
                    "model_conf": 141.15
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 237.43
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.04,
                    "duration": "indefinitely",
                    "model_conf": 85.46
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.93,
                    "duration": "indefinitely",
                    "model_conf": 55.93
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 181.23,
                    "duration": "indefinitely",
                    "model_conf": 219.78
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/fort-worth/2600-w-7th-street.html": {
        "name": "Montgomery Plaza",
        "address": "2600 W 7th Street, Fort Worth Fort Worth, Texas, 76107",
        "phone": "(817) 334-0070",
        "url": "https://locations.massageenvy.com/texas/fort-worth/2600-w-7th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.7542407,
            -97.3528979
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.89,
                    "duration": "indefinitely",
                    "model_conf": 269.28
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.13,
                    "duration": "2 Months",
                    "model_conf": 157.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.56,
                    "duration": "indefinitely",
                    "model_conf": 155.22
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.84,
                    "duration": "2 Months",
                    "model_conf": 156.22
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/fort-worth/3000-s-hulen.html": {
        "name": "Fort Worth Hulen",
        "address": "3000 S Hulen Suite 153, Fort Worth, Texas, 76109",
        "phone": "(817) 732-7200",
        "url": "https://locations.massageenvy.com/texas/fort-worth/3000-s-hulen.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            32.707404,
            -97.389499
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 147.67,
                    "duration": "indefinitely",
                    "model_conf": 238.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.97,
                    "duration": "3 Months",
                    "model_conf": 133.78
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.1,
                    "duration": "indefinitely",
                    "model_conf": 121.96
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.0,
                    "duration": "2 Months",
                    "model_conf": 154.3
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/fort-worth/3100-texas-sage-trail.html": {
        "name": "Alliance Town Center",
        "address": "3100 Texas Sage Trail, Fort Worth, Texas, 76177",
        "phone": "(817) 337-7300",
        "url": "https://locations.massageenvy.com/texas/fort-worth/3100-texas-sage-trail.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 7:00 PM"
        },
        "geolocation": [
            32.9119615,
            -97.3116017
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.36,
                    "duration": "1 Months",
                    "model_conf": 280.01
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 164.8
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.34,
                    "duration": "3 Months",
                    "model_conf": 166.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 156.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/fort-worth/5636-n-tarrant.html": {
        "name": "Parkwood",
        "address": "5636 N Tarrant Pkwy #116, Fort Worth, Texas, 76244",
        "phone": "(817) 281-7200",
        "url": "https://locations.massageenvy.com/texas/fort-worth/5636-n-tarrant.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 7:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            32.89417351797346,
            -97.26425349712372
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.3,
                    "duration": "1 Months",
                    "model_conf": 280.14
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.12,
                    "duration": "indefinitely",
                    "model_conf": 164.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.49,
                    "duration": "3 Months",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.11,
                    "duration": "indefinitely",
                    "model_conf": 156.37
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/frisco/3685-preston-rd.html": {
        "name": "Stonebriar",
        "address": "3685 Preston Rd Suite 159, Frisco, Texas, 75034",
        "phone": "(972) 370-3689",
        "url": "https://locations.massageenvy.com/texas/frisco/3685-preston-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.110064,
            -96.807511
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/frisco/5105-eldorado-parkway.html": {
        "name": "Frisco North",
        "address": "5105 Eldorado Parkway Ste 100, Frisco Frisco, Texas, 75033",
        "phone": "(214) 619-4801",
        "url": "https://locations.massageenvy.com/texas/frisco/5105-eldorado-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.174505760801885,
            -96.84208188415073
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.96,
                    "duration": "indefinitely",
                    "model_conf": 269.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 160.27
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.75,
                    "duration": "indefinitely",
                    "model_conf": 160.01
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.01,
                    "duration": "indefinitely",
                    "model_conf": 153.04
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/1005-waugh-dr.html": {
        "name": "River Oaks",
        "address": "1005 Waugh Dr Ste. G, Houston, Texas, 77019",
        "phone": "(713) 526-3689",
        "url": "https://locations.massageenvy.com/texas/houston/1005-waugh-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.7564168718819,
            -95.39769275337295
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/houston/1210-w-43rd.html": {
        "name": "43rd And Ella",
        "address": "1210 W 43rd Ste 200, Houston, Texas, 77018",
        "phone": "(713) 686-3689",
        "url": "https://locations.massageenvy.com/texas/houston/1210-w-43rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.828846293262455,
            -95.43037892283358
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.77,
                    "duration": "indefinitely",
                    "model_conf": 268.71
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.34,
                    "duration": "1 Months",
                    "model_conf": 157.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.63,
                    "duration": "indefinitely",
                    "model_conf": 156.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.64,
                    "duration": "2 Months",
                    "model_conf": 150.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/14008-c-memorial-dr..html": {
        "name": "Memorial West",
        "address": "14008 C Memorial Dr., Houston Houston, Texas, 77079",
        "phone": "(281) 558-4900",
        "url": "https://locations.massageenvy.com/texas/houston/14008-c-memorial-dr..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.771575067656965,
            -95.59045939745295
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/houston/190-meyerland-plaza-mall.html": {
        "name": "Meyerland",
        "address": "190 Meyerland Plaza Mall, Houston, Texas, 77096",
        "phone": "(281) 220-6000",
        "url": "https://locations.massageenvy.com/texas/houston/190-meyerland-plaza-mall.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.68568,
            -95.463996
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.09,
                    "duration": "indefinitely",
                    "model_conf": 69.66
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.05,
                    "duration": "indefinitely",
                    "model_conf": 139.59
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 88.89
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.26,
                    "duration": "1 Months",
                    "model_conf": 148.65
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.14,
                    "duration": "2 Months",
                    "model_conf": 256.58
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.72,
                    "duration": "indefinitely",
                    "model_conf": 148.42
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.31,
                    "duration": "4 Months",
                    "model_conf": 144.73
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 143.59
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.24,
                    "duration": "indefinitely",
                    "model_conf": 140.76
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.18,
                    "duration": "indefinitely",
                    "model_conf": 82.19
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.12,
                    "duration": "indefinitely",
                    "model_conf": 53.03
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.16,
                    "duration": "1 Months",
                    "model_conf": 191.32
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/19720-katy-freeway.html": {
        "name": "Katy",
        "address": "19720 Katy Freeway, Houston Houston, Texas, 77094",
        "phone": "(281) 579-9223",
        "url": "https://locations.massageenvy.com/texas/houston/19720-katy-freeway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            29.786146308021767,
            -95.71441411972046
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.48,
                    "duration": "4 Months",
                    "model_conf": 249.92
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.21,
                    "duration": "indefinitely",
                    "model_conf": 148.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 53.93,
                    "duration": "7 Months",
                    "model_conf": 144.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.35,
                    "duration": "indefinitely",
                    "model_conf": 141.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 140.71
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.35,
                    "duration": "indefinitely",
                    "model_conf": 81.95
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 55.28,
                    "duration": "indefinitely",
                    "model_conf": 54.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.51,
                    "duration": "3 Months",
                    "model_conf": 185.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/5162-buffalo-speedway.html": {
        "name": "West University",
        "address": "5162 Buffalo Speedway, Houston Houston, Texas, 77005",
        "phone": "(713) 664-3689",
        "url": "https://locations.massageenvy.com/texas/houston/5162-buffalo-speedway.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.72679731691837,
            -95.42973822453689
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/houston/5885-san-felipe-st.html": {
        "name": "Tanglewood",
        "address": "5885 San Felipe St Suite 475, Houston Houston, Texas, 77057",
        "phone": "(713) 627-3689",
        "url": "https://locations.massageenvy.com/texas/houston/5885-san-felipe-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.74908975638186,
            -95.48424783699816
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.02,
                    "duration": "indefinitely",
                    "model_conf": 219.13
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.03,
                    "duration": "2 Months",
                    "model_conf": 120.48
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.06,
                    "duration": "indefinitely",
                    "model_conf": 104.85
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.81,
                    "duration": "2 Months",
                    "model_conf": 124.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/6915-fm-1960-west.html": {
        "name": "Champions",
        "address": "6915 FM 1960 West Suite H1, Houston Houston, Texas, 77069",
        "phone": "(281) 469-3689",
        "url": "https://locations.massageenvy.com/texas/houston/6915-fm-1960-west.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.9696447,
            -95.5302542
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.81,
                    "duration": "indefinitely",
                    "model_conf": 280.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.01,
                    "duration": "2 Months",
                    "model_conf": 165.24
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.15,
                    "duration": "indefinitely",
                    "model_conf": 166.39
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.98,
                    "duration": "indefinitely",
                    "model_conf": 157.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/7039-highway-6-north.html": {
        "name": "Copperfield",
        "address": "7039 Highway 6 North, Houston Houston, Texas, 77095",
        "phone": "(832) 593-6800",
        "url": "https://locations.massageenvy.com/texas/houston/7039-highway-6-north.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 7:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 7:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            29.8797824,
            -95.6468629
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.0,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "-",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.34,
                    "duration": "indefinitely",
                    "model_conf": 274.85
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.07,
                    "duration": "indefinitely",
                    "model_conf": 161.39
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.91,
                    "duration": "indefinitely",
                    "model_conf": 163.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.32,
                    "duration": "1 Months",
                    "model_conf": 153.78
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.19,
                    "duration": "indefinitely",
                    "model_conf": 140.95
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.55,
                    "duration": "1 Months",
                    "model_conf": 84.91
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.64,
                    "duration": "2 Months",
                    "model_conf": 55.02
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.75,
                    "duration": "indefinitely",
                    "model_conf": 212.82
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/houston/9758-katy-freeway.html": {
        "name": "Bunker Hill",
        "address": "9758 Katy Freeway Ste 700, Houston Houston, Texas, 77055",
        "phone": "(713) 457-3689",
        "url": "https://locations.massageenvy.com/texas/houston/9758-katy-freeway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.7856232,
            -95.5333836
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.225,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": null,
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 136.69,
                    "duration": "indefinitely",
                    "model_conf": 237.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.16,
                    "duration": "2 Months",
                    "model_conf": 136.54
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.12,
                    "duration": "indefinitely",
                    "model_conf": 130.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 178.48,
                    "duration": "3 Months",
                    "model_conf": 131.5
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.5,
                    "duration": "indefinitely",
                    "model_conf": 140.83
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.61,
                    "duration": "1 Months",
                    "model_conf": 79.16
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.77,
                    "duration": "1 Months",
                    "model_conf": 50.84
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.61,
                    "duration": "indefinitely",
                    "model_conf": 172.4
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/katy/1708-spring-green-boulevard.html": {
        "name": "West Katy-Firethorne",
        "address": "1708 Spring Green Boulevard #140, Katy Katy, Texas, 77494",
        "phone": "(832) 437-1074",
        "url": "https://locations.massageenvy.com/texas/katy/1708-spring-green-boulevard.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.76382956010553,
            -95.84362982770818
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.75,
                    "duration": "6 Months",
                    "model_conf": 253.66
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 154.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.36,
                    "duration": "9 Months",
                    "model_conf": 147.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.43,
                    "duration": "indefinitely",
                    "model_conf": 144.44
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/katy/23730-westheimer-parkway.html": {
        "name": "Cinco Ranch",
        "address": "23730 Westheimer Parkway Suite L, Katy Katy, Texas, 77494",
        "phone": "(281) 347-3689",
        "url": "https://locations.massageenvy.com/texas/katy/23730-westheimer-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.736432,
            -95.777064
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.02,
                    "duration": "2 Months",
                    "model_conf": 221.45
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.27,
                    "duration": "2 Months",
                    "model_conf": 140.65
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 63.78,
                    "duration": "7 Months",
                    "model_conf": 124.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.27,
                    "duration": "1 Months",
                    "model_conf": 130.99
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/katy/9550-spring-green-blvd.html": {
        "name": "FM 1093 Spring Green",
        "address": "9550 Spring Green Blvd Suite 420, Katy Katy, Texas, 77494",
        "phone": "(281) 392-3689",
        "url": "https://locations.massageenvy.com/texas/katy/9550-spring-green-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.700557976368966,
            -95.81297990066372
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.93,
                    "duration": "11 Months",
                    "model_conf": 228.29
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 142.17
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 62.56,
                    "duration": "indefinitely",
                    "model_conf": 129.05
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.03,
                    "duration": "indefinitely",
                    "model_conf": 132.75
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/mckinney/2890-craig-dr.html": {
        "name": "Eldorado Parkway",
        "address": "2890 Craig Dr Suite 120, McKinney McKinney, Texas, 75072",
        "phone": "(214) 544-3689",
        "url": "https://locations.massageenvy.com/texas/mckinney/2890-craig-dr.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.174639,
            -96.64357
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/mckinney/8950-state-highway-121.html": {
        "name": "Craig Ranch",
        "address": "8950 State Highway 121 Suite 340, McKinney McKinney, Texas, 75070",
        "phone": "(214) 644-0967",
        "url": "https://locations.massageenvy.com/texas/mckinney/8950-state-highway-121.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            33.126983,
            -96.732014
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 0,
        "services": {
            "facials": {
                "1. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "62",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": "25",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "31",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "50",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {}
    },
    "https://locations.massageenvy.com/texas/pearland/2682-pearland-parkway.html": {
        "name": "East Pearland",
        "address": "2682 Pearland Parkway Suite 100, Pearland, Texas, 77581",
        "phone": "(281) 997-3689",
        "url": "https://locations.massageenvy.com/texas/pearland/2682-pearland-parkway.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.5581066,
            -95.262145
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.79,
                    "duration": "3 Months",
                    "model_conf": 275.91
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 162.05
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.41,
                    "duration": "3 Months",
                    "model_conf": 166.15
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.83,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/pearland/2754-smith-ranch-road.html": {
        "name": "Pearland",
        "address": "2754 Smith Ranch Road Suite 106, Pearland, Texas, 77584",
        "phone": "(713) 436-8860",
        "url": "https://locations.massageenvy.com/texas/pearland/2754-smith-ranch-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.55767459885258,
            -95.38484139876606
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.57,
                    "duration": "indefinitely",
                    "model_conf": 280.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.61,
                    "duration": "1 Months",
                    "model_conf": 164.64
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 166.42
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.14,
                    "duration": "1 Months",
                    "model_conf": 156.38
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/plano/1900-preston-road.html": {
        "name": "Preston Park Village",
        "address": "1900 Preston Road Ste 201, Plano Plano, Texas, 75093",
        "phone": "(972) 964-0600",
        "url": "https://locations.massageenvy.com/texas/plano/1900-preston-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            33.025327000000004,
            -96.79334858895493
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 157.13,
                    "duration": "indefinitely",
                    "model_conf": 212.31
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.19,
                    "duration": "indefinitely",
                    "model_conf": 133.03
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.44,
                    "duration": "4 Months",
                    "model_conf": 128.0
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.32,
                    "duration": "indefinitely",
                    "model_conf": 113.59
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.66,
                    "duration": "2 Months",
                    "model_conf": 124.7
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.53,
                    "duration": "indefinitely",
                    "model_conf": 140.32
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.01,
                    "duration": "indefinitely",
                    "model_conf": 139.86
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.34,
                    "duration": "1 Months",
                    "model_conf": 76.2
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.51,
                    "duration": "2 Months",
                    "model_conf": 47.47
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.9,
                    "duration": "indefinitely",
                    "model_conf": 146.76
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/plano/6101-avenue-k.html": {
        "name": "Spring Creek @ Hwy 75",
        "address": "6101 Avenue K Ste 160, Plano, Texas, 75074",
        "phone": "(972) 516-8888",
        "url": "https://locations.massageenvy.com/texas/plano/6101-avenue-k.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            33.058146,
            -96.691497
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 152.45,
                    "duration": "6 Months",
                    "model_conf": 260.67
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.59,
                    "duration": "indefinitely",
                    "model_conf": 153.41
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 68.62,
                    "duration": "7 Months",
                    "model_conf": 150.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.73,
                    "duration": "indefinitely",
                    "model_conf": 147.47
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.47,
                    "duration": "indefinitely",
                    "model_conf": 140.42
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.54,
                    "duration": "indefinitely",
                    "model_conf": 82.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.44,
                    "duration": "indefinitely",
                    "model_conf": 53.09
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.53,
                    "duration": "2 Months",
                    "model_conf": 193.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/round-rock/201-university-oaks-blvd.html": {
        "name": "North Round Rock",
        "address": "201 University Oaks Blvd Ste 1240, Round Rock Round Rock, Texas, 78665",
        "phone": "(512) 735-2900",
        "url": "https://locations.massageenvy.com/texas/round-rock/201-university-oaks-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            30.5580277,
            -97.6876328
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.38,
                    "duration": "indefinitely",
                    "model_conf": 271.85
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.65,
                    "duration": "indefinitely",
                    "model_conf": 188.49
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 164.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.29,
                    "duration": "indefinitely",
                    "model_conf": 162.51
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.64,
                    "duration": "indefinitely",
                    "model_conf": 152.26
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.95,
                    "duration": "indefinitely",
                    "model_conf": 140.63
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.56,
                    "duration": "indefinitely",
                    "model_conf": 230.75
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.38,
                    "duration": "indefinitely",
                    "model_conf": 83.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.03,
                    "duration": "indefinitely",
                    "model_conf": 55.13
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.99,
                    "duration": "indefinitely",
                    "model_conf": 205.17
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/round-rock/3107-s-ih-35.html": {
        "name": "South Round Rock (TEMPORARILY CLOSED)",
        "address": "3107 S IH-35 Suite 790, Round Rock Round Rock, Texas, 78664",
        "phone": "(512) 834-3689",
        "url": "https://locations.massageenvy.com/texas/round-rock/3107-s-ih-35.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "Closed",
            "WEDNESDAY": "Closed",
            "THURSDAY": "Closed",
            "FRIDAY": "Closed",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            30.47762588507332,
            -97.67162486789954
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Book now!"
            },
            {
                "title": "Extra add-ons for when you're feeling a little extra",
                "description": ""
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.83,
                    "duration": "indefinitely",
                    "model_conf": 273.01
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.89,
                    "duration": "indefinitely",
                    "model_conf": 179.79
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.26,
                    "duration": "2 Months",
                    "model_conf": 164.84
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.85,
                    "duration": "indefinitely",
                    "model_conf": 154.41
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.48,
                    "duration": "1 Months",
                    "model_conf": 147.42
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.92,
                    "duration": "indefinitely",
                    "model_conf": 140.16
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.4,
                    "duration": "indefinitely",
                    "model_conf": 225.23
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.14,
                    "duration": "indefinitely",
                    "model_conf": 81.34
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.88,
                    "duration": "indefinitely",
                    "model_conf": 54.31
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.23,
                    "duration": "indefinitely",
                    "model_conf": 186.51
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/san-antonio/11398-bandera-road.html": {
        "name": "Bandera Oaks",
        "address": "11398 Bandera Road Suite 301, San Antonio, Texas, 78250",
        "phone": "(210) 682-3689",
        "url": "https://locations.massageenvy.com/texas/san-antonio/11398-bandera-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.550397107087303,
            -98.66165222104976
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.84,
                    "duration": "indefinitely",
                    "model_conf": 250.96
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 131.32,
                    "duration": "indefinitely",
                    "model_conf": 174.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.44,
                    "duration": "1 Months",
                    "model_conf": 149.81
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.03,
                    "duration": "indefinitely",
                    "model_conf": 157.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.67,
                    "duration": "indefinitely",
                    "model_conf": 151.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/san-antonio/19178-blanco-road.html": {
        "name": "Blanco Pointe",
        "address": "19178 Blanco Road #101, San Antonio San Antonio, Texas, 78258",
        "phone": "(210) 490-3689",
        "url": "https://locations.massageenvy.com/texas/san-antonio/19178-blanco-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.62070623719832,
            -98.51143234465502
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 100.225,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "95",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.89,
                    "duration": "indefinitely",
                    "model_conf": 140.85
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.88,
                    "duration": "indefinitely",
                    "model_conf": 209.79
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.0,
                    "duration": "indefinitely",
                    "model_conf": 82.48
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.9,
                    "duration": "indefinitely",
                    "model_conf": 53.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.98,
                    "duration": "indefinitely",
                    "model_conf": 208.87
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/san-antonio/5511-w-loop-1604-n.html": {
        "name": "Alamo Ranch",
        "address": "5511 W Loop 1604 N Ste 106, San Antonio, Texas, 78253",
        "phone": "(210) 558-8800",
        "url": "https://locations.massageenvy.com/texas/san-antonio/5511-w-loop-1604-n.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 6:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.490688989841104,
            -98.70915967116393
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 69.52
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.95,
                    "duration": "indefinitely",
                    "model_conf": 151.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 139.58
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.86,
                    "duration": "indefinitely",
                    "model_conf": 101.48
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.74,
                    "duration": "indefinitely",
                    "model_conf": 150.62
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.59,
                    "duration": "1 Months",
                    "model_conf": 245.29
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 175.82
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.78,
                    "duration": "indefinitely",
                    "model_conf": 147.31
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.84,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.57,
                    "duration": "indefinitely",
                    "model_conf": 148.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.5,
                    "duration": "indefinitely",
                    "model_conf": 140.5
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 195.6
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.33,
                    "duration": "indefinitely",
                    "model_conf": 81.62
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 53.55
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 207.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/san-antonio/8211-highway-151.html": {
        "name": "Westover Marketplace",
        "address": "8211 Highway 151 Ste 105, San Antonio San Antonio, Texas, 78245",
        "phone": "(210) 933-3689",
        "url": "https://locations.massageenvy.com/texas/san-antonio/8211-highway-151.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "10:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            29.43828666410286,
            -98.64758039155078
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.025,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.1,
                    "duration": "2 Months",
                    "model_conf": 269.94
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.25,
                    "duration": "indefinitely",
                    "model_conf": 187.24
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 160.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.54,
                    "duration": "indefinitely",
                    "model_conf": 163.04
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.98,
                    "duration": "indefinitely",
                    "model_conf": 155.39
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/southlake/100-north-kimball.html": {
        "name": "Southlake East",
        "address": "100 North Kimball #108, Southlake Southlake, Texas, 76092",
        "phone": "(817) 421-6727",
        "url": "https://locations.massageenvy.com/texas/southlake/100-north-kimball.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.941332146691316,
            -97.11772862278127
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.66,
                    "duration": "indefinitely",
                    "model_conf": 277.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.05,
                    "duration": "2 Months",
                    "model_conf": 163.77
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.32,
                    "duration": "indefinitely",
                    "model_conf": 165.1
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.03,
                    "duration": "1 Months",
                    "model_conf": 152.21
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/southlake/250-randol-mill-avenue.html": {
        "name": "Southlake West",
        "address": "250 Randol Mill Avenue Ste 300, Southlake, Texas, 76092",
        "phone": "(817) 562-4433",
        "url": "https://locations.massageenvy.com/texas/southlake/250-randol-mill-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            32.9388,
            -97.186687
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.31,
                    "duration": "1 Months",
                    "model_conf": 277.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.02,
                    "duration": "indefinitely",
                    "model_conf": 160.35
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.54,
                    "duration": "2 Months",
                    "model_conf": 162.88
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 152.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/spring/10807-kuykendahl-rd.html": {
        "name": "The Woodlands - Indian Springs",
        "address": "10807 Kuykendahl Rd Suite 400, Spring Spring, Texas, 77382",
        "phone": "(281) 465-9596",
        "url": "https://locations.massageenvy.com/texas/spring/10807-kuykendahl-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.1784686,
            -95.5386238
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.35,
                    "duration": "4 Months",
                    "model_conf": 280.65
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.17,
                    "duration": "2 Months",
                    "model_conf": 165.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.33,
                    "duration": "indefinitely",
                    "model_conf": 164.3
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.6,
                    "duration": "indefinitely",
                    "model_conf": 157.73
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/spring/2211-rayford-road.html": {
        "name": "Imperial Oaks",
        "address": "2211 Rayford Road Suite 115, Spring, Texas, 77386",
        "phone": "(281) 292-8878",
        "url": "https://locations.massageenvy.com/texas/spring/2211-rayford-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 10:00 PM",
            "SUNDAY": "9:00 AM - 8:00 PM"
        },
        "geolocation": [
            30.12435856094536,
            -95.4085430699406
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 42.48,
                    "duration": "indefinitely",
                    "model_conf": 280.84
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.26,
                    "duration": "2 Months",
                    "model_conf": 183.73
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.68,
                    "duration": "indefinitely",
                    "model_conf": 165.94
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.14,
                    "duration": "5 Months",
                    "model_conf": 162.71
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.48,
                    "duration": "indefinitely",
                    "model_conf": 157.34
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.87,
                    "duration": "3 Months",
                    "model_conf": 141.01
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.18,
                    "duration": "2 Months",
                    "model_conf": 236.49
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 85.65
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.92,
                    "duration": "indefinitely",
                    "model_conf": 52.48
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.66,
                    "duration": "2 Months",
                    "model_conf": 214.72
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/spring/8707-spring-cypress.html": {
        "name": "Gleannloch Farms",
        "address": "8707 Spring Cypress Suite D, Spring Spring, Texas, 77379",
        "phone": "(281) 379-3535",
        "url": "https://locations.massageenvy.com/texas/spring/8707-spring-cypress.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 9:00 PM",
            "SUNDAY": "9:00 AM - 9:00 PM"
        },
        "geolocation": [
            30.034009958143994,
            -95.55286739313351
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.5,
                    "duration": "indefinitely",
                    "model_conf": 280.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 128.02,
                    "duration": "5 Months",
                    "model_conf": 165.43
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 66.73,
                    "duration": "indefinitely",
                    "model_conf": 166.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.4,
                    "duration": "3 Months",
                    "model_conf": 157.34
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/spring/9595-six-pines-drive.html": {
        "name": "The Woodlands - Market Street",
        "address": "9595 Six Pines Drive Suite 1430, Spring, Texas, 77380",
        "phone": "(281) 466-4645",
        "url": "https://locations.massageenvy.com/texas/spring/9595-six-pines-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.164543,
            -95.466537
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.175,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1 + $12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 143.34,
                    "duration": "4 Months",
                    "model_conf": 279.87
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.6,
                    "duration": "1 Months",
                    "model_conf": 165.56
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.18,
                    "duration": "4 Months",
                    "model_conf": 163.65
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.56,
                    "duration": "indefinitely",
                    "model_conf": 157.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/tomball/14257-fm-2920-rd.html": {
        "name": "Tomball",
        "address": "14257 FM 2920 Rd Suite 125, Tomball, Texas, 77377",
        "phone": "(281) 255-5000",
        "url": "https://locations.massageenvy.com/texas/tomball/14257-fm-2920-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.087658,
            -95.639353
        ],
        "rating": 4.7,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.45,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": null,
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.16,
                    "duration": "2 Months",
                    "model_conf": 141.13
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.58,
                    "duration": "1 Months",
                    "model_conf": 85.89
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.49,
                    "duration": "3 Months",
                    "model_conf": 54.65
                }
            }
        }
    },
    "https://locations.massageenvy.com/texas/tomball/24230-kuykendahl-rd.html": {
        "name": "Augusta Pines",
        "address": "24230 Kuykendahl Rd Ste 220, Tomball Tomball, Texas, 77375",
        "phone": "(281) 516-0077",
        "url": "https://locations.massageenvy.com/texas/tomball/24230-kuykendahl-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 7:00 PM",
            "TUESDAY": "9:00 AM - 7:00 PM",
            "WEDNESDAY": "9:00 AM - 7:00 PM",
            "THURSDAY": "9:00 AM - 7:00 PM",
            "FRIDAY": "9:00 AM - 7:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            30.11336810313793,
            -95.55238987483403
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 95.5,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 45.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 44.17,
                    "duration": "7 Months",
                    "model_conf": 280.44
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.3,
                    "duration": "indefinitely",
                    "model_conf": 180.81
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.29,
                    "duration": "2 Months",
                    "model_conf": 166.03
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.54,
                    "duration": "indefinitely",
                    "model_conf": 164.07
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.37,
                    "duration": "1 Months",
                    "model_conf": 157.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.03,
                    "duration": "2 Months",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.87,
                    "duration": "indefinitely",
                    "model_conf": 237.15
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.52,
                    "duration": "1 Months",
                    "model_conf": 85.58
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.8,
                    "duration": "1 Months",
                    "model_conf": 51.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.91,
                    "duration": "indefinitely",
                    "model_conf": 213.16
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/salt-lake-city/2236-south-1300-east.html": {
        "name": "Sugar House",
        "address": "2236 South 1300 East, Salt Lake City, Utah, 84106",
        "phone": "(801) 484-0223",
        "url": "https://locations.massageenvy.com/utah/salt-lake-city/2236-south-1300-east.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "8:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            40.7223531,
            -111.8571665
        ],
        "rating": 4.2,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.24,
                    "duration": "indefinitely",
                    "model_conf": 263.72
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.53,
                    "duration": "indefinitely",
                    "model_conf": 184.68
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.91,
                    "duration": "indefinitely",
                    "model_conf": 159.32
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.12,
                    "duration": "indefinitely",
                    "model_conf": 136.0
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.92,
                    "duration": "indefinitely",
                    "model_conf": 146.07
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.51,
                    "duration": "1 Months",
                    "model_conf": 140.87
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.97,
                    "duration": "indefinitely",
                    "model_conf": 229.37
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.89,
                    "duration": "indefinitely",
                    "model_conf": 83.0
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.69,
                    "duration": "1 Months",
                    "model_conf": 55.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.08,
                    "duration": "indefinitely",
                    "model_conf": 219.08
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/salt-lake-city/655-east-400-south.html": {
        "name": "Fourth South",
        "address": "655 East 400 South Suite B, Salt Lake City Salt Lake City, Utah, 84102",
        "phone": "(801) 433-5333",
        "url": "https://locations.massageenvy.com/utah/salt-lake-city/655-east-400-south.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 7:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            40.761078,
            -111.8720899
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.47,
                    "duration": "indefinitely",
                    "model_conf": 270.65
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.11,
                    "duration": "indefinitely",
                    "model_conf": 188.97
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.34,
                    "duration": "indefinitely",
                    "model_conf": 161.9
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.7,
                    "duration": "1 Months",
                    "model_conf": 147.43
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.78,
                    "duration": "indefinitely",
                    "model_conf": 150.86
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.61,
                    "duration": "indefinitely",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.38,
                    "duration": "1 Months",
                    "model_conf": 230.4
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.15,
                    "duration": "2 Months",
                    "model_conf": 83.76
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.52,
                    "duration": "2 Months",
                    "model_conf": 55.69
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 199.4,
                    "duration": "1 Months",
                    "model_conf": 219.93
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/st.-george/1091-n-bluff.html": {
        "name": "St. George",
        "address": "1091 N Bluff Suite 304, St. George, Utah, 84770",
        "phone": "(435) 628-9049",
        "url": "https://locations.massageenvy.com/utah/st.-george/1091-n-bluff.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.12570399384478,
            -113.60195779178152
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 134.99,
                    "duration": "indefinitely",
                    "model_conf": 71.08
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.17,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.81,
                    "duration": "1 Months",
                    "model_conf": 102.47
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.25,
                    "duration": "indefinitely",
                    "model_conf": 154.95
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.54,
                    "duration": "indefinitely",
                    "model_conf": 270.53
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.42,
                    "duration": "1 Months",
                    "model_conf": 166.72
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.53,
                    "duration": "indefinitely",
                    "model_conf": 167.18
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.66,
                    "duration": "indefinitely",
                    "model_conf": 157.6
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.82,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 85.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.19,
                    "duration": "indefinitely",
                    "model_conf": 55.83
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.68,
                    "duration": "indefinitely",
                    "model_conf": 216.41
                }
            }
        }
    },
    "https://locations.massageenvy.com/utah/st.-george/2376-red-cliffs-drive.html": {
        "name": "Pineview Plaza",
        "address": "2376 Red Cliffs Drive Suite #305, St. George, Utah, 84790",
        "phone": "(435) 627-6960",
        "url": "https://locations.massageenvy.com/utah/st.-george/2376-red-cliffs-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.122032015979464,
            -113.53494885837415
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 94.525,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "60",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "97",
                        "servicePrice": null,
                        "memberAdditional": "72",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "132"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "36",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "60"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "3. Massage Session": {
                    "prices": {
                        "valuedGuest": "128",
                        "servicePrice": null,
                        "memberAdditional": "90",
                        "memberUpgrade": "30",
                        "nonMemberPrice": "180"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "85",
                        "servicePrice": null,
                        "memberAdditional": "60",
                        "memberUpgrade": null,
                        "nonMemberPrice": "120"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.01,
                    "duration": "indefinitely",
                    "model_conf": 71.04
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 139.79
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 59.85,
                    "duration": "indefinitely",
                    "model_conf": 102.44
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.37,
                    "duration": "indefinitely",
                    "model_conf": 154.89
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.44,
                    "duration": "indefinitely",
                    "model_conf": 268.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 119.31,
                    "duration": "2 Months",
                    "model_conf": 166.51
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.58,
                    "duration": "indefinitely",
                    "model_conf": 166.94
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 179.73,
                    "duration": "indefinitely",
                    "model_conf": 157.77
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 135.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 135.8,
                    "duration": "indefinitely",
                    "model_conf": 141.11
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 120.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 120.12,
                    "duration": "indefinitely",
                    "model_conf": 85.93
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 60.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 60.22,
                    "duration": "indefinitely",
                    "model_conf": 55.86
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 180.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 180.67,
                    "duration": "indefinitely",
                    "model_conf": 216.53
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/alexandria/4539-duke-street.html": {
        "name": "Duke Street - Foxchase Shoppes",
        "address": "4539 Duke Street, Alexandria, Virginia, 22304",
        "phone": "(703) 461-3689",
        "url": "https://locations.massageenvy.com/virginia/alexandria/4539-duke-street.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.8130899,
            -77.110894
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 154.09,
                    "duration": "2 Months",
                    "model_conf": 236.12
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.22,
                    "duration": "indefinitely",
                    "model_conf": 142.92
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.25,
                    "duration": "4 Months",
                    "model_conf": 129.12
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.28,
                    "duration": "indefinitely",
                    "model_conf": 125.01
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.02,
                    "duration": "indefinitely",
                    "model_conf": 138.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.09,
                    "duration": "indefinitely",
                    "model_conf": 78.85
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.16,
                    "duration": "indefinitely",
                    "model_conf": 50.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 198.89,
                    "duration": "2 Months",
                    "model_conf": 175.83
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/alexandria/6465-old-beulah-st.html": {
        "name": "Hilltop @ Ft. Belvoir",
        "address": "6465 Old Beulah St, Alexandria, Virginia, 22315",
        "phone": "(703) 719-7721",
        "url": "https://locations.massageenvy.com/virginia/alexandria/6465-old-beulah-st.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 10:00 PM",
            "TUESDAY": "8:00 AM - 10:00 PM",
            "WEDNESDAY": "8:00 AM - 10:00 PM",
            "THURSDAY": "8:00 AM - 10:00 PM",
            "FRIDAY": "8:00 AM - 10:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.7419436,
            -77.1630539
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 103.825,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 153.23,
                    "duration": "4 Months",
                    "model_conf": 253.09
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.52,
                    "duration": "indefinitely",
                    "model_conf": 156.06
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 67.49,
                    "duration": "indefinitely",
                    "model_conf": 150.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 201.57,
                    "duration": "indefinitely",
                    "model_conf": 143.55
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 156.52,
                    "duration": "indefinitely",
                    "model_conf": 140.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.3,
                    "duration": "indefinitely",
                    "model_conf": 82.98
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.14,
                    "duration": "indefinitely",
                    "model_conf": 53.52
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 197.01,
                    "duration": "5 Months",
                    "model_conf": 200.6
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/arlington/1180-n-garfield-st.html": {
        "name": "Clarendon Center",
        "address": "1180 N Garfield St, Arlington, Virginia, 22201",
        "phone": "(571) 483-3333",
        "url": "https://locations.massageenvy.com/virginia/arlington/1180-n-garfield-st.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 9:00 PM",
            "TUESDAY": "9:00 AM - 9:00 PM",
            "WEDNESDAY": "9:00 AM - 9:00 PM",
            "THURSDAY": "9:00 AM - 9:00 PM",
            "FRIDAY": "9:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.8864667,
            -77.0941251
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 104.2,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "107",
                        "servicePrice": "92",
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "142"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Exfoliation Advanced Skin Care Treatment": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "155",
                        "servicePrice": "140",
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "200"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "143",
                        "servicePrice": "120",
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "195"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "48",
                        "servicePrice": "46",
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "65"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "95",
                        "servicePrice": "80",
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "130"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.97,
                    "duration": "indefinitely",
                    "model_conf": 223.39
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.45,
                    "duration": "indefinitely",
                    "model_conf": 150.77
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.52,
                    "duration": "1 Months",
                    "model_conf": 135.66
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.7,
                    "duration": "indefinitely",
                    "model_conf": 123.64
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.0,
                    "duration": "indefinitely",
                    "model_conf": 129.1
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 155.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 155.89,
                    "duration": "indefinitely",
                    "model_conf": 138.05
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 141.05,
                    "duration": "indefinitely",
                    "model_conf": 171.91
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.65,
                    "duration": "indefinitely",
                    "model_conf": 77.7
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.76,
                    "duration": "1 Months",
                    "model_conf": 49.06
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 200.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 200.58,
                    "duration": "indefinitely",
                    "model_conf": 161.86
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/arlington/3600-south-glebe-road.html": {
        "name": "Potomac Yard",
        "address": "3600 South Glebe Road #120, Arlington Arlington, Virginia, 22202",
        "phone": "(703) 414-0808",
        "url": "https://locations.massageenvy.com/virginia/arlington/3600-south-glebe-road.html",
        "email": "",
        "hours": {
            "MONDAY": "11:00 AM - 9:00 PM",
            "TUESDAY": "11:00 AM - 9:00 PM",
            "WEDNESDAY": "11:00 AM - 9:00 PM",
            "THURSDAY": "11:00 AM - 9:00 PM",
            "FRIDAY": "11:00 AM - 9:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            38.8424821,
            -77.0519994
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 111.25,
        "services": {
            "facials": {
                "1. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": "60min",
                    "accrual": "2"
                },
                "2. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "122",
                        "servicePrice": null,
                        "memberAdditional": "87",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "157"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": null,
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "55",
                        "servicePrice": null,
                        "memberAdditional": "44",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "73"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "113",
                        "memberUpgrade": "38",
                        "nonMemberPrice": "218"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "110",
                        "servicePrice": null,
                        "memberAdditional": "75",
                        "memberUpgrade": null,
                        "nonMemberPrice": "145"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "6. Nourishing Light": {
                    "prices": {
                        "valuedGuest": "175",
                        "servicePrice": null,
                        "memberAdditional": "150",
                        "memberUpgrade": "75",
                        "nonMemberPrice": "220"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.29,
                    "duration": "indefinitely",
                    "model_conf": 68.12
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.51,
                    "duration": "1 Months",
                    "model_conf": 139.53
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.11,
                    "duration": "indefinitely",
                    "model_conf": 72.86
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.68,
                    "duration": "indefinitely",
                    "model_conf": 145.27
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 174.39,
                    "duration": "1 Months",
                    "model_conf": 227.18
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 150.67,
                    "duration": "indefinitely",
                    "model_conf": 137.63
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 74.87,
                    "duration": "indefinitely",
                    "model_conf": 123.83
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.94,
                    "duration": "indefinitely",
                    "model_conf": 126.63
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 175.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 175.18,
                    "duration": "indefinitely",
                    "model_conf": 138.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 150.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 149.91,
                    "duration": "indefinitely",
                    "model_conf": 78.03
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 75.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 75.34,
                    "duration": "indefinitely",
                    "model_conf": 49.12
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 220.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 220.08,
                    "duration": "indefinitely",
                    "model_conf": 165.71
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/glen-allen/10114-brook-rd.html": {
        "name": "Virginia Center",
        "address": "10114 Brook Rd Suite 107, Glen Allen, Virginia, 23059",
        "phone": "(804) 456-4111",
        "url": "https://locations.massageenvy.com/virginia/glen-allen/10114-brook-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 5:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 4:00 PM"
        },
        "geolocation": [
            37.67410017,
            -77.46309683
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 88.75,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "92",
                        "servicePrice": "77",
                        "memberAdditional": "67",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "127"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "125",
                        "servicePrice": "110",
                        "memberAdditional": "110",
                        "memberUpgrade": "55",
                        "nonMemberPrice": "170"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": "98",
                        "memberAdditional": "83",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "173"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": "28",
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "40",
                        "servicePrice": "39",
                        "memberAdditional": "34",
                        "memberUpgrade": null,
                        "nonMemberPrice": "58"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "80",
                        "servicePrice": "65",
                        "memberAdditional": "55",
                        "memberUpgrade": null,
                        "nonMemberPrice": "115"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.96,
                    "duration": "indefinitely",
                    "model_conf": 70.55
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.88,
                    "duration": "indefinitely",
                    "model_conf": 152.0
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.01,
                    "duration": "indefinitely",
                    "model_conf": 139.39
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.83,
                    "duration": "1 Months",
                    "model_conf": 96.67
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.24,
                    "duration": "1 Months",
                    "model_conf": 152.46
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 125.73,
                    "duration": "indefinitely",
                    "model_conf": 269.12
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.55,
                    "duration": "indefinitely",
                    "model_conf": 187.34
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.84,
                    "duration": "indefinitely",
                    "model_conf": 160.44
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.9,
                    "duration": "indefinitely",
                    "model_conf": 158.26
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.51,
                    "duration": "1 Months",
                    "model_conf": 152.41
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 125.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 124.61,
                    "duration": "1 Months",
                    "model_conf": 140.93
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 110.22,
                    "duration": "indefinitely",
                    "model_conf": 223.78
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 110.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 109.56,
                    "duration": "1 Months",
                    "model_conf": 84.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 55.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 54.89,
                    "duration": "indefinitely",
                    "model_conf": 53.78
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.92,
                    "duration": "indefinitely",
                    "model_conf": 209.46
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/glen-allen/4346-pouncey-tract-road.html": {
        "name": "Short Pump",
        "address": "4346 Pouncey Tract Road, Glen Allen, Virginia, 23060",
        "phone": "(804) 612-0540",
        "url": "https://locations.massageenvy.com/virginia/glen-allen/4346-pouncey-tract-road.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "9:00 AM - 6:00 PM"
        },
        "geolocation": [
            37.6547358,
            -77.6136633
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.03,
                    "duration": "indefinitely",
                    "model_conf": 67.75
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 119.4
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.85,
                    "duration": "indefinitely",
                    "model_conf": 68.76
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.99,
                    "duration": "2 Months",
                    "model_conf": 139.67
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.41,
                    "duration": "indefinitely",
                    "model_conf": 226.8
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.35,
                    "duration": "indefinitely",
                    "model_conf": 149.27
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.67,
                    "duration": "indefinitely",
                    "model_conf": 129.07
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.56,
                    "duration": "2 Months",
                    "model_conf": 112.02
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 129.9
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 140.23
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.77,
                    "duration": "indefinitely",
                    "model_conf": 165.63
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.73,
                    "duration": "indefinitely",
                    "model_conf": 79.06
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 49.76
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.41,
                    "duration": "1 Months",
                    "model_conf": 153.97
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/virginia-beach/3352-princess-anne-road.html": {
        "name": "Virginia Beach Landstown Commons",
        "address": "3352 Princess Anne Road Ste 915, Virginia Beach, Virginia, 23456",
        "phone": "(757) 689-4959",
        "url": "https://locations.massageenvy.com/virginia/virginia-beach/3352-princess-anne-road.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.776159,
            -76.085707
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.74,
                    "duration": "indefinitely",
                    "model_conf": 69.28
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.53,
                    "duration": "1 Months",
                    "model_conf": 151.29
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.53,
                    "duration": "2 Months",
                    "model_conf": 102.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.57,
                    "duration": "2 Months",
                    "model_conf": 154.27
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.93,
                    "duration": "indefinitely",
                    "model_conf": 269.22
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.51,
                    "duration": "indefinitely",
                    "model_conf": 194.55
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 160.13
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.05,
                    "duration": "5 Months",
                    "model_conf": 161.35
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 155.66
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.25,
                    "duration": "indefinitely",
                    "model_conf": 140.79
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.82,
                    "duration": "indefinitely",
                    "model_conf": 225.85
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 83.43
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.08,
                    "duration": "indefinitely",
                    "model_conf": 53.45
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.0,
                    "duration": "2 Months",
                    "model_conf": 207.79
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/virginia-beach/4000-virginia-beach-blvd.html": {
        "name": "Virginia Beach Loehmann's Plaza",
        "address": "4000 Virginia Beach Blvd Ste 160, Virginia Beach, Virginia, 23452",
        "phone": "(757) 463-3689",
        "url": "https://locations.massageenvy.com/virginia/virginia-beach/4000-virginia-beach-blvd.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 9:00 PM",
            "TUESDAY": "8:00 AM - 9:00 PM",
            "WEDNESDAY": "8:00 AM - 9:00 PM",
            "THURSDAY": "8:00 AM - 9:00 PM",
            "FRIDAY": "8:00 AM - 9:00 PM",
            "SATURDAY": "8:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.8426041,
            -76.112847
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.48,
                    "duration": "1 Months",
                    "model_conf": 66.88
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.55,
                    "duration": "1 Months",
                    "model_conf": 141.97
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.62,
                    "duration": "1 Months",
                    "model_conf": 139.74
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.34,
                    "duration": "3 Months",
                    "model_conf": 102.33
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.43,
                    "duration": "1 Months",
                    "model_conf": 153.33
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.94,
                    "duration": "indefinitely",
                    "model_conf": 250.79
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.58,
                    "duration": "1 Months",
                    "model_conf": 193.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.29,
                    "duration": "indefinitely",
                    "model_conf": 150.97
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.08,
                    "duration": "5 Months",
                    "model_conf": 152.21
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.96,
                    "duration": "indefinitely",
                    "model_conf": 152.99
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.41,
                    "duration": "indefinitely",
                    "model_conf": 140.55
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.33,
                    "duration": "2 Months",
                    "model_conf": 211.53
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 80.92
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 50.4
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.21,
                    "duration": "1 Months",
                    "model_conf": 192.74
                }
            }
        }
    },
    "https://locations.massageenvy.com/virginia/virginia-beach/539-hilltop-plaza.html": {
        "name": "Virginia Beach Hilltop Plaza",
        "address": "539 Hilltop Plaza, Virginia Beach, Virginia, 23454",
        "phone": "(757) 422-0004",
        "url": "https://locations.massageenvy.com/virginia/virginia-beach/539-hilltop-plaza.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            36.8487298,
            -76.0262962
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.4,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": "87",
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": "130",
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": "113",
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": "44",
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": "75",
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.91,
                    "duration": "indefinitely",
                    "model_conf": 68.76
                }
            },
            "Chemical Peel Advanced Skin Care (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.36,
                    "duration": "indefinitely",
                    "model_conf": 144.78
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.99,
                    "duration": "indefinitely",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 101.53
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.9,
                    "duration": "indefinitely",
                    "model_conf": 153.29
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.72,
                    "duration": "indefinitely",
                    "model_conf": 263.42
                }
            },
            "Microderm Infusion (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.39,
                    "duration": "indefinitely",
                    "model_conf": 194.48
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 156.98
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.05,
                    "duration": "indefinitely",
                    "model_conf": 158.56
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.28,
                    "duration": "1 Months",
                    "model_conf": 152.76
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.27,
                    "duration": "indefinitely",
                    "model_conf": 140.64
                }
            },
            "Nourishing Light (servicePrice)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.52,
                    "duration": "indefinitely",
                    "model_conf": 221.31
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 82.88
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.95,
                    "duration": "indefinitely",
                    "model_conf": 52.68
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.62,
                    "duration": "indefinitely",
                    "model_conf": 197.28
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/bellevue/14339-ne-20th-street.html": {
        "name": "Bellevue",
        "address": "14339 NE 20th Street Suite G, Bellevue Bellevue, Washington, 98007",
        "phone": "(425) 614-3689",
        "url": "https://locations.massageenvy.com/washington/bellevue/14339-ne-20th-street.html",
        "email": "",
        "hours": {
            "MONDAY": "Closed",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.626988,
            -122.149731
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 116.05,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "127",
                        "servicePrice": null,
                        "memberAdditional": "92",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "162"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "185",
                        "servicePrice": null,
                        "memberAdditional": "160",
                        "memberUpgrade": "80",
                        "nonMemberPrice": "230"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "173",
                        "servicePrice": null,
                        "memberAdditional": "120",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "225"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": "40",
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "58",
                        "servicePrice": null,
                        "memberAdditional": "46",
                        "memberUpgrade": null,
                        "nonMemberPrice": "75"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "115",
                        "servicePrice": null,
                        "memberAdditional": "80",
                        "memberUpgrade": null,
                        "nonMemberPrice": "150"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.81,
                    "duration": "indefinitely",
                    "model_conf": 71.22
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 161.12,
                    "duration": "indefinitely",
                    "model_conf": 139.23
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.78,
                    "duration": "indefinitely",
                    "model_conf": 100.41
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 230.32,
                    "duration": "indefinitely",
                    "model_conf": 143.69
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 185.24,
                    "duration": "indefinitely",
                    "model_conf": 226.59
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 158.87,
                    "duration": "2 Months",
                    "model_conf": 116.3
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 81.5,
                    "duration": "indefinitely",
                    "model_conf": 140.37
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 228.83,
                    "duration": "1 Months",
                    "model_conf": 141.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 185.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 184.84,
                    "duration": "indefinitely",
                    "model_conf": 140.29
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 160.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 159.65,
                    "duration": "indefinitely",
                    "model_conf": 85.82
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 80.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 80.23,
                    "duration": "indefinitely",
                    "model_conf": 51.91
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 230.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 231.46,
                    "duration": "indefinitely",
                    "model_conf": 195.14
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/bellevue/15100-southeast-38th-st..html": {
        "name": "Bellevue Eastgate",
        "address": "15100 Southeast 38th St. #306, Bellevue, Washington, 98006",
        "phone": "(425) 957-7979",
        "url": "https://locations.massageenvy.com/washington/bellevue/15100-southeast-38th-st..html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 10:00 PM",
            "TUESDAY": "9:00 AM - 10:00 PM",
            "WEDNESDAY": "9:00 AM - 10:00 PM",
            "THURSDAY": "9:00 AM - 10:00 PM",
            "FRIDAY": "9:00 AM - 10:00 PM",
            "SATURDAY": "9:00 AM - 8:00 PM",
            "SUNDAY": "8:00 AM - 8:00 PM"
        },
        "geolocation": [
            47.5765286,
            -122.1370354
        ],
        "rating": 4.5,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 120.475,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": null,
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "132",
                        "servicePrice": null,
                        "memberAdditional": "97",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "167"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": null,
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "195",
                        "servicePrice": null,
                        "memberAdditional": "170",
                        "memberUpgrade": "85",
                        "nonMemberPrice": "240"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "43",
                        "servicePrice": null,
                        "memberAdditional": "43",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "43"
                    },
                    "serviceTime": "30min",
                    "accrual": "43"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "60",
                        "servicePrice": null,
                        "memberAdditional": "49",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "78"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Massage Session": {
                    "prices": {
                        "valuedGuest": "180",
                        "servicePrice": null,
                        "memberAdditional": "128",
                        "memberUpgrade": "43",
                        "nonMemberPrice": "233"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Massage Session": {
                    "prices": {
                        "valuedGuest": "120",
                        "servicePrice": null,
                        "memberAdditional": "85",
                        "memberUpgrade": null,
                        "nonMemberPrice": "155"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 194.95,
                    "duration": "indefinitely",
                    "model_conf": 71.28
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.82,
                    "duration": "indefinitely",
                    "model_conf": 139.55
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 85.68,
                    "duration": "indefinitely",
                    "model_conf": 101.27
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 239.36,
                    "duration": "1 Months",
                    "model_conf": 150.49
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 195.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 196.53,
                    "duration": "indefinitely",
                    "model_conf": 260.31
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 170.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 169.1,
                    "duration": "2 Months",
                    "model_conf": 148.76
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 85.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 85.49,
                    "duration": "indefinitely",
                    "model_conf": 157.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 240.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 239.11,
                    "duration": "1 Months",
                    "model_conf": 151.13
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/spokane/2203-e.-29th-ave.html": {
        "name": "South Spokane",
        "address": "2203 E. 29th Ave, Spokane, Washington, 99203",
        "phone": "(509) 394-4440",
        "url": "https://locations.massageenvy.com/washington/spokane/2203-e.-29th-ave.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 5:30 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "9:00 AM - 4:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.628483196273805,
            -117.3791702167
        ],
        "rating": 4.4,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.81,
                    "duration": "indefinitely",
                    "model_conf": 71.48
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.36,
                    "duration": "4 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.36,
                    "duration": "indefinitely",
                    "model_conf": 101.26
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.64,
                    "duration": "indefinitely",
                    "model_conf": 155.12
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.1,
                    "duration": "indefinitely",
                    "model_conf": 282.05
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.7,
                    "duration": "indefinitely",
                    "model_conf": 165.95
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.0,
                    "duration": "indefinitely",
                    "model_conf": 168.34
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.91,
                    "duration": "indefinitely",
                    "model_conf": 157.32
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.56,
                    "duration": "indefinitely",
                    "model_conf": 141.07
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.41,
                    "duration": "indefinitely",
                    "model_conf": 85.83
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.19,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.85,
                    "duration": "indefinitely",
                    "model_conf": 221.45
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/spokane/920-east-hoerner.html": {
        "name": "Spokane Pavilion",
        "address": "920 East Hoerner Ste 100, Spokane, Washington, 99218",
        "phone": "(509) 465-3689",
        "url": "https://locations.massageenvy.com/washington/spokane/920-east-hoerner.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 6:00 PM",
            "TUESDAY": "9:00 AM - 6:00 PM",
            "WEDNESDAY": "9:00 AM - 6:00 PM",
            "THURSDAY": "9:00 AM - 6:00 PM",
            "FRIDAY": "9:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            47.741901,
            -117.397024
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.91,
                    "duration": "indefinitely",
                    "model_conf": 71.47
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.34,
                    "duration": "4 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.45,
                    "duration": "indefinitely",
                    "model_conf": 101.28
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.7,
                    "duration": "indefinitely",
                    "model_conf": 155.13
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 166.11,
                    "duration": "indefinitely",
                    "model_conf": 281.98
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.62,
                    "duration": "1 Months",
                    "model_conf": 165.79
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 71.0,
                    "duration": "indefinitely",
                    "model_conf": 168.36
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.8,
                    "duration": "indefinitely",
                    "model_conf": 157.21
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 165.54,
                    "duration": "indefinitely",
                    "model_conf": 141.12
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.43,
                    "duration": "indefinitely",
                    "model_conf": 85.74
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.22,
                    "duration": "indefinitely",
                    "model_conf": 55.22
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 211.84,
                    "duration": "indefinitely",
                    "model_conf": 221.42
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/vancouver/322-se-192nd-avenue.html": {
        "name": "East Vancouver",
        "address": "322 SE 192nd Avenue Suite 110, Vancouver, Washington, 98683",
        "phone": "(360) 883-3689",
        "url": "https://locations.massageenvy.com/washington/vancouver/322-se-192nd-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "10:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "10:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            45.620155,
            -122.477423
        ],
        "rating": 4.1,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.73,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 138.79,
                    "duration": "3 Months",
                    "model_conf": 139.71
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.59,
                    "duration": "2 Months",
                    "model_conf": 100.36
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.04,
                    "duration": "indefinitely",
                    "model_conf": 154.74
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 163.28,
                    "duration": "4 Months",
                    "model_conf": 281.63
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.17,
                    "duration": "indefinitely",
                    "model_conf": 165.91
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.52,
                    "duration": "2 Months",
                    "model_conf": 166.53
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.51,
                    "duration": "indefinitely",
                    "model_conf": 157.08
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.19,
                    "duration": "1 Months",
                    "model_conf": 141.03
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.95,
                    "duration": "indefinitely",
                    "model_conf": 85.53
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.01,
                    "duration": "indefinitely",
                    "model_conf": 55.57
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.24,
                    "duration": "1 Months",
                    "model_conf": 219.91
                }
            }
        }
    },
    "https://locations.massageenvy.com/washington/vancouver/8802-ne-5th-avenue.html": {
        "name": "West Vancouver",
        "address": "8802 NE 5th Avenue, Vancouver, Washington, 98665",
        "phone": "(360) 718-8144",
        "url": "https://locations.massageenvy.com/washington/vancouver/8802-ne-5th-avenue.html",
        "email": "",
        "hours": {
            "MONDAY": "10:00 AM - 6:00 PM",
            "TUESDAY": "10:00 AM - 6:00 PM",
            "WEDNESDAY": "10:00 AM - 6:00 PM",
            "THURSDAY": "10:00 AM - 6:00 PM",
            "FRIDAY": "10:00 AM - 6:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            45.68596003951652,
            -122.66643437301639
        ],
        "rating": 4.3,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 106.45,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "117",
                        "servicePrice": null,
                        "memberAdditional": "82",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "152"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "165",
                        "servicePrice": null,
                        "memberAdditional": "140",
                        "memberUpgrade": "70",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "158",
                        "servicePrice": null,
                        "memberAdditional": "105",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "210"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": "35",
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "53",
                        "servicePrice": null,
                        "memberAdditional": "41",
                        "memberUpgrade": null,
                        "nonMemberPrice": "70"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "105",
                        "servicePrice": null,
                        "memberAdditional": "70",
                        "memberUpgrade": null,
                        "nonMemberPrice": "140"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.95,
                    "duration": "indefinitely",
                    "model_conf": 71.38
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 139.05,
                    "duration": "2 Months",
                    "model_conf": 139.64
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.27,
                    "duration": "4 Months",
                    "model_conf": 101.5
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 209.69,
                    "duration": "indefinitely",
                    "model_conf": 154.47
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 162.72,
                    "duration": "5 Months",
                    "model_conf": 280.56
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.79,
                    "duration": "indefinitely",
                    "model_conf": 166.28
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 69.16,
                    "duration": "4 Months",
                    "model_conf": 166.89
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 210.85,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 165.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 164.01,
                    "duration": "2 Months",
                    "model_conf": 141.09
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 140.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 140.24,
                    "duration": "indefinitely",
                    "model_conf": 85.66
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 70.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 70.11,
                    "duration": "indefinitely",
                    "model_conf": 55.66
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 210.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 208.7,
                    "duration": "2 Months",
                    "model_conf": 219.03
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/brookfield/17125-a-w-bluemound-rd.html": {
        "name": "Bluemound",
        "address": "17125-A W Bluemound Rd Suite A, Brookfield Brookfield, Wisconsin, 53005",
        "phone": "(262) 641-5777",
        "url": "https://locations.massageenvy.com/wisconsin/brookfield/17125-a-w-bluemound-rd.html",
        "email": "",
        "hours": {
            "MONDAY": "9:00 AM - 8:00 PM",
            "TUESDAY": "9:00 AM - 8:00 PM",
            "WEDNESDAY": "9:00 AM - 8:00 PM",
            "THURSDAY": "9:00 AM - 8:00 PM",
            "FRIDAY": "9:00 AM - 8:00 PM",
            "SATURDAY": "9:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.035631621860745,
            -88.124536242378
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 99.25,
        "services": {
            "facials": {
                "1. Chemical Peel Advanced Skin Care": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                },
                "2. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": null,
                    "accrual": "1+$12"
                },
                "3. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "4. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": "65",
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "$63"
                    },
                    "serviceTime": "30min",
                    "accrual": ".5"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            },
            {
                "title": "",
                "description": "A customized facial helps you relax and get your glow on. Guests can take\nadvantage of low intro rates. Book now!"
            }
        ],
        "memberships": {
            "Chemical Peel Advanced Skin Care (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.21,
                    "duration": "indefinitely",
                    "model_conf": 71.24
                }
            },
            "Chemical Peel Advanced Skin Care (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 139.63
                }
            },
            "Chemical Peel Advanced Skin Care (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.82,
                    "duration": "indefinitely",
                    "model_conf": 92.02
                }
            },
            "Chemical Peel Advanced Skin Care (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.63,
                    "duration": "indefinitely",
                    "model_conf": 147.4
                }
            },
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 146.24,
                    "duration": "indefinitely",
                    "model_conf": 267.23
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.36,
                    "duration": "1 Months",
                    "model_conf": 166.71
                }
            },
            "Microderm Infusion (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 65.92,
                    "duration": "indefinitely",
                    "model_conf": 157.08
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 189.58,
                    "duration": "indefinitely",
                    "model_conf": 156.52
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 145.05,
                    "duration": "indefinitely",
                    "model_conf": 140.82
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.67,
                    "duration": "indefinitely",
                    "model_conf": 83.42
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.79,
                    "duration": "1 Months",
                    "model_conf": 55.71
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 190.9,
                    "duration": "indefinitely",
                    "model_conf": 218.25
                }
            }
        }
    },
    "https://locations.massageenvy.com/wisconsin/brookfield/19115-w-capitol-drive.html": {
        "name": "Capitol Drive",
        "address": "19115 W Capitol Drive #104, Brookfield, Wisconsin, 53045",
        "phone": "(262) 786-5060",
        "url": "https://locations.massageenvy.com/wisconsin/brookfield/19115-w-capitol-drive.html",
        "email": "",
        "hours": {
            "MONDAY": "8:00 AM - 8:00 PM",
            "TUESDAY": "8:00 AM - 8:00 PM",
            "WEDNESDAY": "8:00 AM - 8:00 PM",
            "THURSDAY": "8:00 AM - 8:00 PM",
            "FRIDAY": "8:00 AM - 8:00 PM",
            "SATURDAY": "10:00 AM - 6:00 PM",
            "SUNDAY": "10:00 AM - 6:00 PM"
        },
        "geolocation": [
            43.08988156577839,
            -88.15032988786692
        ],
        "rating": 4.6,
        "brand": "Massage Envy",
        "coming_soon": false,
        "index": 98.875,
        "services": {
            "facials": {
                "1. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Customized Facial Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Customized Back Facial Session": {
                    "prices": {
                        "valuedGuest": "102",
                        "servicePrice": null,
                        "memberAdditional": "77",
                        "memberUpgrade": "12",
                        "nonMemberPrice": "137"
                    },
                    "serviceTime": "60min",
                    "accrual": "1+$12"
                },
                "4. Microderm Infusion": {
                    "prices": {
                        "valuedGuest": "145",
                        "servicePrice": null,
                        "memberAdditional": "130",
                        "memberUpgrade": null,
                        "nonMemberPrice": "190"
                    },
                    "serviceTime": null,
                    "accrual": "2"
                }
            },
            "totalBody": {
                "1. Massage Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                },
                "2. Massage Session": {
                    "prices": {
                        "valuedGuest": "135",
                        "servicePrice": null,
                        "memberAdditional": "98",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "188"
                    },
                    "serviceTime": "90min",
                    "accrual": "1.5"
                },
                "3. Rapid Tension Relief Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": "33",
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "4. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "45",
                        "servicePrice": null,
                        "memberAdditional": "39",
                        "memberUpgrade": null,
                        "nonMemberPrice": "63"
                    },
                    "serviceTime": "30min",
                    "accrual": "0.5"
                },
                "5. Total Body Stretch Session": {
                    "prices": {
                        "valuedGuest": "90",
                        "servicePrice": null,
                        "memberAdditional": "65",
                        "memberUpgrade": null,
                        "nonMemberPrice": "125"
                    },
                    "serviceTime": "60min",
                    "accrual": "1"
                }
            }
        },
        "offers": [
            {
                "title": "The First-Time Facial Event is here again!",
                "description": "Try your first Customized Facial at a Massage Envy franchise July 17\u2013August 13, 2023 and get a FREE Enhancement + 20% OFF select skin care products. While supplies last at participating locations. Other terms and conditions apply; see www.massageenvy.com/facials/first-time-facial."
            },
            {
                "title": "",
                "description": "Enrich your next massage with CBD Cream, formulated with plant-based extracts that help nourish, hydrate, and soften the skin. Choose from unscented or lavender, which blends calming botanicals for additional aromatherapy benefits."
            },
            {
                "title": "",
                "description": "Reap the ongoing benefits of routine facials, massage, and stretch at low member rates. It\u2019s an optimal blend of affordability, value, and ME time. Join now."
            }
        ],
        "memberships": {
            "Microderm Infusion (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 142.62,
                    "duration": "6 Months",
                    "model_conf": 271.84
                }
            },
            "Microderm Infusion (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 130.66,
                    "duration": "indefinitely",
                    "model_conf": 166.95
                }
            },
            "Microderm Infusion (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 191.07,
                    "duration": "indefinitely",
                    "model_conf": 156.95
                }
            },
            "Nourishing Light (valuedGuest)": {
                "cost": 145.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 144.34,
                    "duration": "1 Months",
                    "model_conf": 140.94
                }
            },
            "Nourishing Light (memberAdditional)": {
                "cost": 130.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 84.18
                }
            },
            "Nourishing Light (memberUpgrade)": {
                "cost": 65.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 64.9,
                    "duration": "indefinitely",
                    "model_conf": 55.74
                }
            },
            "Nourishing Light (nonMemberPrice)": {
                "cost": 190.0,
                "per": "session",
                "term": "1-session",
                "suggested": {
                    "cost": 188.02,
                    "duration": "4 Months",
                    "model_conf": 219.56
                }
            }
        }
    }
}